import React from 'react';
import theme from './../../theme';

export const LoadedDataDetailUi = ({ children, promptTitle }) => 
    <div>
        <h4>
            {
                promptTitle ? promptTitle : 'Specify a location'
            }
        </h4>
        { children }
    </div>

export const Location = ({ loc, onSelected }) => 
    <div style={{
        ...theme.getLocationMailingAddressStyle(theme)
    }}>
        <div>
            <button 
                className="test-carrierGroupLocationSelectorLocationSelectBtn"
                onClick={event => { onSelected(loc) }}
            >
                Select
            </button>
        </div>

        <p>
            { loc.mailingAddress }
        </p>

        {
            loc.hasTruckDocks && 
                <p>
                    <strong>
                        Has Truck Dock(s)
                    </strong>
                </p>
        }

        {
            loc.hasLimitedAccess && 
                <p>
                    <strong>
                        Limited Access Location
                    </strong>
                </p>
        }

        {
            loc.notes !== '' && 
                <p>
                    { loc.notes }
                </p>
        }

    </div>

export const UnSelectedLocationUi = ({ 
    locations, 
    onSelected
}) => 
    <div style={{
        ...theme.getLocationSelectorContainerStyle(theme)  
    }}>
        
        <div>
            {
                locations.map(aLoc => 
                    <Location 
                        onSelected={onSelected}
                        loc={aLoc.userLocation}
                        key={aLoc.id}
                    />
                )
            }
        </div>
        
    </div>

export const SelectedLocationUi = ({ selectedLocation, onRemoveSelected }) => 
    <div style={{
        ...theme.getLocationSelectorContainerStyle(theme)  
    }}>
        <div>
            <button 
                className="test-carrierGroupLocationSelectorLocationChangeBtn"
                onClick={ event => { onRemoveSelected() }}
            >
                Change
            </button>

            <p style={{
              ...theme.getLocationMailingAddressStyle(theme)  
            }}>
                { selectedLocation.mailingAddress }
            </p>
        </div>
    </div>
