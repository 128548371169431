class CountryService {

    static getDefaultValue()
    {
        return 'US';
    }

    static getUSRegionDefaultValue()
    {
        return 'AL';
    }

    static getRegionNameByCode(regionCode, countryCode)
    {
        const list = this.getRegionDropDownValues(countryCode);
        const rec = list.find(aRec => aRec.value === regionCode);
        return rec ? rec.name : '';
    }

    static getCountryNameByCode(countryCode)
    {
        const list = this.getDropDownValues();
        const rec = list.find(aRec => aRec.value === countryCode);
        return rec ? rec.name : '';
    }

    static getUSRegionDropDownValues()
    {
        return [
            {
                name: 'Alabama',
                value: 'AL'
            },
            {
                name: 'Alaska',
                value: 'AK'
            },
            {
                name: 'American Samoa',
                value: 'AS'
            },
            {
                name: 'Arizona',
                value: 'AZ'
            },
            {
                name: 'Arkansas',
                value: 'AR'
            },
            {
                name: 'California',
                value: 'CA'
            },
            {
                name: 'Colorado',
                value: 'CO'
            },
            {
                name: 'Connecticut',
                value: 'CT'
            },
            {
                name: 'Delaware',
                value: 'DE'
            },
            {
                name: 'District of Columbia',
                value: 'DC'
            },
            {
                name: 'Florida',
                value: 'FL'
            },
            {
                name: 'Georgia',
                value: 'GA'
            },
            {
                name: 'Guam',
                value: 'GU'
            },
            {
                name: 'Hawaii',
                value: 'HI'
            },
            {
                name: 'Idaho',
                value: 'ID'
            },
            {
                name: 'Illinois',
                value: 'IL'
            },
            {
                name: 'Indiana',
                value: 'IN'
            },
            {
                name: 'Iowa',
                value: 'IA'
            },
            {
                name: 'Kansas',
                value: 'KS'
            },
            {
                name: 'Kentucky',
                value: 'KY'
            },
            {
                name: 'Louisiana',
                value: 'LA'
            },
            {
                name: 'Maine',
                value: 'ME'
            },
            {
                name: 'Maryland',
                value: 'MD'
            },
            {
                name: 'Massachusetts',
                value: 'MA'
            },
            {
                name: 'Michigan',
                value: 'MI'
            },
            {
                name: 'Minnesota',
                value: 'MN'
            },
            {
                name: 'Mississippi',
                value: 'MS'
            },
            {
                name: 'Missouri',
                value: 'MO'
            },
            {
                name: 'Montana',
                value: 'MT'
            },
            {
                name: 'Nebraska',
                value: 'NE'
            },
            {
                name: 'Nevada',
                value: 'NV'
            },
            {
                name: 'New Hampshire',
                value: 'NH'
            },
            {
                name: 'New Jersey',
                value: 'NJ'
            },
            {
                name: 'New Mexico',
                value: 'NM'
            },
            {
                name: 'New York',
                value: 'NY'
            },
            {
                name: 'North Carolina',
                value: 'NC'
            },
            {
                name: 'North Dakota',
                value: 'ND'
            },
            {
                name: 'Northern Mariana Islands',
                value: 'MP'
            },
            {
                name: 'Ohio',
                value: 'OH'
            },
            {
                name: 'Oklahoma',
                value: 'OK'
            },
            {
                name: 'Oregon',
                value: 'OR'
            },
            {
                name: 'Pennsylvania',
                value: 'PA'
            },
            {
                name: 'Puerto Rico',
                value: 'PR'
            },
            {
                name: 'Rhode Island',
                value: 'RI'
            },
            {
                name: 'South Carolina',
                value: 'SC'
            },
            {
                name: 'South Dakota',
                value: 'SD'
            },
            {
                name: 'Tennessee',
                value: 'TN'
            },
            {
                name: 'Texas',
                value: 'TX'
            },
            {
                name: 'Utah',
                value: 'UT'
            },
            {
                name: 'U.S. Virgin Islands',
                value: 'VI'
            },
            {
                name: 'Vermont',
                value: 'VT'
            },
            {
                name: 'Virginia',
                value: 'VA'
            },
            {
                name: 'Washington',
                value: 'WA'
            },
            {
                name: 'West Virginia',
                value: 'WV'
            },
            {
                name: 'Wisconsin',
                value: 'WI'
            },
            {
                name: 'Wyoming',
                value: 'WY'
            },
        ];
    }

    static getRegionDefaultValue(countryCode)
    {
        switch(countryCode)
        {
            case 'US':
                return this.getUSRegionDefaultValue();

            default: 
                return this.getUSRegionDefaultValue();
        }
    }

    static getRegionDropDownValues(countryCode)
    {
        switch(countryCode)
        {
            case 'US':
                return this.getUSRegionDropDownValues();

            default:
                return this.getUSRegionDropDownValues();
        }
    }

    static getDropDownValues()
    {
        return [
            { name: 'United States', value: 'US' },
            { name: 'Austria', value: 'AT' },
            { name: 'Argentina', value: 'AR' },
            { name: 'Australia', value: 'AU' },
            { name: 'Belgium', value: 'BE' },
            { name: 'Bulgaria', value: 'BG' },
            { name: 'Bolivia', value: 'BO' },
            { name: 'Canada', value: 'CA' },
            { name: 'Chile', value: 'CL' },
            { name: 'Colombia', value: 'CO' },
            { name: 'Costa Rica', value: 'CR' },
            { name: 'Croatia', value: 'HR' },
            { name: 'Cyprus', value: 'CY' },
            { name: 'Czech Republic', value: 'CZ' },
            { name: 'Denmark', value: 'DK' },
            { name: 'Dominican Republic', value: 'DO' },
            { name: 'Estonia', value: 'EE' },
            { name: 'Egypt', value: 'EG' },
            { name: 'Finland', value: 'FI' },
            { name: 'France', value: 'FR' },
            { name: 'Germany', value: 'DE' },
            { name: 'Greece', value: 'GR' },
            { name: 'Hong Kong', value: 'HK' },
            { name: 'Hungary', value: 'HU' },
            { name: 'Indonesia', value: 'ID' },
            { name: 'Ireland', value: 'IE' },
            { name: 'Israel', value: 'IL' },
            { name: 'Iceland', value: 'IS' },
            { name: 'Italy', value: 'IT' },
            { name: 'Kenya', value: 'KE' },
            { name: 'Liechtenstein', value: 'LI' },
            { name: 'Lithuania', value: 'LT' },
            { name: 'Luxembourg', value: 'LU' },
            { name: 'Latvia', value: 'LV' },
            { name: 'Malta', value: 'MT' },
            { name: 'Mexico', value: 'MX' },
            { name: 'Netherlands', value: 'NL' },
            { name: 'Norway', value: 'NO' },
            { name: 'New Zealand', value: 'NZ' },
            { name: 'Peru', value: 'PE' },
            { name: 'Philippines', value: 'PH' },
            { name: 'Poland', value: 'PL' },
            { name: 'Portugal', value: 'PT' },
            { name: 'Paraguay', value: 'PY' },
            { name: 'Romania', value: 'RO' },
            { name: 'Saudi Arabia', value: 'SA' },
            { name: 'Singapore', value: 'SG' },
            { name: 'Slovenia', value: 'SI' },
            { name: 'Slovakia', value: 'SK' },
            { name: 'South Africa', value: 'ZA' },
            { name: 'Spain', value: 'ES' },
            { name: 'Sweden', value: 'SE' },
            { name: 'Switzerland', value: 'CH' },
            { name: 'Thailand', value: 'TH' },
            { name: 'Trinidad and Tobago', value: 'TT' },
            { name: 'Turkey', value: 'TR' },
            { name: 'United Kingdom', value: 'GB' },
            { name: 'Uruguay', value: 'UY' },
        ];
    }
}

export default CountryService;
