import DetailPanel from './../../../core/detail';
import CarrierGroupDeliveryPlanService from './../../../../../service/carrier-group-delivery-plan';
import TrackPricingJobView from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../../store/map/on-logout';
import config from './../../../../../config';

class _TrackPricingJob extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.scheduleRefresh = this.scheduleRefresh.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    refreshData()
    {
        CarrierGroupDeliveryPlanService.priceStatus({
            request: this.getLoadDataRequest(),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    loadData()
    {
        super.loadData({ });
        this.refreshData();
    }

    getDefaultState()
    {
        let baseState = super.getDefaultState();
        return {
            ...baseState,

        };
    }

    onLoadDataResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: response
        };
        this.setState(nextState);
        if(this.props.onPriceUpdated && response.isCompleted)
        {
            this.props.onPriceUpdated(response.result);
        }

        if(!response.isCompleted && !response.isFailed)
        {
            this.scheduleRefresh();
        }
    }

    scheduleRefresh()
    {
        setTimeout(this.refreshData, 
            config.interval.checkJobStatus);
    }

    getLoadDataRequest()
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: this.props.deliveryId,
        };
    }

    componentDidMount()
    {
        this.loadData();
    }

    getLoadedDataDetailUi()
    {
        return <TrackPricingJobView 
            data={this.state.data} 
        />
    }
};

const TrackPricingJob = connect(mapStateToProps, mapDispatchToProps)(_TrackPricingJob);
export default TrackPricingJob;
