import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ReportedIssueDetailsPanel from '../ui/panel/reported-issue-details';
import ProfileMenu from './../ui/menu/profile';
import { useParams } from 'react-router-dom';

const ReportedIssueDetailsPage = () => {
    const { appIssueId } = useParams();
    return (
        <AuthPageTemplate childMenu={ <ProfileMenu /> }>
            <ReportedIssueDetailsPanel appIssueId={appIssueId} />
        </AuthPageTemplate>
    );

};

export default ReportedIssueDetailsPage;
