import React from 'react';
import theme from './../../theme';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import UserReviewHistoryPanel from './../user-review-history';

const Summary = ({ data }) => {
    const [showHistory, setShowHistory] = useState(false);

    const getBodyStyle = () => theme.getUserReviewSummaryStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{ textAlign: 'center' }}>
            <h3>
                Reputation
            </h3>
            
            <div style={bodyStyle}
                className="test-userReviewsShowHistoryBtn"
                onClick={event => { setShowHistory(!showHistory) }}
            >
                {
                    data.positive < 1 ? '' : 
                        <div style={{
                            ...theme.getUserReviewPositiveStyle(theme)
                        }}>
                            { data.positive }
                            { data.positive === 1 ? ' Positive Review' : ' Positive Reviews' }
                        </div>
                }
                {
                    data.negative < 1 ? '' :
                        <div style={{
                            ...theme.getUserReviewNegativeStyle(theme)
                        }}>
                            { data.negative } 
                            { data.negative === 1 ? ' Negative Review' : ' Negative Feedbacks' }
                        </div>
                }
                <div style={{
                    clear: 'both'
                }}></div>
            </div>

            <div style={{
                ...theme.getUserReviewHistoryOuterContainer(theme),  
            }}>
                {
                    showHistory ? <UserReviewHistoryPanel userId={data.ratedUserId} /> : ''
                }
            </div>

        </div>
    );
};

const UserReviewsViewPanel = ({ data }) => 
    <section>
        {
            data.positive < 1 && data.negative < 1 ? '' : <Summary data={data} />
        }
    </section>

export default UserReviewsViewPanel;
