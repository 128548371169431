export const getProfilePwdSuggestionContainerStyle = (theme) => ({
    margin: '.75rem',
    display: 'inline-block',
    borderColor: theme.secondaryColor,
    backgroundColor: theme.buttonColor,
    padding: '0 .5rem .75rem',
    height: 'fit-content',
    width: '15rem',
    verticalAlign: 'top',
    borderRadius: '.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)'
});

export const getProfileInfoWelcomeTourShortcutStyle = (theme) => ({
    margin: '.75rem',
    display: 'inline-block',
    borderColor: theme.secondaryColor,
    backgroundColor: theme.buttonColor,
    padding: '0 .5rem .75rem',
    height: 'fit-content',
    width: '15rem',
    verticalAlign: 'top',
    borderRadius: '.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    cursor: 'pointer'
});

export const getProfilePwdSuggestionOuterContainerStyle = (theme) => ({
    width: '100%',
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    textAlign: 'center',
    height: '2.25rem',
    margin: '0 auto'
});

export const getProfilePwdSuggestionContentStyle = (theme) => ({
    maxWidth: theme.isTablet() ? '58.5rem' : 'calc(100% - 1.5rem)',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    margin: '0 0 1rem 0'
});

export const getProfilePwdContainerStyle = (theme) => ({
    width: '100%',
    textAlign: 'center',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
});

export const getProfilePwdPageStyle = (theme) => ({
    margin: '0 auto 2rem', 
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    width: '100%',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    display: 'flex',
    height: 'fit-content',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 .75rem 1.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    borderRadius: '.25rem',
    backgroundColor: theme.panelColor,
});

export const getProfilePwdBodyStyle = (theme) => ({
    margin: '0 auto 2rem', 
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    width: 'calc(100% - 1.5rem)',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    height: 'fit-content',
    alignItems: 'center',
    padding: '1rem .75rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    borderRadius: '.25rem',
    backgroundColor: theme.panelColor,
});
export const getProfilePwdBodyFixStyle = (theme) => ({
    display: 'flex', 
});

export const getProfilePwdPageAddPadTopStyle = (theme) => ({
    paddingTop: '1rem',
});

export const getProfilePwdPanelStyle = (theme) => ({
    margin: '0 auto', 
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    width: '100%',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    height: 'fit-content',
    alignItems: 'center',
    padding: '1rem .75rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    borderRadius: '.25rem',
    backgroundColor: theme.panelColor,
});

export const getProfileButtonStyle = (theme) => ({
    margin: '0 auto .75rem',
    display: 'flex',
    borderColor: theme.secondaryColor,
    backgroundColor: theme.buttonColor,
    padding: '0 .5rem',
    height: 'fit-content',
    maxWidth: '15rem',
    width: 'fit-content',
    borderRadius: '.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    flexDirection: 'column',
    alignItems: 'center',
});