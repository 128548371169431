import React from 'react';
import PayService from './../../../service/pay';
import ValidateService from './../../../service/validate';
import CountryService from './../../../service/country';
import CurrencyInputService from './../../../service/currency-input';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import CurrencyTypeEnum from './../core/enum/currency-type';

class _AddBankAccountPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _AddBankAccountPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);

        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            addedId: '',
        };
    }

    static getValidators()
    {
        return {
            country: {
                isValid: ValidateService.textLine   
            }, 
            currency: {
                isValid: ValidateService.textLine   
            },
            sortCode: {
                isValid: ValidateService.valueRequired,
                isVisible: CurrencyInputService.bankAccountHasSortCode
            },
            bsb: {
                isValid: ValidateService.valueRequired,
                isVisible: CurrencyInputService.bankAccountHasBSB
            },
            transitNumber:{
                isValid: ValidateService.valueRequired,
                isVisible: CurrencyInputService.bankAccountHasTransitNumber
            },
            institutionNumber:{
                isValid: ValidateService.valueRequired,
                isVisible: CurrencyInputService.bankAccountHasInstitutionNumber                
            },
            routingNumber: {
                isValid: ValidateService.valueRequired,
                isVisible: CurrencyInputService.bankAccountHasRoutingNumber
            }, 
            accountNumber: {
                isValid: ValidateService.valueRequired
            },
        };
    }

    getFieldsModel()
    {
        return {
            country: {
                name: 'Country',
                error: 'Provide the country associated with this account',
                value: CountryService.getDefaultValue(),
                valueList: CountryService.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            currency: {
                name: 'Currency',
                error: 'What currency does this account hold?',
                value: CurrencyTypeEnum.CURRENCY_TYPE_USD,
                valueList: CurrencyTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            sortCode: {
                name: 'Sort Code',
                error: 'Specify the account sort code',
                value: '',
                visible: false,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            bsb: {
                name: 'BSB',
                error: 'Specify the account BSB',
                value: '',
                visible: false,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            transitNumber:{
                name: 'Transit Number',
                error: 'Specify the account transit number',
                value: '',
                visible: false,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            institutionNumber:{
                name: 'Institution Number',
                error: 'Specify the account institution number',
                value: '',
                visible: false,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            routingNumber: {
                name: 'Routing Number',
                error: 'Specify the account routing number',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            accountNumber: {
                name: 'Account Number',
                error: 'Specify the account number',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            }
        };
    }

    getServerRequest()
    {
        let request = {
            country: this.state.country.value,
            currency: this.state.currency.value,
            accountNumber: this.state.accountNumber.value
        };

        /* istanbul ignore next */
        switch(request.currency)
        {
            case CurrencyTypeEnum.CURRENCY_TYPE_GBP:
                request.sortCode = this.state.sortCode.value;
                break;

            case CurrencyTypeEnum.CURRENCY_TYPE_CAD:
                request.transitNumber = this.state.transitNumber.value;
                request.institutionNumber = this.state.institutionNumber.value;
                break;

            case CurrencyTypeEnum.CURRENCY_TYPE_USD:
                request.routingNumber = this.state.routingNumber.value;
                break;

            case CurrencyTypeEnum.CURRENCY_TYPE_AUD:
                request.bsb = this.state.bsb.value;
                break;

            default:

                break;
        }

        return request;
    }

    onSendToServer()
    {
        PayService.addBank({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    getConfirmUi(confirmMessage = 'Account Added')
    {
        return (
            <section>
                <h2 style={{ 
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to={`/payouts`}
                    >
                        Account
                    </NavLink>
                    &nbsp;
                    Added
                </h2>
            </section>
        );
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const AddBankAccountPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddBankAccountPrompt);
export default AddBankAccountPrompt;
