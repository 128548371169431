import React from 'react';
import LoginPrompt from './../../ui/prompt/login';
import mapStateToProps from './../../store/map/is-user';
import { connect } from 'react-redux';
import PageTemplate from './page';

const _AuthPageTemplate = ({ isUser, children, childMenu = null, hideOnScroll = true }) => 
    <PageTemplate 
        isUser={isUser} 
        hideOnScroll={hideOnScroll}
        childMenu={childMenu}
    >
        {
            /* istanbul ignore next */
            isUser ? children : <LoginPrompt /> 
        }
    </PageTemplate>

const AuthPageTemplate = connect(mapStateToProps)(_AuthPageTemplate);
export default AuthPageTemplate;
