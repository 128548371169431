export const getPostsShortCutContainerStyle = (theme) => ({
    margin: '.75rem .75rem 2rem',
    display: 'inline-block',
    borderColor: theme.secondaryColor,
    backgroundColor: theme.buttonColor,
    padding: '.5rem',
    height: 'fit-content',
    width: '9rem',
    verticalAlign: 'top',
    borderRadius: '.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)'
});

export const getPostsShortCutOuterContainerStyle = (theme) => ({
    backgroundColor: theme.panelWarmColor,
    borderRadius: '.25rem',
    margin: '1rem .75rem',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    maxWidth: '30rem',
    textAlign: 'center',
    display: 'inline-block',
    boxShadow: 'rgba(29, 29, 29, 0.5) 0 .1rem .1rem',
    width: '100%'
});

export const getPostsShortCutDetailsStyle = (theme) => ({
    fontSize: '8pt',
});

export const getPostsContainerStyle = (theme) => ({
    display: 'flex',
    flexDirection: theme.isTablet() ? 'row' : 'column',
    alignItems: theme.isTablet() ? 'flex-start' :  'center',
    justifyContent: theme.isTablet() ? 'center' :  'flex-start'
});
