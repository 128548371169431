import React from 'react';
import DetailPanel from './../core/detail';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';
import OnlineTransactionsListViewPanel from './view';
import PayoutService from './../../../service/payout';

class _OnlineTransactionsListPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        PayoutService.list({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        this.loadData({ page });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            page: inputObj.page ? inputObj.page : 0
        }
    }

    componentDidMount()
    {
        this.loadData({ page: 0 });
    }

    getLoadedDataDetailUi()
    {
        return <OnlineTransactionsListViewPanel data={this.state.data} onLoadSpecifiedPage={ this.onLoadSpecifiedPage } />
    }
}

const OnlineTransactionsListPanel = connect(mapStateToProps, mapDispatchToProps)(_OnlineTransactionsListPanel);
export default OnlineTransactionsListPanel;
