import React from 'react';
import { useState } from 'react';
import DeliveryService from '../../../service/delivery';
import SentPackageAssignShipCompanyPrompt from './../../prompt/sent-package-assign-ship-company';
import FreeSetupShippingCompany from './free-setup-shipping-company';
import theme from '../../theme';

const SetupOutOfNetworkDelivery = ({
    delivery,
    onLoadDataResponse
}) => {
    const list = DeliveryService.getShippingCompanies();
    const [ selectedCompany, setSelectedCompany ] = useState(null);
    const onSelected = company => { setSelectedCompany(company) };
    return (
        <div>
            {
                /* istanbul ignore next */
                selectedCompany ? 
                    <div>
                        <h3>
                            { selectedCompany.name } Delivery
                        </h3>
                        <SentPackageAssignShipCompanyPrompt 
                            delivery={delivery} 
                            onLoadDataResponse={onLoadDataResponse} 
                            carrierType={selectedCompany.id}
                        />
                    </div> : 
                    <div>
                        <h3>
                            Select your out of network carrier
                        </h3>
                        <p style={{
                          ...theme.getQuoteFinePrintStyle(theme)  
                        }}>
                            *Quotes are provided by a third party api with supplied details.  Contact your carrier directly to verify their pricing.
                        </p>
                        <div style={{
                            ...theme.getOutOfNetworkQuoteBoxContainerStyle(theme)
                        }}>
                            {
                                list.map((company, index) => 
                                    <FreeSetupShippingCompany 
                                        company={company} 
                                        quote={delivery.quoteList.find(q => q.carrierType === company.id)}
                                        key={index}
                                        onSelected={onSelected}
                                    />
                                )
                            }
                        </div>
                    </div>
            }
        </div>
    );
};

export default SetupOutOfNetworkDelivery;
