import React from 'react';
import { useState } from 'react';
import theme from './../../../../theme';
import CurrencyInputService from './../../../../../service/currency-input';
import Payout from './payout';
import Header from './header';
import Contributions from './contributions';
import CostEstimates from './cost-estimates';
import makeOnPayoutBufferUpdated from './on-payout-buffer-updated';
import makeOnMpgBufferUpdated from './on-mpg-buffer-updated';
import calcTrip from './calc-trip';
import makeOnGasPriceBufferUpdated from './on-gas-price-buffer-updated';

const Contributor = ({
    members,
    record,
    estimatedDollarsPerGallon,
    estimatedMPG,
    onSetPayout,
    pendingPayoutUpdates
}) => {
    
    const foundPayout = pendingPayoutUpdates.find(i => i.memberId === record.memberId);
    const payoutIni = foundPayout && foundPayout.payout ? foundPayout.payout : record.payout;
    const mpgIni = foundPayout && foundPayout.mpg ? foundPayout.mpg : '20';
    const useMpgGlobalIni = foundPayout ? foundPayout.useGlobalMpg : true;
    const useGasPriceGlobalIni = foundPayout ? foundPayout.useGasPriceGlobal : true;
    /* istanbul ignore next */
    const payoutFormattedIni = ['', '0', '000'].includes(payoutIni) ? '0' : CurrencyInputService.currencyToInput(payoutIni);
    const gasPriceIni = foundPayout && foundPayout.gasPrice ? foundPayout.gasPrice : '3.88';

    const [payoutBuffer, setPayoutBuffer] = useState(payoutFormattedIni);
    const [payoutBufferValid, setPayoutBufferValid] = useState(true);
    
    const [useMpgGlobal, setUseMpgGlobal] = useState(useMpgGlobalIni);
    
    const [mpgBufferValid, setMpgBufferValid] = useState(true);
    const [mpgBuffer, setMpgBuffer] = useState(mpgIni);
    
    const [useGasPriceGlobal, setUseGasPriceGlobal] = useState(useGasPriceGlobalIni);

    const [gasPriceBufferValid, setGasPriceBufferValid] = useState(true);
    const [gasPriceBuffer, setGasPriceBuffer] = useState(gasPriceIni);
        
    const onPayoutBufferUpdated = makeOnPayoutBufferUpdated({
        setPayoutBufferValid,
        setPayoutBuffer,
        onSetPayout,
        record,
        useMpgGlobal,
        mpgBufferValid,
        mpgBuffer
    });

    const onMpgBufferUpdated = makeOnMpgBufferUpdated({
        setMpgBufferValid,
        setMpgBuffer,
        onSetPayout,
        record,
        payoutBufferValid,
        payoutBuffer,
        useMpgGlobal
    });

    /* istanbul ignore next */
    const onToggleUseMpgGlobal = event => { 
        onSetPayout({
            record, 
            useGlobalMpg: !useMpgGlobal, 
            mpg: null, 
            payout: null
        });
        setUseMpgGlobal(!useMpgGlobal);
        
    };

    /* istanbul ignore next */
    const onToggleUseGasPriceGlobal = event => {
        onSetPayout({
            record,
            useGasPriceGlobal: !useGasPriceGlobal
        });        
        setUseGasPriceGlobal(!useGasPriceGlobal);
    };

    const onGasPriceBufferUpdated = makeOnGasPriceBufferUpdated({
        setGasPriceBufferValid,
        setGasPriceBuffer,
        onSetPayout,
        record,
        useGasPriceGlobal,
        gasPriceBufferValid,
        gasPriceBuffer
    });

    const {
        tripCost,
        hasTrips
    } = calcTrip({
        useMpgGlobal,
        mpgBufferValid,
        estimatedMPG,
        mpgBuffer,
        estimatedDollarsPerGallon,
        record,
        useGasPriceGlobal,
        gasPriceBufferValid,
        gasPriceBuffer,

    });
    
    return (
        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            
                <Header 
                    members={members}
                    record={record}
                />
                
                <CostEstimates
                    hasTrips={hasTrips}
                    tripCost={tripCost}
                    useMpgGlobal={useMpgGlobal}
                    onToggleUseMpgGlobal={onToggleUseMpgGlobal}
                    estimatedMPG={estimatedMPG}
                    mpgBuffer={mpgBuffer}
                    onMpgBufferUpdated={onMpgBufferUpdated}
                    mpgBufferValid={mpgBufferValid}
                    useGasPriceGlobal={useGasPriceGlobal}
                    onToggleUseGasPriceGlobal={onToggleUseGasPriceGlobal}
                    estimatedDollarsPerGallon={estimatedDollarsPerGallon}
                    gasPriceBuffer={gasPriceBuffer}
                    onGasPriceBufferUpdated={onGasPriceBufferUpdated}
                    gasPriceBufferValid={gasPriceBufferValid}
                />

               <Payout 
                    payoutBuffer={payoutBuffer}
                    onPayoutBufferUpdated={onPayoutBufferUpdated}
                    payoutBufferValid={payoutBufferValid}
                    tripCost={tripCost}
               />

                <Contributions 
                    contributionsList={record.contributions}
                />

                <hr />
        </div>
    );
};

export default Contributor;
