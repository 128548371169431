export const getExchangeChatMessageStyle = (theme) => ({
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    wordWrap: 'anywhere'
});

export const getExchangeChatTimeStyle = (theme) => ({
    textAlign: 'center',
    paddingRight: '15px',
    paddingTop: '10px',
    float: 'left',
    width: '10rem',
    position: 'relative',
    right: '10px',
});

export const getExchangeChatUsernameStyle = (theme) => ({
    textAlign: 'left',
    width: '190px',
    paddingLeft: '5px',
    float: 'left',
});

export const getExchangeChatSpaceStyle = (theme) => ({
    marginBottom: '0.75rem'
});