import React from 'react';
import SeekService from './../../../service/seek';
import TagService from './../../../service/tag';
import { FORM_FIELD } from './../core/abstract';
import ValidateService from './../../../service/validate';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../core/search';
import SeekSearchViewPrompt, { 
    SeekSearchSingleFieldPromptContainer,
    SeekSearchViewPromptContainer
} from './view';
import TagFilterPanel from './../../panel/core/tag-filter';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import addItem from './../../../img/menu-add-item.svg';

class _SeekSearchPrompt extends SearchPrompt
{
    constructor(parms)
    {
        super(parms, _SeekSearchPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.pageToUrl = this.pageToUrl.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onTagsLoaded = this.onTagsLoaded.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.loadTags = this.loadTags.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onTagChanged = this.onTagChanged.bind(this);
        this.onTagAdded = this.onTagAdded.bind(this);
        this.onTagRemoved = this.onTagRemoved.bind(this);
        this.onRemoveAllTags = this.onRemoveAllTags.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.setIsTagFilterPromptOpen = this.setIsTagFilterPromptOpen.bind(this);
    }

    static getValidators()
    {
        return {
            searchText: {
                isValid: ValidateService.textLine,
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    /* istanbul ignore next */
    getFieldsModel()
    {
        return {
            searchText: {
                name: 'Search',
                error: 'no more than 112 characters',
                value: this.props.searchText ? ( 
                    this.props.searchText === '-' ? '' : this.props.searchText 
                ) : '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    /* istanbul ignore next */
    componentDidUpdate(prevProps, prevState)
    {
        if(this.props.page !== prevProps.page)
        {
            this.setContactingServer(true);
            this.onSendToServer({
                searchText: this.state.searchText.value ? this.state.searchText.value : '',
                tagList: this.state.selectedTagList,
                page: this.props.page ? this.props.page : 0,
            });
        }
    }

    /* istanbul ignore next */
    getDefaultState()
    {
        const rootState = super.getDefaultState();
        return {
            ...rootState,
            tagFilterPromptOpen: false,
            allTagList: [],
            loadingTags: false,
            selectedTagList: this.props.tags ? this.props.tags : [],
        };
    }

    setIsTagFilterPromptOpen(isOpen)
    {
        let nextState = this.state;
        nextState.tagFilterPromptOpen = isOpen;
        this.setState(nextState);
    }

    getSingleFieldPrompt()
    {
        return (
            <div>
                <SeekSearchSingleFieldPromptContainer>
                    <NavLink to="/seek/add">
                        <div style={{
                            ...theme.getOfferSearchAddOfferButtonStyle(theme, addItem)
                        }}>
                        </div>
                        <div style={{
                            ...theme.getOfferSearchAddOfferButtonTextStyle(theme, addItem)
                        }}>
                            ADD A<br />SEEK
                        </div>
                    </NavLink>
                    <form onSubmit={this.onFormSubmit}>
                        { this.getServerFeedbackUi() }
                        <div style={{
                            ...theme.getInLineTextStyle(theme),
                        }}>
                            <div style={{
                                ...theme.getOfferSearchSingleFormStyle(theme),
                            }}>
                                { this.getFormFieldsUi() }
                            </div>

                            <div>
                                { this.getSubmitButtonUi() }
                            </div>
                        </div>
                    </form>
                </SeekSearchSingleFieldPromptContainer>

                <SeekSearchSingleFieldPromptContainer>
                    {
                        this.state.loadingTags ? 
                            <p>
                                Checking for Filters...
                            </p> : 
                                <TagFilterPanel 
                                    isOpen={this.state.tagFilterPromptOpen}
                                    setIsOpen={this.setIsTagFilterPromptOpen}
                                    selectedTagList={this.state.selectedTagList}
                                    allTagList={this.state.allTagList}
                                    onTagAdded={this.onTagAdded}
                                    onTagRemoved={this.onTagRemoved}
                                    onRemoveAllTags={this.onRemoveAllTags}
                                />
                    }
                </SeekSearchSingleFieldPromptContainer>
            </div>
        );
    }

    getPromptUi()
    {
        const searchPanel = super.getPromptUi();
        return (
            <div>
                { searchPanel }
            </div>
        );
    }

    /* istanbul ignore next */
    onTagChanged(nextSelectedTagList)
    {
        let nextState = this.state;
        nextState.contactingServer = true;
        nextState.selectedTagList = nextSelectedTagList;
        this.setState(nextState);
        let request = this.getServerRequest();
        request.page = 0;
        this.onSendToServer(request);
    }

    /* istanbul ignore next */
    onRemoveAllTags()
    {
        this.onTagChanged([]);
    }

    /* istanbul ignore next */
    onTagAdded(title)
    {
        const nextSelectedTagList = [
            ...this.state.selectedTagList,
            title
        ];
        this.onTagChanged(nextSelectedTagList);
    }

    /* istanbul ignore next */
    onTagRemoved(title)
    {
        const nextSelectedTagList = this.state.selectedTagList.filter(aTag => aTag !== title);
        this.onTagChanged(nextSelectedTagList);
    }

    /* istanbul ignore next */
    onTagsLoaded(response)
    {
        const nextState = this.state;
        nextState.loadingTags = false;
        nextState.allTagList = response.list;
        this.setState(nextState);
        this.onSendToServer();
    }

    loadTags()
    {
        const nextState = this.state;
        nextState.loadingTags = true;
        nextState.contactingServer = true;
        this.setState(nextState);

        TagService.listPopulatedSeek({
            onResponse: this.onTagsLoaded,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadTags();
    }

    updateUrl(page)
    {
        const currentUrl = this.pageToUrl(page);
        this.applyUrlUpdate(currentUrl);
    }

    /* istanbul ignore next */
    onFormSubmit(e)
    {
        e.preventDefault();
        if(this.state.isValid){
            this.setContactingServer(true);
            let request = this.getServerRequest();
            request.page = 0;
            this.onSendToServer(request);
        }
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'seekList');
    }

    onSendToServer(request = undefined)
    {
        const thisRequest = request ? request : this.getServerRequest();
        this.updateUrl(thisRequest.page);
        
        SeekService.search({
            request: thisRequest,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            searchText: this.state.searchText.value,
            tagList: this.state.selectedTagList,
            page: this.props.page ? this.props.page : 0,
        };
    }

    /* istanbul ignore next */
    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        SeekService.search({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    pageToUrl(page)
    {
        const text = this.state.searchText.value === '' ? '-' : this.state.searchText.value;
        const loadPageUrl = `/seeks/${page}/${encodeURI(text)}/${TagService.parseTagsToUrl(this.state.selectedTagList)}`;
        return loadPageUrl;
    }

    getSearchResultUi(aResult, index)
    {
        return (
            <SeekSearchViewPromptContainer key={index}>
                <SeekSearchViewPrompt seek={aResult} key={index} />
            </SeekSearchViewPromptContainer>
        );
    }
}

const SeekSearchPrompt = connect(mapStateToProps, mapDispatchToProps)(_SeekSearchPrompt);
export default SeekSearchPrompt;
