import FlagService from './../../../../service/flag';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';
import { FLAGGED_CONTENT_TYPE_ONLINE_PAY } from './../../core/enum/flagged-content-type';

class _AdminReviewFlagPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _AdminReviewFlagPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            restored: {
                isValid: ValidateService.anyValue
            },
            reviewerUserSummary: {
                isValid: ValidateService.valueRequired
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            restored: {
                name: this.props.flag.flaggedContentType === FLAGGED_CONTENT_TYPE_ONLINE_PAY ? 'Refund Payment ' : 'Restore Content',
                error: this.props.flag.flaggedContentType === FLAGGED_CONTENT_TYPE_ONLINE_PAY ? 'Refund Online Payment' : 'Restore content to published',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            reviewerUserSummary: {
                name: 'Notes*',
                error: 'Provide notes on why you restored or persisted the flagged content',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    componentDidMount()
    {
        this.setFocus('reviewerUserSummary');
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
        if(this.props.onReviewed)
        {
            this.props.onReviewed();
        }
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        FlagService.reviewFlag({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            restored: this.state.restored.value,
            reviewerUserSummary: this.state.reviewerUserSummary.value,
            id: this.props.flag.id
        };
    }

    getConfirmUi(confirmMessage = 'Flag Reviewed')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Apply')
    {
        return super.getSubmitButtonUi(buttonText);
    }

}

const AdminReviewFlagPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminReviewFlagPrompt);
export default AdminReviewFlagPrompt;
