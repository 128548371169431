import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Pagination from './../../prompt/core/pagination';
import theme from './../../theme';
import AcceptCarrierMemberInvitePrompt from './../../prompt/accept-carrier-member-invite';

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const Invite = ({ 
    invite,
    acceptInvite,
    declineInvite 
}) => {
    const [ acceptInvitePromptOpen, setAcceptInvitePromptOpen ] = useState(false);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <div>
                <p>
                    {`You have been invited by ${invite.createdByUsername} to join the ${invite.carrierGroupName} team!`}
                </p>
                <h5>
                    {`A Note from ${invite.createdByUsername}`}
                </h5>
                <p>
                    { invite.internalNote }
                </p>
            </div>
            <div>
                <button 
                    className="test-carrierGroupInvitesJoinBtn"
                    onClick={event => { setAcceptInvitePromptOpen(true) }}
                >
                    Join
                </button>
                <button 
                    className="test-carrierGroupInvitesDeclineBtn"
                    onClick={event => { declineInvite(invite) }}
                >
                    Decline
                </button>
            </div>
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setAcceptInvitePromptOpen(false) }
                }
                open={acceptInvitePromptOpen}
            >
                <div style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    <h3>
                        Accept Invite
                    </h3>
                    <p>
                        We need a few details about you to setup your company membership
                    </p>

                    <AcceptCarrierMemberInvitePrompt 
                        pendingCarrierMemberId={invite.id}
                        onAcceptInvite={
                            /* istanbul ignore next */
                            () => { acceptInvite(invite.carrierGroupId);
                        }}
                    />
                </div>
            </Dialog>
        </div>
    );
};

const PopulatedView = ({ 
    invites, 
    onLoadSpecifiedPage,
    acceptInvite,
    declineInvite 
}) => 
    <div>
        <h3>
            Invites
        </h3>

        { getPaginationUi({ data: invites, onLoadSpecifiedPage }) }

        {
            invites.list.map((inv, index) => 
                <Invite 
                    invite={inv} 
                    acceptInvite={acceptInvite}
                    declineInvite={declineInvite}
                    key={index} 
                />
            )
        }

        { getPaginationUi({ data: invites, onLoadSpecifiedPage }) }

    </div>

const EmptyView = () => 
    <div>
        <p>
            No Invites Found
        </p>
    </div>


const CarrierGroupInvitesViewPanel = ({ 
    data, 
    onLoadSpecifiedPage,
    acceptInvite,
    declineInvite 
}) => data.invites.list.length < 1 ? 
    <EmptyView /> : 
        <PopulatedView 
            invites={data.invites} 
            onLoadSpecifiedPage={onLoadSpecifiedPage} 
            acceptInvite={acceptInvite}
            declineInvite={declineInvite}
        />

export default CarrierGroupInvitesViewPanel;
