import CarrierGroupTripService from './../../../service/carrier-group-trip';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import CarrierGroupLocationSelectorPanel from './../../panel/carrier-group-location-selector';

class _CreateCarrierGroupTripPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _CreateCarrierGroupTripPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onFromSelected = this.onFromSelected.bind(this);
        this.onToSelected = this.onToSelected.bind(this);
        this.onFromRemoveSelected = this.onFromRemoveSelected.bind(this);
        this.onToRemoveSelected = this.onToRemoveSelected.bind(this);
        this.validateState = this.validateState.bind(this);
        this.validator.estimatedStart.isValid = ValidateService.makeDateMin(this, 'estimatedStart');
        this.validator.estimatedEnd.isValid = ValidateService.makeDateAfter(this, 'estimatedStart');
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            estimatedStart: {
                isValid: ValidateService.valueRequired
            },
            estimatedEnd: {
                isValid: ValidateService.valueRequired
            },
            acceptingMoreCargo: {
                isValid: ValidateService.anyValue
            }
        };
    }

    getFieldsModel()
    {
        return {
            estimatedStart: {
                name: 'Estimated Trip Start',
                error: 'Provide a future estimated start date and time for when this trip will start',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                minDate: new Date(),
                type: FORM_FIELD.DATETIME
            },
            estimatedEnd: {
                name: 'Estimated Trip End',
                error: 'Provide an estimated end data and time for when this trip will end which is after the stated start date and time',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DATETIME
            },
            acceptingMoreCargo: {
                name: 'Accepting Cargo',
                error: 'Specify if you are accepting more cargo to be added to this trip',
                value: true,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
        };
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            from: this.state.from,
            to: this.state.to,
            estimatedStart: this.state.estimatedStart.value,
            estimatedEnd: this.state.estimatedEnd.value,
            acceptingMoreCargo: this.state.acceptingMoreCargo.value,
            page: '0'
        };
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        CarrierGroupTripService.createTrip({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        
        if(this.props.onTripAdded)
        {
            this.props.onTripAdded(response);
        }
    }

    /* istanbul ignore next */
    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    /* istanbul ignore next */
    onAuthError()
    {
        this.props.onLogout();
    }

    /* istanbul ignore next */
    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            from: null,
            to: null
        };
    }

    /* istanbul ignore next */
    validateState(updateState)
    {
        let result = super.validateState(updateState);
        if(this.state.to === null || this.state.from === null)
        {
            result.isValid = false;
        }
        return result;
    }
    
    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Trip Added')
    {
        return super.getConfirmUi(confirmMessage);
    }

    /* istanbul ignore next */
    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    onFromSelected(location)
    {
        let nextState = this.state;
        nextState.from = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onToSelected(location)
    {
        let nextState = this.state;
        nextState.to = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onFromRemoveSelected()
    {
        let nextState = this.state;
        nextState.from = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onToRemoveSelected()
    {
        let nextState = this.state;
        nextState.to = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    getPromptUi()
    {
        return (
            <div>

                <CarrierGroupLocationSelectorPanel
                    promptTitle="Select your starting location"
                    carrierGroupId={this.props.carrierGroupId}
                    selectedLocation={this.state.from}
                    onSelected={this.onFromSelected}
                    onRemoveSelected={this.onFromRemoveSelected}
                    allowLocationAdds={false}
                />

                <CarrierGroupLocationSelectorPanel
                    promptTitle="Select your final location"
                    carrierGroupId={this.props.carrierGroupId}
                    selectedLocation={this.state.to}
                    onSelected={this.onToSelected}
                    onRemoveSelected={this.onLocationRemoveSelected}
                    allowLocationAdds={false}
                />

                { super.getPromptUi() }

            </div>
        );
    }
        
}

const CreateCarrierGroupTripPrompt = connect(mapStateToProps, mapDispatchToProps)(_CreateCarrierGroupTripPrompt);
export default CreateCarrierGroupTripPrompt;
