import React from 'react';
import { useState } from 'react';
import NoAcceptingTrips from './no-accepting-trips';
import PossibleTrip from './possible-trip';
import theme from './../../../theme';
import Dialog from '@mui/material/Dialog';
import CreateCarrierGroupTripPrompt from './../../../prompt/create-carrier-group-trip';
import {
    isTripAlreadyAlignedToCargoItem
} from './../core';

const SelectTrip = ({
    carrierGroupId,
    acceptingCargo,
    carryingCargo,
    cargoItem,
    delivery,
    selectedMemberId,
    step,
    onHookTrip,
    onAddTrip
}) => {
    const [ viewAddPrompt, setViewAddPrompt ] = useState(false);
    /* istanbul ignore next */
    const onAdded = response => {
        onAddTrip();
        setViewAddPrompt(false);
    };
    const filterFreeToSelect = aTrip => !isTripAlreadyAlignedToCargoItem(carryingCargo, aTrip.id);
    const freeList = acceptingCargo.list.filter(filterFreeToSelect);
    return freeList.length < 1 ? 
        <NoAcceptingTrips 
            carrierGroupId={carrierGroupId} 
            onAddTrip={onAddTrip}
        /> :
            <div style={{
                ...theme.getGeneralTextStyle(theme)  
            }}>
                <div>
                    {
                        freeList.map((aTrip, index) => 
                                <PossibleTrip 
                                    trip={aTrip}
                                    step={step}
                                    delivery={delivery}
                                    selectedMemberId={selectedMemberId}
                                    cargoItem={cargoItem}
                                    onHookTrip={onHookTrip}
                                    key={index}
                                />
                            )
                    }
                </div>

                <button onClick={
                    /* istanbul ignore next */
                    event => { setViewAddPrompt(true) }}>
                    Schedule a trip
                </button>

                <Dialog
                    onClose={
                        /* istanbul ignore next */
                        event => { setViewAddPrompt(false) }
                    }
                    open={viewAddPrompt}
                >
                    <h2>
                        Add a new trip
                    </h2>

                    <CreateCarrierGroupTripPrompt
                        onTripAdded={onAdded}
                        carrierGroupId={carrierGroupId}
                    />
                </Dialog>

            </div>
};

export default SelectTrip;
