import React from 'react';
import ErrorService from './../../../../service/error';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../../core/search';
import { Header, SearchMetricsSummaryViewPrompt } from './view';
import CandlestickChartPanel from '../../../panel/core/candlestick-chart';
import theme from './../../../theme';

class _SearchMetricsSummaryPrompt extends SearchPrompt
{
    constructor(parms)
    {
        super(parms, _SearchMetricsSummaryPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
        this.setSearchText = this.setSearchText.bind(this);
    }

    static getValidators()
    {
        return {
            searchText: {
                isValid: ValidateService.anyValue
            },
            noEarlierDateCode: {
                isValid: ValidateService.anyValue
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            searchText: {
                name: 'Request',
                error: 'Specify a request',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            noEarlierDateCode: {
                name: 'Time',
                error: 'Specify a time period',
                value: '',
                valueList: ErrorService.getPossibleTimePeriods(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            }
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'metrics');
    }

    onSendToServer(request = undefined)
    {
        ErrorService.adminSearchMetricsSummary({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            page: 0,
            searchText: this.state.searchText.value,
            noEarlierDateCode: this.state.noEarlierDateCode.value
        };
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        this.onSendToServer(request);
    }

    getNoResultsUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <p>
                    No Metrics Found.
                </p>
            </div>
        );
    }

    setSearchText(text)
    {
        const nextState = this.state;
        nextState.searchText.value = text;
        this.setState(nextState);
        this.onSendToServer();
    }

    getSearchResultUi(aResult, index)
    {
        return <SearchMetricsSummaryViewPrompt 
            metric={aResult} 
            key={index} 
            setSearchText={this.setSearchText}
        />
    }

    getChartDataset()
    {
        const data = [];
        data.push([
            'Request',
            'High',
            'Average',
            'Low',
            'Extra'
        ]);

        this.state.results.forEach(item => {
            data.push([
                item.name,
                item.highDurationMS / 1000,
                item.avgDurationMS / 1000,
                item.lowDurationMS / 1000,
                item.lowDurationMS / 1000
            ]);
        });
        return data;
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : 
            <div>
                <CandlestickChartPanel
                    title="Request Performance in MS"
                    dataset={this.getChartDataset()}
                />
                <table>
                    <Header />
                    <tbody>
                        {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
                    </tbody>
                </table>
            </div>
    }
}

const SearchMetricsSummaryPrompt = connect(mapStateToProps, mapDispatchToProps)(_SearchMetricsSummaryPrompt);
export default SearchMetricsSummaryPrompt;
