import React from 'react';
import MenuList from './core/menu-list';
import { connect } from 'react-redux';
import mapStateToProps from './../../store/map/is-user';
import mapDispatchToProps from './../../store/map/on-logout';
import ErrorService from './../../service/error';
import NoticeService from '../../service/notice';
import AccountIcon from './../../img/menu-accountWhite.svg';
import HomeIcon from './../../img/menu-homeWhite.svg';
import ExchangeIcon from './../../img/menu-exchangeWhite.svg';
import AdminIcon from './../../img/menu-adminWhite.svg';
import JoinIcon from './../../img/menu-join-white.svg';
import SearchIcon from './../../img/menu-searchWhite.svg';
import theme from './../theme';


class _MainMenu extends React.Component
{
    constructor(parms)
    {
        super(parms);
        this.getGuestUi = this.getGuestUi.bind(this);
        this.getUserUi = this.getUserUi.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadUnseenNoticeCount = this.loadUnseenNoticeCount.bind(this);
        this.onLoadUnseenNoticeCountResponse = this.onLoadUnseenNoticeCountResponse.bind(this);
        this.onLoadUnseenNoticeCountError = this.onLoadUnseenNoticeCountError.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    // istanbul ignore next
    onAuthError()
    {
        this.props.onLogout();
    }

    // istanbul ignore next
    onFatalError()
    {
        // disable fatal error on notice polling
        //this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    // istanbul ignore next
    getDefaultState()
    {
        return {
            unseenNoticeCount: this.props.unseenNoticeCount,
        };
    }

    componentDidMount()
    {
        this.loadUnseenNoticeCount();
    }

    loadUnseenNoticeCount()
    {
        if(this.props.authToken !== '')
        {
            NoticeService.getUnseenNoticeCount({
                onResponse: this.onLoadUnseenNoticeCountResponse,
                onError: this.onLoadUnseenNoticeCountError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
    }

    // istanbul ignore next
    onLoadUnseenNoticeCountResponse(response)
    {
        this.props.onUpdateUnseenNoticeCount(response.unseenNoticeCount);
    }

    // istanbul ignore next
    onLoadUnseenNoticeCountError(error)
    {
        const errorHandler = ErrorService.makeHandle({
            onError: (error) => {},
            onNetworkOffline: this.onNetworkOffline
        });
        errorHandler(error);
    }

    getGuestUi()
    {
        return <MenuList 
            list={[
                {
                    to: '/',
                    title: 'Dashboard',
                    customStyle: {
                        ...theme.getMenuStyle(theme, HomeIcon),
                    },
                },
                {
                    to: '/join',
                    title: 'Join',
                    customStyle: {
                        ...theme.getMenuStyle(theme, JoinIcon),
                    },
                },
            ]} 
            isMainMenu={true}
        />
    }

    getUserUi()
    {
        let list = [
            {
                to: '/',
                title: 'Dashboard',
                customStyle: {
                    ...theme.getMenuStyle(theme, HomeIcon),
                },
            },
            {
                to: '/posts',
                title: 'Offers & Seeks',
                customStyle: {
                    ...theme.getMenuStyle(theme, SearchIcon),
                },
            },
            {
                to: '/exchanges',
                title: 'Exchanges',
                customStyle: {
                    ...theme.getMenuStyle(theme, ExchangeIcon),
                },
            },
            {
                to: '/profile',
                title: 'Account',
                customStyle: {
                    ...theme.getMenuStyle(theme, AccountIcon),
                },
            },
        ];

        // istanbul ignore next
        if(this.props.isAdmin)
        {
            list.push({
                to: '/admin',
                title: 'Admin',
                customStyle: {
                    ...theme.getMenuStyle(theme, AdminIcon),
                },
            });
        }
        
        return (
            <MenuList 
                list={list}  
                showLoggedInControls={true}
                onLogout={this.props.onLogout}
                isMainMenu={true}
            />
        );
    }

    render()
    {
        return this.props.isUser ? this.getUserUi() : this.getGuestUi();
    }
}

const MainMenu = connect(mapStateToProps, mapDispatchToProps)(_MainMenu);

export default MainMenu;
