export const getUserOffersPanelStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    marginBottom: '2rem',
});

export const getUserOffersPanelItemStyle = (theme) => ({
    marginBottom: '1.5rem',
    borderBottomColor: '#4b3908',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
});

export const getUserOffersPanelTitleStyle = (theme) => ({
    margin: '.8rem',
    wordWrap: 'break-word',
    width: '10rem',
});

export const getUserOffersPanelTimeStyle = (theme) => ({
    paddingLeft: '1rem',
});