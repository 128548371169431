import React from 'react';
import AdminPageTemplate from './template/admin-page';
import TestEmailsPrompt from './../ui/prompt/admin/test-emails';
import theme from './../ui/theme';

const AdminTestEmailPage = () => 
    <AdminPageTemplate>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h2>
                Send Test Email
            </h2>
            <p>
                DO NOT over use this feature, it may exceed daily limits for production emails of active users.
            </p>

            <TestEmailsPrompt />

        </div>
    </AdminPageTemplate>

export default AdminTestEmailPage;
