import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import theme from './../../theme';

// default method to parse item to ux
const defaultItemToUx = ({ value }) => <li> { value } </li>

// default method to parse item to key
const defaultItemToKey = (value) => `item-${value}`;

const SortListPrompt = ({ 
    onSortUpdated, 
    setItems,
    items = [], 
    itemToUx = defaultItemToUx, 
    itemToKey = defaultItemToKey,
})  => {
    /* istanbul ignore next */
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const nextList = arrayMoveImmutable(items, oldIndex, newIndex);
        setItems(nextList);
        onSortUpdated(nextList);
    };
    const SortableItem = SortableElement(itemToUx);
    const parseToSortableItem = (value, index) => <SortableItem key={ itemToKey(value) } index={index} value={value} />
    const sortableListStyles = theme.getSortListContainerStyle(theme);
    const SortableList = SortableContainer(({ items }) => 
        <div style={sortableListStyles}>
            {
                items.map(parseToSortableItem)
            }
        </div>
    );
    return <SortableList items={items} onSortEnd={onSortEnd} />
};

export default SortListPrompt;
