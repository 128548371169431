import React from 'react';
import AuthPageTemplate from './template/auth-page';
import PayoutsPanel from './../ui/panel/payouts';
import theme from './../ui/theme';
import ProfileMenu from './../ui/menu/profile';

const PayoutsPage = () => 
    <AuthPageTemplate 
        childMenu={
            <div>
                <ProfileMenu />
            </div>
    }>
        <div style={{
                ...theme.getGeneralTextStyle(theme),
        }}>
            <PayoutsPanel />
        </div>
    </AuthPageTemplate>


export default PayoutsPage;