import ValidateService from './../../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../../../prompt/core/abstract';
import mapStateToProps from './../../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../../store/map/on-logout';
import { connect } from 'react-redux';

class _PriceControls extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _PriceControls.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.validateState = this.validateState.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.controlValueChange = this.controlValueChange.bind(this);
        this.isUsingSubmitButton = this.isUsingSubmitButton.bind(this);
        this.validator.estimatedDollarsPerGallon.isValid = ValidateService.makeCashAmountField(this);
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            estimatedMPG: {
                isValid: ValidateService.numeric
            },
            estimatedDollarsPerGallon: {
                isValid: ValidateService.numeric
            },
        };
    }

    isUsingSubmitButton() 
    {
        return false;
    }

    /* istanbul ignore next */
    onFormSubmit(e)
    {
        e.preventDefault();
        if(this.state.isValid){
            this.onSendToServer();
        }
    }

    controlValueChange(name, value, entity = null)
    {
        let updateState = this.state;
        const valid = this.validator[name].isValid(value, updateState);
        updateState[name] = {
            ...updateState[name],
            value,
            valid,
            touched: true
        };

        /* istanbul ignore next */
        if(entity) {
            updateState[name].entity = entity;
        }

        const overallState = this.evaluateFieldsVisibility(updateState);

        const finalState = this.validateState(overallState);

        this.setState(finalState);

        if(finalState.isValid)
        {
            this.onSendToServer();
        }
    }

    getFieldsModel()
    {
        return {
            estimatedMPG: {
                name: 'Estimated Fuel Miles Per Gallon',
                error: 'Provide an estimated miles per gallon to help craft your price',
                value: this.props.iniEstimatedMPG,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            estimatedDollarsPerGallon: {
                name: 'Estimated Fuel Dollars Per Gallon',
                error: 'Provide an estimated dollars per gallon of fuel',
                value: this.props.iniEstimatedDollarsPerGallon,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    getServerRequest()
    {
        return {
            estimatedMPG: this.state.estimatedMPG.value,
            estimatedDollarsPerGallon: this.state.estimatedDollarsPerGallon.value
        };
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        if(this.props.onUpdated)
        {
            this.props.onUpdated(this.getServerRequest());
        }
    }

    /* istanbul ignore next */
    onAuthError()
    {
        this.props.onLogout();
    }

    /* istanbul ignore next */
    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }
}

const PriceControls = connect(mapStateToProps, mapDispatchToProps)(_PriceControls);
export default PriceControls;
