import React from 'react';
import UiService from './../../../../service/ui';

export const Header = () => 
    <thead>
        <tr>
            <td>
                Recorded On
            </td>
            <td>
                Status
            </td>
            <td>

            </td>
        </tr>
    </thead>

export const SearchApiLogViewPrompt = ({ logRecord }) => 
    <tr>
        <td>
            { UiService.getStoryDate(logRecord.createdOn) }
        </td>
        <td>
            { logRecord.status }
        </td>
        <td>
            {
                logRecord.pauseApiRequests ? 
                    <span>
                        Restart Requests at &nbsp;
                        { UiService.getStoryDate(logRecord.restartRequestsOn) }
                    </span> : ''
            }
        </td>
    </tr>

export default SearchApiLogViewPrompt;
