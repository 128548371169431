import React from 'react';
import { Chart } from "react-google-charts";

/*
dataset = [
    [ "Time", "Open", "Account" ],
    [ "All Time", "100", "20" ]
];
*/
const BarChartPanel = ({ 
    title, 
    dataset,
    width = '100%', 
    height = '400px' 
}) => {
    const options = {
        chart: {
            title
        }
    };
    return (
        <Chart
            chartType="Bar"
            width={width}
            height={height}
            data={dataset}
            options={options}
        />
    );
};

export default BarChartPanel;
