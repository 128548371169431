export const getLoadingStyle = (theme, icon) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '435px',
    height: '435px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${icon})`,
    paddingTop: '40px',
    fontWeight: 'bolder',
});

export const getSplashContactingStyle = (theme, icon) => ({
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${icon})`,
    paddingTop: '40px',
    fontWeight: 'bolder',
    zIndex: '999999999999999999999'
});
