export const getWidgetPendingBidsStyle = (theme) => ({
    cursor: 'pointer',
    padding: '.75rem 1.2rem',
    textDecorationLine: 'none',
    borderRadius: theme.isTablet() ? '.25rem' : '0',
    borderColor: theme.panelSecondary,
    textDecoration: 'none',
    backgroundColor: theme.buttonColor,
    textAlign: 'center',
     //:hover      color: theme.selectColorLight,
    //:hover      backgroundColor: theme.panelSecondary,
    //:onclick   color: theme.selectColorLight,
    //:onclick   backgroundColor: theme.panelSecondary,
    transition: 'all 0.2s',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)'
});