import React from 'react';
import DetailPanel from './../core/detail';
import OfferService from './../../../service/offer';
import BidService from './../../../service/bid';
import UiService from './../../../service/ui';
import OfferDetailViewPanel from './view';
import SplashContactingServerPanel from './../core/splash-contacting-server';
import UnableToLoadTargetPanel from './../core/unable-to-load-target';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';

class _OfferDetailPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onArchiveOffer = this.onArchiveOffer.bind(this);
        this.onArchiveOfferResponse = this.onArchiveOfferResponse.bind(this);
        this.setBids = this.setBids.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onBidPageResponse = this.onBidPageResponse.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
        this.onContactingPayServer = this.onContactingPayServer.bind(this);
        this.onContactingPayServerDone = this.onContactingPayServerDone.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            bids: [],
            unableToLoadTarget: false,
            paidAccount: false,
            contactingPayServer: false
        };
    }

    /* istanbul ignore next */
    onContactingPayServer()
    {
        let nextState = this.state;
        nextState.contactingPayServer = true;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onContactingPayServerDone()
    {
        let nextState = this.state;
        nextState.contactingPayServer = false;
        this.setState(nextState);
    }
    
    /* istanbul ignore next */
    setBids(bids)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            data: this.state.data ? {
                ...this.state.data,
                bids,
            }: undefined,
            bids,
        };
        this.setState(nextState);
    }

    onLoadDataResponse(response)
    {
        if(response.exchangeId)
        {
            const url = `/exchange/${response.exchangeId}`;
            this.sendUserTo(url);
        }
        
        super.onLoadDataResponse(response);
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load offer details')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        OfferService.getOfferDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        /* istanbul ignore next */
        BidService.listBidsByOffer({
            request: {
                offerId: this.props.offerId,
                page,
            },
            onResponse: this.onBidPageResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onBidPageResponse(response)
    {
        /* istanbul ignore next */
        this.setBids(response.bids);
    }

    getLoadDataRequest(inputObj)
    {
        return {
            offerId: inputObj.offerId
        };
    }

    componentDidMount()
    {
        const offerId = this.props.offerId;
        this.loadData({ offerId });
        UiService.scrollToTop();
    }

    onArchiveOffer(offer)
    {
        /* istanbul ignore next */
        super.loadData({ });
        /* istanbul ignore next */
        OfferService.archiveOffer({
            request: OfferService.getArchiveOfferRequest(offer.id),
            onResponse: this.onArchiveOfferResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });

    }

    onArchiveOfferResponse(response)
    {
        /* istanbul ignore next */
        this.sendUserTo('/offer/add');
    }

    getUnLoadedDataDetailUi()
    {
        return this.state.unableToLoadTarget ? <UnableToLoadTargetPanel title="Offer" /> : super.getUnLoadedDataDetailUi();
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                {
                    /* istanbul ignore next */
                    this.state.contactingPayServer && 
                        <SplashContactingServerPanel />
                }
                <OfferDetailViewPanel 
                    setBids={this.setBids} 
                    data={this.state.data} 
                    setPayClientSecret={this.props.setPayClientSecret}
                    viewingUserId={this.props.userId} 
                    onArchiveItem={this.onArchiveOffer} 
                    onContactingServer={this.onContactingPayServer}
                    onContactingServerResponse={this.onContactingPayServerDone}
                    onLoadSpecifiedPage={this.onLoadSpecifiedPage} 
                />
            </section>
        );
    }
}

const OfferDetailPanel = connect(mapStateToProps, mapDispatchToProps)(_OfferDetailPanel);

export default OfferDetailPanel;
