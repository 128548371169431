import React from 'react';
import LoadingIcon from './../../../img/loading.gif';
import theme from './../../theme';

const LoadingPanel = () => 
    <div style={{
        ...theme.getLoadingStyle(theme, LoadingIcon),
    }}>
    </div>

export default LoadingPanel;
