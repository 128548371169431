import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminReviewFlagsPanel from './../ui/panel/admin/admin-review-flags';

const AdminReviewFlagsPage = () => 
    <AdminPageTemplate>
        <AdminReviewFlagsPanel />
    </AdminPageTemplate>

export default AdminReviewFlagsPage;
