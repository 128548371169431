export const getUserSettingsViewPanelStyle = (theme) => ({
    width: '100%',
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline'
});

export const getUserEmailViewPanelStyle = (theme) => ({
    width: '100%',
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

export const getUserSettingsViewPanelLeftStyle = (theme) => ({
    alignItems: 'center'
});

export const getUserSettingsASettingLineStyle = (theme) => ({
    paddingBottom: '1.5rem',
    textAlign: 'left'
});

export const getUserChangeCurrentEmailStyle = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    marginBottom: '1rem',
});

export const getUserChangeEmailModalStyle = (theme) => ({
    width: '100%',
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    textAlign: 'center',
    height: 'fit-content',
    padding: '0 .75rem 1.25rem',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});