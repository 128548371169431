import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminPayoutsSearchPrompt from './../ui/prompt/admin-payouts-search';
import theme from './../ui/theme';

const AdminPayoutsPage = () => 
    <AdminPageTemplate>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h2>
                Manage System Payouts
            </h2>
            
            <AdminPayoutsSearchPrompt />
        </div>
    </AdminPageTemplate>

export default AdminPayoutsPage;
