import CarrierMemberService from './../../../service/carrier-member';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import CamImgSelectorPrompt from './../core/cam-img-selector';

class _AddCarrierMemberPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _AddCarrierMemberPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getServerFileList = this.getServerFileList.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.validateState = this.validateState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAddImg = this.onAddImg.bind(this);
        this.onRemoveImg = this.onRemoveImg.bind(this);
        this.openSavedImgDialog = this.openSavedImgDialog.bind(this);
        this.onCloseSavedImgDialog = this.onCloseSavedImgDialog.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            name: {
                isValid: ValidateService.textLine
            },
            contactEmail: {
                isValid: ValidateService.email
            },
            contactPhone: {
                isValid: ValidateService.phone
            },

        };
    }

    getFieldsModel()
    {
        return {
            name: {
                name: '*Legal Name',
                error: 'You must specify your name as it will appear to others associated with deliveries',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            contactEmail: {
                name: '*Public Email',
                error: 'You must specify an email point of contact',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            contactPhone: {
                name: '*Public Phone',
                error: 'You must specify a phone number',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            }
        };
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            name: this.state.name.value,
            contactEmail: this.state.contactEmail.value,
            contactPhone: this.state.contactPhone.value,
            memberUserId: this.props.selectedUserId ? this.props.selectedUserId : this.state.selectedUserId,
            carrierGroupId: this.props.carrierGroupId,
            photo: this.state.selectedImages[0] && this.state.selectedImages[0].isScreenShot ? this.state.selectedImages[0].file : undefined
        };
    }

    /* istanbul ignore next */
    getServerFileList()
    {
        return this.state.selectedImages[0] && !this.state.selectedImages[0].isScreenShot ? 
            this.state.selectedImages : [];
    }

    onSendToServer()
    {
        CarrierMemberService.addMember({
            request: this.getServerRequest(),
            fileList: this.getServerFileList(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken, 
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        if(this.props.onCarrierMemberAdded)
        {
            this.props.onCarrierMemberAdded(response);
        }
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            selectedUserId: null,
            selectedImages: [],
            savedImgDialogOpen: false,
        };
    }

    getConfirmUi(confirmMessage = 'Member Added')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    validateState(state)
    {
        let baseReturn = super.validateState(state);

        /* istanbul ignore next */
        if(!this.props.selectedUserId && baseReturn.selectedUserId === null)
        {
            baseReturn.isValid = false;
        }

        /* istanbul ignore next */
        if(baseReturn.selectedImages.length < 1)
        {
            baseReturn.isValid = false;
        }
        
        return baseReturn;
    }

    onAddImg(img)
    {
        let nextState = this.state;
        /* istanbul ignore next */
        if(nextState.selectedImages.length > 0)
        {
            nextState.selectedImages[0] = img;
        }
        else 
        {
            nextState.selectedImages.push(img);
        }

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onRemoveImg(delIndex)
    {
        let nextState = this.state;
        nextState.selectedImages = [];
        this.setState(nextState);
        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    openSavedImgDialog()
    {
        let nextState = this.state;
        nextState.savedImgDialogOpen = true;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCloseSavedImgDialog(event)
    {
        let nextState = this.state;
        nextState.savedImgDialogOpen = false;
        this.setState(nextState);
    }

    getPromptUi()
    {
        return (
            <div>
                <CamImgSelectorPrompt 
                    imgList={this.state.selectedImages} 
                    onAddImg={this.onAddImg}
                    onRemoveImg={this.onRemoveImg}
                    openSavedImgDialog={this.openSavedImgDialog}
                    onCloseSavedImgDialog={this.onCloseSavedImgDialog}
                    savedImgDialogOpen={this.state.savedImgDialogOpen}
                    onAddSavedImg={this.onAddImg}
                />
                {
                    super.getPromptUi()
                }
            </div>
        );
    }
}

const AddCarrierMemberPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddCarrierMemberPrompt);
export default AddCarrierMemberPrompt;
