import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ExchangeDetailPanel from './../ui/panel/exchange-detail';
import { useParams } from 'react-router-dom';

const ExchangeDetailPage = () => {
    const { exchangeId } = useParams();
    return (
        <AuthPageTemplate>
            <ExchangeDetailPanel exchangeId={exchangeId} />
        </AuthPageTemplate>
    );
};

export default ExchangeDetailPage;
