import React from 'react';
import theme from './../../theme';
import { UserPlatformIcon } from './../user-search/view';

const UserSearchViewPrompt = ({ 
    user, 
    onUserSelected
}) => 
    <div style={{
        ...theme.getUserSearchResultStyle(theme)
    }}>
        <div style={{
            ...theme.getUserSearchResultUsernameStyle(theme),
        }}>
            { user.username }
        </div>

        <div style={{
            ...theme.getUserSearchResultPlatformContainerStyle(theme),
        }}>
            {
                user.trailList.map((aTrail, index) => <UserPlatformIcon trail={aTrail} key={index} /> )
            }
        </div>

        <button 
            className="test-selectUserBtn"
            onClick={event => { onUserSelected(user) }}
        >
            Select
        </button>
    </div>

export default UserSearchViewPrompt;
