import React from 'react';
import theme from './../../../theme';
import { getStepCopy, getStepLocation } from './../core';
import {
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';

const AddTripStop = ({
    trip,
    step,
    onHookTrip,
    cargoItem,
    delivery,
    addIndex = null
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        
        <button 
            className="test-carrierGroupPlanDeliveryAlignTripsAddStopBtn"
            onClick={event => { onHookTrip({
                cargoItemId: cargoItem.id,
                deliveryId: delivery.id,
                stepId: step.stepId,
                location: getStepLocation(step),
                isPickup: [
                    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
                    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
                ].includes(step.type),
                addIndex,
                tripId: trip.id
        }) }}>
            Add Stop at {getStepCopy(step)}
        </button>
        <hr />
    </div>

export default AddTripStop;
