import React from 'react';
import DetailPanel from './../core/detail';
import SeekService from '././../../../service/seek';
import UserSeeksViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _UserSeeksPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onLoadDataResponse(response)
    {
        /* istanbul ignore next */
        if(this.props.setHasSeeks)
        {
            const hasSeeks = response.list.length > 0;
            this.props.setHasSeeks(hasSeeks);
        }
        super.onLoadDataResponse(response);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        SeekService.getUserListings({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            detailsUserId: inputObj.userId,
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        const userId = this.props.userId;
        this.loadData({
            userId,
            page: 0
        });
    }

    getLoadedDataDetailUi()
    {
        return <UserSeeksViewPanel data={this.state.data} />
    }
}

const UserSeeksPanel = connect(mapStateToProps, mapDispatchToProps)(_UserSeeksPanel);
export default UserSeeksPanel;
