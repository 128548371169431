import React from 'react';
import theme from './../../../theme';

const SelectedTagView = ({ tag }) => 
    <div style={{
        ...theme.getTagFilterRightStyle(theme)  
    }}>
        <div>
            &nbsp;|&nbsp;
        </div>

        { tag }

        <div>
            &nbsp;|&nbsp;
        </div>
    </div>

const SelectedListView = ({ tagList }) => 
    <div >
        {
            tagList.map((aTag, index) => <SelectedTagView tag={aTag} key={index} />)
        }
    </div>

const PossibleTagView = ({ onAddTag, onRemoveTag, tag, tagList }) => {
    const isSelected = tagList.some(tagName => tagName === tag.title) ? true : false;
    const changeMethod = isSelected ? onRemoveTag : onAddTag;
    return (
        <div style={{
            ...theme.getTagListPossibleTagViewStyle(theme)
        }}>
            <input 
                type="checkbox"
                className="test-coreTagListPossibleTagCb"
                checked={isSelected}
                onChange={event => { changeMethod(tag.title) } }
            />
            {
                tag.title
            }
        </div>
    );
};

const AllListView = ({ data, onAddTag, onRemoveTag, tagList }) => 
    <div>
        {
            data.list.map((aTag, index) => 
                <PossibleTagView 
                    key={index}
                    tag={aTag} 
                    onAddTag={onAddTag} 
                    onRemoveTag={onRemoveTag}
                    tagList={tagList}
                />
            )
        }
    </div>

const PopulatedView = ({ data, onAddTag, onRemoveTag, tagList }) => 
    <div style={{
        ...theme.getTagListContainerStyle(theme)
    }}>
        <div style={{
            ...theme.getTagListAllListContainerStyle(theme)
        }}>
            <AllListView 
                data={data}
                onAddTag={onAddTag}
                onRemoveTag={onRemoveTag}
                tagList={tagList}
            />
        </div>
        <div style={{
            ...theme.getTagListSelectedListContainerStyle(theme)
        }}>
            <SelectedListView 
                tagList={tagList}
            />
        </div>
        <div style={{ clear: 'both' }}></div>
    </div>

const EmptyView = (parms) => 
    <section>
        <p>
            Unable to load tags.
        </p>
    </section>

const TagListViewPrompt = ({ data, onAddTag, onRemoveTag, tagList }) => data.list.length < 1 ? <EmptyView /> : 
    <PopulatedView
        data={data}
        onAddTag={onAddTag}
        onRemoveTag={onRemoveTag}
        tagList={tagList}
    />

export default TagListViewPrompt;
