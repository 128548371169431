import React from 'react';
import AuthPageTemplate from './template/auth-page';
import TwoFAPanel from './../ui/panel/two-fa';
import ProfileMenu from './../ui/menu/profile';

const ProfileUpdate2FAPage = () => {
    return (
        <AuthPageTemplate childMenu={
            <div>
                <ProfileMenu />
            </div>
        }>
            <TwoFAPanel />
        </AuthPageTemplate>
    );
};

export default ProfileUpdate2FAPage;
