import React from 'react';
import PayPrompt from './../prompt/pay';
import theme from './../theme';
import { EXCHANGE_TYPE_ONLINE } from './../prompt/core/enum/exchange-type';

const AcceptedView = () => 
    <span>
        Bid Accepted
    </span>

const RejectedView = () => 
    <span>
        Bid Rejected
    </span>

const PendingView = ({ bid, onRejectBid, onAcceptBid, setPayClientSecret, onContactingServerResponse, onContactingServer }) => 
    <div>
        
        <div style={{
            ...theme.getBidReviewerPayButtonStyle(theme)
        }}>
            <button 
                className="test-bidReviewerReject"
                onClick={event => { onRejectBid() }}
            >
                Reject
            </button>
        </div>
        
        {
            bid.exchangeMethod === EXCHANGE_TYPE_ONLINE ? 
                <PayPrompt
                    buttonStyle={{
                        ...theme.getBidReviewerPayButtonStyle(theme)
                    }}
                    setPayClientSecret={setPayClientSecret}
                    onContactingServerResponse={onContactingServerResponse}
                    onContactingServer={onContactingServer}
                    content={{
                        ...bid.target,
                        obtainTypeDetailList: bid.obtainMethod && bid.target.obtainTypeDetailList ?  
                            bid.target.obtainTypeDetailList.filter(ob => ob.type === bid.obtainMethod) : 
                                bid.target.obtainTypeDetailList,
                        exchangeList: [
                            {
                                type: EXCHANGE_TYPE_ONLINE,
                                currency: bid.currency,
                                asking: bid.title
                            }
                        ]
                    }}
                    contentType={bid.targetType} 
                    bidId={bid.id}
                /> :
                <div style={{
                    ...theme.getBidReviewerPayButtonStyle(theme)
                }}>
                    <button 
                        className="test-bidReviewerAccept"
                        onClick={event => { onAcceptBid() }}
                    >
                        Accept
                    </button>
                </div>
        }
    </div>

const BidReviewerMenu = ({ 
    bid, 
    onRejectBid, 
    onAcceptBid, 
    setPayClientSecret,
    onContactingServerResponse,
    onContactingServer
}) => 
    <section>
        { 
            bid.rejected ? RejectedView() : (
                bid.accepted ? AcceptedView() : PendingView({ bid, onRejectBid, onAcceptBid, setPayClientSecret, onContactingServerResponse, onContactingServer })
            )
        }
    </section>

export default BidReviewerMenu;
