import CrawlerUtil from './util';
import iniOnboardingCrawler from '../../../../store/state/ini-onboarding-crawler';

export class CrawlerBuilder {
    
    static getNextCrawlerMore_isEnd(crawler)
    {
        return crawler.moreCrawler === null;
    }

    static getNextCrawlerMore(moreIndex, crawler)
    {
        const isEnd = CrawlerBuilder.getNextCrawlerMore_isEnd(crawler);
        return {
            index: crawler.index,
            moreCrawler: isEnd ? CrawlerBuilder.getAddedNextMoreCrawler() : 
                CrawlerBuilder.getNextCrawlerMore(moreIndex, crawler.moreCrawler),
            moreIndex: isEnd ? moreIndex : 
                crawler.moreIndex
        };
    }

    static getNextCrawlerDrop_isEnd(crawler)
    {
        return !crawler || !crawler.moreCrawler || crawler.moreCrawler.moreCrawler === null;
    }

    static getNextCrawlerDrop(crawler)
    {
        const isEnd = CrawlerBuilder.getNextCrawlerDrop_isEnd(crawler);
        return {
            index: crawler.index,
            moreCrawler: isEnd ? null : 
                CrawlerBuilder.getNextCrawlerDrop(crawler.moreCrawler),
            moreIndex: isEnd ? null : 
                crawler.moreIndex
        };
    }

    static getNextCrawlerAdjust_isEnd(crawler)
    {
        return crawler.moreCrawler === null;
    }

    static getNextCrawlerAdjust_adjustIndex(crawler, increment = true)
    {
        return increment ? crawler.index + 1 :
            crawler.index - 1;
    }

    static getNextCrawlerAdjust(crawler, increment = true)
    {
        const isEnd = CrawlerBuilder.getNextCrawlerAdjust_isEnd(crawler);
        return {
            index: isEnd ? 
                CrawlerBuilder.getNextCrawlerAdjust_adjustIndex(crawler, increment) :
                    crawler.index,
            moreCrawler: isEnd ? 
                crawler.moreCrawler :
                    CrawlerBuilder.getNextCrawlerAdjust(crawler.moreCrawler, increment),
            moreIndex: crawler.moreIndex

        };
    }
    
    static getPrev_nextCrawler(crawler, topCrawler)
    {
        // on base list, move up one
        if(crawler.isTop) 
        {
            return {
                index: crawler.index - 1,
                moreCrawler: null,
                moreIndex: null
            };
        }

        if(crawler.index > 0)
        {
            return CrawlerBuilder.getNextCrawlerAdjust(topCrawler, false);
        } else {
            return CrawlerBuilder.getNextCrawlerDrop(topCrawler);
        }
    }
    
    static getPrev(crawler, topCrawler)
    {
        return CrawlerUtil.isNoPrev(crawler) ?
            iniOnboardingCrawler : 
                CrawlerBuilder.getPrev_nextCrawler(crawler, topCrawler);

    }

    static getNext_nextCrawler(map, crawler, topCrawler)
    {
        if(crawler.isTop) 
        {
            return {
                index: crawler.index + 1,
                moreCrawler: null,
                moreIndex: null
            };
        }

        if(CrawlerUtil.isNextBack(map, crawler))
        {
            return CrawlerBuilder.getNextCrawlerDrop(topCrawler);
        }
        else 
        {
            return CrawlerBuilder.getNextCrawlerAdjust(topCrawler, true);
        }
    }

    static getNext(map, crawler, topCrawler)
    {
        return CrawlerUtil.isNextExit(map, crawler) ? 
            iniOnboardingCrawler : 
                CrawlerBuilder.getNext_nextCrawler(map, crawler, topCrawler);
    }

    static getAddedNextMoreCrawler()
    {
        return {
            index: 0,
            moreCrawler: null,
            moreIndex: null
        };
    }

    static getMore_nextCrawler(moreIndex, crawler, topCrawler)
    {
        if(crawler.isTop)
        {
            return {
                index: crawler.index,
                moreCrawler: CrawlerBuilder.getAddedNextMoreCrawler(),
                moreIndex
            };
        }

        return CrawlerBuilder.getNextCrawlerMore(moreIndex, topCrawler);
    }

    static getMore(moreIndex, crawler, topCrawler)
    {
        return CrawlerBuilder.getMore_nextCrawler(moreIndex, crawler, topCrawler);
    }
}

export default CrawlerBuilder;
