import React from 'react';
import { useState } from 'react';
import DeliveryCarrierTypeEnum from './../../prompt/core/enum/delivery-carrier-type';
import SentPackageAssignShipCompanyPrompt from './../../prompt/sent-package-assign-ship-company';
import FinishSetupDeliveryView from './finish-setup-delivery-view';
import SetupDeliveryMainMenu from './setup-delivery-main-menu';
import ScheduleSetupDelivery from './schedule-setup-delivery';

/* istanbul ignore next */
const SentPackageSetupDelivery = ({ clearView, delivery, onLoadDataResponse }) => 
    <div>
        <div>
            <button onClick={clearView}>
                Back
            </button>
        </div>
        <SentPackageAssignShipCompanyPrompt delivery={delivery} onLoadDataResponse={onLoadDataResponse} />
    </div>

const SelectedSetupDelivery = ({ 
    selectedView, 
    clearView, 
    delivery, 
    onLoadDataResponse 
}) => {
    /* istanbul ignore next */
    return selectedView === 'schedule' ? 
        <ScheduleSetupDelivery 
            clearView={clearView} 
            delivery={delivery}
            onLoadDataResponse={onLoadDataResponse}
        /> : 
            <SentPackageSetupDelivery 
                clearView={clearView} 
                delivery={delivery} 
                onLoadDataResponse={onLoadDataResponse}
            />
};


const SetupDeliveryView = ({ 
    exchange, 
    onLoadDataResponse, 
    onProvidedToShipping 
}) => {
    const delivery = exchange.deliveryDetails.list[0];
    const startSelectedView = delivery.inNetworkCarriers.bidRequestedCarrierGroupIdList.length > 0 ? 'schedule' : null;
    const [ selectedView, setSelectedView ] = useState(startSelectedView);
    const clearView = event => { setSelectedView(null) };

    return ![
        DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_UNASSIGNED,
        DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_SELF
    ].includes(delivery.carrierType) ? 
        <FinishSetupDeliveryView 
            delivery={delivery} 
            onProvidedToShipping={onProvidedToShipping} 
        /> : (
            selectedView ? 
                <SelectedSetupDelivery 
                    selectedView={selectedView} 
                    clearView={clearView}
                    delivery={delivery}
                    onLoadDataResponse={onLoadDataResponse}
                /> : 
                    <SetupDeliveryMainMenu 
                        setSelectedView={setSelectedView} 
                    />
            )
};

export default SetupDeliveryView;
