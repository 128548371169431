import React from 'react';
import DetailPanel from './../core/detail';
import AttSetService from './../../../service/att-set';
import AttSetDetailViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import UnableToLoadTargetPanel from './../core/unable-to-load-target';

class _AttSetDetailPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);

        this.state = this.getDefaultState();
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            unableToLoadTarget: false,
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onRemove()
    {
        const attSetId = this.props.attSetId;
        super.loadData({ });
        AttSetService.remove({
            request: { 
                attSetId 
            },
            onResponse: (response) => {
                this.sendUserTo('/profile/att-sets')
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        AttSetService.getDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            attSetId: inputObj.attSetId
        };
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load attset')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    getUnLoadedDataDetailUi()
    {
        return this.state.unableToLoadTarget ? <UnableToLoadTargetPanel title="Field Set" /> : super.getUnLoadedDataDetailUi();
    }

    componentDidMount()
    {
        const attSetId = this.props.attSetId;
        this.loadData({ attSetId });
    }

    getLoadedDataDetailUi()
    {
        return <AttSetDetailViewPanel onRemove={this.onRemove} data={this.state.data} />
    }
}

const AttSetDetailPanel = connect(mapStateToProps, mapDispatchToProps)(_AttSetDetailPanel);
export default AttSetDetailPanel;
