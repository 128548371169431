export const getAdminDataCellStyle = (theme) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0 .5rem',
    textAlign: 'left'
});

export const getClientStatChartStyle = (theme) => ({
    width: '16rem',
    height: '16rem',
    marginLeft: 'auto',
    marginRight: 'auto'
});

export const getPayoutTransactionStyle = (theme) => ({
    maxWidth: '60rem',
    height: 'auto',
    margin: 'auto'
});

export const getPayoutTransactionContainerStyle = (theme) => ({
    height: 'auto',
    overflow: 'scroll hidden',
    padding: '.25rem 0 1rem',
    textAlign: 'left'
});