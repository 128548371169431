import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateMenuState } from './../../../../store/state/action';
import theme from './../../../theme';
import ToggleFullMenu from '../toggle-full-menu';
import NoticesMenu from '../notices-menu';
import isLoggedIn from '../../../../store/select/is-logged-in';
import LogoutIcon from '../../../../img/menu-logout-white.svg';
import { Logout } from '../../../../store/state/action';
import ResizeService from '../../../../service/resize';
import SmallListMenu from './small-list-menu';
import { ShowMenuContext } from './index';

/* istanbul ignore next */
const SmallListMenuBackDrop = () => {
    const dispatch = useDispatch();
    const setShowFullMenu = (showFull) => {
        dispatch(UpdateMenuState(showFull));
    };
    const onClick = event => {
        setShowFullMenu(true);
    };
    return (
        <div 
            className="test-smallMenuBackDrop"
            style={{ ...theme.getSmallMenuBackDropStyle(theme) }}
            onClick={onClick}    
        ></div>
    );
};

const SmallMenu = () => {
    const dispatch = useDispatch();
    const onLogout = event => {
        dispatch(Logout());
    };
    const loggedIn = useSelector(isLoggedIn);
    const showMenu = useContext(ShowMenuContext);

    /* istanbul ignore next */
    const getSmallMainMenuDockStyle = () => theme.getSmallMainMenuDockStyle(theme);
    const [ smallMainMenuDockStyle, setSmallMainMenuDockStyle ] = useState( getSmallMainMenuDockStyle() );

    /* istanbul ignore next */
    const getMenuStyle = () => showMenu ? theme.getSmallMenuContainerStyle(theme) : theme.getHiddenSmallMenuContainerStyle(theme);
    const [menuStyle, setMenuStyle ] = useState( getMenuStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setMenuStyle( getMenuStyle() );
        setSmallMainMenuDockStyle( getSmallMainMenuDockStyle());
    });
    useEffect(resizeEffect);

    useEffect(() => {
        setMenuStyle( getMenuStyle() );
        // eslint-disable-next-line
    }, [showMenu]);

    return ( 
        <div style={menuStyle}>
            <div style={smallMainMenuDockStyle}>
                {
                    loggedIn && 
                    <div 
                        className="test-smallMenuLogout"
                        title="Logout"
                        onClick={onLogout}
                        style={{
                            ...theme.getMenuRightStyle(theme, LogoutIcon),
                    }}></div>
                }

                <ToggleFullMenu neverHide={true} />

                {
                    loggedIn && 
                    <NoticesMenu />
                }

            </div>

            <SmallListMenuBackDrop />
            <SmallListMenu />

        </div>
    );
};

export default SmallMenu;
