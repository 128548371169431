import React from 'react';
import { useState, useEffect } from 'react';
import AttributeListPanel from './../core/attribute-list';
import DatePanel from './../core/date';
import OfferSeekTypePanel from './../core/offer-seek-type';
import ExchangeListPanel from './../core/exchange-list';
import ObtainTypeDetailListPanel from './../core/obtain-type-detail-list';
import ItemAuthorMenu from './../../menu/item-author';
import ItemViewerMenu from './../../menu/item-viewer';
import AuthorPanel from './../core/author';
import FlagPrompt from '../../prompt/flag';
import FlaggedContentTypeEnum from './../../prompt/core/enum/flagged-content-type';
import BidContentTypeEnum from './../../prompt/core/enum/bid-content-type';
import BidsPanel from './../core/bids';
import ImgPanel from './../core/img';
import RefundPolicyPanel from './../core/refund-policy';
import UserReviewsPanel from './../user-reviews';
import theme from './../../theme';
import TagListPanel from './../core/tag-list';
import { isBidButtonVisible } from './../../menu/item-viewer';
import SubscriptionService from './../../../service/subscription';
import ResizeService from './../../../service/resize';
import { BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE } from './../../prompt/core/enum/bx-subscription-access';

const OfferDetailViewPanel = ({ 
    data: offer, 
    viewingUserId, 
    onArchiveItem, 
    setBids, 
    onLoadSpecifiedPage, 
    setPayClientSecret,
    onContactingServerResponse,
    onContactingServer,
}) => {
    const getOfferDetailTitleStyle = () => ({
        ...theme.getAbstractFlexContainerNoGutterStyle(theme),
        ...theme.getOfferAbstractFlexColumnStyle(theme)
    });
    const [offerDetailTitleStyle, setOfferDetailTitleStyle ] = useState(getOfferDetailTitleStyle());

    const getOfferDetailViewPanelStyle = () => theme.getOfferDetailViewPanelStyle(theme);
    const [ offerDetailViewPanelStyle, setOfferDetailViewPanelStyle ] = useState(getOfferDetailViewPanelStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferDetailTitleStyle(getOfferDetailTitleStyle());
        setOfferDetailViewPanelStyle(getOfferDetailViewPanelStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={offerDetailViewPanelStyle}>
            <div style={offerDetailTitleStyle}>
                <div style={{
                    ...theme.getOfferDetailViewHeaderStartStyle(theme),
                }}>
                    <h2>
                        Title:
                    </h2>
                    <h4>
                        { offer.title }
                    </h4>
                </div>
                <div style={{
                    ...theme.getOfferDetailViewHeaderEndStyle(theme),
                }}>
                    {
                        offer.isConfirmView ? '' :
                            /* istanbul ignore next */
                            offer.author.id === viewingUserId ? <ItemAuthorMenu item={offer} onArchiveItem={onArchiveItem} /> : 
                                <ItemViewerMenu 
                                    setPayClientSecret={setPayClientSecret}
                                    item={offer} 
                                    onContactingServerResponse={onContactingServerResponse}
                                    onContactingServer={onContactingServer}
                                    paidAccount={SubscriptionService.can(offer.subscription, BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE)}
                                    setBids={setBids}
                                    flaggedContentType={FlaggedContentTypeEnum.FLAGGED_CONTENT_TYPE_OFFER} 
                                    bidContentType={BidContentTypeEnum.BID_CONTENT_TYPE_OFFER}
                                />
                    }
                </div>
            </div>
            
            <div style={{
                ...theme.getOfferDetailViewBodyStyle(theme),
            }}>
                
                <div style={{
                            ...theme.getOfferDetailViewGroupStyle(theme),
                            ...theme.getOfferAbstractFlexColumnStyle(theme),
                }}>
                    <h3>
                        {
                        offer.imgList.length > 0 ? 'Images:' : 'No Images'
                        }
                    </h3>
                    {
                        offer.imgList.length > 0 ? <ImgPanel imgList={offer.imgList} /> : ''
                    }
                </div>

                <div style={{
                    ...theme.getOfferDetailViewGroupStyle(theme),
                    ...theme.getOfferAbstractFlexColumnStyle(theme),
                }}>
                    <h3>
                        Offer Details:
                    </h3>
                    <p style={{
                    ...theme.getAbstractTextAlignLeftStyle(theme),
                    ...theme.getOfferDetailsBodyStyle(theme),
                    }}>
                        { offer.details }
                    </p>
                </div>

                <TagListPanel tagList={offer.tagList} />  
                
                <div style={{
                    ...theme.getOfferDetailViewGroupStyle(theme),
                    ...theme.getAbstractTextBaselineStyle(theme),
                }}>
                    <h4>
                        Offer Type:
                    </h4>
                    &nbsp;
                    <OfferSeekTypePanel offerSeekType={offer.offerSeekType} />
                </div>
                <div style={{
                    ...theme.getTagListPostedOfferFlexStyle(theme),
                }}>
                    <AttributeListPanel attributeList={offer.attributeList} />
                </div>
                <ExchangeListPanel exchangeList={offer.exchangeList} />
                <ObtainTypeDetailListPanel 
                    obtainTypeDetailList={offer.obtainTypeDetailList} 
                    contentType="offer"
                />

                <RefundPolicyPanel data={offer} />

                {
                    offer.isConfirmView ? '' : 
                        isBidButtonVisible(offer, BidContentTypeEnum.BID_CONTENT_TYPE_OFFER) ? 
                            <BidsPanel 
                                bidderIsReceiver={true} 
                                targetType="offer"
                                isActive={true} bids={offer.bids} 
                                onLoadSpecifiedPage={onLoadSpecifiedPage} 
                            /> : ''
                }

                <div style={{
                    ...theme.getOfferDetailViewSubHeaderStyle(theme),
                    ...theme.getAbstractTextBaselineStyle(theme),
                }}>
                    
                    <div style={{ 
                        ...theme.getAbstractTextAlignLeftStyle(theme),
                        ...theme.getOfferDetailViewAdjustingWidthStyle(theme)
                    }}>
                        {
                            offer.isConfirmView ? '' : <AuthorPanel author={offer.author} />
                        }
                    </div>

                    <div style={{ 
                        ...theme.getAbstractTextAlignCenterStyle(theme),
                        ...theme.getAbstractFullWidthtStyle(theme)
                    }}>
                        {
                            offer.isConfirmView ? '' : <UserReviewsPanel ratedUserId={offer.author.id} />
                        }
                    </div>
                    
                    <div style={{ 
                        ...theme.getAbstractTextAlignRightStyle(theme),
                        ...theme.getOfferDetailViewAdjustingWidthStyle(theme)
                    }}>
                        {
                            offer.isConfirmView ? '' : <DatePanel label="Posted" date={offer.createdOn} />
                        }
                    </div>
                </div>
            </div>

            <div style={{
                ...theme.getOfferDetailViewHeaderEndStyle(theme),
            }}>
                {
                    offer.isConfirmView ? '' :
                        /* istanbul ignore next */
                        offer.author.id === viewingUserId ? '' : 
                            <FlagPrompt
                                buttonStyle={{}} 
                                contentId={offer.id} 
                                flaggedContentType={FlaggedContentTypeEnum.FLAGGED_CONTENT_TYPE_OFFER} 
                                openPromptTitle={"Flag this offer"} 
                            />
                }
            </div>

        </div>
    );
};

export default OfferDetailViewPanel;