import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../../../theme';
import Pagination from './../../../../prompt/core/pagination';
import ResizeService from './../../../../../service/resize';

const Friend = ({ user }) => 
    <div style={{
        ...theme.getWidgetFriendsStyle(theme),
        ...theme.getGeneralTextLinkStyle(theme)
    }}>
        <NavLink to={`/user/${user.id}`}>
            { user.username }
        </NavLink>
    </div>

const GetPagination = ({ data, onLoadSpecifiedPage  }) => 
    data.friends.page !== undefined && 
    data.friends.perPage && 
    data.friends.total ? <Pagination 
                    total={data.friends.total}
                    page={data.friends.page}
                    onLoadSpecifiedPage={ onLoadSpecifiedPage }
                    perPage={data.friends.perPage} /> : '';


const PopulatedView = ({ list }) => 
    <section>
        {
            list.map((friend, index) => <Friend key={index} user={friend} />)
        }
    </section>


const EmptyView = ({ userWidgetHeaderTextStyle }) => {
    const getContainerStyle = () => theme.getUserWidgetSubHeaderTextStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={userWidgetHeaderTextStyle}>
            <p style={containerStyle}>
                No Friends?
                <br />            
                <NavLink to={`/users`} style={{
                    ...theme.getGeneralTextLinkStyle(theme),
                }}>
                    Search for users you know!
                </NavLink>
            </p>
        </div>
    );
};

const WidgetFriendsViewPanel = ({ data, onLoadSpecifiedPage }) => {
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle ] = useState(getUserWidgetHeaderTextStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetHeaderTextStyle(getUserWidgetHeaderTextStyle());
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    Friends
                </div>
            </div>
            <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
            {
                data.friends.list.length < 1 ? 
                    <EmptyView userWidgetHeaderTextStyle={userWidgetHeaderTextStyle} /> : 
                        <PopulatedView list={data.friends.list} />
            }
            <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
        </section>
    );
};

export default WidgetFriendsViewPanel;
