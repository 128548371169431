import { 
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';

import { evalSteps } from './eval-steps';

export const buildOnMemberSelected = (steps, delivery, onAddStep) => (memberId) => {
    
    const {
        isFirstStep,
        lastStep
    } = evalSteps(steps);
    
    let nextStep = {
        sortOrder: steps.length + 1,
        memberId
    };
    
    /* istanbul ignore next */
    if(isFirstStep)
    {
        nextStep.type = CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP;
        nextStep.from = delivery.pickupLocation;
    }
    else 
    {
        /* istanbul ignore next */
        switch(lastStep.type)
        {
            case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF:
                nextStep.type = CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT;
                nextStep.from = lastStep.from;
                nextStep.to = lastStep.to;

                break;

            case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT:
                nextStep.type = CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP;
                nextStep.from = lastStep.to;
                break;

            default:
                console.error('undefined type');
                return false;
        }
    }

    onAddStep(nextStep);
};