import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const CarrierGroupNavMenu = ({
    carrierGroupName = null,
    carrierGroupId = null
}) => carrierGroupName && carrierGroupId ? 
    <div>
        <NavLink
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to="/profile/groups/carrier"
        >
            My Carrier Companies
        </NavLink>
        |
        <NavLink
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/profile/groups/carrier/${carrierGroupId}`}
        >
            { carrierGroupName }
        </NavLink>
    </div> : 
        <div>
            <NavLink
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to="/profile/groups/carrier"
            >
                My Carrier Companies
            </NavLink>
        </div>

export default CarrierGroupNavMenu;
