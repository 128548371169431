import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import SubscriptionPanel from './../ui/panel/subscription';
import PayNewCardPrompt from './../ui/prompt/pay-new-card';
import config from './../config';

const SubscriptionPage = ({ setPayClientSecret, payClientDetails, payClientSecret }) => {
    return (
        <AuthPageTemplate childMenu={ <ProfileMenu /> }>
            {
                payClientDetails && payClientDetails.type === 'subscription' ? 
                    <PayNewCardPrompt 
                        onSuccessRedirectUrl={config.pay.subscriptionConfirmedUrl}
                        payClientSecret={payClientSecret} 
                        payClientDetails={payClientDetails} 
                        setPayClientSecret={setPayClientSecret}  
                    /> :
                        <SubscriptionPanel 
                            setPayClientSecret={setPayClientSecret} 
                        />
            }
        </AuthPageTemplate>
    );
};

export default SubscriptionPage;
