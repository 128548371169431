import React from 'react';
import theme from './../../theme';
import { useSelector, useDispatch } from 'react-redux';
import selectUnseenNoticeCount from './../../../store/select/unseen-notice-count';
import { NavLink } from 'react-router-dom';
import ActiveNoticeIcon from './../../../img/menu-notices-active-white.svg';
import InactiveNoticeIcon from './../../../img/menu-notices-white.svg';
import { selectedMenuItem } from './menu-item';
import { UpdateMenuState } from './../../../store/state/action';


const NoticesMenu = () => {

    const dispatch = useDispatch();

    const hideListMenu = () => {
        dispatch(UpdateMenuState(true));
    };

    const onClickNotices = () => {
        hideListMenu();
        const url = window.location.href;
        const reg = /\/notices/i;
        /* istanbul ignore next */
        if(reg.test(url))
        {
            window.location.reload();
        }
    };
        

    const unseenNoticeCount = useSelector(selectUnseenNoticeCount);
    const hasUnseenNotices = unseenNoticeCount > 0;
    const getNoticeCopy = () => {
        const count = unseenNoticeCount < 99 ? unseenNoticeCount : '99+';
        return unseenNoticeCount > 0 ? 
            <div style={{ ...theme.getUnseenNoticeStyle(theme) }}>
                { `${count}` }
            </div> : '';
    };

    return (
        <NavLink 
            className="test-noticeMenuNotices"
            to="/notices" 
            onClick={event => { onClickNotices() } } 
            activeStyle={selectedMenuItem}
        >
            <div 
                title="Notices"
                style={{
                    ...theme.getMenuRightStyle(theme, hasUnseenNotices ? ActiveNoticeIcon : InactiveNoticeIcon), 
            }}>
                { getNoticeCopy() }
            </div>
        </NavLink>
    );
};

export default NoticesMenu;
