import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';


class FineService 
{
    static getAdminSearchRequest(type, page, targetUserId = undefined)
    {
        return {
            type,
            page,
            targetUserId
        };
    }

    /* istanbul ignore next */
    static adminSearch({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.fineSearch,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUserSearchRequest(page)
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static userSearch({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.fines.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getStartPayFineRequest(paymentMethod, payFineId)
    {
        return {
            paymentMethod,
            payFineId
        };
    }

    /* istanbul ignore next */
    static startPayFine({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.fines.startPay,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default FineService;
