import React from 'react';
import AuthPageTemplate from './template/auth-page';
import AttSetDetailPanel from './../ui/panel/att-set-detail';
import { useParams } from 'react-router-dom';
import ProfileMenu from './../ui/menu/profile';


const AttSetDetailPage = () => {
    const { attSetId } = useParams();
    return (
        <AuthPageTemplate 
            childMenu={ 
                <div>
                    <ProfileMenu />
                </div>
            }
        >
            <AttSetDetailPanel attSetId={attSetId} />
        </AuthPageTemplate>
    );
};

export default AttSetDetailPage;
