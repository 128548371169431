export const getPageStyle = (theme) => ({
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getWidgetPageWrapperStyle = (theme) => ({
    padding: '1rem',
    margin: '0 auto',
    width: theme.isDesktopEXT() ? '1440px' : 
    (
        theme.isDesktopXL() ? '1200px' : 
        (
            theme.isDesktop() ? '992px' : 
            (
                theme.isTablet() ? '100%' : 
                '100%'
            )
        )
    ),
});

export const getPageTopSpacerStyle = (theme, hasChildMenu) => ({
    height: theme.isTablet() || hasChildMenu ? '12rem': '6.5rem'
});