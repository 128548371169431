import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import { NavLink } from 'react-router-dom';
import { getImg } from './../../panel/trails/view';
import theme from './../../theme';

export const UserSearchSingleFieldPromptContainer = ({ children }) => {
    const getContainerStyle = () => theme.getUserSearchSingleFieldContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={containerStyle}>
            { children }
        </div>
    );
};

export const UserPlatformIcon = ({ trail }) => 
    <div style={{
        ...theme.getUserSearchPlatformStyle(theme, getImg(trail.customClass)),
    }}>

    </div>

const UserSearchViewPrompt = ({ user }) => 
    <div>
        <NavLink to={`/user/${user.id}`}>
            <div style={{
                ...theme.getUserSearchResultStyle(theme),
            }}>
                <div style={{
                    ...theme.getUserSearchResultUsernameStyle(theme),
                }}>
                    { user.username }
                </div>

                <div style={{
                    ...theme.getUserSearchResultPlatformContainerStyle(theme),
                }}>
                    {
                        user.trailList.map((aTrail, index) => <UserPlatformIcon trail={aTrail} key={index} /> )
                    }
                </div>

                <div style={{
                    clear: 'both'
                }}></div>
            </div>
        </NavLink>
    </div>

export default UserSearchViewPrompt;
