import React from 'react';

const ReviewedExchangeView = ({ exchange }) => 
    <section>
        <h2>
            Reviewed
        </h2>
        <p>
            This exchange has been reviewed.  Look at your profile to learn about your reputation.
        </p>
    </section>

export default ReviewedExchangeView;
