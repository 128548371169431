import MathService from './../../../../../service/math';
import CurrencyInputService from './../../../../../service/currency-input';

const makeGetEstFuelCost = ({
    useMpgGlobal,
    estimatedMPG,
    mpgBuffer,
    estimatedDollarsPerGallon,
    useGasPriceGlobal,
    gasPriceBufferValid,
    gasPriceBuffer,

}) => trip => {
    const currentGasPrice = useGasPriceGlobal ? estimatedDollarsPerGallon : gasPriceBuffer;
    const currentMpg = useMpgGlobal ? estimatedMPG : mpgBuffer;
    const result = MathService.getFuelCost('usd', trip.distanceInMiles, currentMpg, CurrencyInputService.parse(currentGasPrice));
    return result.cost;
};

export default makeGetEstFuelCost;
