import React from 'react';
import AuthService from './../../service/auth';
import ValidateService from './../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './core/abstract';
import mapDispatchToProps from './../../store/map/on-login';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LoginPrompt from './login';
import LogoPanel from './../panel/logo';
import theme from './../theme';

class _JoinPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _JoinPrompt.getValidators());

        this.onJoinResponse = this.onJoinResponse.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onJoinError = this.onJoinError.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getTextUi_getContainerStyle = this.getTextUi_getContainerStyle.bind(this);
        this.getTextUi_getErrorStyle = this.getTextUi_getErrorStyle.bind(this);
        this.getTextUi_getInputFieldStyle = this.getTextUi_getInputFieldStyle.bind(this);
        this.getPasswordUi_getContainerStyle = this.getPasswordUi_getContainerStyle.bind(this);
        this.getPasswordUi_getErrorStyle = this.getPasswordUi_getErrorStyle.bind(this);
        this.getPasswordUi_getInputFieldStyle = this.getPasswordUi_getInputFieldStyle.bind(this);

        this.validator.pwdRepeat.isValid = ValidateService.makeMatchFieldValue(this, 'pwd');
        this.state = this.getDefaultState();
    }

    getPasswordUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }
    
    getPasswordUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    getPasswordUi_getInputFieldStyle()
    {
        return theme.getAddOfferTextFieldStyle(theme);
    }

    getTextAreaUi_getContainerStyle()
    {
        return theme.getAddOfferTextFieldStyle(theme);
    }

    getTextAreaUi_getErrorStyle()
    {
        return theme.getAddOfferTextFieldStyle(theme);
    }

    getTextUi_getInputFieldStyle()
    {
        return theme.getAddOfferTextFieldStyle(theme);
    }

    static getValidators()
    {
        return {
            inviteCode: {
                isValid: ValidateService.valueRequired
            },
            email: {
                isValid: ValidateService.email
            },
            username: {
                isValid: ValidateService.valueRequired
            },
            pwd: {
                isValid: ValidateService.valueRequired
            },
            pwdRepeat: {
                isValid: ValidateService.textLine
            },
            accepted: {
                isValid: ValidateService.isTrue
            },
        };
    }

    getFieldsModel()
    {
        return {
            inviteCode: {
                name: 'Invite Code*',
                error: 'Provide a valid invite code to create an account',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            username: {
                name: 'Username*',
                error: 'Provide a unique public facing username',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            email: {
                name: 'Email*',
                error: 'Provide a valid email',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            pwd: {
                name: 'Password*',
                error: 'Specify your password',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.PASSWORD
            },
            pwdRepeat: {
                name: 'Password Repeated*',
                error: 'This value does not match the first password',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.PASSWORD
            },
            accepted: {
                name: 'I have read and agree to terms of service*',
                customField: <span>I have read and agree to <NavLink style={{ ...theme.getGeneralTextLinkStyle(theme) }} to="/terms">terms of service</NavLink>*</span>,
                error: 'You must consent to terms of service',
                value: false,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onJoinResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        this.props.onLogin(
            response.authToken, 
            response.userId, 
            response.isAdmin,
            response.unseenNoticeCount,
            response.clientSettings,
        );
        this.props.onOnboarding();
    }

    onJoinError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onSendToServer()
    {
        AuthService.join({
            request: this.getServerRequest(), 
            onResponse: this.onJoinResponse, 
            onError: this.onJoinError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            inviteCode: this.state.inviteCode.value,
            email: this.state.email.value,
            username: this.state.username.value,
            pwd: this.state.pwd.value,
        };
    }

    getConfirmUi(confirmMessage = 'Account Created!  Check your email for your password.') {
        return (
            <section>
                { super.getConfirmUi(confirmMessage) }
                <LoginPrompt />
            </section>
        );
    }

    getSubmitButtonUi(buttonText = 'Join')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getPromptUi()
    {
        return (
            <section>
                <LogoPanel />
                <p>
                    Accounts are invite only.
                </p>
                <p>
                    Please read our
                </p>
                <p>
                    <NavLink 
                        to="/terms" 
                        style={{
                            ...theme.getGeneralTextLinkStyle(theme),
                        }}
                    >
                        Terms Of Service
                    </NavLink>
                </p>
                <br />
                { super.getPromptUi() }
                <br />
                <div style={{
                    ...theme.getAbstractBottomPaddingSpacerStyle(theme),
                    ...theme.getGeneralTextLinkStyle(theme),
                }}>
                    <NavLink to="/">
                        Already Have an Account?
                    </NavLink>
                </div>
            </section>
        );
    }
}

const JoinPrompt = connect(null, mapDispatchToProps)(_JoinPrompt);
export default JoinPrompt;
