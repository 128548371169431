import React from 'react';
import UserService from './../../service/user';
import ValidateService from './../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './core/abstract';
import theme from '../theme';
import { NavLink } from 'react-router-dom';
import ResizeService from '../../service/resize';

class UnsubscribePrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, UnsubscribePrompt.getValidators());

        this.resizeFun = () => {};
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.updateProfilePwdPageStyle = this.updateProfilePwdPageStyle.bind(this);
        this.getProfilePwdPageStyle = this.getProfilePwdPageStyle.bind(this);
    }

    static getValidators()
    {
        return {
            email: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    getFieldsModel()
    {
        return {
            email: {
                name: 'Email*',
                error: 'Provide your email',
                value: this.props.email ? this.props.email : '',
                visible: true,
                valid: this.props.email ? true : false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            profilePwdPageStyle: this.getProfilePwdPageStyle()
        };
    }

    getProfilePwdPageStyle()
    {
        return theme.getProfilePwdPageStyle(theme);
    }

    /* istanbul ignore next */
    updateProfilePwdPageStyle()
    {
        let nextState = this.state;
        nextState.profilePwdPageStyle = this.getProfilePwdPageStyle();
        this.setState(nextState);
    }

    componentDidMount()
    {
        this.resizeFun = ResizeService.getOnResize(this.updateProfilePwdPageStyle);
        ResizeService.startListening(this.resizeFun);

        if(this.props.email)
        {
            let updateState = this.state;
            const overallState = this.evaluateFieldsVisibility(updateState);
            const finalState = this.validateState(overallState);
            this.setState(finalState);
        }
    }

    componentWillUnmount()
    {
        ResizeService.stopListening(this.resizeFun);
    }

    onServerResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
    }

    onServerError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onSendToServer()
    {
        UserService.unsubscribe(
            this.getServerRequest(),
            this.onServerResponse,
            this.onServerError
        );
    }

    getServerRequest()
    {
        return {
            email: this.state.email.value
        };
    }

    getSubmitButtonUi(buttonText = 'Unsubscribe')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getPromptUi(buttonText = 'Unsubscribe')
    {
        return (
            <div style={{ 
                    ...theme.getAbstractPromptStyle(theme), 
            }}>
                <h2>
                    Stop All Emails
                </h2>
                <div style={this.state.profilePwdPageStyle}>
                    <p>
                        <span>
                            If you still wish to receive emails such as password resets, then instead adjust your email settings 
                        </span>
                        &nbsp;
                        <NavLink 
                            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                            to="/profile/settings"
                        >
                            here
                        </NavLink>
                    </p>
                    { super.getPromptUi(buttonText) }                 
                </div>
            </div>
        );
    }

    getConfirmUi(confirmMessage)
    {
        const message = <section>
            <span style={{
                ...theme.getAbstractTextAlignCenterStyle(theme),
            }}>
                <p>
                    You've been unsubscribed from all emails.
                </p>
                <NavLink 
                    to="/profile/email" 
                    style={{
                        ...theme.getAbstractBottomPaddingSpacerStyle(theme),
                        ...theme.getGeneralTextLinkStyle(theme),
                    }}
                >
                    You can change this here
                </NavLink>
            </span>
        </section>
        return super.getConfirmUi(message);
    }
}

export default UnsubscribePrompt;
