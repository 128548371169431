import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class PayService {

    static getAccountDetailsRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static accountDetails({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.account,
            request: this.getAccountDetailsRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getFinishAccountRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static finishAccount({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.finishAccount,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getCreateAccountRequest()
    {
        return {
            
        };
    }

    /* istanbul ignore next */
    static createAccount({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.createAccount,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdateAccountRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static updateAccount({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.updateAccount,
            request: this.getUpdateAccountRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListBankAccountsRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static listBankAccounts({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.bankAccounts,
            request: this.getListBankAccountsRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListPaymentMethodsRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static listPaymentMethods({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.methods,
            request: this.getListPaymentMethodsRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddBankRequest(country, currency, routingNumber, accountNumber)
    {
        return {
            country, 
            currency, 
            routingNumber, 
            accountNumber
        };
    }

    /* istanbul ignore next */
    static addBank({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.addBank,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveBankAccountRequest(bankAccountId)
    {
        return {
            bankAccountId
        };
    }

    /* istanbul ignore next */
    static removeBankAccount({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.removeBankAccount,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }


    static getSetAsPayoutTargetRequest(bankAccountId)
    {
        return {
            bankAccountId
        };
    }

    /* istanbul ignore next */
    static setAsPayoutTarget({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.setPayoutBank,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddPayMethodSecretRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static addPayMethodSecret({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.addPayMethodSecret,
            request: this.getAddPayMethodSecretRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemovePayMethodRequest(paymentMethodId)
    {
        return {
            paymentMethodId
        };
    }

    /* istanbul ignore next */
    static removePayMethod({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.removePayMethod,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminIssueRefundRequest(payId)
    {
        return {
            payId
        };
    }

    /* istanbul ignore next */
    static adminIssueRefund({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.issueRefund,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdatePaymentMethodRequest(paymentMethodId, isDefault, nickname)
    {
        return {
            paymentMethodId, 
            isDefault, 
            nickname
        };
    }

    /* istanbul ignore next */
    static updatePaymentMethod({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.pay.updatePayMethod,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default PayService;
