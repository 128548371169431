import { CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP } from './../../../../prompt/core/enum/carrier-group-delivery-plan-price-contribution-type';
import makeGetEstFuelCost from './get-est-fuel-cost';

const calcTrip = ({
    useMpgGlobal,
    estimatedMPG,
    mpgBuffer,
    estimatedDollarsPerGallon,
    record,
    useGasPriceGlobal,
    gasPriceBufferValid,
    gasPriceBuffer,
}) => {

    const getEstFuelCost = makeGetEstFuelCost({
        useMpgGlobal,
        estimatedMPG,
        mpgBuffer,
        estimatedDollarsPerGallon,
        useGasPriceGlobal,
        gasPriceBufferValid,
        gasPriceBuffer,
    });

    const tripList = record.contributions.filter(c => c.type === CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP);
    const hasTrips = tripList.length > 0 ? true : false;

    let tripCost = 0;
    try 
    {
        tripCost = tripList.map(getEstFuelCost).reduce((prev, curr) => prev + curr);
    } 
    catch(err)
    {
        /* istanbul ignore next */
        tripCost = 0;
    }

    return {
        tripCost,
        hasTrips
    };
};

export default calcTrip;
