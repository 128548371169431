import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class RelationshipService {

    static getBlockUserRequest(userId) {
        return {
            targetUserId: userId
        };
    }

    /* istanbul ignore next */
    static blockUser({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.relationship.block.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUnblockUserRequest(userId) {
        return {
            targetUserId: userId
        };
    }

    /* istanbul ignore next */
    static unblockUser({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.relationship.block.remove,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getFriendUserRequest(userId) {
        return {
            targetUserId: userId
        };
    }

    /* istanbul ignore next */
    static friendUser({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.relationship.friend.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUnfriendUserRequest(userId) {
        return {
            targetUserId: userId
        };
    }

    /* istanbul ignore next */
    static unfriendUser({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.relationship.friend.remove,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSearchFriendsRequest(page)
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static searchFriends({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.relationship.friend.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default RelationshipService;
