import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import Dialog from '@mui/material/Dialog';
import AddLocationPrompt from './../../prompt/add-location';

export const LoadedDataDetailUi = ({ children, promptTitle }) => 
    <div>
        <h4>
            {
                promptTitle ? promptTitle : 'Specify a location'
            }
        </h4>
        { children }
    </div>

export const Location = ({ loc, onSelected }) => 
    <div style={{
        ...theme.getLocationMailingAddressStyle(theme)
    }}>
        <div>
            <button 
                className="test-locationSelectorSelectLocationBtn"
                onClick={event => { onSelected(loc) }}
            >
                Select
            </button>
        </div>

        <p>
            { loc.mailingAddress }
        </p>

        {
            loc.hasTruckDocks && 
                <p>
                    <strong>
                        Has Truck Dock(s)
                    </strong>
                </p>
        }

        {
            loc.hasLimitedAccess && 
                <p>
                    <strong>
                        Limited Access Location
                    </strong>
                </p>
        }

        {
            loc.notes !== '' && 
                <p>
                    { loc.notes }
                </p>
        }

    </div>

export const UnSelectedLocationUi = ({ 
    locations, 
    onSelected, 
    onSelectAdded,
    includeGps,
    allowLocationAdds
}) => {
    const [ showAddPrompt, setShowAddPrompt ] = useState(false);
    const getDialogContainerStyle = () => ({
        ...theme.getProfilePwdPanelStyle(theme),
        ...theme.getAbstractSpacerScrollbarStyle(theme),  
        ...theme.getAbstractTextAlignCenterStyle(theme)
    });
    const [ dialogContainerStyle, setDialogContainerStyle ] = useState( getDialogContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setDialogContainerStyle( getDialogContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getLocationSelectorContainerStyle(theme)  
        }}>
            {
                allowLocationAdds && 
                    <div>
                        <button 
                            className="test-locationSelectorAddLocationBtn"
                            onClick={event => { setShowAddPrompt(true) }}
                        >
                            Add Location
                        </button>
                    </div>
            }
            <div>
                {
                    locations.map(aLoc => 
                        <Location 
                            onSelected={onSelected}
                            loc={aLoc}
                            key={aLoc.id}
                        />
                    )
                }
            </div>
            <Dialog 
                onClose={
                    /* istanbul ignore next */    
                    event => { setShowAddPrompt(false) }
                }
                open={showAddPrompt}
            >
                <div style={dialogContainerStyle}>
                    <AddLocationPrompt
                        includeGps={includeGps}
                        onUpdateLocations={ 
                            /* istanbul ignore next */
                            response => {
                                onSelectAdded(response);
                                setShowAddPrompt(false);
                            }
                        }
                    />
                </div>
            </Dialog>
        </div>
    );
};

export const SelectedLocationUi = ({ selectedLocation, onRemoveSelected }) => 
    <div style={{
        ...theme.getLocationSelectorContainerStyle(theme)  
    }}>
        <div>
            <button 
                className="test-locationSelectorChangeBtn"
                onClick={ event => { onRemoveSelected() }}
            >
                Change
            </button>

            <p style={{
              ...theme.getLocationMailingAddressStyle(theme)  
            }}>
                { selectedLocation.mailingAddress }
            </p>
        </div>
    </div>
