export const getHelpImageStyle = (theme, img) => ({
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    width: '100%',
    maxWidth: '46rem',
//  Need to add code to auto adjust container height to img 
//  height on load or images need to be inline. 
    height: '32rem',
//
    backgroundColor: theme.panelColor,
    backgroundImage: `url(${img})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1rem',
    borderWidth: '.1rem',
    borderColor: theme.panelSecondary,
    borderStyle: 'solid',
    borderRadius: '.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)'
});

export const getHelpRelatedTopicStyle = (theme) => ({
    cursor: 'pointer',
    padding: '.3rem 1rem',
    marginTop: '.5rem',
    fontSize: '12pt',
    textDecorationLine: 'none',
});

export const getHelpMenuStyle = (theme) => ({
    cursor: 'pointer',
    padding: '.75rem 1.2rem',
    fontSize: '12pt',
    textDecorationLine: 'none',
    borderRadius: theme.isTablet() ? '.25rem' : '0',
    borderColor: theme.panelSecondary,
    textDecoration: 'none',
    fontWeight: '500',
    backgroundColor: theme.buttonColor,
    textAlign: 'center',
     //:hover      color: theme.selectColorLight,
    //:hover      backgroundColor: theme.panelSecondary,
    //:onclick   color: theme.selectColorLight,
    //:onclick   backgroundColor: theme.panelSecondary,
    transition: 'all 0.2s',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)'
});

export const getHelpToMenuStyle = (theme) => ({
    animationName: 'getHelpToMenuStyle',
    cursor: 'pointer',
    paddingTop: '.25rem',
    paddingBottom: '4px',
    marginBottom: '.25rem',
    fontSize: '14pt',
    paddingLeft: '15px',
    textDecorationLine: 'underline',
});


export const getHelpMenuContainerStyle = (theme) => ({
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
});

export const getHelpPageContentDetailStyle = (theme) => ({
    width: '400px',
    textAlign: 'center',
    paddingLeft: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    whiteSpace: 'pre-wrap',
});

export const getHelpPageContentImagesContainerStyle = (theme) => ({
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
});
