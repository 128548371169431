import React from 'react';
import ActorDetails from './actor-details';

const AwaitingPaymentView = ({ exchange, onPaid }) => 
    <section>
        <div>
            <h2>
                Awaiting Payment from 
                <ActorDetails 
                    usersDetails={exchange.usersDetails} 
                    actorId={exchange.actorId} 
                    amIActor={exchange.amIActor}
                />
            </h2>
            <p>
                The payment or offered exchange must be sent to begin the transaction.  Use the chat to gather any unknown details.
            </p>
        </div>
        {
            exchange.amIActor ? 
                <button 
                    className="test-exchangeDetailAwaitingPaymentViewPaymentSentBtn"
                    onClick={event => { onPaid() }}
                >
                    Payment Sent
                </button> : ''
        }
    </section>

export default AwaitingPaymentView;
