import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../../service/resize';

/* istanbul ignore next */
const PageLink = ({ onLoadSpecifiedPage, pageToUrl, linkText, toPage }) => {
    const btnStyle = {
        ...theme.getPaginationButtonStyle(theme),
    };
    
    const toUrl = pageToUrl ? pageToUrl(toPage) : '';
    let currentUrl;
    try {
        currentUrl = window.location.href.toString().split("#")[1];
    } 
    catch(err)
    {
        currentUrl = window.location.href;
    }

    const onClick = currentUrl === toUrl ? event => { window.location.reload() } : event => {};

    return pageToUrl ? <NavLink 
                            className="test-paginationBtn"
                            style={btnStyle} 
                            onClick={onClick} 
                            to={toUrl}
                        >{ linkText }</NavLink> : 
                            <span 
                                className="test-paginationBtn"    
                                onClick={event => onLoadSpecifiedPage(toPage)} 
                                style={btnStyle}
                            >{ linkText }</span>
};

const Pagination = ({ total, page, perPage, onLoadSpecifiedPage, pageToUrl, hideAllWhenNoButtonsVisible = false }) => {
    const displayPage = page + 1;
    const totalPages = Math.ceil(total / perPage);
    const showPrev = page > 0;
    const showNext = (displayPage * perPage) < total;
    const prevPage = showPrev ? page - 1 : 0;
    const nextPage = showNext ? page + 1 : 0;

    const getPaginationButtonContainerStyle = () => theme.getPaginationButtonContainerStyle(theme);
    const [ paginationButtonContainerStyle, setPaginationButtonContainerStyle ] = useState( getPaginationButtonContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPaginationButtonContainerStyle( getPaginationButtonContainerStyle() );
    });
    useEffect(resizeEffect);
    return hideAllWhenNoButtonsVisible && ( !showPrev  && !showNext ) ? '' :
        <div style={paginationButtonContainerStyle}>
            {
                !showPrev ? '' : <PageLink onLoadSpecifiedPage={onLoadSpecifiedPage} pageToUrl={pageToUrl} linkText="Prev" toPage={prevPage} />
            }
            {
                totalPages < 2 ? '' : 
                <span>
                    { displayPage } of { totalPages }
                </span>
            }
            {
                !showNext ? '' : <PageLink onLoadSpecifiedPage={onLoadSpecifiedPage} pageToUrl={pageToUrl} linkText="Next" toPage={nextPage} />
            }
        </div>
    
};

export default Pagination;
