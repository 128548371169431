import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import { PaymentElement } from '@stripe/react-stripe-js';
import CurrencyService from './../../../service/currency';
import menuDetails from '../../../store/select/menu-details';
import { useSelector } from 'react-redux';

// istanbul ignore next 
const PayNewCardViewPrompt = ({
    payClientDetails,
    message,
    onSubmit,
    formEnabled
}) => {
    const getPanelStyle = () => theme.getProfilePwdPanelStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );
    const { showFullMenu } = useSelector(menuDetails);
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPanelStyle( getPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getPayNewCardContainerStyle(theme)  
        }}>

            <h2 style={{
                ...theme.getAbstractWordWrapStyle(theme)  
            }}>

                { payClientDetails.title }
            </h2>

            <h3>
                Complete your payment
            </h3>
            <div  style={panelStyle}>
                <p>
                    { 
                        payClientDetails.total ? 
                            CurrencyService.getDisplayPrice(
                                payClientDetails.currency,
                                payClientDetails.total
                            ) : '' 
                    }
                </p>

                {
                    message ? 
                    <p>
                        { message }
                    </p> : ''
                }
                {
                    showFullMenu && 
                    <form onSubmit={(event) => { onSubmit(event) }}>
                        <PaymentElement />
                        <div style={{
                            ...theme.getPayNewCardButtonContainerStyle(theme)
                        }}>
                            <button disabled={!formEnabled}>
                                Pay
                            </button>
                        </div>
                    </form>
                }
            </div>
        </div>
    );
};


export default PayNewCardViewPrompt;
