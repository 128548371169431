import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';

const UserTrailListContainer = ({ 
    user,
    children 
}) => {
    const getGridStyle = () => theme.getEditProfileTrailsGridStyle(theme);
    const [ gridStyle, setGridStyle ] = useState( getGridStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setGridStyle( getGridStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <h3 style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                { user && user.username ? `${user.username}'s ` : 'My' } Platforms
            </h3>
            <section style={gridStyle}>
                { children }
            </section>
        </section>
    );
};

export default UserTrailListContainer;
