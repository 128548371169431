import React from 'react';
import ValidateService from './../../../service/validate';
import CurrencyService from './../../../service/currency';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import RestrictViewTypeEnum from './../core/enum/restrict-view-type';
import ExchangeTypeEnum from './../core/enum/exchange-type';
import Dialog from '@mui/material/Dialog';
import theme from './../../theme';
import CloseIcon from './../../../img/close.svg';
import AddOneIcon from './../../../img/addOne.svg';
import { NavLink } from 'react-router-dom';
import AddOnlinePaymentExchangePrompt from './../add-online-payment-exchange';
import AddUSPSPaymentExchangePrompt from './../add-usps-payment-exchange';
import AddCashPaymentExchangePrompt from './../add-cash-payment-exchange';
import {
    EXCHANGE_TYPE_CASH,
    EXCHANGE_TYPE_ONLINE,
    EXCHANGE_TYPE_USPS_CASH_ORDER,
} from './enum/exchange-type';
import { ExchangeMethodCopy } from './../../panel/core/bids';

class ExchangeDetailViewPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, ExchangeDetailViewPrompt.getValidators());

        this.getDefaultState = this.getDefaultState.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.triggerOnAddExchange = () => { throw new Error('Missing onAddExchange'); };
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getListItemUi = this.getListItemUi.bind(this);
        this.getListUi = this.getListUi.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.getEmptyListUi = this.getEmptyListUi.bind(this);
        this.getDialogUi = this.getDialogUi.bind(this);
        this.setupOnlinePaymentUi = this.setupOnlinePaymentUi.bind(this);
        this.getOnlinePaymentUi = this.getOnlinePaymentUi.bind(this);
        this.onAddOnlinePaymentExchange = this.onAddOnlinePaymentExchange.bind(this);
        this.onAddCashPaymentExchange = this.onAddCashPaymentExchange.bind(this);
        this.onAddUSPSPaymentExchange = this.onAddUSPSPaymentExchange.bind(this);
        this.onSetTypeField = this.onSetTypeField.bind(this);
        this.getUSPSPaymentUi = this.getUSPSPaymentUi.bind(this);
        this.getDialogTitleUi = this.getDialogTitleUi.bind(this);
        this.getCashPaymentUi = this.getCashPaymentUi.bind(this);
        this.getDialogTitleCopy = this.getDialogTitleCopy.bind(this);

        this.state = this.getDefaultState();
    }

    /* istanbul ignore next */
    static getValidators()
    {
        return {
            type: {
                isValid: ExchangeTypeEnum.isValid,
            },
            visibilityType: {
                isValid: RestrictViewTypeEnum.isValid,
            },
/*            
            visibilityExpireDate: {
                isValid: ValidateService.textLine
            },
*/            
            asking: {
                isValid: ValidateService.valueRequired,
                isVisible: (state) => state.type.value !== ExchangeTypeEnum.EXCHANGE_TYPE_FREE,
            },
/*            
            min: {
                isValid: ValidateService.textLine
            },
            max: {
                isValid: ValidateService.textLine
            }
*/
        };
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    getFieldsModel()
    {
        return {
            type: {
                name: 'Exchange Type',
                error: 'Specify the type of exchange you accept',
                value: ExchangeTypeEnum.EXCHANGE_TYPE_TRADE,
                valueList: ExchangeTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            visibilityType: {
                name: 'Offer Visibility',
                error: 'Specify the offer visibility',
                value: RestrictViewTypeEnum.RESTRICT_VIEW_TYPE_PUBLIC,
                valueList: RestrictViewTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
/*            
            visibilityExpireDate: {
                name: 'Offer Expiration Date',
                error: 'Specify the date this offer is no longer available',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
*/
            asking: {
                name: 'Asking',
                error: 'What are you asking for in exchange?',
                value: this.props.asking ? (
                    this.props.omitAskingFromRoot ? 
                    '' : 
                    this.props.asking 
                ): '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
/*            
            min: {
                name: 'Minimum Asking',
                error: 'What is the least you would accept in exchange?',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            max: {
                name: 'Ideal Asking',
                error: 'What is the ideal you expect you would accept in exchange?',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
*/            
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        let iniState = {
            ...baseState,
            ...fields,
            isValid: true,
            dialogOpen: false,
        };
        
        if(!this.props.skipOnlinePayValidation)
        {
            // omit online payments if details not loaded, setup not complete, or no currecy payout setup
            if(!this.props.payAccountLoaded || !this.props.payAccountSetupComplete || this.props.payCurrencyList.length < 1)
            {
                iniState.type.valueList = ExchangeTypeEnum.getDropDownValues().filter(item => item.value !== ExchangeTypeEnum.EXCHANGE_TYPE_ONLINE);
            }
        }
        return iniState;
    }

    /* istanbul ignore next */
    openDialog()
    {
        const nextState = {
            ...this.state,
            dialogOpen: true,
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    closeDialog()
    {
        const nextState = {
            ...this.state,
            dialogOpen: false,
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCloseDialog(event)
    {
        this.closeDialog();
    }

    componentDidMount()
    {
        /* istanbul ignore next */
        this.triggerOnAddExchange = (details = undefined) => {
            const request = details ? details : this.getServerRequest();
            this.props.onAddExchange(request);
            const nextState = {
                ...this.state,
                isValid: true
            };
            this.setState(nextState);
        };
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        this.triggerOnAddExchange();
        let nextState = this.getBaseStateOnResponse({ message: '', success: false });
        nextState = this.clearFields(nextState);
        nextState.dialogOpen = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            type: this.state.type.value,
            visibility: {
                type: this.state.visibilityType.value,
                expireDate: '', // this.state.visibilityExpireDate.value,
            },
            asking: this.state.type.value !== ExchangeTypeEnum.EXCHANGE_TYPE_FREE ? this.state.asking.value : '',
            currency: '',
            min: '', // this.state.min.value,
            max: '', // this.state.max.value,
        };
    }

    getListItemUi(exchangeDetail, index)
    {
        const typeCopy = ExchangeMethodCopy({ 
            bid: {
                exchangeMethod: exchangeDetail.type
            }
        });
        
        /*
        exchangeDetail.type === EXCHANGE_TYPE_USPS_CASH_ORDER ? 'USPS Money Order' : (
            exchangeDetail.type[0].toUpperCase() + exchangeDetail.type.substring(1)
        );
        */
        const isPublic = exchangeDetail.visibility.type === 'public';
        const askingText = exchangeDetail.type === EXCHANGE_TYPE_ONLINE ? 
            CurrencyService.getDisplayPrice(exchangeDetail.currency, exchangeDetail.asking) : (
                exchangeDetail.type === EXCHANGE_TYPE_USPS_CASH_ORDER ? 
                    CurrencyService.getDisplayPrice('usd', exchangeDetail.asking) : (
                        exchangeDetail.type === EXCHANGE_TYPE_CASH ? 
                            CurrencyService.getDisplayPrice('usd', exchangeDetail.asking) : 
                                exchangeDetail.asking
                    )
            );
        return (
            <div 
                style={{
                    ...theme.getExchangeDetailListItemStyle(theme),
                }}
                key={index}
            >
               
                <theme.getIconButtonUi 
                    theme={theme}
                    icon={CloseIcon}
                    className="test-exchangeDetailViewRemoveBtn"
                    onClick={
                        /* istanbul ignore next */
                        event => { this.props.onRemoveExchange(index) }
                    }
                    customContainerStyle={{
                        ...theme.getExchangeDetailCloseIconStyle(theme),
                    }}
                />
                
                <div style={{
                    ...theme.getExchangeDetailAskingStyle(theme),
                    ...theme.getMediumTextStyle(theme),
                    
                }}>
                    <div style={{
                        ...theme.getAbstractSingleFieldContainerStyle(theme),
                    }}>
                        { 
                            exchangeDetail.type === ExchangeTypeEnum.EXCHANGE_TYPE_FREE ? 
                                typeCopy : 
                                `${typeCopy}: ${askingText}` 
                        }
                    </div>
                    { isPublic ? '' : ' Private ( Friends Only )' }
                </div>
                
                
                
            </div>
        );
    }

    getEmptyListUi()
    {
        return (
            <div style={{
                ...theme.getAbstractCenterFlexContainerStyle(theme)  
            }}>
                <p>
                    Nothing Added.
                </p>
            </div>
        );
    }

    getListUi()
    {
        return this.props.exchangeList.length < 1 ? this.getEmptyListUi() : this.props.exchangeList.map(this.getListItemUi);
    }

    setupOnlinePaymentUi()
    {
        return this.props.payAccountLoaded && !this.props.payAccountSetupComplete ? (
            <p>
                <NavLink to="/profile/income">
                    <span style={{
                        ...theme.getWarningTextStyle(theme),
                        ...theme.getGeneralTextLinkStyle(theme)
                    }}>
                        { this.props.customSetupOnlinePaymentsCopy ? this.props.customSetupOnlinePaymentsCopy : 'Setup online payments' }
                    </span>
                </NavLink>
            </p>
        ) : (
            ( this.props.payCurrencyList.length < 1 ) ? 
                <p>
                    <NavLink to="/payout/add">
                        <span style={{
                            ...theme.getWarningTextStyle(theme),
                            ...theme.getGeneralTextLinkStyle(theme),
                        }}>
                            Add a payout account to receive online payments
                        </span>
                        
                    </NavLink>
                </p> : ''
        );
    }

    /* istanbul ignore next */
    onAddOnlinePaymentExchange(details)
    {
        this.triggerOnAddExchange(details);
        let nextState = this.state;
        nextState.dialogOpen = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onAddCashPaymentExchange(details)
    {
        this.triggerOnAddExchange(details);
        let nextState = this.state;
        nextState.dialogOpen = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onAddUSPSPaymentExchange(details)
    {
        this.triggerOnAddExchange(details);
        let nextState = this.state;
        nextState.dialogOpen = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onSetTypeField(type)
    {
        let nextState = this.state;
        nextState.type.value = type;
        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    getOnlinePaymentUi(buttonText)
    {
        return <AddOnlinePaymentExchangePrompt 
            buttonText={buttonText} 
            extraCopy={this.props.extraOnlinePaymentCopy }           
            onAddOnlinePaymentExchange={this.onAddOnlinePaymentExchange} 
            onSetTypeField={this.onSetTypeField}
            payCurrencyList={this.props.payCurrencyList}
            paidAccount={this.props.paidAccount}
            asking={this.props.asking}
        />
    }

    /* istanbul ignore next */
    getCashPaymentUi(buttonText)
    {
        return <AddCashPaymentExchangePrompt
            buttonText={buttonText}
            onAddCashPaymentExchange={this.onAddCashPaymentExchange}
            onSetTypeField={this.onSetTypeField}
            asking={this.props.asking}
            skipOnlinePayValidation={this.props.skipOnlinePayValidation}
            payAccountLoaded={this.props.payAccountLoaded}
            payAccountSetupComplete={this.props.payAccountSetupComplete}
            payCurrencyList={this.props.payCurrencyList}
        />
    }

    /* istanbul ignore next */
    getUSPSPaymentUi(buttonText)
    {
        return <AddUSPSPaymentExchangePrompt
            buttonText={buttonText}
            onAddUSPSPaymentExchange={this.onAddUSPSPaymentExchange}
            onSetTypeField={this.onSetTypeField}
            asking={this.props.asking}
            skipOnlinePayValidation={this.props.skipOnlinePayValidation}
            payAccountLoaded={this.props.payAccountLoaded}
            payAccountSetupComplete={this.props.payAccountSetupComplete}
            payCurrencyList={this.props.payCurrencyList}
        />
    }

    /* istanbul ignore next */
    getDialogTitleCopy(type)
    {
        switch(type)
        {
            case ExchangeTypeEnum.EXCHANGE_TYPE_ONLINE:
                return 'Add Online Payment Exchange Option';

            case ExchangeTypeEnum.EXCHANGE_TYPE_USPS_CASH_ORDER:
                return 'Add USPS Money Order Exchange Option';

            case ExchangeTypeEnum.EXCHANGE_TYPE_FREE:
                return 'Add Free Exchange Option';

            case ExchangeTypeEnum.EXCHANGE_TYPE_CASH:
                return 'Add Cash Exchange Option';

            default:
                return 'Add Trade Exchange Option';
        }
    }

    /* istanbul ignore next */
    getDialogTitleUi()
    {
        return (
            <h3>
                { this.getDialogTitleCopy(this.state.type.value) }
            </h3>
        );
    }

    /* istanbul ignore next */
    getDialogUi(buttonText)
    {
        return (
            <div style={{
                ...theme.getAbstractTextAlignCenterStyle(theme),
            }}>

                { this.getDialogTitleUi() }

                {
                    !this.props.skipOnlinePayValidation ? this.setupOnlinePaymentUi() : ''
                }

                <div style={{
                    ...theme.getAbstractPromptStyle(theme),
                }}>
                    { 
                        this.state.type.value === ExchangeTypeEnum.EXCHANGE_TYPE_ONLINE ?
                            this.getOnlinePaymentUi(buttonText) : (
                                this.state.type.value === ExchangeTypeEnum.EXCHANGE_TYPE_USPS_CASH_ORDER ? 
                                    this.getUSPSPaymentUi(buttonText) : (
                                        this.state.type.value === ExchangeTypeEnum.EXCHANGE_TYPE_CASH ? 
                                            this.getCashPaymentUi(buttonText) : 
                                                super.getPromptUi(buttonText) 
                                    )
                            )
                    }
                </div>

                {
                    [
                        ExchangeTypeEnum.EXCHANGE_TYPE_CASH,
                        ExchangeTypeEnum.EXCHANGE_TYPE_ONLINE,
                        ExchangeTypeEnum.EXCHANGE_TYPE_FREE,
                        ExchangeTypeEnum.EXCHANGE_TYPE_USPS_CASH_ORDER
                    ].includes(this.state.type.value) ? '' :
                    <p style={{
                        ...theme.getSmallTextStyle(theme),
                    }}>
                        If asking for cash, please make sure to specify which currency
                    </p>
                }
                {
                    [
                        ExchangeTypeEnum.EXCHANGE_TYPE_USPS_CASH_ORDER
                    ].includes(this.state.type.value) &&
                    <p style={{
                        ...theme.getSmallTextStyle(theme),
                    }}>
                        *USPS limits and rates are estimates.  
                        See <a rel="noreferrer" href="https://www.usps.com/shop/money-orders.htm" target="_blank">USPS.com</a>&nbsp;
                        for up to date details.
                    </p>
                }
            </div>
        );
    }

    getPromptUi(buttonText = 'Add')
    {
        return (
            <section>
                <h2>
                    { this.props.promptTitle }
                </h2>

                <p>
                    List the types of transactions that interest you.
                </p>

                <div style={{
                ...theme.getAttributeFlexContainerStyle(theme),
                }}>

                    <theme.getIconButtonUi
                        theme={theme}
                        icon={AddOneIcon}
                        onClick={
                            /* istanbul ignore next */
                            event => { this.openDialog() }
                        }
                    />

                </div>

                <Dialog
                    onClose={this.onCloseDialog}
                    open={this.state.dialogOpen}
                >
                    { this.getDialogUi(buttonText) }
                    
                </Dialog>


                <div style={{
                    ...theme.getAttributeListStyle(theme),
                }}>
                    { this.getListUi() }
                </div>
                
            </section>
        );
    }
}

export default ExchangeDetailViewPrompt;
