import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminSearchLogsPrompt from './../ui/prompt/admin/admin-search-logs';
import theme from './../ui/theme';

const AdminSearchLogsPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            Logs
        </h2>
        <AdminSearchLogsPrompt />
    </AdminPageTemplate>

export default AdminSearchLogsPage;
