export const getAdminAddTagContainerStyle = (theme) => ({
    width: '530px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
});

export const getAdminTagLineStyle = (theme) => ({
    textAlign: 'left',
    paddingBottom: '18px'
});

export const getAdminTagLineButtonStyle = (theme) => ({
    marginRight: '10px'
});
