import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../../../theme';
import ResizeService from './../../../../../service/resize';

const LoadedContainer = ({ children }) => {
    const getBodyStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={bodyStyle}>
                <div>
                    My Platforms
                </div>
            </div>
            { children }
        </section>
    );
};

export default LoadedContainer;
