import React from 'react';
import PrevButton from './prev-button';
import NextButton from './next-button';
import SkipButton from './skip-button';
import theme from '../../../../theme';

const Menu = ({ 
    updateCrawler,
    exitOnboarding,
    selectedFrame
}) => {

    return (
        <div style={{
            ...theme.getOnboardingDynamicButtonStyle(theme),
        }}>
            <PrevButton
                selectedFrame={selectedFrame}
                updateCrawler={updateCrawler}
            />

            {
                selectedFrame && selectedFrame.next && selectedFrame.next.title === 'Exit' ? '' : 
                <SkipButton
                    exitOnboarding={exitOnboarding}
                />
            }
            
            <NextButton
                selectedFrame={selectedFrame}
                updateCrawler={updateCrawler}
                exitOnboarding={exitOnboarding}
            />
        </div>
    );
}
export default Menu;
