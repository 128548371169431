import React from 'react';
import AdminPageTemplate from './template/admin-page';
import ReportedIssuePanel from './../ui/panel/admin/reported-issue';
import { useParams } from 'react-router-dom';

const ReportedIssuePage = () => {
    const { appIssueId } = useParams();
    return (
        <AdminPageTemplate>
            <ReportedIssuePanel appIssueId={appIssueId} />
        </AdminPageTemplate>
    );
};

export default ReportedIssuePage;
