export const getAddOnlinePaymentExchangePromptPriceTitleStyle = (theme) => ({
    float: 'left',
    width: '140px',
    textAlign: 'left'
});

export const getAddOnlinePaymentExchangePromptPriceValueStyle = (theme) => ({
    float: 'left',
    width: '140px',
    textAlign: 'right'
});

export const getAddOnlinePaymentExchangePromptPriceContainerStyle = (theme) => ({
    width: '285px',
    marginLeft: 'auto',
    marginRight: 'auto'
});
