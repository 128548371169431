import React from 'react';
import CurrencyService from '../../../service/currency';
import theme from '../../theme';

const QuoteUnavailable = () => 
<div>
    <p>
        Quote Unavailable
    </p>
</div>

const ShipQuote = ({ quote }) => 
    <div>
        <p>
            { CurrencyService.getDisplayPrice('usd', quote.cost) }*
        </p>
    </div>

const FreeSetupShippingCompany = ({ 
    company, 
    onSelected, 
    quote 
}) => {
    const onClick = event => { onSelected(company) };
    return (
        <div style={{
            ...theme.getOutOfNetworkQuoteBoxStyle(theme)  
        }}>
            <h4>
                { company.name }
            </h4>
            {
                quote ? 
                    <ShipQuote quote={quote} /> : 
                        <QuoteUnavailable />
            }
            <h5>Actions</h5>
            <div style={{
                ...theme.getOutOfNetworkQuoteBoxActionStyle(theme)
            }}>
                <a 
                    href={company.pickUpUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={onClick}
                    style={{
                        ...theme.getGeneralTextLinkStyle(theme)
                    }}
                >
                    Schedule Pickup
                </a>
            </div>
            <div style={{
                ...theme.getOutOfNetworkQuoteBoxActionStyle(theme)
            }}>
                <a 
                    href={company.dropOffUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={onClick}
                    style={{
                        ...theme.getGeneralTextLinkStyle(theme)
                    }}
                >
                    Drop Off
                </a>
            </div>
        </div>
    );
};

export default FreeSetupShippingCompany;
