export const getPayNewCardContainerStyle = (theme) => ({
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    width: '100%',
    margin: '0 auto 2rem', 
    textAlign: 'center',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    display: 'flex',
    height: 'fit-content',
    alignItems: 'center',
    flexDirection: 'column',
});

export const getPayNewCardButtonContainerStyle = (theme) => ({
    paddingTop: '.75rem'
});
