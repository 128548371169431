import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../service/resize';
import theme from './../theme';
import { NavLink } from 'react-router-dom';

const list = [
    {
        title: 'Payouts',
        url: '/admin/payouts',
        details: 'Manage system payouts'
    },
    {
        title: 'Disputed Transactions',
        url: '/admin/disputed',
        details: 'Search and review disputed transactions'
    },
    {
        title: 'Fines',
        url: '/admin/fines/search',
        details: 'Search Fine Records',
    },
    {
        title: 'Ledger',
        url: '/admin/ledger',
        details: 'Manage system ledger details'
    },
    {
        title: 'Ledger Summary',
        url: '/admin/ledger/summary',
        details: 'System ledger summary details'
    }
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const AdminMoneyPanel = () => {
    const getContainerStyle = () => theme.getProfilePwdSuggestionOuterContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme),  
        }}>
            <div style={containerStyle}>
                {
                    list.map((item, index) => <Shortcut details={item.details} title={item.title} url={item.url} key={index} />)
                }
            </div>
        </div>
    );
};

export default AdminMoneyPanel;
