import ValidateService from './../../../../../service/validate';

const makeOnGasPriceBufferUpdated = ({
    setGasPriceBufferValid,
    setGasPriceBuffer,
    onSetPayout,
    record,
    useGasPriceGlobal
}) => event => {
    const value = event.target.value;
    const isValid = ValidateService.currencyInput(value);
    setGasPriceBufferValid(isValid);
    setGasPriceBuffer(value);
    if(isValid)
    {
        onSetPayout({
            record,
            payout: null,
            useGlobalMpg: null,
            mpg: null,
            useGasPriceGlobal,
            gasPrice: value
        });
    }
};

export default makeOnGasPriceBufferUpdated;
