import React from 'react';
import AuthPageTemplate from './template/auth-page';
import TaskCompletedRedirectPanel from './../ui/panel/task-completed-redirect';
import { useParams } from 'react-router-dom';

const TaskCompletedRedirectPage = () => {
    const {redirectType} = useParams();
    return (
        <AuthPageTemplate>
            <TaskCompletedRedirectPanel redirectType={redirectType} />
        </AuthPageTemplate>
    );
};

export default TaskCompletedRedirectPage;
