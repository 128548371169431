import React from 'react';
import AdminPageTemplate from './template/admin-page';
import theme from './../ui/theme';
import AdminClientStatsPanel from './../ui/panel/admin/admin-client-stats';

const AdminClientStatsPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            Client Details
        </h2>
        <AdminClientStatsPanel />
    </AdminPageTemplate>

export default AdminClientStatsPage;
