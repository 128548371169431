import React from 'react';
import { useState } from 'react';
import theme from './../theme';
import Dialog from '@mui/material/Dialog';

const ItemAuthorMenu = ({ item, onArchiveItem }) => {
    const [ modalOpen, setModalOpen ] = useState(false);
    return (
        <div style={{
            ...theme.getItemAuthorMenuStyle(theme),
        }}>
            <button 
                className="test-itemAuthorArchiveItem"
                onClick={event => { setModalOpen(true) }}
            >
                Remove Post
            </button>

            <Dialog
                onClose={ 
                    /* istanbul ignore next */
                    event => { setModalOpen(false) } 
                }
                open={modalOpen}
            >
                <div style={{
                    ...theme.getConfirmArchiveItemModalContainerStyle(theme)
                }}>
                    <div style={{
                        ...theme.getConfirmArchiveItemModalTextStyle(theme)
                    }}>
                        Are you sure you want to remove this post?
                    </div>
                    <div>
                        <button onClick={event => { onArchiveItem(item) }}>
                            Delete
                        </button>
                        <button onClick={event => { setModalOpen(false) }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
    
export default ItemAuthorMenu;
