import React from 'react';

const SetupDeliveryMainMenu = ({ 
    setSelectedView
}) => 
    <div>
        <div>
            <button 
                className="test-exchangeDetailSetupDeliveryMainMenuScheduleBtn"
                onClick={event => { setSelectedView('schedule') }}
            >
                Schedule Package Pick Up / Drop Off
            </button>
        </div>
        <div>
            <button 
                className="test-exchangeDetailSetupDeliveryMainMenuAlreadyContactedBtn"
                onClick={event => { setSelectedView('sent') }}
            >
                I've already contacted a shipping company
            </button>
        </div>
    </div>

export default SetupDeliveryMainMenu;
