import React from 'react';
import theme from './../../../../theme';
import Contribution from './contribution';

const Contributions = ({
    contributionsList
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <h4>
            Contributions 
        </h4>
        
        {
            contributionsList.map((con, index) => 
                <Contribution 
                    contribution={con}
                    key={index}
                />
            )
        }
    </div>

export default Contributions;
