import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';

class TagService {

    static getRemoveRequest(id)
    {
        return {
            id
        };
    }

    /* istanbul ignore next */
    static remove({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.removeTag,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddRequest(title)
    {
        return {
            title
        };
    }

    /* istanbul ignore next */
    static add({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.admin.addTag,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListRequest(page) 
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static list({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.tag.list,
            request: {
                page: 0
            },
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getListPopulatedSeekRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static listPopulatedSeek({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.tag.populated.seek,
            request: {},
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListPopulatedOfferRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static listPopulatedOffer({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.tag.populated.offer,
            request: {},
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static parseTagsToUrl(tags)
    {
        if(tags.length < 1)
        {
            return '';
        }

        const encodedList = tags.map(tag => encodeURI(tag));
        return encodedList.join('|');
    }            

    static parseUrlTags(raw)
    {
        try
        {
            if(raw.trim() === '')
            {
                return [];
            }
        
            return raw.split("|");
        }
        catch(err)
        {
            return [];
        }
    }
}

export default TagService;
