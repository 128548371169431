import endpointRequest from './endpoint-request';
import endpointFileUpload from './endpoint-file-upload';
import config from './../config';
import ErrorService from './error';

class IssueService {
    
    static getSpeakRequest(appIssueId, message, savedImgList)
    {
        return {
            appIssueId,
            message,
            savedImgList,
        };
    }

    static speak({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointFileUpload({
            url: config.url.report.speak,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
    
    static getReportedIssueDetailsRequest(appIssueId, page)
    {
        return {
            appIssueId, 
            page
        };
    }
    
    static getReportedIssueDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.report.issueDetails,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
    
    static getMyReportedIssuesRequest(page) 
    {
        return {
            page
        };
    }

    static getMyReportedIssues({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.report.listIssues,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
    
    static getUserReportedIssuesRequest(reporterUserId, page)
    {
        return {
            reporterUserId,
            page
        };
    }

    /* istanbul ignore next */
    static getUserReportedIssues({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.userReportedIssues,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
    
    static getReportAppIssueRequest(details)
    {
        return {
            details
        };
    }

    /* istanbul ignore next */
    static reportAppIssue({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.report.issue,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getListReportedIssuesRequest(page)
    {
        return {
            page,
        };
    }

    /* istanbul ignore next */
    static listReportedIssues({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.listReportedIssues,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getReportedIssueRequest(appIssueId)
    {
        return {
            appIssueId
        };
    }

    /* istanbul ignore next */
    static getReportedIssue({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.getReportedIssue,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getUpdateIssueRequest(appIssueId, unseen)
    {
        return {
            appIssueId,
            unseen,
        };
    }

    /* istanbul ignore next */
    static updateIssue({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.updateReportedIssue,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default IssueService;
