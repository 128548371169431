import React from 'react';

const NextButton = ({
    selectedFrame,
    updateCrawler,
    exitOnboarding
}) => {
    const nextButtonText = selectedFrame.next.title;
    const onNext = () => {
        // istanbul ignore next
        if(selectedFrame.next.isExit)
        {
            // istanbul ignore next
            exitOnboarding();   
        } else {
            updateCrawler(
                selectedFrame.next.nextCrawler
            );
        }
    };

    return (
        <button 
            className="test-onboardingNextBtn"
            onClick={event => {
                onNext();
            }}
        >
            { nextButtonText }
        </button>
    );
};

export default NextButton;
