import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../service/resize';
import theme from './../theme';
import { NavLink } from 'react-router-dom';

const list = [
    {
        title: 'News',
        url: '/admin/news/add',
        details: 'Publish news stories to users'
    },
    {
        title: 'Tags',
        url: '/admin/tag/add',
        details: 'Manage tags for user post searches'
    },
    {
        title: 'Platforms',
        url: '/admin/trail/add',
        details: 'Manage platforms'
    },
    {
        title: 'Test Email',
        url: '/admin/test/email',
        details: 'Send Test Email'
    },
    {
        title: 'Maintance Mode',
        url: '/admin/maintance-mode',
        details: 'Trigger jobs that are only enabled in system maintance mode'
    }
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const AdminWikiPanel = () => {
    const getContainerStyle = () => theme.getProfilePwdSuggestionOuterContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme),  
        }}>
            
            <div style={containerStyle}>
                {
                    list.map((item, index) => <Shortcut details={item.details} title={item.title} url={item.url} key={index} />)
                }
            </div>
        </div>
    );
};

export default AdminWikiPanel;
