import React from 'react';
import DetailPanel from './../../core/detail';
import IssueService from './../../../../service/issue';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import ReportedIssuesViewPanel from './view';

class _ReportedIssuesPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onArchive = this.onArchive.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onArchive(appIssueId)
    {
        const request = {
            appIssueId,
            unseen: false,
        };
        super.loadData({ });
        IssueService.updateIssue({
            request,
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        IssueService.listReportedIssues({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest({ page })
    {
        return {
            page
        };
    }

    componentDidMount()
    {
        this.loadData({ page: 0 });
    }

    getLoadedDataDetailUi()
    {
        return <ReportedIssuesViewPanel data={this.state.data} onArchive={this.onArchive} />
    }
}

const ReportedIssuesPanel = connect(mapStateToProps, mapDispatchToProps)(_ReportedIssuesPanel);

export default ReportedIssuesPanel;