import React from 'react';
import theme from './../../../theme';
import CurrencyService from './../../../../service/currency';
import makeGetGrossTotal from './../core/get-gross-total';

const PriceTotals = ({
    price,
    pendingPayoutUpdates
}) => {
    const getGrossTotal = makeGetGrossTotal({
        price,
        pendingPayoutUpdates
    });
    const grossTotal = getGrossTotal();
       
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <h3>
                Price
            </h3>

            <p>
                { `Total Payouts: ${CurrencyService.usd(grossTotal, false)}` }
            </p>

        </div>
    );
};

export default PriceTotals;
