import config from './../config';

const resizeEvent = 'resize';

class ResizeService 
{
    // returns debouncing onResize method
    static getOnResize(onResize)
    {
        /* istanbul ignore next */
        return () => {
            var resizeServiceTimeoutId = null;
           
            if(resizeServiceTimeoutId)
            {
                clearTimeout(resizeServiceTimeoutId);
                resizeServiceTimeoutId = null;
            }

            resizeServiceTimeoutId = setTimeout(
                onResize, 
                config.interval.reloadOnResize
            );
        
        };
    }

    static buildOnResize(onResizeMethod)
    {
        const onResize = this.getOnResize(onResizeMethod);
        // this returned method should be passed to a useEffect call
        return () => {
            this.startListening(onResize);
            return () => {
                this.stopListening(onResize);
                
            };
        };
    }

    static triggerResizeEvent()
    {
        const event = new Event(resizeEvent);
        window.dispatchEvent(event);
    }

    static startListening(onResize)
    {
        window.addEventListener(resizeEvent, onResize);
    }

    static stopListening(onResize)
    {
        window.removeEventListener(resizeEvent, onResize);
    }
}

export default ResizeService;
