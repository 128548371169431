import React from 'react';
import theme from './../../theme';
import CurrencyService from './../../../service/currency';
import {
    EXCHANGE_TYPE_CASH,
    EXCHANGE_TYPE_FREE,
    EXCHANGE_TYPE_TRADE,
    EXCHANGE_TYPE_ONLINE, 
    EXCHANGE_TYPE_USPS_CASH_ORDER,
} from './../../prompt/core/enum/exchange-type';

const PostalOrderPanel = (ex, summaryMode) => 
    <div style={{
        ...theme.getExchangeListContainerStyle(theme)
    }}>
        <div style={{
            ...theme.getExchangeListLabelStyle(theme)
        }}>
            {
                summaryMode ? 'USPS Money Order' : 
                    <a href="https://www.usps.com/shop/money-orders.htm" target="_blank" rel="noreferrer"> 
                        USPS Money Order
                    </a>
            }
        </div>
        <div style={{
            ...theme.getExchangeListValueStyle(theme)
        }}>
            { CurrencyService.getDisplayPrice('usd', ex.asking) }
        </div>
    </div>

const CashExchangePanel = (ex) => 
    <div style={{
        ...theme.getExchangeListContainerStyle(theme)
    }}>
        <div style={{
            ...theme.getExchangeListLabelStyle(theme)
        }}>
            Cash
        </div>
        <div style={{
            ...theme.getExchangeListValueStyle(theme)
        }}>
            { CurrencyService.getDisplayPrice('usd', ex.asking) }
        </div>
    </div>

const OnlineExchangePanel = (ex) => 
    <div style={{
        ...theme.getExchangeListContainerStyle(theme)
    }}>
        <div style={{
            ...theme.getExchangeListLabelStyle(theme)
        }}>
            Online
        </div>
        <div style={{
            ...theme.getExchangeListValueStyle(theme)
        }}>
            { 
                CurrencyService.getDisplayPrice(ex.currency, ex.asking)
            }
        </div>
    </div>

const TradeExchangePanel = (ex) => 
    <div style={{
        ...theme.getExchangeListContainerStyle(theme)
    }}>
        <div style={{
            ...theme.getExchangeListLabelStyle(theme)
        }}>
            Trade
        </div>
        <div style={{
            ...theme.getExchangeListValueStyle(theme)
        }}>
            { ex.asking }
        </div>
    </div>

const FreeExchangePanel = (ex) => 
    <div style={{
        ...theme.getExchangeListContainerStyle(theme)
    }}>
        <div style={{
            ...theme.getExchangeListLabelStyle(theme)
        }}>
            Free
        </div>
        <div style={{
            ...theme.getExchangeListValueStyle(theme)
        }}>
            { ex.asking }
        </div>
    </div>

const SwitchExchangePanel = (ex, summaryMode) => {
    /* istanbul ignore next */
    switch(ex.type){
        case EXCHANGE_TYPE_ONLINE:
            return OnlineExchangePanel(ex);
        case EXCHANGE_TYPE_CASH:
            return CashExchangePanel(ex);
        case EXCHANGE_TYPE_USPS_CASH_ORDER:
            return PostalOrderPanel(ex, summaryMode);
        case EXCHANGE_TYPE_TRADE:
            return TradeExchangePanel(ex);
        case EXCHANGE_TYPE_FREE:
            return FreeExchangePanel(ex);
        default:
            return FreeExchangePanel(ex);
    }
};

const ExchangePanel = (ex, index, summaryMode) => 
    <div 
        style={{
            ...theme.getExchangeListPanelStyle(theme),
        }}
        key={index}>
        { SwitchExchangePanel(ex, summaryMode) }
    </div>

const ExchangeListPanel = ({ exchangeList, summaryMode = false, showCopyOnEmpty = false  }) => 
    <div>
        { 
            exchangeList.length > 0 ? 
            <section  style={{
                ...theme.getAbstractPostFlexStyle(theme),
                ...theme.getOfferAbstractFlexColumnStyle(theme),
            }}>
                {
                    summaryMode ? '' : 
                        <h4>
                            { exchangeList.length > 1 ? 'Exchange Options:' : 'Exchange Option:' }
                        </h4>
                }
                <div style={{
                ...theme.getAbstractFullWidthtStyle(theme),
                }}>
                    { exchangeList.map(( ex, index) => ExchangePanel(ex, index, summaryMode)) }
                </div>
            </section> : (
                showCopyOnEmpty ? 
                    <div>
                        <div style={{
                            ...theme.getExchangeListNoValueStyle(theme)
                        }}>
                            Make an Offer!
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div> : ''
            )
        }
    </div>

export default ExchangeListPanel;
