import React from 'react';
import DetailPanel from './../core/detail';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import LocationService from './../../../service/location';
import { 
    LoadedDataDetailUi,
    UnSelectedLocationUi,
    SelectedLocationUi
} from './view';

export class _LocationSelectorPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);
        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getSelectedLocationUi = this.getSelectedLocationUi.bind(this);
        this.getUnSelectedLocationUi = this.getUnSelectedLocationUi.bind(this);
        this.onRemoveSelected = this.onRemoveSelected.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.onSelectAdded = this.onSelectAdded.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({ });
        LocationService.list({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData();
    }

    getDefaultState()
    {
        return {
            ...super.getDefaultState(),
            selectedLocation: this.props.selectedLocation !== undefined ? this.props.selectedLocation : null
        }
    }

    getLoadedDataDetailUi()
    {
        return (
            <LoadedDataDetailUi promptTitle={this.props.promptTitle}>
                {
                    this.state.selectedLocation ? this.getSelectedLocationUi() : 
                        this.getUnSelectedLocationUi()
                }
            </LoadedDataDetailUi>
        );
    }

    onRemoveSelected()
    {
        let nextState = this.state;
        nextState.selectedLocation = null;
        this.setState(nextState);
        if(this.props.onRemoveSelected)
        {
            this.props.onRemoveSelected();
        }
    }

    onSelected(loc)
    {
        let nextState = this.state;
        nextState.selectedLocation = loc;
        this.setState(nextState);
        if(this.props.onSelected)
        {
            this.props.onSelected(loc);
        }
    }

    /* istanbul ignore next */
    onSelectAdded(response)
    {
        let nextState = this.state;
        const oldIdList = this.state.data.locations.list.map(i => i.id);
        const added = response.locations.list.find(i => !oldIdList.includes(i.id));
        if(added)
        {
            nextState.selectedLocation = added;
        }
        nextState.data = response;
        this.setState(nextState);

        if(this.props.onSelected && added)
        {
            this.props.onSelected(added);
        }
    }

    getSelectedLocationUi()
    {
        return (
            <SelectedLocationUi 
                selectedLocation={this.state.selectedLocation} 
                onRemoveSelected={this.onRemoveSelected} 
            />
        );
    }

    getUnSelectedLocationUi()
    {
        const allowLocationAdds = this.props.allowLocationAdds !== undefined ? this.props.allowLocationAdds : true;
        return (
            <UnSelectedLocationUi 
                locations={this.state.data.locations.list} 
                onSelected={this.onSelected} 
                onSelectAdded={this.onSelectAdded}
                includeGps={this.props.includeGps}
                allowLocationAdds={allowLocationAdds}
            />
        );
    }

}

const LocationSelectorPanel = connect(mapStateToProps, mapDispatchToProps)(_LocationSelectorPanel);
export default LocationSelectorPanel;
