import UserActionService from './../../../../service/user-action';
import UserActionTypeEnum from './../../core/enum/user-action-type';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';

class _AdminAddUserPermissionPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _AdminAddUserPermissionPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();
    }
    
    static getValidators()
    {
        return {
            type: {
                isValid: UserActionTypeEnum.isValid
            },
            allowAccess: {
                isValid: ValidateService.anyValue
            },
            notes: {
                isValid: ValidateService.textLines
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            type: {
                name: 'Action',
                error: 'Specify a user action',
                value: this.props.availableDropDownValues[0].value,
                valueList: this.props.availableDropDownValues,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            allowAccess: {
                name: 'Allow Action',
                error: 'Specify if action can be performed by this user',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            notes: {
                name: 'Notes',
                error: 'Specify details about permission, no more than 2170 characters',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }


    onSendToServer()
    {
        UserActionService.adminAdd({
            request: this.getServerRequest(),
            onResponse: this.props.onLoadDataResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            targetUserId: this.props.targetUserId,
            type: this.state.type.value,
            allowAccess: this.state.allowAccess.value,
            notes: this.state.notes.value,
        };
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Permissions Updated')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Save')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const AdminAddUserPermissionPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminAddUserPermissionPrompt);
export default AdminAddUserPermissionPrompt;
