import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';

const TermsAndConditionsCopyPanel = (parms) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                Terms of Service
            </h2>
            <div style={bodyStyle}>

                Effective Date: 9/01/2023.
                <br />
                <br />
                By creating a Bears.Exchange Account or using any Bears.Exchange Services you are agreeing to be bound by the following terms and conditions. (the "Terms of Service").
                <br />
                <br />
                As used in these Terms of Service, "we", "us", "our" and "Bears.Exchange" means Bears Exchange LLC, and "you" means the Bears.Exchange User (if the account is used for an individual), or the business employing the Bears.Exchange User (if the account is using the Bears.Exchange service as a business) and any of its affiliates.
                <br />
                <br />
                The services offered by Bears.Exchange under these Terms of Service include various products and services that enable you to give, trade, and sell goods and services to other Bears.Exchange Users.  Any such service or services offered by Bears.Exchange are referred to in these Terms of Services as the "Service(s)".  Any new features or tools which are added to the current Services will also be subject to the Terms of Service.  You can review the current version of the Terms of Service at any time at https://bears.exchange/#/terms.
                <br />
                <br />
                You must read, agree with and accept all of the terms and conditions contained or expressly referenced in these Terms of Service, including Bears.Exchange's &nbsp;
                <NavLink 
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to="/privacy"
                >
                    Privacy Policy.
                </NavLink>
            

                <ol>
                    <li>
                        Account Terms
                        <ol>
                            <li>
                                To access and use the Services, you must register for a Bears.Exchange account ("Account"). To complete your Account registration, you must provide us with a valid email address and any other information indicated as required.  Bears.Exchange may reject your application for an Account, or cancel an existing Account, for any reason, at our sole discretion.
                            </li>
                            <li>
                                To access some Services you must provide us with your full legal name, business address, phone number, a valid email address, and any other information indicated as required.
                            </li>
                            <li>
                                You must be the older of (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from which you use the Services to open an Account.
                            </li>
                            <li>
                                You acknowledge that Bears.Exchange will use the email address you provide on opening an Account or as updated by you from time to time as the primary method for communication with you. ("Primary Email Address"). You must monitor the Primary Email Address you provide to Bears.Exchange and your Primary Email Address must be capable of both sending and receiving messages.  Your email communications with Bears.Exchange can only be authenticated if they come from your Primary Email Address.
                            </li>
                            <li>
                                You are responsible for keeping your account access secure.  Bears.Exchange cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account, 2FA token, and/or password.
                            </li>
                            <li>
                                You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Services, or access to the Services without the express written permission by Bears.Exchange.
                            </li>
                            <li>
                                You agree not to work around, bypass, or circumvent any of the technical limitations of the Services, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble, or otherwise reverse engineer the Services.
                            </li>
                            <li>
                                You agree not to access the Services or monitor any material or information from the Services using any robot, spider, scraper, or other automated means.
                            </li>
                            <li>
                                You understand that your Materials may be transferred unencrypted and involve (a) transmissions over various networks: and (b) changes to conform and adapt to technical requirements of connecting networks or devices.  "Materials" means Your Trademarks, copyright content, any products services you sell through the Services (including description and price), and any photos, images, videos, graphics written content, audio files, code, information, or other data provided or made available by you or your affiliates to Bears.Exchange or its affiliates.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Account Activation
                        <ol>
                            <li>
                                If you are signing up for the Services on behalf of your employer, your employer will be the Account Owner.  If you are signing up for the Services on behalf of your employer, then you must use your employer-issued email address and you represent and warrant that you have the authority to bind your employer to our Terms of Service.
                            </li>
                            <li>
                                Subject to the previous condition, the person signing up for the Service by opening an Account will be the contracting party ("Account Owner") for the purposes of our Terms of Service and will be the person who is authorized to use any corresponding Account we may provide to the Account Owner in connection with the Service.
                            </li>
                            <li>
                                Your Bears.Exchange postings can only be associated with one Account Owner.
                            </li>
                            <li>
                                Some Services will require a Stripe Connected Account.  If these services are enabled by you then Bears.Exchange will create a Stripe Connected Account on your behalf, using your Primary Email Address.
                            </li>
                            <li>
                                You acknowledge that the Stripe Connected Account will be your default online payment gateway(s) and that it is your sole responsibility as the Account Owner to activate and maintain this account.  If you do not wish to keep the account active, it is your responsibility to deactivate it.  For the avoidance of doubt, Stripe is a Third Party Service.
                            </li>
                            <li>
                                By using a Stripe Connected Account, you are agreeing to be bound by the <a href="https://stripe.com/connect-account/legal" target="_blank" rel="noreferrer">Stripe Connected Account Agreement</a>, and the <a href="https://stripe.com/privacy" target="_blank" rel="noreferrer">Stripe Privacy Policy</a>  including amendments that may be posted from time to time, effective as of the date of posting.  Your continued use of the Stripe Connected Account after the amended terms are posted constitutes your agreement to, and acceptance of the amended Stripe Connected Account Agreement and Stripe Privacy Policy.  If you do not agree to any changes to the Stripe Connected Account Agreement or Stripe Privacy Policy, de-activate your Stripe Connected Account and do not continue to use the related Bears.Exchange Services.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Bears.Exchange Rights
                        <ol>
                            <li>
                                The Services have a range of features and functionalities.  Not all Services or features will be available to all Users at all times and we are under no obligation to make any Services or features available in any jurisdiction.  Except where prohibited in these Terms of Service or by applicable law, we reserve the right to modify the Services or any part thereof for any reason without notice and at any time.
                            </li>
                            <li>
                                Bears.Exchange does not pre-screen Materials and it is in our sole discretion to refuse or remove any Materials from any part of the Services, including if we determine in our sole discretion that the goods or services that you offer or seek through the Services, or the Materials uploaded or posted to the Services, violate our Terms of Service.
                            </li>
                            <li>
                                Verbal or written abuse of any kind (including threats of abuse or retribution) of any Bears.Exchange employee, member or officer will result in immediate Account termination.
                            </li>
                            <li>
                                We reserve the right to provide our Services to your competitors and make no promise of exclusivity.  You further acknowledge and agree that Bears.Exchange employees and contractors may also be Bears.Exchange Users and that they may compete with you, although they may not use your Confidential Information in doing so.
                            </li>
                            <li>
                                In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership.  Documentation may include, but is not limited to, a scanned copy of your business license, government issued photo ID, the last four digiits of the credit card on file, or confirmation of your status as an employee of an entity.
                            </li>
                            <li>
                                Bears.Exchange reserves the  right to determine, in our sole discretion, rightful Account ownership and transfer an Account to the rightful Account Owner.  If we are unable to reasonably determine the rightful Account Owner, without prejudice to our other rights and remedies, Bears.Exchange reserves the right to temporarily suspend or disable an Account until resolution has been determined between the disputing parties.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Your Responsibilities
                        <ol>
                            <li>
                                You acknowledge and agree that the Services are not a marketplace, and any contract of gift or exchange made through the Services is directly between you and the other User.  You are the seller of record for all items you sell or otherwise provide through the Services.  You are responsible for the creation and operation of your Bears.Exchange postings, your Materials, the goods and services that you may provide through the Services, and all aspects of the transactions between you and your customer(s).  This includes, but is not limited to authorizing the charge to the customer in respect of the customer's purchases, refunds, returns, fulfilling any sales or customer service, fraudulent transactions, required legal disclosures, regulatory compliance, alleged or actual violation of applicable laws (including but not limited to consumer protection in any jurisdiction where you offer products or services for sale), of your breach these Terms of Service.  You represent and warrant that your postings, your Materials, and the goods and services you sell through the Services will be true, accurate, and complete, and will not violate any applicable laws, regulations or rights of third parties.  For the avoidance of doubt, Bears.Exchange will not be the seller or merchant of record and will have no responsibility for your postings or items sold to customers through the Services.
                            </li>
                            <li>
                                You are solely responsibly for the goods or services that you may gift or exchange through the Services (including description, price, fees, tax that you calculate, defects, required legal disclosures, regulatory compliance, offers or promotional content) including compliance with any applicable laws or regulations.
                            </li>
                            <li>
                                You may not use the Bears.Exchange Services for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws), the laws applicable to you in your customer's jurisdiction, or the laws of the United States and the State of Wyoming.  You will comply with all applicable laws, rules and regulations (including but not limited to obtaining and complying with the requirements of any license or permit that may be necessary to your activites or that may be held by you) in your use of the Service and your performance of obligations under the Terms of Service.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Payment of Fees and Taxes
                        <ol>
                            <li>
                                If subscribed, you will pay the Fees applicable to your subscription.  You will pay the Fees related to online payment transactions and any other applicable fees, including but not limited to applicable fees relating to the value of sales made through your exchanges when using online payments.  Together, the Subscription fees, Transaction Fees and the Additional Fees are referred to as the "Fees".
                            </li>
                            <li>
                                If subscribed, you must keep a valid payment method on file with us to pay for all incurred and recurring Fees.  Bears.Exchange will charge applicable Fees to any valid payment method that you authorize ("Authorized Payment Method"), and Bears.Exchange will continue to charge the Authorized Payment Method for applicable Fees until the Services are terminated, and any and all outstanding Fees have been paid in full.  Unless otherwise indicated, all Fees and other charges are in US dollarrs, and all payments will be in US currency.
                            </li>
                            <li>
                                Subscription Fees are paid in advance and will be billed in monthly intervals (each such date, a "Billing Date").
                            </li>
                            <li>
                                Transaction Fees are deducted from online transactions prior to payout.
                            </li>
                            <li>
                                Chargeback Fees are billed in response to online payment chargebacks.  A chargeback occurs from an online payment transaction dispute loss.  
                                If you have a stored payment method that has been specified as your default payment method then your chargeback fee will be automatically paid with your default stored payment method.  
                                Until all outstanding fees have been paid, unpaid chargeback fees will deactivate some services including but not limited to receiving payouts, receiving new online payments, receiving new bids and creating new posts.
                            </li>
                            <li>
                                If we are not able to process payment of Fees using an Authorized Payment Method, we may make additional attempts to process payment using any Authorized Payment Method, typically 3, 5, then 7 days after the last failed attempt but Bears.Exchange may attempt sooner.  If the additional attempts are not successful, we may suspend and revoke the features associated with your subscription.
                            </li>
                            <li>
                                All Fees are exclusive of applicable federal, state, local or other governmental sales, goods and services, harmonized or other taxes, fees or changes now in force or enacted in the future ("Taxes").
                            </li>
                            <li>
                                You are responsible for all applicable Taxes that arise from or as a result of your usage of Bears.Exchange's products and services.  You are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction.
                            </li>
                            <li>
                                For the avoidance of doubt, all sums payable by you to Bears.Exchange under these Terms of Service will be paid free and clear of any deductions or withholdings whatsoever.  Any deductions or withholdings that are required by law will be borne by you and paid separately to the relevant taxation authority.  Bears.Exchange will be entitled to charge the full amount of Fees stipulated under these Terms of Service to your Authorized Payment Method ignoring any such deduction or withholding that may be required.
                            </li>
                            <li>
                                You are solely responsible for determining, collecting, withholding, reporting, and remitting applicable taxes, duties, fees, surcharges and additional charges that arise from your use of the Services.  The Services are not a marketplace.  Any contract of sale made through the Services is directly between you and the customer.
                            </li>
                            <li>
                                You must maintain an accurate location in the Stripe Connected Account profile.  If you change jurisdictions you must promptly update your location.
                            </li>
                            <li>
                                Bears.Exchange does not provide refunds.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Confidentiality
                        <ol>
                            <li>
                                "Confidential Information" will include, but will not be limited to, any and all information associated with a party's business and not publicly known, including specific business information, technical processes and formulas, software, customer lists, prospective customer lists, names, addresses, and other information regarding customers and prospective customers, product designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial information, business plans, marketing data, and any other confidential and proprietary information, whether or not marked as confidential or proprietary.  Bears.Exchange's Confidential Information includes all information that you receive relating to us, or to the Services, that is not known to the general public including information related to our security program and practices.
                            </li>
                            <li>
                                Each party agrees to use the other party's Confidential Information solely as necessary for performing its obligations under these Terms of Service and in accordance with any other obligations in these Terms of Service.  Each party agrees that it will take all reasonable steps, at least substantially equivalent to the steps it takes to protect its own proprietary information, to prevent the duplication, disclosure or use of any such Confidential Information, other than (i) by or to its employees, agents, and subcontractors who must have access to such Confidential Information to perform such party's obligations hereunder, who each will treat such Confidential Information as provided herein, and who are each subject to obligations of confidentiality to such party that are at least as stringent as those contained herein; or (ii) as required by any law, regulation, or order of any court of proper jurisdiction over the parties and the subject matter contained in these Terms of Service, provided that, if legally permitted, the receiving party will give the disclosing party prompt written notice and use commercially reasonable efforts to ensure that such disclosure is accorded confidential treatment. Confidential Information will not include any information that the receiving party can prove: (A) was already in the public domain, or was already known by or in the possession of the receiving party, at the time of disclosure of such information; (B) is independently developed by the receiving party without use of or reference to the other party’s Confidential Information, and without breaching any provisions of these Terms of Service; or (C) is thereafter rightly obtained by the receiving party from a source other than the disclosing party without breaching any provision of these Terms of Service.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Limitation of Liability and Indemnification
                        <ol>
                            <li>
                                You expressly understand and agree that, to the extend permitted by applicable laws, Bears.Exchange and its suppliers will not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or relating to the use of or inability to use the Service or these Terms of Service (however arising, including negligence).
                            </li>
                            <li>
                                You agree to indemnify and hold us, affiliates, Bears.Exchange partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of (a) your breach of these Terms of Service or the documents it incorporates by reference; (b) or your violation of any law or the rights of a third party; or (c) any aspect of the transaction between you and your Customer, including but not limited to refunds, fraudulent transactions, alleged or actual violation of applicable laws (including but not limited to Federal and State consumer protection laws) or your breach of the Terms of Service.
                            </li>
                            <li>
                                You will be responsible for any breach of the Terms of Service by your affiliates, agents or subcontractors and will be liable as if it were your own breach.
                            </li>
                            <li>
                                Your use of the Services is at your sole risk.  The Services are provided on an "as is" and "as available" basis without any warranty or condition, express, implied or statutory.
                            </li>
                            <li>
                                Bears.Exchange does not warrant that the Services will be uninterrupted, timely, secure, or error-free.
                            </li>
                            <li>
                                Bears.Exchange does not warrant that the results that may be obtained from the use of the Services will be accurate or reliable.
                            </li>
                            <li>
                                Bears.Exchange is not responsible for any of your tax obligations or liabilities related to the use of Bears.Exchange Services.
                            </li>
                            <li>
                                Bears.Exchange does not warrant that the quality of any products, services, information, or other materials purchased or obtained by you through the Services will meet your expectations, or that any errors in the Services will be corrected.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Intellectual Property and Your Materials
                        <ol>
                            <li>
                                We do not claim ownership of the Materials you provide to Bears.Exchange; however, we do require a license to those Materials.  You grant Bears.Exchange a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to host, use, distribute, expose, modify, run, copy, store, publicly perform, communicate to the public (including by telecommunication), broadcast, reproduce, make available, display, and translate, and create derivative works of any Materials provided by you in connection with the Services.  We may use our rights under this license to operate, provide, and promote the Services and to perform our obligations and exercise our rights under the Terms of Service.  You represent, warrant, and agree that you have all necessary rights in the Materials to grant this license.  You irrevocably waive any and all moral rights you may have in the Materials in favor of Bears.Exchange and agree that this waiver may be invoked by anyone who obtains rights in the materials through Bears.Exchange, including anyone to whom Bears.Exchange may transfer or grant (including by way of license or sublicense) any rights in the Materials.
                            </li>
                            <li>
                                If you owned the Materials before providing them to Bears.Exchange then, despite uploading them to Bears.Exchange they remain yours, subject to any rights or licenses granted in the Terms of Service or elsewhere.  Termination or cancellation of your Account does not terminate any rights or licenses granted to the Materials that Bears.Exchange requires to exercise any rights or perform any obligations that arose during the Term.
                            </li>
                            <li>
                                You agree that Bears.Exchange can, at any time, review and delete any or all of the Materials submitted to the Services, although Bears.Exchange is not obligated to do so.
                            </li>
                            <li>
                                You grant Bears.Exchange a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to use the names, trademarks, service marks and logos associated with you (“Your Trademarks”) to operate, provide, and promote the Services and to perform our obligations and exercise our rights under the Terms of Service. This license will survive any termination of the Terms of Service solely to the extent that Bears.Exchange requires the license to exercise any rights or perform any obligations that arose during the Term.
                            </li>
                            <li>
                                You agree that you may not use any trademarks, logos, or service marks of Bears.Exchange, whether registered or unregistered, including but not limited to the word mark BEARS EXCHANGE, and the Bear carrying goods design mark ("Bears.Exchange Trademarks") unless you are authorized to do so by Bears.Exchange in writing.  You agree not to use or adopt any marks that may be considered confusing with the Bears.Exchange Trademarks.  You agree that any variations or misspellings of the Bears.Exchange Trademarks would be considered confusing with the Bears.Exchange Trademarks.
                            </li>
                            <li>
                                You agree not to purchase, register, or use search engine or other pay-per-click keywords (such as Google Ads), trademarks, email addresses, social media names, or domain names (including without limitation top-level domains, sub-domains, and page URLs) that use or include Bears.Exchange or Bears.Exchange Trademarks or that use or include any terms that may be confusing with the Bears.Exchange Trademarks.
                            </li>
                            <li>
                                You acknowledge and agree that the Terms of Service do not give you any right to implement Bears.Exchange patents.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Feedback and Reviews
                        <ol>
                            <li>
                                Bears.Exchange welcomes any ideas and/or suggestions regarding improvements or additions to the Services. Under no circumstances will any disclosure of any idea, suggestion or related material or any review of the Services, Third Party Services or any Third Party Provider (collectively, “Feedback") to Bears.Exchange be subject to any obligation of confidentiality or expectation of compensation.
                            </li>
                            <li>
                                By submitting Feedback to Bears.Exchange you waive any and all rights in the Feedback and that Bears.Exchange is free to implement and use the Feedback if desired, as provided by you or as modified by Bears.Exchange, without obtaining permission or license from you or from any third party.
                            </li>
                            <li>
                                Any reviews of a Third Party Service or Third Party Provider that you submit to Bears.Exchange must be accurate to the best of your knowledge, and must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable.
                            </li>
                            <li>
                                Bears.Exchange reserves the right (but not the obligation) to remove or edit Feedback of Third Party Services or Third Party Providers, but does not regularly inspect posted Feedback.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Privacy and Data Protection
                        <ol>
                            <li>
                                Bears.Exchange is firmly committed to protecting the privacy of your personal information and the personal information of your customers.  By using the Service you acknowledge and agree that Bears.Exchange's collection, usage and disclosure of this personal information is governed by our Privacy Policy.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Bears.Exchange Contracting Party
                        <ol>
                            <li>
                                The parties irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of the state of Wyoming with respect to any dispute or claim arising out of or in connection with the Terms of Service.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Term and Termination
                        <ol>
                            <li>
                                The term of these Terms of Service will begin on the date of your completed registration for use of a Service and continue until terminated by us or by you, as provided below (the "Term").
                            </li>
                            <li>
                                You may cancel your Account and terminate the Terms of Service at any time by contacting staff@bears.exchange and then following the specific instructions indicated to you in Bears.Exchange response.
                            </li>
                            <li>
                                Without limiting any other remedies, we may suspend or terminate your Account or the Terms of Service for any reason, without notice and at any time (unless otherwise required by law), including if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in fraudulent activity in connection with the use of the Services.  Termination of the Terms of Service will be without prejudice to any rights or obligations which arose prior to the date of termination.
                            </li>
                            <li>
                                Upon termination of the Services by either party for any reason:
                                <ul>
                                    <li>
                                        Bears.Exchange will cease providing you with the Services and you will no longer be able to access your Account;
                                    </li>
                                    <li>
                                        unless otherwise provided in the Terms of Service, you will not be entitled to any refunds of any Fees, pro rata or otherwise;
                                    </li>
                                    <li>
                                        any outstanding balance owed to Bears.Exchange for your use of the Services through the effective date of such termination will immediately become due and payable in full; and
                                    </li>
                                    <li>
                                        your Bears.Exchange postings will be taken offline.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Modifications
                        <ol>
                            <li>
                                We reserve the right, in our sole and absolute discretion, to update or change any portion of the Terms of Service at any time.  We will provide you with reasonable advance notice of changes to the Terms of Service that materially adversely affect your use of the Services or your rights under the Terms of Service by sending an email to the Primary Email Address, providing notice through the platform news service, or by similar means.  However, Bears.Exchange may make changes that materially adversely affect your use of the Services or your rights under the Terms of Service at any time and with immediate effect (i) for legal, regulatory, fraud, and abuse prevention, or security reasons: or (ii) to restrict products or activities that we deem unsafe, inappropriate, or offensive. Unless we indicate otherwise in our notice (if applicable), any changes to the Terms of Service will be effective immediately upon posting of such updated terms at this location.  Your continued access to or use of the Services after we provide such notice, if applicable, or after we post such updated terms, constitutes your acceptance of the changes and consent to be bound by the Terms of Service as amended.  If you do not agree to the amended Terms of Service, you must stop accessing and using the Services.
                            </li>
                            <li>
                                Bears.Exchange may change the Fees for the Services from time-to-time.  We will provide you with 30 days advanced notice prior to any changes in Fees by sending an email to the Primary Email Account, providing notice through the platform news service, or by similar means.  Bears.Exchange will not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Services (or any part thereof).
                            </li>
                        </ol>
                    </li>
                    <li>
                        General Conditions
                        <ol>
                            <li>
                                The Terms of Service, including the documents it incorporates by reference, constitute the entire agreement between you and Bears.Exchange and govern your use of the Services and your Account, superseding any prior agreements between you and Bears.Exchange (including but not limited to, any prior versions of the Terms of Service).
                            </li>
                            <li>
                                The failure of Bears.Exchange to exercise or enforce any right or provision of the Terms of Service will not constitute a waiver of such right or provision.  If any provision of the Terms of Service, including all terms and conditions and other documents it incorporates by reference, is held by a court of competent jurisdiction to be contrary to law, such provision will be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law, and the remaining provision of the Terms of Service will remain in full force and effect.
                            </li>
                            <li>
                                Save for Bears.Exchange and its affiliates, you or anyone accessing Bears.Exchange Services pursuant to these Terms of Service, unless otherwise provided in these Terms of Service, no person or entity who is not a party to these Terms of Service will have any right to enforce any term of these Terms of Service, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description.  For the avoidance of doubt, this will not affect the rights of any permitted assignee or transferee of these Terms.
                            </li>
                            <li>
                                The Terms of Service will be governed by and interpreted in accordance with the laws of the State of Wyoming and the laws of The United States applicable therein, without regard to principles of conflicts of laws.
                            </li>
                            <li>
                                All the terms and provisions of the Terms of Service will be binding upon and inure to the benefit of the parties to the Terms of Service and to their respective heirs, successors, permitted assigns and legal representatives.  Bears.Exchange will be permitted to assign these Terms of Service without notice to you or consent from you. You will have no right to assign or otherwise transfer the Terms of Service, or any of your rights or obligations hereunder, to any third party without Bears.Exchange prior written consent, to be given or withheld in Bears.Exchange's sole discretion.
                            </li>
                            <li>
                                If any provision, or portion of the provision, in these Terms of Service is, for any reason, held to be invalid, illegal or unenforceable in any respect, then such invalidity, illegality or unenforceability will not affect any other provision (or the unaffected portion of the provision) of the Terms of Service, and the Terms of Service will be construed as if such invalid, illegal or unenforceable provision, or portion of the provision, had never been contained within the Terms of Service.
                            </li>
                            <li>
                                On termination, all related rights and obligations under the Terms of Service immediately terminate, except that (a) you will remain responsible for performing all of your obligations in connection with transactions entered into before termination and for any liabilities that accrued before or as a result of termination; and 1 (Account Terms), 5 (Payment of Fees and Taxes), 6 (Confidentiality), 7 (Limitation of Liability and Indemnification), 8 (Intellectual Property and Your Materials), 9 (Feedback and Reviews), 10 (Privacy and Data Protection), 11 (Bears.Exchange Contracting Party), 13 (Modifications), 14 (General Conditions) will survive the termination or expration of these Terms of Service.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default TermsAndConditionsCopyPanel;
