import React from 'react';
import theme from './../../theme';
import PlanEditor from './plan-editor';
import CarrierGroupNavMenu from './../../menu/carrier-group/carrier-group-nav-menu';
import CreatorLockedOut from './creator-locked-out';

// istanbul ignore next
const CarrierGroupPlanDeliveryViewPanel = ({ 
    data,
    setMapColorKeys,
    onAddStep,
    onRemoveStep,
    onHookTrip,
    onCompleteDraftStatus,
    onCompleteSchedulingStatus,
    onCompletePricingStatus,
    onPriceUpdated,
    onPriceControlsUpdated,
    estimatedDollarsPerGallon,
    estimatedMPG,
    onSetPayout,
    pendingPayoutUpdates,
    onAddExchange,
    onAddTrip,
    onRemoveExchange,
    exchangeList,
    removeCargoFromCarrierGroupTrip,
    forceUnlockPlan
}) => data.isLockedOut ? 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>

        <CarrierGroupNavMenu
            carrierGroupId={data.carrierGroup.id}
            carrierGroupName={data.carrierGroup.name}
        />

        <CreatorLockedOut 
            isCreator={data.isCreator}
            lockedByUsername={data.lockedByUsername}
            carrierGroupName={data.carrierGroup.name}
            forceUnlockPlan={forceUnlockPlan}
        /> 
    </div> : 
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            
            <CarrierGroupNavMenu
                carrierGroupId={data.carrierGroup.id}
                carrierGroupName={data.carrierGroup.name}
            />
            
            <PlanEditor
                plan={data.plan}
                estimatedMPG={estimatedMPG}
                pendingPayoutUpdates={pendingPayoutUpdates}
                estimatedDollarsPerGallon={estimatedDollarsPerGallon}
                price={data.price}
                onPriceControlsUpdated={onPriceControlsUpdated}
                cargoItem={data.cargoItem}
                onAddTrip={onAddTrip}
                acceptingCargo={data.acceptingCargo}
                carryingCargo={data.carryingCargo}
                setMapColorKeys={setMapColorKeys}
                onSetPayout={onSetPayout}
                mapColorKeys={data.plan.mapColorKeys}
                onPriceUpdated={onPriceUpdated}
                delivery={data.delivery}
                carrierGroupId={data.carrierGroup.id}
                members={data.members}
                onAddStep={onAddStep}
                onRemoveStep={onRemoveStep}
                onHookTrip={onHookTrip}
                onCompleteDraftStatus={onCompleteDraftStatus}
                onCompletePricingStatus={onCompletePricingStatus}
                onCompleteSchedulingStatus={onCompleteSchedulingStatus}
                onAddExchange={onAddExchange}
                onRemoveExchange={onRemoveExchange}
                exchangeList={exchangeList}
                removeCargoFromCarrierGroupTrip={removeCargoFromCarrierGroupTrip}
            />
            
        </div>

export default CarrierGroupPlanDeliveryViewPanel;
