import React from 'react';
import { useState, useEffect } from 'react';
import AttributeListPanel from './../core/attribute-list';
import DatePanel from './../core/date';
import OfferSeekTypePanel from './../core/offer-seek-type';
import ExchangeListPanel from './../core/exchange-list';
import ObtainTypeDetailListPanel from './../core/obtain-type-detail-list';
import AuthorPanel from './../core/author';
import BidsPanel from './../core/bids';
import ImgPanel from './../../panel/core/img';
import theme from './../../theme';
import ResizeService from './../../../service/resize';

const SeekArchivedDetailViewPanel = ({ data: seek, onLoadSpecifiedPage }) => {
    const getOfferDetailViewPanelStyle = () => theme.getOfferDetailViewPanelStyle(theme);
    const [ offerDetailViewPanelStyle, setOfferDetailViewPanelStyle] = useState(getOfferDetailViewPanelStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferDetailViewPanelStyle(getOfferDetailViewPanelStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={offerDetailViewPanelStyle}>
            <div style={{
                ...theme.getGeneralTextStyle(theme),
                ...theme.getWarningTextStyle(theme),
                ...theme.getMediumTextStyle(theme),
            }}>
                {
                    seek.flagged ? 'Flagged Post' : (
                        seek.isActive ? '' : 'No Longer Published'
                    )
                }
            </div>


            <h2>
                { seek.title }
            </h2>
            
            <DatePanel label="Posted" date={seek.createdOn} />
        
            <AuthorPanel author={seek.author} />
            
            <OfferSeekTypePanel offerSeekType={seek.offerSeekType} />
            <AttributeListPanel attributeList={seek.attributeList} />
            <p>
                { seek.details }
            </p>
            <ExchangeListPanel exchangeList={seek.exchangeList} />
            <ObtainTypeDetailListPanel 
                obtainTypeDetailList={seek.obtainTypeDetailList} 
                contentType="seek"
            />

            {
                // istanbul ignore next
                seek.imgList.length > 0 ? <ImgPanel imgList={seek.imgList} /> : ''
            }

            {
                seek.isConfirmView ? '' : 
                    <BidsPanel 
                        bidderIsReceiver={false} 
                        targetType="seek"
                        isActive={false} 
                        bids={seek.bids} 
                        onLoadSpecifiedPage={onLoadSpecifiedPage} 
                    />
            }

        </div>
    );
};

export default SeekArchivedDetailViewPanel;
