import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import CarrierGroupPlanDeliveryEditHistoryPanel from './../../carrier-group-plan-delivery-edit-history';
import {
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_PRICING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_MEMBERS_CONFIRMING
} from './../../../prompt/core/enum/carrier-group-delivery-plan-status';

const Toolbar = ({
    plan,
    carrierGroupId,
    viewMapToTrips,
    toggleViewMapToTrips,
    pricingModeSelectedView,
    setPricingModeViewToPlanPricing,
    setPricingModeViewToAlignTrips,
    setPricingModeViewToPlanDelivery
}) => {

    const [ viewEditHistory, setViewEditHistory ] = useState(false);
    
    return (
        <div>
            <div>

                {
                    plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING && 
                        <span>
                            <button 
                                className="test-carrierGroupPlanDeliveryPlanEditorToolbarDeliveryStepsBtn"
                                onClick={toggleViewMapToTrips} 
                                disabled={!viewMapToTrips}
                            >
                                Delivery Steps
                            </button>
                            
                            <button 
                                className="test-carrierGroupPlanDeliveryPlanEditorToolbarAlignTripsBtn"
                                onClick={toggleViewMapToTrips} 
                                disabled={viewMapToTrips}
                            >
                                Aligned Trips
                            </button>
                        </span>
                        
                }

                {
                    [
                        CARRIER_GROUP_DELIVERY_PLAN_STATUS_PRICING,
                        CARRIER_GROUP_DELIVERY_PLAN_STATUS_MEMBERS_CONFIRMING
                    ].includes(plan.status) && 
                        <span>
                            <button 
                                className="test-carrierGroupPlanDeliveryPlanEditorToolbarDeliveryStepsBtn"
                                onClick={setPricingModeViewToPlanDelivery} 
                                disabled={pricingModeSelectedView === 'plan-delivery'}
                            >
                                Delivery Steps
                            </button>
                    
                            <button 
                                className="test-carrierGroupPlanDeliveryPlanEditorToolbarAlignTripsBtn"
                                onClick={setPricingModeViewToAlignTrips} 
                                disabled={pricingModeSelectedView === 'align-trips'}
                            >
                                Aligned Trips
                            </button>
                    
                            <button 
                                className="test-carrierGroupPlanDeliveryPlanEditorToolbarPricingBtn"
                                onClick={setPricingModeViewToPlanPricing} 
                                disabled={pricingModeSelectedView === 'plan-pricing'}
                            >
                                Pricing
                            </button>
                    
                        </span>
                }

                <button 
                    className="test-carrierGroupPlanDeliveryPlanEditorToolbarAuthorHistoryBtn"
                    onClick={event => { setViewEditHistory(true) }}
                >
                    Author History
                </button>
            </div>

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setViewEditHistory(false) }
                }
                open={viewEditHistory}
            >
                <h4>
                    Author History
                </h4>

                <CarrierGroupPlanDeliveryEditHistoryPanel
                    carrierGroupId={carrierGroupId}
                    deliveryId={plan.deliveryId}
                />

            </Dialog>


        </div>
    );
}

export default Toolbar;
