export const getAttributeListPanelFlexStyle = (theme) => ({
    display: 'flex',
    maxWidth: '19rem',
    alignItems: 'center',
    marginTop: '1.5rem',
    minWidth: '9rem',

});

export const getAttributeListPanelNameStyle = (theme) => ({
    width: 'auto',
    paddingLeft: '1.5rem',
    textAlign: 'right',
    paddingRight: '.5rem',
    borderRightWidth: '.1rem',
    borderRightStyle: 'solid',
    borderRightColor: theme.secondaryColor,
    wordWrap: 'break-word',
});

export const getAttributeListPanelValueStyle = (theme) => ({
    width: 'auto',
    textAlign: 'left',
    paddingLeft: '1rem', 
    wordWrap: 'anywhere',
});

export const getAttSetDetailViewPanelStyle = (theme) => ({
    textAlign: 'center',
    width: 'fit-content',
    minWidth: theme.isTablet() ? '20rem' : 'calc(100% - 1.5rem)',
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const getAttSetListCountStyle = (theme) => ({
    padding: '1rem 0 1.5rem',
});

export const getAttSetListContainerStyle = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: theme.isTablet() ? '100%' : 'calc(100% - 1.5rem)',
});
