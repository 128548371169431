import React from 'react';
import AdminPageTemplate from './template/admin-page';
import SearchMetricsSummaryPrompt from './../ui/prompt/admin/search-metrics-summary';
import theme from './../ui/theme';
import { NavLink } from 'react-router-dom';

const AdminSearchMetricsSummaryPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            Metrics Summary
        </h2>
        <div>
            <NavLink to="/admin/metrics">
                All Metrics
            </NavLink>
            <span>
                |
            </span>
            <NavLink to="/admin/metrics/graph">
                Graph
            </NavLink>
        </div>
        <SearchMetricsSummaryPrompt />
    </AdminPageTemplate>

export default AdminSearchMetricsSummaryPage;
