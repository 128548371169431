import React from 'react';
import AuthPageTemplate from './template/auth-page';
import SeekArchivedDetailPanel from './../ui/panel/seek-archived-detail';
import { useParams } from 'react-router-dom';

const SeekArchivedDetailPage = () => {
    const { seekId } = useParams();
    return (
        <AuthPageTemplate>
            <SeekArchivedDetailPanel seekId={seekId} />
        </AuthPageTemplate>
    );
};

export default SeekArchivedDetailPage;
