import React from 'react';
import UiService from './../../../service/ui';
import CurrencyService from './../../../service/currency';
import { NavLink } from 'react-router-dom';
import { reasonDisplayName } from './../../panel/dispute-respond/view';
import theme from './../../theme';

export const Header = () => 
    <thead>
        <tr>
            <td>
                Exchange
            </td>
            <td>
                Dispute ID
            </td>
            <td>
                Status
            </td>
            <td>
                Created
            </td>
            <td>
                Updated
            </td>
            <td>
                Amount
            </td>
            <td>
                Reason
            </td>
        </tr>
    </thead>
    
const Status = ({ dispute }) => 
    <span>
        {
            dispute.awaitingResponse && !dispute.userResponseSent ? 'Awaiting Response' : (
                dispute.isChargeBack ? 'Chargeback' : (
                    dispute.disputeIsActive ? 'Active' : 'Won'
                )   
            )
        }
    </span>

export const AdminDisputeSearchViewPrompt = ({ dispute }) => 
    <tr>
        <td>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/exchange/${dispute.exchangeId}`}
            >
                { dispute.exchangeId }
            </NavLink>
        </td>
        <td>
            {
                dispute.disputeId
            }
        </td>
        <td>
            <Status dispute={dispute} />
        </td>
        <td>
            {
                UiService.getDateReport(dispute.createdOn)
            }
        </td>
        <td>
            {
                UiService.getDateReport(dispute.updatedOn)
            }
        </td>
        <td>
            {
                CurrencyService.getDisplayPrice('usd', dispute.disputedAmount, false)
            }
        </td>
        <td>
            {
                reasonDisplayName[dispute.reason]
            }
        </td>
    </tr>