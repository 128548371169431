import React from 'react';
import { 
    OBTAIN_TYPE_PICK_UP, 
    OBTAIN_TYPE_ON_SITE,
    OBTAIN_TYPE_REMOTE
} from './../../prompt/core/enum/obtain-type';
import DeliveriesPanel from './deliveries-panel';
import PickupPanel from './pickup-panel';
import OnSitePanel from './onsite-panel';
import RemotePanel from './remote-panel';
import { hasDelivery } from './util';

const FulfillmentPanel = ({ 
    exchange,
    postAuthor,
    bidAuthor
}) => 
    <div>
        {
            hasDelivery(exchange) && 
                <DeliveriesPanel 
                    deliveryDetails={exchange.deliveryDetails} 
                    postAuthor={postAuthor}
                    bidAuthor={bidAuthor}
                />
        }

        {
            exchange.targetType === 'offer' && exchange.bid && exchange.bid.obtainMethod === OBTAIN_TYPE_PICK_UP && 
                exchange.target && exchange.target.rules && exchange.target.rules.obtainTypeDetailList && 
                    <PickupPanel location={exchange.target.rules.obtainTypeDetailList.find(a => a.type === exchange.bid.obtainMethod).locationLimit.area} />
        }

        {
            exchange.targetType === 'seek' && exchange.bid && exchange.bid.obtainMethod === OBTAIN_TYPE_PICK_UP && exchange.bid.selectedLocation && 
                <PickupPanel location={exchange.bid.selectedLocation} />

        }

        {
            exchange.targetType === 'offer' && exchange.bid && exchange.bid.selectedLocation && exchange.bid.obtainMethod === OBTAIN_TYPE_ON_SITE && 
                <OnSitePanel location={exchange.bid.selectedLocation} />
        }

        {
            exchange.targetType === 'seek' && exchange.bid && exchange.bid.obtainMethod === OBTAIN_TYPE_ON_SITE && exchange.target && 
                exchange.target.rules && exchange.target.rules.obtainTypeDetailList && 
                    <OnSitePanel location={exchange.target.rules.obtainTypeDetailList.find(a => a.type === exchange.bid.obtainMethod).locationLimit.area} />
        }

        {
            exchange.bid && exchange.bid.obtainMethod === OBTAIN_TYPE_REMOTE && 
                <RemotePanel obtainTypeDetail={exchange.target.rules.obtainTypeDetailList.find(a => a.type === exchange.bid.obtainMethod)} />
        }
    </div>

export default FulfillmentPanel;
