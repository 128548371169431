import React from 'react';
import CarrierGroupService from './../../../service/carrier-group';
import { FORM_FIELD } from './../core/abstract';
import ValidateService from './../../../service/validate';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../core/search';
import SearchCarrierGroupsAcceptingJoinsViewPrompt from './view';
import theme from './../../theme';

class _SearchCarrierGroupsAcceptingJoinsPrompt extends SearchPrompt 
{
    constructor(parms)
    {
        super(parms, _SearchCarrierGroupsAcceptingJoinsPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
    }

    static getValidators()
    {
        return {
            name: {
                isValid: ValidateService.anyValue
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            name: {
                name: 'Company Name',
                error: 'Specify a company name',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    onSendToServer()
    {
        CarrierGroupService.searchAccepting({
            request: this.getServerRequest(), 
            onResponse: this.onSearchResponse, 
            onError: this.onSearchError, 
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            name: this.state.name.value,
            page: 0
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'carrierGroups');
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        CarrierGroupService.searchAccepting({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getNoResultsUi()
    {
        return (
            <div>
                <p style={{
                   ...theme.getGeneralTextStyle(theme),
                }}>
                    No Companies Found.
                </p>
            </div>
        );
    }

    getSearchResultUi(aResult, index)
    {
        return <SearchCarrierGroupsAcceptingJoinsViewPrompt carrierGroup={aResult} key={index} />
    }
}

const SearchCarrierGroupsAcceptingJoinsPrompt = connect(mapStateToProps, mapDispatchToProps)(_SearchCarrierGroupsAcceptingJoinsPrompt);
export default SearchCarrierGroupsAcceptingJoinsPrompt;
