export const getBasicDialogContainerStyle = (theme) => ({
    maxWidth: 416,
    m: 0,
    borderRadius: 1,
    overflowX: 'hidden',
    backgroundColor: theme.panelColor,
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    minWidth: 288,
    minHeight: 288,
});

export const getDialogContainerContentStyle = (theme) => ({
    margin: '0 auto', 
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    width: '100%',
    height: 'fit-content',
    alignItems: 'center',
    padding: '.5rem 1rem 1rem 1rem',
});
