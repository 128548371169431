import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../../service/resize';
import UiService from './../../../service/ui';
import theme from './../../theme';
import ExchangeListPanel from './../../panel/core/exchange-list';

export const OfferSearchSingleFieldPromptContainer = ({ children }) => {
    const getOfferSearchSingleFileContainerFileStyle = () => theme.getOfferSearchSingleFileContainerFileStyle(theme);
    const [ offerSearchSingleFileContainerFileStyle, setOfferSearchSingleFileContainerFileStyle] = useState( getOfferSearchSingleFileContainerFileStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferSearchSingleFileContainerFileStyle( getOfferSearchSingleFileContainerFileStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={offerSearchSingleFileContainerFileStyle}>
            { children }
        </div>
    );
};


export const NoResultsView = () => 
    <section>
        <p style={{ 
            ...theme.getGeneralTextStyle(theme),            
        }}>
            No offers found.  You might want to post a
            &#160;
            <NavLink 
                style={{...theme.getGeneralTextLinkStyle(theme) }}  
                to="/seek/add"
            >
                seek
            </NavLink>
        </p>
    </section>

// istanbul ignore next 
const OfferSearchViewPromptImgThumb = ({ offer }) => {
    const hasImg = offer.imgList.length > 0;
    const imgSrc = hasImg ? offer.imgList[0].data : '';

    const getOfferSearchResultNoImageStyle = () => theme.getOfferSearchResultNoImageStyle(theme);
    const [ offerSearchResultNoImageStyle, setOfferSearchResultNoImageStyle] = useState( getOfferSearchResultNoImageStyle() );
    
    const getOfferSearchResultImageStyle = () => theme.getOfferSearchResultImageStyle(theme);
    const [ offerSearchResultImageStyle, setOfferSearchResultImageStyle ] = useState( getOfferSearchResultImageStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferSearchResultNoImageStyle( getOfferSearchResultNoImageStyle() );
        setOfferSearchResultImageStyle( getOfferSearchResultImageStyle() );
    });
    useEffect(resizeEffect);
    return hasImg ? 
        <img style={offerSearchResultImageStyle}
            src={imgSrc} 
            alt="offer thumbnail" 
        /> :
        <div style={offerSearchResultNoImageStyle}> 
            <p>
                No images saved.
            </p>
        </div>
};

const OfferSearchViewPrompt = ({ offer }) => {
    const getOfferSearchResultTileStyle = () => theme.getOfferSearchResultTileStyle(theme);
    const [ offerSearchResultTileStyle, setOfferSearchResultTileStyle ] = useState( getOfferSearchResultTileStyle() );
    
    const getOfferSearchResultDetailsStyle = () => theme.getOfferSearchResultDetailsStyle(theme);
    const [ offerSearchResultDetailsStyle, setOfferSearchResultDetailsStyle ] = useState( getOfferSearchResultDetailsStyle() );
    
    const getOfferSearchContainerStyle = () => theme.getOfferSearchResultTileContainerStyle(theme);
    const [ offerSearchContainerStyle, setOfferSearchContainerStyle ] = useState( getOfferSearchContainerStyle() );
    
    const getTimeStyle = () => theme.getOfferSearchResultTimeStyle(theme);
    const [ timeStyle, setTimeStyle ] = useState( getTimeStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferSearchResultTileStyle( getOfferSearchResultTileStyle());
        setOfferSearchResultDetailsStyle( getOfferSearchResultDetailsStyle() );
        setOfferSearchContainerStyle( getOfferSearchContainerStyle() );
        setTimeStyle( getTimeStyle() );
    });
    useEffect(resizeEffect);
    return (
        <NavLink 
            to={`/offer/${offer.id}`} 
            style={offerSearchContainerStyle}
        >
            <div style={offerSearchResultTileStyle}>
                <OfferSearchViewPromptImgThumb offer={offer} />
                <div>
                    <h3>
                        { offer.title }
                    </h3>
                    <p style={timeStyle}>
                        { UiService.timeSince(offer.createdOn) }
                    </p>
                </div>
            </div>
            <div style={offerSearchResultDetailsStyle}>
                <ExchangeListPanel summaryMode={true} exchangeList={offer.exchangeList} showCopyOnEmpty={true} />
            </div>

        </NavLink>
    );
};
export default OfferSearchViewPrompt;