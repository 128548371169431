import FramesBuilder from '../builder';

export class CrawlerUtil {

    static isNoPrev(crawler)
    {
        return crawler.isTop && crawler.index === 0 ? true : false;
    }

    static hasIndex(map, crawler)
    {
        return map[crawler.index] !== undefined ? true : false;
    }

    static hasMore(map, crawler)
    {
        return crawler.moreCrawler !== null && 
            map[crawler.index].moreList[crawler.moreIndex] !== undefined;
    }

    static isNextExit(map, crawler)
    {
        return crawler.isTop && crawler.index + 1 >= map.length ? true : false;
    }

    static isNextBack(map, crawler)
    {
        return !crawler.isTop && crawler.index + 1 >= map.length ? true : false;
    }

    static crawlToFrame(map, crawler, topCrawler)
    {
        let foundFrame = null;
        if(!CrawlerUtil.hasIndex(map, crawler))
        {
            return undefined;
        }

        if(CrawlerUtil.hasMore(map, crawler))
        {
            foundFrame = CrawlerUtil.crawlToFrame(
                map[crawler.index].moreList,
                crawler.moreCrawler,
                topCrawler
            );

            // istanbul ignore next 
            if(foundFrame)
            {
                return foundFrame;
            }
        }

        return FramesBuilder.getFrameDetails(map, crawler, topCrawler);
    }
}

export default CrawlerUtil;
