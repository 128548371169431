import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../../service/resize';
import theme from './../../../../theme';

const GasPrice = ({
    useGasPriceGlobal,
    onToggleUseGasPriceGlobal,
    estimatedDollarsPerGallon,
    gasPriceBuffer,
    onGasPriceBufferUpdated,
    gasPriceBufferValid
}) => {
    const getSmallTextErrorStyle = () => theme.getAbstractSmallTextError(theme);
    const [ smallTextErrorStyle, setSmallTextErrorStyle ] = useState( getSmallTextErrorStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSmallTextErrorStyle( getSmallTextErrorStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <div>
                <input type="checkbox" checked={useGasPriceGlobal} onChange={onToggleUseGasPriceGlobal} />

                <span>
                    { `Use ${estimatedDollarsPerGallon} / Gallon` }
                </span>
            </div>

            {
                !useGasPriceGlobal && 
                    <div>
                        <span>
                            Dollars per Gallon for this member
                        </span>
                        <input type="text" value={gasPriceBuffer} onChange={onGasPriceBufferUpdated} />

                        {
                            !gasPriceBufferValid && 
                                <div style={smallTextErrorStyle}>
                                    Specify the dollars per gallon you expect for this member's vehicle
                                </div>
                        }
                    </div>
            }
        </div>
    );
};

export default GasPrice;
