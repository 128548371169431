export const getImageListContainerStyle = (theme) => ({
    minHeight: '8rem',
    backgroundColor: theme.panelColor,
    margin: '.5rem auto 1rem',
    display: 'flex',
    maxWidth: theme.isDesktop() ? '52.5rem' : 
        (
            theme.isTablet() ? '36.5rem' : '100%'
        ),
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    width: 'fit-content',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    padding: '1rem',
    flexWrap: 'wrap',
    gap: '.75rem'
});

export const getImageViewStyle = (theme) => ({
    width: 'auto', 
    height: 'auto',
    borderRadius: '.5rem',
    maxHeight: '8rem',
    maxWidth: '8rem', 
});

export const getImageViewFlexContainerStyle = (theme) => ({
    height: '8rem',
    width: '8rem', 
});

export const getImageViewContainerStyle = (theme) => ({
    display: 'flex',
    gap: '.25rem',
    padding: '.75rem',
    backgroundColor: 'rgba(0, 0, 0, .13)',
    borderRadius: '.5rem',
    flexDirection: 'column'
});

export const getImageFlexStyle = (theme) => ({
    display: 'flex',
    margin: '.75rem auto 0',
    maxWidth: '26rem'
});

export const getImageTextStyle = (theme) => ({
    height: '100%',
    textAlign: 'center',
    margin: '0 auto',
    alignSelf: 'center',
    padding: '0 0 0 1rem',
});

export const getImageDropTextStyle = (theme) => ({
    fontWeight: '700',
});
