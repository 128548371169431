import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminStatsPanel from './../ui/panel/admin/admin-stats';

const AdminStatsPage = () => 
    <AdminPageTemplate>
        <AdminStatsPanel />
    </AdminPageTemplate>

export default AdminStatsPage;
