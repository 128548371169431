export const CARRIER_MEMBER_ROLE_GROUP_ADMIN = 'groupAdmin';
export const CARRIER_MEMBER_ROLE_DELIVERY_ADMIN = 'deliveryAdmin';
export const CARRIER_MEMBER_ROLE_CARRIER = 'carrier';
export const CARRIER_MEMBER_ROLE_HOST = 'host';

const validValues = [
    CARRIER_MEMBER_ROLE_GROUP_ADMIN,
    CARRIER_MEMBER_ROLE_DELIVERY_ADMIN,
    CARRIER_MEMBER_ROLE_CARRIER,
    CARRIER_MEMBER_ROLE_HOST
];

const isValid = value => validValues.includes(value);

const dropDownList = [
    {
        name: 'Company Admin',
        value: CARRIER_MEMBER_ROLE_GROUP_ADMIN
    },
    {
        name: 'Delivery Manager',
        value: CARRIER_MEMBER_ROLE_DELIVERY_ADMIN
    },
    {
        name: 'Carrier',
        value: CARRIER_MEMBER_ROLE_CARRIER
    },
    {
        name: 'Hub / Drop Off Host',
        value: CARRIER_MEMBER_ROLE_HOST
    },
];

const getDropDownValues = () => dropDownList;

export const codeToCopy = value => {
    const found = dropDownList.find(d => d.value === value);
    return found ? found.name : '';
};

const CarrierMemberRoleEnum = {
    CARRIER_MEMBER_ROLE_GROUP_ADMIN,
    CARRIER_MEMBER_ROLE_DELIVERY_ADMIN,
    CARRIER_MEMBER_ROLE_CARRIER,
    CARRIER_MEMBER_ROLE_HOST,
    getDropDownValues,
    codeToCopy,
    isValid
};

export default CarrierMemberRoleEnum;
