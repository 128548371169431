import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import isCurrentPageSpecial from './current-pay-special';

export const selectedMenuItem = {
    backgroundColor: theme.selectedMenuColor,
};

/* istanbul ignore next */
export const getCurrentPath = () => {
    const url = window.location.href;
    const urlParts = url.split('#');
    return urlParts[1] ? urlParts[1] : url;
};

/* istanbul ignore next */
export const checkToPath = (to, currentPath) => 
{
    switch(to)
    {
        case '/exchanges':
            return /\/exchange\//.test(currentPath) ? true : currentPath === to;
        
        case '/exchanges/all':
            return /\/exchange\//.test(currentPath) ? true : currentPath === to;

        case '/profile/att-sets':
            return currentPath === '/profile/att-set/add' ? false : /\/profile\/att-set\//.test(currentPath) ? true : currentPath === to;
 
        case '/profile/store':
            return /\/profile\/att-set\//.test(currentPath) ? true : currentPath === to;

        case '/posts':
            return /\/offers/.test(currentPath) ? true : (
                 /\/seeks/.test(currentPath) ? true : 
                    currentPath === to
            );

        case '/offers':
            return /\/offers/.test(currentPath);

        case '/seeks':
            return /\/seeks/.test(currentPath);

        case '/profile/groups/carrier':
            return /\/profile\/groups\/carrier/.test(currentPath);

        case '/users':
            return /\/user/.test(currentPath);

        default:
            return currentPath === to;
    }
}

export const isCurrentPage = (to, noSpecialCheck = false) => {
    const currentPath = getCurrentPath();
    if(noSpecialCheck)
    {
        return checkToPath(to, currentPath);
    }
    else
    {
        return checkToPath(to, currentPath) ? true : isCurrentPageSpecial(to, currentPath);
    }
};

const CustomMenuItem = ({ to, title, customStyle, isMainMenu }) => {
    
    const selectedStyle = isCurrentPage(to) ? theme.getSelectedMenuItemStyle(theme) : {};
    const menuTitleTextStyle = isMainMenu ? theme.getMenuTitleStyle(theme) : theme.getMenuSubTitleStyle(theme);
    const menuStyle = {
        ...customStyle,
        ...selectedStyle
    };
    
    return (
        <NavLink to={to} title={title} key={to}>
            <div>
                <div style={menuStyle}>

                </div>
                <div style={{
                    ...menuTitleTextStyle,
                    ...selectedStyle
                }}>
                    { title }
                </div>
            </div>
        </NavLink>
    );
};

const MenuItem = ({ to, title, customStyle, isMainMenu }) => 
    customStyle ? <CustomMenuItem key={to} to={to} title={title} customStyle={customStyle} isMainMenu={isMainMenu} /> :
        <span key={to}>
            { to === '/' ? 
                <NavLink to={to}>
                    { title }
                </NavLink> : 
                <NavLink to={to} activeStyle={selectedMenuItem}>
                    { title }
                </NavLink>
            }
            &nbsp;&nbsp;
            |
            &nbsp;&nbsp;
        </span>

export default MenuItem;
