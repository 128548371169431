import React from 'react';
import DetailPanel from './../core/detail';
import TrailService from './../../../service/trail';
import TrailsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';

class _TrailsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    /* istanbul ignore next */
    onAuthError()
    {
        if(this.props.onLogout)
        {
            this.props.onLogout();
        }
    }

    /* istanbul ignore next */
    onFatalError()
    {
        if(this.props.onUpdateError)
        {
            this.props.onUpdateError(true, '');
        }
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        TrailService.search({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            searchText: '',
            page: 0
        };
    }

    componentDidMount()
    {
        this.loadData({ });
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                <TrailsViewPanel data={this.state.data} />
            </section>
        );
    }
}

const TrailsPanel = connect(mapStateToProps)(_TrailsPanel);
export default TrailsPanel;
