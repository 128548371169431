export const getNetworkSpeedContainerStyle = (theme) => ({
    display: 'block',
    position: 'fixed',
    width: 'fit-content',
    maxWidth: '20rem',
    padding: '.75rem 1rem',
    top: 'calc(50vh - 6rem)',
    borderRadius: '0 .25rem .25rem 0',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    backgroundColor: theme.panelWarmColor,
});