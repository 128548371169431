import { ajax } from 'rxjs/ajax';
import endpointFlow from './endpoint-flow';
import MetricService from './metric';
import ClientService from './client';

const SUCCESS_CODE = 201;

/* istanbul ignore next */
const getAjaxRequest = ({ 
    url, 
    request, 
    method = 'POST',
    authToken, 
}) => ({
        url,
        body: JSON.stringify(request),
        headers: {
            'Content-Type': 'application/json',
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
            'X-Requested-With': ClientService.getIP()
        },
        method,
        crossDomain: true,
        responseType: 'json'
    });


/* istanbul ignore next */
const responseHasSuccessCode = result => result.status === SUCCESS_CODE;

/* istanbul ignore next */
const buildExecuteAjaxResponse = (
    onResponse, 
    onError, 
    apiCallMetric
) => result => {

    if(responseHasSuccessCode(result)) 
    {
        apiCallMetric.complete({
            isSuccess: true
        });
        onResponse(result.response);
    } 
    else 
    {
        apiCallMetric.complete({
            isSuccess: false
        });
        onError('Invalid server response code');
    }
};

/* istanbul ignore next */
const buildExecuteAjaxError = onError => err => { onError(err); };

/* istanbul ignore next */
const endpointRequest = ({
    url,
    request,
    onResponse,
    onError,
    authToken, 
    debounce = false
}) => {

    const serverRequest = () => {
        const requestParms = getAjaxRequest({ url, request, authToken });
        ajax(requestParms).subscribe(
            buildExecuteAjaxResponse(
                onResponse, 
                onError, 
                MetricService.startApi(url)
            ), 
            buildExecuteAjaxError(onError)
        );
    };

    const apiRequest = debounce ? endpointFlow(serverRequest) : serverRequest;
    apiRequest();
};

export default endpointRequest;
