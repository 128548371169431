import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';

class BidService {

    static getAddOfferBidRequest(offerId, title, details, exchangeMethod, obtainMethod, selectedLocation) {
        return {
            offerId,
            title,
            details,
            exchangeMethod,
            obtainMethod,
            selectedLocation,
        };
    }

    /* istanbul ignore next */
    static addOfferBid({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.offer.addBid,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getAddSeekBidRequest(seekId, title, details, exchangeMethod, obtainMethod, hasRefundPolicy, refundPolicyDetails, selectedLocation) {
        return {
            seekId,
            title,
            details,
            exchangeMethod,
            obtainMethod,
            hasRefundPolicy,
            refundPolicyDetails,
            selectedLocation
        };
    }

    /* istanbul ignore next */
    static addSeekBid({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.seek.addBid,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getListBidsByOfferRequest(offerId, page = 0) {
        return {
            offerId,
            page
        };
    }

    /* istanbul ignore next */
    static listBidsByOffer({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.offer.listBids,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getListBidsBySeekRequest(seekId, page = 0) {
        return {
            seekId,
            page
        };
    }

    /* istanbul ignore next */
    static listBidsBySeek({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.seek.listBids,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getDetailsRequest(bidId)
    {
        return {
            bidId
        };
    }

    /* istanbul ignore next */
    static getDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.bid.detail,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSetApprovalStateRequest(bidId, accepted, rejected)
    {
        return {
            bidId,
            accepted,
            rejected,
        };
    }

    /* istanbul ignore next */
    static setApprovalState({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.bid.setApprovalState,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getListPendingBidsRequest(page) 
    {
        return {
            page
        }
    }

    /* istanbul ignore next */
    static listPendingBids({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.widget.pendingBids.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default BidService;
