import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../theme';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UpdateOnboardingVisible } from '../../store/state/action';
import ResizeService from './../../service/resize';

const shortcutList = [
    {
        title: 'Platform News',
        url: '/news',
        details: 'Keep up to date on platform news'
    },
    {
        title: 'Report an Issue',
        url: '/profile/report/issue',
        details: 'Notify us of any system issues'
    },
    {
        title: 'Terms of Service',
        url: '/terms',
        details: 'Learn about our platform terms of service'
    },
    {
        title: 'Fees',
        url: '/fees',
        details: 'Learn about our platform fees' 
    },
    {
        title: 'Help',
        url: '/help',
        details: 'Learn how to use the Bears.Exchange platform'
    }
];

// istanbul ignore next
const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

// istanbul ignore next
const ExchangeShortcuts = (parms) => {
    const dispatch = useDispatch();
    const getProfileInfoShortCutOuterContainerStyle = () => theme.getProfileInfoShortCutOuterContainerStyle(theme);
    const [ profileInfoShortCutOuterContainerStyle, setProfileInfoShortCutOuterContainerStyle ] = useState( getProfileInfoShortCutOuterContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setProfileInfoShortCutOuterContainerStyle( getProfileInfoShortCutOuterContainerStyle() );
    });
    useEffect(resizeEffect);
    const enterOnboarding = () => {
        dispatch(
            UpdateOnboardingVisible(
                true
            )
        );
    };
    return (
        <div style={profileInfoShortCutOuterContainerStyle}>
            {
                shortcutList.map((cut, index) => <Shortcut details={cut.details} title={cut.title} url={cut.url} key={index} />)
            }

            <div 
                key="onboarding"
                onClick={event => { enterOnboarding() }}
                style={{
                    ...theme.getProfileInfoWelcomeTourShortcutStyle(theme)
                }}
            >
                <h3>
                    Welcome Tour
                </h3>
                <p>
                    Revisit the tour you saw when first joining.
                </p>
            </div>
        </div>
    );
};

// istanbul ignore next
const ProfileInfoPanel = (parms) => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    const getBodyStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
        setBodyStyle( setBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={containerStyle}>
                <h2>
                    Platform Information
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Get help, review platform details and report issues.
                </h4>
            </div>
            <div style={bodyStyle}>
                <div style={{
                    ...theme.getProfilePwdBodyFixStyle(theme),
                }}>
                    <div style={{
                        ...theme.getProfilePwdContainerStyle(theme),
                    }}>

                        <ExchangeShortcuts />

                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfileInfoPanel;
