export const getPayPromptStyle = (theme) => ({
    textAlign: 'center',
    paddingLeft: '.75rem',
    paddingRight: '.75rem',
    paddingBottom: '.75rem',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getPayFormContainerStyle = (theme) => ({
    width: '100%',
    maxWidth: '30rem',
    marginLeft: 'auto',
    marginRight: 'auto'
});
