import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class LedgerService {

    static getDepositRequest(idList, hasRefunded, readyForWithdrawl, depositedToCompany, page = 0)
    {
        return {
            idList,
            hasRefunded,
            readyForWithdrawl,
            depositedToCompany,
            page,
        };
    }

    /* istanbul ignore next */
    static deposit({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.depositLedger,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSearchRequest(
        hasRefunded = undefined,
        readyForWithdrawl = undefined,
        depositedToCompany = undefined,
        page = 0,
    ) {
        return {
            hasRefunded,
            readyForWithdrawl,
            depositedToCompany,
            page,
        };
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.searchLedger, 
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getArchiveRequest(
        ledgerItemId,
        hasRefunded = undefined,
        readyForWithdrawl = undefined,
        depositedToCompany = undefined,
        page = 0,
    ) {
        return {
            ledgerItemId,
            hasRefunded,
            readyForWithdrawl,
            depositedToCompany,
            page,
        };
    }

    /* istanbul ignore next */
    static archive({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.archiveLedgerItem,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    /* istanbul ignore next */
    static getSummaryRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static summary({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.ledgerSummary,
            request: {},
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    /* istanbul ignore next */
    static getDepositConfirmedRequest()
    {
        return {
            
        };
    }

    /* istanbul ignore next */
    static confirmDeposit({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.confirmDeposit,
            request: {},
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default LedgerService;
