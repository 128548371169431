const USER_ACTION_TYPE_CREATE_OFFER = 'createOffer';
const USER_ACTION_TYPE_CREATE_SEEK = 'createSeek';
const USER_ACTION_TYPE_RECEIVE_PURCHASE = 'receivePurchase';
const USER_ACTION_TYPE_RECEIVE_BID = 'receiveBid';
const USER_ACTION_TYPE_RECEIVE_PAYOUT = 'receivePayout';

const validValues = [
    USER_ACTION_TYPE_CREATE_OFFER,
    USER_ACTION_TYPE_CREATE_SEEK,
    USER_ACTION_TYPE_RECEIVE_PURCHASE,
    USER_ACTION_TYPE_RECEIVE_BID,
    USER_ACTION_TYPE_RECEIVE_PAYOUT
];

const isValid = value => validValues.includes(value);

const getCopy = (userAction) => {
    const enabledCopy = userAction.allowAccess ? 'Allowed' : 'Restricted';
    return `${getTypeCopy(userAction.type)} ${enabledCopy}`;
};

const getTypeCopy = (type) => {
    switch(type)
    {
        case USER_ACTION_TYPE_CREATE_OFFER:
            return 'Create Offer';

        case USER_ACTION_TYPE_CREATE_SEEK:
            return 'Create Seek';

        case USER_ACTION_TYPE_RECEIVE_PURCHASE:
            return 'Receive Purchases';

        case USER_ACTION_TYPE_RECEIVE_BID:
            return 'Receive Bids';

        case USER_ACTION_TYPE_RECEIVE_PAYOUT:
            return 'Receive Payouts';

        default:
            return type;
    }
};

const getDropDownValues = () => validValues.map(value => ({
    name: getTypeCopy(value),
    value
}));

const getAddTypeDropDownList = inUseTypeList => getDropDownValues().filter(aDrop => 
    !inUseTypeList.includes(aDrop.value)
);

const UserActionTypeEnum = {
    USER_ACTION_TYPE_CREATE_OFFER,
    USER_ACTION_TYPE_CREATE_SEEK,
    USER_ACTION_TYPE_RECEIVE_PURCHASE,
    USER_ACTION_TYPE_RECEIVE_BID,
    USER_ACTION_TYPE_RECEIVE_PAYOUT,
    getAddTypeDropDownList,
    isValid,
    getDropDownValues,
    getCopy,
    getTypeCopy
};

export default UserActionTypeEnum;
