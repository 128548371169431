export const USER_ACTION_CONTEXT_UNPAID_FEE = 'unpaidFee';
export const USER_ACTION_CONTEXT_SET_BY_USER = 'setByUser';
export const USER_ACTION_CONTEXT_SET_BY_ADMIN = 'setByAdmin';

const validValues = [
    USER_ACTION_CONTEXT_UNPAID_FEE,
    USER_ACTION_CONTEXT_SET_BY_USER,
    USER_ACTION_CONTEXT_SET_BY_ADMIN
];

const isValid = value => validValues.includes(value);

const UserActionContextEnum = {
    USER_ACTION_CONTEXT_UNPAID_FEE,
    USER_ACTION_CONTEXT_SET_BY_USER,
    USER_ACTION_CONTEXT_SET_BY_ADMIN,
    isValid
};

export default UserActionContextEnum;
