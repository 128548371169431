import FeeService from './fee';
import USPSService from './usps';
import CurrencyInputService from './currency-input';
import config from './../config';

class ValidateService {
    
    static textLine(value)
    {
        return value.length <= config.inputLimit.textLine;
    }

    static textLines(value)
    {
        return value.length <= config.inputLimit.textLines;
    }

    static phone(value)
    {
        return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value);
    }

    static latitude(value)
    {
        return /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(value);

    }

    static longitude(value)
    {
        return /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(value);

    }

    static password(value)
    {
        // Minimum eight characters, at least one letter and one number
        return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value);
    }

    static email(value)
    {
        /* eslint-disable */
        return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value);
        /* eslint-enable */
    }

    static currencyInput(value)
    {
        // a dollar or more, with or without change | less than a dollar with or without leading zero
        return /(^[1-9][0-9]*(?:\.\d\d)?$)|(^0?\.\d\d$)/.test(value);
    }

    static onlyDigitsNoLeadingZeros(value)
    {
        return /^[1-9][0-9]*$/.test(value);
    }

    static makeCashAmountField(obj)
    {
        return val => {
            const isValidCurrencyInput = this.currencyInput(val);
            if(isValidCurrencyInput)
            {
                const parsedVal = CurrencyInputService.parse(val);
                const cleanAmt = parsedVal.replace(/^0+/, '');
                return parseInt(cleanAmt) > 0;
            }
            else
            {
                return false;
            }
        };
    }

    static makeUspsOrderAmountField(obj)
    {
        return val => {
            const isValidCurrencyInput = this.currencyInput(val);
            if(isValidCurrencyInput)
            {
                const parsedVal = CurrencyInputService.parse(val);
                const { isValid } = USPSService.getFeeDetails(parsedVal);
                return isValid;
            }
            else
            {
                return false;
            }
        };
    }

    static makeCurrencyFieldValue(obj, fieldName, paidAccount = false)
    {
        return val => {
            const isValidCurrencyInput = this.currencyInput(val);
            if(isValidCurrencyInput)
            {
                const parsedVal = CurrencyInputService.parse(val);
                const currency =  obj.state[fieldName].value;
                const { isValid } = FeeService.getEstimated(
                    currency,
                    parsedVal,
                    paidAccount
                );
                return isValid;
            }
            else
            {
                return false;
            }

        };
    }

    static makeDateAfter(obj, earierFieldName)
    {
        return val => {
            const valDt = new Date(val);
            const earlierDt = new Date(obj.state[earierFieldName].value);
            return this.valueRequired(val) && valDt >= earlierDt;
        }
    }

    static makeDateMin(obj, fieldName)
    {
        return val => {
            const valDt = new Date(val);
            return this.valueRequired(val) && valDt >= obj.state[fieldName].minDate;
        }
    }

    static makeMatchFieldValue(obj, fieldName)
    {
        return val => val === obj.state[fieldName].value;
    }

    static valueRequired(value)
    {
        return value !== '';
    }

    static calculateAge(birthDate)
    {
        birthDate = new Date(birthDate);
        const today = new Date();
        let years = (today.getFullYear() - birthDate.getFullYear());
        /* istanbul ignore next */
        if(today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()))
        {
            years--;
        }
        return years;
    }

    static date(value)
    {
        return /^([0-9]{4})[-/]?([0-1][0-9])[-/]?([0-3][0-9])$/.test(value);
    }
    
    static numeric(value)
    {
        return !isNaN(value) && value === value.trim() && value !== '';
    }

    static anyValue(value)
    {
        return true;
    }

    static isTrue(value)
    {
        return value === true ? true : false;
    }
}

export default ValidateService;
