import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../../../theme';

const Empty = () => 
    <div>
        <p>
            <NavLink
                style={{ 
                    ...theme.getGeneralTextLinkStyle(theme) 
                }}
                to="/profile/trails"
            >
                Add your platforms
            </NavLink>
        </p>
    </div>

export default Empty;
