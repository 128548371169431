import React from 'react';
import ReactPlayer from 'react-player'
import theme from '../../../../theme';

const VideoRenderType = ({
    selectedFrame,
    updateCrawler,
    mobileUrl,
    desktopUrl,
    onVideoFinished
}) => {
    return theme.isDesktop() ?
        <ReactPlayer 
            loop={false} 
            playing={true} 
            url={desktopUrl} 
            controls={false}
            style={{...theme.getOnboardingDynamicFrameStyle(theme)}}
            height='100%'
            width='100%'
            onEnded={onVideoFinished}
        /> :
            <ReactPlayer 
                loop={false} 
                playing={true} 
                url={mobileUrl} 
                controls={false}
                style={{...theme.getOnboardingDynamicFrameStyle(theme)}}
                width='100vw'
                height='calc(100vh - 4rem)'
                onEnded={onVideoFinished}
            />            
};

export default VideoRenderType;
