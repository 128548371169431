import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../../service/resize';
import UiService from './../../../../../service/ui';
import { NavLink } from 'react-router-dom';
import theme from './../../../../theme';

const Seek = ({ seek }) => {
    const getWidgetMyArchivedSeeksStyle = () => theme.getWidgetMyArchivedSeeksStyle(theme);
    const [ widgetMyArchivedSeeksStyle, setWidgetMyArchivedSeeksStyle ] = useState( getWidgetMyArchivedSeeksStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setWidgetMyArchivedSeeksStyle( getWidgetMyArchivedSeeksStyle() );
    });
    useEffect(resizeEffect);
    return (
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/archived/seek/${seek.id}`}
        >
            <div style={widgetMyArchivedSeeksStyle}>
                {
                    seek.flagged ? <span>&#x2691;</span> : ''
                }
                <div>
                    { seek.title }
                </div>
                <p>
                    { UiService.timeSince(seek.createdOn) }
                </p>
            </div>
        </NavLink>
    );
};

const PopulatedView = ({ list }) => {
    const getUserWidgetFlexParentStyle = () => theme.getUserWidgetFlexParentStyle(theme);
    const [ userWidgetFlexParentStyle, setUserWidgetFlexParentStyle] = useState(getUserWidgetFlexParentStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetFlexParentStyle(getUserWidgetFlexParentStyle());
    });
    useEffect(resizeEffect);
    return (
        <section style={userWidgetFlexParentStyle}>
            {
                list.map((seek, index) => <Seek key={index} seek={seek} />)
            }
            <div style={{
                clear: 'both'
            }}></div>
        </section>
    );
};
    

const EmptyView = ({ userWidgetHeaderTextStyle }) => 
    <section>
        <p style={userWidgetHeaderTextStyle}>
            You have no archived seeks.  
        </p>
        
    </section>

const WidgetMyArchivedSeeksViewPanel = ({ data }) => {
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle] = useState(getUserWidgetHeaderTextStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetHeaderTextStyle( getUserWidgetHeaderTextStyle());
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    My Past Seeks
                </div>
            </div>
            {
                data.list.length < 1 ? 
                    <EmptyView userWidgetHeaderTextStyle={userWidgetHeaderTextStyle} /> : 
                        <PopulatedView list={data.list} />
            }
        </section>
    );
};


export default WidgetMyArchivedSeeksViewPanel;
