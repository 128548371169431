const mapStateToProps = state => ({
    authToken: state.authToken,
    isAdmin: state.isAdmin,
    showFullMenu: state.showFullMenu,
    unseenNoticeCount: state.unseenNoticeCount,
    deviceIsOnline: state.deviceIsOnline,
    clientSettings: state.clientSettings,
    customView: state.customView
});

export default mapStateToProps;
