import React from 'react';
import theme from './../../theme';
import Pagination from './../../prompt/core/pagination';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import AddCarrierGroupPrompt from './../../prompt/add-carrier-group';
import { NavLink } from 'react-router-dom';

const Row = ({ group }) => 
    <tr>
        <td>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/profile/groups/carrier/${group.id}`}
            >
                { group.name }
            </NavLink>
        </td>
    </tr>
    

const Header = () => 
    <thead>
        <tr>
            <td>
                <strong>
                    Name
                </strong>
            </td>
        </tr>
    </thead>

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />


const PopulatedView = ({ carrierGroups, onLoadSpecifiedPage, onCarrierGroupAdded }) => {
    const [ startViewVisible, setStartViewVisible ] = useState(false);

    /* istanbul ignore next */
    const onGroupAdded = response => {
        setStartViewVisible(false);
        onCarrierGroupAdded(response);
    };
    return startViewVisible ? 
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <button 
                className="test-profileCarrierGroupsPopulatedViewBackBtn"
                onClick={event => { setStartViewVisible(false) }}
            >
                Back
            </button>

            <StartView onCarrierGroupAdded={onGroupAdded} /> 
        </div> : 
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <button 
                className="test-profileCarrierGroupsPopulatedViewStartBtn"
                onClick={event => { setStartViewVisible(true) }}
            >
                Start
            </button>
            <div>
                { getPaginationUi({ data: carrierGroups, onLoadSpecifiedPage }) }
                <div>
                    <table>
                        <Header />
                        <tbody>
                            {
                                carrierGroups.list.map((group, index) => 
                                    <Row group={group} key={index} />
                                )
                            }
                        </tbody>
                    </table>
                </div>
                { getPaginationUi({ data: carrierGroups, onLoadSpecifiedPage }) }
            </div>
        </div>
};

const StartView = ({ onCarrierGroupAdded }) => {
    const [ addPromptOpen, setAddPromptOpen ] = useState(false);
    /* istanbul ignore next */
    const onGroupAdded = (response) => {
        setAddPromptOpen(false);
        onCarrierGroupAdded(response);
    };
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <hr />
            <div>
                <p>
                    To start offering delivery services for bears in exchanges.
                </p>
                <button 
                    className="test-profileCarrierGroupsStartViewCreateACompanyBtn"
                    onClick={event => {
                        setAddPromptOpen(true)
                    }}
                >
                    Create a Company
                </button>
            </div>
            
            <p>
                OR
            </p>

            <div>
                <p>
                    To apply to join in the established delivery companies on Bears.Exchange
                </p>
                <NavLink 
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to="/profile/groups/carrier/search"
                >
                    Search For An Existing Company To Join
                </NavLink>
            </div>

            <p>
                OR
            </p>

            <div>
                <p>
                    To accept an invitation to join an established delivery company on Bears.Exchange
                </p>
                <NavLink 
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to="/profile/groups/carrier/invites"
                >
                    Review Join Invites
                </NavLink>
            </div>
 
            <Dialog
                onClose={ 
                    /* istanbul ignore next */
                    event => { setAddPromptOpen(false) }
                }
                open={addPromptOpen}
            >
                <h2>
                    Create a Carrier Company
                </h2>
                <AddCarrierGroupPrompt onCarrierGroupAdded={onGroupAdded} />
            </Dialog>
        </div>
    );
};

const EmptyView = ({ onCarrierGroupAdded }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <p>
            Carrier companies allow you to form delivery teams with other users.
        </p>
                
        <StartView onCarrierGroupAdded={onCarrierGroupAdded} />

    </div>

const ProfileCarrierGroupsViewPanel = ({ 
    data, 
    onLoadSpecifiedPage,
    onCarrierGroupAdded
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <h2>
            My Carrier Companies
        </h2>
                
        {
            data.carrierGroups.list.length < 1 ? 
                <EmptyView onCarrierGroupAdded={onCarrierGroupAdded} /> : 
                <PopulatedView 
                    carrierGroups={data.carrierGroups} 
                    onLoadSpecifiedPage={onLoadSpecifiedPage} 
                    onCarrierGroupAdded={onCarrierGroupAdded}
                />
        }
    </div>

export default ProfileCarrierGroupsViewPanel;
