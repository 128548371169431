export const IP_EVENT_TYPE_LOGIN_LOCKOUT = 'login-lockout';
export const IP_EVENT_TYPE_ONLINE_TRANSACTION = 'online-transaction';
export const IP_EVENT_TYPE_TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const IP_EVENT_TYPE_USER_ACTIVITY = 'user-activity';

const validValues = [
    IP_EVENT_TYPE_LOGIN_LOCKOUT,
    IP_EVENT_TYPE_ONLINE_TRANSACTION,
    IP_EVENT_TYPE_TERMS_AND_CONDITIONS,
    IP_EVENT_TYPE_USER_ACTIVITY
];

const getDropDownValues = () => [
    {
        name: 'IP Event - Login Locked',
        value: IP_EVENT_TYPE_LOGIN_LOCKOUT
    },
    {
        name: 'IP Event - Online Payment',
        value: IP_EVENT_TYPE_ONLINE_TRANSACTION
    },
    {
        name: 'IP Event - Terms and Condition',
        value: IP_EVENT_TYPE_TERMS_AND_CONDITIONS
    },
    {
        name: 'IP Event - User Activity',
        value: IP_EVENT_TYPE_USER_ACTIVITY
    }
];

const isValid = value => validValues.includes(value);

const IpEventTypeEnum = {
    IP_EVENT_TYPE_LOGIN_LOCKOUT,
    IP_EVENT_TYPE_ONLINE_TRANSACTION,
    IP_EVENT_TYPE_TERMS_AND_CONDITIONS,
    IP_EVENT_TYPE_USER_ACTIVITY,
    getDropDownValues,
    isValid
};

export default IpEventTypeEnum;
