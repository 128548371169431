import React from 'react';
import { 
    BX_EVENT_TYPE_PAY_SUCCESS,
    BX_EVENT_TYPE_SUB_CANCELLED,
    BX_EVENT_TYPE_SUB_PAID,
    BX_EVENT_TYPE_SUB_PAYMENT_FAILED,
    BX_EVENT_TYPE_SUB_FORCE_CANCEL,
    BX_EVENT_TYPE_PAY_ACCOUNT_UPDATED,
    BX_EVENT_TYPE_PAYMENT_DISPUTED,
    BX_EVENT_TYPE_PAYMENT_DISPUTE_UPDATED,
    BX_EVENT_TYPE_TRANSFER_FAIL,
    BX_EVENT_TYPE_TRANSFER_SUCCESS
} from './../../core/enum/bx-event-type';
import {
    IP_EVENT_TYPE_LOGIN_LOCKOUT,
    IP_EVENT_TYPE_ONLINE_TRANSACTION,
    IP_EVENT_TYPE_TERMS_AND_CONDITIONS,
    IP_EVENT_TYPE_USER_ACTIVITY,
} from './../../core/enum/ip-event-type';
import {
    BX_EVENT_CONTEXT_IP
} from './../../core/enum/bx-event-context';
import { BX_EVENT_CONTEXT_PAY } from './../../core/enum/bx-event-context';
import UiService from './../../../../service/ui';

export const getContextCopy = (context) => context === BX_EVENT_CONTEXT_PAY ? 'Pay API' : 'IP Event';
export const getTypeCopy = (type) => {
    switch(type)
    {
        case IP_EVENT_TYPE_LOGIN_LOCKOUT:
            return 'Login Lockout';

        case IP_EVENT_TYPE_ONLINE_TRANSACTION:
            return 'Online Payment';

        case IP_EVENT_TYPE_TERMS_AND_CONDITIONS:
            return 'Terms of Service';

        case IP_EVENT_TYPE_USER_ACTIVITY:
            return 'User Activity';
        
        case BX_EVENT_TYPE_PAY_SUCCESS: 
            return 'Successful Payment';

        case BX_EVENT_TYPE_SUB_PAID:
            return 'Subscription Paid';

        case BX_EVENT_TYPE_SUB_PAYMENT_FAILED:
            return 'Subscription Payment Failed';

        case BX_EVENT_TYPE_SUB_FORCE_CANCEL:
            return 'Subscription Force Cancelled';

        case BX_EVENT_TYPE_SUB_CANCELLED:
            return 'Subscription Cancelled';

        case BX_EVENT_TYPE_PAY_ACCOUNT_UPDATED:
            return 'Pay Profile Updated';

        case BX_EVENT_TYPE_PAYMENT_DISPUTED:
            return 'Transaction Disputed';

        case BX_EVENT_TYPE_PAYMENT_DISPUTE_UPDATED:
            return 'Dispute Updated';

        case BX_EVENT_TYPE_TRANSFER_FAIL:
            return 'Transfer Failed';

        case BX_EVENT_TYPE_TRANSFER_SUCCESS:
            return 'Transfer Confirmed';

        default:
            return 'Unknown';
    }
};

export const getStatusCopy = (bxe) => bxe.isPending ? 'Received' : (
    bxe.isFailed ? `Failed - ${bxe.failDetails}` : (
        bxe.lockId ? 'Processing' : 'Processed'
    )
);

export const Header = ({context}) => 
    <thead>
        <tr>
            <td>
                Context
            </td>
            <td>
                Type
            </td>
            <td>
                Run Attempt(s)
            </td>
            <td>
                Occured At
            </td>
            <td>
                Status
            </td>
            <td>
                {
                    /* istanbul ignore next */
                    context === BX_EVENT_CONTEXT_IP && 
                        <span>
                            Event
                        </span>
                }
            </td>
            <td>
                
            </td>
            <td>

            </td>
        </tr>
    </thead>

const SearchBxeViewPrompt = ({ bxEvent, onRescheduleEvent, onDeleteJob }) => 
    <tr>
        <td>
            { getContextCopy(bxEvent.context) }
        </td>
        <td>
            { getTypeCopy(bxEvent.type) }
        </td>
        <td>
            { bxEvent.runCount }
        </td>
        <td>
            { UiService.getDateReport(bxEvent.createdOn) }
        </td>
        <td>
            { getStatusCopy(bxEvent) }
        </td>
        <td>
            {
                bxEvent.context === BX_EVENT_CONTEXT_IP && 
                <span>
                    { bxEvent.event }
                </span>
            }
        </td>
        <td>
            {
                bxEvent.isFailed ? 
                    <button 
                        className="test-adminSearchBxeRescheduleJobBtn"
                        onClick={event => { onRescheduleEvent(bxEvent.id, bxEvent.context === BX_EVENT_CONTEXT_IP) }}
                    >
                        Reschedule Job
                    </button> : ''
            }
        </td>
        <td>
            {
                bxEvent.isFailed ? 
                    <button 
                        className="test-adminSearchBxeDeleteJobBtn"
                        onClick={event => { onDeleteJob(bxEvent.id, bxEvent.context === BX_EVENT_CONTEXT_IP) }}>
                        Delete Job
                    </button> : ''
            }
        </td>
    </tr>

export default SearchBxeViewPrompt;
