export const LOGIN = '[User] Successful Login';
export const AUTH_UPDATE = '[User] Auth Update';
export const LOGOUT = '[User] Logout';
export const UPDATE_NOTICE_COUNT = '[Notice] Count Update';
export const UPDATE_CLIENT_SETTINGS = '[ClientSettings] Update';
export const UPDATE_ERROR = '[Error] State Update';
export const UPDATE_MENU = '[Menu] State Update';
export const UPDATE_METRICS = '[Metric] State Update';
export const UPDATE_SPEED_VIEW = '[CustomView] Change Speed View';
export const UPDATE_AUTO_SPEED_VIEW = '[CustomView] Change Auto Speed View';
export const UPDATE_SPEED_VIEW_PROMPT_VISIBLE = '[CustomView] Change Show Speed Prompt';
export const UPDATE_USER_CLOSED_SPEED_VIEW_PROMPT = '[CustomView] User Closed Speed View Prompt';
export const UPDATE_ONBOARDING_VISIBLE = '[Onboarding] Change Visibility';
export const UPDATE_ONBOARDING_CRAWLER = '[Onboarding] Update Crawler';
export const UPDATE_DEVICE_ONLINE = '[Device] Update Network Online State';
export const REDUX_STORAGE = 'client-state';
