import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ReportIssuePrompt from './../ui/prompt/report-issue';
import ProfileMenu from './../ui/menu/profile';
import { NavLink } from 'react-router-dom';
import theme from '../ui/theme';

const ReportIssuePage = () => {
    return (
        <AuthPageTemplate childMenu={
            <ProfileMenu />
        }>
            <ReportIssuePrompt />

            <div style={{ 
                ...theme.getGeneralTextLinkStyle(theme),
                ...theme.getGeneralBottomSpacerStyle(theme),
                ...theme.getGeneralTextStyle(theme) 
            }}>
                <NavLink
                    to={`/profile/reported/issues`}
                >
                    Review reported issues.
                </NavLink>
            </div>

        </AuthPageTemplate>
    );
};

export default ReportIssuePage;
