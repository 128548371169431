import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminPanel from './../ui/panel/admin';

const AdminPage = () => {
    return (
        <AdminPageTemplate>
            <AdminPanel />
        </AdminPageTemplate>
    );
};

export default AdminPage;
