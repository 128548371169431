import React from 'react';

const SkipButton = ({
    exitOnboarding
}) => 
    <button 
        className="test-onboardingSkipBtn"
        onClick={event => {
            exitOnboarding();
        }}
    >
        Skip
    </button>

export default SkipButton;
