import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminDisputeSearchPrompt from './../ui/prompt/admin-dispute-search';
import theme from './../ui/theme';

const AdminDisputeSearchPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            Disputed Transactions
        </h2>
        <AdminDisputeSearchPrompt />
    </AdminPageTemplate>

export default AdminDisputeSearchPage;
