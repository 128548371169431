export const CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP = 'trip';
export const CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_SORT = 'sort';
export const CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_PLAN = 'plan';
export const CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_HOST = 'host';

const validValues = [
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_PLAN,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_HOST
];

const isValid = value => validValues.includes(value);

const CarrierGroupPlanPriceContributionTypeEnum = {
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_PLAN,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_HOST,
    validValues,
    isValid
};

export default CarrierGroupPlanPriceContributionTypeEnum;
