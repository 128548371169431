import React from 'react';
import { NavLink } from 'react-router-dom';
import UiService from './../../../service/ui';
import ExchangeChatPrompt from './../../prompt/exchange-chat';
import FlagPrompt from './../../prompt/flag';
import ImgPanel from './../core/img';
import { FLAGGED_CONTENT_TYPE_CHAT } from './../../prompt/core/enum/flagged-content-type';
import Pagination from './../../prompt/core/pagination';
import theme from './../../theme';

const EmptyView = () => 
    <section>
        <p>
            No messages
        </p>
    </section>

// istanbul ignore next
const messageView = ({ chat, index }) => 
    <section key={index}>
        
        <div>

            <div style={{
                ...theme.getMediumTextStyle(theme),
                ...theme.getExchangeChatUsernameStyle(theme),
            }}>
                <NavLink to={`/user/${chat.userId}`}>
                    { chat.username }
                    {
                        chat.staffMessage && 
                            <div>
                                <strong>
                                    System Admin
                                </strong>
                            </div>
                    }
                </NavLink>
            </div>

            <div style={{
                ...theme.getSmallTextStyle(theme),
                ...theme.getExchangeChatTimeStyle(theme),
            }}>   
                { UiService.timeSince(chat.createdOn) }
            </div>

            <div style={{ clear: 'both' }}></div>

        </div>

        <div>
            <p style={{
                ...theme.getExchangeChatMessageStyle(theme),
            }}>
                { chat.message }
            </p>
            {
                chat.imgList.length > 0 ? <ImgPanel imgList={chat.imgList} /> : ''
            }
        </div>
        <hr />
        
    </section>

const getPaginationUi = ({ chats, onLoadSpecifiedPage }) => <Pagination total={chats.total} page={chats.page} perPage={chats.perPage} onLoadSpecifiedPage={onLoadSpecifiedPage} />

const PopulatedView = ({ chats, onLoadSpecifiedPage }) => 
    <section>
        { getPaginationUi({ chats, onLoadSpecifiedPage }) }
        { 
            chats.list.map((chat, index) => messageView({chat, index}))
        }
        { getPaginationUi({ chats, onLoadSpecifiedPage }) }
    </section>

const ExchangeChatDetails = ({ chats, onLoadSpecifiedPage }) => chats.list && chats.list.length > 0 ? <PopulatedView chats={chats} onLoadSpecifiedPage={onLoadSpecifiedPage} /> : <EmptyView />

// istanbul ignore next 
const ExchangeChatViewPanel = ({ data: chats, exchangeId, onLoadChats, chatPromptVisible, onLoadSpecifiedPage }) => 
    <section style={{
        ...theme.getBidDetailViewPanelStyle(theme),
    }}>
        <h3>
            Chat
        </h3>
        {
            !chatPromptVisible ? '' : 
            <section style={{
                ...theme.getAbstractCenterFlexContainerStyle(theme),
            }}>
                <ExchangeChatPrompt exchangeId={exchangeId} onLoadChats={onLoadChats} />
                { 
                    chats.length > 0 ? 
                        <FlagPrompt 
                            contentId={exchangeId}
                            flaggedContentType={FLAGGED_CONTENT_TYPE_CHAT}
                            openPromptTitle="Flag this conversation"
                        /> : ''
                }
            </section>
        }
        <ExchangeChatDetails chats={chats} onLoadSpecifiedPage={onLoadSpecifiedPage} />
    </section>

export default ExchangeChatViewPanel;
