const key_dashboard = 'dashboard';
const key_platforms = 'platforms';
const key_add_offers = 'addoffers';
const key_add_seeks = 'addseeks';
const key_offer_bids = 'offerbids';
const key_seek_bids = 'seekbids';
const key_review_bid = 'reviewbid';
const key_accepted_bid = 'acceptedbid';
const key_exchanges = 'exchanges';
const key_my_platforms = 'myplatforms';
const key_users = 'users';
const key_invited = 'invited';
const key_delete_fieldset = 'deletefieldset';
const key_fieldsets = 'fieldsets';
const key_modify_fieldset = 'modifyfieldset';
const key_password = 'password';
const key_report_issue = 'reportanissue';
const key_add_pay_method = 'addpaymethod';
const key_remove_pay_method = 'removepaymethod';
const key_start_setup_online = 'startsetuponline';
const key_start_sub = 'startsub';
const key_cancel_sub = 'cancelsub';
const key_manage_email = 'manageemail';
const key_payout_transactions_start = 'payout-trans1';
const key_reject_offer_bid = 'rejectofferbid';
const key_saved_img = 'savedimg';
const key_exchange_message = 'exchangemessage';
const key_tfa = 'tfa';
const key_change_email = 'changeemail';

const menuData = [
    {
        key: key_report_issue,
        title: 'Report an Issue',
        top: true,
        details: 'Tell us about a problem you are experiencing',
        videoFile: 'report-an-issue.mp4',
        relatedItems: [],
    },
    {
        key: key_password,
        title: 'Update Password',
        top: true,
        details: 'Change your login password',
        videoFile: 'password-update.mp4',
        relatedItems: [
            {
                key: key_tfa,
                title: 'Two-factor authentication',
            },
            {
                key: key_change_email,
                title: 'Change Email'
            }
        ],
    },
    {
        key: key_tfa,
        title: 'Two-factor authentication',
        top: true,
        details: 'Keep your account more secure with 2FA logins',
        videoFile: 'two-factor-authentication.mp4',
        relatedItems: [
            {
                key: key_password,
                title: 'Update Password',
            },
            {
                key: key_change_email,
                title: 'Change Email'
            }
        ]
    },
    {
        key: key_start_setup_online,
        title: 'Setup Online Payments',
        top: true,
        details: 'Provide the information required to receive online payments',
        videoFile: 'setup-online-payments.mp4',
        relatedItems: [
            {
                key: key_payout_transactions_start,
                title: 'Payout Transactions',
            },
        ],
    },
    {
        key: key_payout_transactions_start,
        title: 'Payout Transactions',
        top: true,
        details: 'Track the status of your online income',
        videoFile: 'payout-transactions.mp4',
        relatedItems: [
            {
                key: key_start_setup_online,
                title: 'Setup Online Payments',
            },
        ],
    },
    {
        key: key_start_sub,
        title: 'Start Subscription',
        top: true,
        details: 'Help support our platform while reducing your online transaction fees',
        videoFile: 'start-subscription.mp4',
        relatedItems: [
            {
                key: key_cancel_sub,
                title: 'Cancel Subscription',
            }
        ]
    },
    {
        key: key_cancel_sub,
        title: 'Cancel Subscription',
        top: true,
        details: 'Stop your recurring Bears.Exchange subscription payments',
        videoFile: 'cancel-subscription.mp4',
        relatedItems: [
            {
                key: key_start_sub,
                title: 'Start Subscription',
            }
        ]
    },
    {
        key: key_dashboard,
        title: 'Dashboard',
        top: true,
        details: 'The dashboard provides a custom view of what is important to you.',
        videoFile: 'dashboard-and-widgets.mp4',
        relatedItems: [],
    },
    {
        key: key_platforms,
        title: 'Platforms',
        top: true,
        details: `Platforms is a hub for Beartarian and bear adjacent platforms.`,
        videoFile: 'platforms.mp4',
        relatedItems: [
            {
                key: key_my_platforms,
                title: 'My Platforms',
            },
        ],
    },
    {
        key: key_offer_bids,
        title: 'Offer Bids',
        top: true,
        details: 'Place a bid to attempt to accept a posted offer.',
        videoFile: 'offer-bids.mp4',
        relatedItems: [
            {
                key: key_review_bid,
                title: 'Review a Bid',
            },
            {
                key: key_accepted_bid,
                title: 'Accepted Bid',
            },
            {
                key: key_add_offers,
                title: 'Add an Offer',
            },
            {
                key: key_exchanges,
                title: 'Exchanges',
            },
        ]
    },
    {
        key: key_seek_bids,
        title: 'Seek Bids',
        top: true,
        details: 'Place a bid to attempt fulfill a posted seek.',
        videoFile: 'seek-bid.mp4',
        relatedItems: [
            {
                key: key_review_bid,
                title: 'Review a Bid',
            },
            {
                key: key_accepted_bid,
                title: 'Accepted Bid',
            },
            {
                key: key_add_seeks,
                title: 'Add a Seek',
            },
            {
                key: key_exchanges,
                title: 'Exchanges',
            },
        ]
    },
    {
        key: key_exchanges,
        title: 'Exchanges', 
        top: true,
        details: 'An exchange is a record to track a transaction.  Current and past orders are called Exchanges.',
        videoFile: 'exchanges.mp4',
        relatedItems: [
            {
                key: key_exchange_message,
                title: 'Send an exchange message',
            },
            {
                key: key_offer_bids,
                title: 'Offer Bids',
            },
            {
                key: key_seek_bids,
                title: 'Seek Bids',
            },
            {
                key: key_review_bid,
                title: 'Review a Bid',
            },
            {
                key: key_accepted_bid,
                title: 'Accepted Bid',
            },
        ],
    },
    {
        key: key_exchange_message,
        title: 'Send an exchange message',
        top: true,
        details: 'Communicate with other users as you complete exchanges',
        videoFile: 'send-exchange-message.mp4',
        relatedItems: [
            {
                key: key_exchanges,
                title: 'Exchanges',  
            }
        ]
    },
    {
        key: key_add_pay_method,
        title: 'Add a payment method',
        top: true, 
        details: 'Save an online payment method for future payments.  This will create a temporary charge to verify the card.',
        videoFile: 'add-a-payment-method.mp4',
        relatedItems: [
            {
                key: key_remove_pay_method,
                title: 'Remove a payment method',        
            }
        ]
    },
    {
        key: key_manage_email,
        title: 'Manage email',
        top: true,
        details: 'Manage your email contact preferences',
        videoFile: 'manage-email-and-settings.mp4',
        relatedItems: [
            {
                key: key_change_email,
                title: 'Change Email'
            }
        ]
    },
    {
        key: key_users,
        title: 'Users',
        top: true,
        details: 'Search for and friend users to allow for access to friend only offer and seek details.',
        videoFile: 'friending-a-user.mp4',
        relatedItems: [
            {
                key: key_invited,
                title: 'Invited Friends',
            },
        ],
    },
    {
        key: key_invited,
        title: 'Invited Friends',
        top: false,
        details: 'See the users you invited',
        videoFile: 'invited-friends.mp4',
        relatedItems: [
            {
                key: key_users,
                title: 'Users',
            }
        ],
    },
    {
        key: key_my_platforms,
        title: 'My Platforms',
        top: true,
        details: 'Use the Manage My Platforms panel to list the platforms where you are available.',
        videoFile: 'my-platforms.mp4',
        relatedItems: [
            {
                key: key_platforms,
                title: 'Platforms',
            },
        ],
    },
    {
        key: key_add_offers,
        title: 'Add an Offer',
        top: true,
        details: 'An offer can be as simple or detailed as you need.  We recommend providing plenty of detail including currency type and shipping details.',
        videoFile: 'add-an-offer.mp4',
        relatedItems: [
            {
                key: key_offer_bids,
                title: 'Offer Bids',
            },
            {
                key: key_fieldsets,
                title: 'Fieldsets',
            },
            {
                key: key_review_bid,
                title: 'Review a Bid',
            },
            {
                key: key_accepted_bid,
                title: 'Accepted Bid',
            },
            {
                key: key_exchanges,
                title: 'Exchanges',
            },
        ],
    },
    {
        key: key_add_seeks,
        title: 'Add a Seek',
        top: true,
        details: 'A seek can be as simple or detailed as you need.  We recommend providing plenty of detail including currency type and shipping details.',
        videoFile: 'add-a-seek.mp4',
        relatedItems: [
            {
                key: key_fieldsets,
                title: 'Fieldsets',
            },
            {
                key: key_seek_bids,
                title: 'Seek Bids',
            },
            {
                key: key_review_bid,
                title: 'Review a Bid',
            },
            {
                key: key_accepted_bid,
                title: 'Accepted Bid',
            },
            {
                key: key_exchanges,
                title: 'Exchanges',
            },
        ],
    },
    {
        key: key_fieldsets,
        title: 'Fieldsets',
        top: true,
        details: 'Fieldsets allow you to store sets of custom fields for usage in your offers and seeks',
        videoFile: 'fieldsets.mp4',
        relatedItems: [
            {
                key: key_modify_fieldset,
                title: 'Modify a fieldset',
            },
            {
                key: key_delete_fieldset,
                title: 'Delete a fieldset',
            },
            {
                key: key_add_seeks,
                title: 'Add a Seek',
            },
            {
                key: key_add_offers,
                title: 'Add an Offer',
            },
        ],
    },
    {
        key: key_delete_fieldset,
        title: 'Delete a fieldset',
        top: true,
        details: 'Field sets can be quickly removed',
        videoFile: 'delete-a-fieldset.mp4',
        relatedItems: [
            {
                key: key_fieldsets,
                title: 'Fieldsets',
            },
            {
                key: key_modify_fieldset,
                title: 'Modify a fieldset',
            },
            {
                key: key_add_seeks,
                title: 'Add a Seek',
            },
            {
                key: key_add_offers,
                title: 'Add an Offer',
            },
        ],
    },
    {
        key: key_modify_fieldset,
        title: 'Modify a fieldset',
        top: true,
        details: 'Fieldsets can be created and deleted.  There is a way to modify a fieldset.  Navigate to create a new fieldset, copy the existing fields into the new fieldset, modify, then save.',
        videoFile: 'modify-a-fieldset.mp4',
        relatedItems: [
            {
                key: key_fieldsets,
                title: 'Fieldsets',
            },
            {
                key: key_delete_fieldset,
                title: 'Delete a fieldset',
            },
        ],
    },
    {
        key: key_saved_img,
        title: 'Saved Images',
        top: true,
        details: 'When you publish images to seeks or offers then your images will be saved for future usage',
        videoFile: 'saved-images.mp4',
        relatedItems: [
            {
                key: key_add_seeks,
                title: 'Add a Seek',
            },
            {
                key: key_add_offers,
                title: 'Add an Offer',
            },
        ]
    },
    {
        key: key_review_bid,
        title: 'Review a Bid',
        top: true,
        details: 'When you a receive a bid you can accept or reject it.  If you accept a bid, it will close your post and create an exchange.',
        videoFile: 'review-a-bid.mp4',
        relatedItems: [
            {
                key: key_offer_bids,
                title: 'Offer Bids',
            },
            {
                key: key_seek_bids,
                title: 'Seek Bids',
            },
            {
                key: key_accepted_bid,
                title: 'Accepted Bid',
            },
            {
                key: key_exchanges,
                title: 'Exchanges',
            },
        ],
    },
    {
        key: key_reject_offer_bid,
        title: 'Reject offer bid',
        top: true,
        details: 'Reject a bid on your offer',
        videoFile: 'reject-offer-bid.mp4',
        relatedItems: [
            {
                key: key_offer_bids,
                title: 'Offer Bids',
            },
            {
                key: key_accepted_bid,
                title: 'Accepted Bid',
            },
            {
                key: key_add_offers,
                title: 'Add an Offer',
            }
        ],
    },
    {
        key: key_accepted_bid,
        title: 'Accepted Bid',
        top: true,
        details: 'Once your bid is accepted you will flow through an exchange with the poster',
        videoFile: 'accepted-bid.mp4',
        relatedItems: [
            {
                key: key_review_bid,
                title: 'Review a Bid',
            },
            {
                key: key_offer_bids,
                title: 'Offer Bids',
            },
            {
                key: key_seek_bids,
                title: 'Seek Bids',
            },
            {
                key: key_exchanges,
                title: 'Exchanges',
            },
        ],
    },
    {
        key: key_change_email,
        title: 'Change Email',
        top: true,
        details: 'Change your account email by sending a code to your existing email',
        videoFile: 'change-email.mp4',
        relatedItems: [
            {
                key: key_manage_email,
                title: 'Manage email',
            }
        ],
    }
];

export default menuData;
