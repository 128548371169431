import React from 'react';
import { useState, useEffect } from 'react';
import AttributeListPanel from './../core/attribute-list';
import DatePanel from './../core/date';
import OfferSeekTypePanel from './../core/offer-seek-type';
import ExchangeListPanel from './../core/exchange-list';
import ObtainTypeDetailListPanel from './../core/obtain-type-detail-list';
import AuthorPanel from './../core/author';
import BidsPanel from './../core/bids';
import ImgPanel from './../../panel/core/img';
import theme from './../../theme';
import ResizeService from './../../../service/resize';

const OfferArchivedDetailViewPanel = ({ data: offer, onLoadSpecifiedPage }) => {
    const getOfferDetailViewPanelStyle = () => theme.getOfferDetailViewPanelStyle(theme);
    const [ offerDetailViewPanelStyle, setOfferDetailViewPanelStyle ] = useState(getOfferDetailViewPanelStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferDetailViewPanelStyle(getOfferDetailViewPanelStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={offerDetailViewPanelStyle}>
            <div style={{
                ...theme.getGeneralTextStyle(theme),
                ...theme.getWarningTextStyle(theme),
                ...theme.getMediumTextStyle(theme),
            }}>
                {
                    offer.flagged ? 'Flagged Post' : (
                        offer.isActive ? '' : 'No Longer Published'
                    )
                }
            </div>


            <h2>
                { offer.title }
            </h2>
            
            <DatePanel label="Posted" date={offer.createdOn} />
        
            <AuthorPanel author={offer.author} />
            
            <OfferSeekTypePanel offerSeekType={offer.offerSeekType} />
            <AttributeListPanel attributeList={offer.attributeList} />
            <p>
                { offer.details }
            </p>
            <ExchangeListPanel exchangeList={offer.exchangeList} />
            <ObtainTypeDetailListPanel 
                obtainTypeDetailList={offer.obtainTypeDetailList} 
                contentType="offer"
            />

            {
                // istanbul ignore next 
                offer.imgList.length > 0 ? <ImgPanel imgList={offer.imgList} /> : ''
            }

            {
                offer.isConfirmView ? '' : 
                    <BidsPanel 
                        bidderIsReceiver={true} 
                        targetType="offer"
                        isActive={false} bids={offer.bids} 
                        onLoadSpecifiedPage={onLoadSpecifiedPage} 
                    />
            }

        </div>
    );
};

export default OfferArchivedDetailViewPanel;
