import React from 'react';
import { useState, useEffect } from 'react';
import OnboardingService from '../../../service/onboarding';
import DynamicFrame from './dynamic-frame';

const OnboardingViewPanel = ({
    exitOnboarding,
    crawler,
    updateCrawler
}) => {

    const [ selectedFrame, setSelectedFrame ] = useState(
        OnboardingService.getFrameByCrawler(crawler)
    );

    useEffect(() => {
        setSelectedFrame(
            OnboardingService.getFrameByCrawler(crawler)
        );
    }, [crawler]);

    return <DynamicFrame
        exitOnboarding={exitOnboarding}
        updateCrawler={updateCrawler}
        selectedFrame={selectedFrame}
    />
    
};

export default OnboardingViewPanel;
