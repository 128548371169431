const isCurrentPageSpecial = (to, currentPath) => {
    switch(to)
    {
        case '/profile/report/issue':
            switch(currentPath)
            {
                case '/profile/reported/issues':
                    return true;

                default:
                    if(/\/profile\/reported\/issue/.test(currentPath))
                    {
                        return true;
                    }
                    
                    return false;
            }

        case '/admin/metrics/summary':
            switch(currentPath)
            {
                case '/admin/metrics':
                    return true;

                default: 
                    return false;
            }

        case '/terms':
            switch(currentPath)
            {
                case '/privacy':
                    return true;

                default: 
                    return false;
            }

        case '/profile/income':
            switch(currentPath)
            {
                case '/payouts':
                    return true;

                case '/payment/methods':
                    return true;

                case '/payment/method/add':
                    return true;

                case '/transactions/list':
                    return true;

                case '/payout/add':
                    return true;

                case '/profile/fees':
                    return true;

                default: 
                    return false;

            }

        case '/payment/methods':
            switch(currentPath)
            {
                case '/payment/method/add':
                    return true;

                default:
                    return false;
            }

        case '/profile/people':
            switch(currentPath)
            {
                case '/users':
                    return true;

                case '/trails':
                    return true;

                case '/profile/trails':
                    return true;

                case '/join':
                    return true;

                case '/profile/groups/carrier':
                    return true;

                default:
                    if(/\/user/.test(currentPath))
                    {
                        return true;
                    }

                    if(/\/profile\/groups\/carrier/.test(currentPath))
                    {
                        return true;
                    }

                    return false;
            }

        case '/payouts':
            switch(currentPath)
            {
                case '/payout/add': 
                    return true;
                    
                default:
                    return false;
            }

        case '/users':
            switch(currentPath)
            {
                default: 
                    if(/\/user/.test(currentPath))
                    {
                        return true;
                    }
                    return false;
            }
        case '/posts':

            switch(currentPath)
            {
                case '/offers':
                    return true;

                case '/seeks':
                    return true;

                case '/offer/add':
                    return true;

                case '/seek/add':
                    return true;
        
                default:
                    if(/\/offer\//.test(currentPath))
                    {
                        return true;
                    }

                    if(/\/seek\//.test(currentPath))
                    {
                        return true;
                    }

                    return false;
            }

        case '/exchanges':
            switch(currentPath)
            {
                default:
                    return /exchanges/.test(currentPath);
            }

        case '/offers':
            switch(currentPath)
            {
                case '/offer/add':
                    return true;

                default:
                    return /\/offer\//.test(currentPath);

            }

        case '/seeks':
            switch(currentPath)
            {
                case '/seek/add':
                    return true;
                    
                default:
                    return /\/seek\//.test(currentPath);
            }

        case '/profile/pwd':
            switch(currentPath)
            {
                case '/subscription':
                    return true;
                    
                case '/profile/pwd/update':
                    return true;

                case '/profile/pwd/token':
                    return true;
                    
                default:
                    return false;
            }

        case '/profile/store':
            switch(currentPath)
            {
                case '/location/list':
                    return true;

                case '/profile/email':
                    return true;

                case '/profile/settings':
                    return true;

                case '/profile/att-sets':
                    return true;

                case '/img/list':
                    return true;
                
                case '/profile/att-set/add':
                    return true;

                case '/profile/shop':
                    return true;

                default:
                    return false;
            }

        case '/profile/info':
            switch(currentPath)
            {
                case '/news':
                    return true;
                
                case '/privacy':
                    return true;

                case '/terms':
                    return true;

                case '/profile/report/issue':
                    return true;

                case '/profile/reported/issues':
                    return true;

                case '/fees':
                    return true;

                case '/help':
                    return true;

                default:
                    if(/\/profile\/reported\/issue/.test(currentPath))
                    {
                        return true;
                    }

                    return false;
            }

        case '/profile':
            switch(currentPath)
            {
                case '/profile/fees':
                    return true;
                
                case '/payouts':
                    return true;

                case '/payment/methods':
                    return true;

                case '/profile/email':
                    return true;

                case '/profile/settings':
                    return true;

                case '/profile/att-sets':
                    return true;

                case '/profile/att-set/add':
                    return true;

                case '/payment/method/add':
                    return true;

                case '/transactions/list':
                    return true;

                case '/payout/add':
                    return true;
                
                case '/profile/income':
                    return true;
                
                case '/help':
                    return true;
                
                case '/news':
                    return true;
                    
                case '/profile/trails':
                    return true;

                case '/profile/shop':
                    return true;
                   
                case '/users':
                    return true;

                case '/img/list':
                    return true;

                case '/profile/report/issue':
                    return true;

                case '/profile/reported/issues':
                    return true;

                case '/privacy':
                    return true;

                case '/terms':
                    return true;

                case '/join':
                    return true;

                case '/profile/pwd':
                    return true;

                case '/profile/pwd/update':
                    return true;

                case '/profile/pwd/token':
                    return true;

                case '/trails':
                    return true;

                case '/profile/info':
                    return true;

                case '/profile/store':
                    return true;

                case '/profile/people':
                    return true;

                case '/profile/groups/carrier':
                    return true;

                case '/subscription':
                    return true;

                case '/fees':
                    return true;

                case '/location/list':
                    return true;

                default:
                    if(/\/user/.test(currentPath))
                    {
                        return true;
                    }

                    if(/\/profile\/reported\/issue/.test(currentPath))
                    {
                        return true;
                    }

                    if(/\/profile\/groups\/carrier/.test(currentPath))
                    {
                        return true;
                    }

                    return false;
            }

            case '/admin/money':
                switch(currentPath)
                {
                    case '/admin/ledger/summary':
                        return true;

                    case '/admin/ledger':
                        return true;
                        
                    case '/admin/disputed':
                        return true;

                    case '/admin/payouts':
                        return true;

                    default:
                        return false;
                }

            case '/admin/wiki':
                switch(currentPath)
                {
                    case '/admin/news/add':
                        return true;

                    case '/admin/tag/add':
                        return true;

                    case '/admin/trail/add':
                        return true;

                    case '/admin/test/email':
                        return true;

                    default: 
                        return false;
                }

            case '/admin/users':
                switch(currentPath)
                {
                    case '/admin/invite/send':
                        return true;

                    case '/admin/review/flags':
                        return true;

                    case '/admin/reported/issues':
                        return true;

                    default: 
                        return false;
                }

            case '/admin/stats':
                switch(currentPath)
                {
                    case '/admin/client/stats':
                        return true;

                    default:
                        return false;
                }

            case '/admin':
                switch(currentPath)
                {
                    case '/admin/ip':
                        return true;

                    case '/admin/logs':
                        return true;

                    case '/admin/metrics/summary':
                        return true;

                    case '/admin/metrics':
                        return true;

                    case '/admin/client/stats':
                        return true;

                    case '/admin/ledger/summary':
                        return true;
                        
                    case '/admin/ledger':
                        return true;

                    case '/admin/disputed':
                        return true;
                        
                    case '/admin/payouts':
                        return true;
                    
                    case '/admin/money':
                        return true;

                    case '/admin/news/add':
                        return true;

                    case '/admin/trail/add':
                        return true;

                    case '/admin/stats':
                        return true;

                    case '/admin/invite/send':
                        return true;

                    case '/admin/review/flags':
                        return true;

                    case '/admin/reported/issues':
                        return true;

                    case '/admin/wiki':
                        return true;

                    case '/admin/tag/add':
                        return true;

                    case '/admin/test/email':
                        return true;

                    case '/admin/users':
                        return true;

                    case '/admin/events':
                        return true;

                    default:
                        return false;
                }

        case '/profile/groups/carrier':
            switch(currentPath)
            {

                default:

                    if(/\/profile\/groups\/carrier/.test(currentPath))
                    {
                        return true;
                    }

                    return false;
            }

        default:
            return false;
    }
};

export default isCurrentPageSpecial;
