import React from 'react';
import theme from './../../theme';
import CloseIcon from './../../../img/close.svg';
import filter from './../../../img/filter.svg';

const ClosedEmptyView = ({ setIsOpen }) => 
    <div 
        style={{
            ...theme.getTagFilterHeaderStyle(theme),
            ...theme.getTagFilterContainerIconStyle(theme)
        }}
        className="test-tagFilterOpenBtn"
        onClick={event => { setIsOpen(true) }}
    >
        <div style={{
            ...theme.getOfferSearchFilterIconStyle(theme, filter)
        }}>

        </div>
        Add Filters
    </div>

const ClosedTagLineView = ({ tag }) => 
    <div style={{
        ...theme.getTagFilterRightStyle(theme)
    }}>
        <div>
            &nbsp;|&nbsp;
        </div>

        { tag }

        <div>
            &nbsp;|&nbsp;
        </div>
    </div>

const ClosedPopulatedView = ({ 
    setIsOpen, 
    selectedTagList 
}) => 
    <div 
        style={{
            ...theme.getTagFilterContainerIconStyle(theme)
        }}
        className="test-tagFilterOpenBtn"
        onClick={event => { setIsOpen(true) }}
    >
        <div style={{
            ...theme.getOfferSearchFilterIconStyle(theme, filter)
        }}>
        </div>
        <div style={{
            ...theme.getTagFilterHeaderStyle(theme)
        }}>
            Add/Remove Filters
        </div>
        <div style={{
            ...theme.getTagFilterTagContainerStyle(theme)
        }}>
        {
            selectedTagList.map((tag, index) => 
                <ClosedTagLineView 
                    tag={tag} 
                    key={index} 
                />
            )
        }
        </div>
    </div>

const ClosedView = ({ 
    setIsOpen, 
    selectedTagList 
}) => selectedTagList.length < 1 ? 
    <ClosedEmptyView 
        setIsOpen={setIsOpen} 
    /> : 
        <ClosedPopulatedView 
            setIsOpen={setIsOpen} 
            selectedTagList={selectedTagList} 
        />
    
const OpenLeftTagLineView = ({ 
    tag, 
    selectedTagList, 
    onTagAdded, 
    onTagRemoved  
}) => {
    const isSelected = selectedTagList.some(selTag => selTag === tag.title);
    const onTagChanged = isSelected ? onTagRemoved : onTagAdded;
    return (
        <div style={{
            ...theme.getTagFilterOpenLeftLineStyle(theme)  
        }}>
            <input 
                type="checkbox" 
                checked={isSelected} 
                className="test-tagFilterToggleBtn"
                onChange={event => { onTagChanged(tag.title) }}
            />
            <div>
                { tag.title }
                &nbsp;
                ( { tag.total } )
            </div>
        </div>
    );
};

const OpenLeftView = ({ 
    selectedTagList, 
    allTagList, 
    onTagAdded, 
    onTagRemoved 
}) => 
    <div style={{
        ...theme.getTagFilterOpenLeftStyle(theme)
    }}>
        {
            allTagList.map((tag, index) => 
                <OpenLeftTagLineView 
                    tag={tag} 
                    key={index} 
                    selectedTagList={selectedTagList} 
                    onTagAdded={onTagAdded}
                    onTagRemoved={onTagRemoved} 
                />
            )
        }
    </div>

const OpenRightLineView = ({ tag }) => 
    <div style={{
        ...theme.getTagFilterRightStyle(theme)  
    }}>
        <div>
            &nbsp;|&nbsp;
        </div>

        { tag }

        <div>
            &nbsp;|&nbsp;
        </div>
    </div>

const OpenPopulatedRightView = ({ 
    selectedTagList, 
    onRemoveAllTags 
}) => 
    <div style={{
        ...theme.getTagFilterRightContainerStyle(theme)  
    }}>
        {
            selectedTagList.map((tag, index) => 
                <OpenRightLineView 
                    tag={tag} 
                    key={index} 
                />
            )
        }
        <div style={{
          ...theme.getTagFilterOpenClearBarStyle(theme)  
        }}>
            <button 
                className="test-tagFilterClearAllBtn"
                onClick={event => { onRemoveAllTags() }}
            >
                Clear
            </button>
        </div>
    </div>

const OpenEmptyRightView = () => 
    <div style={{
        ...theme.getTagFilterNoSelectHeaderStyle(theme)  
    }}>
        Select a category to filter your search
    </div>

const OpenRightView = ({ 
    selectedTagList, 
    onRemoveAllTags 
}) => selectedTagList.length < 1 ? <OpenEmptyRightView /> : 
    <OpenPopulatedRightView 
        selectedTagList={selectedTagList} 
        onRemoveAllTags={onRemoveAllTags} 
    />

const OpenView = ({ 
    setIsOpen, 
    selectedTagList, 
    allTagList, 
    onTagAdded, 
    onTagRemoved, 
    onRemoveAllTags 
}) => 
    <div style={{
        ...theme.getTagFilterContainerStyle(theme)
    }}>
        <div style={{
        ...theme.getTagFilterOpenFlexContainerTitleStyle(theme)
        }}>
            <div style={{
                ...theme.getTagFilterOpenViewButtonContainerStyle(theme)  
            }}>
                <theme.getIconButtonUi 
                    theme={theme}
                    icon={CloseIcon}
                    className="test-tagFilterCloseBtn"
                    onClick={
                        /* istanbul ignore next */
                        event => { setIsOpen(false) } 
                    }
                />
            </div>
            <div style={{
                ...theme.getTagFilterOpenHeaderStyle(theme),  
            }}>
                Filter By Category
            </div>

            <div style={{
                ...theme.getTagFilterOpenHeaderSpacerStyle(theme)  
            }}>
            </div>
        </div>
        <div style={{
            ...theme.getTagFilterOpenFlexContainerStyle(theme)  
        }}>

            <OpenLeftView 
                selectedTagList={selectedTagList}
                allTagList={allTagList}
                onTagAdded={onTagAdded}
                onTagRemoved={onTagRemoved}
            />

            <OpenRightView selectedTagList={selectedTagList} onRemoveAllTags={onRemoveAllTags} />

            <div style={{ clear: 'both' }}></div>
        </div>
    </div>

const TagFilterPanel = ({ 
    isOpen, 
    setIsOpen, 
    selectedTagList, 
    allTagList, 
    onTagAdded, 
    onTagRemoved, 
    onRemoveAllTags 
}) => {

    return allTagList.length < 1 ? '' : ( 
        isOpen ? <OpenView 
            setIsOpen={setIsOpen} 
            selectedTagList={selectedTagList}
            allTagList={allTagList}
            onTagAdded={onTagAdded}
            onTagRemoved={onTagRemoved}
            onRemoveAllTags={onRemoveAllTags}
        /> : 
        <ClosedView 
            setIsOpen={setIsOpen} 
            selectedTagList={selectedTagList}
        />
        );

};

export default TagFilterPanel;
