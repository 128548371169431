import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class UserService  {

    static getSearchUsersRequest(username, page = 0) {
        return {
            username,
            page
        };
    }
    
    /* istanbul ignore next */
    static searchUsers({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        
        endpointRequest({
            url: config.url.user.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getUserSettingsRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static getSettings({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.settings.list,
            request: this.getUserSettingsRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUserClientSettingsRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static getUserClientSettings({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.settings.client,
            request: this.getUserClientSettingsRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUnsubscribeRequest(email)
    {
        return {
            email
        };
    }

    /* istanbul ignore next */
    static unsubscribe(request, onResponse, onError)
    {
        endpointRequest({
            url: config.url.unauth.unsubscribe,
            request,
            onResponse,
            onError
        });
    }

    static getConsentToEmailRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static consentToEmail({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.settings.consentToEmail,
            request: this.getConsentToEmailRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getApplySettingRequest(appSettingId, title, value)
    {
        return {
            appSettingId,
            title,
            value,
        };
    }

    /* istanbul ignore next */
    static applySettingUpdate({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.settings.apply,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });        
    }

    static getUserDetailsRequest(userId) {
        return {
            detailsUserId: userId
        };
    }

    /* istanbul ignore next */
    static getUserDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.details,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdatePwdRequest(currentPwd, nextPwd) {
        return {
            currentPwd,
            nextPwd,
        };
    }

    /* istanbul ignore next */
    static updatePwd({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.updatePwd,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getInviteDetailsRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static getInviteDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.invites,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminStatsRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static getAdminClientStats({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.clientStats,
            request: {},
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    /* istanbul ignore next */
    static getAdminStats({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.stats,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getIssueInvitesRequest(inviteCount, shareToUserId)
    {
        return {
            inviteCount, 
            shareToUserId
        };
    }

    /* istanbul ignore next */
    static issueInvites({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.issueInvites,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminUserDetailsRequest(id, page = 0) 
    {
        return {
            id,
            page,
        };
    }

    /* istanbul ignore next */
    static getAdminUserDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.userDetails,
            request, 
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getToggleAccessRequest(id, loginEnabled)
    {
        return {
            id,
            loginEnabled
        };
    }

    /* istanbul ignore next */
    static toggleAccess({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.userAccess,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getCreate2FATokenRequest(label)
    {
        return {
            label
        };
    }

    /* istanbul ignore next */
    static create2FAToken({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.token.create,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getVerify2FATokenRequest(token)
    {
        return {
            token
        };
    }

    /* istanbul ignore next */
    static verify2FAToken({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.token.verify,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getModify2FATokenRequest(enabled)
    {
        return {
            enabled
        };
    }

    /* istanbul ignore next */
    static modify2FAToken({
        enabled, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.token.modify,
            request: {
                enabled
            },
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getLoad2FATokenRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static load2FAToken({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.token.load,
            request: this.getLoad2FATokenRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemove2FATokenRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static remove2FAToken({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.token.remove,
            request: this.getRemove2FATokenRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListUserStoreTraitsRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static listUserStoreTraits({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.store.listTraits,
            request: this.getListUserStoreTraitsRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSetStoreTraitRequest(enabled, traitType, details)
    {
        return {
            enabled, 
            traitType, 
            details
        };
    }

    /* istanbul ignore next */
    static setStoreTrait({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.store.setTrait,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveStoreTraitRequest(traitType)
    {
        return {
            traitType
        };
    }

    /* istanbul ignore next */
    static removeStoreTrait({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.store.removeTrait,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSendEmailVerifyCodeRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static sendEmailVerifyCode({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.email.sendCode,
            request: this.getSendEmailVerifyCodeRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getChangeUserEmailRequest(nextEmail, verifyCode)
    {
        return {
            nextEmail, 
            verifyCode
        };
    }

    /* istanbul ignore next */
    static changeUserEmail({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.email.change,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSearchIpRequest(searchText, page)
    {
        return {
            searchText,
            page
        };
    }

    /* istanbul ignore next */
    static searchIp({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.ipSearch,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
};

export default UserService;