export const getSmallMapStyle = (theme) => ({
    margin: '2rem auto 3rem',
    width: '10rem',
    height: '10rem'
});

export const getMapFinePrint = (theme) => ({
    fontSize: '8pt'
});

export const getMapMarkerStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: '30px',
    height: '30px',
    color: theme.primaryColor,
    backgroundColor: theme.secondaryColor,
    border: 'solid 2px',
    borderRadius: '0 70% 70%',
    boxShadow: '0 0 2px #000',
    cursor: 'pointer',
    transformOrigin: '0 0',
    transform: 'rotateZ(-135deg)'
});

export const getMapFeaturesUnavailbleStyle = (theme) => ({
    fontSize: '8pt',
    color: theme.warningColor,
    textAlign: 'center'
});