import React from 'react';
import UserLandingViewPanel from './view';
import WidgetService from './../../../service/widget';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import LoadingPanel from './../core/loading';
import theme from './../../theme';

class _UserLandingPanel extends React.Component 
{
    constructor(parms)
    {
        super(parms);
        
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onAddWidget = this.onAddWidget.bind(this);
        this.onRemoveWidget = this.onRemoveWidget.bind(this);
        this.getContactingServerUi = this.getContactingServerUi.bind(this);
        this.onUpdateWidgetResponse = this.onUpdateWidgetResponse.bind(this);
        this.loadUserWidgets = this.loadUserWidgets.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onUpdateWidgetError = this.onUpdateWidgetError.bind(this);
        this.loadUserWidgets = this.loadUserWidgets.bind(this);
        this.onContactingServer = this.onContactingServer.bind(this);
        this.setUserWidgets = this.setUserWidgets.bind(this);
        this.onSortUpdated = this.onSortUpdated.bind(this);
        
        this.state = this.getDefaultState();
    }

    componentDidMount()
    {
        this.loadUserWidgets(0);
    }

    onUpdateWidgetResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            firstLoadCompleted: true,
            userWidgets: response.list,
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onUpdateWidgetError(error)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            errorMessage: error.response && error.response.error ? error.response.error : error.message,
        };
        this.setState(nextState);
    }

    onContactingServer()
    {
        const nextState = {
            ...this.state,
            contactingServer: true,
            errorMessage: '',
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    loadUserWidgets(page = 0)
    {
        this.onContactingServer();
        WidgetService.list({
            request: { page },
            onResponse: this.onUpdateWidgetResponse,
            onError: this.onUpdateWidgetError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    /* istanbul ignore next */
    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onAddWidget(widget)
    {
        this.onContactingServer();
        WidgetService.add({
            request: {
                type: widget.type,
                widgetParmsJson: widget.widgetParmsJson,
                sortOrder: widget.sortOrder,
            },
            onResponse: this.onUpdateWidgetResponse,
            onError: this.onUpdateWidgetError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onRemoveWidget(widget)
    {
        this.onContactingServer();
        WidgetService.remove({
            request: {
                userWidgetId: widget.id,
            },
            onResponse: this.onUpdateWidgetResponse,
            onError: this.onUpdateWidgetError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getDefaultState()
    {
        return {
            availableWidgets: WidgetService.getAvailableWidgets(),
            userWidgets: [],
            contactingServer: false,
            errorMessage: '',
            firstLoadCompleted: false,
        };
    }
    
    getContactingServerUi()
    {
       return <LoadingPanel />
    }

    /* istanbul ignore next */
    setUserWidgets(list)
    {
        let nextState = this.state;
        nextState.userWidgets = list;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onSortUpdated(list)
    {
        const idList = list.map(rec => rec.id);
        this.onContactingServer();
        WidgetService.reorder({
            request: {
                idList
            }, 
            onResponse: this.onUpdateWidgetResponse,
            onError: this.onUpdateWidgetError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    render()
    {
        return this.state.contactingServer && !this.state.firstLoadCompleted ? this.getContactingServerUi() : 
            <section>
                { 
                    this.state.errorMessage !== '' ? 
                        <p style={{
                            ...theme.getGeneralTextStyle(theme),
                        }}> 
                            { this.state.errorMessage }     
                        </p> : ''
                }
                <UserLandingViewPanel 
                    displayAvailableWidgetsPrompt={this.props.displayAvailableWidgetsPrompt}
                    displaySortWidgets={this.props.displaySortWidgets}
                    contactingServer={this.state.contactingServer}
                    availableWidgets={this.state.availableWidgets} 
                    userWidgets={this.state.userWidgets}
                    onAddWidget={this.onAddWidget}
                    onRemoveWidget={this.onRemoveWidget}
                    onSortUpdated={this.onSortUpdated}
                    setUserWidgets={this.setUserWidgets}
                />
            </section>
    }
}

const UserLandingPanel = connect(mapStateToProps, mapDispatchToProps)(_UserLandingPanel);

export default UserLandingPanel;