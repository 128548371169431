class UrlService 
{
    static correctUrl(regex, successUrl, forceReload = false)
    {
        const url = window.location.href;
        if(regex.test(url))
        {
            window.location.replace(successUrl);
            if(forceReload)
            {
                window.location.reload();
            }
        }
    }

    /* istanbul ignore next */
    static extractPI()
    {
        const intentKey = 'payment_intent';
        const queryString = window.location.search;
        const urlParms = new URLSearchParams(queryString);
        let paymentIntentId = undefined;
        if(urlParms.has(intentKey))
        {
            paymentIntentId = urlParms.get(intentKey);
        }

        return {
            paymentIntentId
        };
    }
}

export default UrlService