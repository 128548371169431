import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';
import StoreTraitTypeEnum from './../../prompt/core/enum/store-trait-type';
import ProfileShopTraitPrompt from './../../prompt/profile-shop-trait';

const Tab = ({ selectedTab, setTab, tabValue, tabName }) => {
    const isSelected = selectedTab === tabValue;
    return (
        <div 
            className="test-profileShopTraitsTabNameBtn"  
            onClick={event => { setTab(tabValue)}}
                style={{
                    ...theme.getButtonTabStyle(theme, isSelected)
                }}
        >
            { tabName }
        </div>
    );
};

const TraitTabs = ({ selectedTab, setTab }) => {
    const getTraitTabsStyle = () => ({
        ...theme.getAbstractFlexContainerStyle(theme),
        ...theme.getAbstractFlexGapStyle(theme)
    });
    const [ traitTabsStyle, setTraitTabsStyle ] = useState( getTraitTabsStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setTraitTabsStyle( getTraitTabsStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={traitTabsStyle}>
            { 
                StoreTraitTypeEnum.getDropDownValues().map((rec, index) => 
                    <Tab key={index} tabValue={rec.value} tabName={rec.name} selectedTab={selectedTab} setTab={setTab} />
                )
            }
        </div>
    );
}

const Header = ({ selectedTab, setTab }) => 
    <div style={{
        ...theme.getInLineTextStyle(theme),
        ...theme.getInLineColumnStyle(theme),
    }}>
        <h2>
            Shop Profile
        </h2>
        <TraitTabs selectedTab={selectedTab} setTab={setTab} />
    </div>

const hasTabData = (data, type) => !! data.list.find(rec => rec.traitType === type);
const getTabRecord = (data, type) => data.list.find(rec => rec.traitType === type);

const AnyTab = ({ 
    data, 
    traitType, 
    onUpdateShopTrait, 
    enabledLabel, 
    detailsLabel,
    onDeleteShopTrait 
}) => {
    const hasData = hasTabData(data, traitType);
    /* istanbul ignore next */
    const record = hasData ? getTabRecord(data, traitType) : null;
    /* istanbul ignore next */
    return <ProfileShopTraitPrompt
        onUpdateShopTrait={onUpdateShopTrait}
        onDeleteShopTrait={() => { onDeleteShopTrait(traitType) }}
        hasData={hasData}
        enabledLabel={enabledLabel}
        detailsLabel={detailsLabel}
        traitType={traitType}
        enabled={hasData ? record.enabled : false}
        details={hasData ? record.details : '' }
    />
}

const ContactDetailsTab = ({ data, onUpdateShopTrait, onDeleteShopTrait }) => 
    <div>
        <p>
            These details will be visible on all exchanges
        </p>
        <AnyTab
            data={ data }
            onUpdateShopTrait={onUpdateShopTrait}
            onDeleteShopTrait={onDeleteShopTrait}
            traitType={StoreTraitTypeEnum.STORE_TRAIT_TYPE_CONTACT_DETAILS}
            enabledLabel="Publish Contact Details"
            detailsLabel="Contact Details"
        />
    </div>

const ReturnPolicyTab = ({ data, onUpdateShopTrait, onDeleteShopTrait }) => 
    <div>
        <p>
            This return policy will auto populate into the prompt for your offer posts and seek bids.
        </p>
        <AnyTab
            data={ data }
            onUpdateShopTrait={onUpdateShopTrait}
            onDeleteShopTrait={onDeleteShopTrait}
            traitType={StoreTraitTypeEnum.STORE_TRAIT_TYPE_RETURN_POLICY}
            enabledLabel="Refund Policy Offered"
            detailsLabel="Refund Policy"
        />

    </div>

const DefaultTab = ({ data, onUpdateShopTrait, onDeleteShopTrait }) => <ContactDetailsTab data={data} onUpdateShopTrait={onUpdateShopTrait} onDeleteShopTrait={onDeleteShopTrait} />

/* istanbul ignore next */
const SelectedTabView = ({ selectedTab, data, onUpdateShopTrait, onDeleteShopTrait }) => selectedTab === StoreTraitTypeEnum.STORE_TRAIT_TYPE_CONTACT_DETAILS ? 
    <ContactDetailsTab data={data} onUpdateShopTrait={onUpdateShopTrait} onDeleteShopTrait={onDeleteShopTrait} /> : (
        selectedTab === StoreTraitTypeEnum.STORE_TRAIT_TYPE_RETURN_POLICY ? 
            <ReturnPolicyTab data={data} onUpdateShopTrait={onUpdateShopTrait} onDeleteShopTrait={onDeleteShopTrait} /> :
                <DefaultTab data={data} onUpdateShopTrait={onUpdateShopTrait} onDeleteShopTrait={onDeleteShopTrait} />
    )

const ProfileShopTraitsViewPanel = ({ 
    data,  
    setTab,
    selectedTab,
    onUpdateShopTrait,
    onDeleteShopTrait
}) => {
    const getContainerStyle = () => theme.getProfileShopTraitsContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={containerStyle}>
            <Header selectedTab={selectedTab} setTab={setTab} />
            <SelectedTabView selectedTab={selectedTab} data={data} onUpdateShopTrait={onUpdateShopTrait} onDeleteShopTrait={onDeleteShopTrait} />
        </div>
    );
};

export default ProfileShopTraitsViewPanel;
