import MaintanceTaskService from './../../../../service/maintance-task';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';

class _MaintanceTasksPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _MaintanceTasksPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            requestName: {
                isValid: ValidateService.anyValue
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            requestName: {
                name: 'Maintance Task',
                error: 'Specify a maintance task to run',
                value: MaintanceTaskService.getDefaultRequestNameValue(),
                valueList: MaintanceTaskService.getRequestNameDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();

        return {
            ...baseState,
            ...fields,
            isValid: true
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        MaintanceTaskService.run({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            requestName: this.state.requestName.value
        }
    }

    getConfirmUi(confirmMesssage = 'Task Completed')
    {
        return super.getConfirmUi(confirmMesssage);
    }

    getSubmitButtonUi(buttonText = 'Run')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const MaintanceTasksPrompt = connect(mapStateToProps, mapDispatchToProps)(_MaintanceTasksPrompt);
export default MaintanceTasksPrompt;
