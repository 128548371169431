export const API_STATUS_SERVICE_LOCATION = 'locationService';
export const API_STATUS_SERVICE_STREET_ADDRESS = 'locationFromStreetService';

const validValues = [
    API_STATUS_SERVICE_LOCATION,
    API_STATUS_SERVICE_STREET_ADDRESS
];

const getDropDownValues = () => [
    {
        name: 'GeoLocation API',
        value: API_STATUS_SERVICE_LOCATION
    },
    {
        name: 'GeoEncoding API',
        value: API_STATUS_SERVICE_STREET_ADDRESS
    }
];

const isValid = value => validValues.includes(value);

const ApiStatusServiceEnum = {
    API_STATUS_SERVICE_LOCATION,
    API_STATUS_SERVICE_STREET_ADDRESS,
    getDropDownValues,
    isValid
};

export default ApiStatusServiceEnum;
