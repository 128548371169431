import React, { useEffect, useState } from 'react';
import theme from './../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import getSmallMenuListData from '../menu-small-list-data';
import isAdmin from '../../../../store/select/is-admin';
import isLoggedIn from '../../../../store/select/is-logged-in';
import { UpdateMenuState } from './../../../../store/state/action';
import { UpdateOnboardingVisible } from './../../../../store/state/action';
import { getCurrentPath, selectedMenuItem, isCurrentPage } from '../menu-item';
import { NavLink } from 'react-router-dom';

const isCurrentPageInChildren = (currentPath, menuItem) => !! menuItem.children.find(aChild => aChild.to && isCurrentPage(aChild.to));

const CRAWL_DEPTH_FOR_DEEP = 2;

const crawlSelectionDepth = (depthCount, menuItem, lastSelected) => {
    if(menuItem.children && isOpen(menuItem, lastSelected))
    {
        depthCount++;
        for(const childItem of menuItem.children)
        {
            depthCount = crawlSelectionDepth(depthCount, childItem, lastSelected);
        }
    }
    
    return depthCount;
};

const isCurrentNavSelectionDeep = (list, lastSelected) => {
    let depthCount = 0;
    for(const menuItem of list)
    {
        depthCount = crawlSelectionDepth(depthCount, menuItem, lastSelected);   
    }

    return depthCount >= CRAWL_DEPTH_FOR_DEEP;
};

/* istanbul ignore next */
const crawlForStartOpened = (currentPath, menuItem) => {
    let isOpen = isCurrentPageInChildren(currentPath, menuItem)
    if(!isOpen)
    {
        for(const aChild of menuItem.children)
        {
            if(aChild.children)
            {
                const isChildOpen = crawlForStartOpened(currentPath, aChild);
                if(isChildOpen)
                {
                    return true;
                }
            }
        }
    }

    return isOpen;
};

/* istanbul ignore next */
const crawlForIsOpened = (lastSelected, menuItem) => {
    let isOpen = lastSelected === menuItem.title;
    if(!isOpen)
    {
        for(const aChild of menuItem.children)
        {
            if(aChild.children)
            {
                const isChildOpen = crawlForIsOpened(lastSelected, aChild);
                if(isChildOpen)
                {
                    return true;
                }
            }
        }
    }
    return isOpen;
};

const isOpen = (menuItem, lastSelected) => {
    const currentPath = getCurrentPath();
    const startOpened = crawlForStartOpened(currentPath, menuItem);
    return lastSelected ? crawlForIsOpened(lastSelected, menuItem) : startOpened;
};

const SmallListMenuItemWithChildren = ({ 
    menuItem,
    lastSelected, 
    setLastSelected,
    isNavDeep,
    isTopLevel = false
}) => {
    
    const [ showChildren, setShowChildren ] = useState( isOpen(menuItem, lastSelected) );
    const navSyle = isTopLevel && isNavDeep ? theme.getSmallListMenuItemParentWhenDeepStyle(theme) : 
        theme.getSmallListMenuItemParentStyle(theme);

    const onClick = event => {
        if(!showChildren)
        {
            setLastSelected(menuItem.title);
        }
        setShowChildren(!showChildren);
    };

    useEffect(() => {
        const open = isOpen(menuItem, lastSelected);
        setShowChildren(open);
    }, [lastSelected, menuItem]);

    
    return (
        <div>
            <div 
                style={navSyle}
                className="test-smallListMenuWithChildren"
                onClick={onClick}
            >
                { menuItem.title }
            </div>
            {
                showChildren && 
                    <ul>
                        {
                            menuItem.children.map((childItem, index) => 
                                <SmallListMenuItem 
                                    menuItem={childItem} 
                                    key={index} 
                                    isNavDeep={isNavDeep}
                                    lastSelected={lastSelected} 
                                    setLastSelected={setLastSelected}
                                />
                            )
                        }
                    </ul>
            }
        </div>
    );
};


const SmallMenuItemWelcomeTour = ({ menuItem, style }) => {
    const dispatch = useDispatch();
    const enterOnboarding = () => {
        dispatch(
            UpdateOnboardingVisible(
                true
            )
        );
    };
    const setShowFullMenu = (showFull) => {
        dispatch(UpdateMenuState(showFull));
    };
    return (
        <div 
            key="onboarding"
            style={{
                cursor: 'pointer'
            }}
            onClick={event => { 
                setShowFullMenu(true);
                enterOnboarding();
            }}
            className="test-smallListMenuWithoutChildren"
        >
            { menuItem.title }
        </div>
    );
};

const SmallListMenuItem = ({ 
    menuItem, 
    lastSelected, 
    setLastSelected,
    isTopLevel = false,
    isNavDeep = false
}) => {
    const dispatch = useDispatch();
    const setShowFullMenu = (showFull) => {
        dispatch(UpdateMenuState(showFull));
    };
    const navStyle = isTopLevel ? (
        isNavDeep ? theme.getSmallListMenuItemParentWhenDeepStyle(theme) : 
            theme.getSmallListMenuItemParentStyle(theme)
    ) : {};
    const divStyle = !isTopLevel ? {
        ...theme.getSmallMenuListLinkStyle(theme)
    } : {};
    
    const listStyle = isTopLevel && isNavDeep && (!menuItem.children || !isOpen(menuItem, lastSelected)) ? 
        theme.getSmallMenuListWhenDeepStyle(theme) : theme.getSmallMenuListStyle(theme);
    return (
        <li style={listStyle}>
            {
                menuItem.children ? 
                    <SmallListMenuItemWithChildren 
                        menuItem={menuItem} 
                        isNavDeep={isNavDeep}
                        isTopLevel={isTopLevel}
                        lastSelected={lastSelected}
                        setLastSelected={setLastSelected}
                    /> : (
                        menuItem.isWelcomeTour ? 
                            <SmallMenuItemWelcomeTour
                                menuItem={menuItem}
                                style={navStyle}
                            /> : 
                            <NavLink 
                                className="test-smallListMenuWithoutChildren"
                                to={menuItem.to}
                                style={navStyle}
                                onClick={ event => { setShowFullMenu(true) }}
                            >
                                <div style={{
                                    ...(isCurrentPage(menuItem.to, true) ? selectedMenuItem : {}),
                                    ...divStyle
                                }}>
                                    { menuItem.title }
                                </div>
                            </NavLink>    
                    )
            }
        </li>
    );
};


const SmallListMenu = () => {
    const showAdminOptions = useSelector(isAdmin);
    const loggedIn = useSelector(isLoggedIn);
    const list = getSmallMenuListData(loggedIn, showAdminOptions);
    const [lastSelected, setLastSelected] = useState(null);
    const [isNavDeep, setIsNavDeep] = useState( isCurrentNavSelectionDeep(list, lastSelected) );

    useEffect(() => {
        setIsNavDeep( isCurrentNavSelectionDeep(list, lastSelected) );
        // eslint-disable-next-line
    }, [lastSelected]);

    return (
        <div style={{
            ...theme.getSmallMenuListContainerStyle(theme)
        }}>
            <ul style={{
                ...theme.getSmallMenuListFlexContainerStyle(theme)
            }}>
                {
                    list.map((menuItem, index) => 
                        <SmallListMenuItem 
                            menuItem={menuItem} 
                            isTopLevel={true} 
                            isNavDeep={isNavDeep}
                            key={index} 
                            lastSelected={lastSelected}
                            setLastSelected={setLastSelected}
                        />
                    )
                }
            </ul>
        </div>
    );
};

export default SmallListMenu;
