import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';
import endpointFileUpload from './endpoint-file-upload';

class ExchangeService {
    static getDetailsRequest(exchangeId)
    {
        return {
            exchangeId,
        };
    }

    /* istanbul ignore next */
    static getDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.details,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSearchRequest(text = '',  page = 0)
    {
        return {
            text,
            page
        };
    }

    /* istanbul ignore next */
    static searchArchive({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.searchArchive,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    /* istanbul ignore next */
    static searchActive({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.searchActive,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSpeakRequest(exchangeId, message, savedImgList)
    {
        return {
            exchangeId,
            message,
            savedImgList,
        };
    }

    /* istanbul ignore next */
    static speak({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointFileUpload({
            url: config.url.exchange.speak,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getChatsRequest(exchangeId, page = 0)
    {
        return {
            exchangeId,
            page,
        };
    }

    /* istanbul ignore next */
    static getChats({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.chats,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getUpdateStateRequest(exchangeId, attributeField, value)
    {
        return {
            exchangeId,
            [attributeField]: value
        };
    }

    /* istanbul ignore next */
    static updateState({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.update,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getDetailsByPayIdRequest(payId)
    {
        return {
            payId
        };
    }

    /* istanbul ignore next */
    static getDetailsByPayId({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.detailsByPayId,
            request, 
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getApproveEarlyPayout(id) 
    {
        return {
            exchangeId: id
        };
    }

    /* istanbul ignore next */
    static approveEarlyPayout({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.approveEarlyPayout,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRequestRefundRequest(exchangeId)
    {
        return {
            exchangeId
        };
    }

    /* istanbul ignore next */
    static requestRefund({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.requestRefund,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getReviewRefundRequest(exchangeId, approved)
    {
        return {
            exchangeId,
            approved
        };
    }

    /* istanbul ignore next */
    static reviewRefund({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.reviewRefund,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDisputeDetailsRequest(exchangeId)
    {
        return {
            exchangeId
        };
    }

    /* istanbul ignore next */
    static getDisputeDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.disputeDetails,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDisputeRespondRequest(exchangeId, fileToFieldMap = [], fieldList = [
        "access_activity_log",
        "billing_address",
        "cancellation_policy",
        "cancellation_policy_disclosure",
        "cancellation_rebuttal",
        "customer_communication",
        "customer_email_address",
        "customer_name",
        "customer_purchase_ip",
        "customer_signature",
        "duplicate_charge_documentation",
        "duplicate_charge_explanation",
        "duplicate_charge_id",
        "product_description",
        "receipt",
        "refund_policy",
        "refund_policy_disclosure",
        "refund_refusal_explanation",
        "service_date",
        "service_documentation",
        "shipping_address",
        "shipping_carrier",
        "shipping_date",
        "shipping_documentation",
        "shipping_tracking_number",
        "uncategorized_file",
        "uncategorized_text",
    ]    )
    {
        let out = {
            exchangeId,
            fileToFieldMap
        };

        fieldList.forEach(fieldName => {
            out[fieldName] = '';
        });

        return out;
    }

    /* istanbul ignore next */
    static disputeRespond({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointFileUpload({
            url: config.url.exchange.disputeRespond,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAcceptDisputeLossRequest(exchangeId) 
    {
        return {
            exchangeId
        };
    }

    /* istanbul ignore next */
    static acceptDisputeLoss({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.disputeLoss,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default ExchangeService;
