import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import Pagination from './../../prompt/core/pagination';
import ResizeService from './../../../service/resize';
import UiService from './../../../service/ui';
import goodIcon from './../../../img/good.svg';
import badIcon from './../../../img/bad.svg';

const ReviewView = ({ review }) => {
    const icon = review.isPositive ? goodIcon : badIcon;
    const getUserReviewHistoryContainerStyle = () => theme.getUserReviewHistoryContainer(theme);
    const [ userReviewHistoryContainerStyle, setUserReviewHistoryContainerStyle ] = useState( getUserReviewHistoryContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserReviewHistoryContainerStyle( getUserReviewHistoryContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={userReviewHistoryContainerStyle}>
            <div style={{
                ...theme.getUserReviewHistoryIconStyle(theme, icon)
            }}></div>
            <h5 style={{
                ...theme.getUserReviewHistoryDateStyle(theme),  
            }}>
                { UiService.timeSince(review.createdOn) }
            </h5>
            <p style={{
                ...theme.getUserReviewDetailStyle(theme)
            }}>
                { review.details }
            </p>
        </div>
    );
};

const PopulatedView = ({ data }) => 
    <div>
        { 
            data.list.map((review, index) => 
                <ReviewView key={index} review={review} />
            )
        }
    </div>


const EmptyView = () => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <p>
            No reviews available
        </p>
    </div>

const GetPagination = ({ data, onLoadSpecifiedPage }) => 
    data.page !== undefined && 
    data.perPage && 
    data.total ? <Pagination 
                    total={data.total}
                    page={data.page}
                    onLoadSpecifiedPage={ onLoadSpecifiedPage }
                    perPage={data.perPage} /> : '';

const UserReviewHistoryViewPanel = ({ data, onLoadSpecifiedPage }) => 
    <section style={{
        ...theme.getReviewHistoryContainerStyle(theme),
    }}>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            Review History
        </h2>
        <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
        {
            data.list.length < 1 ? <EmptyView /> : 
                <PopulatedView data={data} />
        }
        <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
    </section>

export default UserReviewHistoryViewPanel;
