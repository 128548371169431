import React from 'react';
import UiService from './../../../../service/ui';
import CurrencyService from './../../../../service/currency';
import payFineTypeEnum from './../../core/enum/pay-fine-type';

export const Header = () => 
    <thead>
        <tr>
            <td>
                Type
            </td>
            <td>
                Source
            </td>
            <td>
                Amount
            </td>
            <td>
                Auto
            </td>
            <td>
                Paid
            </td>
            <td>
                Last Update
            </td>
        </tr>
    </thead>

export const AdminSearchFineViewPrompt = ({ fineRecord }) => 
    <tr>
        <td>
            { fineRecord.type === payFineTypeEnum.PAY_FINE_TYPE_DISPUTE ? 
                'Lost Dispute' : 'Unknown'
            }
        </td>
        <td>
            { fineRecord.sourceId }
        </td>
        <td>
            { CurrencyService.getDisplayPrice(fineRecord.currency, fineRecord.total, false) }
        </td>
        <td>
            {
                fineRecord.canAutoPay ? (
                    fineRecord.autoPayFailed ? 'Failed' : 
                        'Availabe'
                ) : 'Unavailable'
            }
        </td>
        <td>
            {
                fineRecord.finePaid ? 'Paid' : 'UnPaid'
            }
        </td>
        <td>
            { UiService.timeSince(fineRecord.updatedOn) }
        </td>
    </tr>