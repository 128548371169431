import { 
    Login, 
    UpdateDeviceNetwork,
    UpdateOnboardingVisible 
} from './../state/action';
import iniOnboardingCrawler from '../state/ini-onboarding-crawler';
import ResizeService from '../../service/resize';
import config from '../../config';

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
    onLogin(authToken, userId, isAdmin, unseenNoticeCount, clientSettings)
    {
        dispatch(Login(authToken, userId, isAdmin, unseenNoticeCount, clientSettings));
        setTimeout(() => {
                ResizeService.triggerResizeEvent();
            },
            config.interval.postLoginResizeEvent
        );
    },
    onOnboarding()
    {
        dispatch(UpdateOnboardingVisible(true, iniOnboardingCrawler));
    },
    onUpdateDeviceOnline(deviceIsOnline)
    {
        dispatch(UpdateDeviceNetwork(deviceIsOnline));
    }
});

export default mapDispatchToProps;