export const getAbstractPromptStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
});

export const getAbstractFormContainerStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const getAbstractWordWrapStyle = (theme) => ({
    wordWrap: 'anywhere'
}); 

export const getAbstractFlexContainerStyle = (theme) => ({
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    alignItems: 'center'
});

export const getAbstractFlexContainerNoGutterStyle = (theme) => ({
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : '100%',
    alignItems: 'center'
});

export const getAbstractFlexFormStyle = (theme) => ({
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60rem',
    margin: '0px auto'
});

export const getAbstractFlexGapStyle = (theme) => ({
    gap: '.75rem'
});

export const getAbstractCenterFlexContainerStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
});

export const getAbstractDisplayBlockStyle = (theme) => ({
    display: 'block',
});

export const getAbstractFullWidthtStyle = (theme) => ({
    width: '100%',
});

export const getDynamicAbstractLeftFlexContainerStyle = (theme) => ({
    display: theme.isDesktop() ? 'flex' : 'block',
    justifyContent: 'start',
    alignItems: 'baseline',
});

export const getAbstractLeftFlexContainerStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'baseline',
});

export const getAbstractTextBaselineStyle = (theme) => ({
    alignItems: 'baseline',
});

export const getAbstractTextAlignLeftStyle = (theme) => ({
    textAlign: 'left',
});

export const getAbstractTextAlignRightStyle = (theme) => ({
    textAlign: 'right',
});

export const getAbstractTextAlignCenterStyle = (theme) => ({
    textAlign: 'center',
});

export const getOfferAbstractFlexColumnStyle = (theme) => ({
    flexDirection: 'column',
});

export const getAbstractFlexWrapStyle = (theme) => ({
    flexWrap: 'wrap',
    justifyContent: 'center',
});

export const getAbstractImageFlexStyle = (theme) => ({
    display: 'flex',
    justifyContent: theme.isTablet() ? 'flex-start' : 'center',
    flexWrap: 'wrap',
    gap: '.75rem',
    maxWidth: theme.isDesktop() ? '55.5rem' : 
    (
        theme.isTablet() ? '36.75rem' : 
        '100%'
    ),
    alignSelf: 'center',
    width: 'fit-content'
});

export const getAbstractPostFlexStyle = (theme) => ({
    margin: '.5rem .75rem 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
});

export const getAbstractItemDetailFlexStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    flexDirection: 'column'
});

export const getAbstractSingleFieldButtonStyle = (theme) => ({
    marginLeft: '1rem',
});

export const getAbstractSingleFieldStyle = (theme) => ({
    flexGrow: '30',
    margin: '0 0 0 0.5rem',
    width: '100%',
});

export const getAbstractSingleFieldContainerStyle = (theme) => ({
    width: '100%',
});

export const getAbstractTextField = (theme) => ({
    height: '2.25rem',
    width: 'calc(100% - 1.5rem)',
    padding: '0 1rem',
    marginBottom: '.75rem'
});

export const getAbstractSingleTextField = (theme) => ({
    height: '2.25rem',
    width: theme.isTablet() ? '100%' : 'calc(100% - 1.5rem)',
    padding: '0 .75rem',
});

export const getAbstractBottomPaddingSpacerStyle = (theme) => ({
    paddingBottom: '2rem',
});

export const getAbstractTextAreaField = (theme) => ({
    padding: '.5rem .75rem',
    minHeight: '12rem',
    maxWidth: '58.5rem',
    width: theme.isTablet() ? '100%' : 'calc(100% - 1.5rem)',
});

export const getAbstractUserNamePasswordField = (theme) => ({
    height: '2.25rem',
    width: '100%',
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    paddingLeft: '1rem',
    marginBottom: '.75rem'
});

export const getAbstractFileLabelStyle = (theme) => ({
    paddingBottom: '.75rem'
});

export const getAbstractTextError = (theme) => ({
    textAlign: 'center',
    color: theme.warningColor,
    height: 'fit-content',
    fontWeight: '700',
    margin: '0 auto',
    maxWidth: '58rem',
    width: '100%'
});

export const getAbstractSingleTextError = (theme) => ({
    textAlign: 'center',
    color: theme.warningColor,
    height: 'fit-content',
    fontWeight: '700',
    margin: '0 auto',
    maxWidth: '58rem',
    width: '100%'
});

export const getAbstractSmallTextError = (theme) => ({
    textAlign: 'center',
    color: theme.warningColor,
    height: 'fit-content',
    fontWeight: '700',
    paddingLeft: '.75rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: theme.isTablet() ? '100%' : 'calc(100% - 1.5rem)',
    maxWidth: '26rem'
});

export const getAbstractFieldContainerStyle = (theme) => ({

});

export const getAbstractBottomMarginStyle = (theme) => ({
    marginBottom: '2rem',
});

export const getAbstractSpacerScrollbarStyle = (theme) => ({
    margin: '0',
    overflowX: 'hidden'
});

export const getAbstractNoMarginStyle = (theme) => ({
    margin: '0',
    display: theme.isTablet() ?  'block' : 'none'
});

export const getAbstractNoMarginDiaStyle = (theme) => ({
    margin: '0',
});

export const getAbstractTextAreaSmallField = (theme) => ({
    padding: '.5rem',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    minHeight: '12rem',
    width: theme.isTablet() ? '100%' : 'calc(100% - 1.5rem)',
    maxWidth: '26rem',
    marginBottom: '.75rem'
});

export const getAbstractDropField = (theme) => ({
    width: '15rem',
    height: '2.25rem',
    paddingLeft: '.32rem',
    color: '#2A2A2A',
    cursor: 'pointer',
    borderRadius: '.15rem',
    padding: '.4rem 1.2rem',
    transition: 'all 0.2s',
    fontWeight: '500',
    backgroundColor: '#EEEAEA',
    margin: '.5rem .75rem 1rem',
    borderWidth: '0 0 .075rem 0',
    borderColor: '#2A2A2A',
    boxShadow: 'rgba(29, 29, 29, .5) 0 .1rem .1rem'
});

export const getAbstractDropContainerField = (theme) => ({
    marginBottom: '.75rem',    
});

export const getAbstractCheckFieldText = (theme) => ({
    paddingLeft: '.75rem'
});

export const getAbstractCheckFieldContainer = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '.75rem',
});

export const getAbstractNoPaddingTopStyle = (theme) => ({
    paddingTop: '0',
});