import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';
import DeliveryCarrierTypeEnum from './../ui/prompt/core/enum/delivery-carrier-type';

const {
    DELIVERY_CARRIER_TYPE_USPS,
    DELIVERY_CARRIER_TYPE_FEDEX,
    DELIVERY_CARRIER_TYPE_DHL,
    DELIVERY_CARRIER_TYPE_UPS,
} = DeliveryCarrierTypeEnum;


const deliveryShippingCompanyList = [
    DELIVERY_CARRIER_TYPE_USPS,
    DELIVERY_CARRIER_TYPE_FEDEX,
    DELIVERY_CARRIER_TYPE_DHL,
    DELIVERY_CARRIER_TYPE_UPS
];

class DeliveryService 
{
    static isTrackingCarrier(carrierType)
    {
        return deliveryShippingCompanyList.includes(carrierType);
    }
    
    static getShippingCompanies()
    {
        const dropList = DeliveryCarrierTypeEnum.getSentPackageAssignDeliveryCarrierTypeDropDown();
        return deliveryShippingCompanyList.map(id => ({
            id,
            name: dropList.find(i => i.value === id)?.name,
            dropOffUrl: this.getShippingCompanyDropOffUrl(id),
            pickUpUrl: this.getShippingCompanySchedulePickupUrl(id)
        }));
    }

    static getShippingCompanyDropOffUrl(carrierType)
    {
        switch(carrierType)
        {
            case DELIVERY_CARRIER_TYPE_USPS:
                return 'https://tools.usps.com/find-location.htm';

            case DELIVERY_CARRIER_TYPE_FEDEX:
                return 'https://www.fedex.com/en-us/shipping/drop-off-package.html';

            case DELIVERY_CARRIER_TYPE_UPS:
                return 'https://www.ups.com/dropoff/';

            case DELIVERY_CARRIER_TYPE_DHL:
                return 'https://locator.dhl.com/';

            default:
                return null;
        }
    }

    static getShippingCompanySchedulePickupUrl(carrierType)
    {
        switch(carrierType)
        {
            case DELIVERY_CARRIER_TYPE_USPS: 
                return 'https://tools.usps.com/schedule-pickup-steps.htm';

            case DELIVERY_CARRIER_TYPE_FEDEX:
                return 'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html';
            
            case DELIVERY_CARRIER_TYPE_UPS:
                return 'https://wwwapps.ups.com/pickup/schedule';

            case DELIVERY_CARRIER_TYPE_DHL:
                return 'https://mydhl.express.dhl/us/en/schedule-pickup.html';

            default:
                return null;
        }
    }

    static getShippingCompanyTrackingUrl(carrierType, trackingNumber)
    {
        switch(carrierType)
        {
            case DELIVERY_CARRIER_TYPE_USPS: 
                return `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingNumber}`;

            case DELIVERY_CARRIER_TYPE_FEDEX:
                return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}`;
            
            case DELIVERY_CARRIER_TYPE_UPS:
                return `https://www.ups.com/mobile/track?trackingNumber=${trackingNumber}`;

            case DELIVERY_CARRIER_TYPE_DHL:
                return `http://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}&brand=DHL`;

            default:
                return null;
        }
    }
    
    static getAssignShippingCompanyRequest(deliveryId, carrierType, otherCarrierName, carrierTrackingId, acceptedByCarrier)
    {
        return {
            deliveryId,
            carrierType,
            otherCarrierName,
            carrierTrackingId,
            acceptedByCarrier
        };
    }

    /* istanbul ignore next */
    static assignShippingCompany({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.delivery.assign,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getsetAssignedPackageReceivedByCarrierRequest(deliveryId)
    {
        return {
            deliveryId
        };
    }

    /* istanbul ignore next */
    static setAssignedPackageReceivedByCarrier({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.delivery.received,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getAddToCarrierQueueRequest(deliveryId, bidRequestedCarrierGroupIdList)
    {
        return {
            deliveryId,
            bidRequestedCarrierGroupIdList
        };
    }

    /* istanbul ignore next */
    static addToCarrierQueue({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.delivery.network.addToQueue,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getListAvailableCarriersRequest(deliveryId, page)
    {
        return {
            deliveryId,
            page
        };
    }

    /* istanbul ignore next */
    static listAvailableCarriers({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.delivery.network.listAvailable,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default DeliveryService;
