import React from 'react';
import theme from './../../../theme';
import { NavLink } from 'react-router-dom';
import CurrencyService from './../../../../service/currency';
import {
   groupList,
   statList
} from './stat-list';

// istanbul ignore next
const statViewDetails = ({ title, field, details }) => 
    <div>
        <span>
            { 
                !/^api_.*_available/.test(field) ?
                    details[field] : '' 
            }
        </span>
        &nbsp;
        &nbsp;
        <strong>
            { title }
        </strong>
        {
            /^api_.*_available/.test(field) ? 
            <span style={{
                color: details[field] ? 'green' : 'red'
            }}>
                &nbsp;
                {
                    details[field] ? 'Online' : 'Paused'
                }
            </span> : ''
        }
        <hr />
    </div>

const statView = ({ url, title, field, details, index }) => url ? 
    <NavLink 
        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
        to={url} 
        key={index}
    >
        { statViewDetails({ title, field, details }) }
    </NavLink> : 
    <section key={index}>
        { statViewDetails({ title, field, details }) }
    </section>

const GroupView = ({ title, details, fullList }) => 
    <div 
        style={{
        ...theme.getAdminStatsGroupStyle(theme)
    }}>
        <h3>
            { title }
        </h3>
        <div>
            { 
                fullList
                    .filter(item => item.group === title)
                    .map((item, index) => statView({ ...item, details, index })) 
            }
        </div>
    </div>

// istanbul ignore next
const AdminStatsViewPanel = ({ data: details }) =>
    <div style={{
        ...theme.getAdminStatsViewPanelStyle(theme),
    }}>
        <h2>
            System Stats
        </h2>
        
        <div>
            {
                groupList.map((aGroupTitle, index) => 
                    <GroupView title={aGroupTitle} details={details} fullList={statList} key={index} />
                )
            }
        
            <div 
                style={{
                ...theme.getAdminStatsGroupStyle(theme)
            }}>
                <h3>
                    Projections 
                </h3>

                {
                    [
                        {
                            title: 'Subscription Cashflow:',
                            value: CurrencyService.getDisplayPrice('usd', details.estimatedSubIncome, false)
                        },
                        {
                            title: 'Total Potential Users:',
                            value: details.continuedInviteCount > 0 ? 'Unlimited' : ( details.activeUserCount + details.activeInviteCount )
                        }
                    ].map((item, index) => 
                        <div key={index}>
                            <strong>
                                { item.title }
                            </strong>
                            &nbsp;
                            &nbsp;
                            <span>
                                { item.value }
                            </span>
                            <hr />
                        </div>
                    )
                }
            </div>
            <div style={{ clear: 'both' }} />
        </div>
    </div>

export default AdminStatsViewPanel;
