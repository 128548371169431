class UiService {
    
    static fullTextOptions = {
      textSize: {
        min: 12, // min text size ie textSize.min pt
        max: 34, // max text size ie textSize.max pt
      },
      charLength: {
        maxLarge: 6, // the max number of characters displayed at textSize.max ie charLength.maxLarge character words are textSize.max size
        minSmall: 20, // the min number of characters displayed at textSize.min ie charLength.minSmall character words are textSize.min size
      },
      trim: {
        enabled: false, // cut off remaining text if too long
        maxLength: 35, // max number of characters displayed
        trailTrim: ' ...', // text added to end of trimmed string
      }
    };

    static trimTextOptions = {
      textSize: {
        min: 12, // min text size ie textSize.min pt
        max: 34, // max text size ie textSize.max pt
      },
      charLength: {
        maxLarge: 6, // the max number of characters displayed at textSize.max ie charLength.maxLarge character words are textSize.max size
        minSmall: 20, // the min number of characters displayed at textSize.min ie charLength.minSmall character words are textSize.min size
      },
      trim: {
        enabled: true, // cut off remaining text if too long
        maxLength: 35, // max number of characters displayed
        trailTrim: ' ...', // text added to end of trimmed string
      }
    };

    static isValidGetTextOptions(options)
    {
      if(!options.textSize)
      {
        throw new Error('Missing options.textSize');
      }

      if(!options.textSize.min)
      {
        throw new Error('Missing options.textSize.min');
      }

      if(!options.textSize.max)
      {
        throw new Error('Missing options.textSize.max');
      }

      if(!options.charLength)
      {
        throw new Error('Missing options.charLength');
      }

      if(!options.charLength.maxLarge)
      {
        throw new Error('Missing options.charLength.maxLarge');
      }

      if(!options.trim)
      {
        throw new Error('Missing options.trim');
      }

      if(options.trim.enabled === undefined)
      {
        throw new Error('Missing options.trim.enabled');
      }

      if(!options.trim.maxLength)
      {
        throw new Error('Missing options.trim.maxLength');
      }

    }

    /* istanbul ignore next */
    static getTextSize(textLength, options)
    {
      if(textLength <= options.charLength.maxLarge)
      {
        return options.textSize.max;
      }

      if(textLength >= options.charLength.minSmall)
      {
        return options.textSize.min;
      }
      
      const sizeDiff = options.textSize.max - options.textSize.min;
      if(sizeDiff < 0)
      {
        throw new Error('Invalid sizeDiff: options.textSize.max or options.textSize.min');
      }

      const charLengthDiff = options.charLength.minSmall - options.charLength.maxLarge;
      if(charLengthDiff <= 0)
      {
        throw new Error('Invalid charLengthDiff: options.charLength.minSmall or options.charLength.maxLarge');
      }

      const sizePerCharLength = Math.round(sizeDiff / charLengthDiff);
      const increaseLength = textLength - options.charLength.maxLarge;
      const textSizeChange = increaseLength * sizePerCharLength;
      const rawOutput = options.textSize.max - textSizeChange;
      return rawOutput < options.textSize.min ? options.textSize.min : rawOutput;
    }

    static getFormattedText(rawText, textLength, options)
    {
      return !options.trim.enabled ? rawText : (
        options.trim.maxLength >= textLength ? 
          rawText : rawText.substring(0, options.trim.maxLength - 1) + options.trim.trailTrim
      );
    }

    // UiService.getText(chat.message, UiService.fullTextOptions)
    // UiService.getText(chat.message, UiService.trimTextOptions)
    static getText(rawText, options)
    {
      this.isValidGetTextOptions(options);
      const textLength = rawText.length;
      const size = this.getTextSize(textLength, options);
      const formattedText = this.getFormattedText(rawText, textLength, options);
      return (
        <div>
          <span style={{
            fontSize: `${size}pt`
          }}>
            { formattedText }
          </span>
        </div>
      );
    }

    static getLocale()
    {
        return 'en-US';
    }
    
    static getDateFormat()
    {
        // full, long, medium, short, undefined
        return 'short';
    }

    static getTimeFormat()
    {
        return 'short';
    }

    static getWeight(weight) 
    {
      const lb = weight.pounds !== '0' ? `${weight.pounds} lb` : '';
      const oz = weight.ounces !== '0' ? `${weight.ounces} oz` : '';
      return lb === '' && oz === '' ? 'No weight' : 
        `${lb} ${oz}`.trim()
    }
    
    static getDateReport(dt)
    {
      return new Intl.DateTimeFormat(
        this.getLocale(), { 
            dateStyle: 'short', 
        }).format(new Date(dt));
    }

    static getStoryDate(dt)
    {
      return new Intl.DateTimeFormat(
        this.getLocale(), { 
            dateStyle: 'full', 
            timeStyle: this.getTimeFormat()
        }).format(new Date(dt));
    }

    static date(dt)
    {
        return new Intl.DateTimeFormat(
            this.getLocale(), { 
                dateStyle: this.getDateFormat(), 
                timeStyle: this.getTimeFormat()
            }).format(new Date(dt));
    }

    static secToHumanTime(seconds, postFixCopy = 'ago', justNowCopy = 'just now')
    {
      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + ( Math.floor(interval) > 1 ? ` years ${postFixCopy}` : ` year ${postFixCopy}`);
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + ( Math.floor(interval) > 1 ? ` months ${postFixCopy}` : ` month ${postFixCopy}`);
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + ( Math.floor(interval) > 1 ? ` days ${postFixCopy}` : ` day ${postFixCopy}`);
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + ( Math.floor(interval) > 1 ? ` hours ${postFixCopy}` : ` hour ${postFixCopy}`);
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + ( Math.floor(interval) > 1 ? ` minutes ${postFixCopy}` : ` minute ${postFixCopy}`);
      }

      if(Math.floor(seconds) > 0)
      {
          return Math.floor(seconds) + ( Math.floor(seconds) > 1 ? ` seconds ${postFixCopy}` : ` second ${postFixCopy}`);
      }

      return justNowCopy;
    }

    static timeSince(dt, postFixCopy = 'ago')
    {
        var seconds = Math.floor((new Date() - new Date(dt)) / 1000);
        return this.secToHumanTime(seconds, postFixCopy);
    }

    static scrollToTop()
    {
      window.scrollTo({ top: 0 });
    }
}

export default UiService;
