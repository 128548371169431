import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import ExchangeSearchActivePrompt from './../ui/prompt/exchange-search-active';
import theme from './../ui/theme';
import { useParams } from 'react-router-dom';
import ExchangesMenu from './../ui/menu/exchanges';

const ActiveExchangesPage = () => {
    const { page, searchText } = useParams();
    /* istanbul ignore next */
    const passPage = page ? parseInt(page) : 0;
    /* istanbul ignore next */
    const passSearchText = searchText ? searchText : '';

    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate childMenu={ <ExchangesMenu /> }>
            <div style={containerStyle}>
                <h2>
                    Active Exchanges
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Search for your active transactions. While an exchange is still active, a reference to the transaction will be found here.
                </h4>
            </div>
            
            <ExchangeSearchActivePrompt page={passPage} searchText={passSearchText} />
        </AuthPageTemplate>
    );
};
export default ActiveExchangesPage;
