import React from 'react';
import { useEffect, useState, useContext } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';
import MenuItem from './menu-item';
import LogoutIcon from './../../../img/menu-logout-white.svg';
import ToggleFullMenu from './toggle-full-menu';
import NoticesMenu from './notices-menu';
import isLoggedIn from './../../../store/select/is-logged-in';
import { useSelector } from 'react-redux';
import { ShowMenuContext } from './menu-container';

const SpecialMainMenuItems = ({ 
    onLogout, 
}) => {
    const loggedIn = useSelector(isLoggedIn);

    return (
        <div style={{
            ...theme.getMenuListRightStyle(theme),
        }}>
            {
                loggedIn && 
                <div 
                    className="test-menuListLogout"
                    title="Logout"
                    onClick={onLogout}
                    style={{
                        ...theme.getMenuRightStyle(theme, LogoutIcon),
                }}></div>
            }

            <ToggleFullMenu />

            {
                loggedIn && 
                <NoticesMenu />
            }
            
        </div>
    );
};

const MenuList = ({ 
    list, 
    onLogout, 
    isMainMenu = false
}) => {
    
    const showMenu = useContext(ShowMenuContext);
    
    /* istanbul ignore next */
    const getMenuDockStyle = () => isMainMenu ? (
        showMenu ? theme.getMainMenuDockStyle(theme) :
            theme.getMainMenuDockHiddenStyle(theme)
    ) : (
        showMenu ? theme.getMenuDockStyle(theme) :
            theme.getMenuDockHiddenStyle(theme)
    );
    
    const [ menuDockStyle, setMenuDockStyle ] = useState( getMenuDockStyle() );

    const getMenuListStyle = () => isMainMenu ? theme.getMainMenuListStyle(theme) : theme.getMenuListStyle(theme);
    const [ menuListStyle, setMenuListStyle ] = useState ( getMenuListStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setMenuDockStyle( getMenuDockStyle() );
        setMenuListStyle( getMenuListStyle() );
    });

    useEffect(resizeEffect);

    useEffect(() => {
        setMenuDockStyle( getMenuDockStyle() );
        // eslint-disable-next-line
    }, [showMenu, isMainMenu]);

    return (
        <div style={menuDockStyle}>
            <div style={menuListStyle}>
                { list.map((item, index) => <MenuItem key={index} isMainMenu={isMainMenu} to={item.to} title={item.title} customStyle={item.customStyle} />) }
            </div>
            {
                isMainMenu && 
                    <SpecialMainMenuItems 
                        key="special"
                        onLogout={onLogout} 
                    />
                    
            }

            <div style={{clear: 'both'}}> </div>

        </div>
    );
};

export default MenuList;
