import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class CarrierGroupTripService 
{
    static getListUserTripsRequest(carrierGroupId, page)
    {
        return {
            carrierGroupId,
            page
        };
    }

    /* istanbul ignore next */
    static listUserTrips({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.trip.userList,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getCreateTripRequest(carrierGroupId, from, to, estimatedStart, estimatedEnd, acceptingMoreCargo)
    {
        return {
            carrierGroupId,
            from,
            to,
            estimatedStart,
            estimatedEnd,
            acceptingMoreCargo,
            page: 0
        };
    }

    /* istanbul ignore next */
    static createTrip({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.trip.create,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddStopRequest(
        carrierGroupId,
        deliveryId,
        location,
        sortUpdateStopIdList,
        tripId,
        cargoItemId,
        stepId,
        isPickup,
        sortOrder
    )
    {
        return {
            carrierGroupId,
            deliveryId,
            sortUpdateStopIdList,
            addStop: {
                tripId,
                location,
                cargoItemId,
                stepId,
                isPickup,
                sortOrder
            }
        };
        
    }

    /* istanbul ignore next */
    static addStop({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.trip.stop.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveCargoRequest(carrierGroupId, deliveryId, tripId)
    {
        return {
            carrierGroupId,
            deliveryId,
            tripId
        };
    }

    /* istanbul ignore next */
    static removeCargo({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.trip.cargo.remove,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default CarrierGroupTripService;
