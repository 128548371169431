import React from 'react';
import theme from './../theme';
import imgLogoColor from './../../img/logo_color.png';
import imgLogo from './../../img/logo_bw.svg';

const LogoPanel = ({ isColor = false }) => {
    const logo = isColor ? imgLogoColor : imgLogo;
    return (
        <div style={{
            ...theme.getLogoPanelStyle(theme, logo),
        }}></div>
    );
};

export default LogoPanel;
