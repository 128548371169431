import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class UserActionService
{
    static getAdminListRequest(targetUserId)
    {
        return {
            targetUserId
        };
    }

    /* istanbul ignore next */
    static adminList({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.userActions.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminAddRequest(targetUserId, context, type, allowAccess, notes)
    {
        return {
            targetUserId,
            context,
            type,
            allowAccess,
            notes
        };
    }

    /* istanbul ignore next */
    static adminAdd({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.userActions.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminUpdateRequest(targetUserId, context, type, allowAccess, notes)
    {
        return {
            targetUserId,
            type,
            allowAccess,
            notes
        };
    }

    /* istanbul ignore next */
    static adminUpdate({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.userActions.update,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminDeleteRequest(targetUserId, context, type)
    {
        return {
            targetUserId,
            type
        };
    }

    /* istanbul ignore next */    
    static adminDelete({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.userActions.delete,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default UserActionService;
