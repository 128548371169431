import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const FieldList = ({ attSet }) => 
    <div>
        {
            attSet.attributeList.map((att, index) => 
                <div key={index}>
                    { att.name } : { att.value }
                </div>
            )
        }
    </div>

export const SearchResultsViewPrompt = ({
    children
}) => {
    const getOfferSearchResultStyle = () => theme.getFieldsSearchResultStyle(theme);
    const [ offerSearchResultStyle, setOfferSearchResultStyle ] = useState(getOfferSearchResultStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferSearchResultStyle(getOfferSearchResultStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={{
                ...theme.getOfferSearchResultCenterStyle(theme),
            }}>
            <section style={offerSearchResultStyle}>
                { children }
            </section>
        </div>
    );
};

export const SingleFieldContainer = ({ children }) => {
    const getConStyle = () => theme.getOfferSearchSingleFileContainerFileStyle(theme);
    const [ conStyle, setConStyle ] = useState( getConStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setConStyle( getConStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={conStyle}>
            { children }
        </div>
    );
};

const AttSetSearchViewPrompt = ({ attSet, index }) => {
    const getComposedStyle = () => ({
        ...theme.getAbstractWordWrapStyle(theme),
        ...theme.getAttSetListContainerStyle(theme)
    });
    const getBidDetailStyle = () => theme.getBidDetailsStyle(theme);

    const [ composedStyle, setComposedStyle ] = useState( getComposedStyle() );
    const [ bidDetailStyle, setBidDetailStyle ] = useState( getBidDetailStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setComposedStyle( getComposedStyle() );
        setBidDetailStyle( getBidDetailStyle() );
    });
    useEffect(resizeEffect);
    return (
        <NavLink key={index} to={`/profile/att-set/${attSet.id}`}>
            <section style={bidDetailStyle}>
                <div style={composedStyle}>
                    <h3>
                        { attSet.title }
                    </h3>
                    <p style={{
                        ...theme.getAttSetListCountStyle(theme),  
                    }}>
                        { attSet.attributeList.length } { attSet.attributeList.length > 1 ? 'Fields' : 'Field' }
                    </p>
                    <FieldList attSet={attSet} />
                </div>
            </section>
        </NavLink>
    );
};


export default AttSetSearchViewPrompt;
