import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class CarrierLocationService 
{
    static getListRequest(carrierGroupId, page)
    {
        return {
            carrierGroupId,
            page
        };
    }

    /* istanbul ignore next */
    static list({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.locations.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddRequest(userLocationId, carrierGroupId, maxServiceDistanceMiles, isPublicDropOff, isDeliveryHub)
    {
        return {
            userLocationId,
            carrierGroupId,
            maxServiceDistanceMiles,
            isPublicDropOff,
            isDeliveryHub
        };
    }

    /* istanbul ignore next */
    static add({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.locations.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveRequest(carrierGroupId, carrierLocationId)
    {
        return {
            carrierGroupId,
            carrierLocationId
        };
    }

    /* istanbul ignore next */
    static remove({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.locations.remove,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default CarrierLocationService;
