export const OBTAIN_TYPE_SHIP_TO_ADDRESS = 'ship-to-address';
export const OBTAIN_TYPE_HAND_TO_HAND = 'hand-to-hand-delivery';
export const OBTAIN_TYPE_PICK_UP = 'pick-up';
export const OBTAIN_TYPE_REMOTE = 'remote';
export const OBTAIN_TYPE_ON_SITE = 'onsite';

const validValues = [
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_PICK_UP,
    OBTAIN_TYPE_REMOTE,
    OBTAIN_TYPE_ON_SITE,
];

const getDropDownValues = () => [
    {
        name: 'Shipping Company',
        value: OBTAIN_TYPE_SHIP_TO_ADDRESS
    },
    {
        name: 'Hand Delivery',
        value: OBTAIN_TYPE_HAND_TO_HAND
    },
    {
        name: 'Pick Up',
        value: OBTAIN_TYPE_PICK_UP
    },
    {
        name: 'Remote',
        value: OBTAIN_TYPE_REMOTE
    },
    {
        name: 'On Site',
        value: OBTAIN_TYPE_ON_SITE
    }
];

const isValid = value => validValues.includes(value);

const ObtainTypeEnum = {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_PICK_UP,
    OBTAIN_TYPE_REMOTE,
    OBTAIN_TYPE_ON_SITE,
    isValid,
    getDropDownValues,
};

export default ObtainTypeEnum;
