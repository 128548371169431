export const BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE = 'low-trans-fee';

const validValues = [
    BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE
];

const getDropDownValues = () => [
    {
        name: 'Low transaction fee',
        value: BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE
    }
];

const isValid = value => validValues.includes(value);

const BxSubscriptionAccessEnum = {
    BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE,
    getDropDownValues,
    isValid
};

export default BxSubscriptionAccessEnum;
