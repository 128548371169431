import React from 'react';
import DetailPanel from './../core/detail';
import ExchangeService from './../../../service/exchange';
import UrlService from './../../../service/url';
import DeliveryService from './../../../service/delivery';
import UiService from './../../../service/ui';
import ExchangeDetailViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from'./../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import UnableToLoadTargetPanel from './../core/unable-to-load-target';
import config from './../../../config';

export class _ExchangeDetailPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getSteps = this.getSteps.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.getCurrentStep = this.getCurrentStep.bind(this);
        this.onPaid = this.onPaid.bind(this);
        this.onPaymentConfirmed = this.onPaymentConfirmed.bind(this);
        this.onFulfilled = this.onFulfilled.bind(this);
        this.onExchangeConfirmed = this.onExchangeConfirmed.bind(this);
        this.onUpdateExchangeState = this.onUpdateExchangeState.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.onReviewed = this.onReviewed.bind(this);
        this.onFlagged = this.onFlagged.bind(this);
        this.onApproveEarlyPayout = this.onApproveEarlyPayout.bind(this);
        this.onRequestRefund = this.onRequestRefund.bind(this);
        this.onRefundReviewed = this.onRefundReviewed.bind(this);
        this.onDisputeResponded = this.onDisputeResponded.bind(this);
        this.onAcceptDisputeLoss = this.onAcceptDisputeLoss.bind(this);
        this.onProvidedToShipping = this.onProvidedToShipping.bind(this);

        this.state = this.getDefaultState();
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        const steps = this.getSteps()
        return {
            ...baseState,
            currentStep: 1,
            steps,
            unableToLoadTarget: false,
        };
    }

    getCurrentStep(exchange)
    {
        const AwaitingPayment = 1;
        const ConfirmingPayment = 2;
        const AwaitingExchange = 3;
        const AwaitingExchangeConfirmation = 4;
        const AwaitingReview = 5;
        const ExchangeCompleted = 6;
        return exchange.reviewed ? ExchangeCompleted : (
            exchange.fulfilledConfirmed ? AwaitingReview : (
                exchange.fulfilled ? AwaitingExchangeConfirmation : (
                    exchange.paidConfirmed ? AwaitingExchange : (
                        exchange.paid ? ConfirmingPayment : 
                        AwaitingPayment
                    )
                )
            )
        );
    }

    getSteps(exchange)
    {
        return [
            exchange && exchange.paid ?
`Payment 
Sent` : 
`Send
Payment`,
            exchange && exchange.paidConfirmed ?
`Payment 
Confirmed` : 
`Confirm
Payment`,
            exchange && exchange.fulfilled ?
`Exchange 
Started` :
`Start
Exchange`,
            exchange && exchange.fulfilledConfirmed ?
`Exchange 
Confirmed` :
`Confirm
Exchange`,
            exchange && exchange.reviewed ?
'Reviewed' :
'Review',
        ];
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        ExchangeService.getDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onDisputeResponded(response)
    {
        const exchangeId = this.props.exchangeId;
        this.loadData({
            exchangeId,
        });
    }

    /* istanbul ignore next */
    onApproveEarlyPayout(exchange)
    {
        super.loadData({ });
        ExchangeService.approveEarlyPayout({
            request: {
              exchangeId: exchange.id
            },
            onResponse: response => {
                const exchangeId = this.props.exchangeId;
                this.loadData({
                    exchangeId,
                });
            },
           onError: this.onLoadDataError,
           authToken: this.props.authToken,
           onAuthError: this.onAuthError,
           onFatalError: this.onFatalError,
           onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onAcceptDisputeLoss()
    {
        super.loadData({ });
        const exchangeId = this.props.exchangeId;
        ExchangeService.acceptDisputeLoss({
            request: {
                exchangeId
            },
            onResponse: response => {
                const exchangeId = this.props.exchangeId;
                this.loadData({
                    exchangeId,
                });
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadDataResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: response, 
            currentStep: this.getCurrentStep(response),
            steps: this.getSteps(response),
        };
        this.setState(nextState);
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load exchange')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    getLoadDataRequest(inputObj)
    {
        return {
            exchangeId: inputObj.exchangeId,
        };
    }

    componentDidMount()
    {
        const exchangeId = this.props.exchangeId;
        
        const tester = /payment_intent/i;
        const redirectUrl = `${config.pay.paymentConfirmedUrl}${exchangeId}`;
        UrlService.correctUrl(tester, redirectUrl);

        this.loadData({
            exchangeId,
        });
        UiService.scrollToTop();
    }

    /* istanbul ignore next */
    onFlagged()
    {
        const exchangeId = this.props.exchangeId;
        this.loadData({
            exchangeId,
        });
    }

    /* istanbul ignore next */
    onRequestRefund()
    {
        const exchangeId = this.props.exchangeId;
        super.loadData({ });
        ExchangeService.requestRefund({
            request: {
                exchangeId
            },
            onResponse: response => {
                const exchangeId = this.props.exchangeId;
                this.loadData({
                    exchangeId,
                });
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError, 
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onRefundReviewed(approved)
    {
        const exchangeId = this.props.exchangeId;
        super.loadData({ });
        ExchangeService.reviewRefund({
            request: {
                exchangeId,
                approved
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError, 
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onUpdateExchangeState(updateDetails)
    {
        super.loadData({ });
        ExchangeService.updateState({
            request: {
                exchangeId: this.props.exchangeId,
                ...updateDetails,
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
        UiService.scrollToTop();
    }

    onPaid()
    {
        this.onUpdateExchangeState({
            paid: true
        });
    }

    onPaymentConfirmed()
    {
        this.onUpdateExchangeState({
            paidConfirmed: true
        });
    }

    onFulfilled()
    {
        this.onUpdateExchangeState({
            fulfilled: true
        });
    }

    onExchangeConfirmed()
    {
        this.onUpdateExchangeState({
            fulfilledConfirmed: true
        });
    }

    /* istanbul ignore next */
    onReviewed()
    {
        this.onUpdateExchangeState({
            reviewed: true
        });
    }
    
    getUnLoadedDataDetailUi()
    {
        return this.state.unableToLoadTarget ? <UnableToLoadTargetPanel title="Exchange" /> : super.getUnLoadedDataDetailUi();
    }

    /* istanbul ignore next */
    onProvidedToShipping(delivery)
    {
        super.loadData({ });
        DeliveryService.setAssignedPackageReceivedByCarrier({
            request: {
                deliveryId: delivery.id
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
        UiService.scrollToTop();
    }

    getLoadedDataDetailUi()
    {
        return <ExchangeDetailViewPanel 
            data={this.state.data} 
            steps={this.state.steps}
            onDisputeResponded={this.onDisputeResponded}
            onApproveEarlyPayout={this.onApproveEarlyPayout}
            currentStep={this.state.currentStep}
            onPaid={this.onPaid}
            onPaymentConfirmed={this.onPaymentConfirmed}
            onFulfilled={this.onFulfilled}
            onExchangeConfirmed={this.onExchangeConfirmed}
            onFlagged={this.onFlagged} 
            onRequestRefund={this.onRequestRefund}
            onRefundReviewed={this.onRefundReviewed}
            onAcceptDisputeLoss={this.onAcceptDisputeLoss}
            onReviewed={this.onReviewed}
            awaitingExchangeStep={3}
            onProvidedToShipping={this.onProvidedToShipping}
            onLoadDataResponse={this.onLoadDataResponse}
        />
    }
}

const ExchangeDetailPanel = connect(mapStateToProps, mapDispatchToProps)(_ExchangeDetailPanel);

export default ExchangeDetailPanel;
