import React from 'react';
import theme from './../../theme';

const incompleteIcons = [
    theme.getTrackStepsEmptyOne(theme),
    theme.getTrackStepsEmptyTwo(theme),
    theme.getTrackStepsEmptyThree(theme),
    theme.getTrackStepsEmptyFour(theme),
    theme.getTrackStepsEmptyFive(theme),
    theme.getTrackStepsEmptySix(theme),
    theme.getTrackStepsEmptySeven(theme),
    theme.getTrackStepsEmptyEight(theme),
    theme.getTrackStepsEmptyNine(theme),
];

const completedIcons = [
    theme.getTrackStepsFullOne(theme),
    theme.getTrackStepsFullTwo(theme),
    theme.getTrackStepsFullThree(theme),
    theme.getTrackStepsFullFour(theme),
    theme.getTrackStepsFullFive(theme),
    theme.getTrackStepsFullSix(theme),
    theme.getTrackStepsFullSeven(theme),
    theme.getTrackStepsFullEight(theme),
    theme.getTrackStepsFullNine(theme),
];

const StepTile = ({ step, index, currentStep }) => {
    const isChecked = index < (currentStep - 1);
    const isCompleted = index === ( currentStep - 1 );

    return (
        <div style={{
            ...theme.getTrackStepTileStyle(theme),
        }}>
            <div
                style={{  
                    ...theme.getStepTileTextStyle(theme),
            }}>
                { 
                    isChecked ? theme.getTrackStepsChecked(theme) : (
                        isCompleted ? completedIcons[index] : incompleteIcons[index]
                    )
                }
            </div>
            <div
                style={{
                    ...theme.getSmallTextStyle(theme),
                    ...theme.getTrackStepTextStyle(theme),
                    ...(isCompleted ? theme.getCompletedTrackStepTextStyle(theme) : {})
            }}>
                { step }
            </div>
        </div>
    );
};

const TrackStepsPanel = ({ list, currentStep }) => {
    return (
        <div style={{
            ...theme.getTrackStepsPanelStyle(theme)
        }}>
            {
                list.map((aStep, index) => <StepTile step={aStep} currentStep={currentStep} index={index} key={index} /> )
            }
        
        </div>
    );
}
export default TrackStepsPanel;
