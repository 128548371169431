import React from 'react';
import { useEffect, useState } from 'react';
import theme from './../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateMenuState } from './../../../store/state/action';
import menuDetails from './../../../store/select/menu-details';
import CollapseIcon from './../../../img/menu-collapse-white.svg';
import ResizeService from './../../../service/resize';

const ToggleFullMenu = ({ neverHide = false }) => {

    const {
        showFullMenu
    } = useSelector(menuDetails);

    const dispatch = useDispatch();
    const setShowFullMenu = (showFull) => {
        dispatch(UpdateMenuState(showFull));
    };

    const getMenuStyle = () => theme.getMenuCollapseStyle(theme, CollapseIcon, neverHide);
    const [ menuCollapseStyle, setMenuCollapseStyle ] = useState(getMenuStyle());

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setMenuCollapseStyle( getMenuStyle() );
    });

    useEffect(resizeEffect);

    return (
        <div 
            className="test-toggleFullMenuOpenClose"
            onClick={event => { setShowFullMenu(!showFullMenu) }}
            title={ showFullMenu ? "Open Menu" : "Close Menu"}
            style={menuCollapseStyle}
        >
        </div> 
    );
}

export default ToggleFullMenu;
