import React from 'react';
import DetailPanel from './../core/detail';
import CarrierMemberService from './../../../service/carrier-member';
import CarrierMembersListViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import EventBusService, { EVENT_CARRIER_MEMBERSHIP_GRANTED } from './../../../service/event-bus';

class _CarrierMembersListPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.onMemberUpdated = this.onMemberUpdated.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierMemberService.listMembers({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        const carrierGroupId = this.props.carrierGroupId;
        CarrierMemberService.listMembers({
            request: {
                carrierGroupId,
                page
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onMemberUpdated(response)
    {
        this.onLoadDataResponse(response);
    }

    getLoadDataRequest(inputObj)
    {
        return {
            carrierGroupId: inputObj.carrierGroupId,
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.refreshData();
        EventBusService.on(EVENT_CARRIER_MEMBERSHIP_GRANTED, this.refreshData);
    }

    refreshData()
    {
        const carrierGroupId = this.props.carrierGroupId;
        this.loadData({
            carrierGroupId,
            page: 0
        });
    }

    /* istanbul ignore next */
    componentWillUnmount() 
    {
        EventBusService.remove(EVENT_CARRIER_MEMBERSHIP_GRANTED, this.refreshData);
    }

    getLoadedDataDetailUi()
    {
        return <CarrierMembersListViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            onMemberUpdated={this.onMemberUpdated}
        />
    }
}

const CarrierMembersListPanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierMembersListPanel);
export default CarrierMembersListPanel;
