import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

const ChangePwdPromptContainer = ({ children }) => {
    const getPanelStyle = () => theme.getProfilePwdPanelStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPanelStyle( getPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                <h2>
                    Update password
                </h2>
            </div>
            <div style={panelStyle}>
                <h4 style={{
                    ...theme.getGeneralTextStyle(theme),
                    ...theme.getGeneralBottomSpacerStyle(theme),
                }}>
                    Change your account password
                </h4>
                { children }
            </div>
        </section>
    );
};

export default ChangePwdPromptContainer;
