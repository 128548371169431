export const getPaginationButtonStyle = (theme) => ({
    cursor: 'pointer',
    textDecorationLine: 'underline',
    marginRight: '5px',
    marginLeft: '5px',
});

export const getPaginationButtonContainerStyle = (theme) => ({
    textAlign: 'right',
    margin: '0 auto',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
});
