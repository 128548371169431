import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink }  from 'react-router-dom';
import ResizeService from './../../../service/resize';
import UiService from './../../../service/ui';
import theme from './../../theme';

const UserSeekViewPanel = (seek, index) => 
    <div style={{
            ...theme.getUserSeeksPanelItemStyle(theme),
        }}
        key={index}
    >
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/seek/${seek.id}`}
        >
            <h3>
                { seek.title }
            </h3>
            <p
                style={{
                    ...theme.getUserSeeksPanelTimeStyle(theme),
                }}>
                { UiService.timeSince(seek.createdOn) }
            </p> 
        </NavLink>
    </div>

const UserSeeksViewPanel = ({ data }) => {
    const getUserSeeksPanelStyle = () => theme.getUserSeeksPanelStyle(theme);
    const [ userSeeksPanelStyle, setUserSeeksPanelStyle ] = useState( getUserSeeksPanelStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserSeeksPanelStyle( getUserSeeksPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            { 
                data.list.length < 1 ? '' : 
                <div style={userSeeksPanelStyle}>
                    <h3
                        style={{
                            ...theme.getGeneralTextStyle(theme),
                    }}>
                        Seeks
                    </h3>
                    <section>
                        { data.list.map((aSeek, index) => UserSeekViewPanel(aSeek, index)) }
                    </section>
                </div> 
            }
        </section>
    );
};

export default UserSeeksViewPanel;
