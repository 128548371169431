const RefundPolicyPanel = ({data}) => 
    <div style={{ textAlign: 'center' }}>
        <h4>
            Refund Policy
        </h4>
        <p>
            {
                data.hasRefundPolicy ? (
                    data.refundPolicyDetails === '' ? 'Has Refund Policy' : data.refundPolicyDetails
                ) : 'No Refunds'
            }
        </p>

    </div>

export default RefundPolicyPanel;
