import React from 'react';
import DetailPanel from './../core/detail';
import OfferService from './../../../service/offer';
import BidService from './../../../service/bid';
import OfferArchivedDetailViewPanel from './view';
import UnableToLoadTargetPanel from './../core/unable-to-load-target';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';

class _OfferArchivedDetailPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);

        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            bids: [],
            unableToLoadTarget: false,
        };
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load offer details')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        OfferService.getRetiredOfferDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        }); 
    }

    onLoadSpecifiedPage(page)
    {
        /* istanbul ignore next */
        BidService.listBidsByOffer({
            request: {
                offerId: this.props.offerId,
                page,
            },
            onResponse: this.onBidPageResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onBidPageResponse(response)
    {
        this.setBids(response.bids);
    }

    getLoadDataRequest(inputObj)
    {
        return {
            offerId: inputObj.offerId
        };
    }

    componentDidMount()
    {
        const offerId = this.props.offerId;
        this.loadData({ offerId });
    }

    getUnLoadedDataDetailUi()
    {
        return this.state.unableToLoadTarget ? <UnableToLoadTargetPanel title="Offer" /> : super.getUnLoadedDataDetailUi();
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                <OfferArchivedDetailViewPanel data={this.state.data} onLoadSpecifiedPage={this.onLoadSpecifiedPage} />
            </section>
        );
    }
}

const OfferArchivedDetailPanel = connect(mapStateToProps, mapDispatchToProps)(_OfferArchivedDetailPanel);

export default OfferArchivedDetailPanel;
