import React from 'react';
import theme from './../../theme';
import { 
    hasOtherPlatforms,
    hasContactDetails
} from './util';
import { PlatformsViewPanel } from './../user-detail/view';

const OtherMethodsOfContactPanel = ({
    exchange
}) => 
    <div>
        {
            (hasOtherPlatforms(exchange) || hasContactDetails(exchange)) && 
                <div>
                    <h3>
                        Other methods of contact
                    </h3>
                    {
                        exchange.usersDetails.filter(aUser => 
                            aUser.trailList.length > 0 || 
                                aUser.hasContactDetails
                        ).map(aUser => 
                            <div key={`contact-user${aUser.username}`}>
                                <h4>
                                    { aUser.username }
                                </h4>
                                {
                                    aUser.hasContactDetails &&
                                        <p style={{
                                            ...theme.getExchangeOtherContactStyle(theme)
                                        }}>
                                            {
                                                aUser.contactDetails
                                            }
                                        </p>
                                }
                                <PlatformsViewPanel trailList={aUser.trailList} />
                            </div>
                        )
                    }
                </div>
        }
    </div>


export default OtherMethodsOfContactPanel;
