import { NavLink } from 'react-router-dom';
import UiService from './../../../../service/ui';
import theme from './../../../theme';

export const Header = () => 
    <thead>
        <tr>
            <td>
                IP Address
            </td>
            <td>
                Associated User
            </td>
            <td>
                Associated Record
            </td>
            <td>
                Event
            </td>
            <td>
                Recorded
            </td>
        </tr>
    </thead>

export const SearchIpViewPrompt = ({ ipRecord }) => 
    <tr>
        <td>
            { ipRecord.payload.ip }
        </td>
        <td>
            {
                ipRecord.payload.userId ? 
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to={`/user/${ipRecord.payload.userId}`}
                    >
                        User Profile
                    </NavLink> : 'Guest'
            }
        </td>
        <td>
            {
                ipRecord.payload.exchangeId ? 
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to={`/exchange/${ipRecord.payload.exchangeId}`}
                    >
                        Exchange Details
                    </NavLink> : (
                        ipRecord.payload.targetIdParm === 'offerId' ? 
                        <NavLink 
                            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                            to={`/offer/${ipRecord.payload.targetId}`}
                        >
                            Offer Details
                        </NavLink> : (
                            ipRecord.payload.targetIdParm === 'seekId' ? 
                                <NavLink 
                                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                    to={`/seek/${ipRecord.payload.targetId}`}
                                >
                                    Seek Details
                                </NavLink> : (
                                    // istanbul ignore next 
                                    ipRecord.payload.appIssueId ? 
                                        <NavLink 
                                            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                            to={`/admin/reported/issue/${ipRecord.payload.appIssueId}`}
                                        >
                                            Reported Issue
                                        </NavLink> : 
                                    'N/A'
                                )
                        )
                    )
            }
        </td>
        <td>
            { ipRecord.payload.event }
        </td>
        <td>
            { UiService.getStoryDate(ipRecord.createdOn) }
        </td>
    </tr>

export default SearchIpViewPrompt;
