import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

const ENCRYPT_V1_DATA = 'encryptV1Data';
const CONVERT_DATA_TO_V2 = 'convertDataToV2';

class MaintanceTaskService 
{
    static getRunRequest(requestName)
    {
        return {
            requestName
        };
    }

    static getRequestNameDropDownValues()
    {
        return [
            {
                name: 'Encrypt Version 1 Data', 
                value: ENCRYPT_V1_DATA
            },
            {
                name: 'Convert Data to Version 2 - ONLY RUN THIS ONCE DURING INITIAL V2 UPGRADE',
                value: CONVERT_DATA_TO_V2
            }
        ];
    }

    static getDefaultRequestNameValue()
    {
        return ENCRYPT_V1_DATA;
    }

    static isValidRequestName(requestName)
    {
        return [
            ENCRYPT_V1_DATA,
            CONVERT_DATA_TO_V2
        ].includes(requestName);
    }

    /* istanbul ignore next */
    static run({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.runMaintanceTask,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default MaintanceTaskService;