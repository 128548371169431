import React, { useState } from 'react';
import { connect } from 'react-redux';
import AuthPageTemplate from './template/auth-page';
import mapStateToProps from './../store/map/is-user';
import UserLandingPanel from './../ui/panel/user-landing';
import { UserLandingViewPanelMenu } from './../ui/panel/user-landing/view';

const Landing = () => {
    const [ displayAvailableWidgetsPrompt, setDisplayAvailableWidgetsPrompt ] = useState(false);
    const [ displaySortWidgets, setDisplaySortWidgets ] = useState(false);

    return (
        <AuthPageTemplate 
            childMenu={
                <UserLandingViewPanelMenu 
                    displayAvailableWidgetsPrompt={displayAvailableWidgetsPrompt}
                    setDisplayAvailableWidgetsPrompt={setDisplayAvailableWidgetsPrompt}
                    displaySortWidgets={displaySortWidgets}
                    setDisplaySortWidgets={setDisplaySortWidgets}
                />
        }>
            <UserLandingPanel 
                displayAvailableWidgetsPrompt={displayAvailableWidgetsPrompt}  
                displaySortWidgets={displaySortWidgets}
            />
        </AuthPageTemplate>
    );
};

const LandingPage = connect(mapStateToProps)(Landing);
export default LandingPage;
