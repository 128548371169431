import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';

class WidgetService {
    
    static getPublishNewsRequest(title, details)
    {
        return {
            title,
            details,
        };
    }

    /* istanbul ignore next */
    static publishNews({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.publishNews,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
    
    static getListNewsRequest(page = 0)
    {
        return {
            page,
        };
    }

    /* istanbul ignore next */
    static listNews({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.widget.news.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getAddRequest(type, widgetParmsJson, sortOrder)
    {
        return {
            type,
            widgetParmsJson,
            sortOrder,
        };
    }

    /* istanbul ignore next */
    static add({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.widgets.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getListRequest(page = 0)
    {
        return {
            page,
        };
    }

    /* istanbul ignore next */
    static list({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.widgets.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getWidgetTitleByType(type)
    {
        const list = WidgetService.getAvailableWidgets();
        const found = list.find(aWidget => aWidget.type === type);
        if(found)
        {
            return found.title;
        }
        else
        {
            return 'Unknown';
        }
    }

    static getAvailableWidgets()
    {
        return [
            {
                title: 'Platform News',
                type: 'news',
                summary: 'Keep up to date on platform news',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'Help',
                type: 'help',
                summary: 'Learn how to use the platform',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Active Seeks',
                type: 'myactiveseeks',
                summary: 'Track the goods and services you seek from other users',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Active Offers',
                type: 'myactiveoffers',
                summary: 'Track the goods and services you offer to other users',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Active Exchanges',
                type: 'myactiveexchanges',
                summary: 'Track your active transactions with other users',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Platforms',
                type: 'trails',
                summary: 'Quick access to your other platforms',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Past Offers',
                type: 'myarchivedoffers',
                summary: 'Review past goods and services you have offered to other users',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Past Seeks',
                type: 'myarchivedseeks',
                summary: 'Review past goods and services you have asked from other users',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Past Exchanges',
                type: 'myarchivedexchanges',
                summary: 'Review your completed transactions',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'Friends',
                type: 'friends',
                summary: 'Quick access to your friends',
                widgetParmsJson: '',
                allowMultiple: false,
            },
            {
                title: 'My Pending Bids',
                type: 'pendingbids',
                summary: 'Review your active bids',
                widgetParmsJson: '',
                allowMultiple: false,
            },
        ];
    }

    static getRemoveRequest(userWidgetId, userId)
    {
        return {
            userWidgetId,
            userId,
        };
    }

    /* istanbul ignore next */
    static remove({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.widgets.remove,
            request,
            onResponse,
            authToken,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
        });
    }

    static getReorderRequest(idList = [])
    {
        return {
            idList
        };
    }

    /* istanbul ignore next */
    static reorder({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.widgets.reorder,
            request, 
            onResponse,
            authToken,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
        });
    }
}

export default WidgetService;
