import ApiService from '../../../../service/api';
import ValidateService from '../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';

class _TestEmailsPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _TestEmailsPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            emailTemplate: {
                isValid: ValidateService.anyValue
            },
            toAddress: {
                isValid: ValidateService.email
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            emailTemplate: {
                name: 'Email Template',
                error: 'Specify an email template',
                value: ApiService.getDefaultTestEmailTemplateNameValue(),
                valueList: ApiService.getTestEmailTemplateDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            toAddress: {
                name: 'Tester Email',
                error: 'Specify YOUR valid email address',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();

        return {
            ...baseState,
            ...fields,
            isValid: false
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        ApiService.sendEmail({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            emailTemplate: this.state.emailTemplate.value,
            toAddress: this.state.toAddress.value
        };
    }

    getConfirmUi(confirmMesssage = 'Email Sent')
    {
        return super.getConfirmUi(confirmMesssage);
    }

    getSubmitButtonUi(buttonText = 'Send Email')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const TestEmailsPrompt = connect(mapStateToProps, mapDispatchToProps)(_TestEmailsPrompt);
export default TestEmailsPrompt;
