import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import theme from '../../theme';
import ResizeService from './../../../service/resize';
import UiService from './../../../service/ui';

const UserOfferViewPanel = (offer, index) => 
    <div style={{
            ...theme.getUserOffersPanelItemStyle(theme),
        }}
        key={index}
    >
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/offer/${offer.id}`}
        >
            <h3 style={{
                    ...theme.getUserOffersPanelTitleStyle(theme),
                }}>
                    { offer.title }
            </h3>
            <p
                style={{
                    ...theme.getUserOffersPanelTimeStyle(theme),
                }}>
                { UiService.timeSince(offer.createdOn) }
            </p>
        </NavLink>
    </div>

const UserOffersViewPanel = ({ data }) => {
    const getUserOffersPanelStyle = () => theme.getUserOffersPanelStyle(theme);
    const [ userOffersPanelStyle, setUserOffersPanelStyle ] = useState( getUserOffersPanelStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserOffersPanelStyle( getUserOffersPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            { 
                data.list.length < 1 ? '' : 
                    <div style={userOffersPanelStyle}>
                        <h3 
                            style={{
                                ...theme.getGeneralTextStyle(theme),
                        }}>
                            Offers
                        </h3>
                        <section>
                            { data.list.map((anOffer, index) => UserOfferViewPanel(anOffer, index)) }
                        </section>
                    </div>
            }
        </section>
    );
};

export default UserOffersViewPanel;