import React from 'react';
import CurrencyService from './../../../service/currency';

const FeeList = ({ list }) => list.map((aFee, index) => 
    <section key={index}>
        <h4>
            { aFee.title}
        </h4>

        <p>
            { aFee.displayFeePercent } % of listed price + 
            {
                CurrencyService.getDisplayPrice(
                    'usd',
                    aFee.flatFee
                )
            }
        </p>
    </section>
);

export default FeeList;
