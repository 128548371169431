import React from 'react';
import { useState } from 'react';
import TermsAndConditionsCopyPanel from './terms-and-conditions-copy';
import PrivacyCopyPanel from './privacy-copy';
import theme from '../../theme';

export const TERMS = 'terms';
export const PRIVACY = 'privacy';

const possibleTabs = [
    {
        title: 'Terms of Service',
        key: TERMS,
    },
    {
        title: 'Privacy Policy',
        key: PRIVACY
    }
];

const Tabs = ({ currentTab, setCurrentTab }) => 
    <div style={{
        ...theme.getTermsAndConditionsTabContainerStyle(theme)
    }}>
        {
            possibleTabs.map((aTab, index) => 
                <button 
                    
                    key={index}
                    className="test-termsAndConditionsTabBtn"
                    onClick={(event) => {
                        setCurrentTab(aTab.key);
                    }}
                >
                    { aTab.title }
                </button>)    
        }
        <div style={{ clear: 'both' }}></div>
    </div>    


const GetSelectedTab = ({currentTab}) => {
    switch(currentTab) 
    {
        case TERMS:
            return <TermsAndConditionsCopyPanel />

        case PRIVACY:
            return <PrivacyCopyPanel />

        default:
            return <TermsAndConditionsCopyPanel />
    }
};

const TermsAndConditionsPanel = ({ startTab = TERMS }) => {
    const [currentTab, setCurrentTab] = useState(startTab);

    return (
        <div>
            <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
            <GetSelectedTab currentTab={currentTab} />
        </div>
         
    );
};

export default TermsAndConditionsPanel;
