import React from 'react';
import DetailPanel from './../core/detail';
import ImgService from './../../../service/img';
import AddImgListViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';

class _AddImgListPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.onAddSavedImg = this.onAddSavedImg.bind(this);

        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onAddSavedImg(img)
    {
        if(this.props.onAddSavedImg)
        {
            this.props.onAddSavedImg(img);
        }
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load offer details')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        ImgService.list({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        ImgService.list({
            request: {
                page,
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getLoadDataRequest(inputObj)
    {
        return {
            page: inputObj.page ? inputObj.page : 0
        };
    }

    componentDidMount()
    {
        this.loadData({ page: 0 });
    }

    getLoadedDataDetailUi()
    {
        return <AddImgListViewPanel data={this.state.data} onLoadSpecifiedPage={this.onLoadSpecifiedPage} onAddSavedImg={this.onAddSavedImg} />
    }
}

const AddImgListPanel = connect(mapStateToProps, mapDispatchToProps)(_AddImgListPanel);
export default AddImgListPanel;
