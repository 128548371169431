export const getRefundPolicyContainerStyle = (theme) => ({
    marginBottom: '20px'
});

export const getRefundPolicyCheckFieldText = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '.5rem .75rem',
    width: 'fit-content',
    maxWidth: theme.isTablet() ? '60rem' : 'calc(100% - 1.5rem)'
});

export const getRefundPolicyCheckFieldContainer = (theme) => ({
    backgroundColor: theme.panelWarmColor,
    borderRadius: '.25rem',
    margin: '1rem 0',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    maxWidth: '60rem',
    textAlign: 'center',
    display: 'inline-block',
    boxShadow: 'rgba(29, 29, 29, 0.5) 0px 0.1rem 0.1rem',
    width: 'fit-content'
});

export const getRefundPolicyTextAreaField = (theme) => ({
    padding: '.5rem',
    maxWidth: '58.5rem',
    minHeight: '12rem',
    width: 'calc(100% -1.5rem)',
    margin: '.75rem .75rem 1.25rem',
});

export const getRefundPolicyExplanationContainerStyle = (theme) => ({
    margin: '.5rem auto',
});