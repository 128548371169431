import React from 'react';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import TrailService from './../../../service/trail';
import UserService from './../../../service/user';
import EditProfileTrailsContainer from './edit-profile-trails-container';
import UserTrailListContainer from './user-trail-list-container';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from '../../prompt/core/abstract';
import { customTrailUi } from './../trails/view';
import CloseIcon from './../../../img/close.svg';
import theme from './../../theme';
import { Header } from './view';

class _EditProfileTrailsPanel extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _EditProfileTrailsPanel.getValidators());

        this.resizeFun = () => {};
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getBaseStateOnResponse = this.getBaseStateOnResponse.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.render = this.render.bind(this);
        this.loadTrails = this.loadTrails.bind(this);
        this.onLoadTrailsResponse = this.onLoadTrailsResponse.bind(this);
        this.onLoadTrailsError = this.onLoadTrailsError.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getUserTrailListUi = this.getUserTrailListUi.bind(this);
        this.getAUserTrailUi = this.getAUserTrailUi.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getAddTrailToUserRequest = this.getAddTrailToUserRequest.bind(this);
        this.onAddTrailToUserResponse = this.onAddTrailToUserResponse.bind(this);
        this.onAddTrailToUserError = this.onAddTrailToUserError.bind(this);
        this.onRemoveUserTrail = this.onRemoveUserTrail.bind(this);
        this.getRemoveUserTrailRequest = this.getRemoveUserTrailRequest.bind(this);
        this.onRemoveUserTrailResponse = this.onRemoveUserTrailResponse.bind(this);
        this.onRemoveUserTrailError = this.onRemoveUserTrailError.bind(this);
        this.absorbUpdatedTrailList = this.absorbUpdatedTrailList.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.updatePromptContainerStyle = this.updatePromptContainerStyle.bind(this);
        this.getPromptContainerStyle = this.getPromptContainerStyle.bind(this);
    }

    static getValidators()
    {
        return {
            trailId: {
                isValid: ValidateService.anyValue
            },
            username: {
                isValid: ValidateService.valueRequired
            },

        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            trailId: {
                name: 'Platform',
                error: 'Select a platform you frequent',
                value: '',
                valueList: [],
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.DROP
            },
            username: {
                name: 'Username*',
                error: 'Do you have a username for this service?',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    /* istanbul ignore next */
    getBaseStateOnResponse({ message = '' } = {})
    {
        return {
            ...this.state,
            message,
            requestComplete: false,
            contactingServer: false,
        };
    }

    componentDidMount()
    {
        this.loadTrails();
    }

    getLoadTrailsRequest()
    {
        return {}; // empty request to load self profile details
    }

    loadTrails()
    {
        UserService.getUserDetails({
            request: this.getLoadTrailsRequest(), 
            onResponse: this.onLoadTrailsResponse, 
            onError: this.onLoadTrailsError, 
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onLoadTrailsResponse(response)
    {
        const valueList = response.allTrails.list.filter(aTrail => aTrail.linksToUsers)
            .map(aTrail => ({ name: aTrail.title, value: aTrail.id }) );
        const nextState = {
            ...this.getBaseStateOnResponse(response),
            trailId: {
                ...this.state.trailId,
                valueList,
                value: valueList.length > 0 ? valueList[0].value : '',
                visible: true,
            },
            valid: false,
            allTrailsList: response.allTrails.list ? response.allTrails.list : [],
            user: response.user ? response.user : undefined,
        };

        this.setState(nextState);
    }

    onLoadTrailsError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState();
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            user: undefined,
            isValid: false,
            allTrailsList: [],
            promptContainerStyle: this.getPromptContainerStyle()
        };
    }

    getPromptContainerStyle()
    {
        return theme.getProfilePwdPageStyle(theme);
    }

    /* istanbul ignore next */
    updatePromptContainerStyle()
    {
        let nextState = this.state;
        nextState.promptContainerStyle = this.getPromptContainerStyle();
        this.setState(nextState);
    }

    getPromptUi()
    {
        return (
            <EditProfileTrailsContainer>
                <Header />
                <section style={this.state.promptContainerStyle}>
                    { super.getPromptUi() }
                    { this.getUserTrailListUi() }
                </section> 
            </EditProfileTrailsContainer>
        );
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Added')
    {
        return (
            <section>
                <Header />
                <section style={this.state.promptContainerStyle}>
                    { super.getConfirmUi(confirmMessage) }
                    { this.getUserTrailListUi() } 
                </section>
            </section>
        );
    }

    getRemoveUserTrailRequest(trailId)
    {
        return {
            trailId
        };
    }

    onRemoveUserTrailResponse(response)
    {
        this.absorbUpdatedTrailList(response);
    }

    onRemoveUserTrailError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onRemoveUserTrail(trailId)
    {
        TrailService.removeTrailFromUser({
            request: this.getRemoveUserTrailRequest(trailId),
            onResponse: this.onRemoveUserTrailResponse,
            onError: this.onRemoveUserTrailError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getAUserTrailUi(aUserTrail, index)
    {
        return (
            <div style={{
                    ...theme.getProfileUserTrailStyle(theme),
                }} 
                key={index}>                
                <div style={{
                ...theme.getTrailsCustomContainerStyle(theme),
                }}>
                    { customTrailUi({ aTrail: aUserTrail, index }) }
                </div>
                <div style={{ 
                    ...theme.getAbstractCenterFlexContainerStyle(theme),
                }}>
                    <div 
                        className="test-editProfileTrailsRemoveBtn"
                        onClick={(event) => {this.onRemoveUserTrail(aUserTrail.trailId)}} 
                        style={{
                            ...theme.getProfileRemovePlatformButtonStyle(theme, CloseIcon),
                        }}
                    >
                    </div>
                    { aUserTrail.username }
                </div>
                
            </div>
        );
    }

    getUserTrailListUi()
    {
        return (
            <UserTrailListContainer user={this.state.user}>
                { 
                    this.state.user && this.state.user.trailList.map(uTrail => {
                        const foundTrail = this.state.allTrailsList.find(aTrail => aTrail.id === uTrail.trailId);
                        /* istanbul ignore next */
                        return foundTrail ? {
                            ...uTrail,
                            ...foundTrail,
                        } : uTrail;

                    }).map(this.getAUserTrailUi) 
                }
            </UserTrailListContainer>
        );
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getAddTrailToUserRequest()
    {
        return {
            trailId: this.state.trailId.value,
            trailUsername: this.state.username.value
        };
    }

    absorbUpdatedTrailList(response)
    {
        const nextState = {
            ...this.getBaseStateOnResponse(response),
            user: {
                ...this.state.user,
                trailList: response.trailList
            }
        };
        this.setState(nextState);
    }

    onAddTrailToUserResponse(response)
    {
        this.absorbUpdatedTrailList(response);
    }

    /* istanbul ignore next */
    onAddTrailToUserError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        TrailService.addTrailToUser({
            request: this.getAddTrailToUserRequest(),
            onResponse: this.onAddTrailToUserResponse,
            onError: this.onAddTrailToUserError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }
}

const EditProfileTrailsPanel = connect(mapStateToProps, mapDispatchToProps)(_EditProfileTrailsPanel);
export default EditProfileTrailsPanel;
