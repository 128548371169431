import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminWikiPanel from './../ui/panel/admin-wiki';
import theme from './../ui/theme';

const AdminWikiPage = () => 
    <AdminPageTemplate>
        <section style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <h2>
                Admin Wiki
            </h2>
            
            <p>
                Update system entities
            </p>
            <AdminWikiPanel />

        </section>
        
    </AdminPageTemplate>

export default AdminWikiPage;
