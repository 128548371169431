import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateMenuState } from './../../../../store/state/action';
import menuDetails from '../../../../store/select/menu-details';
import MenuScrollService from '../../../../service/menu-scroll';
import ResizeService from './../../../../service/resize';
import SmallMenu from './small-menu';
import FullMenu from './full-menu';

export const ShowMenuContext = React.createContext(true);

const MenuContainer = ({ 
    children, 
    hideOnScroll = true
}) => {
    const dispatch = useDispatch();
    const [ lastScrollPos, setLastScrollPos ] = useState(window.pageYOffset);
    const [ showMenu, setShowMenu ] = useState(true);
    const { showFullMenu } = useSelector(menuDetails);

    /* istanbul ignore next */
    const closeListMenu = () => {
        if(!showFullMenu)
        {
            dispatch(UpdateMenuState(true));
        }
    };
    
    const scrollEffect = MenuScrollService.buildOnScroll({
        showMenu,
        setShowMenu,
        lastScrollPos,
        setLastScrollPos,
        hideOnScroll,
        onHidingMenu: closeListMenu
    });
    useEffect(scrollEffect);

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        closeListMenu();
    });
    useEffect(resizeEffect);

    /* istanbul ignore next */
    return (
        <ShowMenuContext.Provider value={showMenu}>
            {
                showFullMenu ? 
                    <FullMenu>
                        { children }
                    </FullMenu> : <SmallMenu />
            }
        </ShowMenuContext.Provider>
    );
};

export default MenuContainer;
