import React from 'react';
import FlagService from './../../../service/flag';
import ValidateService from './../../../service/validate';
import FlaggingReasonEnum from './../core/enum/flagging-reason';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import theme from './../../theme';
import flagIcon from './../../../img/flag.svg';

class _FlagPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _FlagPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getClosedPromptUi = this.getClosedPromptUi.bind(this);
        this.openPrompt = this.openPrompt.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            flaggingReason: {
                isValid: FlaggingReasonEnum.isValid
            },
            flaggingUserSummary: {
                isValid: ValidateService.valueRequired
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            flaggingReason: {
                name: 'Reason Type*',
                error: 'Provide the category of reason for flagging',
                value: FlaggingReasonEnum.getDropDownValues(this.props.flaggedContentType)[0].value,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: FlaggingReasonEnum.getDropDownValues(this.props.flaggedContentType)
            },
            flaggingUserSummary: {
                name: 'Reason Details*',
                error: 'Please provide details as to why you are flagging this item',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA,
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            flaggingPromptOpen: false,
        };
    }

    getServerRequest()
    {
        return {
            flaggedContentType: this.props.flaggedContentType,
            contentId: this.props.contentId,
            flaggingReason: this.state.flaggingReason.value,
            flaggingUserSummary: this.state.flaggingUserSummary.value,
        };
    }

    /* istanbul ignore next */
    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
        if(response.message === 'Success' && this.props.onFlagged)
        {
            this.props.onFlagged();
        }
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        FlagService.flagItem({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'This item is under review and no longer visible.')
    {
        return (
            <section>
                <p style={{
                    ...theme.getWarningTextStyle(theme),
                }}>
                    { this.props.confirmMessage ? this.props.confirmMessage : confirmMessage }
                </p>
            </section>
        );
    }

    getSubmitButtonUi(buttonText = 'Flag')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    openPrompt()
    {
        const nextState = {
            ...this.state,
            flaggingPromptOpen: true
        };
        this.setState(nextState);
    }

    getClosedPromptUi()
    {
        return (
            <div style={this.props.buttonStyle}>
                <button 
                    className="test-flagPromptFlagBtn"
                    onClick={event => { this.openPrompt() }} 
                >
                    <div
                        style={{
                            ...theme.getFlagImageStyle(theme, flagIcon),  
                    }}>
                    </div>
                    <div style={{
                        ...theme.getFlagTextStyle(theme),  
                    }}>
                        { 
                            this.props.flagButtonTitle ? this.props.flagButtonTitle : 'Flag'
                        }
                    </div>
                </button>
            </div>
        );
    }

    getOpenPromptUi()
    {
        return (
            <div style={{
                ...theme.getFlagPromptStyle(theme),
            }}>
                <h2>
                    { this.props.openPromptTitle }
                </h2>
                
                <p style={{
                    ...theme.getSmallWarningTextStyle(theme),
                }}>
                    Abuse of the flagging system will result in a banned account.
                </p>

                { super.getPromptUi() }
                
            </div>
        );
    }

    /* istanbul ignore next */
    onCloseDialog(event)
    {
        const nextState = {
            ...this.state,
            flaggingPromptOpen: false
        };
        this.setState(nextState);
    }

    getPromptUi() {
        return (
            <div>
                { this.getClosedPromptUi() }
                <Dialog
                    onClose={this.onCloseDialog}
                    open={this.state.flaggingPromptOpen}
                >
                    { this.getOpenPromptUi() }
                </Dialog>
                     
            </div>
        );
    }
}
const FlagPrompt = connect(mapStateToProps, mapDispatchToProps)(_FlagPrompt);
export default FlagPrompt;
