import theme from './../../theme';
import CurrencyService from './../../../service/currency';
import ResizeService from './../../../service/resize';
import CurrencyInputService from './../../../service/currency-input';
import USPSService from './../../../service/usps';
import { useState, useEffect } from 'react';

const getFieldList = (asking) => {

    const parsedVal = CurrencyInputService.parse(asking);
    const { total, amount, fee } = USPSService.getFeeEstimate(parsedVal);
    
    return [
        {
            title: 'Listed Price',
            value: amount
        },
        {
            title: '*Estimated Post Office Fee',
            value: fee
        },
        {
            title: '*Estimated Sender Cost',
            value: total
        }
    ];
};

const UspsDisplayPricePanel = ({ asking }) => {
    const getAddUspsPaymentExchangePromptPriceLineStyle = () => theme.getAddUspsPaymentExchangePromptPriceLineStyle(theme);
    const getAddUspsPaymentExchangePromptPriceContainerStyle = () => theme.getAddUspsPaymentExchangePromptPriceContainerStyle(theme);
    const [ addUspsPaymentExchangePromptPriceContainerStyle, setAddUspsPaymentExchangePromptPriceContainerStyle ] = useState( getAddUspsPaymentExchangePromptPriceContainerStyle() );
    const [ addUspsPaymentExchangePromptPriceLineStyle, setAddUspsPaymentExchangePromptPriceLineStyle ] = useState( getAddUspsPaymentExchangePromptPriceLineStyle() );
    const resizeEffect = ResizeService.buildOnResize(
        // istanbul ignore next
        () => {
            setAddUspsPaymentExchangePromptPriceContainerStyle( getAddUspsPaymentExchangePromptPriceContainerStyle() );
            setAddUspsPaymentExchangePromptPriceLineStyle( getAddUspsPaymentExchangePromptPriceLineStyle() );
        }
    );
    useEffect(resizeEffect);
    const fieldList = getFieldList(asking);
    return (
        <div style={addUspsPaymentExchangePromptPriceContainerStyle}>
            {
                fieldList.map((f, index) => 
                    <div 
                        key={index}
                        style={addUspsPaymentExchangePromptPriceLineStyle}>
                        <div style={{
                            ...theme.getAddUspsPaymentExchangePromptPriceTitleStyle(theme)
                        }}>
                            { f.title }
                        </div>
                        <div style={{
                            ...theme.getAddUspsPaymentExchangePromptPriceValueStyle(theme)
                        }}>
                            { 
                                CurrencyService.getDisplayPrice(
                                    'usd',
                                    f.value,
                                    false
                                )
                            }
                        </div>
                    </div>)
            }
        </div>
    );
};

export default UspsDisplayPricePanel;
