import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class AuthService {

    static getForgotRequest(email) 
    {
        return {
            email
        };
    }

    /* istanbul ignore next */
    static forgot({
        request, 
        onResponse, 
        onError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.unauth.forgot,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError,
                onNetworkOffline
            })
        });
    }

    static getJoinRequest(email, username, inviteCode, pwd) 
    {
        return {
            inviteCode,
            email, 
            username,
            pwd
        };
    }

    /* istanbul ignore next */
    static join({
        request, 
        onResponse, 
        onError, 
        onNetworkOffline
    }) 
    {
        endpointRequest({
            url: config.url.unauth.join,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError,
                onNetworkOffline
            })
        });
    }


    static getLoginRequest(email, pwd) {
        return {
            email,
            pwd
        };
    }

    /* istanbul ignore next */
    static login({
        request, 
        onResponse, 
        onError, 
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.unauth.login,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError,
                onNetworkOffline
            })
        });
    }

    static getLoginWithTokenRequest(email, pwd, token)
    {
        return {
            email,
            pwd,
            token
        }
    }

    /* istanbul ignore next */
    static loginWithToken({
        request, 
        onResponse, 
        onError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.unauth.loginWithToken,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError,
                onNetworkOffline
            })
        });
    }
}

export default AuthService;
