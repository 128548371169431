import TrailService from './../../service/trail';
import ValidateService from './../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './core/abstract';
import mapStateToProps from './../../store/map/auth-token';
import mapDispatchToProps from './../../store/map/on-logout';
import { connect } from 'react-redux';

class _AddTrailPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _AddTrailPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    static getValidators()
    {
        return {
            title: {
                isValid: ValidateService.textLine
            },
            url: {
                isValid: ValidateService.textLines
            },
            details: {
                isValid: ValidateService.textLine
            },
            displaySortOrder: {
                isValid: ValidateService.numeric
            },
            linksToUsers: {
                isValid: ValidateService.anyValue
            },

        };
    }

    getFieldsModel()
    {
        return {
            title: {
                name: 'Title*',
                error: 'Provide a trail name, no more than 112 characters',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            url: {
                name: 'URL*',
                error: 'Provide a trail URL, no more than 2170 characters',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            details: {
                name: 'Details*',
                error: 'Provide trail details, no more than 112 characters',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
            displaySortOrder: {
                name: 'Display Sort Order',
                error: 'Provide a sorting order',
                value: '1',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            linksToUsers: {
                name: 'Links to Users',
                error: 'Specify a trail has individual user accounts',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },

        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    componentDidMount()
    {
        this.setFocus('title');
    }

    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onSendToServer()
    {
        TrailService.addTrail({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            title: this.state.title.value,
            url: this.state.url.value,
            details: this.state.details.value,
            linksToUsers: this.state.linksToUsers.value,
        };
    }

    getConfirmUi(confirmMessage = 'Trail Added')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const AddTrailPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddTrailPrompt);
export default AddTrailPrompt;
