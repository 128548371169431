import React from 'react';
import Pagination from './../../prompt/core/pagination';
import theme from './../../theme';
import { NavLink } from 'react-router-dom'; 
import CarrierGroupNavMenu from './../../menu/carrier-group/carrier-group-nav-menu';

const FormattedLocation = ({ loc }) => 
    <span>
        { loc.shortState !== '' ? 
            `${loc.city}, ${loc.shortState}` : 
            `${loc.city} ${loc.state}` }
    </span>

const PendingBid = ({
    pendingBid,
    carrierGroupId,
    declineRequest
}) => {
    
    return (
        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)  
        }}>
            <p>
                Weight: 
                    { 
                        pendingBid.delivery.weight.pounds !== '0' ? `${pendingBid.delivery.weight.pounds} lb ` : '' 
                    }

                    { 
                        pendingBid.delivery.weight.ounces !== '0' ? `${pendingBid.delivery.weight.ounces} oz ` : '' 
                    }

            </p>
            <p>
                { `Estimated Distance: ${pendingBid.delivery.estimatedDeliveryDistance} ${pendingBid.delivery.estimatedDeliveryDistanceUnit}`}
            </p>
            <p>
                From: <FormattedLocation loc={pendingBid.delivery.pickupLocation} />
            </p>
            <p>
                To <FormattedLocation loc={pendingBid.delivery.deliveryLocation} />
            </p>

            <div>
                <NavLink to={`/profile/groups/carrier/${carrierGroupId}/plan/${pendingBid.delivery.id}`}>
                    <button>
                        Plan Delivery
                    </button>
                </NavLink>
            </div>
            <div>
                <button 
                    className="test-carrierGroupPendingBidQueueDeclineBtn"
                    onClick={event => { declineRequest(pendingBid.id) }}
                >
                    Decline Request For Delivery
                </button>
            </div>

            <hr />
        </div>
    );
};

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />


const PopulatedView = ({
    pendingBids,
    onLoadSpecifiedPage,
    declineRequest,
    carrierGroupId
}) => 
    <div>
        <h3>
            Pending Quote Requests
        </h3>

        { getPaginationUi({ data: pendingBids, onLoadSpecifiedPage }) }

        {
            pendingBids.list.map((pendingBid, index) => 
                <PendingBid 
                    pendingBid={pendingBid}
                    declineRequest={declineRequest}
                    carrierGroupId={carrierGroupId}
                    key={index}
                />
            )
        }

        { getPaginationUi({ data: pendingBids, onLoadSpecifiedPage }) }

    </div>

const EmptyView = () => 
    <div>
        <p>
            No Pending Quote Requests
        </p>
    </div>


const CarrierGroupPendingBidQueueViewPanel = ({
    data, 
    onLoadSpecifiedPage,
    declineRequest
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <CarrierGroupNavMenu
            carrierGroupId={data.carrierGroup.id}
            carrierGroupName={data.carrierGroup.name}
        />
    
        {
            data.pendingBids.list.length < 1 ? <EmptyView /> : 
                <PopulatedView 
                    pendingBids={data.pendingBids}
                    onLoadSpecifiedPage={onLoadSpecifiedPage}
                    declineRequest={declineRequest}
                    carrierGroupId={data.carrierGroup.id}
                />

        }
    </div>
export default CarrierGroupPendingBidQueueViewPanel;
