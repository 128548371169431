import config from '../config';

class NetworkService {
        
    static syncIsOnline(onlineResult, offlineResult)
    {
        NetworkService.isOnline().then(isOnlineResult => {
            if(isOnlineResult)
            {
                onlineResult();
            }
            else 
            {
                offlineResult();
            }
        });
    }

    static async isOnline()
    {
        try
        {
            const response = await fetch(config.url.networkTest);
            return response.status >= 200 && response.status < 300;
        }
        catch(err)
        {
            return false;
        }
    }
}

export default NetworkService;
