import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../service/resize';
import theme from './../theme';
import { NavLink } from 'react-router-dom';

const shortcutList = [
    {
        title: 'Search Active Exchanges',
        url: '/exchanges/active',
        details: 'Review your active exchanges'
    },
    {
        title: 'Search All Exchanges',
        url: '/exchanges/all',
        details: 'Review all your exchanges'
    },
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getExchangesLandingShortCutContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const ExchangeShortcuts = (parms) => 
    <div style={{
        ...theme.getExchangesLandingShortCutOuterContainerStyle(theme),  
    }}>
        {
            shortcutList.map((cut, index) => <Shortcut details={cut.details} title={cut.title} url={cut.url} key={index} />)
        }
    </div>

const ExchangesLandingPanel = () => {
    const getExContainerStyle = () => theme.getExchangesContainerStyle(theme);
    const [ exContainerStyle, setExContainerStyle ] = useState( getExContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setExContainerStyle( getExContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getExchangesLandingContainerStyle(theme)
        }}>
            <div style={exContainerStyle}>
                <h2>
                    Exchanges
                </h2>
                <p>
                    Once your bid is accepted, a transaction reference will be found here.
                </p>
                <ExchangeShortcuts />
            </div>
        </div>
    );
};

export default ExchangesLandingPanel;
