import ExchangeService from './../../../service/exchange';
import { _ExchangeDetailPanel } from './../exchange-detail';
import { connect } from 'react-redux';
import mapStateToProps from'./../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _AdminOnlineTransactionDetailReview extends _ExchangeDetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    getLoadDataRequest(inputObj)
    {
        return {
            payId: this.props.payId
        }
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        const nextState = {
            ...this.state,
            contactingServer: true,
        };

        this.setState(nextState);
        ExchangeService.getDetailsByPayId({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }
}

const AdminOnlineTransactionDetailReview = connect(mapStateToProps, mapDispatchToProps)(_AdminOnlineTransactionDetailReview);
export default AdminOnlineTransactionDetailReview;
