import { 
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';


export const buildFindColorKey = loc => aKey => aKey.latitude === loc.latitude && aKey.longitude === loc.longitude;

const getRandomColor = () => Math.floor(Math.random()*16777215).toString(16); 

export const generateColorKey = loc => ({
    latitude: loc.latitude,
    longitude: loc.longitude,
    color: getRandomColor()
});

export const buildGetColorKeyByLocation = (mapColorKeys, setMapColorKeys) => loc => {
    let found = mapColorKeys.find(buildFindColorKey(loc));
    if(!found)
    {
        const nextKey = generateColorKey(loc);
        setMapColorKeys([
            ...mapColorKeys,
            nextKey
        ]);
        return nextKey;
    }

    return found;
};

export const generateIniMapColorKeys = (stepList, existingColorKeys = []) => {
    let iniMapColorKeys = [ ...existingColorKeys ];

    if(stepList)
    {

        stepList.forEach(aStep => {
            const loc = [
                CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP,
                CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP
            ].includes(aStep.type) ? aStep.from : (
                [
                    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
                    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
                    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT
                ].includes(aStep.type) ? 
                    aStep.to : null
            );

            if(loc)
            {
                const findColorKey = buildFindColorKey(loc);
                let found = iniMapColorKeys.find(findColorKey);
                /* istanbul ignore next */
                if(!found)
                {
                    iniMapColorKeys.push(
                        generateColorKey(
                            loc
                        )
                    );
                }
            }
        });
    }
    
    return iniMapColorKeys;
};
