import React from 'react';
import { useState } from 'react';
import theme from './../../../theme';
import UserActionTypeEnum from './../../../prompt/core/enum/user-action-type';
import UserActionContextEnum from './../../../prompt/core/enum/user-action-context';
import { USER_ACTION_CONTEXT_SET_BY_ADMIN } from './../../../prompt/core/enum/user-action-context';
import UiService from './../../../../service/ui';
import AdminAddUserPermissionPrompt from './../../../prompt/admin/admin-add-user-permission';
import AdminUpdateUserPermissionPrompt from './../../../prompt/admin/admin-update-user-permission';
import Dialog from '@mui/material/Dialog';

const PermissionRow = ({ action, onDeleteAction, startEditAction }) => 
    <tr>
        <td>
            {
                action.context === USER_ACTION_CONTEXT_SET_BY_ADMIN && 
                <div>
                    <button 
                        className="test-adminUserPermissionsEditBtn"
                        onClick={event => { startEditAction(action) }}
                    >
                        Edit
                    </button>
                    <button 
                        className="test-adminUserPermissionsDeleteBtn"
                        onClick={event => { onDeleteAction(action.type) }}
                    >
                        Delete
                    </button>
                </div>

            }
        </td>
        <td>
            { UserActionTypeEnum.getCopy(action) }
        </td>
        <td>
            { UiService.getDateReport(action.updatedOn) }
        </td>
        <td>
            { action.context }
        </td>
        <td>
            { action.notes }
        </td>
        
    </tr>

const FullPermissionsList = ({
    allActionRecords,
    targetUserId,
    onDeleteAction,
    onLoadDataResponse
}) => {
    const [selectedAction, setSelectedAction] = useState(undefined);
    const [editActionOpen, setEditActionOpen] = useState(false);

    /* istanbul ignore next */
    const startEditAction = (action) => {
        setSelectedAction(action);
        setEditActionOpen(true);
    };

    /* istanbul ignore next */
    const stopEditAction = () => {
        setEditActionOpen(false);
        setSelectedAction(undefined);
    };

    return (
        <div>
            <h3>
                Full View Access Details
            </h3>
            <table>
                <thead>
                    <tr>
                        <td>

                        </td>
                        <td>
                            Permission
                        </td>
                        <td>
                            Last Updated
                        </td>
                        <td>
                            Context
                        </td>
                        <td>
                            Notes
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        allActionRecords.map(action => <PermissionRow startEditAction={startEditAction} action={action} onDeleteAction={onDeleteAction} key={action.id} />)
                    }
                </tbody>
            </table>
            {
                /* istanbul ignore next */
                selectedAction !== undefined && 
                <div>
                    <Dialog 
                        onClose={ event => {
                            stopEditAction();
                        }}
                        open={editActionOpen}
                    >
                        <AdminUpdateUserPermissionPrompt 
                            targetUserId={targetUserId} 
                            title={UserActionTypeEnum.getTypeCopy(selectedAction.type)}
                            onLoadDataResponse={response => {
                                onLoadDataResponse(response);
                                stopEditAction();
                            }}
                            type={selectedAction.type}
                            notes={selectedAction.notes}
                            allowAccess={selectedAction.allowAccess}
                        />
                    </Dialog>
                </div>
            }
        </div>
    );
};

const AccessDetail = ({ actionKey, actionPermissions }) => 
    <div>
        { UserActionTypeEnum.getCopy(actionPermissions[actionKey]) }
    </div>

const AccessDetails = ({
    actionPermissions,
    keyList
}) => <div>
        <h3>
            Access Details
        </h3>
        {
            keyList.map(actionKey => 
                <AccessDetail 
                    key={actionKey} 
                    actionKey={actionKey}
                    actionPermissions={actionPermissions}
                />
            )
        }
    </div>

const AddRule = ({
    targetUserId,
    onLoadDataResponse,
    actionPermissions = undefined
}) => {
    
    const serverSideKeyList = actionPermissions ? Object.keys(actionPermissions) : [];
    const adminServerSideKeyList = serverSideKeyList.filter(aKey => actionPermissions[aKey].context === UserActionContextEnum.USER_ACTION_CONTEXT_SET_BY_ADMIN);
    const availableDropDownValues = UserActionTypeEnum.getAddTypeDropDownList(adminServerSideKeyList);

    const [addOpen, setAddOpen] = useState(false);
    return (
        <div>
            {
                availableDropDownValues.length > 0 && !addOpen && (
                    <div>
                        <button 
                            className="test-adminUserPermissionsAddRuleBtn"
                            onClick={ event => { setAddOpen(!addOpen) }}
                        >
                            Add Rule
                        </button>
                    </div>
                )
            }

            {
                availableDropDownValues.length > 0 && addOpen && (
                    <div>
                        <button 
                            className="test-adminUserPermissionsCloseBtn"
                            onClick={event => { setAddOpen(!addOpen) }}
                        >
                            Close
                        </button>
                        <h3>
                            Add Permission Rule
                        </h3>
                        <AdminAddUserPermissionPrompt 
                            availableDropDownValues={availableDropDownValues} 
                            targetUserId={targetUserId} 
                            onLoadDataResponse={response => {
                                /* istanbul ignore next */
                                onLoadDataResponse(response);
                                /* istanbul ignore next */
                                setAddOpen(false);
                            }}
                        />
                    </div>
                )
            }
        </div>
    );
};

const PermissionsView = ({
    allActionRecords,
    actionPermissions,
    targetUserId,
    onDeleteAction,
    onLoadDataResponse
 }) => {
    const serverSideKeyList = Object.keys(actionPermissions);
    const [ keyList ] = useState(serverSideKeyList);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <AccessDetails actionPermissions={actionPermissions} keyList={keyList} />

            <FullPermissionsList 
                targetUserId={targetUserId} 
                allActionRecords={allActionRecords} 
                keyList={keyList}
                onDeleteAction={onDeleteAction}
                onLoadDataResponse={onLoadDataResponse}
            />

            <AddRule 
                targetUserId={targetUserId} 
                actionPermissions={actionPermissions} 
                onLoadDataResponse={onLoadDataResponse}
            />

        </div>
    );
 };



 const AllAccessView = ({
    targetUserId,
    onLoadDataResponse
 }) => {

    
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <h3>
                Full User Access
            </h3>
            
            <AddRule 
                targetUserId={targetUserId} 
                onLoadDataResponse={onLoadDataResponse}
            />
        </div>
    );
};

const AdminUserPermissionsViewPanel = ({ 
    data, 
    targetUserId, 
    onDeleteAction,
    onLoadDataResponse
}) => data.allowedAllAccess ? 
    <AllAccessView 
        targetUserId={targetUserId} 
        onLoadDataResponse={onLoadDataResponse}
    /> : 
        <PermissionsView 
            onLoadDataResponse={onLoadDataResponse}
            targetUserId={targetUserId}
            allActionRecords={data.allActionRecords}
            onDeleteAction={onDeleteAction}
            actionPermissions={data.actionPermissions}
        />

export default AdminUserPermissionsViewPanel;
