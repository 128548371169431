import React from 'react';
import { useState } from 'react';
import ActorDetails from './actor-details';
import Dialog from '@mui/material/Dialog';
import theme from './../../theme';

const AwaitingRefundView = ({ exchange, onRefundReviewed }) => {
    const [showPrompt, setShowPrompt] = useState(false);
    return (
        <section>
            <div>
                <h2>
                    Awaiting Refund Review from 
                    <ActorDetails
                        usersDetails={exchange.usersDetails}
                        actorId={exchange.bid.payoutUserId}
                        amIActor={exchange.bid.amIPayout}
                    />
                </h2>
                {
                    exchange.bid.amIPayout && 
                        <div>
                            <div>
                                <button 
                                    className="test-exchangeDetailAwaitingRefundViewRespondToRefundRequestBtn"
                                    onClick={event => { setShowPrompt(true) }}
                                >
                                    Respond to Refund Request
                                </button>
                            </div>
                            <Dialog 
                                onClose={ 
                                    /* istanbul ignore next */
                                    event => { setShowPrompt(false) }
                                }
                                open={showPrompt}
                            >
                                <div style={{
                                    ...theme.getExchangeDetailReviewRefundRequestStyle(theme)
                                }}>
                                    <p>
                                        Would you like to provide a refund to this user?  
                                        <br />
                                        <br />
                                        Need more information?  Use the chat feature to learn more.
                                    </p>
                                    <button 
                                        className="test-exchangeDetailAwaitingRefundViewApproveRefundBtn"
                                        onClick={
                                            /* istanbul ignore next */
                                            event => { onRefundReviewed(true) }
                                        }
                                    >
                                        Approve Refund
                                    </button>
                                    <button 
                                        className="test-exchangeDetailAwaitingRefundViewDenyRefundBtn"
                                        onClick={
                                            /* istanbul ignore next */
                                            event => { onRefundReviewed(false) }
                                        }
                                    >
                                        Deny Refund
                                    </button>
                                </div>
                            </Dialog>
                        </div>
                }
            </div>
        </section>
    );
};

export default AwaitingRefundView;
 

