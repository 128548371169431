import React from 'react';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import ValidateService from './../../../service/validate';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';

class _ProfileShopTraitPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _ProfileShopTraitPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
    }

    static getValidators()
    {
        return {
            enabled: {
                isValid: ValidateService.anyValue
            },
            details: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    getFieldsModel()
    {
        return {
            enabled: {
                name: this.props.enabledLabel,
                error: '',
                value: this.props.enabled,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX,
            },
            details: {
                name: this.props.detailsLabel,
                error: 'Required',
                value: this.props.details,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA,
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }
    
    onSendToServer()
    {
        this.props.onUpdateShopTrait(this.getServerRequest());
    }

    getServerRequest()
    {
        return {
            enabled: this.state.enabled.value, 
            traitType: this.props.traitType, 
            details: this.state.details.value
        };
    }

    getSubmitButtonUi(buttonText = 'Save')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getPromptUi()
    {
        return (
            <div>
                <div>
                    {
                        this.props.hasData && 
                            <button 
                                className="test-ProfileShopTrait"
                                onClick={event => { this.props.onDeleteShopTrait() }}
                            >
                                Delete
                            </button>
                        }
                </div>
                {  super.getPromptUi() }
            </div>
        );
    }
}

const ProfileShopTraitPrompt = connect(mapStateToProps, mapDispatchToProps)(_ProfileShopTraitPrompt);
export default ProfileShopTraitPrompt;
