import React from 'react';
import AdminPageTemplate from './template/admin-page';
import MaintanceTasksPrompt from './../ui/prompt/admin/maintance-tasks';
import theme from './../ui/theme';

const AdminMaintanceTasksPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            Maintance Mode Tasks
        </h2>
        <MaintanceTasksPrompt />
    </AdminPageTemplate>

export default AdminMaintanceTasksPage;
