import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../../theme';
import ResizeService from './../../../../service/resize';

export const SearchResultsViewPrompt = ({
    children
}) => {
    const getOfferSearchResultStyle = () => theme.getOfferSearchResultStyle(theme);
    const [ offerSearchResultStyle, setOfferSearchResultStyle ] = useState(getOfferSearchResultStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferSearchResultStyle(getOfferSearchResultStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={{
                ...theme.getOfferSearchResultCenterStyle(theme),
            }}>
            <section style={offerSearchResultStyle}>
                { children }
            </section>
        </div>
    );
};

