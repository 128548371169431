import React from 'react';
import { useState } from 'react';
import Pagination from './../../prompt/core/pagination';
import theme from './../../theme';
import UiService from './../../../service/ui';
import Dialog from '@mui/material/Dialog';
import CreateCarrierGroupTripPrompt from './../../prompt/create-carrier-group-trip';
import CarrierGroupNavMenu from './../../menu/carrier-group/carrier-group-nav-menu';

const Trip = ({
    trip
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        
        {
            trip.estimatedStart && 
                <div>
                    <span>
                        Estimated Start
                    </span>
                    <span>
                        { UiService.getStoryDate(trip.estimatedStart) }
                    </span>    
                </div>
        }

        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            <span>
                From
            </span>
            <p>
                {
                    trip.from.mailingAddress
                }
            </p>
        </div>


        {
            trip.estimatedEnd && 
                <div>
                    <span>
                        Estimated End
                    </span>
                    <span>
                        { UiService.getStoryDate(trip.estimatedEnd) }
                    </span>    
                </div>
        }

        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            <span>
                To
            </span>
            <p>
                {
                    trip.to.mailingAddress
                }
            </p>
        </div>


        
    </div>

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const PopulatedView = ({ 
    trips, 
    onLoadSpecifiedPage
}) => 
    <div>
        <h3>
            Trips
        </h3>

        { getPaginationUi({ data: trips, onLoadSpecifiedPage }) }

        {
            trips.list.map((trip, index) => 
                <Trip 
                    trip={trip} 
                    key={index} 
                />
            )
        }

        { getPaginationUi({ data: trips, onLoadSpecifiedPage }) }

    </div>

const EmptyView = () => 
    <div>
        <p>
            No Trips Found
        </p>
    </div>
                                                        
const NavToolBar = ({ 
    carrierGroupName, 
    carrierGroupId,
    onTripAdded
}) => {
    const [ viewAddPrompt, setViewAddPrompt ] = useState(false);
    /* istanbul ignore next */
    const onAdded = response => {
        onTripAdded(response);
        setViewAddPrompt(false);
    };
    return (
        <div>
            <CarrierGroupNavMenu
                carrierGroupId={carrierGroupId}
                carrierGroupName={carrierGroupName}
            />

            <div>
                <button 
                    className="test-carrierGroupUserTripsAddTripBtn"
                    onClick={event => { setViewAddPrompt(true) }}
                >
                    Add Trip
                </button>
            </div>

            <Dialog
                onClose={
                    /* istanbul ignore next */
                    event => { setViewAddPrompt(false) }
                }
                open={viewAddPrompt}
            >
                <h2>
                    Add a new trip
                </h2>

                <p>
                    Trips are planned shipping routes.  Once you've created a trip, you and your carrier group members can add cargo to fulfill delivery requests.
                </p>

                <CreateCarrierGroupTripPrompt
                    onTripAdded={onAdded}
                    carrierGroupId={carrierGroupId}
                />
            

            </Dialog>
        </div>
    );
};                                              


const CarrierGroupUserTripsViewPanel = ({
    data,
    onLoadSpecifiedPage,
    onTripAdded
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>

        <NavToolBar 
            carrierGroupId={data.carrierGroup.id}
            carrierGroupName={data.carrierGroup.name}
            onTripAdded={onTripAdded}
        />

        {
            data.trips.list.length < 1 ? 
                <EmptyView /> : 
                    <PopulatedView
                        trips={data.trips}
                        onLoadSpecifiedPage={onLoadSpecifiedPage} 
                    />
        }

    </div>


export default CarrierGroupUserTripsViewPanel;
