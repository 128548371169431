import React from 'react';
import theme from './../../../theme';
import PlanPricingEditor from './plan-pricing-editor';
import TrackPricingJob from './track-pricing-job';

const PlanPricing = ({
    carrierGroupId,
    price,
    delivery,
    onPriceUpdated,
    members,
    onPriceControlsUpdated,
    onCompletePricingStatus,
    estimatedDollarsPerGallon,
    estimatedMPG,
    onSetPayout,
    pendingPayoutUpdates,
    onAddExchange,
    onRemoveExchange,
    exchangeList
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        {
            price === null || !price.jobCompleted ? 
                <TrackPricingJob
                    carrierGroupId={carrierGroupId}
                    deliveryId={delivery.id}
                    onPriceUpdated={onPriceUpdated}
                /> : 
                <PlanPricingEditor 
                    onPriceControlsUpdated={onPriceControlsUpdated}
                    delivery={delivery}
                    onSetPayout={onSetPayout}
                    estimatedMPG={estimatedMPG}
                    estimatedDollarsPerGallon={estimatedDollarsPerGallon}
                    price={price}
                    members={members}
                    onAddExchange={onAddExchange}
                    onRemoveExchange={onRemoveExchange}
                    pendingPayoutUpdates={pendingPayoutUpdates}
                    exchangeList={exchangeList}
                    onCompletePricingStatus={onCompletePricingStatus}
                />
        }
    </div>

export default PlanPricing;
