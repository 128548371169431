import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';

class DisputeService 
{
    static getSearchRequest(disputeId, disputeIsActive, awaitingResponse, page)
    {
        return {
            disputeId, 
            disputeIsActive,
            awaitingResponse, 
            page
        }
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.searchDisputes,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
            debounce: true
        });
    }
}

export default DisputeService;
