import theme from './../../theme';

const OnlineExchangeAdvicePanel = () => 
    <div style={{
        ...theme.getExchangesContainerStyle(theme),
        ...theme.getExchangesPaddingStyle(theme),
    }}>
        <h1>
            When receiving online payments consider that
        </h1>
        <ul>
            <li>
                Insuring packages can cover you in cases of refunds related to shipping issues
            </li>
            <li>
                Keeping records of tracked shipments can help you win a payment dispute
            </li>
            <li>
                Complete and accurate online profile information is required for a quick payout
            </li>
            <li>
                After an exchange has been confirmed by the other party, typically a payout will be received after two weeks
            </li>
        </ul>
    </div>
    
export default OnlineExchangeAdvicePanel;
