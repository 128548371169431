export const getLocationMailingAddressStyle = (theme) => ({
    whiteSpace: 'pre-wrap',
    paddingBottom: '1rem',
    width: '15rem',
});

export const getConfirmDeleteLocationStyle = theme => ({
    padding: '.75rem 1rem',
});

export const getLocationSelectorContainerStyle = (theme) => ({
    height: 'fit-content',
    borderRadius: '.15rem',
    backgroundColor: theme.buttonColor,
    padding: '.5rem',
    boxShadow: '0 .05rem .05rem rgba(29, 29, 29, .3)',
});

export const getLocationMapLabelsStyle = (theme) => ({
    textAlign: 'center'
});