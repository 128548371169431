import React from 'react';
import LedgerService from './../../../service/ledger';
import ValidateService from './../../../service/validate';
import { FORM_FIELD } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../core/search';
import { Header, AdminLedgerSearchViewPrompt } from './view';
import theme from './../../theme';

class _AdminLedgerSearchPrompt extends SearchPrompt 
{
    constructor(parms)
    {
        super(parms, _AdminLedgerSearchPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.removeSelectedId = this.removeSelectedId.bind(this);
        this.addSelectedId = this.addSelectedId.bind(this);
        this.idIsSelected = this.idIsSelected.bind(this);
        this.onDepositSelected = this.onDepositSelected.bind(this);
        this.onArchiveLedgerItem = this.onArchiveLedgerItem.bind(this);
    }

    static getValidators()
    {
        return {
            hasRefunded: {
                isValid: ValidateService.anyValue
            },
            readyForWithdrawl: {
                isValid: ValidateService.anyValue
            },
            depositedToCompany: {
                isValid: ValidateService.anyValue
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            selectedIdList: []
        };
    }

    removeSelectedId(id)
    {
        const nextState = {
            ...this.state,
            selectedIdList: this.state.selectedIdList.filter(selId => selId !== id )
        };
        this.setState(nextState);
    }

    addSelectedId(id)
    {
        const nextState = {
            ...this.state,
            selectedIdList: [
                ...this.state.selectedIdList,
                id
            ],
        };
        this.setState(nextState);
    }
    
    idIsSelected(id)
    {
        return this.state.selectedIdList.some(selId => selId === id);
    }
    
    onDepositSelected()
    {
        let request = this.getServerRequest();
        request.idList = this.state.selectedIdList;

        const nextState = {
            ...this.state,
            selectedIdList: [],
            contactingServer: true
        };
        this.setState(nextState);
        
        LedgerService.deposit({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getFieldsModel()
    {
        return {
            hasRefunded: {
                name: 'Has Refunded',
                error: '',
                value: false,
                visible: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            readyForWithdrawl: {
                name: 'Ready for Withdrawl',
                error: '',
                value: false,
                visible: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            depositedToCompany: {
                name: 'Deposited to Company',
                error: '',
                value: false,
                visible: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            }
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'ledgerItemList');
    }

    onSendToServer(request = undefined)
    {
        LedgerService.search({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        let request = {
            page: 0
        };

        if(this.state.hasRefunded.value)
        {
            request.hasRefunded = this.state.hasRefunded.value;
        }

        if(this.state.readyForWithdrawl.value)
        {
            request.readyForWithdrawl = this.state.readyForWithdrawl.value;
        }

        if(this.state.depositedToCompany.value)
        {
            request.depositedToCompany = this.state.depositedToCompany.value;
        }

        return request;
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        LedgerService.search({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getNoResultsUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <p>
                    No Ledger Items Found.
                </p>
            </div>
        );
    }

    onArchiveLedgerItem(ledgerItem)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.ledgerItemId = ledgerItem.id;
        LedgerService.archive({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getSearchResultUi(aResult, index)
    {
        return (
            <AdminLedgerSearchViewPrompt 
                addSelectedId={this.addSelectedId} 
                removeSelectedId={this.removeSelectedId} 
                idIsSelected={this.idIsSelected}
                ledgerItem={aResult} 
                key={index} 
                onArchiveLedgerItem={this.onArchiveLedgerItem}
            />
        );
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : (
            <div>
                {
                    this.state.selectedIdList.length > 0 && 
                        <div>
                            <button 
                                className="test-adminLedgerSearchDepositBtn"
                                onClick={ event => { this.onDepositSelected() }}
                            >
                                Deposit {this.state.selectedIdList.length} selected
                            </button>
                        </div>
                }
                <table>
                    <Header />
                    <tbody>
                        {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
                    </tbody>
                </table>
            </div>
        );
    }
}

const AdminLedgerSearchPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminLedgerSearchPrompt);
export default AdminLedgerSearchPrompt;
