export const getUserSeeksPanelStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    marginBottom: '2rem',
});

export const getUserSeeksPanelItemStyle = (theme) => ({
    marginBottom: '10px',
    paddingBottom: '10px',
    borderBottomColor: '#4b3908',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
});

export const getUserSeeksPanelTimeStyle = (theme) => ({
    paddingLeft: '15px',
});