export const getTagListContainerStyle = (theme) => ({
    display: 'flex',
    overflow: 'auto',
    padding: '1rem 0',
    borderRadius: '.25rem',
    height: 'fit-content',
    backgroundColor: theme.panelWarmColor,
});

export const getTagListAllListContainerStyle = (theme) => ({
    display: 'flex',
    overflow: 'auto',
    alignContent: 'flex-start',
    flexDirection: 'column',
    scrollbarGutter: 'stable both-edges',
    flexShrink: '0',
    maxHeight: '26rem',
    textAlign: 'left',
    paddingLeft: '.75rem',
});

export const getTagListSelectedListContainerStyle = (theme) => ({
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: '26rem',
    overflow: 'auto',
    paddingLeft: '.75rem',
});

export const getTagListPossibleTagViewStyle = (theme) => ({
    paddingBottom: '1rem',
    display: 'flex',
    alignItems: 'center'
});

export const getTagListPostedOfferStyle = (theme) => ({
    margin: '.5rem 0 .5rem .75rem',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
});

export const getTagListPostedOfferFlexStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'column'
});