import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from '../../../../service/resize';
import theme from '../../../theme';

const ListItemContainerView = ({ children }) => {
    const getContainerStyle = () => theme.getObtainTypeListItemStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={containerStyle}>
            { children }
        </div>
    );
};

export default ListItemContainerView;