import React from 'react';
import UiService from './../../../../service/ui';

export const Header = () => 
    <thead>
        <tr>
            <td>
                Request
            </td>
            <td>
                Response Time
            </td>
            <td>
                Recorded On
            </td>
        </tr>
    </thead>

export const SearchMetricsViewPrompt = ({ metric, setSearchText }) => 
    <tr>
        <td>
            <span 
                className="test-adminSearchMetricNameTxt"
                onClick={event => { setSearchText(metric.name) }}
            >
                { metric.name }
            </span>
        </td>
        <td>
            {`${metric.durationMS / 1000} seconds`}
        </td>
        <td>
            { UiService.getStoryDate(metric.start) }
        </td>
    </tr>

export default SearchMetricsViewPrompt;
