import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminLedgerSummaryPanel from './../ui/panel/admin/admin-ledger-summary';
import theme from './../ui/theme';

const AdminLedgerSummaryPage = (parms) => 
    <AdminPageTemplate>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h2>
                Admin Ledger Summary
            </h2>
            <AdminLedgerSummaryPanel />
        </div>
    </AdminPageTemplate>

export default AdminLedgerSummaryPage;
