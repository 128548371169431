import React from 'react';
import config from './../../../../config';
import theme from './../../../theme';
import {
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';
import Map, { Marker } from "react-map-gl";
import { supported } from 'mapbox-gl';
import { 
    isGps,
    ZOOM_START_WIDE,
    NoMapView,
} from './../../location-map';
import mapboxgl from 'mapbox-gl';
try 
{
    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line
}
catch(err)
{
    console.error(err);
}

const hasMissingLocations = steps => {
    const totalStepCount = steps.length;
    if(totalStepCount < 1)
    {
        return false;
    }

    const missingLocList = steps.filter(aStep => {
        if(aStep.from && !isGps(aStep.from))
        {
            return true;
        }

        if(aStep.to && !isGps(aStep.to))
        {
            return true;
        }

        return false;
    });

    if(missingLocList.length > 0)
    {
        return true;
    }

    return false;
};

const stepHasMapElements = step => [
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF
].includes(step.type)

/* istanbul ignore next */
const MapView = ({ 
    mapViewState,
    setMapViewState,
    steps, 
    delivery, 
    getColorKeyByLocation
}) => {
    
    return (
        <div style={{
            ...theme.getSmallMapStyle(theme)
        }}>
            <Map 
                { ...mapViewState }
                mapboxAccessToken={config.map.clientKey}
                onMove={(e) => { 
                    /* istanbul ignore next */
                    setMapViewState(e.viewState) 
                }}
                onZoom={(e) => { 
                    /* istanbul ignore next */
                    setMapViewState(e.viewState) 
                }}
                initialViewState={{
                    longitude: delivery.pickupLocation.longitude,
                    latitude: delivery.pickupLocation.latitude,
                    zoom: ZOOM_START_WIDE,
                  }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
            >
                {
                    steps.filter(stepHasMapElements).map(
                        /* istanbul ignore next */
                        (aStep, index) => {
                            const keyColor = getColorKeyByLocation(aStep.type === CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP ? aStep.from : aStep.to).color;
                            return (
                                <Marker 
                                    key={index}
                                    longitude={aStep.type === CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP ? aStep.from.longitude : aStep.to.longitude}
                                    latitude={aStep.type === CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP ? aStep.from.latitude : aStep.to.latitude}
                                >
                                    <div style={{
                                        ...theme.getMapMarkerStyle(theme),
                                        color: `#${keyColor}`, 
                                        background: `#${keyColor}`,
                                        boxShadow: `0 0 2px #${keyColor}`
                                    }}>

                                    </div>
                                </Marker>
                            );
                        }
                    )
                }
            </Map>
        </div>
    );
};

const isMapSupported = () => supported();

const MapsNotSupported = () => 
    <div style={{
        ...theme.getMapFeaturesUnavailbleStyle(theme)
    }}>
        <p>
            This browser does not support maps.
            <br />
            Upgrade your browser for this feature.
        </p>
    </div>

/* istanbul ignore next */
const DeliveryStepsMap = ({
    steps,
    delivery,
    mapViewState,
    setMapViewState,
    getColorKeyByLocation
}) => {

    const missingLocationsGpsData = hasMissingLocations(steps);

    return missingLocationsGpsData ? <NoMapView /> : (
        !isMapSupported() ? <MapsNotSupported /> :
            <MapView 
                mapViewState={mapViewState}
                setMapViewState={setMapViewState}
                steps={steps}
                delivery={delivery}
                getColorKeyByLocation={getColorKeyByLocation}
            />
    )
};

export default DeliveryStepsMap;
