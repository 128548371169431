export const getReportedIssuesViewPanelStyle = (theme) => ({
    textAlign: 'center',
    width: '480px',
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const getReportedIssueUnseenStyle = (theme) => ({
    color: theme.warningColor,
});

export const getReportedIssueSeenStyle = (theme) => ({
    color: 'grey',
});

export const getReportIssuePromptStyle = (theme) => ({
    width: '520px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
});