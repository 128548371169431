export const getUserWidgetSortWidgetStyle = (theme) => ({
    width: '100%',
    height: '4.5rem',
    textAlign: 'center',
    cursor: 'move',
    marginTop: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.panelColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none'

});

export const getUserLandingAvailableWidgetsButtonStyle = (theme, icon) => ({
    width: '100%',
    minWidth: '3.5rem',
    height: '3rem',
    textDecorationLine: 'none',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
});

export const getUserWidgetSpacerStyle = (theme) => ({
    width: '4rem',
    height: '3.5rem',
    float: 'left',
});

export const getUserWidgetExpandButtonStyle = (theme, icon) => ({
    cursor: 'pointer',
    width: '3.5rem',
    height: '3.5rem',
    margin: '0 .25rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    backgroundImage: `url(${icon})`,
    float: 'right',
});

export const getUserWidgetOuterContainerStyle = (theme) => ({
    textAlign: 'center',
    marginTop: '1rem',
});

export const getUserWidgetHeaderTextStyle = (theme) => ({
    display: 'flex',
    margin: '.75rem 0 1.5rem',
    fontSize: theme.isTablet() ? '1.5rem' : '2rem',
    textTransform: 'uppercase',
    justifyContent: 'center',
    fontWeight: 'bold'
});

export const getUserWidgetSubHeaderTextStyle = (theme) => ({
    display: 'flex',
    margin: '.75rem 0',
    fontSize: theme.isTablet() ? '1.25rem' : '1.5rem',
    alignItems: 'center',
    fontWeight: 'bold',
    flexDirection: 'column'
});

export const getUserWidgetContainerStyle = (theme) => ({
    display: 'inline-block',
    padding: '1rem 1rem .5rem 1rem',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    backgroundColor: theme.panelColor,
    boxShadow: 'rgba(29, 29, 29, 0.5) 0px 0.1rem 0.1rem',
    borderRadius: theme.isTablet() ? '.3rem' : '0',
    margin: theme.isTablet() ? '0 1rem 1rem 1rem' : '0',
    overflow: theme.isTablet() ? 'auto' : 'hidden',
    minHeight: '18rem',
    height: theme.isDesktop() ? 'calc(50vh - 4.5rem)' : 
        (
            theme.isTablet() ? 'calc(50vh - 4.5rem)' : 
                'calc(100vh - 8.25rem)'
        ),
    width: theme.isDesktopXL() ? 'calc(32vw - 2.6rem)' : 
    (
        theme.isTablet() ? 'calc(50vw - 2.6rem)' : 
            "100%"
    )
});

export const getUserWidgetFlexParentStyle = (theme) => ({
    display: 'grid',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '10rem',
    gap: '1em 1em',
    width: 'auto',
    textAlign: 'center',
    justifyItems: 'stretch',
    justifyContent: 'center',
    userSelect: 'none',
    gridTemplateColumns: theme.isDesktopEXT() ? 'repeat(3, 1fr)' : 
    (
        theme.isTablet() ? 'repeat(2, 1fr)' : 
            'repeat(1, 1fr)'
    ),
});
