import React from 'react';
import theme from './../../../theme';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import TripSummary from './trip-summary';
import TripStop from './trip-stop';
import AddTripStop from './add-trip-stop';

const PossibleTrip = ({
    trip,
    step,
    selectedMemberId,
    delivery,
    onHookTrip,
    cargoItem
}) => {

    const [ viewStopList, setViewStopList ] = useState(false);
    const onAddStopClicked = event => { setViewStopList(true) };
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <TripSummary 
                trip={trip} 
                selectedMemberId={selectedMemberId}
                onAddStopClicked={onAddStopClicked}
            />

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setViewStopList(false) }
                }
                open={viewStopList}
            >
                <p>
                    Trip Stops
                </p>

                <AddTripStop
                    trip={trip}
                    delivery={delivery}
                    step={step}
                    onHookTrip={onHookTrip}
                    cargoItem={cargoItem}
                />

                {
                    trip.stopList.map(
                        /* istanbul ignore next */
                        (aStop, index) => 
                            <div key={index}>
                                <TripStop
                                    stop={aStop}
                                    cargoItem={cargoItem}
                                />

                                <AddTripStop
                                    trip={trip}
                                    delivery={delivery}
                                    step={step}
                                    onHookTrip={onHookTrip}
                                    cargoItem={cargoItem}
                                    addIndex={index}
                                />

                            </div>
                        
                    )
                }

            </Dialog>


            <hr />
        </div>
    );
};

export default PossibleTrip;
