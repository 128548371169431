import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class RatingService {

    static getAddUserRatingRequest(exchangeId, isPositive, details) {
        return {
            exchangeId,
            isPositive,
            details,
        };
    }

    /* istanbul ignore next */
    static addUserRating({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.rating.addRating,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getUserRatingsRequest(userId, page = 0) {
        return {
            ratedUserId: userId,
            page
        };
    }

    /* istanbul ignore next */
    static getUserRatings({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.rating.getRatings,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getUserRatingsSummaryRequest(ratedUserId)
    {
        return {
            ratedUserId
        };
    }

    /* istanbul ignore next */
    static getUserRatingsSummary({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.rating.getRatingsSummary,
            request, 
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default RatingService;
