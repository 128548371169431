import DetailPanel from './../../../core/detail';
import CarrierGroupDeliveryPlanService from './../../../../../service/carrier-group-delivery-plan';
import SelectDestinationViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../../store/map/on-logout';

class _SelectDestinationPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.listPossibleDestinations({
            request: this.getLoadDataRequest(),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest()
    {
        const steps = this.props.steps;
        const lastStep = steps[steps.length - 1];
        const from = lastStep.from;
        const omitLocationIds = steps
            .filter(step => step.from && step.from.id)
            .map(step => ({
                latitude: step.from.latitude,
                longitude: step.from.longitude
            }));
        return {
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: this.props.delivery.id,
            fromLatitude: from.latitude,
            fromLongitude: from.longitude,
            omitLocationIds
        };
    }

    componentDidMount()
    {
        this.loadData();
    }

    getLoadedDataDetailUi()
    {
        return <SelectDestinationViewPanel 
            data={this.state.data} 
            delivery={this.props.delivery}
            onDestinationSelected={this.props.onDestinationSelected}
        />
    }
}

const SelectDestinationPanel = connect(mapStateToProps, mapDispatchToProps)(_SelectDestinationPanel);
export default SelectDestinationPanel;
