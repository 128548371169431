import React from 'react';
import DetailPanel from './../core/detail';
import UserService from './../../../service/user';
import UserInvitesViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _UserInvitesPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserService.getInviteDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {

        };
    }

    componentDidMount()
    {
        this.loadData({ });
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                <UserInvitesViewPanel data={this.state.data} />
            </section>
        );
    }
}

const UserInvitesPanel = connect(mapStateToProps, mapDispatchToProps)(_UserInvitesPanel);

export default UserInvitesPanel;
