import React from 'react';
import AuthPageTemplate from './template/auth-page';
import OfferArchivedDetailPanel from './../ui/panel/offer-archived-detail';
import { useParams } from 'react-router-dom';

const OfferArchivedDetailPage = () => {
    const { offerId } = useParams();
    return (
        <AuthPageTemplate>
            <OfferArchivedDetailPanel offerId={offerId} />
        </AuthPageTemplate>
    );
};

export default OfferArchivedDetailPage;
