import React from 'react';
import AttSetService from './../../../service/att-set';
import { FORM_FIELD } from './../core/abstract';
import ValidateService from './../../../service/validate';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../core/search';
import AttSetSearchViewPrompt, {
    SingleFieldContainer,
    SearchResultsViewPrompt
} from './view';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import addItem from './../../../img/menu-add-item.svg';
import { AbstractFlexContainer } from './../core/abstract/view';

class _AttSetSearchPrompt extends SearchPrompt
{
    constructor(parms)
    {
        super(parms, _AttSetSearchPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getSingleFieldPrompt = this.getSingleFieldPrompt.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.pageToUrl = this.pageToUrl.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
    }

    static getValidators()
    {
        return {
            searchText: {
                isValid: ValidateService.textLine
            },
        }
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : 
            <SearchResultsViewPrompt>
                {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
            </SearchResultsViewPrompt>
    }

    getNoResultsUi()
    {
        return (
            <section>
                <p style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Nothing Found.&nbsp;
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to="/profile/att-set/add"
                    >
                        Add a Field Set
                    </NavLink>
                </p>
            </section>
        );
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            searchText: {
                name: 'Search',
                error: 'no more than 112 characters',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    /* istanbul ignore next */
    componentDidUpdate(prevProps, prevState)
    {
        if(this.props.page !== prevProps.page)
        {
            this.setContactingServer(true);
            this.onSendToServer({
                searchText: this.props.searchText ? this.props.searchText : '',
                page: this.props.page ? this.props.page : 0,
            });
        }
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'attSetList');
    }

    onSendToServer(request = undefined)
    {
        AttSetService.search({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            searchText: this.state.searchText.value,
            page: this.props.page ? this.props.page : 0,
        };
    }

    /* istanbul ignore next */
    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        AttSetService.search({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    pageToUrl(page)
    {
        const loadPageUrl = `/profile/att-sets/${page}/${encodeURI(this.state.searchText.value)}`;
        return loadPageUrl;
    }

    getSingleFieldPrompt()
    {
        return (
            <SingleFieldContainer>
                <NavLink to="/profile/att-set/add">
                    <div style={{
                        ...theme.getOfferSearchAddOfferButtonStyle(theme, addItem)
                    }}>
                    </div>
                    <div style={{
                        ...theme.getOfferSearchAddOfferButtonTextStyle(theme)
                    }}>
                        ADD FIELD
                        <br />
                        SET
                    </div>
                </NavLink>

                <div style={{
                    ...this.getSingleFieldPrompt_getContainerStyle(),
                }}>
                    <form onSubmit={this.onFormSubmit}>
                        { this.getServerFeedbackUi() }
                        <AbstractFlexContainer>
                            <div style={{
                                ...this.getSingleFieldPrompt_getFieldsStyle(),
                            }}>
                                { this.getFormFieldsUi() }
                            </div>

                            <div>
                                { this.getSubmitButtonUi() }
                            </div>
                        </AbstractFlexContainer>
                    </form>
                </div>
            </SingleFieldContainer>
        );
    }

    getSearchResultUi(aResult, index)
    {
        return <AttSetSearchViewPrompt key={index} attSet={aResult} index={index} />
    }
    
}


const AttSetSearchPrompt = connect(mapStateToProps, mapDispatchToProps)(_AttSetSearchPrompt);
export default AttSetSearchPrompt;
