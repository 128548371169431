import React from 'react';
import { useState, useEffect } from 'react';
import DeliverySteps from './delivery-steps';
import DeliveryStepsMap from './delivery-steps-map';
import LocationMapPanel from './../../location-map';
import { ZOOM_START_WIDE } from './../../location-map';
import EventBusService, { EVENT_CARRIER_GROUP_DELIVERY_PLAN_STEP_ADDED } from './../../../../service/event-bus';
import { 
    buildOnStepSelected, 
    buildGetColorKeyByLocation,
} from './../core';
import { CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT } from './../../../prompt/core/enum/carrier-group-delivery-plan-status';

const PlanDelivery = ({
    plan,
    delivery,
    members,
    onAddStep,
    carrierGroupId,
    mapColorKeys,
    setMapColorKeys,
    onRemoveStep,
    onCompleteDraftStatus
}) => {

    const getColorKeyByLocation = buildGetColorKeyByLocation(mapColorKeys, setMapColorKeys);

    const startLoc = delivery.pickupLocation;
    const iniMapState = {
        longitude: startLoc.longitude,
        latitude: startLoc.latitude,
        zoom: ZOOM_START_WIDE,
    };
    const [ mapViewState, setMapViewState ] = useState(iniMapState);
    const [ selectedStep, setSelectedStep ] = useState(null);

    const onStepSelected = buildOnStepSelected(
        mapViewState,
        setMapViewState,
        setSelectedStep
    );

    useEffect(() => {

        /* istanbul ignore next */
        const onAdded = (payload) => {
            onStepSelected(payload.lastStep);
        };

        EventBusService.on(EVENT_CARRIER_GROUP_DELIVERY_PLAN_STEP_ADDED, onAdded);
        /* istanbul ignore next */
        return () => {
            EventBusService.remove(EVENT_CARRIER_GROUP_DELIVERY_PLAN_STEP_ADDED, onAdded)
        };
    });

    /* istanbul ignore next */
    return (
        <div>
            <DeliverySteps 
                steps={plan.planSteps}
                canEdit={plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT ? true : false}
                plan={plan}
                delivery={delivery}
                members={members}
                selectedStep={selectedStep}
                onAddStep={onAddStep}
                onRemoveStep={onRemoveStep}
                onStepSelected={onStepSelected}
                carrierGroupId={carrierGroupId}
                onCompleteDraftStatus={onCompleteDraftStatus}
                getColorKeyByLocation={getColorKeyByLocation}
            />
        
            { 
                /* istanbul ignore next */
                plan.planSteps.length > 0 ?
                    <DeliveryStepsMap
                        steps={plan.planSteps}
                        getColorKeyByLocation={getColorKeyByLocation}
                        setMapViewState={setMapViewState}
                        mapViewState={mapViewState}
                        delivery={delivery}
                    /> : 
                        <LocationMapPanel
                            selectedLocation={delivery.pickupLocation}
                        />
            }
        </div>
    );
};

export default PlanDelivery;
