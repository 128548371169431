import {
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';
import { evalSteps } from './eval-steps';

export const buildOnDestinationSelected = (steps, delivery, onAddStep) => (event) => {
    
    const eventValue = event.target.value;
    if(eventValue === '')
    {
        return false;
    }

    /* istanbul ignore next */
    const isFinal = eventValue === 'final' ? true : false;
   
    const {
        lastStep
    } = evalSteps(steps);

    /* istanbul ignore next */
    let nextStep = {
        sortOrder: steps.length + 1,
        memberId: lastStep.memberId,
        type: isFinal ? CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP : CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
        from: lastStep.from,
        to: isFinal ? delivery.deliveryLocation : JSON.parse(eventValue)
    };

    onAddStep(nextStep);
};