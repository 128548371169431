import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../service/resize';
import CurrencyService from './../../../../service/currency';
import {
    CURRENCY_TYPE_USD
} from './../../../prompt/core/enum/currency-type';
import config from './../../../../config';
import { NavLink } from 'react-router-dom';
import theme from './../../../theme';

const JoinCopy = () => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getCompositeContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ compositeContainerStyle, setCompositeContainerStyle ] = useState( getCompositeContainerStyle() );

    const getPanelStyle = () => theme.getProfilePwdPanelStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setCompositeContainerStyle( getCompositeContainerStyle() );
        setPanelStyle( getPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={compositeContainerStyle}>
                <h2>
                    Subscription
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Pay for what you wish to keep.
                </h4>
            </div>
            <p style={panelStyle}>
                If we provide value to you then please consider a subscription.
                <br />
                <strong>
                    Subscribers are granted a lower online payment transaction fee.  
                </strong>
                &nbsp;
                For more details see our &nbsp;
                <NavLink style={{ ...theme.getGeneralTextLinkStyle(theme) }} to="/fees">
                    fees page.
                </NavLink>
                <br />
                    <h4>
                        <strong>
                            { 
                                CurrencyService.getDisplayPrice(
                                    CURRENCY_TYPE_USD,
                                    config.pay.subscriptionPrice
                                ) 
                            } 
                        </strong>
                        &nbsp;
                        per Month
                    </h4>
            </p>
        </section>
    );
};

export default JoinCopy;
