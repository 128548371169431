import CarrierMemberService from './../../../service/carrier-member';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';

class _UpdateCarrierMemberContactInfoPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _UpdateCarrierMemberContactInfoPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            contactEmail: {
                isValid: ValidateService.email
            },
            contactPhone: {
                isValid: ValidateService.phone
            }

        };
    }

    getFieldsModel()
    {
        return {
            contactEmail: {
                name: '*Public Email',
                error: 'You must specify an email point of contact',
                value: this.props.contactEmail,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            contactPhone: {
                name: '*Public Phone',
                error: 'You must specify a phone number',
                value: this.props.contactPhone,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            }
        };
    }

    getServerRequest()
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            contactEmail: this.state.contactEmail.value,
            contactPhone: this.state.contactPhone.value,
        };
    }

    onSendToServer()
    {
        CarrierMemberService.updateContactInfo({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        if(this.props.onCarrierMemberUpdated)
        {
            this.props.onCarrierMemberUpdated(response);
        }
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    getConfirmUi(confirmMessage = 'Contact Info Updated')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Save')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const UpdateCarrierMemberContactInfoPrompt = connect(mapStateToProps, mapDispatchToProps)(_UpdateCarrierMemberContactInfoPrompt);
export default UpdateCarrierMemberContactInfoPrompt;
