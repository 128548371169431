import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';

const EditProfileTrailsContainer = ({ children }) => {
    const getContainerStyle = () => theme.getEditProfileTrailsPromptStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section style={containerStyle}>
            { children }
            
        </section>
    );
};

export default EditProfileTrailsContainer;
