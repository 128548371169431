import React from 'react';
import ActorDetails from './actor-details';

const PaymentSentView = ({ exchange, onPaymentConfirmed }) => 
    <section>
        <div>
            <h2>
                Awaiting Payment Confirmation from 
                <ActorDetails 
                    usersDetails={exchange.usersDetails} 
                    actorId={exchange.actorId} 
                    amIActor={exchange.amIActor}
                />
            </h2>
            <p>
                Payment has been sent, once received the recipient must confirm to continue the exchange.
            </p>
        </div>
        {
            exchange.amIActor ? 
                <button 
                    className="test-exchangeDetailPaymentSentViewPaymentConfirmedBtn"
                    onClick={event => { onPaymentConfirmed() }}
                >
                    Payment Confirmed
                </button> : ''
        }
    </section>

export default PaymentSentView;
