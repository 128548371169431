import CurrencyTypeEnum from './../ui/prompt/core/enum/currency-type';

class CurrencyService 
{
    static getNoAccountCurrencyList()
    {
        return [
            'USD'
        ]
    }

    static base100(value, cent, dollar, currency, showCurrency)
    {
        let out = '';
        if(value < 100)
        {
            out = `${value} ${cent}`
        }
        else 
        {
            const strValue = value.toString();
            const insertPoint = strValue.length - 2;
            const formatValue = strValue.slice(0, insertPoint) + '.' + strValue.slice(insertPoint);
            out = `${dollar} ${formatValue}`;
        }
        return showCurrency ? `${out} ${currency}` : out;
    }

    static usd(value, showCurrency)
    {
        return this.base100(value, '¢', '$', 'USD', showCurrency);
    }

    static ars(value, showCurrency)
    {
        return this.base100(value, 'pesos', '$', 'ARS', showCurrency);
    }

    static aud(value, showCurrency)
    {
        return this.base100(value, 'c', '$', 'AUD', showCurrency);
    }

    static brl(value, showCurrency)
    {
        return this.base100(value, 'centavos', 'R$', 'BRL', showCurrency);
    }

    static cad(value, showCurrency)
    {
        return this.base100(value, '¢', '$', 'CAD', showCurrency);
    }

    static clp(value, showCurrency)
    {
        return this.base100(value, 'pesos', '$', 'CLP', showCurrency);
    }

    static gbp(value, showCurrency)
    {
        return this.base100(value, 'p', '£', 'GBP', showCurrency);
    }

    static hkd(value, showCurrency)
    {
        return this.base100(value, '¢', 'HK$', 'HKD', showCurrency);
    }

    static inr(value, showCurrency)
    {
        return this.base100(value, 'paise', '₹', 'INR', showCurrency);
    }

    static krw(value, showCurrency)
    {
        return this.base100(value, 'jeon', '₩', 'KRW', showCurrency);
    }

    static mxn(value, showCurrency)
    {
        return this.base100(value, '¢', '$', 'MXN', showCurrency);
    }

    static nzd(value, showCurrency)
    {
        return this.base100(value, 'c', '$', 'NZD', showCurrency);
    }

    static sgd(value, showCurrency)
    {
        return this.base100(value, 'c', '$', 'SGD', showCurrency);
    }

    static getDisplayPrice(currency, value, showCurrency = true)
    {
        switch(currency)
        {
            case CurrencyTypeEnum.CURRENCY_TYPE_USD:
                return this.usd(value, showCurrency);
           
            case CurrencyTypeEnum.CURRENCY_TYPE_AUD:
                return this.aud(value, showCurrency);

            case CurrencyTypeEnum.CURRENCY_TYPE_CAD:
                return this.cad(value, showCurrency);

            case CurrencyTypeEnum.CURRENCY_TYPE_GBP:
                return this.gbp(value, showCurrency);

            case CurrencyTypeEnum.CURRENCY_TYPE_MXN:
                return this.mxn(value, showCurrency);

            default:
                return this.usd(value, showCurrency);
        }
    }
}

export default CurrencyService;
