import React from 'react';
import theme from './../../theme';
import LocationService from './../../../service/location';
import LocationMapPanel from './../location-map';

const PickupPanel = ({ location }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)  
    }}>
        <h3>
            Pickup Details
        </h3>
        <p>
            Make sure to chat with each other and agree on a specific pick up time.
        </p>

        <div style={{
          ...theme.getLocationMailingAddressStyle(theme)  
        }}>
            <p>
                {
                    LocationService.displayMailingAddress(location)
                }
            </p>
        </div>

        <LocationMapPanel selectedLocation={location} />
    </div>

export default PickupPanel;
