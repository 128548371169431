import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminFailedGeoLocationsPanel from './../ui/panel/admin/admin-failed-geo-locations';
import theme from './../ui/theme';

const AdminFailedGeoLocationsPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            Failed GeoLocation Jobs
        </h2>
        <AdminFailedGeoLocationsPanel />
    </AdminPageTemplate>

export default AdminFailedGeoLocationsPage;
