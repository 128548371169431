import React from 'react';
import PayService from './../../../service/pay';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import TermsAndConditionsPanel from './../../panel/tandc';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import theme from '../../theme';
import Dialog from '@mui/material/Dialog';


class _CreatePayAccountPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _CreatePayAccountPrompt.getValidators());
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onDialogClose = this.onDialogClose.bind(this);
        this.onDialogOpen = this.onDialogOpen.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            accepted: {
                isValid: ValidateService.isTrue
            },
        };
    }

    /* istanbul ignore next */
    onAuthError()
    {
        this.props.onLogout();
    }

    /* istanbul ignore next */
    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            accepted: {
                name: 'I have read the terms of service',
                customField: <span>I have read and agree to <span className="test-createPayAccountPromptTosBtn" style={{ ...theme.generaLinkStyle(theme) }} onClick={event => { this.onDialogOpen() }}>terms of service</span>*</span>,
                error: 'Specify if you wish to make online payments with this account',
                disabled: false,
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            dialogIsOpen: false,
        };
    }

    /* istanbul ignore next */
    onDialogOpen()
    {
        const nextState = {
            ...this.state,
            dialogIsOpen: true
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onDialogClose()
    {
        const nextState = {
            ...this.state,
            dialogIsOpen: false
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        PayService.createAccount({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            
        };
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Redirecting...')
    {
        return super.getConfirmUi(confirmMessage);
    }

    /* istanbul ignore next */
    getSubmitButtonUi(buttonText = 'Setup Online Payments')
    {
        return (
            <div style={{
                ...theme.getAbstractPromptStyle(theme),
            }}>
                { super.getSubmitButtonUi(buttonText) }
            </div>
        );
    }

    /* istanbul ignore next */
    onServerResponse(response)
    {
        if(this.props.onAccountCreated)
        {
            this.props.onAccountCreated(response);
        }
    }

    getPromptUi()
    {
        return (
            <div>
                <form onSubmit={this.onFormSubmit}>
                    { this.getServerFeedbackUi() }
                    { this.getFormFieldsUi() }
                    { this.getSubmitButtonUi() }
                </form>
                <Dialog
                    onClose={ 
                        /* istanbul ignore next */
                        event => { this.onDialogClose() }
                    }
                    open={this.state.dialogIsOpen}
                >
                    <TermsAndConditionsPanel />
                </Dialog>
            </div>
        );
    }
}

const CreatePayAccountPrompt = connect(mapStateToProps, mapDispatchToProps)(_CreatePayAccountPrompt);
export default CreatePayAccountPrompt;
