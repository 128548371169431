import React from 'react';
import AuthPageTemplate from './template/auth-page';
import PostsLandingPanel from './../ui/panel/posts-landing';
import PostsMenu from './../ui/menu/posts';

const PostsPage = () => 
    <AuthPageTemplate childMenu={ <PostsMenu /> }>
        <PostsLandingPanel />
    </AuthPageTemplate>

export default PostsPage;
