import React from 'react';
import {
    PaymentElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import config from './../../../config';
import menuDetails from '../../../store/select/menu-details';
import { useSelector } from 'react-redux';

// istanbul ignore next
const ActiveView = ({ clientSecret }) => {

  const stripe = useStripe();
  const elements = useElements();
  const [ message, setMessage ] = useState('');
  const [ isSubmit, setIsSubmit ] = useState(false);
  const [ formEnabled, setFormEnabled ] = useState(true);
  const { showFullMenu } = useSelector(menuDetails);
  
  const onSubmit = async (event) => {
    try
    {
      event.preventDefault();
  
      if(!stripe || !elements)
      {
        return;
      }


      setFormEnabled(false);
      setIsSubmit(true);

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: config.pay.paymentMethodAddedUrl
        }
      });

      if(result.error)
      {
        const errorMessage = result.error.message ? result.error.message : 'An Error Has Occurred';
        setMessage(errorMessage);
      }
      else
      {
        setMessage('Added');
      }

      setFormEnabled(true);
    }
    catch(err)
    {
      setMessage('Error');
      setFormEnabled(true);
    }
  };

  
  useEffect(() => {
    
    if(!stripe || !elements)
    {
      return;
    }


    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {

      switch (setupIntent.status) {
        case "succeeded":
          setMessage("Card Saved");
          break;
        case "processing":
          setMessage("Saving");
          break;
        case "requires_payment_method":
          if(isSubmit)
          {
            setMessage('Processing');
            const message = 'Card save unsuccessful, please try again';
            setTimeout(() => {
              setMessage(message);
            }, config.pay.submitPaymentMethodFeedbackDelay);
          }
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }

    });
    
  }, [stripe, clientSecret, elements, isSubmit]);
    

  return (
    <div style={{
        ...theme.getAddPayMethodFormContainerStyle(theme),
    }}>
        {
          message ? 
          <p>
            { message }
          </p> : ''
        }
        {
          showFullMenu && 
          <form onSubmit={(event) => { onSubmit(event) }}>
              <PaymentElement />
              <button disabled={!formEnabled}>
                  Save
              </button>
          </form>
        }
    </div>
  );

};

// istanbul ignore next
const AddPayMethodViewPanel = ({ data }) => {
  const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
  const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
  /* istanbul ignore next */
  const resizeEffect = ResizeService.buildOnResize(() => {
    setBodyStyle( getBodyStyle() );
  });
  useEffect(resizeEffect);
  return (
    <div style={{
      ...theme.getAbstractBottomMarginStyle(theme)
    }}>
      <h2>
        Store a Payment Method
      </h2>
      <div style={bodyStyle}>
        <p>
          You acknowledge and agree that to confirm the validity of the credit card information you provide 
          Bears.Exchange will request a temporary authorization hold for a nominal amount on your card. 
          This authorization hold does not result in actual charges to your card. 
          This authorization will automatically expire based on guidelines established by your card issuer.
        </p>
        <ActiveView clientSecret={data.clientSecret} /> 
      </div>
    </div>
  );
};

export default AddPayMethodViewPanel;
