import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import Dialog from '@mui/material/Dialog';

const LargeImageView = ({ 
    image,
    setToNextImage,
    setToLastImage,
    hasManyImages
}) => {
    const getImgViewStyle = () => theme.getLargeImgViewStyle(theme);
    const [ imgViewStyle, setImgViewStyle ] = useState( getImgViewStyle() );

    const getImgViewContainerStyle = () => theme.getLargeImgViewContainerStyle(theme);
    const [ imgViewContainerStyle, setImgViewContainerStyle ] = useState( getImgViewContainerStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setImgViewStyle(  getImgViewStyle() );
        setImgViewContainerStyle( getImgViewContainerStyle() );
    });
    useEffect(resizeEffect);

    return (
        <div>
            <div style={imgViewContainerStyle}>
                {
                    image && 
                    <img 
                        src={image.previewImage ? image.previewImage : image.data } 
                        alt="File Missing" 
                        style={imgViewStyle}
                    />
                }
            </div>
            {
                hasManyImages &&
                    <div>
                        <button 
                            className="test-imgLastBtn"
                            onClick={event => { setToLastImage() }}
                        >
                            Last
                        </button>
                        <button 
                            className="test-imgNextBtn"
                            onClick={event => { setToNextImage() }}
                        >
                            Next
                        </button>
                    </div>
            }
        </div>
    );
};

const ImgView = ({ 
    image,
    viewLarge 
}) => {
    const getImgViewStyle = () => theme.getImgViewStyle(theme);
    const [ imgViewStyle, setImgViewStyle ] = useState( getImgViewStyle() );

    const getImgViewContainerStyle = () => theme.getImgViewContainerStyle(theme);
    const [ imgViewContainerStyle, setImgViewContainerStyle ] = useState(getImgViewContainerStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setImgViewStyle( getImgViewStyle() );
        setImgViewContainerStyle( getImgViewContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div 
            style={imgViewContainerStyle}
            className="test-imgViewLargeBtn"
            onClick={event => { viewLarge() }}
        >
            <img 
                src={image.previewImage ? image.previewImage : image.data } 
                alt="File Missing" 
                style={imgViewStyle}
            />
            
        </div>
    );
};

const ImgPanel = ({ imgList }) => {
    const getAbstractImageFlexStyle = () => theme.getAbstractImageFlexStyle(theme);
    const [ abstractImageFlexStyle, setAbstractImageFlexStyle ] = useState( getAbstractImageFlexStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setAbstractImageFlexStyle(getAbstractImageFlexStyle());
    });
    useEffect(resizeEffect);
    
    const [ selectedImageIndex, setSelectedImageIndex ] = useState(null);
    const isLargeViewOpen = () => selectedImageIndex !== null;
    const hasManyImages = imgList.length > 1 ? true : false;
    
    /* istanbul ignore next */
    const setToNextImage = () => {
        if(isLargeViewOpen() && hasManyImages)
        {
            let nextIndex = selectedImageIndex + 1;
            nextIndex = nextIndex >= imgList.length ? 0 : nextIndex;
            setSelectedImageIndex(nextIndex);
        }
    };

    /* istanbul ignore next */
    const setToLastImage = () => {
        if(isLargeViewOpen() && hasManyImages)
        {
            let nextIndex = selectedImageIndex - 1;
            nextIndex = nextIndex < 0 ? (imgList.length - 1) : nextIndex;
            setSelectedImageIndex(nextIndex);
        }
    };
    
    return (
        <section style={abstractImageFlexStyle}>
            { 
                imgList.map((img, index) => 
                    <ImgView 
                        image={img} 
                        key={index} 
                        viewLarge={() => { setSelectedImageIndex(index) }}
                    />
                ) 
            }

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setSelectedImageIndex(null) }
                }
                open={imgList[selectedImageIndex] ? true : false}
            >
                <LargeImageView 
                    image={imgList[selectedImageIndex]} 
                    setToNextImage={setToNextImage}
                    setToLastImage={setToLastImage}
                    hasManyImages={hasManyImages}
                />
            </Dialog>

        </section>
    );
};

export default ImgPanel;