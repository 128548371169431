export const getForgotPasswordStyle = (theme) => ({
    marginTop: '15px',
});

export const getLoginPromptStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: theme.isTablet() ? '60rem' : 'calc(100% - 1.5rem)',
    marginBottom: '2rem',
    textAlign: 'center',
});

export const getForgotPwdPromptStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: '450px',
});

export const getLoginEmailFieldStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.isTablet() ? '26rem' : 'calc(100% - 1.5rem)',
    width: '100%',
    paddingLeft: '1rem',
    height: '2.25rem',
    marginBottom: '2rem',
});