export const getTermsAndConditionsStyle = (theme) => ({
    textAlign: 'justified',
    fontFamily: 'Courier New',
    paddingLeft: '20px',
    paddingRight: '20px',
    whiteSpace: 'pre-wrap',
});

export const getTermsAndConditionsTabContainerStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem'
});