export const DELIVERY_CARRIER_TYPE_UNASSIGNED = 'unassigned';
export const DELIVERY_CARRIER_TYPE_SELF = 'self';
export const DELIVERY_CARRIER_TYPE_USPS = 'usps';
export const DELIVERY_CARRIER_TYPE_FEDEX = 'fedex';
export const DELIVERY_CARRIER_TYPE_DHL = 'dhl';
export const DELIVERY_CARRIER_TYPE_UPS = 'ups';
export const DELIVERY_CARRIER_TYPE_OTHER = 'other';

const validValues = [
    DELIVERY_CARRIER_TYPE_UNASSIGNED,
    DELIVERY_CARRIER_TYPE_SELF,
    DELIVERY_CARRIER_TYPE_USPS,
    DELIVERY_CARRIER_TYPE_FEDEX,
    DELIVERY_CARRIER_TYPE_DHL,
    DELIVERY_CARRIER_TYPE_UPS,
    DELIVERY_CARRIER_TYPE_OTHER
];

const validSentAssignCarrierTypeValues = [
    DELIVERY_CARRIER_TYPE_USPS,
    DELIVERY_CARRIER_TYPE_FEDEX,
    DELIVERY_CARRIER_TYPE_DHL,
    DELIVERY_CARRIER_TYPE_UPS,
    DELIVERY_CARRIER_TYPE_OTHER
];

const isValidSentPackageAssignDeliveryCarrierType = value => validSentAssignCarrierTypeValues.includes(value);

const getSentPackageAssignDeliveryCarrierTypeDropDown = () => [
    {
        name: 'Unassigned',
        value: DELIVERY_CARRIER_TYPE_UNASSIGNED
    },
    {
        name: 'DHL',
        value: DELIVERY_CARRIER_TYPE_DHL
    },
    {
        name: 'FedEx',
        value: DELIVERY_CARRIER_TYPE_FEDEX
    },
    {
        name: 'UPS',
        value: DELIVERY_CARRIER_TYPE_UPS
    },
    {
        name: 'US Postal',
        value: DELIVERY_CARRIER_TYPE_USPS
    },
    {
        name: 'Other',
        value: DELIVERY_CARRIER_TYPE_OTHER
    }
];

const isValid = value => validValues.includes(value);

const DeliveryCarrierTypeEnum = {
    DELIVERY_CARRIER_TYPE_UNASSIGNED,
    DELIVERY_CARRIER_TYPE_SELF,
    DELIVERY_CARRIER_TYPE_USPS,
    DELIVERY_CARRIER_TYPE_FEDEX,
    DELIVERY_CARRIER_TYPE_DHL,
    DELIVERY_CARRIER_TYPE_UPS,
    DELIVERY_CARRIER_TYPE_OTHER,
    isValid,
    getSentPackageAssignDeliveryCarrierTypeDropDown,
    isValidSentPackageAssignDeliveryCarrierType
};

export default DeliveryCarrierTypeEnum;
