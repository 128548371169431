import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class TrailService {

    static getAddTrailToUserRequest(trailId, trailUsername) {
        return {
            trailId,
            trailUsername
        };
    }

    /* istanbul ignore next */
    static addTrailToUser({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.trail.addTrail,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveTrailFromUserRequest(trailId) {
        return {
            trailId
        };
    }

    /* istanbul ignore next */
    static removeTrailFromUser({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.trail.removeTrail,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSearchRequest(searchText = '', page = 0) {
        return {
            searchText,
            page
        };
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.trail.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddTrailRequest(title, url, details, linksToUsers = false) {
        return {
            title,
            url,
            details,
            linksToUsers
        };
    }

    /* istanbul ignore next */
    static addTrail({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.trail.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdateTrailRequest(id, title, url, details, linksToUsers = false) {
        return {
            id, 
            title,
            url,
            details,
            linksToUsers
        };
    }

    /* istanbul ignore next */
    static updateTrail({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.trail.update,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getRemoveTrailRequest(id) {
        return {
            id
        };
    }

    /* istanbul ignore next */
    static removeTrail({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.trail.remove,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default TrailService;