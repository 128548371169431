import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import ProfileInfoPanel from './../ui/panel/profile-info';

const ProfileInfoPage = () => {
    return (
        <AuthPageTemplate 
            childMenu={
                <ProfileMenu />
        }>
            <ProfileInfoPanel />
        </AuthPageTemplate>
    );
};

export default ProfileInfoPage;
