import DetailPanel from './../core/detail';
import CarrierGroupTripService from './../../../service/carrier-group-trip';
import CarrierGroupUserTripsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _CarrierGroupUserTripsPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onTripAdded = this.onTripAdded.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierGroupTripService.listUserTrips({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadDataResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: response
        };
        this.setState(nextState);
    }

    /* istanbul ignore next*/
    onTripAdded(response)
    {
        this.onLoadDataResponse(response);
    }

    onLoadSpecifiedPage(page)
    {
        CarrierGroupTripService.listUserTrips({
            request: {
                carrierGroupId: this.props.carrierGroupId,
                page
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.loadData({
            page: 0
        });
    }

    getLoadedDataDetailUi()
    {
        return <CarrierGroupUserTripsViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            onTripAdded={this.onTripAdded}
        />
    }
}

const CarrierGroupUserTripsPanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierGroupUserTripsPanel);
export default CarrierGroupUserTripsPanel;
