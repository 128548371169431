import { 
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';


export const getStepCopy = (step, getColorKeyByLocation) => {
    switch(step.type)
    {
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP:
            return (
                <span>
                    <span>
                        Pick up at
                    </span>
                    
                    <span style={{
                        color: getColorKeyByLocation ? `#${getColorKeyByLocation(step.from).color}` : undefined
                    }}>
                        {step.from.mailingAddress}
                    </span>
                    
                </span>
            );

        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF:
            return (
                <span>
                    <span>
                        Drop off at 
                    </span>
                    <span style={{
                        color: getColorKeyByLocation ? `#${getColorKeyByLocation(step.to).color}` : undefined
                    }}>
                        {step.to.mailingAddress}
                    </span>
                    
                </span>
            );


        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT:
            return (
                <span>
                    <span>
                        Sort for pickup at
                    </span>
                    <span style={{
                        color: getColorKeyByLocation ? `#${getColorKeyByLocation(step.to).color}` : undefined
                    }}>
                        {step.to.mailingAddress}
                    </span>
                    
                </span>
            );

        default: 
            return '';
    }
};

