import React from 'react';
import { useState, useEffect } from 'react';
import theme from '../../theme';
import ResizeService from './../../../service/resize';

const RefundPolicyPrompt = ({
    onSetRefundPolicy, 
    hasRefundPolicy, 
    refundPolicyDetails, 
    saveRefundPolicy,
    showSaveRefundPrompt
}) => {
    const getConStyle = () => theme.getRefundPolicyCheckFieldText(theme);
    const [ conStyle, setConStyle ] = useState( getConStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setConStyle( getConStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getRefundPolicyContainerStyle(theme)
        }}>
            <h2>
                Specify your Refund Policy
            </h2>

            <div style={{
                ...theme.getRefundPolicyCheckFieldContainer(theme)
            }}>
                <div style={conStyle}>
                    <input 
                        type="checkbox" 
                        className="test-coreRefundPolicyCb"
                        onChange={event => {
                            onSetRefundPolicy(event.target.checked, refundPolicyDetails, saveRefundPolicy);
                        }} 
                        checked={hasRefundPolicy} 
                    /> 
                    <span>
                        <h4>
                            Refund Policy Offered
                        </h4>
                    </span>
                </div>
                {
                    hasRefundPolicy && 
                        <div style={{
                            ...theme.getRefundPolicyExplanationContainerStyle(theme)
                        }}>
                            <p>
                                Please explain your refund policy
                            </p>
                            <textarea 
                                value={refundPolicyDetails}
                                className="test-coreRefundPolicyTxt"
                                style={{
                                    ...theme.getRefundPolicyTextAreaField(theme)
                                }}
                                onChange={event => {
                                    onSetRefundPolicy(hasRefundPolicy, event.target.value, saveRefundPolicy);
                                }}
                            ></textarea>
                            {
                                showSaveRefundPrompt && refundPolicyDetails !== '' && 
                                    <div>
                                        <input type="checkbox" onChange={event => {
                                            onSetRefundPolicy(hasRefundPolicy, refundPolicyDetails, event.target.checked);
                                        }} checked={saveRefundPolicy} />
                                        <span>
                                            Save Refund Details For Re-use
                                        </span>
                                    </div>
                            }
                        </div>
                }
                
            </div>
        </div>
    );
};

export default RefundPolicyPrompt;
