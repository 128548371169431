import {
    LOGIN,
    AUTH_UPDATE,
    LOGOUT,
    UPDATE_NOTICE_COUNT,
    UPDATE_ERROR,
    UPDATE_MENU,
    UPDATE_CLIENT_SETTINGS,
    UPDATE_METRICS,
    UPDATE_SPEED_VIEW,
    UPDATE_AUTO_SPEED_VIEW,
    UPDATE_SPEED_VIEW_PROMPT_VISIBLE,
    UPDATE_USER_CLOSED_SPEED_VIEW_PROMPT,
    UPDATE_ONBOARDING_VISIBLE,
    UPDATE_ONBOARDING_CRAWLER,
    UPDATE_DEVICE_ONLINE
} from './../constants';

import INI_STATE from './../initial';

const AuthReducer = (state = INI_STATE, action) => {
    switch(action.type) {
        case LOGIN:
            return {
                ...state,
                authToken: action.authToken,
                userId: action.userId,
                isAdmin: action.isAdmin,
                unseenNoticeCount: action.unseenNoticeCount,
                clientSettings: action.clientSettings,
            };

        case UPDATE_DEVICE_ONLINE:
            return {
                ...state,
                deviceIsOnline: action.deviceIsOnline
            };

        case UPDATE_METRICS:
            return {
                ...state,
                metrics: action.metrics
            };

        case UPDATE_CLIENT_SETTINGS:
            return {
                ...state,
                clientSettings: action.clientSettings,
            };

        case UPDATE_MENU:
            return {
                ...state,
                showFullMenu: action.showFullMenu
            };

        case AUTH_UPDATE:
            return {
                ...state,
                authToken: action.authToken
            };

        case UPDATE_NOTICE_COUNT:
            return {
                ...state,
                unseenNoticeCount: action.unseenNoticeCount
            };
        
        case LOGOUT:
            return INI_STATE;

        case UPDATE_ERROR:
            return {
                ...state,
                displayError: action.displayError,
                errorMessage: action.errorMessage,
            };

        case UPDATE_SPEED_VIEW:
            return {
                ...state,
                customView: {
                    ...state.customView,
                    slowSpeedViewEnabled: action.slowSpeedViewEnabled
                }
            };

        case UPDATE_AUTO_SPEED_VIEW:
            return {
                ...state,
                customView: {
                    ...state.customView,
                    autoAdjustSpeedViewEnabled: action.autoAdjustSpeedViewEnabled
                }
            };

        case UPDATE_SPEED_VIEW_PROMPT_VISIBLE:
            return {
                ...state,
                customView: {
                    ...state.customView,
                    showSpeedViewPrompt: action.showSpeedViewPrompt
                }
            };

        case UPDATE_USER_CLOSED_SPEED_VIEW_PROMPT:
            return {
                ...state,
                customView: {
                    ...state.customView,
                    showSpeedViewPrompt: action.showSpeedViewPrompt,
                    userHasClosedSpeedViewPrompt: action.showSpeedViewPrompt ? 
                        state.customView.userHasClosedSpeedViewPrompt : 
                        action.userHasClosedSpeedViewPrompt
                }
            };

        case UPDATE_ONBOARDING_VISIBLE:
            return {
                ...state,
                onboarding: {
                    showOnboarding: action.showOnboarding,
                    onboardingCrawler: action.onboardingCrawler
                }
            };

        case UPDATE_ONBOARDING_CRAWLER: 
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    onboardingCrawler: action.onboardingCrawler
                }
            };

        default:
            return state;
    }
};

export default AuthReducer;