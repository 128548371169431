import React from 'react';
import DetailPanel from './../../../panel/core/detail';
import TagService from './../../../../service/tag';
import TagListViewPrompt from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';

class _TagListPrompt extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            tagList: this.props.tagList
        };
    }

    loadData()
    {
        super.loadData({ });
        TagService.list({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    static getDerivedStateFromProps(nextProps, prevState)
    {
        if(nextProps.tagList !== prevState.tagList)
        {
            return { tagList: nextProps.tagList };
        }
        else 
        {
            return null;
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.tagList !== this.props.tagList)
        {
            const nextState = {
                ...this.state,
                tagList: this.props.tagList
            };
            this.setState(nextState);
        }
    }

    componentDidMount()
    {
        this.loadData();
    }

    getLoadedDataDetailUi()
    {
        return <TagListViewPrompt 
            data={this.state.data} 
            tagList={this.state.tagList}
            onAddTag={this.props.onAddTag}
            onRemoveTag={this.props.onRemoveTag}
        />
    }
}

const TagListPrompt = connect(mapStateToProps, mapDispatchToProps)(_TagListPrompt);
export default TagListPrompt;
