import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';
import endpointFileUpload from './endpoint-file-upload';


class SeekService {

    static getRetiredSeekDetailsRequest(seekId)
    {
        return {
            seekId
        };
    }

    /* istanbul ignore next */ 
    static getRetiredSeekDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.seek.retired,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSeekDetailsRequest(seekId) {
        return {
            seekId
        };
    }

    /* istanbul ignore next */
    static getSeekDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.seek.details,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getUserListingsRequest(userId, page = 0) {
        return {
            detailsUserId: userId,
            page,
        };
    }

    /* istanbul ignore next */
    static getUserListings({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.listings.seekListings,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getAddSeekRequest(title, details, rules, savedImgList) {
        return {
            title,
            details,
            rules,
            savedImgList
        };
    }

    /* istanbul ignore next */
    static addSeek({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointFileUpload({
            url: config.url.seek.add,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getArchiveSeekRequest(seekId) {
        return {
            seekId
        };
    }

    /* istanbul ignore next */
    static archiveSeek({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.seek.archive,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdateSeekRequest(seekId, title, details, rules) {
        return {
            seekId,
            title,
            details,
            rules
        }
    }

    /* istanbul ignore next */
    static updateSeek({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.seek.update,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSearchRequest(searchText = '', page = 0) {
        return {
            searchText,
            page
        };
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.seek.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
            debounce: true
        });
    }

    static getArchivesRequest(page = 0)
    {
        return {
            page,
        };
    }
    
    /* istanbul ignore next */
    static getArchives({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.archives.seeks,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken

        });
    }
    
    static getSetupAddRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static setupAdd({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) 
    {
        endpointRequest({
            url: config.url.seek.setupAdd,
            request: this.getSetupAddRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

}

export default SeekService;
