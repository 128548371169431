import { 
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';

export const evalSteps = steps => {

    const isFirstStep = steps.length < 1 ? true : false;
    const lastStep = !isFirstStep ? steps[steps.length - 1] : null;
    const lastStepType = !isFirstStep ? lastStep.type : null;
    const shouldPromptStep = lastStepType !== CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP ? true : false;

    const isNextADropStep = [ 
        CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
        CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
    ].includes(lastStepType);

    const isNextStepAPickUp = [
        CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT
    ].includes(lastStepType);

    const isNextASortStep = [
        CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF
    ].includes(lastStepType);

    const shouldPromptSelectMember = isFirstStep || isNextASortStep || isNextStepAPickUp ? true : false;
    
    const shouldPromptSelectDestination = isNextADropStep ? true : false;

    return {
        isFirstStep,
        lastStep,
        shouldPromptStep,
        isNextADropStep,
        isNextASortStep,
        isNextStepAPickUp,
        shouldPromptSelectMember,
        shouldPromptSelectDestination
    };
};