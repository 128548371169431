import React from 'react';
import { useState } from 'react';
import theme from './../../../theme';
import SearchUserSystemsPrompt from './../../../prompt/admin/search-user-systems';
import AdminUserReportedHistoryPrompt from './../../../prompt/admin/admin-user-reported-history';
import AdminSearchFinePrompt from './../../../prompt/admin/admin-search-fine';
import AdminUserPermissionsPanel from './../admin-user-permissions';

const AdminUserDetailViewPanel = ({ data, onToggleAccountAccess, onAddInvite, viewingUserId }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [inviteOpen, setInviteOpen] = useState(false);
    const [flagOpen, setFlagOpen] = useState(false);
    const [systemHistoryOpen, setSystemHistoryOpen] = useState(false);
    const [reportedIssuesHistoryOpen, setReportedIssuesHistoryOpen] = useState(false);
    const [fineHistoryOpen, setFineHistoryOpen] = useState(false);
    const [permissionsOpen, setPermissionsOpen] = useState(false);
    /* istanbul ignore next */
    const flagView = (flag, index) => {
        return (
            <li key={index}>
                <span style={{
                    ...theme.getAdminUserDetailFlagViewStyle(theme, flag),
                }}>
                    { `${flag.flaggingReason} ${flag.flaggedContentType} ` }
                    { flag.reviewed ? ( flag.restored ? 'Content Restored ' : 'Confirmed Flagging ' ) : 'Awaiting Review ' }
                    { flag.updatedOn }
                </span>
                <hr />
            </li>
        );
    }

    const flaggingHistoryView = () => 
        <section style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h4>
                Flagging History
            </h4>
            <ul>
                { data.flaggingHistory.list.map((flag, index) => flagView(flag, index)) }
            </ul>
        </section>

    const ControlPanel = () => {
        const list = [
            {
                openFlag: inviteOpen,
                setOpenFlag: setInviteOpen,
                closedText: 'Close Invites',
                openText: 'Invites'
            },
            {
                openFlag: flagOpen,
                setOpenFlag: setFlagOpen,
                closedText: 'Close Flagging History',
                openText: 'Flagging History'
            },
            {
                openFlag: systemHistoryOpen,
                setOpenFlag: setSystemHistoryOpen,
                closedText: 'Close System History',
                openText: 'System History'
            },
            {
                openFlag: reportedIssuesHistoryOpen,
                setOpenFlag: setReportedIssuesHistoryOpen,
                closedText: 'Close Reported Issues History',
                openText: 'Reported Issues History'
            },
            {
                openFlag: fineHistoryOpen,
                setOpenFlag: setFineHistoryOpen,
                closedText: 'Close Fine History',
                openText: 'Fine History'
            },
            {
                openFlag: permissionsOpen,
                setOpenFlag: setPermissionsOpen,
                closedText: 'Close Permissions',
                openText: 'Permissions'
            }
        ];
        return (
            <section>
                <button 
                    className="test-adminUserDetailCloseBtn"
                    style={{
                        ...theme.getAdminUserDetailControlPanelButtonStyle(theme),
                    }}
                    onClick={event => { setIsOpen(false) }}
                >
                    Close
                </button>

                {
                    data.restrictedUserDetails.loginEnabled ? 
                        <button 
                            style={{
                                ...theme.getAdminUserDetailControlPanelButtonStyle(theme),
                            }}
                            className="test-adminUserDetailDisableAccountBtn"
                            onClick={ event => { onToggleAccountAccess(false) }}>
                            Disable Account
                        </button> : 
                        <button 
                            style={{
                                ...theme.getAdminUserDetailControlPanelButtonStyle(theme),
                            }}
                            className="test-adminUserDetailEnableAccountBtn"
                            onClick={ event => { onToggleAccountAccess(true) }}>
                            Enable Account
                        </button>
                }

                {
                    list.map((tab, index) => <button key={index} 
                        style={{
                            ...theme.getAdminUserDetailControlPanelButtonStyle(theme),
                        }}
                        className="test-adminUserDetailTabBtn"
                        onClick={event => { tab.setOpenFlag(!tab.openFlag) }}
                    >
                        { tab.openFlag ? tab.closedText : tab.openText }
                    </button>
                    )
                }

            </section>
        );
    };

    const invitePromptView = () => 
        <section>
            <button 
                className="test-adminUserDetailIssueInviteBtn"
                onClick={event => { onAddInvite() }}
            >
                Issue Invite
            </button>
        </section>

    const openView = () => 
        <section>
            <ul>
                { data.restrictedUserDetails.admin ? <li>Admin Account</li> : '' }
                <li>
                    { data.restrictedUserDetails.loginEnabled ? 'Login Enabled' : 'Login Disabled' }
                </li>
            </ul>
            <ControlPanel />
            { systemHistoryOpen ? <SearchUserSystemsPrompt userId={viewingUserId} /> : '' }
            { inviteOpen ? invitePromptView() : '' }
            { flagOpen ? ( data.flaggingHistory.total > 0 ? flaggingHistoryView() : <div style={{ ...theme.getGeneralTextStyle(theme) }}>No Flagging History</div> ) : '' }
            { reportedIssuesHistoryOpen ? <AdminUserReportedHistoryPrompt userId={viewingUserId} /> : '' }
            { fineHistoryOpen ? <AdminSearchFinePrompt userId={viewingUserId} /> : '' }
            { permissionsOpen ? <AdminUserPermissionsPanel userId={viewingUserId} /> : '' }
        </section>

    const closedView = () => 
        <section>
            <button 
                className="test-adminUserDetailAdminBtn"
                onClick={event => { setIsOpen(true) }}
            >
                Admin
            </button>
        </section>


    return isOpen ? openView() : closedView();
};

export default AdminUserDetailViewPanel;
