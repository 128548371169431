import React from 'react';
import { 
    getStepCopy,
} from './../core';
import theme from './../../../theme';
import HookedTrip from './hooked-trip';
import SelectTrip from './select-trip';

const TripHook = ({
    step,
    carrierGroupId,
    carryingCargo,
    onAddTrip,
    delivery,
    cargoItem,
    acceptingCargo,
    foundTrip,
    shouldPromptUnHooked,
    onHookTrip,
    shouldPromptRemoveHook,
    removeCargoFromCarrierGroupTrip
}) => {

    return (
        <div style={{
            display: 'flex'
        }}>
            <div style={{
                ...theme.getLocationMailingAddressStyle(theme),
                flexDirection: 'column'
            }}>
                { getStepCopy(step) }
            </div>

            <div style={{
                flexDirection: 'column'
            }}>
                {
                    foundTrip ? 
                        <HookedTrip 
                            trip={foundTrip}
                            shouldPromptRemoveHook={shouldPromptRemoveHook}
                            removeCargoFromCarrierGroupTrip={removeCargoFromCarrierGroupTrip}
                        /> : (
                            shouldPromptUnHooked ? 
                                <SelectTrip
                                    carryingCargo={carryingCargo}
                                    selectedMemberId={step.memberId}
                                    onAddTrip={onAddTrip}
                                    carrierGroupId={carrierGroupId}
                                    cargoItem={cargoItem}
                                    delivery={delivery}
                                    step={step}
                                    acceptingCargo={acceptingCargo}
                                    onHookTrip={onHookTrip}
                                /> : ''
                        )
                }
            </div>
        </div>
    );
};

export default TripHook;
