import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { UpdateError } from './../../../store/state/action';
import ErrorImg from './../../../img/sitedownFire.png'
import theme from './../../theme';

const ErrorPanel = ({ errorMessage = '' }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onContinue = () => {
        dispatch(UpdateError(false, ''));
    };
    const onReport = () => {
        dispatch(UpdateError(false, ''));
        history.push('/profile/report/issue');
    };
    return (
        <section>
            <h2 style={{ 
                ...theme.getGeneralTextStyle(theme),
            }}>
                An Error has occurred.
            </h2>
            <p>
                { errorMessage }
            </p>
            <div
                style={{
                    ...theme.getErrorImageBlockStyle(theme, ErrorImg),
                }}
            ></div>
            <div style={{
                    ...theme.getErrorButtonsContainerStyle(theme),
            }}>
                <button 
                    className="test-errorPanelContinueBtn"
                    onClick={event => { onContinue() }}
                >
                    Continue
                </button>
                <button
                    style={{
                        ...theme.getErrorButtonReportIssueStyle(theme),
                    }}
                    className="test-errorPanelReportBtn"
                    onClick={event => { onReport() }}
                >
                    Report Issue
                </button>
            </div>
        </section>
    );
};

export default ErrorPanel;
