import config from './../../../config';

const getSmallMenuListData = (loggedIn = false, showAdminOptions = false) => {
    if(!loggedIn)
    {
        return [
            {
                title: 'Dashboard',
                to: '/'
            },
            {
                title: 'Join',
                to: '/join'
            },
            {
                title: 'Terms Of Service',
                to: '/terms'
            },
            {
                title: 'Forgot Password',
                to: '/forgot'
            }
        ];
    }
    
    /* istanbul ignore next */
    let list = [
        {
            title: 'Dashboard',
            to: '/'
        },
        {
            title: 'Offers & Seeks',
            children: [
                {
                    title: 'Offers',
                    children: [
                        {
                            title: 'Search Offers',
                            to: '/offers'
                        },
                        {
                            title: 'Add an Offer',
                            to: '/offer/add'
                        }
                    ]
                },
                {
                    title: 'Seeks',
                    children: [
                        {
                            title: 'Search Seeks',
                            to: '/seeks'
                        },
                        {
                            title: 'Add a Seek',
                            to: '/seek/add'
                        }
                    ]
                },

            ]
        },
        {
            title: 'Exchanges',
            children: [
                {
                    title: 'Active Exchanges',
                    to: '/exchanges/active'
                },
                {
                    title: 'All Exchanges',
                    to: '/exchanges/all'
                }
            ]
        },
        {
            title: 'Account',
            children: [
                {
                    title: 'Profile',
                    to: '/profile'
                },
                {
                    title: 'Access',
                    children: [
                        {
                            title: 'Subscription',
                            to: '/subscription'
                        },
                        {
                            title: 'Update Password',
                            to: '/profile/pwd/update'
                        },
                        {
                            title: '2FA',
                            to: '/profile/pwd/token'
                        }
                    ]
                },
                {
                    title: 'Manage',
                    children: [
                        {
                            title: 'My Locations',
                            to: '/location/list'
                        },
                        {
                            title: 'Custom Fields',
                            children: [
                                {
                                    title: 'My Custom Fields',
                                    to: '/profile/att-sets'
                                },
                                {
                                    title: 'Add A Custom Fieldset',
                                    to: '/profile/att-set/add'
                                }
                            ]
                        },
                        {
                            title: 'My Images',
                            to: '/img/list'
                        },
                        {
                            title: 'Email',
                            to: '/profile/email'
                        },
                        {
                            title: 'Settings',
                            to: '/profile/settings'
                        },
                        {
                            title: 'Shop Profile',
                            to: '/profile/shop'
                        }
                    ]
                },
                {
                    title: 'Users & Networks',
                    children: [
                        {
                            title: 'Users',
                            to: '/users'
                        },
                        {
                            title: 'Platforms',
                            to: '/trails'
                        },
                        {
                            title: 'My Platforms',
                            to: '/profile/trails'
                        },
                        ...( config.features.allowCarrierGroups ? [
                                {
                                    title: 'My Carrier Companies',
                                    to: '/profile/groups/carrier'
                                },
                            ] : []
                        ),
                        {
                            title: 'Invite Friends',
                            to: '/join'
                        }
                    ]
                },
                {
                    title: 'Online Payments',
                    children: [
                        {
                            title: 'Online Payments',
                            to: '/profile/income'
                        },
                        {
                            title: 'Payout Accounts',
                            children: [
                                {
                                    title: 'Payout Accounts',
                                    to: '/payouts'
                                },
                                {
                                    title: 'Add a Payout Account',
                                    to: '/payout/add'
                                }
                            ]
                        },
                        {
                            title: 'Payout Transactions',
                            to: '/transactions/list'
                        },
                        {
                            title: 'Payment Methods',
                            children: [
                                {
                                    title: 'My Payment Methods',
                                    to: '/payment/methods'
                                },
                                {
                                    title: 'Add a Payment Method',
                                    to: '/payment/method/add'
                                }
                            ]
                        },
                        {
                            title: 'Chargeback Fees',
                            to: '/profile/fees'
                        }
                    ]
                },
                {
                    title: 'Info',
                    children: [
                        {
                            title: 'News',
                            to: '/news'
                        },
                        {
                            title: 'Report an Issue',
                            to: '/profile/report/issue'
                        },
                        {
                            title: 'Terms of Service',
                            to: '/terms'
                        },
                        {
                            title: 'Fees',
                            to: '/fees'
                        },
                        {
                            title: 'Help',
                            to: '/help'
                        },
                        {
                            title: 'Welcome Tour',
                            to: '#',
                            isWelcomeTour: true
                        }
                    ]
                }
            ]
        },

        
    ];

    if(showAdminOptions)
    {
        list.push(
            {
                title: 'Admin',
                children: [
                    {
                        title: 'System Status',
                        children: [
                            {
                                title: 'System Stats',
                                to: '/admin/stats'
                            },
                            {
                                title: 'Client Details',
                                to: '/admin/client/stats'
                            },
                            {
                                title: 'API Logs',
                                to: '/admin/api/log/locationService'
                            }
                        ]
                    },
                    {
                        title: 'Admin Wiki',
                        children: [
                            {
                                title: 'Publish News',
                                to: '/admin/news/add'
                            },
                            {
                                title: 'Manage Tags',
                                to: '/admin/tag/add'
                            },
                            {
                                title: 'Manage Platforms',
                                to: '/admin/trail/add'
                            },
                            {
                                title: 'Send Test Email',
                                to: '/admin/test/email'
                            },
                            {
                                title: 'Maintance Mode',
                                to: '/admin/maintance-mode'
                            },
                        ]
                    },
                    {
                        title: 'Users',
                        children: [
                            {
                                title: 'Issue Invites',
                                to: '/admin/invite/send'
                            },
                            {
                                title: 'Pending Flags',
                                to: '/admin/review/flags'
                            },
                            {
                                title: 'Reported Issues',
                                to: '/admin/reported/issues'
                            }
                        ]
                    },
                    {
                        title: 'Admin Money',
                        children: [
                            {
                                title: 'Payouts',
                                to: '/admin/payouts'
                            },
                            {
                                title: 'Disputed Transactions',
                                to: '/admin/disputed'
                            },
                            {
                                title: 'Fines',
                                to: '/admin/fines/search',
                            },
                            {
                                title: 'Ledger',
                                to: '/admin/ledger'
                            },
                            {
                                title: 'Ledger Summary',
                                to: '/admin/ledger/summary'
                            }
                        ]
                    },
                    {
                        title: 'BX Events',
                        to: '/admin/events'
                    },
                    {
                        title: 'IP Addresses',
                        to: '/admin/ip'
                    },
                    {
                        title: 'Logs',
                        to: '/admin/logs'
                    },
                    {
                        title: 'Metrics',
                        children: [
                            {
                                title: 'Metrics Summary',
                                to: '/admin/metrics/summary'
                            },
                            {
                                title: 'All Metrics',
                                to: '/admin/metrics'
                            }
                        ]
                    }
                    
                ]
            }
        );
    }

    return list;
};

export default getSmallMenuListData;
