import React from 'react';
import PayService from './../../../service/pay';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import theme from '../../theme';
import { NavLink } from 'react-router-dom';

class _FinishPayAccountPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _FinishPayAccountPrompt.getValidators());
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            accepted: {
                isValid: ValidateService.isTrue
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            accepted: {
                name: 'I have read the terms of service',
                customField: <span>I have read and agree to <NavLink style={{ ...theme.getGeneralTextLinkStyle(theme) }} to="/terms">terms of service</NavLink>*</span>,
                error: 'Specify if you wish to make online payments with this account',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onSendToServer()
    {
        PayService.finishAccount({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    getServerRequest()
    {
        return {
            
        };
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Redirecting...')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Finish')
    {
        return (
            <div style={{
                ...theme.getAbstractPromptStyle(theme),
            }}>
                { super.getSubmitButtonUi(buttonText) }
            </div>
        );
    }

    onServerResponse(response)
    {
        if(this.props.onAccountCreated)
        {
            this.props.onAccountCreated(response);
        }
    }

    /* istanbul ignore next */
    getPromptUi()
    {
        if(Object.keys(this.validator).length === 1)
        {
            return this.getSingleFieldPrompt();
        } 
        else 
        {
            return (
                <div>
                    <form onSubmit={this.onFormSubmit}>
                        { this.getServerFeedbackUi() }
                        { this.getFormFieldsUi() }
                        { this.getSubmitButtonUi() }
                    </form>
                </div>
            );
        }
    }

}

const FinishPayAccountPrompt = connect(mapStateToProps, mapDispatchToProps)(_FinishPayAccountPrompt);
export default FinishPayAccountPrompt;
