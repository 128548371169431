import config from './../config';

var endpointTimeoutId = null;
/* istanbul ignore next */
function endpointFlow(endpointRequest)
{
    return () => {
        if(endpointTimeoutId)
        {
            clearTimeout(endpointTimeoutId);
            endpointTimeoutId = null;
        }

        endpointTimeoutId = setTimeout(() => {
                endpointRequest();
            }, 
            config.interval.endpointDebounce
        );
    };
    
}

export default endpointFlow;
