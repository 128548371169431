import React from 'react';
import CarrierGroupService from './../../../service/carrier-group';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import DetailPanel from './../core/detail';
import ProfileCarrierGroupsViewPanel from './view';

class _ProfileCarrierGroupsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onCarrierGroupAdded = this.onCarrierGroupAdded.bind(this);

        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierGroupService.list({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        CarrierGroupService.list({
            request: {
                page,
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getLoadDataRequest(inputObj)
    {
        return {
            page: inputObj.page ? inputObj.page : 0
        };
    }

    componentDidMount()
    {
        this.loadData({ page: 0 });
    }

    /* istanbul ignore next */
    onCarrierGroupAdded(response)
    {
        this.sendUserTo(`/profile/groups/carrier/${response.id}`);   
    }

    getLoadedDataDetailUi()
    {
        return <ProfileCarrierGroupsViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={ this.onLoadSpecifiedPage } 
            onCarrierGroupAdded={ this.onCarrierGroupAdded }
        />
    }
}

const ProfileCarrierGroupsPanel = connect(mapStateToProps, mapDispatchToProps)(_ProfileCarrierGroupsPanel);
export default ProfileCarrierGroupsPanel;
