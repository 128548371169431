import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import PayNewCardPrompt from './../ui/prompt/pay-new-card';
import theme from './../ui/theme';
import UserSearchFinePrompt from './../ui/prompt/user-search-fine';
import { NavLink } from 'react-router-dom';
import config from '../config';

const UserFinesPage = ({ setPayClientSecret, payClientDetails, payClientSecret }) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate childMenu={ <ProfileMenu /> }>
            {
                payClientDetails && payClientDetails.type !== '' ?
                    <PayNewCardPrompt 
                        onSuccessRedirectUrl={config.pay.finePaymentCompletedUrl}
                        isFeePayment={true}
                        payClientSecret={payClientSecret} 
                        payClientDetails={payClientDetails} 
                        setPayClientSecret={setPayClientSecret} 
                    /> : 
                        <div style={{
                            ...theme.getGeneralTextStyle(theme)
                        }}>
                            <h2>
                                Chargeback Fees
                            </h2>
                            <div style={bodyStyle}>
                                <p>
                                    To learn more about fees see our 
                                </p>
                                &nbsp;
                                <NavLink 
                                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                    to="/fees"
                                >
                                    fees page
                                </NavLink>
                                <br />
                            

                                <UserSearchFinePrompt setPayClientSecret={setPayClientSecret} />
                                
                            </div>
                        </div>
            }
        </AuthPageTemplate>
    );
};

export default UserFinesPage;
