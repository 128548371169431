import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../service/resize';
import theme from './../theme';
import { NavLink } from 'react-router-dom';

const list = [
    {
        title: 'Password',
        url: '/profile/pwd/update',
        details: 'Change your password'
    },
    {
        title: '2FA',
        url: '/profile/pwd/token',
        details: 'Two-Factor Authentication'
    },
    {
        title: 'Subscription',
        url: '/subscription',
        details: 'Pay for what you wish to keep'
    }
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const ProfilePwdPanel = () => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );

    const getBodyStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={containerStyle}>
                <h2>
                    Account Access
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Keep your account secure.
                </h4>
            </div>
            <div style={bodyStyle}>
                <div style={{
                    ...theme.getProfilePwdBodyFixStyle(theme),
                }}>
                    <div style={{
                        ...theme.getProfilePwdContainerStyle(theme),
                    }}>
                        {
                            list.map((item, index) => <Shortcut details={item.details} title={item.title} url={item.url} key={index} />)
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfilePwdPanel;
