import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import AttSetSearchPrompt from './../ui/prompt/att-set-search';
import ProfileMenu from './../ui/menu/profile';
import theme from './../ui/theme';
import { useParams } from 'react-router-dom';

const AttSetsPage = () => {
    const { page, searchText } = useParams();
    /* istanbul ignore next */
    const passPage = page ? parseInt(page) : 0;
    /* istanbul ignore next */
    const passSearchText = searchText ? searchText : '';
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const getInLineTextTitleStyle = () => theme.getInLineTextTitleStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    const [ inLineTextTitleStyle, setInLineTextTitleStyle ] = useState( getInLineTextTitleStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setInLineTextTitleStyle( getInLineTextTitleStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate 
            childMenu={
                <div>
                    <ProfileMenu />
                </div>
        }>
            <div style={{
                    ...theme.getInLineTextStyle(theme),
            }}>
                <h2 style={inLineTextTitleStyle}>   
                    My Fields
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Define a set of custom fields to auto populate into your posts such as weight for produce, item amount per order, etc.
                </h4>
            </div>
            <AttSetSearchPrompt page={passPage} searchText={passSearchText} />
        </AuthPageTemplate>
    );
};

export default AttSetsPage;
