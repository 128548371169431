import React from 'react';
import DetailPanel from './../core/detail';
import SeekService from './../../../service/seek';
import BidService from './../../../service/bid';
import UiService from './../../../service/ui';
import SeekDetailViewPanel from './view';
import SplashContactingServerPanel from './../core/splash-contacting-server';
import UnableToLoadTargetPanel from './../core/unable-to-load-target';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';

class _SeekDetailPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);
        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onArchiveSeek = this.onArchiveSeek.bind(this);
        this.onArchiveSeekResponse = this.onArchiveSeekResponse.bind(this);
        this.setBids = this.setBids.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onBidPageResponse = this.onBidPageResponse.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
        this.onContactingPayServer = this.onContactingPayServer.bind(this);
        this.onContactingPayServerDone = this.onContactingPayServerDone.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            bids: [],
            unableToLoadTarget: false,
            contactingPayServer: false,
        };
    }

    /* istanbul ignore next */
    onContactingPayServer()
    {
        let nextState = this.state;
        nextState.contactingPayServer = true;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onContactingPayServerDone()
    {
        let nextState = this.state;
        nextState.contactingPayServer = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    setBids(bids)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            data: this.state.data ? {
                ...this.state.data,
                bids,
            }: undefined,
            bids,
        };
        this.setState(nextState);
    }

    onLoadDataResponse(response)
    {
        if(response.exchangeId)
        {
            const url = `/exchange/${response.exchangeId}`;
            this.sendUserTo(url);
        }

        super.onLoadDataResponse(response);
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load seek details')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        SeekService.getSeekDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onLoadSpecifiedPage(page)
    {
        super.loadData({ });
        BidService.listBidsBySeek({
            request: {
                seekId: this.props.seekId,
                page,
            },
            onResponse: this.onBidPageResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onBidPageResponse(response)
    {
        this.setBids(response.bids);
    }

    getLoadDataRequest(inputObj)
    {
        return {
            seekId: inputObj.seekId
        };
    }

    componentDidMount()
    {
        const seekId = this.props.seekId;
        this.loadData({ seekId });
        UiService.scrollToTop();
    }

    /* istanbul ignore next */
    onArchiveSeek(seek)
    {
        super.loadData({ });
        SeekService.archiveSeek({
            request: SeekService.getArchiveSeekRequest(seek.id),
            onResponse: this.onArchiveSeekResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });

    }

    /* istanbul ignore next */
    onArchiveSeekResponse(response)
    {
        this.sendUserTo('/seek/add');
    }

    getUnLoadedDataDetailUi()
    {
        return this.state.unableToLoadTarget ? <UnableToLoadTargetPanel title="Seek" /> : super.getUnLoadedDataDetailUi();
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                {
                    /* istanbul ignore next */
                    this.state.contactingPayServer && 
                        <SplashContactingServerPanel />
                }
                <SeekDetailViewPanel 
                    setBids={this.setBids} 
                    data={this.state.data} 
                    viewingUserId={this.props.userId} 
                    onArchiveItem={this.onArchiveSeek} 
                    onLoadSpecifiedPage={this.onLoadSpecifiedPage} 
                    onContactingServer={this.onContactingPayServer}
                    onContactingServerResponse={this.onContactingPayServerDone}
                />
            </section>
        );
    }
}

const SeekDetailPanel = connect(mapStateToProps, mapDispatchToProps)(_SeekDetailPanel);

export default SeekDetailPanel;
