import React, { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import CloseIcon from './../../../img/close.svg';
import RecurringIcon from './../../../img/recurring.svg';
import EditIcon from './../../../img/menu-admin-edit.png';
import { NavLink } from 'react-router-dom';
import AddPayout from './../../../img/payment-add-payout.svg';
import Dialog from '@mui/material/Dialog';
import EditPaymentMethodMetaDataPrompt from './../../prompt/edit-payment-method-meta-data';

const EmptyView = (parms) => <div style={{
    ...theme.getAbstractFormContainerStyle(theme)
}}>
    <p>
        No Methods Saved.  
    </p>
    <NavLink 
        to="/payment/method/add" 
            style={{
            ...theme.getGeneralTextLinkStyle(theme), 
        }}
    >
        Add a payment method
    </NavLink>
</div>

const Row = ({ 
    card, 
    hasSubscription,
    showSetSubPaymethod, 
    onRemoveCard, 
    onLoadDataResponse,
    onSetSubscriptionPaymentMethod 
}) => {
    const [ confirmRemoveDialogOpen, setConfirmRemoveDialogOpen ] = useState(false);
    const [ editCardDialogOpen, setEditCardDialogOpen ] = useState(false);

    const getPanelStyle = () => theme.getProfilePwdPanelStyle(theme);
    const getPaymentMethodItemStyle = () => theme.getPaymentMethodItemStyle(theme);
    const getPaymentMethodHeaderStyle = () => theme.getPaymentMethodHeaderStyle(theme);
    const getInLineTextTitleStyle = () => theme.getInLineTextTitleStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );
    const [ paymentMethodItemStyle, setPaymentMethodItemStyle ] = useState( getPaymentMethodItemStyle() );
    const [ paymentMethodHeaderStyle, setpaymentMethodHeaderStyle ] = useState( getPaymentMethodHeaderStyle() );
    const [ inLineTextTitleStyle, setInLineTextTitleStyle ] = useState( getInLineTextTitleStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPanelStyle( getPanelStyle() );
        setPaymentMethodItemStyle( getPaymentMethodItemStyle() );
        setpaymentMethodHeaderStyle( getPaymentMethodHeaderStyle() );
        setInLineTextTitleStyle( getInLineTextTitleStyle() );
    });
    useEffect(resizeEffect);
    return <div style={{
        ...theme.getPaymentMethodGridContainerStyle(theme),
    }}>
        <div style={paymentMethodItemStyle}>
            <strong>
                Nickname
            </strong>
            <div style={paymentMethodHeaderStyle}>
                { card.nickname }
            </div>
        </div>
        <div style={paymentMethodItemStyle}>
            <strong>
                Brand
            </strong>
            <div style={inLineTextTitleStyle}>
                { card.brand }
            </div>
        </div>
        <div style={paymentMethodItemStyle}>
            <strong>
                Type
            </strong>
            <div style={inLineTextTitleStyle}>
                { card.funding }
            </div>
        </div>
        <div style={paymentMethodItemStyle}>
            <strong>
                Expire
            </strong>
            <div style={inLineTextTitleStyle}>
                { `${card.expire.month} / ${card.expire.year}` }
            </div>
        </div>
        <div style={paymentMethodItemStyle}>
            <strong>
                Account
            </strong>
            <div style={inLineTextTitleStyle}>
                { `******${card.lastFour}` }
            </div>
        </div>
        <div style={paymentMethodItemStyle}>
            <strong>
                Default payment method
            </strong>
            <div style={inLineTextTitleStyle}>
                { card.isDefault ? 'Yes' : 'no' }
            </div>
        </div>
        <div style={{
            ...theme.getPaymentMethodButtonStyle(theme),
        }}>
            { 
                hasSubscription && card.isSubscriptionPaymentMethod ? 
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to="/subscription"
                    >
                        Used for Subscription
                    </NavLink> : 
                        <div>
                            <div 
                                style={{
                                    ...theme.getPaymentCloseButtonStyle(theme),
                                }}
                                onClick={
                                    /* istanbul ignore next */
                                    (event) => { setEditCardDialogOpen(true) }
                                }
                            >
                                <h4>
                                    Edit Card
                                </h4>
                                <theme.getIconButtonUi
                                    theme={theme}
                                    icon={EditIcon}
                                    className="test-paymentMethodsEditBtn"
                                    onClick={
                                        /* istanbul ignore next */
                                        (event) => { setEditCardDialogOpen(true) }
                                    }
                                />
                            </div>
                            
                            {
                                showSetSubPaymethod && 
                                <div 
                                    style={{
                                    ...theme.getPaymentCloseButtonStyle(theme),
                                    }}
                                    onClick={
                                        /* istanbul ignore next */
                                        event => { onSetSubscriptionPaymentMethod(card.id) }
                                    }
                                >
                                    <h4>
                                        set as subscription payment method
                                    </h4>
                                    { 
                                        <theme.getIconButtonUi 
                                            theme={theme}
                                            icon={RecurringIcon}
                                            className="test-paymentMethodsRecurringBtn"
                                            onClick={
                                                /* istanbul ignore next */
                                                event => { onSetSubscriptionPaymentMethod(card.id) }
                                            }
                                        />  
                                    }
                                </div>
                            }
                            
                            <Dialog
                                onClose={ 
                                    /* istanbul ignore next */
                                    event => { setEditCardDialogOpen(false) }
                                }
                                open={editCardDialogOpen}
                            >
                                <div style={panelStyle}>
                                    
                                    <h3>
                                        Edit Nickname
                                    </h3>
                                    <div>
                                        <EditPaymentMethodMetaDataPrompt 
                                            card={card} 
                                            closeDialog={
                                                /* istanbul ignore next */
                                                (response) => { 
                                                    setEditCardDialogOpen(false);
                                                    onLoadDataResponse(response);
                                                }
                                            } 
                                        />
                                    </div>
                                    <div 
                                        style={{
                                            ...theme.getPaymentCloseButtonStyle(theme),
                                        }}
                                        onClick={
                                            /* istanbul ignore next */
                                            (event) => { setConfirmRemoveDialogOpen(true) }
                                        }
                                    >
                                        <h4>
                                            Delete Card
                                        </h4>
                                        <theme.getIconButtonUi
                                            theme={theme}
                                            icon={CloseIcon}
                                            className="test-paymentMethodsDeleteBtn"
                                            onClick={
                                                /* istanbul ignore next */
                                                (event) => { setConfirmRemoveDialogOpen(true) }
                                            }
                                        />
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog 
                                onClose={ 
                                    /* istanbul ignore next */
                                    event => { setConfirmRemoveDialogOpen(false) }
                                }
                                open={confirmRemoveDialogOpen}
                            >
                                <div style={{
                                        ...theme.getPaymentMethodRemoveStyle(theme)
                                }}>
                                    <p>
                                        Remove Payment Method ?
                                    </p>
                                    <button 
                                        className="test-paymentMethodsRemoveBtn"
                                        onClick={
                                            /* istanbul ignore next */
                                            event => { onRemoveCard(card.id) }
                                        }
                                    >
                                        Remove
                                    </button>
                                </div>
                            </Dialog>
                        </div>
             }
        </div>
    </div>
}

const EnabledView = ({ 
    paymentMethods, 
    hasSubscription,
    onLoadDataResponse,
    onSetSubscriptionPaymentMethod, 
    onRemoveCard 
}) => {
    const showSetSubPaymethod = paymentMethods.length > 1 ? hasSubscription : false;
    
    const getContainerStyle = () => ({
        ...theme.getProfilePwdSuggestionContentStyle(theme),
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);

    return (
        paymentMethods.length < 1 ? <EmptyView /> :
            <div>
                <table>
                    <div style={containerStyle}>
                        {
                            paymentMethods.map((card, index) => 
                                <Row 
                                    onRemoveCard={onRemoveCard} 
                                    onLoadDataResponse={onLoadDataResponse}
                                    showSetSubPaymethod={showSetSubPaymethod}
                                    card={card} 
                                    key={index} 
                                    hasSubscription={hasSubscription}
                                    onSetSubscriptionPaymentMethod={onSetSubscriptionPaymentMethod}
                                />)
                        }
                    </div>
                </table>
            </div>
    );
};

const DisabledView = (parms) => 
    <p>
        Your customer account has been disabled
    </p>

const PaymentMethodsViewPanel = ({ 
    data, 
    onRemoveCard, 
    onLoadDataResponse,
    onSetSubscriptionPaymentMethod 
}) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <h2>
                Payment Methods
            </h2>
            <div 
                style={{
                    ...bodyStyle,
                    ...theme.getPaymentMethodMinWidthStyle(theme), 
                    ...theme.getProfilePwdBodyStyle(theme)       
                }}
            >
                <NavLink 
                    to="/payment/method/add" 
                    style={{
                        ...theme.getProfileButtonStyle(theme),        
                    }}
                >
                    <div style={{
                        ...theme.getGeneralButtonStyle(theme, AddPayout)
                    }}>
                    </div>
                    <div style={{
                        ...theme.getGeneralButtonTextStyle(theme)
                    }}>
                        Add Payment Method
                    </div> 
                </NavLink>
                
                {
                    data.enabled ? 
                        <EnabledView 
                            onLoadDataResponse={onLoadDataResponse}
                            paymentMethods={data.paymentMethods} 
                            onRemoveCard={onRemoveCard} 
                            onSetSubscriptionPaymentMethod={onSetSubscriptionPaymentMethod} 
                            hasSubscription={data.hasSubscription}
                        /> : <DisabledView />
                }


            </div>
        </div>
    );
};

export default PaymentMethodsViewPanel;
