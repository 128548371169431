import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import UserDetailPanel from './../ui/panel/user-detail';
import { useParams } from "react-router-dom";
const UserDetailPage = () => {
        
    const { userId } = useParams();
    return (
        <AuthPageTemplate childMenu={
            <div>
                <ProfileMenu />
            </div>
        }>
            <UserDetailPanel userId={userId} />
        </AuthPageTemplate>
    );

};

export default UserDetailPage;