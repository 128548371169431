export const groupPopulation = 'Population';
export const groupSubscriptions = 'Subscriptions';
export const groupPosts = 'Posts';
export const groupCta = 'Requests';
export const groupTrans = 'Transactions';
export const groupAnalytics = 'Analytics';
export const groupFines = 'Fines';
export const groupLocations = 'Locations';
export const groupApis = 'APIs';

export const groupList = [
    groupCta,
    groupTrans,
    groupSubscriptions,
    groupPosts,
    groupApis,
    groupLocations,
    groupFines,
    groupAnalytics,
    groupPopulation,
];

export const statList = [
    {
        title: 'Pending Address to GeoEncoding',
        field: 'pendingAddressToGeoLoc',
        group: groupLocations
    },
    {
        title: 'Failed Address to GeoEncoding',
        field: 'totalFailedGeoLoc',
        group: groupCta,
        url: '/admin/geolocation/failed',
    },
    {
        title: 'GeoEncoding Found with API',
        field: 'addressToGeoLocFound',
        group: groupLocations
    },
    {
        title: 'Total User Locations',
        field: 'totalUserLocations',
        group: groupLocations
    },
    {
        title: 'Active Users',
        field: 'activeUserCount',
        url: '/users',
        group: groupPopulation,
    },
    {
        title: 'Active Subscriptions',
        field: 'activeSubscriptionsCount',
        group: groupSubscriptions
    },
    {
        title: 'Failed Subscription Payments',
        field: 'failedPaymentSubscriptionCount',
        group: groupSubscriptions
    },
    {
        title: 'Active Invites',
        field: 'activeInviteCount',
        group: groupPopulation,
    },
    {
        title: 'Disabled Users',
        field: 'disabledUserCount',
        group: groupPopulation,
    },
    {
        title: 'Admin Users',
        field: 'adminUserCount',
        group: groupPopulation,
    },
    {
        title: 'Login Failure Locks',
        field: 'loginLockOutCount',
        group: groupPopulation,
    },
    {
        title: 'Active 2FA Tokens',
        field: 'using2FACount',
        group: groupPopulation,
    },
    {
        title: 'Uncapped Invites',
        field: 'continuedInviteCount',
        group: groupPopulation,
    },
    {
        title: 'UnReviewed Flags',
        field: 'unreviewedFlaggedCount',
        url: '/admin/review/flags',
        group: groupCta
    },
    {
        title: 'UnSeen Issues',
        field: 'unseenIssueCount',
        url: '/admin/reported/issues',
        group: groupCta
    },
    {
        title: 'Pending Payouts',
        field: 'pendingPayouts',
        group: groupTrans
    },
    {
        title: 'Ready For Payouts',
        field: 'readyForPayouts',
        group: groupTrans
    },
    {
        title: 'Failed Payouts',
        field: 'failedPayouts',
        url: '/admin/payouts',
        group: groupCta
    },
    {
        title: 'Active Payout Jobs',
        field: 'activePayoutJobs',
        group: groupTrans
    },
    {
        title: 'Disputed Transactions',
        field: 'disputedPayouts',
        url: '/admin/disputed',
        group: groupCta
    },
    {
        title: 'Active Exchanges',
        field: 'activeExchangeCount',
        group: groupTrans
    },
    {
        title: 'Active Offers',
        field: 'activeOfferCount',
        url: '/offers',
        group: groupPosts
    },
    {
        title: 'Active Seeks',
        field: 'activeSeekCount',
        url: '/seeks',
        group: groupPosts
    },
    {
        title: 'Images',
        field: 'imgCount',
        group: groupPopulation,
    },
    {
        title: 'Users Consenting to Analytics',
        field: 'userConsentToAnalytics',
        group: groupAnalytics
    },
    {
        title: 'Pending IP Events',
        field: 'ipEventsPending',
        url: '/admin/events',
        group: groupAnalytics
    },
    {
        title: 'Failed IP Events',
        field: 'ipEventsFailed',
        url: '/admin/events',
        group: groupAnalytics
    },
    {
        title: 'Country Detected Records',
        field: 'countryDetectedRecords',
        url: '/admin/client/stats',
        group: groupAnalytics
    },
    {
        title: 'City Detected Records',
        field: 'cityDetectedRecords',
        url: '/admin/client/stats',
        group: groupAnalytics
    },
    {
        title: 'OS Detected Records',
        field: 'osDetectedRecords',
        url: '/admin/client/stats',
        group: groupAnalytics
    },
    {
        title: 'Browser Detected Records',
        field: 'browserDetectedRecords',
        url: '/admin/client/stats',
        group: groupAnalytics
    },
    {
        title: 'GeoIP API',
        field: 'api_locationService_available',
        url: '/admin/api/log/locationService',
        group: groupApis
    },
    {
        title: 'GeoEncoding API',
        field: 'api_locationFromStreetService_available',
        group: groupApis
    },
    {
        title: 'Pending Processing',
        field: 'payFinePendingProcessing',
        url: '/admin/fines/search',
        group: groupFines
    },
    {
        title: 'Auto Pay Failed',
        field: 'payFineAutoPayFailed',
        url: '/admin/fines/search',
        group: groupFines
    },
    {
        title: 'UnPaid Fine Count',
        field: 'payFineUnpaidCount',
        url: '/admin/fines/search',
        group: groupFines
    },
    {
        title: 'Paid Out Fines',
        field: 'payFinePaidOutCount',
        url: '/admin/fines/search',
        group: groupFines
    },
];