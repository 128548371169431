import React from 'react';
import theme from './../../theme';

const AdminPanel = () => {
    return (
        <section style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <h2>
                Admin Panel
            </h2>
            
            <p>
                Manage system platform
            </p>

        </section>
    );
};

export default AdminPanel;
