import DeliveryService from './../../../service/delivery';
import ValidateService from './../../../service/validate';
import DeliveryCarrierTypeEnum from './../core/enum/delivery-carrier-type';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';

class _SentPackageAssignShipCompanyPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _SentPackageAssignShipCompanyPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onUpdateResponse = this.onUpdateResponse.bind(this);
        this.onUpdateError = this.onUpdateError.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    /* istanbul ignore next */
    static getValidators()
    {
        return {
            carrierType: {
                isValid: DeliveryCarrierTypeEnum.isValidSentPackageAssignDeliveryCarrierType,
                isVisible: (thisState) => thisState.hasPassedCarrierType ? false : true
            },
            otherCarrierName: {
                isValid: ValidateService.anyValue,
                isVisible: (thisState) => !thisState.hasPassedCarrierType && thisState.carrierType.value === DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_OTHER,
            },
            carrierTrackingId: {
                isValid: ValidateService.anyValue
            },
            acceptedByCarrier: {
                isValid: ValidateService.anyValue
            },
        };
    }

    getFieldsModel()
    {
        return {
            carrierType: {
                name: 'Carrier',
                error: 'Specify the shipping company',
                value: DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_UNASSIGNED,
                valueList: DeliveryCarrierTypeEnum.getSentPackageAssignDeliveryCarrierTypeDropDown(),
                visible: this.props.carrierType ? false : true,
                valid: false,
                touched: false,
                type: FORM_FIELD.DROP
            },
            otherCarrierName: {
                name: 'Company Name',
                error: 'Specify the shipping company name',
                value: '',
                visible: false,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            carrierTrackingId: {
                name: 'Delivery Tracking ID',
                error: 'Specify the tracking id associated with this delivery',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            acceptedByCarrier: {
                name: 'Shipping company has received package',
                error: 'specify if the carrier has received the package',
                value: true,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        const hasPassedCarrierType = this.props.carrierType ? true : false;
        return {
            ...baseState,
            ...fields,
            hasPassedCarrierType,
            isValid: hasPassedCarrierType,
        };
    }

    onUpdateResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);

        if(this.props.onLoadDataResponse)
        {
            this.props.onLoadDataResponse(response);
        }
    }

    onUpdateError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onSendToServer()
    {
        DeliveryService.assignShippingCompany({
            request: this.getServerRequest(),
            onResponse: this.onUpdateResponse,
            onError: this.onUpdateError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            deliveryId: this.props.delivery.id,
            carrierType: this.props.carrierType ? this.props.carrierType : this.state.carrierType.value,
            otherCarrierName: this.state.otherCarrierName.value,
            carrierTrackingId: this.state.carrierTrackingId.value,
            acceptedByCarrier: this.state.acceptedByCarrier.value,
        };
    }


    getConfirmUi(confirmMessage = 'Delivery Updated')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Update')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const SentPackageAssignShipCompanyPrompt = connect(mapStateToProps, mapDispatchToProps)(_SentPackageAssignShipCompanyPrompt);
export default SentPackageAssignShipCompanyPrompt;
