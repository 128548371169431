export const getDisconnectedIconStyle = (theme, icon) => ({
    width: '100%',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    maxWidth: '30rem',
    height: '10rem',
    margin: '.0 auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundImage: `url(${icon})`,
});