import React from 'react';
import ValidateService from './../../../service/validate';
import CurrencyService from './../../../service/currency';
import CurrencyInputService from './../../../service/currency-input';
import FeeService from './../../../service/fee';
import BidContentTypeEnum from './../core/enum/bid-content-type';
import { connect } from 'react-redux';
import BidService from './../../../service/bid';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import PayService from './../../../service/pay';
import { 
    obtainToValue, 
    exchangeToValue,
    mapExchangeToValueItem,
    mapObtainToValueItem
} from './../bid';
import OnlineExchangeAdvicePanel from './../../panel/core/online-exchange-advice';
import { EXCHANGE_TYPE_ONLINE } from './../core/enum/exchange-type';
import {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_ON_SITE,
    OBTAIN_TYPE_PICK_UP
} from './../core/enum/obtain-type';
import LocationSelectorPanel from './../../panel/location-selector';
import { getObtainTypeLocationTitleCopy } from './../core/copy/obtain-type-copy-util';

class _BidOnlinePaymentPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _BidOnlinePaymentPrompt.getValidators(parms));
        
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadPayAccount = this.loadPayAccount.bind(this);
        this.onLoadPayAccountResponse = this.onLoadPayAccountResponse.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.setupOnlinePaymentUi = this.setupOnlinePaymentUi.bind(this);
        this.applyDynamicFieldValidators = this.applyDynamicFieldValidators.bind(this);
        this.getDynamicFields = this.getDynamicFields.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.setExchangeMethod = this.setExchangeMethod.bind(this);
        this.controlValueChange = this.controlValueChange.bind(this);
        this.getAskingError = this.getAskingError.bind(this);
        this.displayPriceUi = this.displayPriceUi.bind(this);
        this.isPromptingLocation = this.isPromptingLocation.bind(this);
        this.getFormFieldsUi = this.getFormFieldsUi.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationRemoveSelected = this.onLocationRemoveSelected.bind(this);
        this.validateState = this.validateState.bind(this);
        this.reorderValidators = this.reorderValidators.bind(this);

        this.validator.title.isValid = ValidateService.makeCurrencyFieldValue(this, 'currency', this.props.paidAccount ? this.props.paidAccount : false);

        this.applyDynamicFieldValidators();
        this.reorderValidators();
        this.state = this.getDefaultState(parms);

    }

    /* istanbul ignore next */
    static loadWithWeightVisible(parms, thisState)
    {
        const weightTypes = [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND
        ];
        return (
            (
                // no prompt for obtain method
                parms.obtainTypeDetailList && 
                parms.obtainTypeDetailList.length === 1 && 
                weightTypes.includes(
                    obtainToValue(parms.obtainTypeDetailList[0]) 
                )
            )
            ||
            (
                // obtain prompt has ship selected
                thisState.obtainMethod && 
                weightTypes.includes(
                    thisState.obtainMethod.value
                )
            )
            ||
            (
                // obtain prompt not yet rendered - first option ship 
                !thisState.obtainMethod && 
                parms.obtainTypeDetailList && 
                parms.obtainTypeDetailList.length > 0 && 
                weightTypes.includes(
                    obtainToValue(parms.obtainTypeDetailList[0]) 
                )
            )
        ) && parms.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER
    }

    /* istanbul ignore next */
    static getValidators(parms)
    {
        return {
            currency: {
                isValid: ValidateService.valueRequired
            },
            title: {
                isValid: ValidateService.valueRequired,
            },
            details: {
                isValid: ValidateService.textLines,
            },
            weightPounds: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => _BidOnlinePaymentPrompt.loadWithWeightVisible(parms, thisState)
            },
            weightOunces: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => _BidOnlinePaymentPrompt.loadWithWeightVisible(parms, thisState)
            },
            hasRefundPolicy: {
                isValid: ValidateService.anyValue,
            },
            saveRefundPolicy: {
                isValid: ValidateService.anyValue,
                isVisible: (thisState) => parms.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && 
                    parms.setupBid && 
                    !parms.setupBid.hasRefundPolicy && 
                    thisState.hasRefundPolicy.value,
            },
            refundPolicyDetails: {
                isValid: (value, thisState) => {
                    if(thisState.hasRefundPolicy.value)
                    {
                        return ValidateService.valueRequired(value);
                    }

                    return true;
                },
                isVisible: (thisState) => thisState.hasRefundPolicy.value
            },
        };
    }

    /* istanbul ignore next */
    applyDynamicFieldValidators()
    {
        if( this.props.exchangeList && 
            this.props.exchangeList.length > 1)
        {
            this.validator.exchangeMethod = {
                isValid: ValidateService.anyValue
            };
        }
        
        if( this.props.obtainTypeDetailList && 
            this.props.obtainTypeDetailList.length > 1)
        {
            this.validator.obtainMethod = {
                isValid: ValidateService.anyValue
            };
        }
    }

    /* istanbul ignore next */
    reorderValidators()
    {
        const nextValidators = {};
        const nextOrder = [
            'exchangeMethod',
            'currency',
            'title',
            'details',
            'weightPounds',
            'weightOunces',
            'hasRefundPolicy',
            'saveRefundPolicy',
            'refundPolicyDetails',
            'obtainMethod'
        ];

        for(const aValName of nextOrder)
        {
            if(this.validator[aValName])
            {
                nextValidators[aValName] = this.validator[aValName];
            }
        }

        this.validator = nextValidators;
    }

    /* istanbul ignore next */
    getAskingError(currency = 'usd')
    {
        return `Specify a valid currency amount greater than or equal to ${CurrencyService.getDisplayPrice(
            currency,
            FeeService.getMinListedPrice(this.props.paidAccount ? this.props.paidAccount : false),
            false
        )}`;
    }

    /* istanbul ignore next */
    getDynamicFields()
    {
        let out = {};
        let uniqueValue = [];
        const filterToUnique = exOpt => {
            const value = exchangeToValue(exOpt);
            const found = uniqueValue.find(aVal => aVal === value);
            if(!found)
            {
                uniqueValue.push(value);
                return true;
            }
            else
            {
                return false;
            }
        };
        if( this.props.exchangeList && 
            this.props.exchangeList.length > 1)
        {
            out.exchangeMethod = {
                name: 'Exchange Method',
                error: 'Specify which exchange method should be used',
                value: exchangeToValue(this.props.exchangeList.filter(exOpt => exOpt.type === EXCHANGE_TYPE_ONLINE)[0]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: this.props.exchangeList
                    .filter(filterToUnique)
                    .map(mapExchangeToValueItem),

            };
        }

        if( this.props.obtainTypeDetailList && 
            this.props.obtainTypeDetailList.length > 1)
        {
            out.obtainMethod = {
                name: 'Delivery Method',
                error: 'Specify which delivery method should be used',
                value: obtainToValue(this.props.obtainTypeDetailList[0]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: this.props.obtainTypeDetailList.map(mapObtainToValueItem),
            };
        }

        return out;
    }

    /* istanbul ignore next */
    controlValueChange(name, value, entity = null)
    {
        if(name === 'exchangeMethod')
        {
            this.setExchangeMethod(value);
        }
        super.controlValueChange(name, value, entity);
    }

    /* istanbul ignore next */
    getFieldsModel()
    {
        const isWeightPromptNeeded = _BidOnlinePaymentPrompt.loadWithWeightVisible(this.props, {});
        const currencyValue = "usd";
        const base = {
            state: {
                currency: {
                    value: currencyValue
                }
            }
        };
        const validateTitle = ValidateService.makeCurrencyFieldValue(base, 'currency', this.props.paidAccount ? this.props.paidAccount : false);
        const titleValue = CurrencyInputService.currencyToInput(this.props.asking);
        const titleValid = validateTitle(titleValue);
        return {
            currency: {
                name: 'Currency',
                error: 'Specify the currency type for the amount requested',
                value: currencyValue, 
                valueList: [],
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            title: {
                name: 'Bid*',
                error: this.getAskingError("usd"),
                value: titleValue,
                visible: true,
                valid: titleValid,
                touched: false,
                type: FORM_FIELD.TEXT,
            },
            details: {
                name: 'Details',
                error: 'Provide details regarding your bid, no more than 2170 characters',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
            weightPounds: {
                name: 'Shipping Weight Pounds',
                error: 'Specify the pounds in shipping weight',
                value: '',
                visible: isWeightPromptNeeded,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            weightOunces: {
                name: 'Shipping Weight Ounces',
                error: 'Specify the ounces in shipping weight',
                value: '',
                visible: isWeightPromptNeeded,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            hasRefundPolicy: {
                name: 'Refund Policy Offered',
                error: 'Do you offer a refund policy?',
                value: this.props.setupBid ? this.props.setupBid.hasRefundPolicy : false,
                visible: this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX,
            },
            saveRefundPolicy: {
                name: 'Save Refund Policy for Re-Use',
                error: 'Do you wish to save this policy for re-use?',
                value: false,
                visible: false,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            refundPolicyDetails: {
                name: 'Please explain your refund policy',
                error: 'Explain your refund policy',
                value: this.props.setupBid && this.props.setupBid.hasRefundPolicy ? this.props.setupBid.refundPolicy : '',
                visible: this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && this.props.setupBid ? this.props.setupBid.hasRefundPolicy : false,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    /* istanbul ignore next */
    setExchangeMethod(value)
    {
        if(this.props.setExchangeMethod)
        {
            this.props.setExchangeMethod(value);
        }
    }

    /* istanbul ignore next */
    isPromptingLocation(state)
    {
        const isOffer = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? true : false;
        const showList = isOffer ? [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND,
            OBTAIN_TYPE_ON_SITE
        ] : [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND,
            OBTAIN_TYPE_ON_SITE,
            OBTAIN_TYPE_PICK_UP
        ];
        if(this.props.obtainTypeDetailList && 
            this.props.obtainTypeDetailList.length > 0)
        {
            if(this.props.obtainTypeDetailList.length === 1)
            {
                return showList.includes(this.props.obtainTypeDetailList[0].type);
            }

            return showList.includes(state.obtainMethod.value);
        }
        return false;
    }

    /* istanbul ignore next */
    validateState(state)
    {
        let baseReturn = super.validateState(state);
        if(this.isPromptingLocation(state))
        {
            if(!this.state.selectedLocation)
            {
                baseReturn.isValid = false;
            }
        }
        return baseReturn;
    }

    /* istanbul ignore next */
    onLocationSelected(location)
    {
        let nextState = this.state;
        nextState.selectedLocation = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onLocationRemoveSelected()
    {
        let nextState = this.state;
        nextState.selectedLocation = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    getFormFieldsUi()
    {
        let output = [];
        for(const aField in this.validator)
        {
            try
            {
                if(this.state[aField].visible)
                {

                    output.push(
                        <section key={aField}>
                            { this.getFormFieldUi(aField) }
                        </section>
                    );
                }

                if(aField === 'obtainMethod' || (aField === 'details' && this.props.obtainTypeDetailList && this.props.obtainTypeDetailList.length === 1))
                {
                    if(this.isPromptingLocation(this.state))
                    {
                        const selectedType = this.state.obtainMethod ? this.state.obtainMethod.value : (
                            this.props.obtainTypeDetailList && this.props.obtainTypeDetailList[0] && this.props.obtainTypeDetailList[0].type ? 
                                this.props.obtainTypeDetailList[0].type : ''
                        );
                        const isOffer = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? true : false;
                        const title = getObtainTypeLocationTitleCopy(selectedType, isOffer);

                        output.push(
                            <LocationSelectorPanel
                                promptTitle={title}
                                key={`locationSelector${output.length}`}
                                selectedLocation={this.state.selectedLocation}
                                onSelected={this.onLocationSelected}
                                onRemoveSelected={this.onLocationRemoveSelected}
                                includeGps={true}
                            />
                        );
                    }
                }
            }
            catch(err)
            {
                throw new Error(`Unable to read ${aField}`);
            }
        }
        return output;
    }

    setupOnlinePaymentUi()
    {
        return (
            <div>
                {
                    this.state.payAccountLoaded && !this.state.payAccountSetupComplete ? (
                        <p>
                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to="/profile/income"
                            >
                                <span style={{
                                ...theme.getWarningTextStyle(theme)  
                                }}>
                                    Setup online payments
                                </span>
                            </NavLink>
                        </p>
                    ) : this.state.payCurrencyList.length < 1 ? 
                        <p>
                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to="/payout/add"
                            >
                                <span style={{
                                    ...theme.getWarningTextStyle(theme)  
                                }}>
                                    Add a payout account to receive online payments
                                </span>
                                
                            </NavLink>
                        </p> : ''
                }
                {
                    this.state.payAccountLoaded && 
                        ( 
                            !this.state.payAccountSetupComplete || 
                            this.state.payCurrencyList.length < 1
                        ) && 
                        this.props.exchangeList.length > 1 ? (
                            <div>
                                <button 
                                    className="test-bidOnlinePaymentUseAnotherMethodBtn"
                                    onClick={(event) => {
                                        const nextVal = this.props.exchangeList.filter(exOpt => exOpt.type !== EXCHANGE_TYPE_ONLINE)[0].type;
                                        this.setExchangeMethod(nextVal);
                                    }}
                                >
                                    Use another method
                                </button>
                            </div>
                        ) : ''
                }
            </div>
        );
        
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        const dynamicFields = this.getDynamicFields();
        let nextState = {
            ...baseState,
            ...fields,
            ...dynamicFields,
            payAccountLoaded: false,
            payAccountSetupComplete: false,
            payCurrencyList: [],
            selectedLocation: null
        };
        return nextState;
    }

    onLoadPayAccountResponse(response)
    {
        const nextState = this.state;
        nextState.contactingServer = false;
        nextState.payAccountLoaded = true;
        nextState.payAccountSetupComplete = response.account.accountSetupComplete;
        nextState.payCurrencyList = response.payments.receive.currency;
        nextState.currency.value = response.payments.receive.currency.length > 0 ? response.payments.receive.currency[0].toLowerCase() : '';
        nextState.title.error = this.getAskingError(nextState.currency.value);
        nextState.currency.valueList = response.payments.receive.currency.map(aCur => ({
            name: aCur,
            value: aCur.toLowerCase()
        }));

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    loadPayAccount()
    {
        this.setContactingServer(true);
        PayService.accountDetails({
            onResponse: this.onLoadPayAccountResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadPayAccount();
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? {
            offerId: this.props.contentId,
            title: CurrencyInputService.parse(this.state.title.value),
            details: this.state.details.value,
            selectedLocation: this.isPromptingLocation(this.state) ? this.state.selectedLocation.id : '',
            currency: this.state.currency.value,
            obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                this.props.obtainTypeDetailList.length > 0 ? 
                    obtainToValue(this.props.obtainTypeDetailList[0]) : ''
                ),
            exchangeMethod: EXCHANGE_TYPE_ONLINE,
        } : {
            seekId: this.props.contentId,
            title: CurrencyInputService.parse(this.state.title.value),
            details: this.state.details.value,
            selectedLocation: this.isPromptingLocation(this.state) ? this.state.selectedLocation.id : '',
            currency: this.state.currency.value,
            obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                this.props.obtainTypeDetailList.length > 0 ? 
                    obtainToValue(this.props.obtainTypeDetailList[0]) : ''
                ),
            weight: _BidOnlinePaymentPrompt.loadWithWeightVisible(this.props, this.state) ? {
                pounds: this.state.weightPounds.value,
                ounces: this.state.weightOunces.value
            } : {},
            exchangeMethod: EXCHANGE_TYPE_ONLINE,
            hasRefundPolicy: this.state.hasRefundPolicy.value,
            refundPolicyDetails: this.state.hasRefundPolicy.value ? this.state.refundPolicyDetails.value : '',
            saveRefundPolicy: 
                this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && 
                this.props.setupBid && 
                !this.props.setupBid.hasRefundPolicy ? this.state.saveRefundPolicy.value : false,
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.props.setBids(response.bids);
        this.setState(nextState);        
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        const request = this.getServerRequest();
        const requestName = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? 'addOfferBid' : 'addSeekBid';
        BidService[requestName]({
            request,
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getConfirmUi(confirmMessage = 'Bid Submitted')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Submit Bid')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    displayPriceUi()
    {
        const { total, fee, netTotal } = FeeService.getEstimated(
            this.state.currency.value,
            CurrencyInputService.parse(this.state.title.value),
            this.props.paidAccount ? this.props.paidAccount : false
        );

        const fieldList = [
            {
                title: 'Listed Bid',
                value: total
            },
            {
                title: 'Fee',
                value: fee
            },
            {
                title: 'Payout',
                value: netTotal
            }
        ];
        
        return (
            <div>
                {
                    fieldList.map((f, index) => 
                        <div 
                            key={index}
                            style={{
                                ...theme.getBidOnlinePaymentPromptPriceContainerStyle(theme)
                        }}>
                            <div style={{
                                ...theme.getBidOnlinePaymentPromptPriceTitleStyle(theme)
                            }}>
                                { f.title }
                            </div>
                            <div style={{
                                ...theme.getBidOnlinePaymentPromptPriceValueStyle(theme)
                            }}>
                                { 
                                    CurrencyService.getDisplayPrice(
                                        this.state.currency.value,
                                        f.value,
                                        false
                                    )
                                }
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>)
                }
            </div>
        );
        
    }

    getPromptUi()
    {
        return (
            <div style={{
                ...theme.getBidPromptStyle(theme),
            }}>
                <h2>
                    Place an online payment bid
                </h2>
                
                { this.setupOnlinePaymentUi() }
                
                { 
                    this.state.payAccountLoaded && this.state.payAccountSetupComplete && this.state.payCurrencyList.length > 0 ?
                        <div>
                            <p>
                                Make sure to include all relevant details.
                            </p>
                            <p>
                                <span style={{
                                    ...theme.getWarningTextStyle(theme),
                                }}>
                                    Bid field will be visible to all users, details section will only be visible to the poster
                                </span>
                            </p>

                            <p>
                                Online payments are deducted a transaction fee.  See our 
                                &nbsp;
                                <NavLink 
                                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                    to="/fees"
                                >
                                    fees page
                                </NavLink> 
                                &nbsp;
                                for more information.
                            </p>
                            {
                                this.state.title.valid ? this.displayPriceUi() : ''
                            }

                            { super.getPromptUi() }

                            <OnlineExchangeAdvicePanel />

                        </div> : ''
                }
            </div>
        );
    }
}

const BidOnlinePaymentPrompt = connect(mapStateToProps, mapDispatchToProps)(_BidOnlinePaymentPrompt);
export default BidOnlinePaymentPrompt;
