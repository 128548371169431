import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../service/resize';
import MainMenu from './../../ui/menu/main';
import MenuContainer from './../../ui/menu/core/menu-container';
import { useSelector } from 'react-redux';
import errorDetails from './../../store/select/error-details';
import DeviceOnlineSelector from '../../store/select/device-online';
import SystemHibernatingSelector from '../../store/select/system-hibernating';
import HibernatingPanel from '../../ui/panel/hibernating';
import ErrorPanel from './../../ui/panel/error';
import DisconnectedPanel from '../../ui/panel/disconnected';
import theme from './../../ui/theme';
import { Toaster } from 'react-hot-toast';

const PageTemplate = ({ children, childMenu = null, isUser = false, hideOnScroll = true }) => {
    const { 
        displayError,
        errorMessage
    } = useSelector(errorDetails);
    const isDeviceOnline = useSelector(DeviceOnlineSelector);
    const isHibernating = useSelector(SystemHibernatingSelector);

    const hasChildMenu = isUser && childMenu !== null;
    const getTopSpacerStyle = () => theme.getPageTopSpacerStyle(theme, hasChildMenu);
    const [ topSpacerStyle, setTopSpacerStyle ] = useState( getTopSpacerStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setTopSpacerStyle( getTopSpacerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getPageStyle(theme),
        }}>
            { 
                isHibernating ? <HibernatingPanel /> : (
                    displayError ? 
                        <ErrorPanel errorMessage={errorMessage} /> : (
                            !isDeviceOnline ? <DisconnectedPanel /> : 
                                <div>
                                    <Toaster /> 

                                    <MenuContainer 
                                        hideOnScroll={hideOnScroll}
                                    >
                                        <MainMenu />
                                        { 
                                            isUser && 
                                                childMenu 
                                        }
                                    </MenuContainer>

                                    <div style={topSpacerStyle}></div>

                                    { children }

                                </div>
                        )
                )      
            }
        </div>
    );
};

export default PageTemplate;
