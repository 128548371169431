import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../../service/resize';
import theme from './../../../../theme';

const Mpg = ({
    useMpgGlobal,
    onToggleUseMpgGlobal,
    estimatedMPG,
    mpgBuffer,
    onMpgBufferUpdated,
    mpgBufferValid
}) => {
    const getSmallTextErrorStyle = () => theme.getAbstractSmallTextError(theme);
    const [ smallTextErrorStyle, setSmallTextErrorStyle ] = useState( getSmallTextErrorStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSmallTextErrorStyle(  getSmallTextErrorStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <div>
                <input type="checkbox" checked={useMpgGlobal} onChange={onToggleUseMpgGlobal}  />

                <span>
                    { `Use ${estimatedMPG} MPG` }
                </span>
            </div>

            {
                !useMpgGlobal && 
                    <div>
                        <span>
                            Miles per gallon for this member
                        </span>
                        <input type="text" value={mpgBuffer} onChange={onMpgBufferUpdated}  />

                        {
                            !mpgBufferValid && 
                                <div style={smallTextErrorStyle}>
                                    Specify the miles per gallon you expect for this member's vehicle
                                </div>
                        }
                    </div>
            }

        </div>
    );
}

export default Mpg;
