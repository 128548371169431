import React from 'react';
import DatePanel from './../core/date';
import OfferSeekTypePanel from './../core/offer-seek-type';
import AttributeListPanel from './../core/attribute-list';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const OfferSummaryPanel = ({ offer }) => 
    <section>
        <h2>
            { offer.title }
        </h2>
        <DatePanel label="Posted" date={offer.createdOn} />
        <OfferSeekTypePanel offerSeekType={offer.offerSeekType} />
        <AttributeListPanel attributeList={offer.attributeList} />
        &nbsp;
        <p>
            { offer.details }
        </p>
        &nbsp;
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/offer/${offer.id}`}
        >
            More Details
        </NavLink>
    </section>

export default OfferSummaryPanel;
