import React from 'react';
import DisputedTransactionView from './disputed-transaction-view';
import ReviewedExchangeView from './reviewed-exchange-view';
import AwaitingPaymentView from './awaiting-payment-view';
import PaymentSentView from './payment-sent-view';
import PaymentConfirmedView from './payment-confirmed-view';
import FulfilledView from './fulfilled-view';
import AwaitingRefundView from './awaiting-refund-view';
import FulFilledConfirmedView from './fulfilled-confirmed-view';

const StepView = ({ 
    exchange, 
    onPaid,
    onPaymentConfirmed,
    onFulfilled,
    onExchangeConfirmed,
    onReviewed,
    onRefundReviewed,
    onAcceptDisputeLoss,
    onDisputeResponded,
    onLoadDataResponse,
    onProvidedToShipping
}) => exchange.bid.lockedForDispute && !exchange.bid.hasRefunded ? 
    <DisputedTransactionView 
        onAcceptDisputeLoss={onAcceptDisputeLoss} 
        exchange={exchange} 
        onResponded={onDisputeResponded} 
    /> : (
        exchange.bid && exchange.bid.refundRequested && !exchange.bid.refundRequestReviewed ? 
            <AwaitingRefundView 
                exchange={exchange} 
                onRefundReviewed={onRefundReviewed} 
            /> : ( 
                exchange.reviewed ? 
                    <ReviewedExchangeView 
                        exchange={exchange} 
                    /> : (
                        exchange.fulfilledConfirmed ? 
                            <FulFilledConfirmedView 
                                exchange={exchange} 
                                onReviewed={onReviewed} 
                            /> : (
                                exchange.fulfilled ? 
                                    <FulfilledView 
                                        exchange={exchange} 
                                        onExchangeConfirmed={onExchangeConfirmed}
                                    /> : (
                                        exchange.paidConfirmed ? 
                                            <PaymentConfirmedView 
                                                onProvidedToShipping={onProvidedToShipping} 
                                                exchange={exchange} 
                                                onFulfilled={onFulfilled} 
                                                onLoadDataResponse={onLoadDataResponse} 
                                            /> : (
                                                exchange.paid ? 
                                                    <PaymentSentView 
                                                        exchange={exchange} 
                                                        onPaymentConfirmed={onPaymentConfirmed}  
                                                    /> : 
                                                        <AwaitingPaymentView 
                                                            exchange={exchange} 
                                                            onPaid={onPaid} 
                                                        />
                                                )
                    )
                )
            )
        )
    );

export default StepView;
