import React from 'react';
import DetailPanel from './../core/detail';
import LocationService from './../../../service/location';
import ManageLocationsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import config from './../../../config';

class _ManageLocationsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onDeleteLocation = this.onDeleteLocation.bind(this);
        this.onSetAsDefault = this.onSetAsDefault.bind(this);
        this.onLocationUpdate = this.onLocationUpdate.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.scheduleCheckGeoJob = this.scheduleCheckGeoJob.bind(this);
        this.checkGeoJob = this.checkGeoJob.bind(this);
        this.onLoadLocationByIdResponse = this.onLoadLocationByIdResponse.bind(this);
        this.isPendingOrProcessingGeoData = this.isPendingOrProcessingGeoData.bind(this);
        this.hasPendingOrProcessingGeoData = this.hasPendingOrProcessingGeoData.bind(this);
        this.removeNotice = this.removeNotice.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({ });
        LocationService.list({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });   
    }

    removeNotice(userLocationId, userLocationNoticeId)
    {
        super.loadData({ });
        LocationService.removeNotice({
            request: {
                userLocationId, 
                userLocationNoticeId
            }, 
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getDefaultState()
    {
        return {
            contactingServer: false,
            message: '',
            data: undefined,
            redirectTo: undefined,
            pendingGeoProcessingLocationIdList: []
        };
    }

    hasPendingOrProcessingGeoData()
    {
        return this.state.pendingGeoProcessingLocationIdList.length > 0 ? true : false;
    }

    isPendingOrProcessingGeoData(loc)
    {
        return !loc.hasGps && (
            loc.pendingGeoLocProcessing 
            || 
            !loc.geoLocFailed
        ) ? true : false;
    }

    onLoadDataResponse(response)
    {
        const pendingGeoProcessingLocationIdList = response.locations.list
            .filter(loc => this.isPendingOrProcessingGeoData(loc))
            .map(loc => loc.id);
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: response,
            pendingGeoProcessingLocationIdList
        };
        this.setState(nextState, () => {
            if(this.hasPendingOrProcessingGeoData())
            {
                this.scheduleCheckGeoJob();
            }
        });
        
    }

    /* istanbul ignore next */
    scheduleCheckGeoJob()
    {
        setTimeout(
            () => {
                this.checkGeoJob()
            }, 
            config.interval.checkJobStatus
        );
    }

    /* istanbul ignore next */
    checkGeoJob()
    {
        if(this.hasPendingOrProcessingGeoData())
        {
            const locationId = this.state.pendingGeoProcessingLocationIdList[0];
            LocationService.getLocation({
                request: {
                    locationId
                },
                onResponse: this.onLoadLocationByIdResponse,
                onError: this.onLoadDataError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
    }

    /* istanbul ignore next */
    onLoadLocationByIdResponse(response)
    {
        if(this.isPendingOrProcessingGeoData(response.location))
        {
            this.scheduleCheckGeoJob();
        } else {
            window.location.reload();
        }
    }

    /* istanbul ignore next */
    onLocationUpdate(response)
    {
        this.onLoadDataResponse(response);
    }

    onSetAsDefault(location)
    {
        super.loadData({ });
        LocationService.setAsDefault({
            request: LocationService.getSetAsDefaultRequest(location.id),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });   
    }

    onDeleteLocation(location)
    {
        super.loadData({ });
        LocationService.remove({
            request: LocationService.getRemoveRequest(location.id),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });   
    }

    componentDidMount()
    {
        this.loadData();
    }

    getLoadedDataDetailUi()
    {
        return (
            <ManageLocationsViewPanel 
                data={this.state.data} 
                removeNotice={this.removeNotice}
                onDeleteLocation={this.onDeleteLocation}
                onLocationUpdate={this.onLocationUpdate}
                onLoadDataResponse={this.onLoadDataResponse}
                onSetAsDefault={this.onSetAsDefault}
                reloadData={this.loadData}
            />
        );
    }
}

const ManageLocationsPanel = connect(mapStateToProps, mapDispatchToProps)(_ManageLocationsPanel);
export default ManageLocationsPanel;
