import React from 'react';

const UnknownRenderType = ({
    selectedFrame
}) => 
    <div>
        Unknown Frame Render Type - ID: {selectedFrame.frameDetails.id}
    </div>

export default UnknownRenderType;
