export const getUserReviewHistoryIconStyle = (theme, icon) => ({
    width: '4rem',
    height: '4rem',
    margin: '1rem auto 0',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${icon})`,
});

export const getUserReviewHistoryContainer = (theme) => ({
    backgroundColor: theme.panelWarmColor,
    padding: '.5rem',
    margin: '1rem auto',
    borderRadius: theme.isTablet() ? '.25rem' : '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
});

export const getUserReviewHistoryDateStyle = (theme) => ({
    fontSize: '16pt'
});

export const getUserReviewDetailStyle = (theme) => ({
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
});
