import theme from './../../theme';
import DisputeRespondPrompt from './../../prompt/dispute-respond';

export const reasonDisplayName = {
    'bank_cannot_process': 'Bank Cannot Process',
    'check_returned': 'Check Returned',
    'credit_not_processed': 'Credit Not Processed',
    'customer_initiated': 'Customer Initiated',
    'debit_not_authorized': 'Debit Not Authorized',
    'duplicate': 'Duplicate',
    'fraudulent': 'Fraudulent',
    'general': 'General',
    'incorrect_account_details': 'Incorrect Account Details',
    'insufficient_funds': 'Insufficient Funds',
    'product_not_received': 'Product Not Received',
    'product_unacceptable': 'Product Unacceptable',
    'subscription_canceled': 'Subscription Cancelled',
    'unrecognized': 'Unrecognized',
    
};

export const evidenceFieldDisplayNames = {
    "access_activity_log": 'Access Activity Log - Used to show proof that the customer accessed or downloaded a purchased digital product',
    "billing_address": 'Customer Billing Address',
    "customer_name": 'Customer Name',
    "customer_email_address": 'Customer Email Address',
    "customer_purchase_ip": 'Customer Purchase IP Address',
    "duplicate_charge_explanation": 'Duplicate Charge Explanation - An explanation of the difference between the disputed charge versus the prior charge that appears to be a duplicate.',
    "product_description": 'Product Description - A description of the product or service that was sold.',
    "refund_policy_disclosure": 'Refund Policy Disclosure - Documentation demonstrating that the customer was shown your refund policy prior to purchase. ',
    "refund_refusal_explanation": 'Refund Refusal Explanation - A justification for why the customer is not entitled to a refund.',
    "service_date": 'Service Date - The date on which the customer received or began receiving the purchased service',
    "shipping_address": 'Shipping Address',
    "shipping_carrier": 'Shipping Carrier',
    "shipping_date": 'Shipping Date',
    "shipping_tracking_number": 'Shipping Tracking Number - The tracking number for a physical product, obtained from the delivery service. If multiple tracking numbers were generated for this purchase, please separate them with commas.',
    "uncategorized_text": 'Any additional evidence or statements.',
    "refund_policy": 'Refund Policy - Your refund policy, as shown to the customer.',
    "receipt": 'Receipt - Any receipt or message sent to the customer notifying them of the charge.',
    "customer_signature": 'Customer Signature',
    "duplicate_charge_documentation": 'Duplicate Charge Documentation - Documentation for the prior charge that can uniquely identify the charge, such as a receipt, shipping label, work order, etc. This document should be paired with a similar document from the disputed payment that proves the two payments are separate.',
    "shipping_documentation": 'Shipping Documentation - Documentation showing proof that a product was shipped to the customer at the same address the customer provided to you. This could include a copy of the shipment receipt, shipping label, etc. It should show the customer’s full shipping address, if possible.',
    "service_documentation": 'Service Documentation - Documentation showing proof that a service was provided to the customer. This could include a copy of a signed contract, work order, or other form of written agreement.',
    "customer_communication": 'Customer Communication',
    "uncategorized_file": 'Any additional evidence or statement documents.',
};

const omitFieldList = [
    'customer_purchase_ip'
];

const isValidFieldForDispute = (aField, dispute) => {
    const customerDetailsReasonList = [
        'fraudulent',
        'customer_initiated',
        'general',
        'incorrect_account_details',
        'unrecognized'
    ];

    const refundList = [
        'customer_initiated',
        'general',
        'product_not_received',
        'product_unacceptable'
    ];

    // istanbul ignore next 
    switch(aField)
    {
        case 'customer_purchase_ip':
            return customerDetailsReasonList.includes(dispute.reason);
        
        case 'customer_email_address':
            return customerDetailsReasonList.includes(dispute.reason);
        
        case 'customer_name':
            return customerDetailsReasonList.includes(dispute.reason);

        case 'product_description':
            return [
                'customer_initiated',
                'general',
                'unrecognized'
            ].includes(dispute.reason);

        case 'billing_address':
            return [
                'bank_cannot_process',
                'check_returned',
                'credit_not_processed',
                'fraudulent',
                'customer_initiated',
                'debit_not_authorized',
                'general',
                'incorrect_account_details',
                'unrecognized'
            ].includes(dispute.reason);

        case 'access_activity_log':
            return dispute.reason === 'product_not_received';

        case 'duplicate_charge_explanation':
            return dispute.reason === 'duplicate';

        case 'duplicate_charge_documentation':
            return dispute.reason === 'duplicate';

        case 'refund_policy_disclosure':
            return refundList.includes(dispute.reason);

        case 'refund_refusal_explanation':
            return refundList.includes(dispute.reason);
        
        case 'service_date':
            return refundList.includes(dispute.reason);

        case 'shipping_address':
            return refundList.includes(dispute.reason);

        case 'shipping_carrier':
            return refundList.includes(dispute.reason);

        case 'shipping_date':
            return refundList.includes(dispute.reason);

        case 'shipping_tracking_number':
            return refundList.includes(dispute.reason);
    
        case 'refund_policy':
            return refundList.includes(dispute.reason);

        case 'receipt':
            return refundList.includes(dispute.reason);

        case 'customer_signature':
            return refundList.includes(dispute.reason);

        case 'shipping_documentation':
            return refundList.includes(dispute.reason);

        case 'service_documentation':
            return refundList.includes(dispute.reason);

        case 'customer_communication':
            return true;

        case 'uncategorized_file':
            return true;

        case 'uncategorized_text':
            return true;
            
        default:
            return true;
    }
};

const VALID_FILE_FORMATS = '.pdf,.jpeg,.png';
export const fileFields = {
    'uncategorized_file': {
        validFormats: VALID_FILE_FORMATS
    },
    'customer_signature': {
        validFormats: VALID_FILE_FORMATS
    },
    'duplicate_charge_documentation': {
        validFormats: VALID_FILE_FORMATS
    },
    'receipt': {
        validFormats: VALID_FILE_FORMATS
    },
    'refund_policy': {
        validFormats: VALID_FILE_FORMATS
    },
    'service_documentation': {
        validFormats: VALID_FILE_FORMATS
    },
    'shipping_documentation': {
        validFormats: VALID_FILE_FORMATS
    },
    'customer_communication': {
        validFormats: VALID_FILE_FORMATS
    }
};

const getAvailableEvidence = (dispute) => Object.keys(evidenceFieldDisplayNames)
    .filter(fieldKey => ! dispute.existingEvidence.includes(fieldKey))
    .filter(fieldKey => ! omitFieldList.includes(fieldKey))
    .filter(fieldKey => isValidFieldForDispute(fieldKey, dispute));

const TopCopy = (parms) => 
    <p style={{
        ...theme.getAbstractTextAlignLeftStyle(theme)
    }}>
        A dispute occurs when a customer questions your charge with their card issuer. 
        When this happens, you're given the opportunity to respond to the dispute with evidence that shows that the charge is legitimate.
        When you receive a dispute, you can respond to it and submit the appropriate evidence or accept it and issue a refund. &nbsp;
        <strong>
            If you receive a dispute, contact the customer and discuss it before you respond. 
        </strong> 
        &nbsp; It’s possible that they didn’t recognize or remember the transaction when they viewed their statement.
    </p>

const BottomCopy = (parms) => 
    <div style={{
        ...theme.getAbstractTextAlignLeftStyle(theme)
    }}>
        <p>
            The dispute process involves multiple parties. Although Bears.Exchange doesn’t decide the outcome of the dispute, 
            we do convey your evidence to our financial partners. Our financial partners then pass your evidence to the card issuers, 
            if they deem it sufficient.
        </p>
        <p>
            Many inquiries and retrievals are the result of the cardholder not recognizing the transaction—providing basic information about 
            your business and what was purchased is usually sufficient to close the case. However, if the cardholder refutes your evidence, 
            or the card issuer considers it insufficient, the card issuer might escalate the inquiry or retrieval into a chargeback and 
            reclaim the cardholder’s funds. &nbsp;
            <strong>
                It’s important to resolve the case while it’s still in the inquiry or retrieval stage, before it escalates.
            </strong>
        </p>
        <p>
            You are only able to send a single response.  Please provide as much detail as you can.  If you don't have details for a field, leave the field blank.
        </p>
    </div>

// istanbul ignore next 
const ExistingEvidence = ({ dispute }) => dispute.existingEvidence.length > 0 ? 
    <div>
        <h4>
            Existing evidence we will include with your response
        </h4>
        <ul style={{ 
            ...theme.getGeneralDefaultBulletStyle(theme), 
            ...theme.getExchangeDetailDisputeReasonContainerStyle(theme),
            ...theme.getAbstractBottomMarginStyle(theme),
        }}>
            {
                dispute.existingEvidence.map((fieldName, index) => 
                    <li key={index} 
                        style={{ 
                            ...theme.getAbstractTextAlignLeftStyle(theme),
                    }}>
                        { 
                            evidenceFieldDisplayNames[fieldName] ? evidenceFieldDisplayNames[fieldName] : fieldName
                        }
                    </li>
                )
            }
        </ul>
    </div> : ''

const DisputeRespondViewPanel = ({ data: dispute, exchangeId, onAcceptDisputeLoss, onResponded }) => 
    <div style={{
        ...theme.getExchangeDetailReviewRefundRequestStyle(theme)
    }}>
        <h3>
            Disputed Charge
        </h3>
        <TopCopy />
        
        <h3>Dispute Reason</h3>

        <p>
            { reasonDisplayName[dispute.reason] }
        </p>
        
        <ExistingEvidence dispute={dispute} />

        <h3>
            Challenge Dispute
        </h3>
        
        <BottomCopy />

        <p>
            File uploads can be in PDF, JPEG, or PNG formats.
        </p>

        <DisputeRespondPrompt onResponded={onResponded} evidenceFields={ getAvailableEvidence(dispute) } exchangeId={exchangeId} dispute={dispute} />

        <p>
            or
        </p>

        <p>
            If you do not wish to challenge the dispute you can accept the dispute loss <strong> and incur a fee</strong>
        </p>

        <div>
            <button onClick={
                /* istanbul ignore next */
                event => { onAcceptDisputeLoss() }
            }>
                Accept Dispute Loss
            </button>
        </div>

        
    </div>

export default DisputeRespondViewPanel;
