import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const ActorDetails = ({ usersDetails, actorId, amIActor }) => {
    const user = usersDetails.find(aUser => aUser.userId === actorId);
    return (
        <span>
            &nbsp;
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/user/${actorId}`}
            >
                { amIActor ? 'You' : user.username }
            </NavLink>
        </span>
    );
};

export default ActorDetails;
