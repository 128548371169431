import { FLAGGED_CONTENT_TYPE_ONLINE_PAY } from './flagged-content-type';
const FLAGGING_REASON_SCAM = 'scam';
const FLAGGING_REASON_ILLEGAL = 'illegal';
const FLAGGING_REASON_SPAM = 'spam';
const FLAGGING_REASON_OTHER = 'other';
const FLAGGING_REASON_ONLINE_FRAUD = 'fraud';
const FLAGGING_REASON_ONLINE_NOT_RECEIVED = 'not-received';
 
const validValues = [
    FLAGGING_REASON_SCAM,
    FLAGGING_REASON_ILLEGAL,
    FLAGGING_REASON_SPAM,
    FLAGGING_REASON_OTHER,
    FLAGGING_REASON_ONLINE_FRAUD,
    FLAGGING_REASON_ONLINE_NOT_RECEIVED
];

const getDropDownValues = (flaggedContentType) => flaggedContentType === FLAGGED_CONTENT_TYPE_ONLINE_PAY ? [
    {
        name: 'Fraud',
        value: FLAGGING_REASON_ONLINE_FRAUD
    },
    {
        name: 'Other',
        value: FLAGGING_REASON_OTHER
    }
] : [
    {
        name: 'Scam',
        value: FLAGGING_REASON_SCAM
    },
    {
        name: 'Illegal',
        value: FLAGGING_REASON_ILLEGAL
    },
    {
        name: 'Spam',
        value: FLAGGING_REASON_SPAM
    },
    {
        name: 'Other',
        value: FLAGGING_REASON_OTHER
    }
];

const isValid = value => !! validValues.includes(value);

const FlaggingReasonEnum = {
    FLAGGING_REASON_SCAM,
    FLAGGING_REASON_ILLEGAL,
    FLAGGING_REASON_SPAM,
    FLAGGING_REASON_OTHER,
    FLAGGING_REASON_ONLINE_FRAUD,
    FLAGGING_REASON_ONLINE_NOT_RECEIVED,
    getDropDownValues,
    isValid,
};

export default FlaggingReasonEnum;
