import React from 'react';
import AuthPageTemplate from './template/auth-page';
import AddPayMethodPanel from './../ui/panel/add-pay-method';
import theme from './../ui/theme';
import ProfileMenu from './../ui/menu/profile';

const AddPayMethodPage = ({ setPayClientSecret, payClientDetails, payClientSecret }) => 
    <AuthPageTemplate
        childMenu={
            <div>
                <ProfileMenu />
            </div>
        }
    >
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <AddPayMethodPanel 
                setPayClientSecret={setPayClientSecret} 
                payClientSecret={payClientSecret}
                payClientDetails={payClientDetails}
            />
        </div>
    </AuthPageTemplate>

export default AddPayMethodPage;
