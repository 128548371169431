export const getExchangeReviewExperienceButton = (theme, icon) => ({
    width: '4rem',
    height: '4rem',
    marginTop: '.75rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
});

export const getExchangeReviewExperienceBar = (theme) => ({
    width: '100%',
    margin: '0 0 .75rem',
    display: 'flex',
    justifyContent: 'center'
});
