import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import Pagination from './../../prompt/core/pagination';
import ImgPanel from './../core/img';
import ReportedIssueChatPrompt from '../../prompt/reported-issue-chat';
import UiService from '../../../service/ui';

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            hideAllWhenNoButtonsVisible={true}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const ChatMessage = ({ chat }) => {

    return (
        <div style={{
            ...theme.getExchangeChatSpaceStyle(theme),
        }}>
            <div>
                <div style={{
                    ...theme.getMediumTextStyle(theme),
                    ...theme.getExchangeChatUsernameStyle(theme),
                }}>
                    <NavLink to={`/user/${chat.userId}`}>
                        <span>
                            { chat.username }
                        </span>
                        
                        {
                            chat.staffMessage && 
                                <div>
                                    <strong>
                                        System Admin
                                    </strong>
                                </div>
                        }
                    </NavLink>
                </div>

                <div style={{
                    ...theme.getSmallTextStyle(theme),
                    ...theme.getExchangeChatTimeStyle(theme),
                }}>   
                    { UiService.timeSince(chat.createdOn) }
                </div>
            </div>
            <div style={{ clear: 'both' }}></div>
            {
                <div>
                    <div style={{
                        ...theme.getExchangeChatMessageStyle(theme),
                    }}>
                        { chat.message }
                    </div>
                    <div>
                        {
                            chat.imgList.length > 0 ? <ImgPanel imgList={chat.imgList} /> : ''
                        }
                    </div>
                </div>
            }
            <hr />
        </div>

    );
};

const PopulatedChat = ({ chatDetails, onLoadSpecifiedPage }) => {

    return (
        <div>
            { getPaginationUi({ data: chatDetails, onLoadSpecifiedPage }) }

            {
                chatDetails.list.map((chat, index) => 
                    <ChatMessage
                        chat={chat}
                        key={index}
                    />
                )
            }

            { getPaginationUi({ data: chatDetails, onLoadSpecifiedPage }) }
        </div>
    );
};

const EmptyChat = () => {

    return (
        <div>
            No Messages
        </div>
    );
};

const IssueChat = ({ chatDetails, onLoadSpecifiedPage }) => chatDetails.list.length > 0 ? 
        <PopulatedChat 
            chatDetails={chatDetails} 
            onLoadSpecifiedPage={onLoadSpecifiedPage}
        /> : 
            <EmptyChat />

const ReportedIssueDetailsViewPanel = ({
    data,
    appIssueId,
    onLoadSpecifiedPage,
    onLoadChats
}) => {
    const issue = data.issue;
    const chatDetails = data.chat;
    return (
        <div style={{ 
            ...theme.getProfilePwdBodyStyle(theme),
            ...theme.getGeneralTopSpacerStyle(theme)
        }}>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                My Reported Issue
            </h2>
            <p>
                { issue.details }
            </p>
            <div style={{ 
                ...theme.getInLineTextStyle(theme),
                ...theme.getInLineColumnStyle(theme)
            }}>
                <h3>
                    Issue Chat
                </h3>
                <ReportedIssueChatPrompt 
                    appIssueId={appIssueId}
                    onLoadChats={onLoadChats}
                />
                
                <IssueChat 
                    chatDetails={chatDetails} 
                    onLoadSpecifiedPage={onLoadSpecifiedPage}
                />
            </div>
            <div style={{ 
                ...theme.getGeneralTextLinkStyle(theme),
                ...theme.getGeneralBottomSpacerStyle(theme),
                ...theme.getGeneralTextStyle(theme) 
            }}>
                <NavLink
                    to={`/profile/reported/issues`}
                >
                    Review reported issues.
                </NavLink>
            </div>
        </div>
    );
};

export default ReportedIssueDetailsViewPanel;
