import React from 'react';
import UserActionService from './../../../../service/user-action';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';

class _AdminUpdateUserPermissionPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _AdminUpdateUserPermissionPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            allowAccess: {
                isValid: ValidateService.anyValue
            },
            notes: {
                isValid: ValidateService.textLines
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            allowAccess: {
                name: 'Allow Action',
                error: 'Specify if action can be performed by this user',
                value: this.props.allowAccess,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            notes: {
                name: 'Notes',
                error: 'Specify details about permission, no more than 2170 characters',
                value: this.props.notes,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }


    onSendToServer()
    {
        UserActionService.adminUpdate({
            request: this.getServerRequest(),
            onResponse: this.props.onLoadDataResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            targetUserId: this.props.targetUserId,
            type: this.props.type,
            allowAccess: this.state.allowAccess.value,
            notes: this.state.notes.value,
        };
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Permissions Updated')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Save')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getPromptUi()
    {
        return (
            <div>
                <h3>
                    { this.props.title }
                </h3>
                {
                    super.getPromptUi()
                }
            </div>
        );
        
    }
}

const AdminUpdateUserPermissionPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminUpdateUserPermissionPrompt);

export default AdminUpdateUserPermissionPrompt;
