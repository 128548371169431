import { createStore, applyMiddleware } from 'redux';
import { REDUX_STORAGE } from './state/constants';
import INI_STATE from './state/initial';
import AuthReducer from './state/reducer/auth-reducer';
import Save from './save';
import config from '../config';

// istanbul ignore next 
export const GetStoredState = (initialState = INI_STATE) => {
    try 
    {
        const foundState = localStorage[REDUX_STORAGE] ? JSON.parse(localStorage[REDUX_STORAGE]) : initialState;
        if(foundState.clientVersion === config.version)
        {
            return foundState;
        }
        else 
        {
            return initialState;
        }
    }
    catch(e)
    {
        return initialState;
    }
};

const GenerateStore = (initialState = INI_STATE) => applyMiddleware(Save)(createStore)(
    AuthReducer,
    GetStoredState(initialState)
);

export default GenerateStore;
