import React from 'react';
import { evidenceFieldDisplayNames, fileFields } from './../../panel/dispute-respond/view';
import ExchangeService from './../../../service/exchange';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import theme from './../../theme';

class _DisputeRespondPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _DisputeRespondPrompt.getValidators(parms));

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getServerFileList = this.getServerFileList.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onAddFile = this.onAddFile.bind(this);
        this.makeParseFileDetails = this.makeParseFileDetails.bind(this);

        this.state = this.getDefaultState();
    }

    /* istanbul ignore next */
    static getValidators(parms)
    {
        let out = {};
        if(parms.evidenceFields)
        {
            parms.evidenceFields.forEach((value) => {
                out[value] = {
                    isValid: ValidateService.textLines
                };
            });
        }
        return out;
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    /* istanbul ignore next */
    onAddFile(fileRec)
    {
        let nextState = this.state;
        let nextFileList = nextState.fileList.filter(rec => rec.name !== fileRec.name);
        nextFileList.push(fileRec);
        nextState.fileList = nextFileList;
        this.setState(nextState);
    }

    makeParseFileDetails(fieldName)
    {
        return (aFile) => ({
            file: aFile,
            filename: aFile.name,
            name: fieldName
        });
    }

    /* istanbul ignore next */
    getFieldsModel()
    {
        let out = {};
        if(this.props.evidenceFields)
        {
            this.props.evidenceFields.forEach((value) => {
                const parseFileDetails = this.makeParseFileDetails(value);

                out[value] = {
                    name: evidenceFieldDisplayNames[value],
                    error: 'Provide details',
                    value: '',
                    onAddFile: this.onAddFile,
                    parseFileDetails,
                    visible: true,
                    valid: true,
                    touched: false,
                    validFormats: fileFields[value] ? fileFields[value].validFormats : undefined,
                    type: fileFields[value] ? FORM_FIELD.FILE : FORM_FIELD.TEXT_AREA,
                };
            });
        }
        return out;
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            fileList: [],
            isValid: true
        };
    }

    getServerFileList()
    {
        return this.state.fileList;
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        let request = {
            exchangeId: this.props.exchangeId,
            fileToFieldMap: this.state.fileList.map(aRec => ({
                fileName: aRec.filename,
                fieldName: aRec.name
            }))
        };

        this.props.evidenceFields.forEach((value) => {
            // is not file ?
            if(fileFields[value] === undefined)
            {
                if(this.state[value].value !== '')
                {
                    
                    request[value] = this.state[value].value;
                }
            }
        });

        return request;
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
        if(this.props.onResponded)
        {
            this.props.onResponded(response);
        }
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        ExchangeService.disputeRespond({
            request: this.getServerRequest(),
            fileList: this.getServerFileList(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Response Sent')
    {
        return (
            <section>
                <p style={{
                    ...theme.getWarningTextStyle(theme),
                }}>
                    { this.props.confirmMessage ? this.props.confirmMessage : confirmMessage }
                </p>
            </section>
        );
    }

    getSubmitButtonUi(buttonText = 'Respond')
    {
        return super.getSubmitButtonUi(buttonText);
    }

}

const DisputeRespondPrompt = connect(mapStateToProps, mapDispatchToProps)(_DisputeRespondPrompt);
export default DisputeRespondPrompt;
