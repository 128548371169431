import React from 'react';
import AuthPageTemplate from './template/auth-page';
import AddPayoutPanel from './../ui/panel/add-payout';
import theme from './../ui/theme';
import ProfileMenu from './../ui/menu/profile';

const AddPayoutPage = () => 
    <AuthPageTemplate 
        childMenu={
            <div>
                <ProfileMenu />
            </div>
        }
    >
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <AddPayoutPanel />
        </div>
    </AuthPageTemplate>

export default AddPayoutPage;
