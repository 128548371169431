import React from 'react';
import ActorDetails from './actor-details';
import ExchangeReviewPrompt from './../../prompt/exchange-review';

const FulFilledConfirmedView = ({ exchange, onReviewed }) =>
    <section>
        {
            exchange.bid.hasRefunded ? '' : 
                <div>
                    <h2>
                        Awaiting Exchange Review from
                        <ActorDetails 
                            usersDetails={exchange.usersDetails} 
                            actorId={exchange.actorId} 
                            amIActor={exchange.amIActor}
                        />
                    </h2>
                    {
                        exchange.amIActor && 
                            <p>
                                Review your experience to finish this exchange.
                            </p>
                    }
                </div>
        }
        {   (
                ( 
                    (exchange.bid.payFlagged && exchange.bid.flagReviewed ) 
                    ||
                    (exchange.bid.refundRequested && exchange.bid.refundRequestReviewed)
                )
                && !exchange.bid.hasRefunded
            ) ? 
            <div>
                <p>
                    Transaction has been reviewed and the refund request was rejected.
                </p>
            </div> : ''
        }
        {
            (( exchange.bid.payFlagged && !exchange.bid.flagReviewed) || (exchange.bid.refundRequested && !exchange.bid.refundRequestReviewed ) || exchange.bid.hasRefunded ) ? (
                exchange.bid.hasRefunded ? 'Transaction refunded.  Exchange has halted.' : 
                    exchange.bid.payFlagged ? 'Transaction has been flagged for review.' : 'Transaction has been paused for refund request'
                ) : (
                    exchange.amIActor ? 
                        <ExchangeReviewPrompt 
                            exchange={exchange}
                            onReviewed={onReviewed}
                        /> : ''
                )
        }
    </section>

export default FulFilledConfirmedView;
