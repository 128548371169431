import dinero from 'dinero.js';

class MathService 
{
    static subtractCurrency(currency, total, sub)
    {
        return parseInt(total) - parseInt(sub);
    }

    static multiplyCurrency(currency, total, mul)
    {
        const base = dinero({
            amount: parseInt(total),
            currency: currency.toUpperCase()
        });
        const result = base.multiply(mul);
        return result.getAmount();
    }

    static getFuelCost(currency, distanceInMiles, estimatedMPG, estimatedDollarsPerGallon)
    {
        try 
        {
            if(distanceInMiles < 1)
            {
                distanceInMiles = 1;
            }
            const roundedDistance = Math.round(parseFloat(distanceInMiles));
            let gallons = Math.round(parseFloat(distanceInMiles) / estimatedMPG);
            if(gallons < 1)
            {
                gallons = 1;
            }
            const base = dinero({
                amount: parseInt(estimatedDollarsPerGallon),
                currency: currency.toUpperCase()
            });
            const result = base.multiply(gallons);
            return {
                dollarsPerGallon: estimatedDollarsPerGallon,
                distance: roundedDistance,
                mpg: estimatedMPG,
                gallons: gallons,
                cost: result.getAmount(),
                error: false,
            };
        } 
        catch(err)
        {
            return {
                dollarsPerGallon: estimatedDollarsPerGallon,
                distance: distanceInMiles,
                mpg: estimatedMPG,
                gallons: 0,
                cost: 0,
                error: true,
                details: err.message
            };
        }
    }
}

export default MathService;