export const CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP = 'ini-pickup';
export const CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF = 'dropoff';
export const CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP = 'pickup';
export const CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_TRIP = 'trip';
export const CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT = 'sort';
export const CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP = 'final-drop';

const validValues = [
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_TRIP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP
];

const isValid = value => validValues.includes(value);

const CarrierGroupDeliveryPlanStepTypeEnum = {
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_TRIP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    isValid
};

export default CarrierGroupDeliveryPlanStepTypeEnum;
