import CarrierLocationService from './../../../service/carrier-location';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import LocationSelectorPanel from './../../panel/location-selector';

class _AddCarrierLocationPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _AddCarrierLocationPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.validateState = this.validateState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationRemoveSelected = this.onLocationRemoveSelected.bind(this);
        
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            maxServiceDistanceMiles: {
                isValid: ValidateService.anyValue
            },
            isPublicDropOff: {
                isValid: ValidateService.anyValue
            },
            isDeliveryHub: {
                isValid: ValidateService.anyValue
            }
        };
    }

    getFieldsModel()
    {
        return {
            maxServiceDistanceMiles: {
                name: 'Max Service Distance',
                error: 'Specify the max distance this location will service',
                value: 50,
                valueList: [
                    {
                        name: '5 Miles',
                        value: 5
                    },
                    {
                        name: '10 Miles',
                        value: 10
                    },
                    {
                        name: '20 Miles',
                        value: 20
                    },
                    {
                        name: '35 Miles',
                        value: 35
                    },
                    {
                        name: '50 Miles',
                        value: 50
                    },
                    {
                        name: '75 Miles',
                        value: 75
                    },
                    {
                        name: '100 Miles',
                        value: 100
                    },
                    {
                        name: '125 Miles',
                        value: 125
                    },
                    {
                        name: '150 Miles',
                        value: 150
                    },
                    {
                        name: '175 Miles',
                        value: 175
                    },
                    {
                        name: '200 Miles',
                        value: 200
                    },
                    {
                        name: '300 Miles',
                        value: 300
                    },
                    {
                        name: '400 Miles',
                        value: 400
                    },
                    {
                        name: '500 Miles',
                        value: 500
                    },
                    {
                        name: '750 Miles',
                        value: 750
                    },
                    {
                        name: '1,000 Miles',
                        value: 1000
                    },
                    {
                        name: '1,250 Miles',
                        value: 1250
                    },
                    {
                        name: '1,500 Miles',
                        value: 1500
                    },
                    {
                        name: '2,000 Miles',
                        value: 2000
                    },
                    {
                        name: '2,500 Miles',
                        value: 2500
                    },
                    {
                        name: '3,000 Miles',
                        value: 3000
                    },
                ],
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            isPublicDropOff: {
                name: 'Public Drop Off Location',
                error: 'Is this a publicly available drop off location',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            isDeliveryHub: {
                name: 'Delivery Hub',
                error: 'Is a delivery hub',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
        };
    }

    getServerRequest()
    {
        return {
            userLocationId: this.state.userLocation.id,
            carrierGroupId: this.props.carrierGroupId,
            maxServiceDistanceMiles: parseInt(this.state.maxServiceDistanceMiles.value),
            isPublicDropOff: this.state.isPublicDropOff.value,
            isDeliveryHub: this.state.isDeliveryHub.value
        };
    }
    
    onSendToServer()
    {
        CarrierLocationService.add({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken, 
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        /* istanbul ignore next */
        if(this.props.onLocationAdded)
        {
            this.props.onLocationAdded(response);
        }
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            userLocation: null
        };
    }

    getConfirmUi(confirmMessage = 'Location Added')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    validateState(state)
    {
        let baseReturn = super.validateState(state);
        /* istanbul ignore next */
        if(baseReturn.userLocation === null)
        {
            baseReturn.isValid = false;
        }
        
        return baseReturn;
    }

    onLocationSelected(location)
    {
        let nextState = this.state;
        nextState.userLocation = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    onLocationRemoveSelected()
    {
        let nextState = this.state;
        nextState.userLocation = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    getPromptUi()
    {
        return (
            <div>
                <LocationSelectorPanel
                    promptTitle="Select your location"
                    selectedLocation={this.state.userLocation}
                    onSelected={this.onLocationSelected}
                    onRemoveSelected={this.onLocationRemoveSelected}
                />

                {
                    super.getPromptUi()
                }
            </div>
        );
    }

}

const AddCarrierLocationPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddCarrierLocationPrompt);
export default AddCarrierLocationPrompt;
