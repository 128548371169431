import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import config from './../../../config';
import FeeList from './fee-list';
import FeeCopy from './fee-copy';
import CurrencyService from './../../../service/currency';
import ResizeService from './../../../service/resize';

const FeesPanel = () => {
    
    const getFeesPanelStyle = () => theme.getFeesPanelStyle(theme);
    const [ feesPanelStyle, setFeesPanelStyle ] = useState(getFeesPanelStyle() );
    
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setFeesPanelStyle( getFeesPanelStyle() );
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={feesPanelStyle}>
            <FeeCopy />

            <h2 style={{
                ...theme.getGeneralTextStyle(theme)  
            }}>
                Online Payment Fee
            </h2>
            <div style={bodyStyle}>
                <FeeList list={[
                    {
                        title: 'Free Account',
                        flatFee: config.pay.free.flatFee,
                        feePercent: config.pay.free.feePercent,
                        displayFeePercent: config.pay.free.displayFeePercent
                    },
                    {
                        title: 'Subscriber Account',
                        flatFee: config.pay.paid.flatFee,
                        feePercent: config.pay.paid.feePercent,
                        displayFeePercent: config.pay.paid.displayFeePercent
                    }
                ]} />
            </div>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme)  
            }}>
                Chargeback Fee
            </h2>
            <div style={bodyStyle}>
                <p>
                    { `In the event of an online transaction chargeback a fee of ${CurrencyService.getDisplayPrice(config.pay.fine.dispute.currency, config.pay.fine.dispute.total, true) } will be charged` }
                </p>
            </div>
        </div>
    );
};

export default FeesPanel;
