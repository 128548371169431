import React from 'react';
import ActorDetails from './actor-details';
import { hasDelivery } from './util';

const FulfilledView = ({ exchange, onExchangeConfirmed }) => 
    <section>
        {
            exchange.bid.hasRefunded ? '' : 
                <div>
                    <h2>
                        Awaiting Exchange Confirmation from 
                        <ActorDetails 
                            usersDetails={exchange.usersDetails} 
                            actorId={exchange.actorId} 
                            amIActor={exchange.amIActor}
                        />
                    </h2>
                    <p>
                        The offer or service is on its way.  Once delivered the receiver must confirm the exchange to proceed.
                    </p>
                </div>
        }
        {
            ((exchange.bid.payFlagged || exchange.bid.refundRequested ) && (exchange.bid.flagReviewed || exchange.bid.refundRequestReviewed) && !exchange.bid.hasRefunded) ? 
                <p>
                    Transaction has been reviewed and the refund request was rejected.
                </p> : ''
        }
        {
            ((exchange.bid.payFlagged && !exchange.bid.flagReviewed) || (exchange.bid.refundRequested && !exchange.bid.refundRequestReviewed) || exchange.bid.hasRefunded ) ? (
                exchange.bid.hasRefunded ? 'Transaction refunded.  Exchange has halted.' :
                    exchange.bid.payFlagged ? 'Transaction has been flagged for review.'  : 'Transaction has been paused for refund request'
                ) : (
                    exchange.amIActor ? 
                        <button 
                            className="test-exchangeDetailFulfilledViewConfirmBtn"
                            onClick={event => { onExchangeConfirmed() }}
                        >
                            { hasDelivery(exchange) ? 'Delivery Received' : 'Exchange Confirmed' }
                        </button> : ''
                )
        }
    </section>

export default FulfilledView;
