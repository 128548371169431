import React from 'react';
import UiService from './../../../service/ui';
import CurrencyService from './../../../service/currency';

/* istanbul ignore next */
const ItemStatus = ({ ledgerItem }) => 
    <span>
        { 
            ledgerItem.hasRefunded ? 'Refunded' : (
                !ledgerItem.insertedProcessingCompleted && ledgerItem.insertedProcessingFailed ? 'Processing Failed - ' : (
                    !ledgerItem.insertedProcessingCompleted ? 'Pending Inserted Processing' : (
                        ledgerItem.depositedToCompany ? 'Deposited' : (
                            ledgerItem.readyForDeposit ? 'Queued For Deposit' : (
                                ledgerItem.readyForWithdrawl ? 'Ready for withdrawl' : 'Exchange In Progress'
                            )
                        )
                    )
                )
            )
        }
        {
            !ledgerItem.insertedProcessingCompleted && ledgerItem.insertedProcessingFailed ? 
                ledgerItem.processingDetails : ''
        }
    </span>

/* istanbul ignore next */
const ItemType = ({ ledgerItem }) => 
    <span>
        { 
            ledgerItem.payId !== '' ? 'Transaction' : (
                ledgerItem.payFineId !== '' ? 'Fee' : 
                    'Subscription' 
            )
        }
    </span>

export const Header = () => 
    <thead>
        <tr>
            <td>

            </td>
            <td>
                Type
            </td>
            <td>
                Date
            </td>
            <td>
                Status
            </td>
            <td>
                Total
            </td>
            <td>
                Provider Fee
            </td>
            <td>
                Net Total
            </td>
            <td>

            </td>
        </tr>
    </thead>

export const AdminLedgerSearchViewPrompt = ({ 
    ledgerItem, 
    addSelectedId, 
    removeSelectedId, 
    idIsSelected,
    onArchiveLedgerItem
}) => 
    <tr>
        <td>
            <input 
                type="checkbox" 
                className="test-adminLedgerSearchCb"
                checked={idIsSelected(ledgerItem.id)} 
                disabled={!ledgerItem.readyForWithdrawl || (ledgerItem.readyForWithdrawl && ledgerItem.readyForDeposit)} 
                onChange={
                    e => {
                        e.target.checked ? addSelectedId(ledgerItem.id) : removeSelectedId(ledgerItem.id)
                    }
                } 
            />
        </td>
        <td>
            <ItemType ledgerItem={ledgerItem} />
        </td>
        <td>
            { UiService.getDateReport(ledgerItem.createdOn) }
        </td>
        <td>
            <ItemStatus ledgerItem={ledgerItem} />
        </td>
        <td>
            { ledgerItem.insertedProcessingCompleted && CurrencyService.getDisplayPrice(ledgerItem.currency, ledgerItem.total) }
        </td>
        <td>
            { ledgerItem.insertedProcessingCompleted && CurrencyService.getDisplayPrice(ledgerItem.currency, ledgerItem.providerFee) }
        </td>
        <td>
            { ledgerItem.insertedProcessingCompleted && CurrencyService.getDisplayPrice(ledgerItem.currency, ledgerItem.netTotal) }
        </td>
        <td>
            {
                ledgerItem.hasRefunded && 
                <button 
                    className="test-adminLedgerSearchArchiveBtn"
                    onClick={event => {
                        onArchiveLedgerItem(ledgerItem)
                    }}
                >
                    Archive
                </button>
            }
        </td>
    </tr>