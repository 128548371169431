import React from 'react';
import ProfileShopTraitsViewPanel from './view';
import DetailPanel from './../core/detail';
import UserService from './../../../service/user';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import StoreTraitTypeEnum from './../../prompt/core/enum/store-trait-type';

class _ProfileShopTraitsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onUpdateShopTrait = this.onUpdateShopTrait.bind(this);
        this.onDeleteShopTrait = this.onDeleteShopTrait.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.setTab = this.setTab.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    setTab(nextTab)
    {
        /* istanbul ignore next */
        if(StoreTraitTypeEnum.isValid(nextTab))
        {
            let nextState = this.state;
            nextState.selectedTab = nextTab;
            this.setState(nextState);
        }
    }

    getDefaultState()
    {
        let base = super.getDefaultState();
        return {
            ...base,
            selectedTab: StoreTraitTypeEnum.STORE_TRAIT_TYPE_CONTACT_DETAILS
        };
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserService.listUserStoreTraits({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onUpdateShopTrait(updateRequest)
    {
        super.loadData({ });
        UserService.setStoreTrait({
            request: updateRequest,
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onDeleteShopTrait(traitType)
    {
        super.loadData({ });
        UserService.removeStoreTrait({
            request: {
                traitType
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({

        });
    }
    
    getLoadedDataDetailUi()
    {
        return <ProfileShopTraitsViewPanel
            data={this.state.data}
            selectedTab={this.state.selectedTab}
            setTab={this.setTab}
            onUpdateShopTrait={this.onUpdateShopTrait}
            onDeleteShopTrait={this.onDeleteShopTrait}
        />
    }
}

const ProfileShopTraitsPanel = connect(mapStateToProps, mapDispatchToProps)(_ProfileShopTraitsPanel);
export default ProfileShopTraitsPanel;
