import config from './../config';

class USPSService {

    static _trimZeros(total)
    {
        return total.replace(/^0+/, '');
    }

    static _getFee(amount)
    {
        if(amount <= config.pay.usps.lowFeeMaxAmount)
        {
            return config.pay.usps.lowFee;
        }
        else 
        {
            return config.pay.usps.highFee;
        }
    }

    static _isValidAmount(amount)
    {
        return parseInt(amount) <= config.pay.usps.maxAmount ? true : false;
    }

    static getFeeDetails(amount)
    {
        const cleanAmt = this._trimZeros(amount);
        const isValid = this._isValidAmount(cleanAmt);
        const fee = this._getFee(cleanAmt);
        return {
            fee,
            isValid,
            amount: cleanAmt
        };
    }

    static getFeeEstimate(amt)
    {
        const { fee, isValid, amount } = this.getFeeDetails(amt);
        const total = parseInt(fee) + parseInt(amount);
        return {
            isValid,
            fee,
            amount,
            total
        };
    }

}

export default USPSService;
