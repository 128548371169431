export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT = 'draft';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING = 'scheduling';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_PRICING = 'pricing';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_MEMBERS_CONFIRMING = 'members-confirming';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_PUBLISHED = 'published';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_DECLINED = 'declined';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_CANCELLED = 'cancelled';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_IN_PROGRESS = 'in-progress';
export const CARRIER_GROUP_DELIVERY_PLAN_STATUS_COMPLETED = 'completed';

const validValues = [
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_PRICING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_MEMBERS_CONFIRMING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_PUBLISHED,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_DECLINED,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_CANCELLED,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_IN_PROGRESS,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_COMPLETED
];

const isValid = value => validValues.includes(value);

const CarrierGroupDeliveryPlanStatusEnum = {
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_PRICING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_MEMBERS_CONFIRMING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_PUBLISHED,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_DECLINED,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_CANCELLED,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_IN_PROGRESS,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_COMPLETED,
    validValues,
    isValid
};

export default CarrierGroupDeliveryPlanStatusEnum;
