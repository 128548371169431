import React from 'react';
import DetailPanel from './../core/detail';
import ExchangeService from './../../../service/exchange';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';
import DisputeRespondViewPanel from './view';

class _DisputeRespondPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        ExchangeService.getDisputeDetails({
           request: this.getLoadDataRequest(inputObj),
           onResponse: this.onLoadDataResponse,
           onError: this.onLoadDataError,
           authToken: this.props.authToken,
           onAuthError: this.onAuthError,
           onFatalError: this.onFatalError,
           onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getLoadDataRequest(inputObj)
    {
        return {
            exchangeId: inputObj.exchangeId ? inputObj.exchangeId : this.props.exchangeId
        };
    }

    componentDidMount()
    {
        this.loadData({ exchangeId: this.props.exchangeId });
    }

    getLoadedDataDetailUi()
    {
        return <DisputeRespondViewPanel data={this.state.data} exchangeId={this.props.exchangeId} onAcceptDisputeLoss={this.props.onAcceptDisputeLoss} onResponded={this.props.onResponded} />
    }
}

const DisputeRespondPanel = connect(mapStateToProps, mapDispatchToProps)(_DisputeRespondPanel);

export default DisputeRespondPanel;
