import React from 'react';
import DetailPanel from '../../core/detail';
import ApiService from '../../../../service/api';
import BarChartPanel from '../../core/bar-chart';
import { connect } from 'react-redux';
import mapStateToProps from '../../../../store/map/auth-token';
import mapDispatchToProps from '../../../../store/map/on-logout';

class _AdminRequestVolumePanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getLabelList = this.getLabelList.bind(this);
        this.getDataset = this.getDataset.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        ApiService.getTotalRequestsByService({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({  });
    }

    getLabelList()
    {
        return [
            'Total Requests'
        ];
    }

    getDataset()
    {
        const title = [];
        const data = [];
        const list = this.state.data.list ? this.state.data.list : [];
        
        title.push('Period');
        data.push('Total Requests');
        list.forEach(item => {
            title.push(item.serviceName);
            data.push(item.requestCount);
        });
        
        return [
            title,
            data
        ];
    }

    getLoadedDataDetailUi()
    {
        return (
            <BarChartPanel
                title="Request Volume By Service"
                dataset={this.getDataset()}
            />
        );
    }
}

const AdminRequestVolumePanel = connect(mapStateToProps, mapDispatchToProps)(_AdminRequestVolumePanel);
export default AdminRequestVolumePanel;
