import React, { useState, useEffect } from 'react';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import UserEmailPrompt from './../../prompt/user-email';
import ResizeService from './../../../service/resize';

export const UserEmailConsentPrompt = ({ onUserEmailConsent }) => 
    <div style={{
        ...theme.getAbstractFlexContainerNoGutterStyle(theme),
        ...theme.getOfferAbstractFlexColumnStyle(theme),
    }}>
        <p style={{            
            ...theme.getAbstractFlexContainerNoGutterStyle(theme),
            ...theme.getOfferAbstractFlexColumnStyle(theme),
            ...theme.getWarningTextStyle(theme),
        }}>
            You have unsubscribed from ALL emails.  
            <br />
            This includes emails such as password resets.
        </p>
        <button 
            className="test-userEmailSubscribeToEmailsBtn"
            onClick={() => { onUserEmailConsent() }}
        >
            Subscribe to Emails
        </button>
    </div>

const CurrentEmailPanel = ({ email, reloadData }) => {
    const [ changeEmailDialogOpen, setChangeEmailDialogOpen ] = useState(false);
    
    const getDialogConStyle = () => theme.getUserChangeEmailModalStyle(theme);
    const [ dialogConStyle, setDialogConStyle ] = useState( getDialogConStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setDialogConStyle( getDialogConStyle() );
    });
    useEffect(resizeEffect);
    
    return <div style={{
                    ...theme.getUserChangeCurrentEmailStyle(theme)  
            }}>
                <div>
                    Account Email: { email }
                </div>
                <button 
                    className="test-userEmailChangeEmailBtn"
                    onClick={event => { setChangeEmailDialogOpen(true) }}
                >
                    Change Email
                </button>
                <Dialog 
                    onClose={ 
                        /* istanbul ignore next */
                        event => { setChangeEmailDialogOpen(false) }
                    }
                    open={changeEmailDialogOpen}
                    style={{
                        ...theme.getUserChangeCurrentEmailStyle(theme),  
                        ...theme.getAbstractNoMarginDiaStyle(theme), 
                }}>
                    <div style={dialogConStyle}>
                        <UserEmailPrompt currentEmail={email} reloadParentData={reloadData} />
                    </div>
                </Dialog>
            </div>
};

const UserEmailViewPanel = ({ 
    data, 
    onUserEmailConsent, 
    reloadData 
}) => {
    const filteredList = data.list.filter(set => set.appSettingId !== 'no-emails');
    const userHasUnsubscribed = filteredList.length !== data.list.length ? true : false;
    
    const getUserSettingsViewPanelStyle = () => theme.getUserEmailViewPanelStyle(theme);
    const [userSettingsViewPanelStyle, setUserSettingsViewPanelStyle ] = useState(getUserSettingsViewPanelStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserSettingsViewPanelStyle(getUserSettingsViewPanelStyle());
    });
    useEffect(resizeEffect);

    return (
        <div style={userSettingsViewPanelStyle}>
            
            <CurrentEmailPanel email={data.email} reloadData={reloadData} />
            {
                userHasUnsubscribed ? <UserEmailConsentPrompt onUserEmailConsent={onUserEmailConsent} /> : ''
            }
        
            <NavLink to={ `/unsubscribe/${data.email}` }>
                <button>
                    Unsubscribe from ALL emails
                </button>
            </NavLink>
        </div>
    );
};

export default UserEmailViewPanel;
