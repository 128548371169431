import map from './map';
import CrawlerUtil from './frames/crawler/util';

export class OnboardingService {

    static getMap()
    {
        return map;
    }
    

    static getFrameByCrawler(crawler)
    {
        crawler.isTop = true;
        const foundFrame = CrawlerUtil.crawlToFrame(
            OnboardingService.getMap(), 
            crawler, 
            crawler
        );
        return foundFrame;
    }
    
}

export default OnboardingService