import DetailPanel from './../core/detail';
import CarrierGroupService from './../../../service/carrier-group';
import CarrierGroupPendingBidQueueViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _CarrierGroupPendingBidQueuePanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.declineRequest = this.declineRequest.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierGroupService.listBidQueue({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        const carrierGroupId = this.props.carrierGroupId;
        CarrierGroupService.listBidQueue({
            request: {
                carrierGroupId,
                page
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            carrierGroupId: inputObj.carrierGroupId,
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        const carrierGroupId = this.props.carrierGroupId;
        this.loadData({
            page: 0,
            carrierGroupId
        });
    }

    declineRequest(carrierQueueId)
    {
        super.loadData({  });
        const carrierGroupId = this.props.carrierGroupId;
        CarrierGroupService.declineBidQueue({
            request: {
                carrierGroupId,
                carrierQueueId
            },
            onResponsee: response => {
                this.loadData({
                    page: 0,
                    carrierGroupId
                });
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadedDataDetailUi()
    {
        return <CarrierGroupPendingBidQueueViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            declineRequest={this.declineRequest}
        />
    }
}

const CarrierGroupPendingBidQueuePanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierGroupPendingBidQueuePanel);
export default CarrierGroupPendingBidQueuePanel;
