import { _UserSearchPrompt } from './../user-search';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import UserSearchViewPrompt from './view';
import theme from '../../theme';
import Pagination from './../core/pagination';

class _SelectUserPrompt extends _UserSearchPrompt 
{
    constructor(parms)
    {
        super(parms);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.getPaginationUi = this.getPaginationUi.bind(this);
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : 
        <div style={{
            ...theme.getSelectUserSearchResultsStyle(theme)
        }}>
            {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
        </div>
    }

    getSearchResultUi(aResult, index)
    {
        return (
            <UserSearchViewPrompt 
                onUserSelected={this.props.onUserSelected}
                user={aResult} 
                key={index} 
            />
        );
    }

    getPaginationUi()
    {
        return this.state.data && 
        this.state.data.page !== undefined && 
        this.state.data.perPage && 
        this.state.data.total ? <Pagination 
                            total={this.state.data.total}
                            page={this.state.data.page}
                            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
                            pageToUrl={false}
                            perPage={this.state.data.perPage} /> : '';
    }
}

const SelectUserPrompt = connect(mapStateToProps, mapDispatchToProps)(_SelectUserPrompt);
export default SelectUserPrompt;
