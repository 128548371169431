import React from 'react';
import ApiService from './../../../../service/api';
import { FORM_FIELD } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../../core/search';
import ApiStatusServiceEnum from './../../core/enum/api-status-service';
import { Header, SearchApiLogViewPrompt } from './view';
import theme from './../../../theme';

class _SearchApiLogPrompt extends SearchPrompt 
{
    constructor(parms)
    {
        super(parms, _SearchApiLogPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
    }

    static getValidators()
    {
        return {
            service: {
                isValid: ApiStatusServiceEnum.isValid
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            service: {
                name: 'Service',
                error: 'Specify an API Service',
                value: this.props.service ? this.props.service : ApiStatusServiceEnum.API_STATUS_SERVICE_LOCATION,
                valueList: ApiStatusServiceEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            }
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'logList');
    }

    onSendToServer(request = undefined)
    {
        ApiService.listLog({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            page: 0,
            service: this.state.service.value
        };
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        this.onSendToServer(request);
    }

    getNoResultsUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <p>
                    No API Logs Found.
                </p>
            </div>
        );
    }

    getSearchResultUi(aResult, index)
    {
        return <SearchApiLogViewPrompt logRecord={aResult} key={index} />
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : 
            <div>
                <table>
                    <Header />
                    <tbody>
                        {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
                    </tbody>
                </table>
            </div>
    }
}

const SearchApiLogPrompt = connect(mapStateToProps, mapDispatchToProps)(_SearchApiLogPrompt);
export default SearchApiLogPrompt;
