import React, { useState, useEffect } from 'react';
import theme from './../../theme';
import { UserEmailConsentPrompt } from './../user-email/view';
import { isEmailSettingId } from './../../prompt/core/enum/app-setting-id';
import ResizeService from './../../../service/resize';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateSpeedViewPromptVisible } from '../../../store/state/action';
import CustomViewSelector from '../../../store/select/custom-view';

const isEmailSetting = (set) => isEmailSettingId(set.appSettingId);

// istanbul ignore next
const ASetting = ({ set, onUpdateUserSetting, userHasUnsubscribed = false }) => {
    const disabled = isEmailSetting(set) && userHasUnsubscribed ? true : false;
    const checked = isEmailSetting(set) && userHasUnsubscribed ? false : set.value;
    return (
        <div style={{
            ...theme.getUserSettingsASettingLineStyle(theme)  
        }}>
            <input 
                type="checkbox" 
                disabled={disabled}
                checked={checked} 
                onChange={event => { 
                    const value = event.target.checked;
                    const request = {
                        value,
                        appSettingId: set.appSettingId,
                        title: set.title,
                    };
                    onUpdateUserSetting(request);
                }} />
            <span>
                { set.title }
            </span>
        </div>
    );
};

// istanbul ignore next
const UserSettingsViewPanel = ({ 
    data, 
    onUpdateUserSetting, 
    onUserEmailConsent, 
    reloadData 
}) => {
    const dispatch = useDispatch();
    const filteredList = data.list.filter(set => set.appSettingId !== 'no-emails');
    const userHasUnsubscribed = filteredList.length !== data.list.length ? true : false;
    
    const getUserSettingsViewPanelStyle = () => theme.getUserSettingsViewPanelStyle(theme);
    const [userSettingsViewPanelStyle, setUserSettingsViewPanelStyle ] = useState(getUserSettingsViewPanelStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserSettingsViewPanelStyle(getUserSettingsViewPanelStyle());
    });
    useEffect(resizeEffect);

    const customView = useSelector(CustomViewSelector);
    const toggleDisplaySpeedViewPrompt = () => {
        if(customView)
        {
            dispatch(UpdateSpeedViewPromptVisible(
                !customView.showSpeedViewPrompt
            ));
        }
    };
    
    return (
        <div>
            <div style={userSettingsViewPanelStyle}>
                {
                    userHasUnsubscribed ? <UserEmailConsentPrompt onUserEmailConsent={onUserEmailConsent} /> : ''
                }
                {
                    filteredList.map((set, index) => 
                        <ASetting 
                            userHasUnsubscribed={userHasUnsubscribed} 
                            set={set} 
                            onUpdateUserSetting={onUpdateUserSetting} 
                            key={index}
                        />
                    )
                }
            </div>
            <div>
                <button onClick={event => { toggleDisplaySpeedViewPrompt() }}>
                    Speed View Menu
                </button>
            </div>
        </div>
    );
};

export default UserSettingsViewPanel;
