import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class FlagService {
    static getFlagItemRequest(flaggedContentType, contentId, flaggingReason, flaggingUserSummary) {
        return {
            flaggedContentType,
            contentId,
            flaggingReason,
            flaggingUserSummary,
        };
    }

    /* istanbul ignore next */
    static flagItem({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.flagItem,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getPendingFlagsRequest(page = 0)
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static getPendingFlags({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.pendingFlags,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getReviewFlagRequest(restored, reviewerUserSummary, id)
    {
        return {
            restored,
            reviewerUserSummary,
            id
        };
    }

    /* istanbul ignore next */
    static reviewFlag({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.reviewFlag,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default FlagService;
