import React from 'react';
import AuthPageTemplate from './auth-page';
import AdminMenu from './../../ui/menu/admin';

const AdminPageTemplate = ({ children, childMenu = null, hideOnScroll = true }) => 
    <AuthPageTemplate 
        hideOnScroll={hideOnScroll}
        childMenu={
            childMenu ? 
                <div>
                    <AdminMenu />
                    { childMenu }
                </div> : <AdminMenu />
        }>
        <section>
            { children }
        </section>
    </AuthPageTemplate>

export default AdminPageTemplate;
