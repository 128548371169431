import React from 'react';

const PrevButton = ({
    selectedFrame,
    updateCrawler
}) => {
    const hasPrevButton = selectedFrame.prev.hasPrev;
    const prevButtonText = selectedFrame.prev.title;

    const onPrev = () => {

        updateCrawler(
            selectedFrame.prev.nextCrawler
        );
    
    };
    return hasPrevButton ?
        <button 
            className="test-onboardingPrevBtn"
            onClick={event => {
            onPrev();
        }}>
            { prevButtonText }
        </button> : '';
};

export default PrevButton;
