import { 
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';


export const getStepLocation = (step) => {
    switch(step.type)
    {
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP:
            return step.from;

        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF:
            return step.to;

        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT:
            return step.to;

        default:
            return undefined;
    }
};
