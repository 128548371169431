import endpointRequest from './endpoint-request';
import config from './../config';
import NetworkService from './network';

const SENSITIVE_OVERWRITE_VALUE = '*******************';

class ErrorService {

    static getSensitiveHeaderList()
    {
        return [
            'authorization',
            'Authorization'
        ];
    }

    static getSensitiveParmList()
    {
        return [
            'pwd',
            'currentPwd',
            'nextPwd',
            'email',
            'token',
            'contactPhone',
            'contactEmail',
            'billing_address',
            'customer_email_address',
            'customer_name',
            'customer_purchase_ip',
            'shipping_address',
            'shipping_tracking_number',
            'latitude',
            'longitude',
            'pointOfContact',
            'street',
            'streetLine2',
            'city',
            'shortState',
            'state',
            'zipCode',
            'accountNumber',
            'routingNumber'
        ]
    }
    
    static getPossibleTimePeriods()
    {
        return [
            {
                name: 'All Time',
                value: ''
            },
            {
                name: 'Last 15 Min',
                value: '15 Min'
            },
            {
                name: 'Last 1 Hr',
                value: '1 Hr'
            },
            {
                name: 'Last 12 Hr',
                value: '12 Hr'
            },
            {
                name: 'Last 1 Day',
                value: '1 Day'
            },
            {
                name: 'Last 15 Days',
                value: '15 Days'
            },
            {
                name: 'Last 30 Days',
                value: '30 Days'
            }
        ];
    }

    static getWriteLogRequest(textLine, clientInfo)
    {
        return {
            textLine,
            clientInfo
        };
    }

    static getVersion()
    {
        return `Client Web ${config.version}`;
    }

    static doesErrorContainSensitiveData(err)
    {
        if(err.request && err.request.body)
        {
            const requestBody = JSON.parse(err.request.body);
            const list = this.getSensitiveParmList();
            for(const parmName of list)
            {
                if(requestBody[parmName] !== undefined)
                {
                    return true;
                }
            }
        }
        return false;
    }

    static parseSensitiveDataErrorToStringForLogging(err)
    {
        const requestBody = JSON.parse(err.request.body);
        const list = this.getSensitiveParmList();
        for(const parmName of list)
        {
            if(requestBody[parmName] !== undefined)
            {
                requestBody[parmName] = SENSITIVE_OVERWRITE_VALUE;
            }
        }
        err.request.body = JSON.stringify(requestBody);
        return 'Error Sensitive Values Censored Dump - ' + JSON.stringify(err);
    }

    static censorHeaders(err)
    {

        const list = this.getSensitiveHeaderList();
        if(err.request && err.request.headers)
        {
            for(const aHeaderName of list)
            {
                if(err.request.headers[aHeaderName] !== undefined)
                {
                    err.request.headers[aHeaderName] = SENSITIVE_OVERWRITE_VALUE;
                }
            }
        }

        if(err.config && err.config.headers)
        {
            for(const aHeaderName of list)
            {
                if(err.config.headers[aHeaderName] !== undefined)
                {
                    err.config.headers[aHeaderName] = SENSITIVE_OVERWRITE_VALUE;
                }
            }
        }

        return err;
    }

    static parseErrorToStringForLogging(err)
    {
        err = this.censorHeaders(err);
        
        if(this.doesErrorContainSensitiveData(err))
        {
            return this.parseSensitiveDataErrorToStringForLogging(err);
        }
        else 
        {
            return 'Error Dump - ' + JSON.stringify(err);
        }
    }

    static errToWriteLogRequest(err)
    {
        return this.textLineToWriteLogRequest(this.parseErrorToStringForLogging(err));
    }

    /* istanbul ignore next */
    static writeLogFromError(err, authToken)
    {
        endpointRequest({
            url: config.url.client.log,
            request: this.errToWriteLogRequest(err),
            onResponse: () => {},
            onError: () => {},
            authToken
        });
    }
    
    static textLineToWriteLogRequest(textLine)
    {
        return {
            textLine,
            clientInfo: this.getVersion()
        }
    }

    /* istanbul ignore next */
    static writeLog(textLine, authToken)
    {
        endpointRequest({
            url: config.url.client.log,
            request: this.textLineToWriteLogRequest(textLine),
            onResponse: () => {},
            onError: () => {},
            authToken
        });
    }

    static getAdminSearchLogsRequest(searchText, page = 0)
    {
        return {
            searchText,
            page
        };
    }

    /* istanbul ignore next */
    static adminSearchLogs({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.logSearch,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminSearchMetricsRequest(searchText, noEarlierDateCode, page)
    {
        return {
            searchText,
            noEarlierDateCode,
            page
        };
    }

    /* istanbul ignore next */
    static adminSearchMetrics({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.metrics,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminSearchMetricsSummaryRequest(searchText, noEarlierDateCode, page)
    {
        return {
            searchText,
            noEarlierDateCode,
            page
        };
    }

    /* istanbul ignore next */
    static adminSearchMetricsSummary({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.metricsSummary,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    /* istanbul ignore next */
    static getAdminPurgeLogsRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static adminPurgeLogs({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.logPurge,
            request: this.getAdminPurgeLogsRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static isServerDown(err)
    {
        return err.message === 'ajax error';
    }

    static isIgnorable(err)
    {
        try 
        {
            if(err.request && err.request.url === config.url.notice.unseenCount && this.isServerDown(err))
            {
                return document.hidden ? true : false;
            }

            return false;
        }
        catch(innerError)
        {
            return false;
        }
    }

    /* istanbul ignore next */
    static makeHandle({
        onError = undefined, 
        onAuthError = undefined, 
        onFatalError = undefined,
        onNetworkOffline = undefined,
        authToken = undefined
    }) {
        return (err) => {
            /* istanbul ignore next */
            if(onAuthError && err.status === 403)
            {
                onAuthError();
            }
            
            const canWriteToLogs = authToken !== undefined && err.status !== 403

            const runIfOnline = () => {
                
                let shouldWriteToLogs = false;

                if(onFatalError && this.isServerDown(err) && !this.isIgnorable(err))
                {
                    shouldWriteToLogs = true;
                    onFatalError();
                }
    
                if(onError && !this.isIgnorable(err))
                {
                    shouldWriteToLogs = true;
                    onError(err)
                }

                if(canWriteToLogs && shouldWriteToLogs)
                {
                    this.writeLogFromError(err, authToken);
                }
            };

            const runIfOffLine = onNetworkOffline !== undefined ? onNetworkOffline : runIfOnline
               
            NetworkService.syncIsOnline(runIfOnline, runIfOffLine);
        };
    }
}

export default ErrorService;
