export const getLogoPanelStyle = (theme, img) => ({
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    backgroundImage: `url(${img})`,
    maxHeight: '16rem',
    width: 'auto',
    height: '100vh',
    marginTop: '.25rem',
    marginLeft: 'auto',
    marginRight: 'auto'
});