import React from 'react';
import LocationMapPanel from './../location-map';
import LocationSelectorPanel from './../location-selector';

const limitDropDownValues = [
    {
        name: '5 Miles',
        value: '5'
    },
    {
        name: '10 Miles',
        value: '10'
    },
    {
        name: '20 Miles',
        value: '20'
    },
    {
        name: '40 Miles',
        value: '40'
    },
    {
        name: '60 Miles',
        value: '60'
    },
    {
        name: '80 Miles',
        value: '80'
    },
    {
        name: '100 Miles',
        value: '100'
    },
    {
        name: '250 Miles',
        value: '250'
    },
    {
        name: '500 Miles',
        value: '500'
    },
    {
        name: '750 Miles',
        value: '750'
    },
    {
        name: '1,000 Miles',
        value: '1000'
    },
    {
        name: '1,250 Miles',
        value: '1250'
    },
    {
        name: '1,500 Miles',
        value: '1500'
    },
    {
        name: '1,750 Miles',
        value: '1750'
    },
    {
        name: '2,000 Miles',
        value: '2000'
    },
    {
        name: 'No Limit',
        value: ''
    },
];

const LimitPrompt = ({ 
    limit,
    setLimit
 }) => {
    const onChange = (event) => { setLimit(event.target.value) };
    return (
        <div>
            <div>
                Distance Limit
            </div>
            <select 
                className="test-locationLimitSelectorDistanceLimitDropDown"
                onChange={onChange} defaultValue={limit}
            >
                {
                    limitDropDownValues.map(v => 
                        <option key={v.value} value={v.value}>
                            { v.name }
                        </option>
                    )
                }
            </select>
        </div>
    );
};

const LocationLimitSelectorPanel = ({ 
    promptTitle, 
    locationPromptTitle,
    selectedLocation,
    onSelected,
    onRemoveSelected,
    limit,
    setLimit
}) => {
    
    return (
        <div>

            <h4>
                { promptTitle }
            </h4>

            <LimitPrompt 
                limit={limit}
                setLimit={setLimit}
            />

            <LocationSelectorPanel 
                promptTitle={locationPromptTitle}
                selectedLocation={selectedLocation}
                onSelected={onSelected}
                onRemoveSelected={onRemoveSelected}
                includeGps={true}
            />

            {
                selectedLocation && 
                    <LocationMapPanel
                        selectedLocation={selectedLocation}
                        limit={limit}
                        isRegion={true}
                    />
            }

        </div>
    );
};

export default LocationLimitSelectorPanel;
