import React from 'react';
import AuthPageTemplate from './template/auth-page';
import WaitForPaymentConfirmedPanel from '../ui/panel/wait-for-payment-confirmed';
import ProfileMenu from './../ui/menu/profile';
import theme from './../ui/theme';

const PaymentProcessingPage = () => 
    <AuthPageTemplate 
        childMenu={
            <div>
                <ProfileMenu />
            </div>
    }>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <WaitForPaymentConfirmedPanel />
        </div>
    </AuthPageTemplate>

export default PaymentProcessingPage;
