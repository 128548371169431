import React from 'react';
import BidPrompt from './../prompt/bid';
import PayPrompt from './../prompt/pay';
import theme from './../theme';
import BidContentTypeEnum from './../prompt/core/enum/bid-content-type';
import { EXCHANGE_TYPE_ONLINE } from './../prompt/core/enum/exchange-type';

export const isPayButtonVisible = (item, bidContentType) => {
    
    if(bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_SEEK)
    {
        return false;
    }

    if(item.exchangeList && item.exchangeList.length > 0)
    {
        const onlineTotal = item.exchangeList.filter(ex => ex.type === EXCHANGE_TYPE_ONLINE).length;
        return onlineTotal > 0 ? true : false;
    }
    else
    {
        return false;
    }
};

export const isBidButtonVisible = (item, bidContentType) => {
    
    if(bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_SEEK)
    {
        return true;
    }

    if(item.exchangeList && item.exchangeList.length > 0)
    {
        const total = item.exchangeList.length;
        const onlineTotal = item.exchangeList.filter(ex => ex.type === EXCHANGE_TYPE_ONLINE).length;
        return onlineTotal !== total ? true : false;
    }
    else
    {
        return true;
    }
};

const ItemViewerMenu = ({ 
    item, 
    setBids, 
    bidContentType, 
    flaggedContentType, 
    setPayClientSecret,
    onContactingServerResponse,
    onContactingServer,
    paidAccount
}) => 
    <div style={{
        ...theme.getItemViewerMenuStyle(theme),
    }}>
        
        {
            //Bid, Flag, Pay.
            isPayButtonVisible(item, bidContentType) ? 
                <PayPrompt
                    buttonStyle={{}}
                    onContactingServerResponse={onContactingServerResponse}
                    onContactingServer={onContactingServer}
                    setPayClientSecret={setPayClientSecret}
                    content={item}
                    contentType={flaggedContentType} 
                /> : ''
        }
        {
            isBidButtonVisible(item, bidContentType) ? 
                <BidPrompt 
                    buttonStyle={{
                        ...theme.getItemViewerBidPromptButtonStyle(theme),
                    }} 
                    setBids={setBids} 
                    content={item} 
                    paidAccount={paidAccount}
                    bidContentType={bidContentType} 
                    contentType={flaggedContentType} 
                /> : ''
        }
        
    </div>


export default ItemViewerMenu;
