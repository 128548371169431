import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';
import CarrierGroupNavMenu from './../../menu/carrier-group/carrier-group-nav-menu';
import Pagination from './../../prompt/core/pagination';
import UiService from './../../../service/ui';
import InviteCarrierMemberPrompt from './../../prompt/invite-carrier-member';
import GrantCarrierMembershipRequestPrompt from './../../prompt/grant-carrier-membership-request';

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                                total={data.total}
                                                                page={data.page}
                                                                onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                                perPage={data.perPage} />


const PendingCarrierMember = ({ 
    pendingMember,
    declineMembership,
    onInviteSent
}) => {
    const [ isResponsePromptOpen, setIsResponsePromptOpen ] = useState(false);
    return (
        <tr>
            <td>
                {
                    !pendingMember.isInvite && 
                        <button 
                            className="test-pendingCarrierMembersRespondToRequestBtn"
                            onClick={event => { setIsResponsePromptOpen(true) }}
                        >
                            Respond to Request
                        </button>
                }
            </td>
            <td>
                {
                    pendingMember.isInvite ? `Invited by ${pendingMember.createdByUsername}` : 
                        'Membership Requested'
                }
            </td>
            <td>
                <NavLink 
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to={`/user/${pendingMember.pendingMemberUserId}`}
                >
                    { pendingMember.pendingMemberUsername }
                </NavLink>
            </td>
            <td>
                { UiService.timeSince(pendingMember.createdOn) }
            </td>
            <td>
                { pendingMember.internalNote }
            </td>
            <Dialog 
                onClose={
                    /* istanbul ignore next */
                    event => { setIsResponsePromptOpen(false) }
                }
                open={isResponsePromptOpen}
            >
                <h4>
                    Respond to Membership Request
                </h4>

                <button onClick={
                    /* istanbul ignore next */
                    event => { declineMembership(pendingMember) }}
                >
                    Decline Request
                </button>

                <p style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    Or
                </p>

                <GrantCarrierMembershipRequestPrompt 
                    pendingCarrierMemberId={pendingMember.id} 
                    onResponse={onInviteSent}
                />

            </Dialog>
        </tr>
    );
};

const Header = () => 
    <thead>
        <tr>
            <td>

            </td>
            <td>
                Status
            </td>
            <td>
                Username
            </td>
            <td>
                Time Since
            </td>
            <td>
                Notes
            </td>
        </tr>
    </thead>

const PopulatedView = ({ 
    pendingCarrierMembers, 
    onLoadSpecifiedPage,
    declineMembership,
    onInviteSent
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <h3>
            Pending Members
        </h3>
        { getPaginationUi({ data: pendingCarrierMembers, onLoadSpecifiedPage }) }

        <table>
            <Header />
            <tbody>
                {
                    pendingCarrierMembers.list.map((pcm, index) => 
                        <PendingCarrierMember 
                            pendingMember={pcm}
                            declineMembership={declineMembership}
                            key={index}
                            onInviteSent={onInviteSent}
                        />
                    )
                }
            </tbody>
        </table>
        
        { getPaginationUi({ data: pendingCarrierMembers, onLoadSpecifiedPage }) }
    </div>


const PendingCarrierMembersViewPanel = ({ 
    data, 
    carrierGroupId, 
    onLoadSpecifiedPage,
    onInviteSent,
    declineMembership
}) => {
    const [ showInvitePrompt, setShowInvitePrompt ] = useState(false);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <CarrierGroupNavMenu
                carrierGroupId={carrierGroupId}
                carrierGroupName={data.carrierGroup.name}
            />

            <div>
                <button 
                    className="test-pendingCarrierMembersInviteUsersToGroupBtn"
                    onClick={event => {
                        setShowInvitePrompt(true)
                    }}
                >
                    Invite User to Group
                </button>
            </div>

            {
                data.pendingCarrierMembers.list.length > 0 &&
                    <PopulatedView 
                        pendingCarrierMembers={data.pendingCarrierMembers} 
                        onLoadSpecifiedPage={onLoadSpecifiedPage}
                        declineMembership={declineMembership}
                        onInviteSent={onInviteSent}
                    />
            }

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */    
                    event => { setShowInvitePrompt(false) }
                }
                open={showInvitePrompt}
            >
                <div style={{
                  ...theme.getGeneralTextStyle(theme)
                }}>
                    <h3>
                        Invite a Member
                    </h3>
                    <InviteCarrierMemberPrompt 
                        carrierGroupId={carrierGroupId}
                        carrierGroupName={data.carrierGroup.name}
                        onInviteSent={onInviteSent}
                    />
                </div>
            </Dialog>

        </div>
    );
};

export default PendingCarrierMembersViewPanel;
