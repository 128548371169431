import React from 'react';
import noConnection from './../../../img/no-connection.svg';
import theme from './../../theme';

const DisconnectedPanel = () => 
    <section style={{ 
        ...theme.getGeneralTextStyle(theme),
    }}>
        <div style={{ 
            ...theme.getDisconnectedIconStyle(theme, noConnection),
        }}>
        </div>
        <h2>
            No Internet Connection
        </h2>
        <p>
            Please check your device's network and network connection.
        </p>
    </section>

export default DisconnectedPanel;
