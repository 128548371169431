import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../../../theme';
import CloseIcon from './../../../../../img/close.svg';
import ResizeService from './../../../../../service/resize';

export const SelectAttSetSearchResultsView = ({
    children
}) => {
    const getSelectAttSetResultsStyle = () => theme.getProfilePwdSuggestionContentStyle(theme);
    const [selectAttSetResultsStyle, setSelectAttSetResultsStyle ] = useState(getSelectAttSetResultsStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSelectAttSetResultsStyle(getSelectAttSetResultsStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={selectAttSetResultsStyle}>
            { children }
        </div>
    );

};

export const SelectAttSetPromptContainerView = ({ 
    children,
    onCloseSelectFieldSetDialog
}) => {
    const getIconflexContainerStyle = () => theme.getIconflexContainerStyle(theme);
    const [ iconFlexContainerStyle, setIconFlexContainerStyle ] = useState(getIconflexContainerStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setIconFlexContainerStyle( getIconflexContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <div style={iconFlexContainerStyle}>
                <div 
                    style={{
                        ...theme.getIconButtonStyle(theme, CloseIcon)
                    }}
                    onClick={
                        /* istanbul ignore next */
                        event => { 
                            onCloseSelectFieldSetDialog(event) 
                        }
                    }
                >
                </div>
                
                <h3 style={{
                ...theme.getIconHeaderStyle(theme)
                }}>
                    Select from your existing custom fields
                </h3>
            </div>

            { children }

        </div>
    );
};

const SelectAttSetViewPrompt = ({ attSet, index, onAttSetSelected }) => {
    
    const getPaymentMethodItemStyle = () => theme.getPaymentMethodItemStyle(theme);
    const getPaymentMethodHeaderStyle = () => theme.getPaymentMethodHeaderStyle(theme);

    const [ paymentMethodItemStyle, setPaymentMethodItemStyle ] = useState( getPaymentMethodItemStyle() );
    const [ paymentMethodHeaderStyle, setpaymentMethodHeaderStyle ] = useState( getPaymentMethodHeaderStyle() );
    const [ buttonText, setButtonText ] = useState('Add Fields');

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPaymentMethodItemStyle( getPaymentMethodItemStyle() );
        setpaymentMethodHeaderStyle( getPaymentMethodHeaderStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div 
            style={{
                ...theme.getPaymentMethodGridContainerStyle(theme),
            }}
            key={index}
        >
            <div style={paymentMethodItemStyle}>
                <strong>
                    Title
                </strong>
                <div style={paymentMethodHeaderStyle}>
                    { attSet.title }
                </div>
            </div>
            
            <div style={paymentMethodItemStyle}>
                <strong>
                    Count
                </strong>
                <div style={paymentMethodHeaderStyle}>
                { attSet.attributeList.length } { attSet.attributeList.length > 1 ? 'Fields' : 'Field' }
                </div>
            </div>


            <div style={{
                ...theme.getPaymentMethodButtonStyle(theme),
            }}>
                <button onClick={
                    /* istanbul ignore next */
                    event => { 
                        onAttSetSelected(attSet);
                        setButtonText('Fields Added');
                    }
                }>
                    { buttonText }
                </button>

            </div>
            
        </div>
    );
};

export default SelectAttSetViewPrompt;
