import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import theme from './../../theme';
import Pagination from './../../prompt/core/pagination';
import AddCarrierLocationPrompt from './../../prompt/add-carrier-location';
import CarrierGroupNavMenu from './../../menu/carrier-group/carrier-group-nav-menu';

const Location = ({
    location,
    contactPhone,
    contactEmail,
    canRemove,
    maxServiceDistanceMiles,
    remove
}) => 
    <div style={{
        ...theme.getLocationMailingAddressStyle(theme)  
    }}>
        <p>
            { location.mailingAddress }
        </p>
        
        <p>
            { `Phone: ${contactPhone}` }
        </p>
        
        <p>
            { `Email: ${contactEmail}` }
        </p>

        {
            location.hasTruckDocks && 
                <p>
                    <strong>
                        Has Truck Dock(s)
                    </strong>
                </p>
        }

        {
            location.hasLimitedAccess && 
                <p>
                    <strong>
                        Limited Access Location
                    </strong>
                </p>
        }

        {
            location.notes !== '' && 
                <p>
                    { location.notes }
                </p>
        }

        <p>
            Max Service Distance: { maxServiceDistanceMiles } miles
        </p>

        {
            canRemove && 
                <div>
                    <button 
                        className="test-carrierLocationsListRemoveBtn"
                        onClick={event => { remove() }}
                    >
                        Remove
                    </button>
                </div>
        }

        <hr />
    </div>

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />
const PopulatedView = ({ 
    locations, 
    onLoadSpecifiedPage,
    removeLocation
}) => 
    <div>
        <h3>
            Locations
        </h3>

        { getPaginationUi({ data: locations, onLoadSpecifiedPage }) }

        {
            locations.list.map((loc, index) => 
                <Location 
                    location={loc.userLocation} 
                    maxServiceDistanceMiles={loc.maxServiceDistanceMiles}
                    contactPhone={loc.carrierMember.contactPhone}
                    contactEmail={loc.carrierMember.contactEmail}
                    canRemove={loc.canRemove}
                    remove={ event => { removeLocation(loc) }}
                    key={index} 
                />
            )
        }

        { getPaginationUi({ data: locations, onLoadSpecifiedPage }) }

    </div>

const EmptyView = () => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <p>
            No Locations Found
        </p>
    </div>

const CarrierLocationsListViewPanel = ({
    data, 
    onLoadSpecifiedPage,
    onLocationAdded,
    removeLocation
}) => {

    const [ isAddPromptOpen, setIsAddPromptOpen ] = useState(false);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <CarrierGroupNavMenu 
                carrierGroupId={data.carrierGroup.id}
                carrierGroupName={data.carrierGroup.name}
            />

            <div>
                <button 
                    className="test-carrierLocationsListAddBtn"
                    onClick={event => { setIsAddPromptOpen(true) }}
                >
                    Add
                </button>
            </div>

            {
                data.carrierLocations.list.length < 1 ? 
                    <EmptyView /> : 
                        <PopulatedView
                            locations={data.carrierLocations}
                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                            removeLocation={removeLocation}
                        />
            }

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setIsAddPromptOpen(false) }}
                open={isAddPromptOpen}
            >
                <AddCarrierLocationPrompt 
                    carrierGroupId={data.carrierGroup.id}
                    onLocationAdded={onLocationAdded}
                />
            </Dialog>

        </div>
    );
};

export default CarrierLocationsListViewPanel;


