import React from 'react';
import DetailPanel from './../../core/detail';
import FlagService from './../../../../service/flag';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import AdminReviewFlagsViewPrompt from './view';

class _AdminReviewFlagsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.removeLocalDataRecordById = this.removeLocalDataRecordById.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        FlagService.getPendingFlags({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.loadData({ page: 0 });
    }

    /* istanbul ignore next */
    removeLocalDataRecordById(id)
    {
        const nextState = {
            ...this.state,
            data: {
                ...this.state.data,
                list: this.state.data.list.filter(item => item.id !== id)
            },
        };
        this.setState(nextState);
    }

    getLoadedDataDetailUi()
    {
        return <AdminReviewFlagsViewPrompt data={this.state.data} removeById={this.removeLocalDataRecordById} />
    }
}

const AdminReviewFlagsPanel = connect(mapStateToProps, mapDispatchToProps)(_AdminReviewFlagsPanel);
export default AdminReviewFlagsPanel;