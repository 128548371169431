import React from 'react';
import theme from './../../theme';
import UiService from './../../../service/ui';
import { useState } from 'react';
import Pagination from './../../prompt/core/pagination';

const ImgView = ({ image, onAddSavedImg }) => {
    const [isAdded, setIsAdded ] = useState(false);
    return (
        <div>  
            <div style={{
                        ...theme.getImgAddViewContainerStyle(theme),
                }}>
                <img 
                    src={image.previewImage ? image.previewImage : image.data } 
                    alt="File Missing" 
                    style={{
                        ...theme.getImgAddViewStyle(theme),
                }}
                />
            </div>
            <div>
                <div style={{
                    ...theme.getImgTextStyle(theme),
                }}>
                    <p>
                        Filename:
                    </p>
                    <div>
                        { image.filename }
                    </div>
                </div>
                <div style={{
                    ...theme.getImgTextStyle(theme),
                }}>
                    <p>
                        Uploaded:   
                    </p>
                    <div>
                        { UiService.timeSince(image.createdOn) }
                    </div>
                </div>
                <button 
                    className="test-addImgListAddBtn"
                    onClick={ event => { 
                        onAddSavedImg(image);
                        setIsAdded(true);
                    }}
                >
                    { isAdded ? 'Added' : 'Add' }
                </button>
            </div>        
        </div>
    );
}

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const PopulatedView = ({ data, onAddSavedImg, onLoadSpecifiedPage }) => 
    <section style={{
        ...theme.getImgAddContainerStyle(theme),
    }}>
        { getPaginationUi({ data, onLoadSpecifiedPage }) }
        {
            data.list.map((img, index) => <ImgView image={img} onAddSavedImg={onAddSavedImg} key={index} />)
        }
        { getPaginationUi({ data, onLoadSpecifiedPage }) }
    </section>

const EmptyView = (parms) => 
    <section>
        <p>
            No images saved.
        </p>
    </section>

const AddImgListViewPanel = ({ data, onAddSavedImg, onLoadSpecifiedPage }) => 
<div style={{
    ...theme.getImgListContainerStyle(theme),
    ...theme.getGeneralTextStyle(theme),
}}>
    <h2>
        Saved Images
    </h2>
    
    {
        data.list.length < 1 ? <EmptyView /> : <PopulatedView data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} onAddSavedImg={onAddSavedImg} />
    }
</div>

export default AddImgListViewPanel;
