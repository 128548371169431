import React from 'react';
import ValidateService from './../../../service/validate';
import CurrencyInputService from './../../../service/currency-input';
import CurrencyService from './../../../service/currency';
import BidContentTypeEnum from './../core/enum/bid-content-type';
import { connect } from 'react-redux';
import BidService from './../../../service/bid';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import theme from './../../theme';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import {
    obtainToValue,
    exchangeToValue,
    filterRelevantExchanges,
    mapExchangeToValueItem,
    mapObtainToValueItem
} from './../bid';
import { ObtainMethodCopy } from './../../panel/core/bids';
import { 
    EXCHANGE_TYPE_CASH
} from './../core/enum/exchange-type';
import {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_ON_SITE,
    OBTAIN_TYPE_PICK_UP
} from './../core/enum/obtain-type';
import LocationSelectorPanel from './../../panel/location-selector';
import { getObtainTypeLocationTitleCopy } from './../core/copy/obtain-type-copy-util';

class _BidCashPaymentPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _BidCashPaymentPrompt.getValidators(parms));
        
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.applyDynamicFieldValidators = this.applyDynamicFieldValidators.bind(this);
        this.getDynamicFields = this.getDynamicFields.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.setExchangeMethod = this.setExchangeMethod.bind(this);
        this.controlValueChange = this.controlValueChange.bind(this);
        this.getAskingError = this.getAskingError.bind(this);
        this.displayPriceUi = this.displayPriceUi.bind(this);
        this.isPromptingLocation = this.isPromptingLocation.bind(this);
        this.getFormFieldsUi = this.getFormFieldsUi.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationRemoveSelected = this.onLocationRemoveSelected.bind(this);
        this.validateState = this.validateState.bind(this);
        this.reorderValidators = this.reorderValidators.bind(this);

        this.validator.title.isValid = ValidateService.makeCashAmountField(this);

        this.applyDynamicFieldValidators();
        this.reorderValidators();
        this.state = this.getDefaultState(parms);

    }

    static loadWithWeightVisible(parms, thisState)
    {
        const weightTypes = [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND
        ];
        return (
            (
                // no prompt for obtain method
                parms.obtainTypeDetailList && 
                parms.obtainTypeDetailList.length === 1 && 
                weightTypes.includes(
                    obtainToValue(parms.obtainTypeDetailList[0])
                )
            )
            ||
            (
                // obtain prompt has ship selected
                thisState.obtainMethod && 
                weightTypes.includes(
                    thisState.obtainMethod.value 
                )
            )
            ||
            (
                // obtain prompt not yet rendered - first option ship 
                !thisState.obtainMethod && 
                parms.obtainTypeDetailList && 
                parms.obtainTypeDetailList.length > 0 && 
                weightTypes.includes(
                    obtainToValue(parms.obtainTypeDetailList[0]) 
                )
            )
        ) && parms.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER
    }

    /* istanbul ignore next */
    static getValidators(parms)
    {
        return {
            title: {
                isValid: ValidateService.valueRequired,
            },
            details: {
                isValid: ValidateService.textLines,
            },
            weightPounds: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => _BidCashPaymentPrompt.loadWithWeightVisible(parms, thisState)
            },
            weightOunces: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => _BidCashPaymentPrompt.loadWithWeightVisible(parms, thisState)
            },
            hasRefundPolicy: {
                isValid: ValidateService.anyValue,
            },
            saveRefundPolicy: {
                isValid: ValidateService.anyValue,
                isVisible: (thisState) => parms.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && 
                    parms.setupBid && 
                    !parms.setupBid.hasRefundPolicy && 
                    thisState.hasRefundPolicy.value,
            },
            refundPolicyDetails: {
                isValid: (value, thisState) => {
                    if(thisState.hasRefundPolicy.value)
                    {
                        return ValidateService.valueRequired(value);
                    }

                    return true;
                },
                isVisible: (thisState) => thisState.hasRefundPolicy.value
            },
        };
    }

    applyDynamicFieldValidators()
    {
        const relevantExchangeList = this.props.exchangeList ? filterRelevantExchanges(this.props.bidContentType, this.props.exchangeList) : [];
        if( relevantExchangeList.length > 1)
        {
            this.validator.exchangeMethod = {
                isValid: ValidateService.anyValue
            };
        }
        
        if( this.props.obtainTypeDetailList && 
            this.props.obtainTypeDetailList.length > 1)
        {
            this.validator.obtainMethod = {
                isValid: ValidateService.anyValue
            };
        }
    }

    /* istanbul ignore next */
    reorderValidators()
    {
        const nextValidators = {};
        const nextOrder = [
            'exchangeMethod',
            'currency',
            'title',
            'details',
            'weightPounds',
            'weightOunces',
            'hasRefundPolicy',
            'saveRefundPolicy',
            'refundPolicyDetails',
            'obtainMethod'
        ];

        for(const aValName of nextOrder)
        {
            if(this.validator[aValName])
            {
                nextValidators[aValName] = this.validator[aValName];
            }
        }

        this.validator = nextValidators;
    }

    getAskingError()
    {
        return `Specify a valid USD amount`;
    }

    /* istanbul ignore next */
    getDynamicFields()
    {
        let out = {};
        const relevantExchangeList = this.props.exchangeList ? filterRelevantExchanges(this.props.bidContentType, this.props.exchangeList) : [];
        let uniqueValue = [];
        const filterToUnique = exOpt => {
            const value = exchangeToValue(exOpt);
            const found = uniqueValue.find(aVal => aVal === value);
            if(!found)
            {
                uniqueValue.push(value);
                return true;
            }
            else
            {
                return false;
            }
        };
        if( relevantExchangeList.length > 1)
        {
            out.exchangeMethod = {
                name: 'Exchange Method',
                error: 'Specify which exchange method should be used',
                value: exchangeToValue(relevantExchangeList.filter(exOpt => exOpt.type === EXCHANGE_TYPE_CASH)[0]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: relevantExchangeList
                    .filter(filterToUnique)
                    .map(mapExchangeToValueItem),

            };
        }

        if( this.props.obtainTypeDetailList && 
            this.props.obtainTypeDetailList.length > 1)
        {
            out.obtainMethod = {
                name: 'Delivery Method',
                error: 'Specify which delivery method should be used',
                value: obtainToValue(this.props.obtainTypeDetailList[0]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: this.props.obtainTypeDetailList.map(mapObtainToValueItem),
            };
        }

        return out;
    }

    controlValueChange(name, value, entity = null)
    {
        if(name === 'exchangeMethod')
        {
            this.setExchangeMethod(value);
        }
        super.controlValueChange(name, value, entity);
    }

    getFieldsModel()
    {
        const isWeightPromptNeeded = _BidCashPaymentPrompt.loadWithWeightVisible(this.props, {});
        const validateTitle = ValidateService.makeCashAmountField(this);
        const titleValue = CurrencyInputService.currencyToInput(this.props.asking);
        const titleValid = validateTitle(titleValue);

        return {
            title: {
                name: 'Bid*',
                error: this.getAskingError("usd"),
                value: titleValue,
                visible: true,
                valid: titleValid,
                touched: false,
                type: FORM_FIELD.TEXT,
            },
            details: {
                name: 'Details',
                error: 'Provide details regarding your bid, no more than 2170 characters',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
            weightPounds: {
                name: 'Shipping Weight Pounds',
                error: 'Specify the pounds in shipping weight',
                value: '',
                visible: isWeightPromptNeeded,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            weightOunces: {
                name: 'Shipping Weight Ounces',
                error: 'Specify the ounces in shipping weight',
                value: '',
                visible: isWeightPromptNeeded,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            hasRefundPolicy: {
                name: 'Refund Policy Offered',
                error: 'Do you offer a refund policy?',
                value: this.props.setupBid ? this.props.setupBid.hasRefundPolicy : false,
                visible: this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX,
            },
            saveRefundPolicy: {
                name: 'Save Refund Policy for Re-Use',
                error: 'Do you wish to save this policy for re-use?',
                value: false,
                visible: false,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            refundPolicyDetails: {
                name: 'Please explain your refund policy',
                error: 'Explain your refund policy',
                value: this.props.setupBid && this.props.setupBid.hasRefundPolicy ? this.props.setupBid.refundPolicy : '',
                visible: this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && this.props.setupBid ? this.props.setupBid.hasRefundPolicy : false,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    setExchangeMethod(value)
    {
        if(this.props.setExchangeMethod)
        {
            this.props.setExchangeMethod(value);
        }
    }

    isPromptingLocation(state)
    {
        const isOffer = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? true : false;
        const showList = isOffer ? [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND,
            OBTAIN_TYPE_ON_SITE
        ] : [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND,
            OBTAIN_TYPE_ON_SITE,
            OBTAIN_TYPE_PICK_UP
        ];
        if(this.props.obtainTypeDetailList && 
            this.props.obtainTypeDetailList.length > 0)
        {
            if(this.props.obtainTypeDetailList.length === 1)
            {
                return showList.includes(this.props.obtainTypeDetailList[0].type);
            }

            return showList.includes(state.obtainMethod.value);
        }
        return false;
    }

    /* istanbul ignore next */
    validateState(state)
    {
        let baseReturn = super.validateState(state);
        if(this.isPromptingLocation(state))
        {
            if(!this.state.selectedLocation)
            {
                baseReturn.isValid = false;
            }
        }
        return baseReturn;
    }

    /* istanbul ignore next */
    onLocationSelected(location)
    {
        let nextState = this.state;
        nextState.selectedLocation = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onLocationRemoveSelected()
    {
        let nextState = this.state;
        nextState.selectedLocation = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    getFormFieldsUi()
    {
        let output = [];
        for(const aField in this.validator)
        {
            try
            {
                if(this.state[aField].visible)
                {

                    output.push(
                        <section key={aField}>
                            { this.getFormFieldUi(aField) }
                        </section>
                    );
                }

                if(aField === 'obtainMethod' || (aField === 'details' && this.props.obtainTypeDetailList && this.props.obtainTypeDetailList.length === 1))
                {
                    if(this.isPromptingLocation(this.state))
                    {
                        const selectedType = this.state.obtainMethod ? this.state.obtainMethod.value : (
                            this.props.obtainTypeDetailList && this.props.obtainTypeDetailList[0] && this.props.obtainTypeDetailList[0].type ? 
                                this.props.obtainTypeDetailList[0].type : ''
                        );
                        const isOffer = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? true : false;
                        const title = getObtainTypeLocationTitleCopy(selectedType, isOffer);
                        
                        output.push(
                            <LocationSelectorPanel
                                promptTitle={title}
                                key={`locationSelector${output.length}`}
                                selectedLocation={this.state.selectedLocation}
                                onSelected={this.onLocationSelected}
                                onRemoveSelected={this.onLocationRemoveSelected}
                                includeGps={true}
                            />
                        );
                    }
                }
            }
            catch(err)
            {
                /* istanbul ignore next */
                throw new Error(`Unable to read ${aField}`);
            }
        }
        return output;
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        const dynamicFields = this.getDynamicFields();
        let nextState = {
            ...baseState,
            ...fields,
            ...dynamicFields,
            isValid: true,
            selectedLocation: null
        };
        nextState.isValid = !this.isPromptingLocation(nextState);
        return nextState;
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? {
            offerId: this.props.contentId,
            title: CurrencyInputService.parse(this.state.title.value),
            details: this.state.details.value,
            selectedLocation: this.isPromptingLocation(this.state) ? this.state.selectedLocation.id : '',
            obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                this.props.obtainTypeDetailList.length > 0 ? 
                    obtainToValue(this.props.obtainTypeDetailList[0]) : ''
                ),
            exchangeMethod: EXCHANGE_TYPE_CASH,
        } : {
            seekId: this.props.contentId,
            title: CurrencyInputService.parse(this.state.title.value),
            details: this.state.details.value,
            selectedLocation: this.isPromptingLocation(this.state) ? this.state.selectedLocation.id : '',
            obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                this.props.obtainTypeDetailList.length > 0 ? 
                    obtainToValue(this.props.obtainTypeDetailList[0]) : ''
                ),
            weight: _BidCashPaymentPrompt.loadWithWeightVisible(this.props, this.state) ? {
                pounds: this.state.weightPounds.value,
                ounces: this.state.weightOunces.value
            } : {},
            exchangeMethod: EXCHANGE_TYPE_CASH,
            hasRefundPolicy: this.state.hasRefundPolicy.value,
            refundPolicyDetails: this.state.hasRefundPolicy.value ? this.state.refundPolicyDetails.value : '',
            saveRefundPolicy: 
                this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && 
                this.props.setupBid && 
                !this.props.setupBid.hasRefundPolicy ? this.state.saveRefundPolicy.value : false,
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.props.setBids(response.bids);
        this.setState(nextState);        
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        const request = this.getServerRequest();
        const requestName = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? 'addOfferBid' : 'addSeekBid';
        BidService[requestName]({
            request,
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getConfirmUi(confirmMessage = 'Bid Submitted')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Submit Bid')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    displayPriceUi()
    {
        const parsedVal = CurrencyInputService.parse(this.state.title.value);
        const cleanAmt = parsedVal.replace(/^0+/, '');

        return (
            <div>
                { 
                    CurrencyService.getDisplayPrice(
                        'usd',
                        cleanAmt,
                        false
                    ) 
                }
            </div>
        );
    }

    getPromptUi()
    {
        return (
            <div style={{
                ...theme.getBidPromptStyle(theme),
            }}>
                <h2>
                    Place a cash bid
                </h2>
                <p>
                    Make sure to include all relevant details such as currency type.
                </p>
                <p>
                    <span style={{
                        ...theme.getWarningTextStyle(theme),
                    }}>
                        Bid field will be visible to all users, details section will only be visible to the poster
                    </span>
                </p>

                {
                    this.state.title.valid ? this.displayPriceUi() : ''
                }

                { super.getPromptUi() }
                
                <p>
                    {
                        this.state.obtainMethod ? ObtainMethodCopy({
                            bid: {
                                obtainMethod: this.state.obtainMethod.value 
                            },
                            bidderIsReceiver: this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER,
                            bidderName: 'you'
                        }) : ''

                    }
                </p>

                

            </div>
        );
    }
}

const BidCashPaymentPrompt = connect(mapStateToProps, mapDispatchToProps)(_BidCashPaymentPrompt);
export default BidCashPaymentPrompt;
