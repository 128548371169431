import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import AttributeListPanel from './../core/attribute-list';
import theme from './../../theme';

const AttSetDetailViewPanel = ({ onRemove, data: attSet, }) => {
    const getPanelStyle = () => theme.getAttSetDetailViewPanelStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPanelStyle( getPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={panelStyle}>
            <h2 style={{ 
                ...theme.getGeneralTextStyle(theme),
            }}>
                { attSet.title }
            </h2>
            <AttributeListPanel attributeList={attSet.attributeList} />
            <button 
                onClick={event => { onRemove() }}
                className="test-attSetDetailDeleteBtn"
            >
                Delete
            </button>
        </div>
    );
};

export default AttSetDetailViewPanel;
