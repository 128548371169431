import React from 'react';
import { useState, useEffect } from 'react';
import TrackStepsPanel from './../core/track-steps';
import ExchangeChatPanel from './../exchange-chat';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import FulfillmentPanel from './fulfillment-panel';
import OtherMethodsOfContactPanel from './other-methods-of-contact-panel';
import StepView from './step-view';
import ExchangeHeader from './exchange-header';

const ExchangeDetailViewPanel = ({ 
    data: exchange, 
    steps, 
    currentStep,
    onPaid,
    onPaymentConfirmed,
    onFulfilled,
    onExchangeConfirmed,
    onApproveEarlyPayout,
    onReviewed, 
    onFlagged,
    onRequestRefund,
    onRefundReviewed,
    onAcceptDisputeLoss,
    onDisputeResponded,
    awaitingExchangeStep,
    onLoadDataResponse,
    onProvidedToShipping
 }) => {
    const bidderIsReceiver = exchange.targetType === 'offer' ? true : false;
    const postAuthor = exchange.usersDetails.find(aUser => aUser.userId === exchange.target.userId);
    const bidAuthor = exchange.usersDetails.find((aUser) => aUser.userId === exchange.bid.userId);

    const canChat = exchange.bid.lockedForDispute && exchange.bid.disputeDetails && exchange.bid.disputeDetails.isChargeBack ? false : (
        exchange.bid.onlinePayment ? (
            !exchange.bid.paidOut  && !exchange.bid.hasRefunded
        ) : (
            !exchange.fulfilledConfirmed && !exchange.bid.hasRefunded
        )
    );

    const onlineTransDetail = {
        ...exchange.bid,
        total: exchange.bid.total ? exchange.bid.total : exchange.bid.title
    };

    const getContainerStyle = () => ({
        ...theme.getProfilePwdPageStyle(theme),
        ...theme.getAbstractBottomPaddingSpacerStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);

    return (
        <section style={containerStyle}>

            <ExchangeHeader
                exchange={exchange}
                postAuthor={postAuthor}
                bidAuthor={bidAuthor}
                onFlagged={onFlagged}
                onRequestRefund={onRequestRefund}
                onApproveEarlyPayout={onApproveEarlyPayout}
                onlineTransDetail={onlineTransDetail}
                bidderIsReceiver={bidderIsReceiver}

            />

            {
                currentStep >= awaitingExchangeStep && 
                    <FulfillmentPanel 
                        exchange={exchange}
                        postAuthor={postAuthor}
                        bidAuthor={bidAuthor} 
                    />
            }
            

            <TrackStepsPanel list={steps} currentStep={currentStep} />

            <div style={{
                ...theme.getExchangeDetailViewPanelStyle(theme),
            }}>
                <StepView 
                    exchange={exchange} 
                    onDisputeResponded={onDisputeResponded}
                    currentStep={currentStep} 
                    onPaid={onPaid}
                    onAcceptDisputeLoss={onAcceptDisputeLoss}
                    onPaymentConfirmed={onPaymentConfirmed}
                    onFulfilled={onFulfilled}
                    onExchangeConfirmed={onExchangeConfirmed}
                    onReviewed={onReviewed}
                    onRefundReviewed={onRefundReviewed}
                    onLoadDataResponse={onLoadDataResponse}
                    onProvidedToShipping={onProvidedToShipping}
                />

                {
                    exchange.flagged ? <h2>This conversation has been flagged.  Awaiting Review</h2> : ''
                }
                
                <ExchangeChatPanel 
                    exchangeId={exchange.id} 
                    chatPromptVisible={canChat} 
                />
                
                <OtherMethodsOfContactPanel exchange={exchange} />
                

            </div>

        </section>
    );
};

export default ExchangeDetailViewPanel;
