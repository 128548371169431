import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import WidgetNewsPanel from './widget/news';
import WidgetHelpPanel from './widget/help';
import WidgetTrailsPanel from './widget/trails';
import WidgetMyActiveOffersPanel from './widget/my-active-offers';
import WidgetMyActiveSeeksPanel from './widget/my-active-seeks';
import WidgetMyArchivedOffersPanel from './widget/my-archived-offers';
import WidgetMyArchivedSeeksPanel from './widget/my-archived-seeks';
import WidgetMyActiveExchangesPanel from './widget/my-active-exchanges';
import WidgetMyArchivedExchangesPanel from './widget/my-archived-exchanges';
import WidgetPendingBidsPanel from './widget/pending-bids';
import WidgetFriendsPanel from './widget/friends';
import GearIcon from './../../../img/gearWhite.svg';
import CloseIcon from './../../../img/closeWhite.svg';
import EmptyStarIcon from './../../../img/emptyStar.svg';
import FullStarIcon from './../../../img/fullStar.svg';
import ExpandIcon from './../../../img/expand.svg';
import theme from './../../theme';
import LoadingPanel from './../core/loading';
import LogoPanel from './../logo';
import SortListPrompt from './../../prompt/core/sort-list';
import WidgetService from './../../../service/widget';
import ResizeService from './../../../service/resize';
import { ShowMenuContext } from './../../menu/core/menu-container';

const AvailableWidget = ({ 
    widget, 
    index,
    onAddWidget,
    onRemoveWidget,
 }) => {
    const [ availableWidgetContainerStyle, setAvailableWidgetContainerStyle] = useState(theme.getAvailableWidgetContainerStyle(theme));
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setAvailableWidgetContainerStyle(theme.getAvailableWidgetContainerStyle(theme));
    });
    useEffect(resizeEffect);
    return (
        <div 
            style={availableWidgetContainerStyle}
            key={index}
        >
            <div 
                style={{
                    ...theme.getAvailableWidgetSpacerStyle(theme),
            }}
            >
            </div>
            <div>
                { 
                    widget.added ? 
                        <theme.getAvailableWidgetButtonUi 
                            theme={theme}
                            icon={FullStarIcon}
                            className="test-userLandingRemoveWidgetBtn"
                            onClick={event => { onRemoveWidget(widget) }} 
                        /> :
                        <theme.getAvailableWidgetButtonUi 
                            theme={theme}
                            icon={EmptyStarIcon}
                            className="test-userLandingAddWidgetBtn"
                            onClick={event => { onAddWidget(widget) }} 
                        />
                }
            </div>
            <h3>
                { widget.title }
            </h3>
            <p>
                { widget.summary }
            </p>
        </div>
    );
};

const AvailableWidgetsPanel = ({ 
    availableWidgets, 
    userWidgets,
    onAddWidget,
    onRemoveWidget,
}) => {
    const widgets = availableWidgets.map(aWidget => {
        const userWidget = userWidgets.find(uWidget => uWidget.type === aWidget.type);
        const added = !! userWidget;
        const details = added ? {
            added,
            ...userWidget,
        } : {
            added,
        };
        return {
            ...aWidget,
            ...details,
        };
    });
    return (
        <section style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <h2>
                Widget Settings
            </h2>
            <p>
                Add or remove widgets to customize your dashboard.
            </p>
            <div style={{
                ...theme.getAvailableWidgetOuterContainerStyle(theme),
            }}>
                {
                    widgets.map((widget, index) => AvailableWidget({
                        widget, 
                        index,
                        onAddWidget,
                        onRemoveWidget,
                    }))
                }
            </div>
            
        </section>
    );
};

const EmptyUserWidgetsPanel = () => 
    <div style={{
         ...theme.getGeneralTextStyle(theme),
    }}>
        <LogoPanel />
        <h2>
            Dashboard
        </h2>
        <p>
            Add widgets to customize your dashboard.
        </p>
    </div>

export const UserWidgetPanel = ({ type }) => type === 'news' ? <WidgetNewsPanel /> : (
    type === 'help' ? <WidgetHelpPanel /> : 
    (
        type === 'trails' ? <WidgetTrailsPanel /> :
        (
            type === 'myactiveoffers' ? <WidgetMyActiveOffersPanel /> : 
            (
                type === 'myactiveseeks' ? <WidgetMyActiveSeeksPanel /> : 
                (

                    type === 'myarchivedoffers' ? <WidgetMyArchivedOffersPanel /> :
                    (

                        type === 'myarchivedseeks' ? <WidgetMyArchivedSeeksPanel /> :
                        (
                            
                            type === 'myactiveexchanges' ? <WidgetMyActiveExchangesPanel /> :
                            (

                                type === 'myarchivedexchanges' ? <WidgetMyArchivedExchangesPanel /> :
                                (
                                    type === 'friends' ? <WidgetFriendsPanel /> : 
                                    (
                                        type === 'pendingbids' ? <WidgetPendingBidsPanel /> :
                                        <div>
                                            Invalid Widget
                                        </div>
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);

const PopulatedUserWidgetsPanel = ({ userWidgets }) => {
    const history = useHistory();

    const [ userWidgetContainerStyle, setUserWidgetContainerStyle] = useState(theme.getUserWidgetContainerStyle(theme));
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetContainerStyle(theme.getUserWidgetContainerStyle(theme));
    });
    useEffect(resizeEffect);

    return (
        <div style={{
            ...theme.getUserWidgetOuterContainerStyle(theme),  
        }}>  
            {
                userWidgets.map((widget, index) => 
                    <div 
                        key={index}
                        style={userWidgetContainerStyle}
                    >
                        <div 
                            style={{
                                ...theme.getUserWidgetSpacerStyle(theme),
                            }}
                        >
                        </div>
                        <div 
                            style={{
                                ...theme.getUserWidgetExpandButtonStyle(theme, ExpandIcon),
                            }}
                            onClick={
                                /* istanbul ignore next */
                                event => { history.push(`/widget/${widget.type}`) }
                            }>
                        </div>
                        <UserWidgetPanel type={widget.type} />
                
                    </div>
                )
            }
            <div style={{ clear: 'both' }}></div>
        </div>
    );
};

const UserWidgetsPanel = ({ userWidgets }) => {
    return userWidgets.length < 1 ? 
        <EmptyUserWidgetsPanel /> : 
        <PopulatedUserWidgetsPanel userWidgets={userWidgets} />
};

/* istanbul ignore next */
const UserLandingViewPanelMenuClosed = ({
    setDisplayAvailableWidgetsPrompt
}) => 
    <div>
        <div
            style={{
                ...theme.getUserLandingAvailableWidgetsButtonStyle(theme, GearIcon),
            }} 
            className="test-userLandingCustomizeBtn"
            onClick={event => { setDisplayAvailableWidgetsPrompt(true) }}>
        </div>
        <div 
            style={{
                ...theme.getMenuSubTitleStyle(theme),
            }}>Personalization
        </div>
    </div>

/* istanbul ignore next */
const UserLandingViewPanelMenuAdd = ({
    setDisplayAvailableWidgetsPrompt,
    setDisplaySortWidgets
}) => {
    const [menuDockStyle, setMenuDockStyle] = useState(theme.getMenuDockStyle(theme));
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setMenuDockStyle(theme.getMenuDockStyle(theme));
    });
    useEffect(resizeEffect);
    return (
        <div style={menuDockStyle}>
            <div 
                className="test-userLandingSortBtn"
                onClick={event => { setDisplaySortWidgets(true) }}
            >
                <div
                    style={{
                        ...theme.getUserLandingAvailableWidgetsButtonStyle(theme, GearIcon),
                    }} 
                >
                </div>
                <div 
                    style={{
                        ...theme.getMenuSubTitleStyle(theme),
                    }}>Sort
                </div>
            </div>
            <div 
                className="test-userLandingExitBtn"
                onClick={event => { setDisplayAvailableWidgetsPrompt(false) }}
            >
                <div
                    style={{
                        ...theme.getUserLandingAvailableWidgetsButtonStyle(theme, CloseIcon),
                    }} 
                >
                </div> 
                <div 
                    style={{
                        ...theme.getMenuSubTitleStyle(theme),
                    }}>Exit Customization
                </div>
            </div>
        </div>
    );
};

/* istanbul ignore next */
const UserLandingViewPanelMenuSort = ({
    setDisplayAvailableWidgetsPrompt,
    setDisplaySortWidgets
}) => {
    const [menuDockStyle, setMenuDockStyle] = useState(theme.getMenuDockStyle(theme));
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setMenuDockStyle(theme.getMenuDockStyle(theme));
    });
    useEffect(resizeEffect);
    return (
        <div style={menuDockStyle}>
            <div 
                className="test-userLandingAddRemoveBtn"
                onClick={event => { setDisplaySortWidgets(false) }}
            >
                <div
                    style={{
                        ...theme.getUserLandingAvailableWidgetsButtonStyle(theme, GearIcon),
                    }} 
                >
                </div>
                <div 
                    style={{
                        ...theme.getMenuSubTitleStyle(theme),
                    }}>Add/Remove
                </div>
            </div>
            <div 
                className="test-userLandingExitBtn"
                onClick={event => { 
                    setDisplaySortWidgets(false);
                    setDisplayAvailableWidgetsPrompt(false);
                }}
            >
                <div
                    style={{
                        ...theme.getUserLandingAvailableWidgetsButtonStyle(theme, CloseIcon),
                    }} 
                >
                </div> 
                <div 
                    style={{
                        ...theme.getMenuSubTitleStyle(theme),
                    }}>Exit Customization
                </div>
            </div>
        </div>
    );
};

/* istanbul ignore next */
export const UserLandingViewPanelMenu = ({ 
    displayAvailableWidgetsPrompt, 
    setDisplayAvailableWidgetsPrompt,
    displaySortWidgets,
    setDisplaySortWidgets
}) => {

    const showMenu = useContext(ShowMenuContext);
    const getMenuStyle = () => showMenu ? theme.getMenuDockStyle(theme) : theme.getMenuDockHiddenStyle(theme);
    const [ menuDockStyle, setMenuDockStyle ] = useState(getMenuStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setMenuDockStyle( getMenuStyle() );
    });

    useEffect(resizeEffect);
    
    useEffect(() => {
        setMenuDockStyle( getMenuStyle() );
        // eslint-disable-next-line
    }, [showMenu]);

    return (
        <div style={menuDockStyle}>
            {
                displayAvailableWidgetsPrompt ? 
                    (
                        displaySortWidgets ? 
                            <UserLandingViewPanelMenuSort setDisplayAvailableWidgetsPrompt={setDisplayAvailableWidgetsPrompt} 
                                setDisplaySortWidgets={setDisplaySortWidgets} 
                            /> :
                                <UserLandingViewPanelMenuAdd 
                                    setDisplayAvailableWidgetsPrompt={setDisplayAvailableWidgetsPrompt} 
                                    setDisplaySortWidgets={setDisplaySortWidgets} 
                                /> 
                    ): 
                        <UserLandingViewPanelMenuClosed setDisplayAvailableWidgetsPrompt={setDisplayAvailableWidgetsPrompt} />
            }
        </div>
    );
};

/* istanbul ignore next */
const SortWidgetsPanel = ({ userWidgets, onSortUpdated, setUserWidgets }) => 
    
    <div style={{
        ...theme.getGeneralTextStyle(theme),
    }}>
        <h2>
            Sort Widgets
        </h2>
        <p>
            Drag around your widgets to customize their order
        </p>
    
        <SortListPrompt 
            onSortUpdated={onSortUpdated}
            setItems={setUserWidgets}
            items={userWidgets}
            itemToUx={({ value }) => 
                <div style={{
                    ...theme.getUserWidgetSortWidgetStyle(theme)
                }}>
                    { WidgetService.getWidgetTitleByType(value.type) }
                </div>
            }
            itemToKey={value => value.id }
        />
    </div>

const UserLandingViewPanel = ({ 
    availableWidgets, 
    userWidgets,
    onAddWidget, 
    onRemoveWidget,
    contactingServer,
    displayAvailableWidgetsPrompt,
    displaySortWidgets,
    onSortUpdated,
    setUserWidgets
}) => <section>
        {
            displayAvailableWidgetsPrompt ? (
                displaySortWidgets ? 
                    <SortWidgetsPanel 
                        userWidgets={userWidgets}
                        onSortUpdated={onSortUpdated}
                        setUserWidgets={setUserWidgets}
                    /> :
                        <AvailableWidgetsPanel 
                            availableWidgets={availableWidgets} 
                            userWidgets={userWidgets} 
                            onAddWidget={onAddWidget}
                            onRemoveWidget={onRemoveWidget}
                        /> 
            ) : (
                /* istanbul ignore next */
                contactingServer ? <LoadingPanel /> : <UserWidgetsPanel userWidgets={userWidgets} />
            )
        }
    </section>

export default UserLandingViewPanel;
