import React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PageTemplate from './template/page';
import mapStateToProps from './../store/map/is-user';
import JoinPrompt from './../ui/prompt/join';
import ProfileMenu from './../ui/menu/profile';
import UserInvitesPanel from './../ui/panel/user-invites';
import theme from './../ui/theme';
import ResizeService from './../service/resize';

const Join = ({ isUser }) => {
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <PageTemplate 
            isUser={isUser} 
            childMenu={
                <div>
                    <ProfileMenu /> 
                </div>
            }>
            <section style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                { 
                    /* istanbul ignore next */
                    !isUser ? '' : 
                        <div>
                            <div style={containerStyle}>
                                <h2>
                                    Invite Friends
                                </h2>
                            </div>
                            <p style={{
                                ...theme.getGeneralTextStyle(theme),
                            }}>
                                Use the link below to invite trusted partners.
                            </p>
                        </div>
                }
            
                { 
                    /* istanbul ignore next */
                    isUser ? <UserInvitesPanel /> : <JoinPrompt /> 
                }
            </section>
        </PageTemplate>
    );
};

const JoinPage = connect(mapStateToProps)(Join);
export default JoinPage;
