import React from 'react';

const FinishSetupDeliveryView = ({ 
    delivery, 
    onProvidedToShipping 
}) => 
    <div>
        <button 
            className="test-exchangeDetailFinishSetupDeliveryViewPackagePickedUpBtn"
            onClick={event => { onProvidedToShipping(delivery) }}
        >
            Package has been picked up by carrier
        </button>
    </div>

export default FinishSetupDeliveryView;
