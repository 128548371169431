import config from './../../../config';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import UrlService from '../../../service/url';

const getCopy = (redirectType) => 'Thank you for your payment.';
const getRedirectPath = (redirectType) => config.redirects.userFines;
const getLinkText = (redirectType) => 'Return to Chargeback Fees Page';
const TaskCompletedRedirectPanel = ({ redirectType }) => {
    setTimeout(
        /* istanbul ignore next */
        () => {
            const tester = /payment_intent/i;
            UrlService.correctUrl(tester, config.pay.finePaymentReloadCompletedUrl);
        }, 
        config.pay.reloadDelay
    );

    const url = getRedirectPath(redirectType);
    const text = getCopy(redirectType);
    const linkText = getLinkText(redirectType);
    return <div style={{
        ...theme.getGeneralTextStyle(theme)  
    }}>
        <p>
            { text }
        </p>
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={url}
        >
            { linkText } 
        </NavLink>

    </div>
};

export default TaskCompletedRedirectPanel;
