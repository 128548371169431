export const getProfileShopTraitTabsStyle = (theme) => ({
    display: 'flex'
});

export const getProfileShopTraitsContainerStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    textAlign: 'center',
    width: '100%',
    maxWidth: theme.isTablet() ? '60rem' : 'calc(100% - 1.5rem)',
});

export const getProfileShopTraitsTabStyle = (theme, isSelected) => ({
    width: '160px',
    cursor: 'pointer',
    borderBottomColor: isSelected ? theme.secondaryColor : theme.primaryColor,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    height: '2rem',
});