import React from 'react';
import PageTemplate from './template/page';
import UnsubscribePrompt from './../ui/prompt/unsubscribe';
import { useParams } from 'react-router-dom';

const UnsubscribePage = ({ isUser }) => {
    const { email } = useParams();
    return (
        <PageTemplate isUser={isUser}>
            <UnsubscribePrompt email={email} />
        </PageTemplate>
    );
};

export default UnsubscribePage;
