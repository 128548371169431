import React from 'react';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';
import { Status } from './../../panel/online-transactions-list/view';
import UiService from './../../../service/ui';
import CurrencyService from './../../../service/currency';

export const Header = () => 
    <thead>
        <tr>
            <td>
                Exchange
            </td>
            <td>
                Status
            </td>
            <td>
                Created
            </td>
            <td>
                Updated
            </td>
            <td>
                Currency
            </td>
            <td>
                Payment
            </td>
            <td>
                Fee
            </td>
            <td>
                Payout
            </td>
        </tr>
    </thead>

export const AdminPayoutsSearchViewPrompt = ({ trans, selectPayoutRecord }) => 
    <tr>
        <td>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/exchange/${trans.exchangeId}`}
            >
                { trans.exchangeId }
            </NavLink>
        </td>
        <td>
            <span 
                style={{ 
                    ...theme.getAdminPayoutsStatusButtonStyle(theme)
                }} 
                className="test-adminPayoutsSearchSelectBtn"
                onClick={event => { selectPayoutRecord(trans) }}
            >
                <Status trans={trans} />
            </span>
        </td>
        <td>
            { UiService.getDateReport(trans.createdOn) }
        </td>
        <td>
            { UiService.getDateReport(trans.updatedOn) }
        </td>
        <td>
            { trans.currency.toUpperCase() }
        </td>
        <td>
            { CurrencyService.getDisplayPrice(trans.currency, trans.total, false) }
        </td>
        <td>
            { 
                trans.hasRefunded ? ' - ' : 
                    CurrencyService.getDisplayPrice(trans.currency, trans.fee, false) 
            }
        </td>
        <td>
            { 
                trans.hasRefunded ? ' - ' : 
                    CurrencyService.getDisplayPrice(trans.currency, trans.netTotal, false) 
            }
        </td>

    </tr>

