import React from 'react';
import UserService from './../../../service/user';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import ValidateService from './../../../service/validate';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import theme from './../../theme';

class _Verify2FAPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _Verify2FAPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    static getValidators()
    {
        return {
            token: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    getFieldsModel()
    {
        return {
            token: {
                name: '2FA Token*',
                error: 'Please provide the current 2FA Token',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT,
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onServerResponse(response)
    {
        if(this.props.onVerified)
        {
            this.props.onVerified(response);
        }
        else
        {
            let nextState = this.getBaseStateOnResponse(response);
            nextState = this.clearFields(nextState);
            this.setState(nextState);
        }
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        UserService.verify2FAToken({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getConfirmUi(confirmMessage = 'Your token has been verified and enabled')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Verify and Enable')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getPromptUi()
    {
        return (
            <section style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                { super.getPromptUi() }
            </section>
        );
    }

    getServerRequest()
    {
        return {
            token: this.state.token.value,
        };
    }

}

const Verify2FAPrompt = connect(mapStateToProps, mapDispatchToProps)(_Verify2FAPrompt);
export default Verify2FAPrompt;
