import React from 'react';
import { Fragment } from 'react';

export const getTrackStepsEmptyOne = (theme) => <Fragment>&#x2780;</Fragment>
export const getTrackStepsEmptyTwo = (theme) => <Fragment>&#x2781;</Fragment>
export const getTrackStepsEmptyThree = (theme) => <Fragment>&#x2782;</Fragment>
export const getTrackStepsEmptyFour = (theme) => <Fragment>&#x2783;</Fragment>
export const getTrackStepsEmptyFive = (theme) => <Fragment>&#x2784;</Fragment>
export const getTrackStepsEmptySix = (theme) => <Fragment>&#x2785;</Fragment>
export const getTrackStepsEmptySeven = (theme) => <Fragment>&#x2786;</Fragment>
export const getTrackStepsEmptyEight = (theme) => <Fragment>&#x2787;</Fragment>
export const getTrackStepsEmptyNine = (theme) => <Fragment>&#x2788;</Fragment>

export const getTrackStepsFullOne = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
        <Fragment>&#x2776;</Fragment>
    </span>

export const getTrackStepsFullTwo = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x2777;</Fragment>
    </span>

export const getTrackStepsFullThree = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x2778;</Fragment>
    </span>

export const getTrackStepsFullFour = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x2779;</Fragment>
    </span>

export const getTrackStepsFullFive = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x277a;</Fragment>
    </span>

export const getTrackStepsFullSix = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x277b;</Fragment>
    </span>

export const getTrackStepsFullSeven = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x277c;</Fragment>
    </span>

export const getTrackStepsFullEight = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x277d;</Fragment>
    </span>

export const getTrackStepsFullNine = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x277e;</Fragment>
    </span>

export const getTrackStepsChecked  = (theme) => 
    <span style={{
        ...theme.getTrackStepsFullStyle(theme),
    }}>
         <Fragment>&#x2714;</Fragment>
    </span>

export const getTrackStepsPanelStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '.5rem',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    whiteSpace: 'nowrap',
    maxWidth: '42rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

export const getTrackStepTextStyle = (theme) => ({
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
});

export const getTrackStepTileStyle = (theme) => ({
    height: 'fit-content'
});

export const getTrackStepsFullStyle = (theme) => ({
    fontSize: '32pt',
});

export const getCompletedTrackStepTextStyle = (theme) => ({
    fontSize: '11pt',
    fontWeight: '700'
});