import React from 'react';
import FineService from './../../../service/fine';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../core/search';
import { Header, UserSearchFineViewPrompt } from './view';
import theme from './../../theme';
import Dialog from '@mui/material/Dialog';
import LoadingPanel from './../../panel/core/loading';

class _UserSearchFinePrompt extends SearchPrompt
{
    constructor(parms)
    {
        super(parms, _UserSearchFinePrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
        this.isUsingSubmitButton = this.isUsingSubmitButton.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.removeFineById = this.removeFineById.bind(this);
        this.addFineById = this.addFineById.bind(this);
        this.getSelectedFineTotal = this.getSelectedFineTotal.bind(this);
        this.onContactingPayServer = this.onContactingPayServer.bind(this);
        this.onContactingPayServerDone = this.onContactingPayServerDone.bind(this);
    }

    static getValidators()
    {
        return {

        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            isValid: true,
            results: [],
            selectedFineIds: [],
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'fineList');
    }

    onSendToServer(request = undefined)
    {
        FineService.userSearch({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            page: 0
        };
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        this.onSendToServer(request);
    }

    getNoResultsUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <p>
                    No Fees Found.
                </p>
            </div>
        );
    }

    /* istanbul ignore next */
    removeFineById(fineId)
    {
        const nextSelectedFineIds = this.state.selectedFineIds.filter(id => id !== fineId);
        const nextState = {
            ...this.state,
            selectedFineIds: nextSelectedFineIds
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    addFineById(fineId)
    {
        const nextState = this.state;
        nextState.selectedFineIds.push(fineId);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onContactingPayServer()
    {
        let nextState = this.state;
        nextState.contactingPayServer = true;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onContactingPayServerDone()
    {
        let nextState = this.state;
        nextState.contactingPayServer = false;
        this.setState(nextState);
    }

    getSearchResultUi(aResult, index)
    {
        const isSelectedFine = this.state.selectedFineIds.includes(aResult.id);
        return <UserSearchFineViewPrompt 
            isSelectedFine={isSelectedFine} 
            fineRecord={aResult} 
            onContactingServer={this.onContactingPayServer}
            onContactingServerResponse={this.onContactingPayServerDone}
            setPayClientSecret={this.props.setPayClientSecret}
            key={index}
            removeFineById={this.removeFineById}
            addFineById={this.addFineById}
        />
    }

    /* istanbul ignore next */
    getSelectedFineTotal()
    {
        const includeList = this.state.selectedFineIds;
        const totalInt = this.state.results.filter(aFine => includeList.includes(aFine.id))
            .map(aFine => parseInt(aFine.total))
            .reduce((prev, curr) => prev + curr, 0);
        return totalInt.toString();
    }

    getSearchResultsUi()
    {
        // const hasSelectedFines = this.state.selectedFineIds.length > 0 ? true : false;
        // const fineTotal = hasSelectedFines ? this.getSelectedFineTotal() : '';
        // const payAllButtonText = `Pay ${CurrencyService.getDisplayPrice('usd', fineTotal, false)}`;
        return this.state.results.length < 1 ? this.getNoResultsUi() : 
            <div style={{
                ...theme.getPayoutTransactionContainerStyle(theme)
            }}> 
                <table style={{
                    ...theme.getPayoutTransactionStyle(theme)
                }}> 
                    <Header />
                    <tbody>
                        {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
                    </tbody>
                </table>
                <Dialog open={this.state.contactingPayServer}>
                    <LoadingPanel />
                </Dialog>
            </div>
    }

    isUsingSubmitButton() 
    {
        return false;
    }
}

const UserSearchFinePrompt = connect(mapStateToProps, mapDispatchToProps)(_UserSearchFinePrompt);
export default UserSearchFinePrompt;
