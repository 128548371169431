import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class NoticeService {
    static getUnseenNoticeCountRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static getUnseenNoticeCount({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.notice.unseenCount,
            request: this.getUnseenNoticeCountRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getNoticesRequest(page = 0)
    {
        return {
            page,
        };
    }

    /* istanbul ignore next */
    static getNotices({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.notice.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSeenRequest(noticeId)
    {
        return {
            noticeId
        };
    }

    /* istanbul ignore next */
    static seen({
        notice, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.notice.seen,
            request: this.getSeenRequest(notice.id),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getDeleteRequest(noticeId)
    {
        return {
            noticeId,
        };
    }

    /* istanbul ignore next */
    static delete({
        notice, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.notice.delete,
            request: this.getDeleteRequest(notice.id),
            onResponse, 
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getMarkAllSeenRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static markAllSeen({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.notice.seeAll,
            request: this.getMarkAllSeenRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getDeleteAllRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static deleteAll({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.notice.deleteAll,
            request: this.getDeleteAllRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default NoticeService;
