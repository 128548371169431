import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import UiService from './../../../service/ui';
import ExchangeDetailsAdmin from './exchange-details-admin';
import BidDetailViewPanel from './../bid-detail/view';
import OnlineTransactionDetail from './../online-transaction-detail';
import RefundPolicyPanel from '../core/refund-policy';

const TargetDetails = ({
    target
}) => {
    return (
        <section style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <h2>
                Summary
            </h2>
            <h4>
                { target.title }
            </h4>
            <p>
                { target.details }
            </p>

            {
                target.hasRefundPolicy ? <RefundPolicyPanel data={target} /> : ''
            }

        </section>
    );
};

const ExchangeHeader = ({
    exchange,
    postAuthor,
    bidAuthor,
    onFlagged,
    onRequestRefund,
    onApproveEarlyPayout,
    onlineTransDetail,
    bidderIsReceiver,

}) => 
    <div>

        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            {
                exchange.adminDetails && <ExchangeDetailsAdmin exchange={exchange} />
            }
            
            <NavLink to={`/user/${postAuthor.userId}`}>
                Post Author: { postAuthor.username }
            </NavLink>
            <br />
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/user/${bidAuthor.userId}`}
            >
                {
                    exchange.bid.onlinePayment ? ( 
                        exchange.bid.targetType === 'seek' ? 'Fulfiller: ' : 'Purchaser: '
                    ) : 'Bid Author: '
                }
                { bidAuthor.username }
            </NavLink>
            <br />
            <p>
                Last updated&nbsp;
                { UiService.timeSince(exchange.updatedOn) }
            </p>
        </div>


        <TargetDetails target={exchange.target} />

        {
            exchange.bid.onlinePayment ? 
                <OnlineTransactionDetail 
                    exchange={exchange} 
                    onFlagged={onFlagged} 
                    onRequestRefund={onRequestRefund}
                    onApproveEarlyPayout={onApproveEarlyPayout} 
                    data={onlineTransDetail} 
                /> : 
                <BidDetailViewPanel 
                    bidderIsReceiver={bidderIsReceiver} 
                    data={exchange.bid} 
                    isReview={true} 
                    hideRefundPolicy={exchange.targetType === 'offer'}
                />
        }

    </div>

export default ExchangeHeader;
