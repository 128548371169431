import config from './../config';

const ALWAYS_SHOW_HEIGHT = 10;
const SCROLL_ACTION_AMT = 50;

class MenuScrollService 
{
    // returns debouncing onScroll method
    static getOnScroll(onScroll)
    {
        return () => {
            var menuScrollServiceTimeoutId = null;

            /* istanbul ignore next */
            if(menuScrollServiceTimeoutId)
            {
                clearTimeout(menuScrollServiceTimeoutId);
                menuScrollServiceTimeoutId = null;
            }

            menuScrollServiceTimeoutId = setTimeout(
                onScroll,
                config.interval.menuScroll
            );

        };
    }

    static isMenuVisible(
        currentScrollPos, 
        lastScrollPos,
        showMenu
    )
    {
        const positionChange = lastScrollPos - currentScrollPos;
        const show = (showMenu && positionChange > 0 ) || 
        (
            (
                (lastScrollPos > currentScrollPos) && 
                (positionChange > SCROLL_ACTION_AMT)
            ) || 
            currentScrollPos < ALWAYS_SHOW_HEIGHT
        );
        return show;
    }

    /* istanbul ignore next */
    static buildHandleScroll({
        showMenu,
        setShowMenu,
        lastScrollPos,
        setLastScrollPos,
        hideOnScroll = true,
        onHidingMenu = () => {}
    })
    {
        return () => {
            if(hideOnScroll)
            {
                const currentScrollPos = window.pageYOffset;
                const show = this.isMenuVisible(currentScrollPos, lastScrollPos, showMenu);
                setShowMenu(show);
                setLastScrollPos(currentScrollPos);
                if(!show)
                {
                    onHidingMenu();
                }
            }
        };
    }

    // used like:
    /*
        const [ lastScrollPos, setLastScrollPos ] = useState(window.pageYOffset);
        const [ showMenu, setShowMenu ] = useState(true);
        const scrollEffect = MenuScrollService.buildOnScroll({
            showMenu,
            setShowMenu,
            lastScrollPos,
            setLastScrollPos,
            hideOnScroll // default true
            onHidingMenu => logic to trigger on menu hides
        });
        useEffect(scrollEffect);
    */
    static buildOnScroll(scrollConfig)
    {
        const onScrollMethod = this.buildHandleScroll(scrollConfig)
        const onScroll = this.getOnScroll(onScrollMethod);
        return () => {
            window.addEventListener('scroll', onScroll);
            return () => {
                /* istanbul ignore next */
                window.removeEventListener('scroll', onScroll);
            };
        };
    }
}

export default MenuScrollService;
