import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const FeeCopy = () => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme)  
            }}>
                Fees
            </h2>
            <div style={bodyStyle}>
                <p>
                    Bears.Exchange charges fees for online payment processing.  
                    <br />
                    <strong>
                        Reduced fees are offered to &nbsp;
                        <NavLink style={{ ...theme.getGeneralTextLinkStyle(theme) }} to="/subscription">
                            subscribers.
                        </NavLink> 
                    </strong>
                    <br />
                    Cash, USPS postal payments, trade, and free exchange options are available &nbsp;
                    <strong>
                        without
                    </strong> 
                    &nbsp; fees.
                </p>
            </div>
        </div>
    );
};

export default FeeCopy;
