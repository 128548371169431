import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ManageLocationsPanel from './../ui/panel/manage-locations';
import ProfileMenu from './../ui/menu/profile';

const ManageLocationsPage = () => 
    <AuthPageTemplate
        childMenu={ <ProfileMenu /> }
    >
        <ManageLocationsPanel />
    </AuthPageTemplate>

export default ManageLocationsPage;
