import React from 'react';
import noConnection from '../../img/no-connection.svg';
import theme from '../theme';

const HibernatingPanel = () => 
    <section style={{ 
        ...theme.getGeneralTextStyle(theme),
    }}>
        <div style={{ 
            ...theme.getDisconnectedIconStyle(theme, noConnection),
        }}>
        </div>
        <h2>
            Bears.Exchange is Currently Hibernating
        </h2>
        <p>
            Please check back later when we scale back up services.
        </p>
    </section>

export default HibernatingPanel;
