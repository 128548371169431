import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import { UserWidgetPanel } from './../ui/panel/user-landing/view';
import theme from './../ui/theme';

const NewsPage = () => {
    const getWidgetPageWrapperStyle = () => theme.getWidgetPageWrapperStyle(theme);
    const [ widgetPageWrapperStyle, setWidgetPageWrapperStyle ] = useState( getWidgetPageWrapperStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setWidgetPageWrapperStyle( getWidgetPageWrapperStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate 
            childMenu={ 
                <ProfileMenu />
        }>
            <div style={widgetPageWrapperStyle}>
                <UserWidgetPanel type="news" />
            </div>
        </AuthPageTemplate>
    );
};

export default NewsPage;
