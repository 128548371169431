import React from 'react';
import theme from './../../../theme';
import { getOutOfNetworkCarrierName } from './../../exchange-detail/deliveries-panel';
import CurrencyService from './../../../../service/currency';

const OutOfNetworkQuotes = ({
    delivery 
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <h3>
            Stay competitive 
        </h3>
        <p>
            Review the out of network quotes for this delivery.
        </p>
        <ul>
            {
                delivery.quoteList.map((quote, index) => 
                    <li key={index}>
                        {`${getOutOfNetworkCarrierName(quote.carrierType)}  ${CurrencyService.getDisplayPrice('usd', quote.cost)}`}
                    </li>
                )
            }
        </ul>
        <p>
            Increase your profits by fulfilling more than one delivery on a single trip.
        </p>
    </div>

export default OutOfNetworkQuotes;
