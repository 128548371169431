import DetailPanel from './../core/detail';
import CarrierGroupDeliveryPlanService from './../../../service/carrier-group-delivery-plan';
import CarrierGroupPlanDeliveryEditHistoryViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _CarrierGroupPlanDeliveryEditHistoryPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.listEditHistory({
            request: this.getLoadDataRequest(),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest()
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: this.props.deliveryId
        };
    }
    
    componentDidMount()
    {
        this.loadData();
    }

    getLoadedDataDetailUi()
    {
        return <CarrierGroupPlanDeliveryEditHistoryViewPanel 
            data={this.state.data} 
        />
    }
}

const CarrierGroupPlanDeliveryEditHistoryPanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierGroupPlanDeliveryEditHistoryPanel);
export default CarrierGroupPlanDeliveryEditHistoryPanel;
