import React from 'react';
import ValidateService from '../../../../service/validate';
import LocationSelectorPanel from '../../../panel/location-selector';
import LocationMapPanel from '../../../panel/location-map';
import LocationLimitSelectorPanel from '../../../panel/location-limit-selector';
import { FORM_FIELD, AbstractPrompt } from '../../core/abstract';
import RestrictViewTypeEnum from '../enum/restrict-view-type';
import ObtainTypeEnum from '../enum/obtain-type';
import Dialog from '@mui/material/Dialog';
import theme from '../../../theme';
import CloseIcon from '../../../../img/close.svg';
import AddOneIcon from '../../../../img/addOne.svg';
import { ObtainMethodCopy } from '../../../panel/core/bids';
import toast from 'react-hot-toast';
import ListItemContainerView from './view';

class ObtainTypeDetailViewPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, ObtainTypeDetailViewPrompt.getValidators(parms));

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getListUi = this.getListUi.bind(this);
        this.getListItemUi = this.getListItemUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.triggerOnAddObtainTypeDetail = () => { throw new Error('Missing onAddObtainTypeDetail'); };
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.getEmptyListUi = this.getEmptyListUi.bind(this);
        this.getDialogUi = this.getDialogUi.bind(this);
        this.getShippingCoDialogUi = this.getShippingCoDialogUi.bind(this);
        this.getPickUpDialogUi = this.getPickUpDialogUi.bind(this);
        this.getHandToHandDialogUi = this.getHandToHandDialogUi.bind(this);
        this.getRemoteDialogUi = this.getRemoteDialogUi.bind(this);
        this.getOnSiteDialogUi = this.getOnSiteDialogUi.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationRemoveSelected = this.onLocationRemoveSelected.bind(this);
        this.getFormFieldsUi = this.getFormFieldsUi.bind(this);
        this.getLocationLimitAreaRequest = this.getLocationLimitAreaRequest.bind(this);
        this.validateState = this.validateState.bind(this);
        this.setSelectedLimit = this.setSelectedLimit.bind(this);
        this.getCustomItemUi = this.getCustomItemUi.bind(this);
        this.getShippingItemUi = this.getShippingItemUi.bind(this);
        this.getGeneralItemUi = this.getGeneralItemUi.bind(this);
        this.getPickUpItemUi = this.getPickUpItemUi.bind(this);
        this.getHandToHandItemUi = this.getHandToHandItemUi.bind(this);
        this.getOnSiteItemUi = this.getOnSiteItemUi.bind(this);

        this.state = this.getDefaultState();
    }

    /* istanbul ignore next */
    static getValidators(parms)
    {
        return {
            type: {
                isValid: ObtainTypeEnum.isValid
            },
            visibilityType: {
                isValid: RestrictViewTypeEnum.isValid
            },
/*            
            visibilityExpireDate: {
                isValid: ValidateService.textLine
            },
*/           
            weightPounds: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => [
                    ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                    ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND
                ].includes(thisState.type.value) && parms.contentType === 'offer'
            },
            weightOunces: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => [
                    ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                    ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND
                ].includes(thisState.type.value) && parms.contentType === 'offer'
            },
            locationLimitArea: {
                isValid: ValidateService.textLine,
                isVisible: (thisState) => ! [
                    ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                    ObtainTypeEnum.OBTAIN_TYPE_PICK_UP,
                    ObtainTypeEnum.OBTAIN_TYPE_ON_SITE,
                    ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND
                ].includes(thisState.type.value)
            },
            locationLimitDistance: {
                isValid: ValidateService.textLine,
                isVisible: (thisState) => ! [
                    ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                    ObtainTypeEnum.OBTAIN_TYPE_PICK_UP,
                    ObtainTypeEnum.OBTAIN_TYPE_ON_SITE,
                    ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND
                ].includes(thisState.type.value)
            },
        };
    }

    /* istanbul ignore next */
    validateState(updateState)
    {
        let baseResponse = super.validateState(updateState);
        if(!updateState.selectedLocation && [
            ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
            ObtainTypeEnum.OBTAIN_TYPE_PICK_UP,
            ObtainTypeEnum.OBTAIN_TYPE_ON_SITE,
            ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND
        ].includes(updateState.type.value))
        {
            baseResponse.isValid = false;
        }
        
        return baseResponse;
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    getFieldsModel()
    {
        return {
            type: {
                name: 'Delivery Method',
                error: 'Specify a delivery method',
                value: ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                valueList: ObtainTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            visibilityType: {
                name: 'Delivery Method Visibility',
                error: 'Specify the offer visibility',
                value: RestrictViewTypeEnum.RESTRICT_VIEW_TYPE_PUBLIC,
                valueList: RestrictViewTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            weightPounds: {
                name: 'Shipping Weight Pounds',
                error: 'Specify the pounds in shipping weight',
                value: '',
                visible: this.props.contentType === 'offer' ? true : false,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            weightOunces: {
                name: 'Shipping Weight Ounces',
                error: 'Specify the ounces in shipping weight',
                value: '',
                visible: this.props.contentType === 'offer' ? true : false,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
/*            
            visibilityExpireDate: {
                name: 'Offer Expiration Date',
                error: 'Specify the date this offer is no longer available',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
*/            
            locationLimitArea: {
                name: 'Virtual Meeting Service',
                error: 'Specify the way you will interact where you are offering from',
                value: '',
                visible: false,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            locationLimitDistance: {
                name: 'Availability',
                error: 'Specify your availability for meetings',
                value: '',
                visible: false,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            isValid: false,
            dialogOpen: false,
            selectedLocation: null,
            selectedLimit: '20',
        };
    }

    /* istanbul ignore next */
    setSelectedLimit(limit)
    {
        let nextState = this.state;
        nextState.selectedLimit = limit;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onLocationSelected(location)
    {
        let nextState = this.state;
        nextState.selectedLocation = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onLocationRemoveSelected()
    {
        let nextState = this.state;
        nextState.selectedLocation = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    openDialog()
    {
        const nextState = {
            ...this.state,
            dialogOpen: true,
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    closeDialog()
    {
        const nextState = {
            ...this.state,
            dialogOpen: false,
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCloseDialog(event)
    {
        this.closeDialog();
    }

    componentDidMount()
    {
        /* istanbul ignore next */
        this.triggerOnAddObtainTypeDetail = () => {
            const serverRequest = this.getServerRequest();
            this.props.onAddObtainTypeDetail(serverRequest);   
            const nextState = {
                ...this.state,
                isValid: true
            };
            this.setState(nextState);
        };
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        this.triggerOnAddObtainTypeDetail();
        let nextState = this.getBaseStateOnResponse({ message: '', success: false });
        nextState = this.clearFields(nextState);
        nextState.dialogOpen = false;
        this.setState(nextState);
        toast('Delivery Option Added');
    }

    /* istanbul ignore next */
    getLocationLimitAreaRequest()
    {
        return [
            ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
            ObtainTypeEnum.OBTAIN_TYPE_PICK_UP,
            ObtainTypeEnum.OBTAIN_TYPE_ON_SITE,
            ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND
        ].includes(this.state.type.value) ? JSON.stringify(this.state.selectedLocation) : 
            this.state.locationLimitArea.value;
    }

    /* istanbul ignore next */
    getLocationLimitDistanceRequest()
    {
        if(this.props.contentType === 'offer')
        {
            return [
                ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                ObtainTypeEnum.OBTAIN_TYPE_PICK_UP,
            ].includes(this.state.type.value) ? '' : (
                [
                    ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND, 
                    ObtainTypeEnum.OBTAIN_TYPE_ON_SITE
                ].includes(this.state.type.value) ? 
                    this.state.selectedLimit : 
                        this.state.locationLimitDistance.value
            );
        }
        else 
        {
            return [
                ObtainTypeEnum.OBTAIN_TYPE_ON_SITE,
                ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND, 
            ].includes(this.state.type.value) ? '' : (
                [
                    ObtainTypeEnum.OBTAIN_TYPE_PICK_UP,
                ].includes(this.state.type.value) ? 
                    this.state.selectedLimit : 
                        this.state.locationLimitDistance.value
            );
        }
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            type: this.state.type.value,
            visibility: {
                type: this.state.visibilityType.value,
                expireDate: '',
            },
            locationLimitArea: this.getLocationLimitAreaRequest(),
            locationLimitDistance: this.getLocationLimitDistanceRequest(),
            weight: [
                ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND
            ].includes(this.state.type.value) && this.props.contentType === 'offer' ? {
                pounds: this.state.weightPounds.value,
                ounces: this.state.weightOunces.value
            } : {},
        };
    }
    
    /* istanbul ignore next */
    getShippingItemUi(obtainTypeDetail)
    {
        try
        {
            const location = JSON.parse(obtainTypeDetail.locationLimitArea);
            return (
                <div>
                    <p>
                        { `${this.props.contentType === 'offer' ? 'from' : 'to'} ${location.regionalAddress}` }
                    </p>
    
                </div>
            );
        }
        catch(err)
        {
            return this.getGeneralItemUi(obtainTypeDetail);
        }
    }

    /* istanbul ignore next */
    getHandToHandItemUi(obtainTypeDetail)
    {
        try
        {
            const location = JSON.parse(obtainTypeDetail.locationLimitArea);
            return (
                <div>
                    <p>
                        { 
                            this.props.contentType === 'offer' && obtainTypeDetail.locationLimitDistance ?
                                `within ${obtainTypeDetail.locationLimitDistance} miles of ${location.regionalAddress}` : 
                                    `${this.props.contentType === 'offer' ? 'from' : 'to'} ${location.regionalAddress}`
                        }
                    </p>
    
                    {
                        this.props.contentType === 'offer' && obtainTypeDetail.locationLimitDistance && 
                            <LocationMapPanel 
                                selectedLocation={location}
                                limit={obtainTypeDetail.locationLimitDistance}
                                isRegion={true}
                                forceAnon={true}
                            />
                    }

                </div>
            );
        }
        catch(err)
        {
            return this.getGeneralItemUi(obtainTypeDetail);
        }
    }

    /* istanbul ignore next */
    getPickUpItemUi(obtainTypeDetail)
    {
        try
        {
            const location = JSON.parse(obtainTypeDetail.locationLimitArea);
            return this.props.contentType === 'offer' ?
                <div>
                    <p>
                        { `in ${location.regionalAddress}` }
                    </p>
    
                </div> : 
                <div>
                    { 
                        obtainTypeDetail.locationLimitDistance ?
                            `within ${obtainTypeDetail.locationLimitDistance} miles of ${location.regionalAddress}` : 
                                `from ${location.regionalAddress}`
                    }

                    {
                        obtainTypeDetail.locationLimitDistance && 
                            <LocationMapPanel 
                                selectedLocation={location}
                                limit={obtainTypeDetail.locationLimitDistance}
                                isRegion={true}
                                forceAnon={true}
                            />
                    }

                </div>
            
        }
        catch(err)
        {
            return this.getGeneralItemUi(obtainTypeDetail);
        }
    }

    /* istanbul ignore next */
    getOnSiteItemUi(obtainTypeDetail)
    {
        try
        {
            const location = JSON.parse(obtainTypeDetail.locationLimitArea);
            return this.props.contentType === 'offer' ? 
                <div>
                    <p>
                        { 
                            obtainTypeDetail.locationLimitDistance ?
                                `within ${obtainTypeDetail.locationLimitDistance} miles of ${location.regionalAddress}` : 
                                    `from ${location.regionalAddress}`
                        }
                    </p>
    

                    {
                        obtainTypeDetail.locationLimitDistance && 
                            <LocationMapPanel 
                                selectedLocation={location}
                                limit={obtainTypeDetail.locationLimitDistance}
                                isRegion={true}
                                forceAnon={true}
                            />
                    }
                </div> : 
                <div>
                    <p>
                        { `in ${location.regionalAddress}` }
                    </p>
    
                </div>
            
        }
        catch(err)
        {
            return this.getGeneralItemUi(obtainTypeDetail);
        }
    }

    /* istanbul ignore next */
    getGeneralItemUi(obtainTypeDetail)
    {
        return (
            <div style={{
                ...theme.getObtainTypeLocationStyle(theme),
                ...theme.getMediumTextStyle(theme),
            }}>

                {
                    obtainTypeDetail.locationLimitArea === '' ? '' : 
                        <div>
                            { obtainTypeDetail.locationLimitArea }
                        </div>
                }

                {
                    obtainTypeDetail.locationLimitDistance === '' ? '' : 
                        <div>
                            { obtainTypeDetail.locationLimitDistance }
                        </div>
                }

            </div>
        );
    }

    /* istanbul ignore next */
    getCustomItemUi(obtainTypeDetail)
    {
        switch(obtainTypeDetail.type)
        {
            case ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS:
                return this.getShippingItemUi(obtainTypeDetail);

            case ObtainTypeEnum.OBTAIN_TYPE_PICK_UP:
                return this.getPickUpItemUi(obtainTypeDetail);

            case ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND:
                return this.getHandToHandItemUi(obtainTypeDetail);

            case ObtainTypeEnum.OBTAIN_TYPE_ON_SITE:
                return this.getOnSiteItemUi(obtainTypeDetail);

            default:
                return this.getGeneralItemUi(obtainTypeDetail);
        }
    }

    /* istanbul ignore next */
    getListItemUi(obtainTypeDetail, index)
    {
        const isPublic = obtainTypeDetail.visibility.type === 'public';
        return (
            <div>
                <theme.getIconButtonUi 
                    theme={theme}
                    icon={CloseIcon}
                    customContainerStyle={{
                        ...theme.getObtainTypeCloseIconStyle(theme),
                    }}
                    onClick={event => { this.props.onRemoveObtainTypeDetail(index) }}
                />

                <div style={{
                    ...theme.getObtainTypeObtainMethodStyle(theme),
                    ...theme.getMediumTextStyle(theme),
                }}>
                    <div>
                        { 
                            ObtainMethodCopy(
                                { 
                                    bid: {
                                        obtainMethod: obtainTypeDetail.type 
                                    },
                                    bidderIsReceiver: this.props.bidderIsReceiver,
                                    posterName: this.props.posterName ?? 'poster',
                                    bidderName: this.props.bidderName ?? 'bidder'
                                }
                            )
                        }
                    </div>
                    { isPublic ? '' : ' Private ( Friends Only )' }
                </div>

                { this.getCustomItemUi(obtainTypeDetail) }
                
                
               
            </div>
        );
    }

    getEmptyListUi()
    {
        return (
            <div style={{
                ...theme.getAbstractCenterFlexContainerStyle(theme)  
            }}>
                <p>
                    Nothing Added.
                </p>
            </div>
        );
    }

    /* istanbul ignore next */
    getListUi()
    {
        return this.props.obtainTypeDetailList.length < 1 ? this.getEmptyListUi() : this.props.obtainTypeDetailList.map((item, index) => 
            <ListItemContainerView key={index}>
                { this.getListItemUi(item, index) }
            </ListItemContainerView>
        );
    }

    /* istanbul ignore next */
    getRemoteDialogUi(buttonText)
    {
        return (
            <div style={{
                ...theme.getObtainTypeDialogStyle(theme),
            }}>
                <h3>
                    Add a Remote Fulfillment Option
                </h3>

                <p style={{
                    ...theme.getWarningTextStyle(theme),
                }}>
                    { 
                        ObtainMethodCopy(
                            { 
                                bid: {
                                    obtainMethod: this.state.type.value
                                },
                                bidderIsReceiver: this.props.bidderIsReceiver,
                                posterName: this.props.posterName ?? 'poster',
                                bidderName: this.props.bidderName ?? 'bidder'
                            }
                        )
                    }
                </p>
                

                { super.getPromptUi(buttonText) }


            </div>
        );
    }

    /* istanbul ignore next */
    getHandToHandDialogUi(buttonText)
    {
        return (
            <div style={{
                ...theme.getObtainTypeDialogStyle(theme),
            }}>
                <h3>
                    Add a Personally Delivered Option
                </h3>

                <p style={{
                    ...theme.getWarningTextStyle(theme),
                }}>
                    { 
                        ObtainMethodCopy(
                            { 
                                bid: {
                                    obtainMethod: this.state.type.value
                                },
                                bidderIsReceiver: this.props.bidderIsReceiver,
                                posterName: this.props.posterName ?? 'poster',
                                bidderName: this.props.bidderName ?? 'bidder'
                            }
                        )
                    }
                </p>

                { super.getPromptUi(buttonText) }

                <p style={{
                    ...theme.getSmallTextStyle(theme),
                }}>    
                    
                    We recommend avoiding unsafe situations with strangers.
                    <br />
                    Consider limiting hand delivery to friends or using a reputable shipping company.
                </p>

            </div>
        );
    }

    /* istanbul ignore next */
    getPickUpDialogUi(buttonText)
    {
        return (
            <div style={{
                ...theme.getObtainTypeDialogStyle(theme),
            }}>
                <h3>
                    Add a Pick Up Option
                </h3>

                <p style={{
                    ...theme.getWarningTextStyle(theme),
                }}>
                    { 
                        ObtainMethodCopy(
                            { 
                                bid: {
                                    obtainMethod: this.state.type.value
                                },
                                bidderIsReceiver: this.props.bidderIsReceiver,
                                posterName: this.props.posterName ?? 'poster',
                                bidderName: this.props.bidderName ?? 'bidder'
                            }
                        )
                    }
                </p>

                { super.getPromptUi(buttonText) }

                <p style={{
                    ...theme.getSmallTextStyle(theme),
                }}>    
                    We recommend avoiding unsafe situations with strangers.
                    <br />
                    Consider limiting pick up to friends or using a reputable shipping company.
                </p>

            </div>
        );
    }

    /* istanbul ignore next */
    getShippingCoDialogUi(buttonText)
    {
        return (
            <div style={{
                ...theme.getObtainTypeDialogStyle(theme),
            }}>
                <h3>
                    Add a Shipping Company Delivery Option
                </h3>

                <p style={{
                    ...theme.getWarningTextStyle(theme),
                }}>
                    { 
                        ObtainMethodCopy(
                            { 
                                bid: {
                                    obtainMethod: this.state.type.value
                                },
                                bidderIsReceiver: this.props.bidderIsReceiver,
                                posterName: this.props.posterName ?? 'poster',
                                bidderName: this.props.bidderName ?? 'bidder'
                            }
                        )
                    }
                </p>

                { super.getPromptUi(buttonText) }

            </div>
        );
    }

    /* istanbul ignore next */
    getOnSiteDialogUi(buttonText)
    {
        return (
            <div style={{
                ...theme.getObtainTypeDialogStyle(theme),
            }}>
                <h3>
                    Add an On Site Fulfillment Option
                </h3>

                <p style={{
                    ...theme.getWarningTextStyle(theme),
                }}>
                    { 
                        ObtainMethodCopy(
                            { 
                                bid: {
                                    obtainMethod: this.state.type.value
                                },
                                bidderIsReceiver: this.props.bidderIsReceiver,
                                posterName: this.props.posterName ?? 'poster',
                                bidderName: this.props.bidderName ?? 'bidder'
                            }
                        )
                    }
                </p>
                

                { super.getPromptUi(buttonText) }

                <p style={{
                    ...theme.getSmallTextStyle(theme),
                }}>    
                    We recommend avoiding unsafe situations with strangers.
                </p>

            </div>
        );
    }

    /* istanbul ignore next */
    getFormFieldsUi()
    {
        let output = [];
        for(const aField in this.validator)
        {
            try
            {
                if(this.state[aField].visible)
                {

                    output.push(
                        <section key={aField}>
                            { this.getFormFieldUi(aField) }
                        </section>
                    );
                }

                if(aField === 'type')
                {
                    if(
                        (
                            this.props.contentType === 'offer' && 
                            [
                                ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                                ObtainTypeEnum.OBTAIN_TYPE_PICK_UP,
                            ].includes(this.state.type.value)
                        ) || 
                        (
                            this.props.contentType === 'seek' && 
                            [
                                ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS,
                                ObtainTypeEnum.OBTAIN_TYPE_ON_SITE,
                                ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND,
                            ].includes(this.state.type.value)
                        )
                    )
                    {
                        let title = 'Select a Location';
                        switch(this.state.type.value)
                        {
                            case ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS: // offer + seek
                                title = this.props.contentType === 'offer' ?  'Where do you intend to ship from?' : 'Where do you intend to receive delivery?';
                                break;

                            case ObtainTypeEnum.OBTAIN_TYPE_PICK_UP: 
                                title = 'Where is the pick up location?'; // offer
                                break;

                            case ObtainTypeEnum.OBTAIN_TYPE_ON_SITE:
                                title = 'Where is the site?'; // seek
                                break;

                            case ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND:
                                title = 'Where do you intend to receive delivery?'; // seek 
                                break;

                            default:
                                title = 'Select a Location';
                                break;
                        }
                        
                        output.push(
                            <LocationSelectorPanel
                                key={`locationSelector${output.length}`}
                                promptTitle={title}
                                selectedLocation={this.state.selectedLocation}
                                onSelected={this.onLocationSelected}
                                onRemoveSelected={this.onLocationRemoveSelected}
                                includeGps={true}
                            />
                        )
                    }

                    if(
                        (
                            this.props.contentType === 'offer' && 
                            [
                                ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND,
                                ObtainTypeEnum.OBTAIN_TYPE_ON_SITE
                            ].includes(this.state.type.value)
                        ) ||
                        (
                            this.props.contentType === 'seek' && 
                            [
                                ObtainTypeEnum.OBTAIN_TYPE_PICK_UP
                            ].includes(this.state.type.value)
                        )
                    )
                    {
                        let promptTitle = "How far will you travel?";
                        switch(this.state.type.value)
                        {
                            case ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND:
                                promptTitle = "How far will you travel to deliver?"; // offer
                                break;

                            case ObtainTypeEnum.OBTAIN_TYPE_ON_SITE:
                                promptTitle = "How far will you travel to a site?"; // offer
                                break;

                            case ObtainTypeEnum.OBTAIN_TYPE_PICK_UP:
                                promptTitle = "How far will you travel to pick up?"; // seek
                                break;

                            default:
                                promptTitle = "How far will you travel?";
                                break;
                        }
                        
                        
                        output.push(
                            <LocationLimitSelectorPanel
                                key={`locationLimitSelector${output.length}`}
                                promptTitle={promptTitle}
                                locationPromptTitle="Where are you traveling from?"
                                selectedLocation={this.state.selectedLocation}
                                onSelected={this.onLocationSelected}
                                onRemoveSelected={this.onLocationRemoveSelected}
                                limit={this.state.selectedLimit}
                                setLimit={this.setSelectedLimit}
                            />
                        )
                    }
                }
            }
            catch(err)
            {
                throw new Error(`Unable to read ${aField}`);
            }
        }
        return output;
    }

    /* istanbul ignore next */
    getDialogUi(buttonText)
    {
        switch(this.state.type.value)
        {
            case ObtainTypeEnum.OBTAIN_TYPE_SHIP_TO_ADDRESS:
                return this.getShippingCoDialogUi(buttonText);

            case ObtainTypeEnum.OBTAIN_TYPE_PICK_UP:
                return this.getPickUpDialogUi(buttonText);

            case ObtainTypeEnum.OBTAIN_TYPE_HAND_TO_HAND:
                return this.getHandToHandDialogUi(buttonText);

            case ObtainTypeEnum.OBTAIN_TYPE_REMOTE:
                return this.getRemoteDialogUi(buttonText);

            case ObtainTypeEnum.OBTAIN_TYPE_ON_SITE:
                return this.getOnSiteDialogUi(buttonText);

            default:
                return this.getShippingCoDialogUi(buttonText);
        }
    }

    getPromptUi(buttonText = 'Add')
    {
        return (
            <section>
               
                <h2>
                    Add delivery options.  
                </h2>
                
                <p>
                    Define any delivery options such as a shipping company or hand delivery.
                </p>
                
                <div style={{
                ...theme.getAttributeFlexContainerStyle(theme),
                }}>

                    <theme.getIconButtonUi
                        theme={theme}
                        icon={AddOneIcon}
                        onClick={
                            /* istanbul ignore next */    
                            event => { this.openDialog() }
                        }
                    />          
                    
                </div>

                <Dialog
                    onClose={this.onCloseDialog}
                    open={this.state.dialogOpen}
                >
                    { this.getDialogUi(buttonText) }
                </Dialog>
                
                
                <div style={{
                    ...theme.getAttributeListStyle(theme),
                }}>
                    { this.getListUi() }
                </div>
                
            </section>
        );
    }
}

export default ObtainTypeDetailViewPrompt;
