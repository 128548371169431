import {
    ONBOARDING_RENDER_TYPE_GENERAL,
    ONBOARDING_RENTER_TYPE_VIDEO
} from './render-types';
import config from '../../config';

export const offerDetailMapList = [
    {
        moreTitle: 'Learn more about offers',
        id: 'offers-detail',
        renderType: ONBOARDING_RENDER_TYPE_GENERAL,
        frameParms: {
            title: 'More about Offers',
            details: 'More details about offers here'
        },
        moreList: []
    },
    {
        id: 'offers-detail-more',
        renderType: ONBOARDING_RENDER_TYPE_GENERAL,
        frameParms: {
            title: 'Even More about Offers',
            details: 'Even More details about offers here'
        },
        moreList: []
    }
];

const map = [
    {
        id: 'video-tour',
        renderType: ONBOARDING_RENTER_TYPE_VIDEO,
        frameParms: {
            desktopUrl: `${config.url.videoDir}desktop-welcome-tour.mp4`,
            mobileUrl: `${config.url.videoDir}mobile-welcome-tour.mp4`
        }
    }
];

export default map;
