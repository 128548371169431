import React from 'react';
import AuthPageTemplate from './template/auth-page';
import MyReportedIssuesPanel from '../ui/panel/my-reported-issues';
import ProfileMenu from './../ui/menu/profile';
import { NavLink } from 'react-router-dom';
import theme from '../ui/theme';

const MyReportedIssuesPage = () => {
    return (
        <AuthPageTemplate
            childMenu={
            <ProfileMenu />
        }>
            <div style={{ 
                ...theme.getGeneralBottomSpacerStyle(theme),
                ...theme.getGeneralTextStyle(theme) 
            }}>
                <MyReportedIssuesPanel />
                <NavLink
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to={`/profile/report/issue`}
                >
                    Report an Issue
                </NavLink>
            </div>
        </AuthPageTemplate>
    );
};

export default MyReportedIssuesPage;
