import React from 'react';
import AuthPageTemplate from './template/auth-page';
import AddSeekPrompt from './../ui/prompt/add-seek';
import PostsMenu from './../ui/menu/posts';

const AddSeekPage = () => {
    return (
        <AuthPageTemplate childMenu={ <PostsMenu /> }>
            <AddSeekPrompt />
        </AuthPageTemplate>
    );
};

export default AddSeekPage;
