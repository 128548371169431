import WidgetService from './../../../../service/widget';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';

class _AddAppNewsPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _AddAppNewsPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();

    }

    static getValidators()
    {
        return {
            title: {
                isValid: ValidateService.valueRequired
            },
            details: {
                isValid: ValidateService.valueRequired
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            title: {
                name: 'Title*',
                error: 'Provide a story title',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT,
            },
            details: {
                name: 'Details*',
                error: 'Provide story details',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA,
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    getServerRequest()
    {
        return {
            title: this.state.title.value,
            details: this.state.details.value,
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        WidgetService.publishNews({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getConfirmUi(confirmMessage = 'Story Published')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Publish')
    {
        return super.getSubmitButtonUi(buttonText);
    }


}

const AddAppNewsPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddAppNewsPrompt);

export default AddAppNewsPrompt;
