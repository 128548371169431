export const FLAGGED_CONTENT_TYPE_BID = 'bid';
export const FLAGGED_CONTENT_TYPE_OFFER = 'offer';
export const FLAGGED_CONTENT_TYPE_SEEK = 'seek';
export const FLAGGED_CONTENT_TYPE_CHAT = 'chat';
export const FLAGGED_CONTENT_TYPE_ONLINE_PAY = 'online';
const validValues = [
    FLAGGED_CONTENT_TYPE_BID,
    FLAGGED_CONTENT_TYPE_OFFER,
    FLAGGED_CONTENT_TYPE_SEEK,
    FLAGGED_CONTENT_TYPE_CHAT,
    FLAGGED_CONTENT_TYPE_ONLINE_PAY
];

const getDropDownValues = () => [
    {
        name: 'Bid',
        value: FLAGGED_CONTENT_TYPE_BID,
    },
    {
        name: 'Offer',
        value: FLAGGED_CONTENT_TYPE_OFFER
    },
    {
        name: 'Seek',
        value: FLAGGED_CONTENT_TYPE_SEEK
    },
    {
        name: 'Chat',
        value: FLAGGED_CONTENT_TYPE_CHAT
    },
    {
        name: 'Online Payment',
        value: FLAGGED_CONTENT_TYPE_ONLINE_PAY
    }
];

const isValid = value => !! validValues.includes(value);

const FlaggedContentTypeEnum = {
    FLAGGED_CONTENT_TYPE_BID,
    FLAGGED_CONTENT_TYPE_OFFER,
    FLAGGED_CONTENT_TYPE_SEEK,
    FLAGGED_CONTENT_TYPE_CHAT,
    FLAGGED_CONTENT_TYPE_ONLINE_PAY,
    getDropDownValues,
    isValid,
};

export default FlaggedContentTypeEnum;
