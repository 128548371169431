import React from 'react';
import theme from '../../theme';
import CompanyHeader from './company-header';
import MemberHeader from './member-header';
import SetupCreatorMember from './setup-creator-member';
import CarrierGroupNavMenu from './../../menu/carrier-group/carrier-group-nav-menu';

// istanbul ignore next
const Details = ({ 
    carrierGroup, 
    carrierMember,
    onMemberAdded,
    locationCount,
    pendingJobCount,
    pendingBidCount,
    updateAcceptDeliveries,
    onContactInfoUpdated,
    updateAllowJoins
}) => 
    <div style={{
        ...theme.getCarrierGroupHeaderStyle(theme)
    }}>
        <CompanyHeader 
            carrierGroup={carrierGroup} 
            carrierMember={carrierMember}
            pendingBidCount={pendingBidCount}
            locationCount={locationCount}
            pendingJobCount={pendingJobCount}
            updateAllowJoins={updateAllowJoins}
            updateAcceptDeliveries={updateAcceptDeliveries}
            onContactInfoUpdated={onContactInfoUpdated}
        />

        <MemberHeader 
            carrierMember={carrierMember} 
            groupCreatorUserId={carrierGroup.creatorUserId}
            onContactInfoUpdated={onContactInfoUpdated}
        />

        
    </div>


// istanbul ignore next
const CarrierGroupDetailsViewPanel = ({ 
    data,
    onMemberAdded,
    updateAllowJoins,
    onContactInfoUpdated,
    updateAcceptDeliveries
}) => {
    
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <CarrierGroupNavMenu />
            {
                data.carrierMember ? 
                <Details 
                    locationCount={data.locationCount}
                    carrierGroup={data.carrierGroup} 
                    pendingBidCount={data.pendingBidCount}
                    pendingJobCount={data.pendingJobCount}
                    carrierMember={data.carrierMember}
                    onMemberAdded={onMemberAdded}
                    updateAllowJoins={updateAllowJoins}
                    updateAcceptDeliveries={updateAcceptDeliveries}
                    onContactInfoUpdated={onContactInfoUpdated}
                /> : 
                <SetupCreatorMember 
                    carrierGroup={data.carrierGroup} 
                    onMemberAdded={onMemberAdded}
                /> 
            }
        </div>
    );
};
export default CarrierGroupDetailsViewPanel;