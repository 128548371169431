import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../theme';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../service/resize';

const shortcutList = [
    {
        title: 'My Locations',
        url: '/location/list',
        details: 'Manage your locations for shipping and receiving'
    },
    {
        title: 'My Fields',
        url: '/profile/att-sets',
        details: 'Manage custom sets of fields for usage in your posts'
    },
    {
        title: 'My Images',
        url: '/img/list',
        details: 'Manage images used in your posts'
    },
    {
        title: 'Email',
        url: '/profile/email',
        details: 'Manage account email'
    },
    {
        title: 'Settings',
        url: '/profile/settings',
        details: 'Manage account settings'
    },
    {
        title: 'Shop Profile',
        url: '/profile/shop',
        details: 'Manage your exchange Contact Details and Refund Policy'
    }
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const ExchangeShortcuts = () => {
    const getProfileStoreShortCutOuterContainerStyle = () => theme.getProfileStoreShortCutOuterContainerStyle(theme);
    const [ profileStoreShortCutOuterContainerStyle, setProfileStoreShortCutOuterContainerStyle ] = useState( getProfileStoreShortCutOuterContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setProfileStoreShortCutOuterContainerStyle( getProfileStoreShortCutOuterContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={profileStoreShortCutOuterContainerStyle}>
            {
                shortcutList.map((cut, index) => <Shortcut details={cut.details} title={cut.title} url={cut.url} key={index} />)
            }
        </div>
    );
};


const ProfileStorePanel = (parms) => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    const getBodyStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={containerStyle}>
                <h2>
                    Manage Account
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Manage your images, locations, fieldsets, and shop profile.
                </h4>
            </div>
            <div style={bodyStyle}>
                <div style={{
                    ...theme.getProfilePwdBodyFixStyle(theme),
                }}>
                    <div style={{
                        ...theme.getProfilePwdContainerStyle(theme),
                    }}>

                        <ExchangeShortcuts />

                    </div>
                </div>
            </div>
            
        </section>
    );
};

export default ProfileStorePanel;
