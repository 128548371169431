import React from 'react';
import MenuList from './core/menu-list';
import ActiveIcon from './../../img/active-exchange-white.svg';
import HistoryIcon from './../../img/exchange-history-white.svg';
import theme from './../theme';

const ExchangesMenu = () => <MenuList list={[
    {
        to: '/exchanges/active',
        title: 'Active Exchanges',
        customStyle: {
            ...theme.getMenuSubStyle(theme, ActiveIcon),
        },
    },
    {
        to: '/exchanges/all',
        title: 'All Exchanges',
        customStyle: {
            ...theme.getMenuSubStyle(theme, HistoryIcon),
        },
    }
]} />

export default ExchangesMenu;
