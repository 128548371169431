import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { 
    FLAGGED_CONTENT_TYPE_SEEK,
    FLAGGED_CONTENT_TYPE_OFFER,
    FLAGGED_CONTENT_TYPE_CHAT,
    FLAGGED_CONTENT_TYPE_ONLINE_PAY
} from './../../../prompt/core/enum/flagged-content-type';
import ExchangeDetailPanel from './../../exchange-detail';
import AdminOnlineTransactionDetailReview from './../../admin-online-transaction-detail-review';
import SeekDetailPanel from './../../seek-detail';
import OfferDetailPanel from './../../offer-detail';
import BidDetailPanel from './../../bid-detail';
import UiService from './../../../../service/ui';
import AdminReviewFlagPrompt from './../../../prompt/admin/admin-review-flag';
import theme from './../../../theme';

const ContentPanel = ({ flag }) => flag.flaggedContentType === FLAGGED_CONTENT_TYPE_SEEK ? <SeekDetailPanel seekId={flag.contentId} /> : (
    flag.flaggedContentType === FLAGGED_CONTENT_TYPE_OFFER ? <OfferDetailPanel offerId={flag.contentId} /> : (
        flag.flaggedContentType === FLAGGED_CONTENT_TYPE_CHAT ? <ExchangeDetailPanel exchangeId={flag.contentId} /> : (
            flag.flaggedContentType === FLAGGED_CONTENT_TYPE_ONLINE_PAY ? <AdminOnlineTransactionDetailReview payId={flag.contentId} /> :
                <BidDetailPanel bidId={flag.contentId} />
            )
        )
    );

const FlagDetails = ({ flag, onReviewed }) => 
    <section>
        <strong>
            { flag.flaggingReason }
        </strong>
        <div>
            { UiService.timeSince(flag.createdOn) }
        </div>
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/user/${flag.flaggedByUserId}`}
        >
            Review Flagger Profile
        </NavLink>
        <p>
            { flag.flaggingUserSummary }
        </p>
        <hr />
        <ContentPanel flag={flag} />
        <hr />
        <AdminReviewFlagPrompt flag={flag} onReviewed={onReviewed} />
    </section>

const FlagSummaryLine = ({ flag, setSelectedFlag }) => 
    <div 
        style={{ 
            ...theme.getAdminReviewFlagSummaryLineStyle(theme),
        }} 
        className="test-adminReviewFlagsSummaryLine"
        onClick={event => { setSelectedFlag(flag) }}
    >
        <strong>
            { flag.flaggingReason }
        </strong>
        <p>
            { flag.flaggingUserSummary }
        </p>
    </div>

const AdminReviewFlagsViewPanel = ({ flag, data, removeById }) => {
    const [selectedFlag, setSelectedFlag] = useState(null);
    /* istanbul ignore next */
    const onReviewed = event => { 
        removeById(selectedFlag.id);
        setSelectedFlag(null);
    }
    return (
        <div style={{
            ...theme.getAdminReviewFlagsViewPanelStyle(theme),
        }}>
            <h2>
                Pending Flags
            </h2>
            {
                selectedFlag === null ? 
                    <div>
                        { data.list.map((flag, index) => <FlagSummaryLine flag={flag} key={index} setSelectedFlag={setSelectedFlag} />) }
                    </div> : <FlagDetails flag={selectedFlag} onReviewed={onReviewed} /> 
            }

            {
                data.list.length > 0 ? '' : <p>No Pending Flags</p>
            }
        </div>
    );
};

export default AdminReviewFlagsViewPanel;
