import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import ChooseCarrierNetworkType from './choose-carrier-network-type';
import SetupOutOfNetworkDelivery from './setup-out-of-network-delivery';
import SetupInNetworkDelivery from './setup-in-network-delivery';
import theme from './../../theme';
import config from '../../../config';

const FreeScheduleSetupDelivery = ({ 
    clearView,
    delivery,
    onLoadDataResponse
}) => {
    const startNetworkTypeView = delivery.inNetworkCarriers.bidRequestedCarrierGroupIdList.length > 0 ? 'in' : null;
    const [ networkTypeView, setNetworkTypeView ] = useState(startNetworkTypeView);
    // istanbul ignore next
    return !config.features.allowCarrierGroups ? 
        <div>
            <button 
                className="test-exchangeDetailChooseCarrierNetworkTypeBackBtn"
                onClick={clearView}
            >
                Back
            </button>

            <SetupOutOfNetworkDelivery 
                delivery={delivery}
                onLoadDataResponse={onLoadDataResponse}
            /> 
        </div>
        : (
        networkTypeView === null ? 
            <ChooseCarrierNetworkType 
                clearView={clearView} 
                setNetworkTypeView={setNetworkTypeView}
            /> : 
                <div>  schedule-setup-delivery.js             |     100 |       50 |     100 |     100 | 17-32             

                    <button 
                        className="test-exchangeDetailScheduleSetupDeliveryBackBtn"
                        onClick={
                            // istanbul ignore next
                            event => { setNetworkTypeView(null) }
                        }
                    >
                        Back
                    </button>

                    {
                        /* istanbul ignore next */
                        networkTypeView === 'in' ? 
                            <SetupInNetworkDelivery 
                                delivery={delivery}
                            /> : 
                                <SetupOutOfNetworkDelivery 
                                    delivery={delivery}
                                    onLoadDataResponse={onLoadDataResponse}
                                />
                    }
                </div>
        )
};

/* istanbul ignore next */
const PaidScheduleSetupDelivery = ({ clearView }) => 
    <div>
        <button onClick={clearView}>
            Back
        </button>
        <p>
            Add your 
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to="/profile/shop"
            >
                delivery accounts
            </NavLink>
        </p>
    </div>

const ScheduleSetupDelivery = ({ 
    clearView,
    delivery,
    onLoadDataResponse
}) => {
    // expand into pay feature when delivery accounts area has been added to shop profile
    const isPaid = false;
    /* istanbul ignore next */
    return isPaid ?
        <PaidScheduleSetupDelivery 
            clearView={clearView} 
        /> :
            <FreeScheduleSetupDelivery 
                clearView={clearView} 
                delivery={delivery}
                onLoadDataResponse={onLoadDataResponse}
            />
};

export default ScheduleSetupDelivery;
