import CarrierMemberService from './../../../service/carrier-member';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import CarrierMemberRoleEnum from './../core/enum/carrier-member-role';
import SelectUserPrompt from './../select-user';

class _InviteCarrierMemberPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _InviteCarrierMemberPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDynamicFields = this.getDynamicFields.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.validateState = this.validateState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getSelectedRoles = this.getSelectedRoles.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.selectNewUser = this.selectNewUser.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        let validators = {};
        const roleList = CarrierMemberRoleEnum.getDropDownValues();
        roleList.forEach((role => {
            validators[role.value] = {
                isValid: ValidateService.anyValue
            };
        }));

        validators.internalNote = {
            isValid: ValidateService.valueRequired
        };

        return validators;
    }

    getFieldsModel()
    {
        return {
            internalNote: {
                name: '*Message',
                error: 'You must specify an invite message',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
            
        };
    }

    getDynamicFields()
    {
        const roleList = CarrierMemberRoleEnum.getDropDownValues();
        let dynamicFields = {};
        roleList.forEach((role => {
            dynamicFields[role.value] = {
                name: `${role.name} Role`,
                error: `Check to Grant ${role.name} Role`,
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            };
        }));
        
        return dynamicFields;
    }

    getSelectedRoles()
    {
        let roles = [];
        const roleList = CarrierMemberRoleEnum.getDropDownValues();
        roleList.forEach((role) => {
            if(this.state[role.value].value)
            {
                roles.push(role.value);
            }
        });
        return roles;
    }

    getServerRequest()
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            pendingMemberUserId: this.state.selectedUser.id,
            isInvite: true,
            internalNote: this.state.internalNote.value,
            roles: this.getSelectedRoles(),
        };
    }
    
    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        const dynamicFields = this.getDynamicFields();
        return {
            ...baseState,
            ...dynamicFields,
            ...fields,
            selectedUser: null,
        };
    }

    onUserSelected(user)
    {
        let nextState = this.state;
        nextState.selectedUser = user;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    selectNewUser()
    {
        let nextState = this.state;
        nextState.selectedUser = null;
        nextState.isValid = false;
        this.setState(nextState);
    }

    onSendToServer()
    {
        CarrierMemberService.inviteMember({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken, 
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);

        if(this.props.onInviteSent)
        {
            this.props.onInviteSent();
        }
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getConfirmUi(confirmMessage = 'Member Invited')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Invite')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    validateState(state)
    {
        let baseReturn = super.validateState(state);
        const oneRuleCopy = 'Select at least one role';
        const selectedRoles = this.getSelectedRoles();
        if(selectedRoles.length < 1)
        {
            baseReturn.message = oneRuleCopy;
            baseReturn.isValid = false;
        }
        else 
        {
            if(baseReturn.message === oneRuleCopy)
            {
                baseReturn.message = '';
            }
        }

        if(state.selectNewUser === null)
        {
            baseReturn.isValid = false;
        }

        return baseReturn;
    }

    getPromptUi()
    {
        return (
            <div>

                {
                    this.state.selectedUser === null ? 
                        <SelectUserPrompt onUserSelected={this.onUserSelected} /> :
                        <div>
                            { this.state.selectedUser.username }
                            <div>
                                <button 
                                    className="test-changeUserBtn"
                                    onClick={event => { this.selectNewUser() }}
                                >
                                    Change User
                                </button>
                            </div>
                        </div> 
                            
                }

                <h4>
                    Assign Roles
                </h4>
                {
                    super.getPromptUi()
                }
            </div>
        );
    }
}

const InviteCarrierMemberPrompt = connect(mapStateToProps, mapDispatchToProps)(_InviteCarrierMemberPrompt);
export default InviteCarrierMemberPrompt;
