// item or service types
const OFFER_SEEK_TYPE_ITEM = 'item';
const OFFER_SEEK_TYPE_SERVICE = 'service';

const validValues = [
    OFFER_SEEK_TYPE_ITEM,
    OFFER_SEEK_TYPE_SERVICE,
];

const getDropDownValues = () => [
    {
        name: 'Item',
        value: OFFER_SEEK_TYPE_ITEM
    },
    {
        name: 'Service',
        value: OFFER_SEEK_TYPE_SERVICE
    }
];

const isValid = value => !! validValues.includes(value);

const OfferSeekTypeEnum = {
    OFFER_SEEK_TYPE_ITEM,
    OFFER_SEEK_TYPE_SERVICE,
    getDropDownValues,
    isValid,
};

export default OfferSeekTypeEnum;