import React from 'react';
import PageTemplate from './template/page';
import theme from './../ui/theme';

const MissingPage = ({ isUser }) => 
    <PageTemplate isUser={isUser}>
        <div style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <h2 style={{ 
                ...theme.getGeneralTextStyle(theme),
            }}>
                Page Not Found
            </h2>
            <p>
                This page has been moved, removed, or never existed.
            </p>
        </div>
    </PageTemplate>

export default MissingPage;
