import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../service/resize';
import theme from './../theme';
import { NavLink } from 'react-router-dom';

const list = [
    {
        title: 'Invites',
        url: '/admin/invite/send',
        details: 'Share invites with users'
    },
    {
        title: 'Flags',
        url: '/admin/review/flags',
        details: 'Reviewing pending flagged items from users'
    },
    {
        title: 'Issues',
        url: '/admin/reported/issues',
        details: 'Review unseen reported issues'
    },
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const AdminUsersPanel = () => {
    const getContainerStyle = () => theme.getProfilePwdSuggestionOuterContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme),  
        }}>
            <div style={containerStyle}>
                {
                    list.map((item, index) => <Shortcut details={item.details} title={item.title} url={item.url} key={index} />)
                }
            </div>
        </div>
    );
};

export default AdminUsersPanel;
