import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class CarrierGroupDeliveryPlanService 
{

    static getForceUnlockRequest(carrierGroupId, deliveryId)
    {
        return {
            carrierGroupId,
            deliveryId
        }
    }

    /* istanbul ignore next */
    static forceUnlock({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.forceUnlock,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
    
    static getStartEditingRequest(carrierGroupId, deliveryId)
    {
        return {
            carrierGroupId,
            deliveryId
        }
    }

    /* istanbul ignore next */
    static startEditing({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.startEditing,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getStopEditingRequest(carrierGroupDeliveryPlanId)
    {
        return {
            carrierGroupDeliveryPlanId
        };
    }

    /* istanbul ignore next */
    static stopEditing({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.stopEditing,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListEditHistoryRequest(carrierGroupId, deliveryId)
    {
        return {
            carrierGroupId,
            deliveryId
        };
    }

    /* istanbul ignore next */
    static listEditHistory({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.viewEditHistory,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddStepRequest(carrierGroupId, deliveryId, sortOrder, memberId, type, from = null, to = null, updateMapColorKeys = null)
    {
        return {
            carrierGroupId,
            deliveryId,
            sortOrder,
            memberId,
            type,
            from,
            to,
            updateMapColorKeys
        };
    }

    /* istanbul ignore next */
    static addStep({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.addStep,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveStepRequest(carrierGroupId, deliveryId, stepId)
    {
        return {
            carrierGroupId,
            deliveryId,
            stepId
        };
    }

    /* istanbul ignore next */
    static removeStep({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.removeStep,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListPossibleDestinationsRequest(carrierGroupId, deliveryId, fromLatitude, fromLongitude, omitLocationIds = [])
    {
        return {
            carrierGroupId,
            deliveryId,
            fromLatitude,
            fromLongitude,
            omitLocationIds
        };
    }

    /* istanbul ignore next */
    static listPossibleDestinations({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.listPossibleDestinations,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getCompleteDraftStatusRequest(carrierGroupId, deliveryId)
    {
        return {
            carrierGroupId,
            deliveryId
        };
    }

    /* istanbul ignore next */
    static completeDraftStatus({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.status.completeDraft,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getCompleteSchedulingStatusRequest(carrierGroupId, deliveryId)
    {
        return {
            carrierGroupId,
            deliveryId
        };
    }

    /* istanbul ignore next */
    static completeSchedulingStatus({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.status.completeScheduling,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getPriceStatusRequest(carrierGroupId, deliveryId)
    {
        return {
            carrierGroupId,
            deliveryId
        };
    }

    /* istanbul ignore next */
    static priceStatus({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.status.pricingStatus,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getCompletePricingStatusRequest(carrierGroupId, deliveryId, exchangeList, pendingPayoutUpdates)
    {
        return {
            carrierGroupId,
            deliveryId,
            exchangeList,
            pendingPayoutUpdates
        };
    }

    /* istanbul ignore next */
    static completePricingStatus({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.plan.status.completePricing,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default CarrierGroupDeliveryPlanService;
