import React from 'react';
import WidgetMyActiveSeeksViewPanel from './view';
import DetailPanel from './../../../core/detail';
import SeekService from './../../../../../service/seek';
import mapStateToProps from './../../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../../store/map/on-logout';
import { connect } from 'react-redux';

class _WidgetMyActiveSeeksPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        SeekService.getUserListings({
            request: {
                page: inputObj.page
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({ page: 0 });
    }

    getLoadedDataDetailUi()
    {
        return <WidgetMyActiveSeeksViewPanel data={this.state.data} />
    }
}

const WidgetMyActiveSeeksPanel = connect(mapStateToProps, mapDispatchToProps)(_WidgetMyActiveSeeksPanel);

export default WidgetMyActiveSeeksPanel;
