import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../theme';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../service/resize';
import config from './../../config';

let shortcutList = [
    {
        title: 'Users',
        url: '/users',
        details: 'Search for and friend users you know'
    },
    {
        title: 'Platforms',
        url: '/trails',
        details: 'Check out Beartarian and bear adjacent platforms.'
    },
    {
        title: 'Manage My Platforms',
        url: '/profile/trails',
        details: 'List other platforms where users can find you.',
    },
    {
        title: 'Invite Friends',
        url: '/join',
        details: 'Send Bears.Exchange invites to other people'
    }
];

/* istanbul ignore next */
if(config.features.allowCarrierGroups)
{
    shortcutList.push({
        title: 'My Carrier Companies',
        url: '/profile/groups/carrier',
        details: 'Create Carrier Companies to work with others, deliver exchanges between users, and earn',
    });
}

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const ExchangeShortcuts = () => {
    const getProfilePeopleShortCutOuterContainerStyle = () => theme.getProfilePeopleShortCutOuterContainerStyle(theme);
    const [ profilePeopleShortCutOuterContainerStyle, setProfilePeopleShortCutOuterContainerStyle ] = useState( getProfilePeopleShortCutOuterContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setProfilePeopleShortCutOuterContainerStyle( getProfilePeopleShortCutOuterContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={profilePeopleShortCutOuterContainerStyle}>
            {
                shortcutList.map((cut, index) => <Shortcut details={cut.details} title={cut.title} url={cut.url} key={index} />)
            }
        </div>
    );
};

const ProfilePeoplePanel = () => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    const getBodyStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={containerStyle}>
                <h2>
                    Users &amp; Networks
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Manage your platforms and connect with others.
                </h4>
            </div>
            <div style={bodyStyle}>
                <div style={{
                    ...theme.getProfilePwdBodyFixStyle(theme),
                }}>
                    <div style={{
                        ...theme.getProfilePwdContainerStyle(theme),
                    }}>
                        <ExchangeShortcuts />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfilePeoplePanel;