import React from 'react';
import { UpdateOnboardingVisible, UpdateOnboardingCrawler } from '../../../store/state/action';
import { useDispatch } from 'react-redux';
import OnboardingViewPanel from './view';

const OnboardingPanel = ({ onboarding }) => {
    
    const dispatch = useDispatch();
    
    const exitOnboarding = () => {
        dispatch(
            UpdateOnboardingVisible(false)
        );
    };

    const updateCrawler = (nextCrawler) => {
        dispatch(
            UpdateOnboardingCrawler(nextCrawler)
        );
    };

    return <OnboardingViewPanel 
        exitOnboarding={exitOnboarding}
        crawler={onboarding.onboardingCrawler}
        updateCrawler={updateCrawler}
    />
};

export default OnboardingPanel;
