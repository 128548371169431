import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AddTrailPrompt from './../ui/prompt/add-trail';
import theme from './../ui/theme';

const AddTrailPage = () => {
    return (
        <AdminPageTemplate>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                Add Platform
            </h2>
            <AddTrailPrompt />
        </AdminPageTemplate>
    );
};

export default AddTrailPage;
