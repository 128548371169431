export const getPayoutsTableContainerStyle = (theme) => ({
    width: '475px',
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const getPayoutAccountButtonStyle = (theme) => ({
    float: 'left',
    marginRight: '10px'
});

export const getPayoutAccountButtonContainerStyle = (theme) => ({
    width: '140px',
    height: '80px'
});
