import React from 'react';
import theme from './../../../theme';

const HookedTrip = ({
    trip,
    shouldPromptRemoveHook,
    removeCargoFromCarrierGroupTrip
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <p>
            Scheduled as a stop for trip { trip.id }
        </p>
        <p>
            {`From ${trip.from.city}, ${trip.from.shortState ? trip.from.shortState : trip.from.state}`  }
        </p>
        <p>
            {`To ${trip.to.city}, ${trip.to.shortState ? trip.to.shortState : trip.to.state}`  }
        </p>

        {
            shouldPromptRemoveHook && 
                <button 
                    className="test-carrierGroupPlanDeliveryAlignTripsHookedTripRemoveBtn"
                    onClick={event => { removeCargoFromCarrierGroupTrip(trip) }}
                >
                    Remove from Trip
                </button>
                
        }

        <hr />
    </div>

export default HookedTrip;
