import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import { NavLink } from 'react-router-dom';
import TrackStepsPanel from './../../panel/core/track-steps';
import theme from './../../theme';

export const AddOfferViewPrompt = ({
    children,
    allowAccess,
    steps,
    currentStep
}) => {
    const getAddOfferStepPanelStyle = () => theme.addOfferStepPanelStyle(theme);
    const [ addOfferStepPanelStyle, setAddOfferStepPanelStyle ] = useState(getAddOfferStepPanelStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setAddOfferStepPanelStyle(getAddOfferStepPanelStyle());
    });
    useEffect(resizeEffect);
    return (
        <section>
            {
                !allowAccess && 
                <div style={{ 
                    ...theme.getAbstractCheckFieldContainer(theme),
                    ...theme.getReportedIssueUnseenStyle(theme)
                }}>
                    <p>
                        You must pay all your unpaid fees before you can add more offers.  
                        &nbsp;
                        <NavLink 
                            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                            to="/profile/fees"
                        >
                            Pay Fees Here
                        </NavLink>
                    </p>
                </div>
            }                
            <TrackStepsPanel list={steps} currentStep={currentStep} />
            <div style={addOfferStepPanelStyle}>
                { children }
            </div>
        </section>
    );
};

