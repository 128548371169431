export const getNewsBodyStyle = (theme) => ({
    margin: '0 auto 1rem',
    maxWidth: theme.isDesktopEXT() ?  '1200px' :
    (
        theme.isDesktopXL() ?  '992px' :
        (
            theme.isDesktop() ? '992px' :
            (
                theme.isTablet() ? '992px' :
                '992px'
            )
        )
    ),
    width: theme.isDesktopEXT() ? 'calc(100% - 8rem)' : 
    (    
        theme.isDesktopXL() ? 'calc(100% - 8rem)' : 
        (
            theme.isDesktop() ? 'calc(100% - 8rem)' : 
            (
                theme.isTablet() ? 'calc(100% - 3rem)' : 
                'calc(100% - 3rem)'
            )
        )
    ),
});