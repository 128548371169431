import React from 'react';
import AuthPageTemplate from './template/auth-page';
import SeekSearchPrompt from './../ui/prompt/seek-search';
import PostsMenu from './../ui/menu/posts';
import { useParams } from 'react-router-dom';
import theme from './../ui/theme';
import TagService from './../service/tag';

const SeeksPage = () => {
    const { page, searchText, tags } = useParams();
    /* istanbul ignore next */
    const passPage = page ? parseInt(page) : 0;
    /* istanbul ignore next */
    const passSearchText = searchText ? searchText : '';
    /* istanbul ignore next */
    const foundTagsRaw = tags ? tags : '';
    const parsedTags = TagService.parseUrlTags(foundTagsRaw);

    return (
        <AuthPageTemplate 
            childMenu={ <PostsMenu /> }
        >
            <div style={{
                ...theme.getInLineTextStyle(theme),  
            }}>
                <h2 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Seeks
                </h2>
                <p style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Items and services sought after by other users
                </p>
            </div>
            <SeekSearchPrompt page={passPage} searchText={passSearchText} tags={parsedTags} />
        </AuthPageTemplate>
    );
};
export default SeeksPage;
