import React from 'react';
import { 
    ObtainMethodCopy,
    ExchangeMethodCopy,
} from './../core/bids';
import { 
    BID_CONTENT_TYPE_SEEK,
} from './../../prompt/core/enum/bid-content-type';
import { 
    EXCHANGE_TYPE_CASH,
    EXCHANGE_TYPE_FREE,
    EXCHANGE_TYPE_ONLINE,
    EXCHANGE_TYPE_USPS_CASH_ORDER,
} from './../../prompt/core/enum/exchange-type';
import SeekSummaryPanel from './../seek-summary';
import OfferSummaryPanel from './../offer-summary';
import BidReviewerMenu from './../../menu/bid-reviewer';
import FlagPrompt from './../../prompt/flag';
import FlaggedContentTypeEnum from './../../prompt/core/enum/flagged-content-type';
import theme from './../../theme';
import CurrencyService from './../../../service/currency';
import RefundPolicyPanel from './../core/refund-policy';
import {
    OBTAIN_TYPE_ON_SITE, 
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND
} from './../../prompt/core/enum/obtain-type';

const ViewTargetPanel = ({ bid }) => bid.targetType === BID_CONTENT_TYPE_SEEK ? <SeekSummaryPanel seek={bid.target} /> : <OfferSummaryPanel offer={bid.target} />

// istanbul ignore next
const BidDetailViewPanel = ({ 
    data: bid, 
    onRejectBid, 
    onAcceptBid, 
    isReview, 
    bidderIsReceiver, 
    setPayClientSecret,
    onContactingServerResponse,
    onContactingServer,
    hideRefundPolicy = false,
    posterName = 'poster',
    bidderName = 'bidder'
}) =>
    <div style={{
        ...theme.getBidDetailContainerStyle(theme),
    }}>
        <div style={{
            ...theme.getBidDetailViewPanelStyle(theme),
        }}>

            {
                isReview ? '' : (
                    bid.rejected ? <h1>This bid was rejected</h1> : (
                        bid.accepted ? <h1>This bid was accepted</h1> : ''
                    )
                )
            }
            <h2 style={{ 
                ...theme.getGeneralTextStyle(theme),
            }}>
            { 
                bid.isAuthor ? <strong>
                        Your&nbsp;
                    </strong> : '' }
                Bid Details
            </h2>
            {
                
            bid.selectedLocation && bid.selectedLocation.regionalAddress && 
            <p>
                {`${bid.targetType === 'seek' && [
                    OBTAIN_TYPE_ON_SITE, 
                    OBTAIN_TYPE_SHIP_TO_ADDRESS,
                    OBTAIN_TYPE_HAND_TO_HAND
                ].includes(bid.obtainMethod) ? 'Coming from ' : 'Location:' } ${ bid.selectedLocation.regionalAddress }`}
            </p>
        }
        
            <h3>
                { 
                    bid.exchangeMethod === EXCHANGE_TYPE_ONLINE ? 
                        `${ExchangeMethodCopy({ bid })} ${CurrencyService.getDisplayPrice(bid.currency, bid.title)}` : (
                            [EXCHANGE_TYPE_USPS_CASH_ORDER, EXCHANGE_TYPE_CASH].includes(bid.exchangeMethod)  ? 
                                `${ExchangeMethodCopy({ bid })} ${CurrencyService.getDisplayPrice('usd', bid.title)}` : (
                                    bid.exchangeMethod === EXCHANGE_TYPE_FREE ? 
                                        ExchangeMethodCopy({ bid }) : 
                                            `${ExchangeMethodCopy({ bid })} ${bid.title} ` 
                                )
                        )
                }
            </h3>

            <div style={{
                ...theme.getBidDetailViewPanelFlexStyle(theme),
            }}>

                <div style={{
                    ...theme.getBidDetailViewPanelFlagContainerStyle(theme),
                }}>

                    { isReview || bid.isAuthor ? '' : 
                        <FlagPrompt 
                            contentId={bid.id} 
                            flaggedContentType={FlaggedContentTypeEnum.FLAGGED_CONTENT_TYPE_BID} 
                            openPromptTitle="Flag this bid"
                        /> 
                    }

                </div>


                <div>
                    { !isReview && bid.target.isAuthor ? 
                        <BidReviewerMenu 
                            bid={bid} 
                            onContactingServerResponse={onContactingServerResponse}
                            onContactingServer={onContactingServer}
                            onRejectBid={onRejectBid} 
                            onAcceptBid={onAcceptBid} 
                            setPayClientSecret={setPayClientSecret}
                        /> : '' 
                    }

                </div>

            </div>
            

            
            <p>
                { bid.details }
            </p>
            
            <p>
                { 
                    ObtainMethodCopy({ 
                        bid, 
                        bidderIsReceiver,
                        posterName: bid.target && bid.target.isAuthor ? 'you' : posterName,
                        bidderName: bid.isAuthor ? 'you' :  bidderName
                    }) 
                }
            </p>

            {
                hideRefundPolicy ? '' : 
                    <div>
                        <RefundPolicyPanel data={bid} />
                        <hr />
                    </div>
            }

            
            
            { isReview ? '' : ViewTargetPanel({ bid }) }

        </div>
    </div>

export default BidDetailViewPanel;
