import React from 'react';
import DefineStep from './define-step';
import DefinedSteps from './defined-steps';
import { sortMembers } from './../core';

const DeliverySteps = ({
    steps,
    canEdit,
    plan,
    delivery,
    members,
    onAddStep,
    onStepSelected,
    carrierGroupId,
    selectedStep,
    getColorKeyByLocation,
    onRemoveStep,
    onCompleteDraftStatus
}) => {
    const sortedMembers = sortMembers(members);
    return (
        <div>

            <DefinedSteps
                steps={steps}
                selectedStep={selectedStep}
                onStepSelected={onStepSelected}
                sortedMembers={sortedMembers}
                getColorKeyByLocation={getColorKeyByLocation}
                onRemoveStep={onRemoveStep}
                canEdit={canEdit}
            />

            <DefineStep
                sortedMembers={sortedMembers}
                steps={steps}
                plan={plan}
                delivery={delivery}
                onAddStep={onAddStep}
                carrierGroupId={carrierGroupId}
                onCompleteDraftStatus={onCompleteDraftStatus}
            />

        </div>
    );
};
export default DeliverySteps;
