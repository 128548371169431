import React from 'react';
import BidService from './../../../service/bid';
import ValidateService from './../../../service/validate';
import BidContentTypeEnum from './../core/enum/bid-content-type';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import theme from './../../theme';
import {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_PICK_UP,
    OBTAIN_TYPE_REMOTE,
    OBTAIN_TYPE_ON_SITE,
} from './../core/enum/obtain-type';
import { 
    ObtainMethodCopy,
    ExchangeMethodCopy
} from './../../panel/core/bids';
import {
    EXCHANGE_TYPE_CASH,
    EXCHANGE_TYPE_FREE,
    EXCHANGE_TYPE_ONLINE, 
    EXCHANGE_TYPE_USPS_CASH_ORDER,
    EXCHANGE_TYPE_TRADE
} from './../core/enum/exchange-type';
import { getObtainTypeLocationTitleCopy } from './../core/copy/obtain-type-copy-util';
import LocationSelectorPanel from './../../panel/location-selector';
import BidOnlinePaymentPrompt from './../bid-online-payment';
import BidUspsPaymentPrompt from './../bid-usps-payment';
import BidCashPaymentPrompt from './../bid-cash-payment';
import BidFreePrompt from './../bid-free';


export const obtainToValue = obtainType => `${obtainType.type}`;
/* istanbul ignore next */
export const obtainToName = obtainType => obtainType.type === OBTAIN_TYPE_SHIP_TO_ADDRESS ? 'Shipping Company' : (
    obtainType.type === OBTAIN_TYPE_HAND_TO_HAND ? 'Hand Delivery' : (
        obtainType.type === OBTAIN_TYPE_PICK_UP ? 'Pick Up' : (
            obtainType.type === OBTAIN_TYPE_REMOTE ? 'Remote Fulfillment' : 'On Site'
        )
    )
);

export const mapObtainToValueItem = delivery => ({
    name: obtainToName(delivery),
    value: obtainToValue(delivery),
});

export const filterRelevantExchanges = (bidContentType, exchangeList) => bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? 
    exchangeList.filter(exOpt => exOpt.type !== EXCHANGE_TYPE_ONLINE) : exchangeList;

export const isValidExchangeType = thisType => [
        EXCHANGE_TYPE_ONLINE, 
        EXCHANGE_TYPE_USPS_CASH_ORDER, 
        EXCHANGE_TYPE_CASH, 
        EXCHANGE_TYPE_FREE
    ].includes(thisType);

export const exchangeToValue = exOption => `${exOption.type}`;
export const exchangeToName = exOption => ExchangeMethodCopy({ 
    bid: {
        exchangeMethod: exOption.type
    }
});

export const mapExchangeToValueItem = exOpt => ({ 
    name: exchangeToName(exOpt),
    value: exchangeToValue(exOpt),
});

/* istanbul ignore next */
class _BidPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _BidPrompt.getValidators(parms));

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getClosedPromptUi = this.getClosedPromptUi.bind(this);
        this.openPrompt = this.openPrompt.bind(this);
        this.getDynamicFields = this.getDynamicFields.bind(this);
        this.applyDynamicFieldValidators = this.applyDynamicFieldValidators.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.getOpenPromptOnlinePayUi = this.getOpenPromptOnlinePayUi.bind(this);
        this.getOpenPromptGeneralUi = this.getOpenPromptGeneralUi.bind(this);
        this.setExchangeMethod = this.setExchangeMethod.bind(this);
        this.getOpenPromptUspsPayUi = this.getOpenPromptUspsPayUi.bind(this);
        this.getOpenPromptCashPayUi = this.getOpenPromptCashPayUi.bind(this);
        this.getOpenPromptFreeUi = this.getOpenPromptFreeUi.bind(this);
        this.isPromptingLocation = this.isPromptingLocation.bind(this);
        this.getFormFieldsUi = this.getFormFieldsUi.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationRemoveSelected = this.onLocationRemoveSelected.bind(this);
        this.validateState = this.validateState.bind(this);
        this.getNonOnlineExchangeList = this.getNonOnlineExchangeList.bind(this);
        this.reorderValidators = this.reorderValidators.bind(this);

        this.applyDynamicFieldValidators();
        this.reorderValidators();
        this.state = this.getDefaultState();
    }

    static loadWithWeightVisible(parms, thisState)
    {
        const weightTypes = [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND
        ];
        return (
            (
                // no prompt for obtain method
                parms.content && 
                parms.content.obtainTypeDetailList && 
                parms.content.obtainTypeDetailList.length === 1 && 
                weightTypes.includes(
                    obtainToValue(parms.content.obtainTypeDetailList[0])
                )
            )
            ||
            (
                // obtain prompt has ship selected
                thisState.obtainMethod && 
                weightTypes.includes(
                    thisState.obtainMethod.value 
                )
            )
            ||
            (
                // obtain prompt not yet rendered - first option ship 
                !thisState.obtainMethod && 
                parms.content && 
                parms.content.obtainTypeDetailList && 
                parms.content.obtainTypeDetailList.length > 0 && 
                weightTypes.includes(
                    obtainToValue(parms.content.obtainTypeDetailList[0]) 
                )
            )
        ) && parms.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER
    }

    /* istanbul ignore next */
    reorderValidators()
    {
        const nextValidators = {};
        const nextOrder = [
            'exchangeMethod',
            'currency',
            'title',
            'details',
            'weightPounds',
            'weightOunces',
            'hasRefundPolicy',
            'saveRefundPolicy',
            'refundPolicyDetails',
            'obtainMethod'
        ];

        for(const aValName of nextOrder)
        {
            if(this.validator[aValName])
            {
                nextValidators[aValName] = this.validator[aValName];
            }
        }

        this.validator = nextValidators;
    }

    static getValidators(parms)
    {
        return {
            title: {
                isValid: ValidateService.valueRequired,
            },
            details: {
                isValid: ValidateService.textLines,
            },
            weightPounds: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => _BidPrompt.loadWithWeightVisible(parms, thisState)
            },
            weightOunces: {
                isValid: ValidateService.numeric,
                isVisible: (thisState) => _BidPrompt.loadWithWeightVisible(parms, thisState)
            },
            hasRefundPolicy: {
                isValid: ValidateService.anyValue,
            },
            saveRefundPolicy: {
                isValid: ValidateService.anyValue,
                isVisible: (thisState) => parms.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && 
                    parms.content && 
                    parms.content.setupBid && 
                    !parms.content.setupBid.hasRefundPolicy && 
                    thisState.hasRefundPolicy.value,
            },
            refundPolicyDetails: {
                isValid: (value, thisState) => {
                    if(thisState.hasRefundPolicy.value) 
                    {
                        return ValidateService.valueRequired(value);
                    }

                    return true;
                },
                isVisible: (thisState) => thisState.hasRefundPolicy.value
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        const nonOnlineExchangeList = this.getNonOnlineExchangeList();
        const isWeightPromptNeeded = _BidPrompt.loadWithWeightVisible(this.props, {});
        const tradeList = nonOnlineExchangeList.filter(ex => ex.type === EXCHANGE_TYPE_TRADE);
        const titleValue = tradeList.length > 0 ? tradeList[0].asking : (
            nonOnlineExchangeList.length > 0 ? nonOnlineExchangeList[0].asking : ''
        );
        return {
            title: {
                name: 'Bid*',
                error: 'Provide a summary for your bid',
                value: titleValue,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT,
            },
            details: {
                name: 'Details',
                error: 'Provide details regarding your bid, no more than 2170 characters',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
            weightPounds: {
                name: 'Shipping Weight Pounds',
                error: 'Specify the pounds in shipping weight',
                value: '',
                visible: isWeightPromptNeeded,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            weightOunces: {
                name: 'Shipping Weight Ounces',
                error: 'Specify the ounces in shipping weight',
                value: '',
                visible: isWeightPromptNeeded,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            hasRefundPolicy: {
                name: 'Refund Policy Offered',
                error: 'Do you offer a refund policy?',
                value: this.props.content && this.props.content.setupBid ? this.props.content.setupBid.hasRefundPolicy : false,
                visible: this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX,
            },
            saveRefundPolicy: {
                name: 'Save Refund Policy for Re-Use',
                error: 'Do you wish to save this policy for re-use?',
                value: false,
                visible: false,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            refundPolicyDetails: {
                name: 'Please explain your refund policy',
                error: 'Explain your refund policy',
                value: this.props.content && this.props.content.setupBid && this.props.content.setupBid.hasRefundPolicy ? this.props.content.setupBid.refundPolicy : '',
                visible: this.props.content && this.props.content.setupBid ? this.props.content.setupBid.hasRefundPolicy : false,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
        };
    }

    applyDynamicFieldValidators()
    {
        
        const relevantExchangeList = this.props.content && this.props.content.exchangeList ?
            filterRelevantExchanges(this.props.bidContentType, this.props.content.exchangeList) : [];
        
        if(relevantExchangeList.length > 1) 
        {
            this.validator.exchangeMethod = {
                isValid: ValidateService.anyValue
            };
        } 
    
        if( this.props.content &&
            this.props.content.obtainTypeDetailList && 
            this.props.content.obtainTypeDetailList.length > 1)
        {
            this.validator.obtainMethod = {
                isValid: ValidateService.anyValue
            };
        }
    }

    getDynamicFields()
    {
        let out = {};
        const relevantExchangeList = this.props.content && this.props.content.exchangeList ?
            filterRelevantExchanges(this.props.bidContentType, this.props.content.exchangeList) : [];

        let uniqueValue = [];
        const filterToUnique = exOpt => {
            const value = exchangeToValue(exOpt);
            const found = uniqueValue.find(aVal => aVal === value);
            if(!found)
            {
                uniqueValue.push(value);
                return true;
            }
            else
            {
                return false;
            }
        };

        if(this.props.content && this.props.content.exchangeList && relevantExchangeList.length > 1)
        {
            out.exchangeMethod = {
                name: 'Exchange Method',
                error: 'Specify which exchange method should be used',
                value: exchangeToValue(relevantExchangeList[0]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: relevantExchangeList.filter(filterToUnique).map(exOpt => ({ 
                    name: exchangeToName(exOpt),
                    value: exchangeToValue(exOpt),
                })),

            };
        } else if( this.props.content && this.props.content.exchangeList && relevantExchangeList.length > 0) 
        {
            // just value, no need to add entire field, this is for component rendering
            const thisType = relevantExchangeList[0].type;
            if(isValidExchangeType(thisType))
            {
                out.exchangeMethod = {
                    value: thisType
                };
            }
        }


        if(this.props.content && 
            this.props.content.obtainTypeDetailList && 
            this.props.content.obtainTypeDetailList.length > 1)
        {
            out.obtainMethod = {
                name: 'Delivery Method',
                error: 'Specify which delivery method should be used',
                value: obtainToValue(this.props.content.obtainTypeDetailList[0]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: this.props.content.obtainTypeDetailList.map(delivery => ({
                    name: obtainToName(delivery),
                    value: obtainToValue(delivery),
                })),
            };
        }

        return out;
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        const dynamicFields = this.getDynamicFields();
        let nextState = {
            ...baseState,
            ...fields,
            ...dynamicFields,
            bidPromptOpen: false,
            selectedLocation: null,
        };
        nextState.isValid = !this.isPromptingLocation(nextState);
        return nextState;
    }

    getServerRequest()
    {
        return this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? {
            offerId: this.props.content.id,
            title: this.state.title.value,
            details: this.state.details.value,
            selectedLocation: this.isPromptingLocation(this.state) ? this.state.selectedLocation.id : '',
            obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                this.props.content.obtainTypeDetailList.length > 0 ? 
                    obtainToValue(this.props.content.obtainTypeDetailList[0]) : ''
                ),
            exchangeMethod: this.state.exchangeMethod ? this.state.exchangeMethod.value : (
                this.props.content.exchangeList.filter(ex => 
                    this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ||
                    ex.type !== EXCHANGE_TYPE_ONLINE
                ).length > 0 ? 
                    exchangeToValue(this.props.content.exchangeList.filter(ex => 
                        this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ||
                        ex.type !== EXCHANGE_TYPE_ONLINE
                    )[0]) : ''
                ),
        } : {
            seekId: this.props.content.id,
            title: this.state.title.value,
            details: this.state.details.value,
            selectedLocation: this.isPromptingLocation(this.state) ? this.state.selectedLocation.id : '',
            hasRefundPolicy: this.state.hasRefundPolicy.value,
            refundPolicyDetails: this.state.hasRefundPolicy.value ? this.state.refundPolicyDetails.value : '',
            saveRefundPolicy: 
                this.props.bidContentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER && 
                this.props.content && 
                this.props.content.setupBid && 
                !this.props.content.setupBid.hasRefundPolicy ? this.state.saveRefundPolicy.value : false,
            obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                this.props.content.obtainTypeDetailList.length > 0 ? 
                    obtainToValue(this.props.content.obtainTypeDetailList[0]) : ''
                ),
            weight: _BidPrompt.loadWithWeightVisible(this.props, this.state) ? {
                pounds: this.state.weightPounds.value,
                ounces: this.state.weightOunces.value
            } : {},
            exchangeMethod: this.state.exchangeMethod ? this.state.exchangeMethod.value : (
                this.props.content.exchangeList.length > 0 ? 
                    exchangeToValue(this.props.content.exchangeList[0]) : ''
                ),
        };
    }

    setExchangeMethod(value)
    {
        let nextState = this.state;
        nextState.exchangeMethod.value = value;
        this.setState(nextState);
    }

    isPromptingLocation(state)
    {
        const isOffer = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? true : false;
        const showList = isOffer ? [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND,
            OBTAIN_TYPE_ON_SITE
        ] : [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND,
            OBTAIN_TYPE_ON_SITE,
            OBTAIN_TYPE_PICK_UP
        ];
        if( this.props.content && 
            this.props.content.obtainTypeDetailList && 
            this.props.content.obtainTypeDetailList.length > 0)
        {
            if(this.props.content.obtainTypeDetailList.length === 1)
            {
                return showList.includes(this.props.content.obtainTypeDetailList[0].type);
            }

            return showList.includes(state.obtainMethod.value);
        }
        return false;
    }

    validateState(state)
    {
        let baseReturn = super.validateState(state);
        if(this.isPromptingLocation(state))
        {
            if(!this.state.selectedLocation)
            {
                baseReturn.isValid = false;
            }
        }
        return baseReturn;
    }

    onLocationSelected(location)
    {
        let nextState = this.state;
        nextState.selectedLocation = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    onLocationRemoveSelected()
    {
        let nextState = this.state;
        nextState.selectedLocation = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }


    getFormFieldsUi()
    {
        let output = [];
        for(const aField in this.validator)
        {
            try
            {
                if(this.state[aField].visible)
                {

                    output.push(
                        <section key={aField}>
                            { this.getFormFieldUi(aField) }
                        </section>
                    );
                }

                if(aField === 'obtainMethod' || (aField === 'details' && this.props.content && this.props.content.obtainTypeDetailList && this.props.content.obtainTypeDetailList.length === 1))
                {
                    if(this.isPromptingLocation(this.state))
                    {
                        const selectedType = this.state.obtainMethod ? this.state.obtainMethod.value : (
                            this.props.content.obtainTypeDetailList && this.props.content.obtainTypeDetailList[0] && this.props.content.obtainTypeDetailList[0].type ? 
                                this.props.content.obtainTypeDetailList[0].type : ''
                        );
                        const isOffer = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? true : false;
                        const title = getObtainTypeLocationTitleCopy(selectedType, isOffer);
                        output.push(
                            <LocationSelectorPanel
                                key={`locationSelector${output.length}`}
                                promptTitle={title}
                                selectedLocation={this.state.selectedLocation}
                                onSelected={this.onLocationSelected}
                                onRemoveSelected={this.onLocationRemoveSelected}
                                includeGps={true}
                            />
                        );
                    }
                }
            }
            catch(err)
            {
                throw new Error(`Unable to read ${aField}`);
            }
        }
        return output;
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.props.setBids(response.bids);
        this.setState(nextState);        
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        const request = this.getServerRequest();
        const requestName = this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? 'addOfferBid' : 'addSeekBid';
        BidService[requestName]({
            request,
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getConfirmUi(confirmMessage = 'Bid Submitted')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Submit Bid')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    openPrompt()
    {
        const nextState = {
            ...this.state,
            bidPromptOpen: true,
        };
        this.setState(nextState);
    }

    getClosedPromptUi()
    {
        const hasAccess = this.props.content.receiveBidAllowAccess !== undefined ? this.props.content.receiveBidAllowAccess : true;
        const hasAccessMessage = this.props.doesNotHaveAccessMessage ? this.props.doesNotHaveAccessMessage : 'Poster is currently unable to receive bids';
        return hasAccess ? 
            <div style={this.props.buttonStyle}>
                <button 
                    className="test-bidPromptBidBtn"
                    onClick={event => { this.openPrompt() }}
                >
                    Bid
                </button>
            </div> : 
            <div>
                { hasAccessMessage }
            </div>
    }

    getOpenPromptOnlinePayUi()
    {
        return (
            <BidOnlinePaymentPrompt
                obtainTypeDetailList={this.props.content.obtainTypeDetailList}
                asking={this.props.content.exchangeList.filter(ex => ex.type === EXCHANGE_TYPE_ONLINE)[0].asking}
                exchangeList={this.props.content.exchangeList}
                setupBid={this.props.content && this.props.content.setupBid ? this.props.content.setupBid : undefined }
                bidContentType={this.props.bidContentType}
                contentId={this.props.content.id}
                setBids={this.props.setBids}
                paidAccount={this.props.paidAccount}
                setExchangeMethod={this.setExchangeMethod}
            />
        );
    }

    getNonOnlineExchangeList()
    {
        return this.props.content.exchangeList.filter(ex => ex.type !== EXCHANGE_TYPE_ONLINE);
    }

    getOpenPromptUspsPayUi()
    {
        const nonOnlineExchangeList = this.getNonOnlineExchangeList();
        return (
            <BidUspsPaymentPrompt 
                obtainTypeDetailList={this.props.content.obtainTypeDetailList}
                asking={nonOnlineExchangeList.filter(ex => ex.type === EXCHANGE_TYPE_USPS_CASH_ORDER)[0].asking}
                exchangeList={this.props.content.exchangeList}
                setupBid={this.props.content && this.props.content.setupBid ? this.props.content.setupBid : undefined }
                bidContentType={this.props.bidContentType}
                contentId={this.props.content.id}
                setBids={this.props.setBids}
                setExchangeMethod={this.setExchangeMethod}
            />
        );
    }

    getOpenPromptCashPayUi()
    {
        const nonOnlineExchangeList = this.getNonOnlineExchangeList();
        return (
            <BidCashPaymentPrompt 
                obtainTypeDetailList={this.props.content.obtainTypeDetailList}
                asking={nonOnlineExchangeList.filter(ex => ex.type === EXCHANGE_TYPE_CASH)[0].asking}
                exchangeList={this.props.content.exchangeList}
                setupBid={this.props.content && this.props.content.setupBid ? this.props.content.setupBid : undefined }
                bidContentType={this.props.bidContentType}
                contentId={this.props.content.id}
                setBids={this.props.setBids}
                setExchangeMethod={this.setExchangeMethod}
            />
        );
    }

    getOpenPromptFreeUi()
    {
        return (
            <BidFreePrompt 
                obtainTypeDetailList={this.props.content.obtainTypeDetailList}
                exchangeList={this.props.content.exchangeList}
                setupBid={this.props.content && this.props.content.setupBid ? this.props.content.setupBid : undefined }
                bidContentType={this.props.bidContentType}
                contentId={this.props.content.id}
                setBids={this.props.setBids}
                setExchangeMethod={this.setExchangeMethod}
            />
        );
    }

    getOpenPromptGeneralUi()
    {
        return (
            <div style={{
                ...theme.getBidPromptStyle(theme),
            }}>
                <h2>
                    Place a trade bid
                </h2>
                <p>
                    Make sure to include all relevant details such as currency type or quantities of traded goods.
                </p>
                <p>
                    <span style={{
                        ...theme.getWarningTextStyle(theme),
                    }}>
                        Bid field will be visible to all users, details section will only be visible to the poster
                    </span>
                </p>
                { super.getPromptUi() }
                
                <p>
                    {
                        this.state.obtainMethod ? ObtainMethodCopy({
                            bid: {
                                obtainMethod: this.state.obtainMethod.value 
                            },
                            bidderIsReceiver: this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER,
                            bidderName: 'you'
                        }) : ''

                    }
                </p>

            </div>
        );
    }

    getOpenPromptUi()
    {
        return this.state.exchangeMethod && this.state.exchangeMethod.value === EXCHANGE_TYPE_ONLINE ? 
            this.getOpenPromptOnlinePayUi() : (
                this.state.exchangeMethod && this.state.exchangeMethod.value === EXCHANGE_TYPE_USPS_CASH_ORDER ? 
                    this.getOpenPromptUspsPayUi() : (
                        this.state.exchangeMethod && this.state.exchangeMethod.value === EXCHANGE_TYPE_CASH ? 
                            this.getOpenPromptCashPayUi() : (
                                this.state.exchangeMethod && this.state.exchangeMethod.value === EXCHANGE_TYPE_FREE ? 
                                    this.getOpenPromptFreeUi() : 
                                        this.getOpenPromptGeneralUi()
                            )
                    )
            );
    }

    onCloseDialog(event)
    {
        const nextState = {
            ...this.state,
            bidPromptOpen: false,
        };
        this.setState(nextState);
    }

    getPromptUi()
    {
        return (
            <div>
                { this.getClosedPromptUi() }
                <Dialog 
                    onClose={this.onCloseDialog}
                    open={this.state.bidPromptOpen}
                >
                    { this.getOpenPromptUi() }
                </Dialog>
            </div>
        );
    }
}

const BidPrompt = connect(mapStateToProps, mapDispatchToProps)(_BidPrompt);
export default BidPrompt;
