import React from 'react';
import ValidateService from './../../../service/validate';
import CurrencyService from './../../../service/currency';
import CurrencyInputService from './../../../service/currency-input';
import FeeService from './../../../service/fee';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import ExchangeTypeEnum from './../core/enum/exchange-type';
import RestrictViewTypeEnum from './../core/enum/restrict-view-type';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';

class AddOnlinePaymentExchangePrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, AddOnlinePaymentExchangePrompt.getValidators());

        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.controlValueChange = this.controlValueChange.bind(this);
        this.displayPriceUi = this.displayPriceUi.bind(this);
        this.getAskingError = this.getAskingError.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.validator.asking.isValid = ValidateService.makeCurrencyFieldValue(this, 'currency', this.props.paidAccount ? this.props.paidAccount : false);
    }

    static getValidators()
    {
        return {
            type: {
                isValid: ExchangeTypeEnum.isValid,
            },
            visibilityType: {
                isValid: RestrictViewTypeEnum.isValid,
            },
            currency: {
                isValid: ValidateService.valueRequired
            },
            asking: {
                isValid: ValidateService.textLine
            },
        };
    }

    componentDidMount()
    {
        if(this.props.asking)
        {
            const nextState = this.validateState(this.state);
            nextState.asking.value = this.props.asking;
            this.setState(nextState);
        }
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    controlValueChange(name, value, entity = null)
    {
        if(name === 'type' && this.props.onSetTypeField)
        {
            let updateState = this.state;
            const valid = this.validator[name].isValid(value, updateState);
            if(valid)
            {
                this.props.onSetTypeField(value);
            }
        }

        super.controlValueChange(name, value, entity);
    }

    /* istanbul ignore next */
    getAskingError()
    {
        return `Specify a valid currency amount greater than or equal to ${CurrencyService.getDisplayPrice(
            this.state && this.state.currency ? this.state.currency.value : ( this.props.payCurrencyList[0] ? this.props.payCurrencyList[0].toLowerCase() : 'usd' ),
            FeeService.getMinListedPrice(this.props.paidAccount ? this.props.paidAccount : false),
            false
        )}`;
    }

    getFieldsModel()
    {
        const currencyValue = this.props.payCurrencyList[0].toLowerCase();
        const base = {
            state: {
                currency: {
                    value: currencyValue
                }
            }
        };
        const validateAsking = ValidateService.makeCurrencyFieldValue(base, 'currency', this.props.paidAccount ? this.props.paidAccount : false);
        const askingValue = this.props.asking ? this.props.asking : '';
        const askingValid = validateAsking(askingValue);
        return {
            type: {
                name: 'Exchange Type',
                error: 'Specify the type of exchange you accept',
                value: ExchangeTypeEnum.EXCHANGE_TYPE_ONLINE,
                valueList: ExchangeTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            visibilityType: {
                name: 'Offer Visibility',
                error: 'Specify the offer visibility',
                value: RestrictViewTypeEnum.RESTRICT_VIEW_TYPE_PUBLIC,
                valueList: RestrictViewTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            currency: {
                name: 'Currency',
                error: 'Specify the currency type for the amount requested',
                value: currencyValue, 
                valueList: this.props.payCurrencyList.map(aCur => ({
                    name: aCur,
                    value: aCur.toLowerCase()
                })),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            asking: {
                name: 'Asking',
                error: this.getAskingError(),
                value: askingValue,
                visible: true,
                valid: askingValid,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields
        };
    }

    

    onSendToServer()
    {
        const details = this.getServerRequest();
        this.props.onAddOnlinePaymentExchange(details);

        let nextState = this.getBaseStateOnResponse({ message: '', success: false });
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    getServerRequest()
    {
        return {
            type: this.state.type.value,
            visibility: {
                type: this.state.visibilityType.value,
                expireDate: '',
            },
            asking: CurrencyInputService.parse(this.state.asking.value),
            currency: this.state.currency.value
        };
    }

    displayPriceUi()
    {
        const { total, fee, netTotal } = FeeService.getEstimated(
            this.state.currency.value,
            CurrencyInputService.parse(this.state.asking.value),
            this.props.paidAccount ? this.props.paidAccount : false
        );

        const fieldList = [
            {
                title: 'Listed Price',
                value: total
            },
            {
                title: 'Fee',
                value: fee
            },
            {
                title: 'Payout',
                value: netTotal
            }
        ];
        
        return (
            <div>
                {
                    fieldList.map((f, index) => 
                        <div 
                            key={index}
                            style={{
                                ...theme.getAddOnlinePaymentExchangePromptPriceContainerStyle(theme)
                        }}>
                            <div style={{
                                ...theme.getAddOnlinePaymentExchangePromptPriceTitleStyle(theme)
                            }}>
                                { f.title }
                            </div>
                            <div style={{
                                ...theme.getAddOnlinePaymentExchangePromptPriceValueStyle(theme)
                            }}>
                                { 
                                    CurrencyService.getDisplayPrice(
                                        this.state.currency.value,
                                        f.value,
                                        false
                                    )
                                }
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>)
                }
            </div>
        );
        
    }

    getPromptUi(buttonText = 'Add')
    {
        return (
            <div>
                <p>
                    Online payments are deducted a transaction fee.  See our 
                    &nbsp;
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to="/fees"
                    >
                        fees page
                    </NavLink> 
                    &nbsp;
                    for more information.
                </p>
                {
                    this.state.asking.valid ? this.displayPriceUi() : ''
                }
                {
                    this.props.extraCopy !== '' && this.state.isValid && 
                        <p style={theme.getFinePrintStyle(theme)}>
                            { this.props.extraCopy }
                        </p> 
                }
                {
                    super.getPromptUi(buttonText)
                }
            </div>
        );
    }
}

export default AddOnlinePaymentExchangePrompt;
