export const getButtonStyle = (theme) => ({ 
    textDecoration: 'none',
    color: theme.secondaryColor,
    cursor: 'pointer',
    borderColor: theme.secondaryColor,
    borderRadius: '.15rem',
    padding: '.4rem 1.2rem',
    transition: 'all 0.2s',
    textAlign: 'center',
    fontWeight: '500',
    backgroundColor: theme.buttonColor,
    margin: '.75rem 1.25rem',
    borderWidth: '0 0 .075rem 0'
});

export const getBidButtonStyle = (theme) => ({ 
    textDecoration: 'none',
    color: theme.secondaryColor,
    cursor: 'pointer',
    borderColor: theme.secondaryColor,
    borderRadius: '.15rem',
    padding: '.4rem 1.2rem',
    transition: 'all 0.2s',
    textAlign: 'center',
    fontWeight: '500',
    backgroundColor: theme.buttonColor,
    margin: '.75rem 1.25rem',
    borderWidth: '0 0 .075rem 0'
});

export const getButtonTabStyle = (theme, isSelected) => ({ 
    textDecoration: 'none',
    color: isSelected ? theme.selectColorLight : theme.secondaryColor,
    cursor: 'pointer',
    borderColor: theme.secondaryColor,
    borderRadius: '.15rem .15rem 0 0',
    padding: '.4rem 1.2rem',
    transition: 'all 0.2s',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: isSelected ? theme.secondaryColor : theme.buttonColor,
    borderWidth: '.075rem .075rem 0 .075rem', 
    boxShadow: 'RGBA(29, 29, 29, .5) 0 0 .1rem 0'
});
