import React from 'react';
import MenuList from './core/menu-list';
import UpdatePwdIcon from './../../img/menu-update-pwd-white.svg';
import ProfileStoreIcon from './../../img/menu-profile-store.svg';
import ProfileMoneyIcon from './../../img/menu-profile-money.svg';
import theme from './../theme';
import PeopleIcon from './../../img/menu-people.svg';
import AccountInfoIcon from './../../img/menu-account-info.svg';

const ProfileMenu = () => <MenuList list={[
    {
        to: '/profile/pwd',
        title: 'Access',
        customStyle: {
            ...theme.getMenuSubStyle(theme, UpdatePwdIcon),
        },
    },
    {
        to: '/profile/store',
        title: 'Manage Account',
        customStyle: {
            ...theme.getMenuSubStyle(theme, ProfileStoreIcon),
        },
    },
    {
        to: '/profile/people',
        title: 'Users & Networks',
        customStyle: {
            ...theme.getMenuSubStyle(theme, PeopleIcon),
        },
    },
    {
        to: '/profile/income',
        title: 'Online Payments',
        customStyle: {
            ...theme.getMenuSubStyle(theme, ProfileMoneyIcon),
        },
    },
    {
        to: '/profile/info',
        title: 'Info',
        customStyle: {
            ...theme.getMenuSubStyle(theme, AccountInfoIcon),
        },
    },
]} />

export default ProfileMenu;
