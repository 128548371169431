import axios from 'axios';
import ClientService from './client';

/* istanbul ignore next */
const getAxiosHeaders = ({ authToken }) => {
    
    return {
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
        'content-type': 'multipart/form-data',
        'X-Requested-With': ClientService.getIP()
    };
};

/* istanbul ignore next */
const getAxiosData = ({ request, fileList }) => {
    const data = new FormData();
    data.append('request', JSON.stringify(request));
    fileList.forEach((aFile) => {
        data.append('fileList', aFile.file);
    });
    
    return data;
};

/* istanbul ignore next */
const buildOnResponse = onResponse => (result) => onResponse(result.data);

/* istanbul ignore next */
const endpointFileUpload = ({
    url,
    request,
    fileList,
    onResponse,
    onError,
    authToken
}) => {
    const data = getAxiosData({ request, fileList });
    const headers = getAxiosHeaders({ authToken });
    axios({
        method: 'post',
        url,
        data,
        headers,
    }).then(buildOnResponse(onResponse)).catch(onError);

};

export default endpointFileUpload;
