import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class CarrierGroupService 
{
    static getAddRequest(name, contactEmail, contactPhone, page = 0)
    {
        return {
            name,
            contactEmail,
            contactPhone,
            page
        };
    }

    /* istanbul ignore next */
    static add({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdateRequest(carrierGroupId, contactEmail, contactPhone)
    {
        return {
            carrierGroupId,
            contactEmail,
            contactPhone
        };
    }

    /* istanbul ignore next */
    static update({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.update,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListRequest(page = 0)
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static list({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDetailsRequest(carrierGroupId)
    {
        return {
            carrierGroupId
        };
    }

    /* istanbul ignore next */
    static getDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.details,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSearchAcceptingRequest(name, page)
    {
        return {
            name,
            page
        };
    }

    /* istanbul ignore next */
    static searchAccepting({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.join.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getToggleAcceptingJoinsRequest(carrierGroupId, allowJoinGroupRequests)
    {
        return {
            carrierGroupId,
            allowJoinGroupRequests
        };
    }

    /* istanbul ignore next */
    static toggleAcceptingJoins({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.join.toggle,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getToggleAcceptingDeliveriesRequest(carrierGroupId, isAcceptingDeliveries)
    {
        return {
            carrierGroupId,
            isAcceptingDeliveries
        };
    }

    /* istanbul ignore next */
    static toggleAcceptingDeliveries({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.acceptDeliveries,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListBidQueueRequest(carrierGroupId, page)
    {
        return {
            carrierGroupId,
            page
        };
    }

    /* istanbul ignore next */
    static listBidQueue({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.queue.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDeclineBidQueueRequest(carrierQueueId, carrierGroupId)
    {
        return {
            carrierQueueId,
            carrierGroupId
        };
    }

    /* istanbul ignore next */
    static declineBidQueue({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.queue.decline,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default CarrierGroupService;
