import React from 'react';
import theme from './../../../../theme';
import CurrencyService from './../../../../../service/currency';
import Mpg from './mpg';
import GasPrice from './gas-price';

const CostEstimates = ({
    hasTrips,
    useMpgGlobal,
    onToggleUseMpgGlobal,
    estimatedMPG,
    mpgBuffer,
    onMpgBufferUpdated,
    mpgBufferValid,
    tripCost,
    useGasPriceGlobal,
    onToggleUseGasPriceGlobal,
    estimatedDollarsPerGallon,
    gasPriceBuffer,
    onGasPriceBufferUpdated,
    gasPriceBufferValid
}) => 
    <div style={{
        ...theme.getLocationMailingAddressStyle(theme)
    }}>

        {
            hasTrips && 
                <div>
                    <h4>
                        Cost Estimates
                    </h4>
                    
                    <Mpg 
                        useMpgGlobal={useMpgGlobal}
                        onToggleUseMpgGlobal={onToggleUseMpgGlobal}
                        estimatedMPG={estimatedMPG}
                        mpgBuffer={mpgBuffer}
                        onMpgBufferUpdated={onMpgBufferUpdated}
                        mpgBufferValid={mpgBufferValid}
                    />

                    <GasPrice
                        useGasPriceGlobal={useGasPriceGlobal}
                        onToggleUseGasPriceGlobal={onToggleUseGasPriceGlobal}
                        estimatedDollarsPerGallon={estimatedDollarsPerGallon}
                        gasPriceBuffer={gasPriceBuffer}
                        onGasPriceBufferUpdated={onGasPriceBufferUpdated}
                        gasPriceBufferValid={gasPriceBufferValid}
                    />
                    
                    
                    <div>
                        { `Estimated Fuel Cost: ${CurrencyService.usd(tripCost, false)}` }
                    </div>
                </div>
        }
    </div>

export default CostEstimates;
