import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../theme';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../service/resize';

const shortcutList1 = [
    {
        title: 'Search Offers',
        url: '/offers',
        details: 'Shop items and services'
    },
    {
        title: 'Add an Offer',
        url: '/offer/add',
        details: 'Offer an item or service'
    }
];
const shortcutList2 = [
    {
        title: 'Search Seeks',
        url: '/seeks',
        details: 'Fulfill items or services'
    },
    {
        title: 'Add a Seek',
        url: '/seek/add',
        details: 'Request an item or service'
    }
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getPostsShortCutContainerStyle(theme),  
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const PostsLandingPanel = (parms) => {
    const getPostsContainerStyle = () => theme.getPostsContainerStyle(theme);
    const [ postsContainerStyle, setPostsContainerStyle ] = useState( getPostsContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPostsContainerStyle( getPostsContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={postsContainerStyle}>
            <div style={{
                ...theme.getPostsShortCutOuterContainerStyle(theme),  
            }}>
            <h2 style={{ 
                ...theme.getGeneralTextStyle(theme),
            }}>
                Offers
            </h2>

            <h4>
                Search through and bid on items &#38; services offered by bears.exchange members.
                <br/>
                Or offer items &#38; services to other members.
            </h4>

            {
                shortcutList1.map((cut, index) => <Shortcut details={cut.details} title={cut.title} url={cut.url} key={index} />)
            }

            </div>
        
        
            
            <div style={{
                ...theme.getPostsShortCutOuterContainerStyle(theme),  
            }}>
            <h2 style={{ 
                ...theme.getGeneralTextStyle(theme),
            }}>
                Seeks
            </h2>
            <h4>
                Search through and bid on requests for items &#38; services sought after by bears.exchange members. 
                <br/>
                Or post a request for items &#38; services from other members.
            </h4>

            {
                shortcutList2.map((cut, index) => <Shortcut details={cut.details} title={cut.title} url={cut.url} key={index} />)
            }

            </div>
            
        </div>
    );
};

export default PostsLandingPanel;