import * as notice from './notice';
import * as iconButton from './icon-button';
import * as availableWidget from './available-widget';
import * as userWidget from './user-widget';
import * as generalText from './general-text';
import * as userSearch from './user-search';
import * as seekSearch from './seek-search';
import * as offerSearch from './offer-search';
import * as flag from './flag';
import * as attributeDetail from './attribute-detail';
import * as generalButton from './general-button';
import * as loading from './loading';
import * as exchangeSearch from './exchange-search';
import * as searchPrompt from './search-prompt';
import * as pagination from './pagination';
import * as obtainType from './obtain-type';
import * as imageList from './image-list';
import * as exchangeDetail from './exchange-detail';
import * as abstract from './abstract';
import * as bid from './bid';
import * as seek from './seek';
import * as offer from './offer';
import * as login from './login';
import * as userSeeks from './user-seeks';
import * as userOffers from './user-offers';
import * as widgetMyArchivedSeeks from './widget-my-archived-seeks';
import * as widgetMyArchivedOffers from './widget-my-archived-offers';
import * as widgetMyActiveSeeks from './widget-my-active-seeks';
import * as widgetMyActiveOffers from './widget-my-active-offers';
import * as help from './help';
import * as userInvites from './user-invites';
import * as userDetail from './user-detail';
import * as trails from './trails';
import * as exchangeChat from './exchange-chat';
import * as errorTheme from './error';
import * as profileTrails from './profile-trails';
import * as trackSteps from './track-steps';
import * as img from './img';
import * as exchangeList from './exchange-list';
import * as attributeList from './attribute-list';
import * as bidDetail from './bid-detail';
import * as reportedIssues from './reported-issues';
import * as adminUserDetail from './admin-user-detail';
import * as terms from './terms';
import * as menu from './menu';
import * as news from './news';
import * as page from './page';
import * as widgetExchanges from './widget-exchanges';
import * as widgetFriends from './widget-friends';
import * as logo from './logo';
import * as posts from './posts';
import * as unsubscribe from './unsubscribe';
import * as userReviews from './user-reviews';
import * as exchangeReview from './exchange-review';
import * as userReviewHistory from './user-review-history';
import * as twoFA from './two-fa';
import * as profilePwd from './profile-pwd';
import * as widgetPendingBids from './widget-pending-bids';
import * as adminAddTag from './admin-add-tag';
import * as tagList from './tag-list';
import * as tagFilter from './tag-filter';
import * as userSettings from './user-settings';
import * as exchangesLanding from './exchanges-landing';
import * as payouts from './payouts';
import * as paymentMethods from './payment-methods';
import * as addPayMethod from './add-pay-method';
import * as pay from './pay';
import * as onlineTransactionsList from './online-transactions-list';
import * as addOnlinePaymentExchangePrompt from './add-online-payment-exchange-prompt';
import * as adminPayoutsSearch from './admin-payouts-search';
import * as searchBxe from './search-bxe';
import * as payNewCard from './pay-new-card';
import * as bidOnlinePayment from './bid-online-payment';
import * as bidReviewer from './bid-reviewer';
import * as profileStore from './profile-store';
import * as profilePeople from './profile-people';
import * as profileInfo from './profile-info';
import * as subscription from './subscription';
import * as fees from './fees';
import * as adminDisputeSearch from './admin-dispute-search';
import * as profileIncomeLanding from './profile-income-landing';
import * as adminLedgerSummary from './admin-ledger-summary';
import * as adminData from './admin-data';
import * as onlineTransactionDetail from './online-transaction-detail';
import * as refundPolicy from './refund-policy';
import * as profileShopTraitTabs from './profile-shop-trait-tabs';
import * as sortList from './sort-list';
import * as author from './author';
import * as addUspsPaymentExchangePrompt from './add-usps-payment-exchange-prompt';
import * as locations from './locations';
import * as map from './map';
import * as carrierGroupDetails from './carrier-group-details';
import * as button from './button';
import * as reviewHistory from './review-history';
import * as dialog from './dialog';
import * as myFields from './my-fields';
import * as networkSpeed from './network-speed.js';
import * as disconnected from './disconnected.js';
import * as onboarding from './onboarding';
export { default as BxScaleText } from './bx-scale-text';

const phoneDevicePattern = "(min-width: 435px)";
const tabletDevicePattern = "(min-width: 768px)";
const desktopDevicePattern = "(min-width: 992px)";
const desktopXLDevicePattern = "(min-width: 1200px)";
const desktopEXTDevicePattern = "(min-width: 1440px)";

// breakpoints - note, resize screen issue needs to be delt with.
const isPhone = () => window.matchMedia(phoneDevicePattern).matches;
const isTablet = () => window.matchMedia(tabletDevicePattern).matches;
const isDesktop = () => window.matchMedia(desktopDevicePattern).matches;
const isDesktopXL = () => window.matchMedia(desktopXLDevicePattern).matches;
const isDesktopEXT = () => window.matchMedia(desktopEXTDevicePattern).matches;

const theme = {
    disabledFocusColor: '#808080', // disabled buttons
    focusColor: '#101010', // buttons and links focus Color
    primaryColor: '#f9f8f5', // background
    secondaryColor: '#2A2A2A', // text / dark icons
    panelColor: '#F0F0F0', // cool background for panels 
    navColor: '#454545', // background for nav
    navMiniColor: 'rgba(27, 27, 27, .86)', // background for small nav 
    navTextColor: '#F4F4F5', // nav text color 
    panelSecondary:  '#7F7F7F', // accent for panels 
    buttonColor:  '#FDFDFD', // background for buttons 
    selectColorLight: '#F4F4F4', // color of icons and selected buttons in light theme
    warningColor: '#FF0232', // warning text
    selectedTextColor: '#F4F4F5', // text color white
    positiveRatingColor: '#05925E', // positive user rating color
    selectedMenuColor: '#101010', // Current menu item
    panelWarmColor: '#EEEAEA', // warm background for panels
    shadowColor: '#1d1d1d', //shadow color
    backgroundOverlayLight: 'rgba(255, 255, 255, .72)', //shadow color
    tabletDevicePattern,
    desktopDevicePattern,
    desktopXLDevicePattern,
    desktopEXTDevicePattern,
    isPhone,
    isTablet,
    isDesktop,
    isDesktopXL,
    isDesktopEXT,
    ...notice,
    ...iconButton,
    ...availableWidget,
    ...userWidget,
    ...generalText,
    ...userSearch,
    ...seekSearch,
    ...offerSearch,
    ...flag,
    ...attributeDetail,
    ...generalButton,
    ...loading,
    ...exchangeSearch,
    ...searchPrompt,
    ...pagination,
    ...obtainType,
    ...imageList,
    ...exchangeDetail,
    ...abstract,
    ...bid,
    ...seek,
    ...offer,
    ...login,
    ...userSeeks,
    ...userOffers,
    ...widgetMyArchivedSeeks,
    ...widgetMyArchivedOffers,
    ...widgetMyActiveSeeks,
    ...widgetMyActiveOffers,
    ...help,
    ...userInvites,
    ...userDetail,
    ...trails,
    ...exchangeChat,
    ...errorTheme,
    ...profileTrails,
    ...trackSteps,
    ...img,
    ...exchangeList,
    ...attributeList,
    ...bidDetail,
    ...reportedIssues,
    ...adminUserDetail,
    ...terms,
    ...menu,
    ...news,
    ...page,
    ...widgetExchanges,
    ...widgetFriends,
    ...logo,
    ...posts,
    ...unsubscribe,
    ...userReviews,
    ...exchangeReview,
    ...userReviewHistory,
    ...twoFA,
    ...profilePwd,
    ...widgetPendingBids,
    ...adminAddTag,
    ...tagList,
    ...tagFilter,
    ...userSettings,
    ...exchangesLanding,
    ...payouts,
    ...paymentMethods,
    ...addPayMethod,
    ...pay,
    ...onlineTransactionsList,
    ...addOnlinePaymentExchangePrompt,
    ...adminPayoutsSearch,
    ...searchBxe,
    ...payNewCard,
    ...bidOnlinePayment,
    ...bidReviewer,
    ...profileStore,
    ...profilePeople,
    ...profileInfo,
    ...subscription,
    ...fees,
    ...adminDisputeSearch,
    ...profileIncomeLanding,
    ...adminLedgerSummary,
    ...adminData,
    ...onlineTransactionDetail,
    ...refundPolicy,
    ...profileShopTraitTabs,
    ...sortList,
    ...author,
    ...addUspsPaymentExchangePrompt,
    ...locations,
    ...map,
    ...carrierGroupDetails,
    ...button,
    ...reviewHistory,
    ...dialog,
    ...myFields,
    ...networkSpeed,
    ...disconnected,
    ...onboarding
};

export default theme;
