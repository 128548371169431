import React from 'react';
import DetailPanel from './../core/detail';
import AddPayMethodViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import PayService from '../../../service/pay';

class _AddPayMethodPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);
        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onSetPayClientSecret = this.onSetPayClientSecret.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({ });
        PayService.addPayMethodSecret({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData();
    }

    /* istanbul ignore next */
    getLoadedDataDetailUi()
    {
        return this.props.payClientDetails && this.props.payClientDetails.type !== '' ? 
            <AddPayMethodViewPanel data={this.state.data} /> :
                <div>
                    Loading
                </div>
        
    }

    onLoadDataResponse(response)
    {
        this.onSetPayClientSecret(response);
        super.onLoadDataResponse(response);
    }

    /* istanbul ignore next */
    onSetPayClientSecret(response)
    {
        if(this.props.setPayClientSecret)
        {
            this.props.setPayClientSecret(response.clientSecret, response.clientDetails);
        }
    }
}

const AddPayMethodPanel = connect(mapStateToProps, mapDispatchToProps)(_AddPayMethodPanel);
export default AddPayMethodPanel;
