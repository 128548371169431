import React from 'react';
import theme from './../../../theme';
import SelectMember from './select-member';
import SelectDestinationPanel from './select-destination';
import { 
    evalSteps,
    getStepPromptCopy,
    buildOnMemberSelected,
    buildOnDestinationSelected
} from './../core';
import {
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT
} from './../../../prompt/core/enum/carrier-group-delivery-plan-status';

const DefineStep = ({
    steps,
    delivery,
    plan,
    sortedMembers,
    onAddStep,
    carrierGroupId,
    onCompleteDraftStatus
}) => {
        
    const {
        shouldPromptStep,
        shouldPromptSelectMember,
        shouldPromptSelectDestination,
    } = evalSteps(steps);

    const onDestinationSelected = buildOnDestinationSelected(steps, delivery, onAddStep);
    const onMemberSelected = buildOnMemberSelected(steps, delivery, onAddStep);

    return shouldPromptStep ? 
        <div>
            <div style={{
                display: 'flex',
            }}>
                
                <div style={{
                    ...theme.getLocationMailingAddressStyle(theme),
                    flexDirection: 'column',
                    marginRight: '20px'
                }}>
                    { getStepPromptCopy(steps, delivery) }
                </div>

                

                <div style={{
                    ...theme.getGeneralTextStyle(theme),
                    flexDirection: 'column'
                }}>

                    {
                        shouldPromptSelectMember &&  
                            <div>
                                <p>
                                    Which member will do this?
                                </p>

                                <SelectMember
                                    sortedMembers={sortedMembers}
                                    onMemberSelected={onMemberSelected}
                                />
                            </div>
                    }

                    {
                        shouldPromptSelectDestination && 
                            <div>
                                <p>
                                    Where will this package be dropped off?
                                </p>

                                <SelectDestinationPanel
                                    onDestinationSelected={onDestinationSelected}
                                    steps={steps}
                                    delivery={delivery}
                                    carrierGroupId={carrierGroupId}
                                />
                            </div>
                    }


                </div>
            </div>

            <hr />

        </div> : (
            plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT ? 
                <div>
                    <button 
                        className="test-carrierGroupPlanDeliveryPlanDeliveryDefineStepAlignToTripsBtn"
                        onClick={event => { onCompleteDraftStatus() }}
                    >
                        Align delivery plan to trips
                    </button>
                </div> : ''
        );
};

export default DefineStep;
