import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import FeesPanel from './../ui/panel/fees';

const FeesPage = () => 
    <AuthPageTemplate childMenu={ 
        <ProfileMenu />
    }>
        <FeesPanel />
    </AuthPageTemplate>

export default FeesPage;
