import React from 'react';
import RatingService from './../../../service/rating';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import theme from './../../theme';
import goodIcon from './../../../img/good.svg';
import badIcon from './../../../img/bad.svg';

class _ExchangeReviewPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _ExchangeReviewPrompt.getValidators());

        this.getDefaultState = this.getDefaultState.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.openDetailsView = this.openDetailsView.bind(this);
        this.closeDetailsView = this.closeDetailsView.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getTypeOfExperienceUi = this.getTypeOfExperienceUi.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getExperienceDetails = this.getExperienceDetails.bind(this);
    }

    static getValidators()
    {
        return {
            isPositive: {
                isValid: ValidateService.anyValue,
                isVisible: (state) => false
            },
            details: {
                isValid: ValidateService.valueRequired
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            isPositive: {
                name: 'Positive Experience',
                error: 'Specify the type of experience',
                value: true,
                visible: false, // hide and use alt UX
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            details: {
                name: 'Details',
                error: 'Please provide details about your experience',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            }
        }
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            detailsViewOpen: false,
        };
    }

    getServerRequest()
    {
        return {
            exchangeId: this.props.exchange.id,
            isPositive: this.state.isPositive.value,
            details: this.state.details.value
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.props.onReviewed(response);
        this.setState(nextState);     
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        RatingService.addUserRating({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getConfirmUi(confirmMessage = 'Your feedback has been shared')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Publish Feedback')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    openDetailsView(isPositive)
    {
        const nextState = {
            ...this.state,
            detailsViewOpen: true,
        };
        nextState.isPositive.value = isPositive;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    closeDetailsView()
    {
        const nextState = {
            ...this.state,
            detailsViewOpen: false,
        };
        this.setState(nextState);
    }

    getTypeOfExperienceUi()
    {
        return (
            <section>
                <h2>
                    How was your experience?
                </h2>
                <div style={{
                    ...theme.getExchangeReviewExperienceBar(theme)  
                }}>
                    <div 
                        style={{
                        ...theme.getExchangeReviewExperienceButton(theme, goodIcon),
                        }}
                        className="test-exchangeReviewGoodBtn"
                        onClick={event => { this.openDetailsView(true) }}
                    ></div>
                    <div 
                        style={{
                            ...theme.getExchangeReviewExperienceButton(theme, badIcon),
                        }}
                        className="test-exchangeReviewBadBtn"
                        onClick={event => { this.openDetailsView(false) }}
                    ></div>
                </div>
            </section>
        );
    }

    getExperienceDetails()
    {
        return (
            <section>
                <p style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    { 
                        this.state.isPositive.value ? 'Please share details of your experience' : 
                            <span>
                                Please share details of your experience.  
                                <br />
                                How could your experience be improved?
                            </span>
                    }
                </p>

                { super.getPromptUi() }

            </section>
        );
    }

    getPromptUi()
    {
        return this.state.detailsViewOpen ? this.getExperienceDetails() : this.getTypeOfExperienceUi();
    }
}

const ExchangeReviewPrompt = connect(mapStateToProps, mapDispatchToProps)(_ExchangeReviewPrompt);
export default ExchangeReviewPrompt;
