import React from 'react';
import theme from './../../../../theme';
import { NavLink } from 'react-router-dom';

// istanbul ignore next 
const TrackPricingJobView = ({
    data 
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)  
    }}>
    
        {
            data.isFailed ? 
                <p>
                    A job has failed.  We have already notified staff.  
                    <NavLink to="/profile/report/issue">Follow up or provide more details here</NavLink>
                </p> : (
                
                    !data.jobRequested ? 
                        <p>
                            awaiting cost estimates request...
                        </p> : (

                            data.isPending ? 
                                <p>
                                    cost estimates are pending processing...
                                </p> : (
                                    data.isCompleted ? 
                                        <p>
                                            Processing Completed
                                        </p> : 
                                            <p>
                                                cost estimates are processing...
                                            </p>    
                                )

                )
            )
        }
        
    </div>

export default TrackPricingJobView;
