import LocationService from './../../../service/location';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';

class _UserCorrectGeoLocationPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _UserCorrectGeoLocationPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.state = this.getDefaultState();

    }

    static getValidators()
    {
        return {
            latitude: {
                isValid: ValidateService.latitude
            },
            longitude: {
                isValid: ValidateService.longitude
            },
            anonLatitude: {
                isValid: ValidateService.latitude
            },
            anonLongitude: {
                isValid: ValidateService.longitude
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            latitude: {
                name: 'Latitude',
                error: 'Provide the location latitude',
                value: this.props.latitude,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            longitude: {
                name: 'Longitude',
                error: 'Provide the location longitude',
                value: this.props.longitude,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            anonLatitude: {
                name: 'Anonymous Latitude',
                error: 'Provide the city level latitude',
                value: this.props.anonLatitude,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            anonLongitude: {
                name: 'Anonymous Longitude',
                error: 'Provide the city level longitude',
                value: this.props.anonLongitude,
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    getServerRequest()
    {
        return {
            locationId: this.props.locationId,
            latitude: this.state.latitude.value,
            longitude: this.state.longitude.value,
            anonLatitude: this.state.anonLatitude.value,
            anonLongitude: this.state.anonLongitude.value,
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
        if(this.props.onRepaired)
        {
            this.props.onRepaired(response);
        }
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnResponse(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        LocationService.userCorrectGeoLocation({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getConfirmUi(confirmMessage = 'Location updated')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Update Location')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const UserCorrectGeoLocationPrompt = connect(mapStateToProps, mapDispatchToProps)(_UserCorrectGeoLocationPrompt);
export default UserCorrectGeoLocationPrompt;
