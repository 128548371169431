import React from 'react';
import AuthPageTemplate from './template/auth-page';
import OfferDetailPanel from './../ui/panel/offer-detail';
import PayNewCardPrompt from './../ui/prompt/pay-new-card';
import PostsMenu from './../ui/menu/posts';
import { useParams } from 'react-router-dom';
import config from './../config';

const OfferDetailPage = ({ setPayClientSecret, payClientDetails, payClientSecret }) => {
    const { offerId } = useParams();
    return (
        <AuthPageTemplate childMenu={ <PostsMenu /> }>
            {
                payClientDetails && payClientDetails.type !== '' ? 
                    <PayNewCardPrompt 
                        onSuccessRedirectUrl={config.pay.paymentNewCompletedUrl}
                        payClientSecret={payClientSecret} 
                        payClientDetails={payClientDetails} 
                        setPayClientSecret={setPayClientSecret}  
                    /> :
                        <OfferDetailPanel 
                            setPayClientSecret={setPayClientSecret} 
                            offerId={offerId} 
                        />
            }
        </AuthPageTemplate>
    );
};

export default OfferDetailPage;
