import React from 'react';
import theme from './../../theme';
import LocationService from './../../../service/location';
import LocationMapPanel from './../location-map';

const OnSitePanel = ({ location }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)  
    }}>
        <h3>
            On Site Details
        </h3>
        <p>
            Make sure to chat with each other and agree on specific on site time(s).
        </p>

        <div style={{
        ...theme.getLocationMailingAddressStyle(theme)  
        }}>
            <p>
                {
                    LocationService.displayMailingAddress(location)
                }
            </p>
        </div>

        <LocationMapPanel selectedLocation={location} />
    </div>

export default OnSitePanel;
