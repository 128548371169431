import React from 'react';
import AdminPageTemplate from './template/admin-page';
import SearchBxePrompt from './../ui/prompt/admin/search-bxe';
import theme from './../ui/theme';

const AdminBxePage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            Bx Events
        </h2>
        <SearchBxePrompt />
    </AdminPageTemplate>

export default AdminBxePage;
