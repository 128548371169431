export const getAdminPayoutsSearchContainerStyle = (theme) => ({
    textAlign: 'center',
    width: '750px',
    marginLeft: 'auto',
    marginRight: 'auto'
});

export const getAdminPayoutsSearchDetailModalStyle = (theme) => ({
    overflow: 'scroll',
    height: '635px',
    width: '580px',
    textAlign: 'center',
    paddingTop: '15px',
    paddingLeft: '5px',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor
});

export const getAdminPayoutsSearchDetailModelSummaryStyle = (theme) => ({
    textAlign: 'left'
});

export const getAdminPayoutsSearchDetailsModalApiDataDumpStyle = (theme) => ({
    textAlign: 'left'
});

export const getAdminPayoutsStatusButtonStyle = (theme) => ({
    cursor: 'pointer',
    textDecorationLine: 'underline'
});