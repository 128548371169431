import PayService from './../../../service/pay';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import ValidateService from './../../../service/validate';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';

class _EditPaymentMethodMetaDataPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _EditPaymentMethodMetaDataPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    static getValidators()
    {
        return {
            nickname: {
                isValid: ValidateService.textLine
            },
            isDefault: {
                isValid: ValidateService.anyValue
            }
        };
    }

    getFieldsModel()
    {
        return {
            nickname: {
                name: 'Nickname',
                error: 'Provide a nickame for this card, no more than 112 characters',
                value: this.props.card.nickname,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            isDefault: {
                name: 'Default Payment Method',
                error: 'Set as default payment method for charges',
                value: this.props.card.isDefault,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields
        };
    }

    /* istanbul ignore next */
    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);

        if(this.props.closeDialog)
        {
            this.props.closeDialog(response);
        }
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        PayService.updatePaymentMethod({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getSubmitButtonUi(buttonText = 'Save')
    {
        return this.state.isDefault.value !== this.props.card.isDefault || this.state.nickname.value !== this.props.card.nickname ? super.getSubmitButtonUi(buttonText) : '';
    }

    getServerRequest()
    {
        return {
            isDefault: this.state.isDefault.value,
            nickname: this.state.nickname.value,
            paymentMethodId: this.props.card.id
        };
    }
}

const EditPaymentMethodMetaDataPrompt = connect(mapStateToProps, mapDispatchToProps)(_EditPaymentMethodMetaDataPrompt);
export default EditPaymentMethodMetaDataPrompt;
