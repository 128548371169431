import React from 'react';
import DetailPanel from './../core/detail';
import PayService from './../../../service/pay';
import UrlService from './../../../service/url';
import ProfileIncomeLandingViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import config from './../../../config';

class _ProfileIncomeLandingPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onAccountCreated = this.onAccountCreated.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({  });
        PayService.accountDetails({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        setTimeout(
            /* istanbul ignore next */
            () => {
                const tester = /paydone/i;
                UrlService.correctUrl(tester, config.pay.onboardingCompletedUrl, true);
            }, 
            config.pay.reloadDelay
        );
        
        this.loadData();
    }

    getLoadedDataDetailUi()
    {
        return <ProfileIncomeLandingViewPanel data={this.state.data} onAccountCreated={this.onAccountCreated} />
    }

    /* istanbul ignore next */
    onAccountCreated(response)
    {
        if(response.redirectUrl)
        {
            window.location = response.redirectUrl;
        }
        else
        {
            this.onLoadDataResponse(response);
        }
    }
}

const ProfileIncomeLandingPanel = connect(mapStateToProps, mapDispatchToProps)(_ProfileIncomeLandingPanel);
export default ProfileIncomeLandingPanel;

