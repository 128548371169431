export const getSubscriptionActiveCancelModalContainerStyle = (theme) => ({
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getSubscriptionActiveCancelModalButtonContainerStyle = (theme) => ({
    paddingBottom: '15px'
});

export const getConfirmCancelSubscriptionTextStyle = (theme) => ({
    textAlign: 'center',
    fontWeight: '750',
    fontSize: '11pt',
    lineHeight: '.9rem',
    margin: '30px .75rem .75rem .75rem',
});
