import React from 'react';
import AuthService from './../../service/auth';
import ValidateService from './../../service/validate';
import mapStateToProps from '../../store/map/device-is-online';
import mapDispatchToProps from '../../store/map/on-update-device-online';
import { connect } from 'react-redux';
import { FORM_FIELD, AbstractPrompt } from './core/abstract';
import LoginPrompt from './login';
import theme from './../theme';

class _ForgotPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, ForgotPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
    }

    static getValidators()
    {
        return {
            email: {
                isValid: ValidateService.email
            }
        };
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            email: {
                name: 'Email*',
                error: 'Provide your account email',
                value: '',
                visible: true,
                valid: false,
                touched: false, 
                type: FORM_FIELD.TEXT
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onSendToServer()
    {
        AuthService.forgot({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            email: this.state.email.value
        };
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    getPromptUi()
    {
        return (
            <div style={{
                ...theme.getForgotPwdPromptStyle(theme),
            }}>
                <h2>
                    Forgot Your Password?
                </h2>
                <p>
                    Provide your email to reset your password.
                </p>
                { super.getPromptUi() }
            </div>
        );
    }

    getSubmitButtonUi(buttonText = 'Reset Password')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getConfirmUi(confirmMessage = 'Password Updated')
    {
        return (
            <section>
                { super.getConfirmUi(confirmMessage) }
                <p style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    Check your inbox for instructions on how to reset your password.
                </p>
                <LoginPrompt />
            </section>
        );
    }

}

const ForgotPrompt = connect(mapStateToProps, mapDispatchToProps)(_ForgotPrompt);
export default ForgotPrompt;
