import React from 'react';
import PlanPricingConfirmation from './plan-pricing-confirmation';


const PlanPricingConfirmations = ({
    price,
    plan,
    members
}) => {
    const filterViewer = m => plan.lockedForEditByUserId === m.userId;
    const filterNotViewer = m => plan.lockedForEditByUserId !== m.userId;
    const filterUnConfirmedViewer = m => plan.lockedForEditByUserId === m.userId && !m.confirmed;
    const viewerMustConfirm = price
        .memberContributionList
        .filter(filterUnConfirmedViewer).length > 0 ? true : false;
    
    const withoutViewerList = price
        .memberContributionList
        .filter(filterNotViewer);
    const viewerList = price
        .memberContributionList
        .filter(filterViewer);
    return (
        <div>
            <h3>
                Confirmations
            </h3>
            
            {
                viewerMustConfirm && 
                    <div>
                        <h3>
                            You must confirm this job to proceed.
                        </h3>
                        
                    </div>
            }

            {
                viewerList.map((mem, index) => 
                    
                    <PlanPricingConfirmation 
                        plan={plan}
                        key={index}
                        members={members}
                        memberContribution={mem}
                    />
                )
            }

            {
                withoutViewerList.map((mem, index) => 
                    
                    <PlanPricingConfirmation 
                        plan={plan}
                        key={index}
                        members={members}
                        memberContribution={mem}
                    />
                )
            }
        </div>
    );
};

export default PlanPricingConfirmations;