export const addOfferStepPanelStyle = (theme) => ({
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    marginBottom: '2rem',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
});

export const getOfferDetailViewPanelStyle = (theme) => ({
    maxWidth: theme.isDesktop() ? '60rem' : '100%',
    marginBottom: '2rem',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
});

export const getOfferDetailViewHeaderEndStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
});

export const getOfferDetailViewHeaderStartStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
});

export const getOfferDetailsBodyStyle = (theme) => ({
    textAlign: 'left',
    margin: '.5rem .75rem',
    padding: '.5rem',
    borderRadius: '.25rem',
    height: 'fit-content',
    backgroundColor: theme.primaryColor
});

export const getOfferDetailViewBodyStyle = (theme) => ({
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    borderRadius: '.25rem',
    height: 'fit-content',
    backgroundColor: theme.panelWarmColor,
    paddingBottom: '1rem'
});

export const getOfferDetailViewGroupStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '.5rem .75rem 0',
});

export const getOfferDetailViewSubHeaderStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '.5rem .75rem 0',
    flexDirection: theme.isTablet() ? 'row' : 'column'
});

export const getOfferDetailViewAdjustingWidthStyle = (theme) => ({
    width: theme.isTablet() ? '30%' : '100%'
});

export const getAddOfferTextFieldStyle = (theme) => ({
    height: '2.25rem',
    width: theme.isTablet() ? '100%' : 'calc(100% - 1.5rem)',
    maxWidth: '26rem',
    paddingLeft: '1rem',
    marginBottom: '.75rem'

});

export const getAddOfferConfirmContainerStyle = (theme) => ({
    backgroundColor: theme.panelWarmColor,
    borderRadius: '.25rem',
    margin: '1rem 0',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    maxWidth: '60rem',
    textAlign: 'center',
    display: 'inline-block',
    boxShadow: 'rgba(29, 29, 29, 0.5) 0px 0.1rem 0.1rem',
    width: 'fit-content'
});

export const getAddOfferFilterPageContainerStyle = (theme) => ({
    width: theme.isTablet() ? '26rem' : '100%',
    margin: '0 auto',
});