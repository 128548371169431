import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class LocationService 
{
    static getAddRequest(
        type,
        latitude,
        longitude,
        countryCode,
        countryName,
        pointOfContact,
        street,
        streetLine2,
        city,
        shortState,
        state,
        zipCode,
        isDefault,
        hasTruckDocks,
        hasLimitedAccess,
        notes
    ) {
        return {
            type,
            latitude,
            longitude,
            countryCode,
            countryName,
            pointOfContact,
            street,
            streetLine2,
            city,
            shortState,
            state,
            zipCode,
            isDefault,
            hasTruckDocks,
            hasLimitedAccess,
            notes
        };
    }

    /* istanbul ignore next */
    static add({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveNoticeRequest(userLocationId, userLocationNoticeId)
    {
        return {
            userLocationId,
            userLocationNoticeId
        };
    }

    /* istanbul ignore next */
    static removeNotice({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.removeNotice,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getGetLocationRequest(locationId)
    {
        return {
            locationId
        };
    }

    /* istanbul ignore next */
    static getLocation({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.get,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListRequest(page = 0)
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static list({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.list,
            request: this.getListRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveRequest(userLocationId)
    {
        return {
            userLocationId
        };
    }

    /* istanbul ignore next */
    static remove({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.remove,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSetAsDefaultRequest(userLocationId)
    {
        return {
            userLocationId
        };
    }

    /* istanbul ignore next */
    static setAsDefault({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.setAsDefault,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminSearchFailedGeoLocationsRequest(page)
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static adminSearchFailedGeoLocations({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.failedGeoLocations,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAdminRepairGeoLocationRequest(locationId, latitude, longitude, anonLatitude, anonLongitude)
    {
        return {
            locationId,
            latitude,
            longitude,
            anonLatitude,
            anonLongitude
        };
    }

    /* istanbul ignore next */
    static adminRepairGeoLocation({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.repairGeoLocation,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUserRepairGeoLocationRequest(locationId, latitude, longitude, anonLatitude, anonLongitude)
    {
        return {
            locationId,
            latitude,
            longitude,
            anonLatitude,
            anonLongitude
        };
    }

    /* istanbul ignore next */
    static userRepairGeoLocation({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.repair,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    /* istanbul ignore next */
    static userCorrectGeoLocation({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.location.correct,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static displayMailingAddress(loc)
    {
        if(loc.mailingAddress)
        {
            return loc.mailingAddress;
        }

        const state = loc.shortState ? loc.shortState : loc.state;

        if(loc.streetLine2 !== '')
        {
            return `${loc.pointOfContact}
${loc.street}
${loc.streetLine2}
${loc.city}, ${state} ${loc.zipCode}
${loc.countryCode}`;
        }

        return `${loc.pointOfContact}
${loc.street}
${loc.city}, ${state} ${loc.zipCode}
${loc.countryCode}`;
    }
}

export default LocationService;
