import React from 'react';

export const LearnMores = ({
    updateCrawler,
    moreOptions = []
}) => moreOptions.length < 1 ? '' :
    <ul>
        {
            moreOptions.map((option, index) => {
                return (
                    <li key={index}>
                        <button 
                            className="test-OnboardingMoreBtn"
                            onClick={
                                event => {
                                    updateCrawler(option.more);
                                }
                            }
                        >
                            { option.title }
                        </button>
                    </li>
                );
            })
        }
    </ul>

const GeneralRenderType = ({
    selectedFrame,
    updateCrawler,
    title,
    details, 
    moreOptions = []
}) => 
    <div>
        <h2>
            { title }
        </h2>
        
        {
            Array.isArray(details) ? 
                details.map((aDetail, index) => 
                    <p key={index}>
                        { aDetail }
                    </p>
                ) : 
                    <p>
                        { details }
                    </p>
        }
        
        <LearnMores 
            updateCrawler={updateCrawler}
            moreOptions={moreOptions}
        />

    </div>

export default GeneralRenderType;
