import React from 'react';
import BxEventService from './../../../../service/bx-event';
import { FORM_FIELD } from './../../core/abstract';
import ValidateService from './../../../../service/validate';
import BxEventContextEnum from './../../core/enum/bx-event-context';
import BxEventTypeEnum from './../../core/enum/bx-event-type';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../../core/search';
import SearchBxeViewPrompt, { Header } from './view';
import theme from './../../../theme';
import IpEventTypeEnum from '../../core/enum/ip-event-type';

class _SearchBxePrompt extends SearchPrompt 
{
    constructor(parms)
    {
        super(parms, _SearchBxePrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.onRescheduleEvent = this.onRescheduleEvent.bind(this);
        this.getRescheduleEventRequest = this.getRescheduleEventRequest.bind(this);
        this.onDeleteJob = this.onDeleteJob.bind(this);
        this.getDeleteJobRequest = this.getDeleteJobRequest.bind(this);
    }

    static getValidators()
    {
        return {
            context: {
                isValid: BxEventContextEnum.isValid
            },
            type: {
                isValid: ValidateService.anyValue // BxEventTypeEnum.isValid
            },
            isPending: {
                isValid: ValidateService.anyValue
            },
            isFailed: {
                isValid: ValidateService.anyValue
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getSubmitButtonUi(buttonText = 'Search')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getFieldsModel()
    {
        return {
            context: {
                name: 'Event Context',
                error: 'Specify a event context',
                value: BxEventContextEnum.BX_EVENT_CONTEXT_PAY,
                valueList: BxEventContextEnum.getDropDownValues(),
                visible: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            type: {
                name: 'Event Type',
                error: 'Specify an event type',
                value: 'all',
                valueList: [
                    {
                        name: 'All',
                        value: 'all'
                    },
                    ...BxEventTypeEnum.getDropDownValues(),
                    ...IpEventTypeEnum.getDropDownValues(),
                ],
                visible: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            isPending: {
                name: 'Pending',
                error: 'Filter for pending / procssed events',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            isFailed: {
                name: 'Failed',
                error: 'Filter for failed events',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
        };
    }

    /* istanbul ignore next */
    componentDidUpdate(prevProps, prevState)
    {
        if(this.props.page !== prevProps.page)
        {
            this.setContactingServer(true);
            this.onSendToServer({
                isPending: this.state.isPending.value ? this.state.isPending.value : undefined,
                isFailed: this.state.isFailed.value ? this.state.isFailed.value : undefined,
                context: this.state.context.value ? this.state.context.value : undefined,
                type: this.state.type.value && this.state.type.value !== 'all' ? this.state.type.value : undefined,
                page: this.props.page ? this.props.page : 0,
            });
        }
    }

    /* istanbul ignore next */
    onSendToServer(request = undefined)
    {
        BxEventService.search({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            isPending: this.state.isPending.value ? this.state.isPending.value : undefined,
            isFailed: this.state.isFailed.value ? this.state.isFailed.value : undefined,
            context: this.state.context.value ? this.state.context.value : undefined,
            type: this.state.type.value && this.state.type.value !== 'all' ? this.state.type.value : undefined,
            page: this.props.page ? this.props.page : 0,
        };
    }

    getRescheduleEventRequest(bxEventId)
    {
        return {
            ...this.getServerRequest(),
            bxEventId
        };
    }

    getRescheduleIpEventRequest(ipEventId)
    {
        return {
            ...this.getServerRequest(),
            ipEventId
        };
    }

    onRescheduleEvent(bxEventId, isIpEvent)
    {
        this.setContactingServer(true);
        if(isIpEvent)
        {
            BxEventService.rescheduleIp({
                request: this.getRescheduleIpEventRequest(bxEventId),
                onResponse: this.onSearchResponse,
                onError: this.onSearchError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
        else
        {
            BxEventService.reschedule({
                request: this.getRescheduleEventRequest(bxEventId),
                onResponse: this.onSearchResponse,
                onError: this.onSearchError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
    }

    getDeleteJobRequest(bxEventId)
    {
        return {
            ...this.getServerRequest(),
            bxEventId
        };
    }

    getDeleteIpJobRequest(ipEventId)
    {
        return {
            ...this.getServerRequest(),
            ipEventId
        };
    }

    onDeleteJob(bxEventId, isIpEvent)
    {
        this.setContactingServer(true);
        if(isIpEvent)
        {
            BxEventService.deleteIp({
                request: this.getDeleteIpJobRequest(bxEventId),
                onResponse: this.onSearchResponse,
                onError: this.onSearchError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
        else
        {
            BxEventService.delete({
                request: this.getDeleteJobRequest(bxEventId),
                onResponse: this.onSearchResponse,
                onError: this.onSearchError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        BxEventService.search({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'eventList');
    }

    getSearchResultUi(aResult, index)
    {
        return <SearchBxeViewPrompt 
                    key={index} 
                    bxEvent={aResult} 
                    onRescheduleEvent={this.onRescheduleEvent} 
                    onDeleteJob={this.onDeleteJob}
                />
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : (
            <div style={{
                ...theme.getSearchBxeContainerStyle(theme)  
            }}>
                <table>
                    <Header 
                        context={this.state.context.value}
                    />
                    <tbody>
                       {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
                    </tbody>
                </table>
            </div>
        );
    }
}

const SearchBxePrompt = connect(mapStateToProps, mapDispatchToProps)(_SearchBxePrompt);
export default SearchBxePrompt;
