import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import theme from './../../../theme';
import { ShowMenuContext } from './index';

const FullMenu = ({ children }) => {
    const showMenu = useContext(ShowMenuContext);
    /* istanbul ignore next */
    const getMenuStyle = () => showMenu ? theme.getMenuContainerStyle(theme) : theme.getHiddenMenuContainerStyle(theme);
    const [ menuStyle, setMenuStyle ] = useState( getMenuStyle() );

    useEffect(() => {
        setMenuStyle( getMenuStyle() );
        // eslint-disable-next-line
    }, [showMenu]);
    
    return (
        <div style={menuStyle}>
            { children }
        </div>
    );
}
export default FullMenu;
