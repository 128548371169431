import React from 'react';
import theme from './../../theme';

const UnableToLoadTargetPanel = ({ title }) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme),
    }}>
        <h2 style={{ 
            ...theme.getGeneralTextStyle(theme),
        }}>
            Unable to Load { title }
        </h2>
        <p>
            This record is no longer public.
        </p>
    </div>

export default UnableToLoadTargetPanel;
