import React from 'react';
import { useState, useCallback, useRef } from 'react';
import ImgListPrompt from './img-list';
import Webcam from 'react-webcam';
import theme from '../../theme';

const UploadView = ({ 
    imgList,
    onAddImg,
    onRemoveImg,
    openSavedImgDialog,
    onCloseSavedImgDialog,
    savedImgDialogOpen,
    onAddSavedImg
}) => 
    <ImgListPrompt 
        promptTitle="Add a recent photo of yourself"
        onAddImg={onAddImg}
        imgList={imgList}
        onRemoveImg={onRemoveImg}
        openDialog={openSavedImgDialog}
        onCloseDialog={onCloseSavedImgDialog}
        savedImgDialogOpen={savedImgDialogOpen}
        onAddSavedImg={onAddSavedImg}
    />

const CameraView = ({ 
    onAddImg 
}) => {
    const WIDTH = 640;
    const HEIGHT = 360;
    const cam = useRef(null);
    const capture = useCallback(() => {
        const img = cam.current.getScreenshot();
        const imgRec = {
            isScreenShot: true,
            previewImage: img,
            file: img,
            filename: `selfie.png`
        };
        onAddImg(imgRec);
        // eslint-disable-next-line
    }, [cam]);

    return (
        <div>
            <div>
                <Webcam 
                    audio={false}
                    height={HEIGHT}
                    ref={cam}
                    imageSmoothing={true}
                    screenshotFormat="image/png"
                    width={WIDTH}
                    videoConstraints={{
                        width: WIDTH,
                        height: HEIGHT,
                        facingMode: "user"
                    }}
                />
            </div>
            <button 
                className="test-coreCamImgSelectorTakePicBtn"
                onClick={capture}
            >
                Take Picture
            </button>
        </div>
    );
};

const CamImgSelectorPrompt = ({ 
    imgList,
    onAddImg,
    onRemoveImg,
    openSavedImgDialog,
    onCloseSavedImgDialog,
    savedImgDialogOpen,
    onAddSavedImg
}) => {

    const [ isCameraMode, setIsCameraMode ] = useState(false);
    const toggleCameraMode = event => {
        setIsCameraMode(!isCameraMode);
    };
    const onCameraAddImg = img => {
        onAddImg(img);
        setIsCameraMode(false);
    };
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            {
                isCameraMode ? <CameraView 
                    onAddImg={onCameraAddImg} 
                /> : 
                    <UploadView 
                        imgList={imgList}
                        onAddImg={onAddImg} 
                        onRemoveImg={onRemoveImg}
                        openSavedImgDialog={openSavedImgDialog}
                        onCloseSavedImgDialog={onCloseSavedImgDialog}
                        savedImgDialogOpen={savedImgDialogOpen}
                        onAddSavedImg={onAddSavedImg}
                    />
            }
            <div>
                <button 
                    className="test-coreCamImgSelectorToggleCamModeBtn"
                    onClick={toggleCameraMode}
                >
                    { 
                        isCameraMode ? 'Upload Photo' : 'Use Camera'
                    }
                </button>
            </div>
        </div>
    );
};

export default CamImgSelectorPrompt;
