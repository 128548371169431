export const BX_SUBSCRIPTION_ONBOARDING = 'onboarding';
export const BX_SUBSCRIPTION_ACTIVE = 'active';
export const BX_SUBSCRIPTION_CANCELLED = 'cancelled';

const validValues = [
    BX_SUBSCRIPTION_ONBOARDING,
    BX_SUBSCRIPTION_ACTIVE,
    BX_SUBSCRIPTION_CANCELLED
];

const getDropDownValues = () => [
    {
        name: 'Onboarding',
        value: BX_SUBSCRIPTION_ONBOARDING
    },
    {
        name: 'Active',
        value: BX_SUBSCRIPTION_ACTIVE
    },
    {
        name: 'Cancelled',
        value: BX_SUBSCRIPTION_CANCELLED
    }
];

const isValid = value => !! validValues.includes(value);

const BxSubscriptionStateEnum = {
    BX_SUBSCRIPTION_ONBOARDING,
    BX_SUBSCRIPTION_ACTIVE,
    BX_SUBSCRIPTION_CANCELLED,
    getDropDownValues,
    isValid
};

export default BxSubscriptionStateEnum;
