import React from 'react';
import AuthService from './../../../service/auth';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import { connect } from 'react-redux';
import mapDispatchToProps from './../../../store/map/on-login';
import { NavLink } from 'react-router-dom';
import theme from '../../theme';
import LogoPanel from './../../panel/logo';
import LoginWithTokenPrompt from './../login-with-token';
import { LoginPromptContainer } from './view';

class _LoginPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _LoginPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onLoginResponse = this.onLoginResponse.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoginError = this.onLoginError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.get2FAPromptUi = this.get2FAPromptUi.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.getTextUi_getInputFieldStyle = this.getTextUi_getInputFieldStyle.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            email: {
                isValid: ValidateService.valueRequired
            },
            pwd: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    getConfirmUi(confirmMessage = 'Welcome Back!')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getFieldsModel()
    {
        return {
            email: {
                name: 'Email or Username*',
                error: 'Provide your email or username',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            pwd: {
                name: 'Password*',
                error: 'Provide your password',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.PASSWORD,
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            twoFAPromptOpen: false,
            twoFATokenLabel: '',
        };
    }

    onLoginSuccess(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        this.props.onLogin(
            response.authToken, 
            response.userId, 
            response.isAdmin,
            response.unseenNoticeCount,
            response.clientSettings,
        );
    }

    getTextUi_getInputFieldStyle()
    {
        return theme.getLoginEmailFieldStyle(theme);
    }

    onLoginResponse(response)
    {
        if(response.is2FA)
        {
            let nextState = this.state;
            nextState.twoFAPromptOpen = true;
            nextState.contactingServer = false;
            nextState.twoFATokenLabel = response.label;
            this.setState(nextState);
        }
        else
        {
            this.onLoginSuccess(response);
        }
        
    }

    onLoginError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onSendToServer()
    {
        AuthService.login({
            request: this.getServerRequest(), 
            onResponse: this.onLoginResponse, 
            onError: this.onLoginError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            email: this.state.email.value,
            pwd: this.state.pwd.value
        };
    }

    getSubmitButtonUi(buttonText = 'Login')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    get2FAPromptUi()
    {
        return (
            <LoginPromptContainer>
                <p>
                    2FA Token: { this.state.twoFATokenLabel }
                </p>
                <LoginWithTokenPrompt getLoginRequest={ this.getServerRequest } onLoginSuccess={ this.onLoginSuccess } />
            </LoginPromptContainer>
        );
    }

    getLoginUi(buttonText = 'Login')
    {
        return (
            <LoginPromptContainer>
                <LogoPanel />
                <h2>
                    Login
                </h2>
                { super.getPromptUi(buttonText) }
                <div style={{
                    ...theme.getForgotPasswordStyle(theme),
                }}>
                    <div style={{
                        ...theme.getGeneralTextLinkStyle(theme),
                    }}>
                        <NavLink to="/forgot">
                            Forgot Password
                        </NavLink>
                    </div>
                    <br />
                    <div style={{
                        ...theme.getAbstractBottomPaddingSpacerStyle(theme),
                        ...theme.getGeneralTextLinkStyle(theme),
                    }}>
                        <NavLink to="/join">
                            Don't Have an Account?
                        </NavLink>
                    </div>
                </div>
            </LoginPromptContainer>
        );
    }

    getPromptUi(buttonText = 'Login')
    {
        return this.state.twoFAPromptOpen ? this.get2FAPromptUi() : this.getLoginUi()
    }
}

const LoginPrompt = connect(null, mapDispatchToProps)(_LoginPrompt);
export default LoginPrompt;
