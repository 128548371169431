export const EXCHANGE_TYPE_CASH = 'cash';
export const EXCHANGE_TYPE_USPS_CASH_ORDER = 'usps';
export const EXCHANGE_TYPE_TRADE = 'trade';
export const EXCHANGE_TYPE_FREE = 'free';
export const EXCHANGE_TYPE_ONLINE = 'online';

const validValues = [
    EXCHANGE_TYPE_CASH,
    EXCHANGE_TYPE_USPS_CASH_ORDER,
    EXCHANGE_TYPE_TRADE,
    EXCHANGE_TYPE_FREE,
    EXCHANGE_TYPE_ONLINE
];

const getDropDownValues = () => [
   {
        name: 'Trade',
        value: EXCHANGE_TYPE_TRADE,
    },
    {
        name: 'Online Payment',
        value: EXCHANGE_TYPE_ONLINE
    },
    {
        name: 'Free',
        value: EXCHANGE_TYPE_FREE,
    },
    {
        name: 'USPS Postal Payment',
        value: EXCHANGE_TYPE_USPS_CASH_ORDER,
    },
    {
        name: 'Cash',
        value: EXCHANGE_TYPE_CASH,
    },
    
];

const isValid = value => !! validValues.includes(value);

const ExchangeTypeEnum = {
    EXCHANGE_TYPE_CASH,
    EXCHANGE_TYPE_USPS_CASH_ORDER,
    EXCHANGE_TYPE_TRADE,
    EXCHANGE_TYPE_FREE,
    EXCHANGE_TYPE_ONLINE,
    getDropDownValues,
    isValid,
};

export default ExchangeTypeEnum;
