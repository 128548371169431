export const getSeekSearchResultStyle = (theme) => ({
    display: 'flex',
    margin: '0 0 .75rem',
    width: theme.isTablet() ? '19rem' : '100%',
    boxShadow: '0 .05rem .05rem rgba(29, 29, 29, .3)',
    flexDirection: theme.isTablet() ? 'row' : 'column',
});

export const getSeekSearchResultTileContainerStyle = (theme) => ({
    width: theme.isTablet() ? 'auto' : '100%',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    padding: '.5rem'
});

export const getSeekSearchResultTimeStyle = (theme) => ({
    margin: '.75rem',
    textAlign: theme.isTablet() ? 'left' : 'center',
});

export const getSeekSearchResultDetailsStyle = (theme) => ({
    width: theme.isTablet() ? '19rem' : '100%',
});

export const getSeekSearchResultImageStyle = (theme) => ({
    width: theme.isTablet() ? 'auto' : '100%', 
    height: theme.isTablet() ? 'auto' : 'fit-content',
    maxWidth: theme.isTablet() ? '8.75rem' : 'auto',
    maxHeight: theme.isTablet() ? '8.75rem' : 'auto',
    borderRadius: theme.isTablet() ? '.15rem' : '0',
    padding: theme.isTablet() ? '.5rem 0 .5rem .5rem' : '.5rem',
    margin: 'auto'
});

export const getSeekSearchResultNoImageStyle = (theme) => ({
    width: theme.isTablet() ? '7.5rem' : 'calc(100% - 1.5rem)',
    height: theme.isTablet() ? '7.5rem' : 'auto',
    margin: '.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '.15rem',
    color: theme.selectedTextColor,
    backgroundColor: theme.navColor,
    textAlign: 'center',
    minHeight: theme.isTablet() ? '7.5rem' : '12rem',
    flexShrink: '0',
});
