import React from 'react';
import UiService from './../../../../service/ui';

const AdminUserReportedHistoryViewPrompt = ({ aReportedIssue }) => 
    <div>
        { aReportedIssue.details }
        <br />
        { `Reported: ${UiService.timeSince(aReportedIssue.createdOn)}` }
    </div>

export default AdminUserReportedHistoryViewPrompt