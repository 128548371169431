export const getAttributeDetailNameStyle = (theme) => ({
    textAlign: 'right',
    maxWidth: '18rem',
    wordWrap: 'anywhere',
});

export const getAttributeDetailRemoveCustomContainerStyle = (theme) => ({
    marginRight: '1.5rem',
    marginLeft: '.5rem'
});

export const getAttributeDetailFieldContainerStyle = (theme) => ({
    width: '100%'
});

export const getAttributeDetailFieldOuterContainerStyle = (theme) => ({
    margin: '0 .75rem',
    flexGrow: '1'
});

export const getAttributeDetailFieldsStyle = (theme) => ({
    paddingLeft: '1.5rem', 
});

export const getAttributeDetailTitleStyle = (theme) => ({
    paddingLeft: '.25rem',
});

export const getAttributeDetailItemStyle = (theme) => ({
    display: 'flex',
    textAlign: 'left',
    alignItems: 'baseline',
});

export const getAttributeDetailButtonStyle = (theme) => ({
    maxWidth: '10rem',
});

export const getAttributeDetailItemContainerStyle = (theme) => ({
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center'
});

// Add maxWidth/width
export const getAttributeSelectDialogStyle = (theme) => ({
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
    height: 'fit-content',
});

export const getAttributeFlexContainerStyle = (theme) => ({
    display: 'flex',
    margin: '.75rem 2rem 0 0',
    justifyContent: 'end'
});

export const getAttributeAddDialogStyle = (theme) => ({
    textAlign: 'center',
    padding: '.5rem 1rem',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    maxWidth: '40rem',
    width: '100%',
});

export const getAttributeListStyle = (theme) => ({
    minHeight: '8rem',
    margin: '.5rem auto .5rem',
    backgroundColor: theme.buttonColor,
    display: 'flex',
    maxWidth: '60rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    flexWrap: 'wrap'
});

export const getAttributeListItemStyle = (theme) => ({
    margin: '.75rem 0 1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%'
});

export const getSelectAttSetResultsStyle = (theme) => ({
    maxHeight: '32rem',
    overflow: 'auto',
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    margin: '0 auto',
    flexDirection: 'column',
});
