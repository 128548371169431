export const getBidDetailViewPanelStyle = (theme) => ({
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    margin: '1rem 0',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    maxWidth: '30rem',
    textAlign: 'center',
    display: 'inline-block',
    boxShadow: 'rgba(29, 29, 29, 0.5) 0 .1rem .1rem',
    width: '100%',
    padding: '0 .75rem 1.25rem'
});

export const getBidDetailContainerStyle = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

export const getBidDetailViewPanelFlexStyle = (theme) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

export const getBidDetailViewPanelFlagContainerStyle = (theme) => ({
    width: 'fit-content',
    height: 'fit-content'
});
