export const getUserSearchPlatformStyle = (theme, icon) => ({
    float: 'left',
    marginLeft: '5px',
    marginBottom: '5px',
    marginRight: '.5rem',
    width: '3.2rem',
    height: '3.2rem',
    backgroundImage: `url(${icon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: '#101010',
    borderRadius: '1.5rem',
});

export const getUserSearchSingleFieldContainerStyle = (theme) => ({
    margin: '0 auto 1rem auto', 
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    width: '100%',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    display: 'flex',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    alignItems: 'center'
});

export const getUserSearchResultStyle = (theme) => ({
    width: 'auto',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    padding: '.5rem',
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'center'
});

export const getUserSearchResultUsernameStyle = (theme) => ({
    fontSize: '18pt',
    fontWeight: '700',
    margin: '.75rem 0'
});

export const getUserSearchResultPlatformContainerStyle = (theme) => ({
    margin: '0 0 .75rem 0'
});

export const getSelectUserSearchResultsStyle = (theme) => ({
    height: '120px',
    overflow: 'auto'
});