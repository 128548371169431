export const getNoticeDetailsStyle = (theme) => ({
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0 auto'
});

export const getNoticeDetailsTextStyle = (theme) => ({
    float: 'left',
    width: '285px',
    cursor: 'pointer',
    textDecorationLine: 'underline',
    paddingTop: '10px',
});

export const getNoticeDetailsTimeStyle = (theme) => ({
    float: 'left',
    paddingLeft: '10px',
    width: '345px',
    paddingTop: '10px',
});

export const getNoticeDetailsDeleteButtonStyle = (theme, icon) => ({
    margin: '0 .5rem 0 .75rem',
    width: '2.5rem',
    height: '2.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${icon})`,
    cursor: 'pointer',
});

export const getUnSeenNoticeDetailsDeleteButtonStyle = (theme, icon) => ({
    margin: '0 .5rem 0 .75rem',
    width: '2.5rem',
    height: '2.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${icon})`,
    cursor: 'pointer',
});

export const getNotificationsContainerStyle = (theme) => ({
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    justifyContent: 'right',
    gap: '1rem',
    margin: '0 auto'
});

export const getNoticeDetailsContainerStyle = (theme) => ({
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : '100%',
    justifyContent: 'space-between',
    padding: '.5rem 0'
});

export const getUnSeenNoticeDetailsContainerStyle = (theme) => ({
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : '100%',
    justifyContent: 'space-between',
    padding: '.5rem 0',
    backgroundColor: theme.secondaryColor,
    color: theme.selectedTextColor
});

export const getNoticeListItemStyle = (theme) => ({
    listStyleType: 'none'
});