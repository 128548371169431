import React from 'react';
import theme from './../../../theme';
import JSONPretty from 'react-json-pretty';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import UiService from './../../../../service/ui';
import CurrencyService from './../../../../service/currency';
import { Status } from './../../online-transactions-list/view';

/* istanbul ignore next */
const SummaryTable = ({ paymentIntentRecord, flagReviewDetails, reschedulePayout, issueRefund }) => 
    <table>
        <tbody>
            <tr>
                <td>
                    Exchange ID
                </td>
                <td>
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to={`/exchange/${paymentIntentRecord.exchangeId}`}
                    >
                        { paymentIntentRecord.exchangeId }
                    </NavLink>
                </td>
            </tr>
            <tr>
                <td>
                    Status
                </td>
                <td>
                    <Status trans={paymentIntentRecord} />
                </td>
            </tr>
            {
                !paymentIntentRecord.paidOut && !paymentIntentRecord.hasRefunded ? 
                    <tr>
                        <td>
                            
                        </td>
                        <td>
                            <button 
                                className="test-adminPayTransDetailsIssueRefundBtn"
                                onClick={event => {
                                    if(window.confirm('Issue Refund?'))
                                    {
                                        issueRefund(paymentIntentRecord.id);
                                    }
                                }}
                            >
                                Issue Refund
                            </button>
                        </td>
                    </tr> : ''
            }
            {
                paymentIntentRecord.flagged && paymentIntentRecord.flagReviewed && 
                        <tr>
                            <td>
                                Requested Refund 
                                <br />
                                { UiService.getStoryDate(flagReviewDetails.createdOn) }
                            </td>
                            <td>
                                { flagReviewDetails.flaggingReason }
                            </td>
                        </tr>
            }
            {
                paymentIntentRecord.flagged && paymentIntentRecord.flagReviewed &&                         
                        <tr>
                            <td>
                                Requested Refund Details
                            </td>
                            <td>
                                { flagReviewDetails.flaggingUserSummary }
                            </td>
                        </tr>
            }

            {
                paymentIntentRecord.flagged && paymentIntentRecord.flagReviewed &&                         
                        <tr>
                            <td>
                                Refund Rejected
                                <br />
                                { UiService.getStoryDate(flagReviewDetails.updatedOn) }
                            </td>
                            <td>
                                { flagReviewDetails.reviewerUserSummary }
                            </td>
                        </tr>
                    
            }
            {
                paymentIntentRecord.payoutFailed && 
                    <tr>
                        <td>
                            Failure Reason
                        </td>
                        <td>
                            { paymentIntentRecord.payoutFailureDetails }
                            <button 
                                className="test-adminPayTransDetailsReschedulePayoutBtn"
                                onClick={event => { reschedulePayout(paymentIntentRecord.id) }}
                            >
                                Reschedule Payout
                            </button>
                        </td>
                    </tr>
            }
            <tr>
                <td>
                    Created On
                </td>
                <td>
                    { UiService.getStoryDate(paymentIntentRecord.createdOn) }
                </td>
            </tr>
            <tr>
                <td>
                    Last Updated On
                </td>
                <td>
                    { UiService.getStoryDate(paymentIntentRecord.updatedOn) }
                </td>
            </tr>
            <tr>
                <td>
                    Checkout Total
                </td>
                <td>
                    { CurrencyService.getDisplayPrice(paymentIntentRecord.currency, paymentIntentRecord.total) }
                </td>
            </tr>
            <tr>
                <td>
                    Fee
                </td>
                <td>
                    { 
                        paymentIntentRecord.hasRefunded ? ' - ' : 
                            CurrencyService.getDisplayPrice(paymentIntentRecord.currency, paymentIntentRecord.fee) 
                    }
                </td>
            </tr>
            <tr>
                <td>
                    Payout Amount
                </td>
                <td>
                    { 
                        paymentIntentRecord.hasRefunded ? ' - ' : 
                            CurrencyService.getDisplayPrice(paymentIntentRecord.currency, paymentIntentRecord.netTotal) 
                    }
                </td>
            </tr>
        </tbody>
    </table>

const TransactionDetailsDump = ({ apiDetails }) => {
    const [ apiDetailsVisible, setApiDetailsVisible ] = useState(false);
    return apiDetailsVisible ? 
        <section>
            <button 
                className="test-adminPayTransDetailsHideFullDetailsBtn"
                onClick={event => { setApiDetailsVisible(false) }}
            >
                Hide
            </button>
            <div style={{
                ...theme.getAdminPayoutsSearchDetailsModalApiDataDumpStyle(theme)
            }}>
                <h4>
                    Payment Details
                </h4>
                <JSONPretty data={ JSON.stringify(apiDetails) } /> 
                
            </div>
        </section> : 
        <section>
            <button 
                className="test-adminPayTransDetailsFullDetailsBtn"
                onClick={event => { setApiDetailsVisible(true) }}
            >
                Full Details
            </button>
        </section>
};

const AdminPayTransDetailsViewPanel = ({ 
    data: apiDetails, 
    paymentIntentRecord, 
    flagReviewDetails, 
    reschedulePayout,
    issueRefund
}) => 
    <div style={{
        ...theme.getAdminPayoutsSearchDetailModalStyle(theme)  
    }}>
        <h2>
            Transaction Details
        </h2>
        
        <div style={{
            ...theme.getAdminPayoutsSearchDetailModelSummaryStyle(theme)
        }}>
            <SummaryTable 
                paymentIntentRecord={paymentIntentRecord} 
                flagReviewDetails={flagReviewDetails} 
                reschedulePayout={reschedulePayout} 
                issueRefund={issueRefund}
            />
        </div>
        
        <TransactionDetailsDump apiDetails={apiDetails} />
    </div>

export default AdminPayTransDetailsViewPanel;
