import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminSendInvitesPrompt from './../ui/prompt/admin/admin-send-invites';
import theme from './../ui/theme';

const AdminSendInvitePage = (parms) => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            Issue Invites
        </h2>
        <AdminSendInvitesPrompt />
    </AdminPageTemplate>

export default AdminSendInvitePage;
