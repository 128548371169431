import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';

class SubscriptionService 
{
    static getCurrentStatusRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static getCurrentStatus({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.subscription.current,
            request: this.getCurrentStatusRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getStartRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static start({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.subscription.start,
            request: this.getStartRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken

        });
    }

    static getCancelRequest()
    {
        return {

        };
    }

    /* istanbul ignore next */
    static cancel({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.subscription.cancel,
            request: this.getCancelRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static can(sub, access)
    {
        return sub.accessList.includes(access);
    }

    static getUpdatePaymentMethodRequest(paymentMethodId)
    {
        return {
            paymentMethodId
        };
    }

    /* istanbul ignore next */
    static updatePaymentMethod({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.subscription.update,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default SubscriptionService;
