import React from 'react';
import theme from './../../theme';

const AttributePanel = (att, index) => 
    <div key={index} style={{
      ...theme.getAttributeListPanelFlexStyle(theme),
    }}>
        <div style={{
          ...theme.getAttributeListPanelNameStyle(theme),
        }}>
            { att.name }
        </div>
        <div style={{
          ...theme.getAttributeListPanelValueStyle(theme),
        }}>
            { att.value }
        </div>
    </div>

const AttributeListPanel = ({ attributeList }) => attributeList.length > 0 ?  attributeList.map((att, index) => AttributePanel(att, index)) : ''

export default AttributeListPanel;