

import { evalSteps } from './eval-steps';

export const getStepPromptCopy = (steps, delivery) => {
    const {
        isFirstStep,
        lastStep,
        isNextADropStep,
        isNextASortStep
    } = evalSteps(steps);

    if(isFirstStep)
    {
        return `Pick up at 
${delivery.pickupLocation.mailingAddress}`;
    }

    if(isNextADropStep)
    {
        return `Drop at`;
    }


    if(isNextASortStep)
    {
        return 'Sorted for pick up by ';
    }

    // assumes pick up 
    if(lastStep.from)
    {
        return `Pick up at 
${lastStep.to.mailingAddress}`;
    }

    // default no content
    return '';
    
};