export const getExchangeDetailDialogStyle = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
    flexDirection: 'column',
    padding: '.75rem'
});

export const getExchangeOtherContactStyle = (theme) => ({
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
});

export const getExchangeDetailAskingStyle = (theme) => ({
    textAlign: 'left',
    width: '18rem',
    overflowWrap: 'anywhere',
    margin: '0 .75rem',
    flexGrow: '1'
});

export const getExchangeDetailCloseIconStyle = (theme) => ({
    width: '3rem',
    marginRight: '1.5rem',
    marginLeft: '.5rem',
});

export const getExchangeDetailListItemStyle = (theme) => ({
    margin: '.75rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
});

export const getExchangeDetailViewPanelStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    textAlign: 'center',
});

export const getExchangeDetailViewOnlinePaymentDetailsStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    textAlign: 'center',
});

export const getExchangeDetailViewOnlinePaymentDisputeModalStyle = (theme) => ({
    width: '100%',
    textAlign: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getExchangeDetailViewOnlinePaymentDisputeSuggestionListStyle = (theme) => ({
    textAlign: 'left',
    marginLeft: '1rem',
    marginRight: '1rem'
});

export const getExchangeDetailReviewRefundRequestStyle = (theme) => ({
    width: '100%',
    textAlign: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getExchangeDetailDisputeReasonContainerStyle = (theme) => ({
    width: 'fit-content',
    margin: '0 auto',
});

export const getOutOfNetworkQuoteBoxContainerStyle = (theme) => ({
    display: 'flex',
    flexWrap: 'wrap'
});

export const getQuoteFinePrintStyle = (theme) => ({
    fontSize: '8pt'
});

export const getOutOfNetworkQuoteBoxActionStyle = (theme) => ({
    marginBottom: '1rem'
});

export const getOutOfNetworkQuoteBoxStyle = (theme) => ({
    minWidth: '165px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    flexDirection: 'column',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    marginBottom: '1rem',
    marginTop: '1rem',
    backgroundColor: theme.buttonColor,
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
});