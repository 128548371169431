import config from './../config';
import MathService from './math';

class FeeService
{
    static trimZeros(total)
    {
        return total.replace(/^0+/, '');
    }
    
    /* istanbul ignore next */
    static getFee(currency, total, paid = false)
    {
        const feePercent = this.getFeePercent(paid);
        const flatFee = this.getFlatFee(paid);
        const feePercentAmount = MathService.multiplyCurrency(currency, total, feePercent);
        const fee = parseInt(flatFee) + parseInt(feePercentAmount);
        return fee;
    }

    /* istanbul ignore next */
    static getFeePercent(paid = false)
    {
        return paid ? config.pay.paid.feePercent : config.pay.free.feePercent;
    }

    /* istanbul ignore next */
    static getMinListedPrice(paid = false)
    {
        return paid ? config.pay.paid.minListedPrice : config.pay.free.minListedPrice;
    }

    /* istanbul ignore next */
    static getFlatFee(paid = false)
    {
        return paid ? config.pay.paid.flatFee : config.pay.free.flatFee;
    }

    /* istanbul ignore next */
    static getEstimated(currency, total, paid = false)
    {
        const cleanTotal = this.trimZeros(total);
        const fee = this.getFee(currency, cleanTotal, paid);
        const flatFee = this.getFlatFee(paid);
        const isValid = parseInt(cleanTotal) <= parseInt(fee) ? false : true;
        const netTotal = isValid ? MathService.subtractCurrency(currency, cleanTotal, fee) : 0;

        return {
            flatFee,
            isValid,
            total: cleanTotal,
            fee,
            netTotal
        }
    }
    
}

export default FeeService;
