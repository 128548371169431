import React from 'react';
import DisputeService from './../../../service/dispute';
import ValidateService from './../../../service/validate';
import { FORM_FIELD } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../core/search';
import { Header, AdminDisputeSearchViewPrompt } from './view';
import theme from './../../theme';

class _AdminDisputeSearchPrompt extends SearchPrompt 
{
    constructor(parms)
    {
        super(parms, _AdminDisputeSearchPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
    }

    static getValidators()
    {
        return {
            disputeId: {
                isValid: ValidateService.anyValue   
            }, 
            disputeIsActive: {
                isValid: ValidateService.anyValue   
            },
            awaitingResponse: {
                isValid: ValidateService.anyValue
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            disputeId: {
                name: 'Dispute ID',
                error: '',
                value: '',
                visible: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            disputeIsActive: {
                name: 'Dispute is Active',
                error: '',
                value: true,
                visible: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            awaitingResponse: {
                name: 'Awaiting Response',
                error: '',
                value: true,
                visible: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            }
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'disputeList');
    }

    onSendToServer(request = undefined)
    {
        DisputeService.search({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        let request = {
            page: 0,
            disputeId: this.state.disputeId.value
        };

        if(this.state.disputeIsActive.value)
        {
            request.disputeIsActive = true;
        }

        if(this.state.awaitingResponse.value)
        {
            request.awaitingResponse = true;
        }

        return request;
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        DisputeService.search({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getNoResultsUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <p>
                    No Disputes Found.
                </p>
            </div>
        );
    }

    getSearchResultUi(aResult, index)
    {
        return <AdminDisputeSearchViewPrompt dispute={aResult} key={index} />
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : (
            <div style={{
                ...theme.getAdminDisputeSearchContainerStyle(theme)
            }}>
                <table>
                    <Header />
                    <tbody>
                        {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
                    </tbody>
                </table>
            </div>
        );
    }
}

const AdminDisputeSearchPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminDisputeSearchPrompt);
export default AdminDisputeSearchPrompt;
