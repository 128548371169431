import React from 'react';
import config from '../../../config';
import theme from '../../theme';

const ChooseCarrierNetworkType = ({
    clearView,
    setNetworkTypeView
}) => 
    <div>
        <button 
            className="test-exchangeDetailChooseCarrierNetworkTypeBackBtn"
            onClick={clearView}
        >
            Back
        </button>

        <h3>
            What type of carrier is best for you?
        </h3>

        <h5>
            In-Network
        </h5>

        <p>
            In-network carriers are companies of Bears.Exchange users working together to deliver your packages.
        </p>

        {
            !config.features.allowCarrierGroups && 
                <span style={{
                    ...theme.getSmallWarningTextStyle(theme)
                }}>
                    This feature is coming soon!
                </span>
        }

        <button 
            disabled={!config.features.allowCarrierGroups}
            className="test-exchangeDetailChooseCarrierNetworkTypeSelectInNetworkBtn"
            onClick={event => { 
                // istanbul ignore next
                if(config.features.allowCarrierGroups)
                {
                    setNetworkTypeView('in');
                }
            }}
        >
            Select In-Network
        </button>

        <h5>
            Out of Network
        </h5>

        <p>
            Out of network carriers are delivery companies outside of Bears.Exchange
        </p>

        <button 
            className="test-exchangeDetailChooseCarrierNetworkTypeSelectOutOfNetworkBtn"
            onClick={event => { setNetworkTypeView('out') }}
        >
            Select Out of Network
        </button>

    </div>

export default ChooseCarrierNetworkType;
