import React from 'react';
import PayViewPrompt from './view';
import FineService from './../../../service/fine';
import CheckoutService from './../../../service/checkout';
import ValidateService from './../../../service/validate';
import PayService from './../../../service/pay';
import CurrencyService from './../../../service/currency';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import theme from './../../theme';
import BidContentTypeEnum from './../core/enum/bid-content-type';
import payFineTypeEnum from './../core/enum/pay-fine-type';
import {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_PICK_UP,
    OBTAIN_TYPE_REMOTE,
    OBTAIN_TYPE_ON_SITE
} from './../core/enum/obtain-type';
import { 
    ObtainMethodCopy,
} from './../../panel/core/bids';
import { EXCHANGE_TYPE_ONLINE } from './../core/enum/exchange-type';
import LocationSelectorPanel from './../../panel/location-selector';

const obtainToValue = obtainType => `${obtainType.type}`;

/* istanbul ignore next */
const obtainToName = obtainType => obtainType.type === OBTAIN_TYPE_SHIP_TO_ADDRESS ? 'Shipping Company' : (
    obtainType.type === OBTAIN_TYPE_HAND_TO_HAND ? 'Hand Delivery' : (
        obtainType.type === OBTAIN_TYPE_PICK_UP ? 'Pick Up' : (
            obtainType.type === OBTAIN_TYPE_REMOTE ? 'Remote Fulfillment' : 'On Site'
        )
    )
);

class _PayPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _PayPrompt.getValidators(parms))

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getClosedPromptUi = this.getClosedPromptUi.bind(this);
        this.openPrompt = this.openPrompt.bind(this);
        this.getDynamicFields = this.getDynamicFields.bind(this);
        this.applyDynamicFieldValidators = this.applyDynamicFieldValidators.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.displayPriceUi = this.displayPriceUi.bind(this);
        this.loadPaymentMethods = this.loadPaymentMethods.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onLoadPaymentMethodsResponse = this.onLoadPaymentMethodsResponse.bind(this);
        this.getPaymentMethodValueList = this.getPaymentMethodValueList.bind(this);
        this.isFeePayment = this.isFeePayment.bind(this);
        this.getContactingServerUi = this.getContactingServerUi.bind(this);
        this.isPromptingLocation = this.isPromptingLocation.bind(this);
        this.getFormFieldsUi = this.getFormFieldsUi.bind(this);
        this.onLocationSelected = this.onLocationSelected.bind(this);
        this.onLocationRemoveSelected = this.onLocationRemoveSelected.bind(this);
        this.validateState = this.validateState.bind(this);


        this.applyDynamicFieldValidators();
        this.state = this.getDefaultState();
    }

    static getValidators(props)
    {
        return {
            paymentMethod: {
                isValid: ValidateService.anyValue
            },
            details: {
                isValid: ValidateService.anyValue
            },
        };
    }

    /* istanbul ignore next */
    onAuthError()
    {
        this.props.onLogout();
    }

    /* istanbul ignore next */
    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    /* istanbul ignore next */
    getFieldsModel()
    {
        return {
            paymentMethod: {
                name: 'Payment Method',
                error: 'Select a payment method',
                value: '',
                valueList: this.getPaymentMethodValueList([]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            details: {
                name: 'Details',
                error: 'Provide details regarding your order',
                value: '',
                visible: this.props.omitOrderDetails ? false : true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
        };
    }

    /* istanbul ignore next */
    applyDynamicFieldValidators()
    {
        if( this.props.content &&
            this.props.content.obtainTypeDetailList && 
            this.props.content.obtainTypeDetailList.length > 1)
        {
            this.validator.obtainMethod = {
                isValid: ValidateService.anyValue
            };
        }
    }

    /* istanbul ignore next */
    getDynamicFields()
    {
        let out = {};

        if(this.props.content && 
            this.props.content.obtainTypeDetailList && 
            this.props.content.obtainTypeDetailList.length > 1)
        {
            out.obtainMethod = {
                name: 'Delivery Method',
                error: 'Specify which delivery method should be used',
                value: obtainToValue(this.props.content.obtainTypeDetailList[0]),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP,
                valueList: this.props.content.obtainTypeDetailList.map(delivery => ({
                    name: obtainToName(delivery),
                    value: obtainToValue(delivery),
                })),
            };
        }

        return out;
    }

    componentDidMount()
    {
        this.loadPaymentMethods();
    }

    loadPaymentMethods()
    {
        PayService.listPaymentMethods({
            onResponse: this.onLoadPaymentMethodsResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onLoadPaymentMethodsResponse(response)
    {
        let nextState = this.state;
        nextState.paymentMethodsLoaded = true;
        nextState.paymentMethods = response.paymentMethods;
        const defaultMethod = response.paymentMethods.find(aMethod => aMethod.isDefault);
        nextState.paymentMethod.value = defaultMethod ? defaultMethod.id : '';
        nextState.paymentMethod.valueList = this.getPaymentMethodValueList(response.paymentMethods);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    getPaymentMethodValueList(methodList)
    {
        const outList = methodList.map(aMethod => ({
            name: aMethod.nickname !== '' ? aMethod.nickname : `${aMethod.brand} ${aMethod.funding} ******${aMethod.lastFour} `,
            value: aMethod.id,
        }));
        return [
            {
                name: 'NEW CARD',
                value: ''
            },
            ...outList
        ];
    }

    /* istanbul ignore next */
    isPromptingLocation(state)
    {
        if(this.props.contentType !== BidContentTypeEnum.BID_CONTENT_TYPE_OFFER)
        {
            return false;
        }
        const showList = [
            OBTAIN_TYPE_SHIP_TO_ADDRESS,
            OBTAIN_TYPE_HAND_TO_HAND,
            OBTAIN_TYPE_ON_SITE
        ];
        if(this.props.content.obtainTypeDetailList && 
            this.props.content.obtainTypeDetailList.length > 0)
        {
            if(this.props.content.obtainTypeDetailList.length === 1)
            {
                return showList.includes(this.props.content.obtainTypeDetailList[0].type);
            }

            return showList.includes(state.obtainMethod.value);
        }
        return false;
    }

    /* istanbul ignore next */
    validateState(state)
    {
        let baseReturn = super.validateState(state);
        if(this.isPromptingLocation(state))
        {
            if(!this.state.selectedLocation)
            {
                baseReturn.isValid = false;
            }
        }
        return baseReturn;
    }

    /* istanbul ignore next */
    onLocationSelected(location)
    {
        let nextState = this.state;
        nextState.selectedLocation = location;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    onLocationRemoveSelected()
    {
        let nextState = this.state;
        nextState.selectedLocation = null;

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);
    }

    /* istanbul ignore next */
    getFormFieldsUi()
    {
        let output = [];
        for(const aField in this.validator)
        {
            try
            {
                if(this.state[aField].visible)
                {

                    output.push(
                        <section key={aField}>
                            { this.getFormFieldUi(aField) }
                        </section>
                    );
                }

                if(aField === 'obtainMethod' || (aField === 'details' && this.props.content.obtainTypeDetailList && this.props.content.obtainTypeDetailList.length === 1))
                {
                    if(this.isPromptingLocation(this.state))
                    {
                        const selectedType = this.state.obtainMethod ? this.state.obtainMethod.value : (
                            this.props.content.obtainTypeDetailList && this.props.content.obtainTypeDetailList[0] && this.props.content.obtainTypeDetailList[0].type ? 
                                this.props.content.obtainTypeDetailList[0].type : ''
                        );
                        let title = 'Select a Location';
                        switch(selectedType)
                        {
                            case OBTAIN_TYPE_SHIP_TO_ADDRESS:
                                title = 'Where do you wish to receive shipment?';
                                break;

                            case OBTAIN_TYPE_HAND_TO_HAND:
                                title = 'Where do you wish to receive delivery?';
                                break;

                            case OBTAIN_TYPE_ON_SITE:
                                title = 'Where is the site?';
                                break;

                            default:
                                title = 'Select a Location';
                                break;
                        }
                        output.push(
                            <LocationSelectorPanel
                                promptTitle={title}
                                key={`locationSelector${output.length}`}
                                selectedLocation={this.state.selectedLocation}
                                onSelected={this.onLocationSelected}
                                onRemoveSelected={this.onLocationRemoveSelected}
                                includeGps={true}
                            />
                        );
                    }
                }
            }
            catch(err)
            {
                throw new Error(`Unable to read ${aField}`);
            }
        }
        return output;
    }

    /* istanbul ignore next */
    getDefaultState(store)
    {
        let baseState = super.getDefaultState(store);
        if(this.props.omitOrderDetails)
        {
            baseState.isValid = true;
        }
        const fields = this.getFieldsModel();
        const dynamicFields = this.getDynamicFields();

        let nextState = {
            ...baseState,
            ...fields,
            ...dynamicFields,
            payPromptOpen: false,
            paymentMethodsLoaded: false,
            paymentMethods: [],
            clientSecret: '',
            selectLocation: null
        };
        nextState.isValid = !this.isPromptingLocation(nextState);
        return nextState;
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        switch(this.props.contentType)
        {
            case BidContentTypeEnum.BID_CONTENT_TYPE_OFFER:
                return {
                    paymentMethod: this.state.paymentMethod.value,
                    type: this.props.contentType,
                    offerId: this.props.content.id,
                    details: this.state.details.value,
                    selectedLocation: this.isPromptingLocation(this.state) ? this.state.selectedLocation.id : '',
                    obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                        this.props.content.obtainTypeDetailList.length > 0 ? 
                            obtainToValue(this.props.content.obtainTypeDetailList[0]) : ''
                        ),
                };
            
            case payFineTypeEnum.PAY_FINE_TYPE_DISPUTE:
                return {
                    paymentMethod: this.state.paymentMethod.value,
                    payFineId: this.props.content.id,
                };

            default: 
                return {
                    paymentMethod: this.state.paymentMethod.value,
                    type: this.props.contentType,
                    seekId: this.props.content.id,
                    bidId: this.props.bidId,
                    details: this.state.details.value,
                    obtainMethod: this.state.obtainMethod ? this.state.obtainMethod.value : (
                        this.props.content.obtainTypeDetailList.length > 0 ? 
                            obtainToValue(this.props.content.obtainTypeDetailList[0]) : ''
                        ),
                };
        }
    }

    /* istanbul ignore next */
    onServerResponse(response)
    {
        if(this.props.onContactingServerResponse)
        {
            this.props.onContactingServerResponse();
        }
        
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        
        if(response.transactionCompleted)
        {
            if(response.exchangeId)
            {
                this.sendUserTo(`/exchange/${response.exchangeId}`);
                return;
            }

            if(response.redirectTo)
            {
                this.sendUserTo(response.redirectTo);
                return;
            }
        }
        else
        {
            if(response.clientSecret)
            {
                nextState.clientSecret = response.clientSecret;
                if(this.props.setPayClientSecret)
                {
                    this.props.setPayClientSecret(response.clientSecret, response.clientDetails);
                    return;
                }
            }
            else
            {
                nextState.message = response.message ? response.message : '';   
            }
        }
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onServerError(error)
    {
        if(this.props.onContactingServerResponse)
        {
            this.props.onContactingServerResponse();
        }

        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    getContactingServerUi()
    {
        return this.isFeePayment() ? '' : super.getContactingServerUi();
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        if(this.props.onContactingServer)
        {
            this.props.onContactingServer();
        }

        if(this.isFeePayment())
        {
            FineService.startPayFine({
                request: this.getServerRequest(),
                onResponse: this.onServerResponse,
                onError: this.onServerError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            }); 
        }
        else 
        {
            CheckoutService.start({
                request: this.getServerRequest(),
                onResponse: this.onServerResponse,
                onError: this.onServerError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Provide purchase details')
    {
        return <PayViewPrompt clientSecret={this.state.clientSecret} />
    }

    /* istanbul ignore next */
    getSubmitButtonUi(buttonText = 'Checkout')
    {
        const nextButtonText = this.props.checkoutButtonText ? this.props.checkoutButtonText : buttonText;
        return super.getSubmitButtonUi(nextButtonText);
    }

    openPrompt()
    {
        const nextState = {
            ...this.state,
            payPromptOpen: true,
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    getClosedPromptUi()
    {
        const hasAccess = this.props.content.receivePurchaseAllowAccess !== undefined ? this.props.content.receivePurchaseAllowAccess : true;
        const hasAccessMessage = this.props.doesNotHaveAccessMessage ? this.props.doesNotHaveAccessMessage :  'Poster is currently unable to receive payments';
        return hasAccess ?
            <div style={this.props.buttonStyle}>
                <button 
                    className="test-payPromptPurchaseBtn"
                    onClick={event => { this.openPrompt() }}
                >
                    { 
                        this.props.closedButtonText ? 
                            this.props.closedButtonText : 
                                'Purchase'
                    }
                </button>
            </div> : 
            <div>
                { hasAccessMessage }
            </div>
        
    }

    /* istanbul ignore next */
    isFeePayment()
    {
        return ( this.props.content.type && this.props.content.type === payFineTypeEnum.PAY_FINE_TYPE_DISPUTE ) ? true : false;
    }

    /* istanbul ignore next */
    displayPriceUi()
    {
        if(this.props.content.exchangeList)
        {
            const onlineExList = this.props.content.exchangeList.filter(item => item.type === EXCHANGE_TYPE_ONLINE);
            if(onlineExList.length > 0)
            {
                const onlineEx = onlineExList[0];
                return CurrencyService.getDisplayPrice(
                    onlineEx.currency,
                    onlineEx.asking
                );
            }
        }

        if(this.isFeePayment())
        {
            const fine = this.props.content;
            return CurrencyService.getDisplayPrice(
                fine.currency,
                fine.total
            );
        }
        return '';
    }

    /* istanbul ignore next */
    getOpenPromptUi()
    {
        return (
            <div style={{
                ...theme.getPayPromptStyle(theme),
            }}>
                <h2>
                    {
                        this.props.openPromptTitle ? 
                            this.props.openPromptTitle : 
                                'Online Purchase'
                    }
                </h2>

                <h4>
                    { this.displayPriceUi() }
                </h4>

                {
                    !this.isFeePayment() &&
                    <p>
                        Add details such as special notes.
                    </p>
                }
              
                { super.getPromptUi() }
                
                <p>
                    {
                        this.state.obtainMethod ? ObtainMethodCopy({
                            bid: {
                                obtainMethod: this.state.obtainMethod.value 
                            },
                            bidderIsReceiver: this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER,
                            posterName: this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? 'poster' : 'you',
                            bidderName: this.props.bidContentType === BidContentTypeEnum.BID_CONTENT_TYPE_OFFER ? 'you' : 'bidder'
                        }) : ''

                    }
                </p>

            </div>
        );
    }

    /* istanbul ignore next */
    onCloseDialog(event)
    {
        const nextState = {
            ...this.state,
            payPromptOpen: false,
        };
        this.setState(nextState);
    }

    getPromptUi()
    {
        return (
            <div>
                { this.getClosedPromptUi() }
                <Dialog 
                    onClose={this.onCloseDialog}
                    open={this.state.payPromptOpen}
                >
                    { this.getOpenPromptUi() }
                </Dialog>
            </div>
        );
    }

}

const PayPrompt = connect(mapStateToProps, mapDispatchToProps)(_PayPrompt);
export default PayPrompt;
