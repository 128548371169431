import { PieChart } from 'react-minimal-pie-chart';

const PieChartPanel = ({ data, mapper, style }) => {
    const formattedData = data.map((anItem, index) => ({
        ...mapper(anItem, index)
    }));
    return (
        <div style={style}>
            <PieChart data={formattedData} />
        </div>
    );
};

export default PieChartPanel;
