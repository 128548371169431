import React from 'react';

const CreatorLockedOut = ({
    lockedByUsername,
    carrierGroupName,
    isCreator,
    forceUnlockPlan
}) => 
    <div>
        <p>
            { `This plan is currently locked for editing by ${lockedByUsername}` }
        </p>

        {
            isCreator && 
            <div>
                <p>
                    <strong>
                        { `As the creator of ${carrierGroupName}, you can unlock editing but this may disrupt ${lockedByUsername}.  It's best to check with them before unlocking.`}
                    </strong>
                </p>
                <button 
                    className="test-carrierGroupPlanDeliveryCreatorLockedOutUnlockPlanBtn"
                    onClick={event => { forceUnlockPlan() }}
                >
                    Unlock Plan
                </button>
            </div>
        }
        
    </div>

export default CreatorLockedOut;
