import React from 'react';
import ValidateService from './../../../../../service/validate';
import { FORM_FIELD } from './../../abstract';
import SearchPrompt from './../../../core/search';
import AttSetService from './../../../../../service/att-set';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../../store/map/on-logout';
import SelectAttSetViewPrompt, { 
    SelectAttSetPromptContainerView,
    SelectAttSetSearchResultsView
} from './view';
import theme from '../../../../theme';

class _SelectAttSetPrompt extends SearchPrompt
{
    constructor(parms)
    {
        super(parms, _SelectAttSetPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
    }

    static getValidators()
    {
        return {
            searchText: {
                isValid: ValidateService.textLine
            },
        };
    }

    getNoResultsUi()
    {
        return (
            <section>
                <p style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    No Fieldsets Found.
                </p>
            </section>
        );
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            searchText: {
                name: 'Search',
                error: 'no more than 112 characters',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'attSetList');
    }

    onSendToServer()
    {
        AttSetService.search({
            request: this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            searchText: this.state.searchText.value,
            page: 0,
        };
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        AttSetService.search({
            request: {
                searchText: this.state.searchText.value,
                page,
            },
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getPromptUi()
    {
        return (
            <SelectAttSetPromptContainerView onCloseSelectFieldSetDialog={this.props.onCloseSelectFieldSetDialog} >
                { super.getPromptUi() }
            </SelectAttSetPromptContainerView>
        );
    }

    getSearchResultUi(aResult, index)
    {
        return <SelectAttSetViewPrompt 
            key={index}
            attSet={aResult} 
            onAttSetSelected={this.props.onAttSetSelected}
            index={index} />
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : (
            <SelectAttSetSearchResultsView>
                {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
            </SelectAttSetSearchResultsView>
        );
    }
}

const SelectAttSetPrompt = connect(mapStateToProps, mapDispatchToProps)(_SelectAttSetPrompt);

export default SelectAttSetPrompt;
