import ValidateService from './../../../../../service/validate';
import CurrencyInputService from './../../../../../service/currency-input';

const makeOnPayoutBufferUpdated = ({
    setPayoutBufferValid,
    setPayoutBuffer,
    onSetPayout,
    record,
    useMpgGlobal,
    mpgBufferValid,
    mpgBuffer
}) => event => {
    const value = event.target.value;
    const isValid = ValidateService.currencyInput(value);
    setPayoutBufferValid(isValid);
    setPayoutBuffer(value);
    if(isValid)
    {
        /* istanbul ignore next */
        onSetPayout({
            record, 
            payout: CurrencyInputService.parse(value),
            useGlobalMpg: useMpgGlobal,
            mpg: mpgBufferValid ? mpgBuffer : null
        });
    }
};

export default makeOnPayoutBufferUpdated;
