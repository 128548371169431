import React from 'react';
import Pagination from './../../prompt/core/pagination';
import { codeToCopy } from './../../prompt/core/enum/carrier-member-role';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import UpdateCarrierMemberRolesPrompt from './../../prompt/update-carrier-member-roles';

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const MemberLine = ({ member, groupCreatorUserId, onMemberUpdated }) => {
    const [ rolesPromptOpen, setRolesPromptOpen ] = useState(false);
    /* istanbul ignore next */
    const memberUpdated = (response) => {
        onMemberUpdated(response);
        setRolesPromptOpen(false);
    };
    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <img 
                                src={member.photo.data} 
                                alt="File Missing" 
                                style={{
                                    ...theme.getImgAddViewStyle(theme),
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to={`/user/${member.userId}`}
                            >
                                { member.name }
                            </NavLink>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Status
                        </td>
                        <td>
                            {
                                /* istanbul ignore next */
                                member.isAccepting ? 'Accepting Work' : 'Inactive'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Phone
                        </td>
                        <td>
                            { member.contactPhone }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Email
                        </td>
                        <td>
                            { member.contactEmail }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Roles
                        </td>
                        <td>
                            {
                                member.userId === groupCreatorUserId ? 
                                    'Creator' : member.roles.map(role => codeToCopy(role)).join(', ')
                                    
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {
                                member.userId !== groupCreatorUserId && 
                                    <button 
                                        className="test-carrierMembersListEditRolesBtn"
                                        onClick={event => { setRolesPromptOpen(true) }}
                                    >
                                        Edit Roles
                                    </button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setRolesPromptOpen(false) }
                }
                open={rolesPromptOpen}
            >
                <h4>
                    { `Set roles for ${ member.name }` }
                </h4>

                <UpdateCarrierMemberRolesPrompt 
                    savedRoles={member.roles}
                    carrierMemberId={member.id}
                    onResponse={memberUpdated}
                />

            </Dialog>
        </div>
    );
};

const PopulatedView = ({ members, carrierGroup, onLoadSpecifiedPage, onMemberUpdated }) => 
    <div>
        <h3>
            Members
        </h3>
        { getPaginationUi({ data: members, onLoadSpecifiedPage }) }

        {
            members.list.map((member, index) => 
                <MemberLine 
                    member={member} 
                    groupCreatorUserId={carrierGroup.creatorUserId} 
                    key={index} 
                    onMemberUpdated={onMemberUpdated}
                />
            )
        }

        { getPaginationUi({ data: members, onLoadSpecifiedPage }) }
    </div>

const EmptyView = () => 
    <section>
        <p>
            No Members Found
        </p>
    </section>

const CarrierMembersListViewPanel = ({ data, onLoadSpecifiedPage, onMemberUpdated }) => data.members.list.length < 1 ? 
    <EmptyView /> : 
        <PopulatedView 
            members={data.members}
            carrierGroup={data.carrierGroup}
            onLoadSpecifiedPage={onLoadSpecifiedPage}
            onMemberUpdated={onMemberUpdated}
        />

export default CarrierMembersListViewPanel;
