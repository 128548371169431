import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';

class BxEventService 
{
    static getSearchRequest(isPending, isFailed, context, type)
    {
        return {
            isPending,
            isFailed,
            context,
            type
        }
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.searchBxEvents,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken, 
            debounce: true
        });
    }

    static getRescheduleRequest(bxEventId, isPending, isFailed, context, type)
    {
        const baseRequest = this.getSearchRequest(isPending, isFailed, context, type);
        return {
            ...baseRequest,
            bxEventId
        };
    }

    static getRescheduleIpRequest(ipEventId, isPending, isFailed, context, type)
    {
        const baseRequest = this.getSearchRequest(isPending, isFailed, context, type);
        return {
            ...baseRequest,
            ipEventId
        };
    }

    /* istanbul ignore next */
    static rescheduleIp({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.rescheduleIp,
            request,
            onResponse, 
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    /* istanbul ignore next */
    static reschedule({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.rescheduleBxEvent,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDeleteRequest(bxEventId, isPending, isFailed, context, type)
    {
        const baseRequest = this.getSearchRequest(isPending, isFailed, context, type);
        return {
            ...baseRequest,
            bxEventId
        };
    }

    static getDeleteIpRequest(ipEventId, isPending, isFailed, context, type)
    {
        const baseRequest = this.getSearchRequest(isPending, isFailed, context, type);
        return {
            ...baseRequest,
            ipEventId
        };
    }

    /* istanbul ignore next */
    static deleteIp({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.removeIp,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    /* istanbul ignore next */
    static delete({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.deleteBxEvent,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default BxEventService;
