import React from 'react';
import theme from './../../theme';
import Pagination from './../../prompt/core/pagination';
import UiService from '../../../service/ui';
import { NavLink } from 'react-router-dom';

const IssueDetails = ({ issue }) => {

    return (
        <NavLink to={`/profile/reported/issue/${issue.id}`}>
            <div style={{ 
                ...theme.getGeneralStartTextStyle(theme),
                ...theme.getGeneralBottomSpacerStyle(theme) 
            }}>
                <div>
                    { issue.details }
                </div>
                <strong style={{ 
                    ...theme.getGeneralTextLinkStyle(theme),
                }}>
                    Reported &nbsp; { UiService.timeSince(issue.createdOn) }
                </strong>
            </div>
        </NavLink>
    );
};

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                                total={data.total}
                                                                page={data.page}
                                                                hideAllWhenNoButtonsVisible={true}
                                                                onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                                perPage={data.perPage} />


const PopulatedView = ({ data, onLoadSpecifiedPage }) => {
    return (
        <section>
            { getPaginationUi({ data, onLoadSpecifiedPage }) }
            <div>
                {
                    data.list.map((issue, index) => 
                        <IssueDetails
                            issue={issue}
                            key={index}
                        />
                    )
                }
            </div>
            { getPaginationUi({ data, onLoadSpecifiedPage }) }
        </section>
    );
};

const EmptyView = () => 
    <section style={{ 
        ...theme.getGeneralTextStyle(theme) 
    }}>
        <p>
            You have not reported any issues.
        </p>
    </section>

const MyReportedIssuesViewPanel = ({ data, onLoadSpecifiedPage }) => {

    return (
        <div>
            <h2 style={{ 
                ...theme.getGeneralTextStyle(theme) 
            }}>
                My Reported Issues
            </h2>
            <div style={{ 
                ...theme.getProfilePwdPageStyle(theme)
            }}>
                {
                    data.list.length < 1 ? <EmptyView /> : <PopulatedView data={data} onLoadSpecifiedPage ={onLoadSpecifiedPage} />
                }
            </div>
        </div>
    );
};

export default MyReportedIssuesViewPanel;
