import React from 'react';
import { Prompt } from 'react-router-dom';
import SeekService from './../../../service/seek';
import WarnUnsavedService from './../../../service/warn-unsaved';
import SubscriptionService from './../../../service/subscription';
import { BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE } from './../core/enum/bx-subscription-access';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import ObtainTypeDetailViewPrompt from './../core/obtain-type-detail-view';
import AttributeDetailViewPrompt from './../core/attribute-detail-view';
import ExchangeDetailViewPrompt from './../core/exchange-detail-view';
import OfferSeekTypeEnum from './../core/enum/offer-seek-type';
import RestrictViewTypeEnum from './../core/enum/restrict-view-type';
import ImgListPrompt from './../core/img-list';
import SeekDetailViewPanel from './../../panel/seek-detail/view';
import theme from './../../theme';
import config from './../../../config';
import { NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import TagListPrompt from './../core/tag-list';
import CurrencyService from '../../../service/currency';
import { EXCHANGE_TYPE_ONLINE } from './../core/enum/exchange-type';
import { AddSeekViewPrompt } from './view';

const unsavedWarningCopy = 'Are you sure you want to lose unsaved data?';

const steps = [
    'Summary',
    'Images',
    'Tags',
    'Custom',
    'Delivery',
    'Exchange',
    'Review',
];

class _AddSeekPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _AddSeekPrompt.getValidators());

        this.warnUnSavedFun = () => {};
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getServerRequestRules = this.getServerRequestRules.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAddObtainTypeDetail = this.onAddObtainTypeDetail.bind(this);
        this.onRemoveObtainTypeDetail = this.onRemoveObtainTypeDetail.bind(this);
        this.onRemoveAttribute = this.onRemoveAttribute.bind(this);
        this.onAddAttribute = this.onAddAttribute.bind(this);
        this.onAddExchange = this.onAddExchange.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.onRemoveExchange = this.onRemoveExchange.bind(this);
        this.getRootSeekPromptUi = this.getRootSeekPromptUi.bind(this);
        this.onNextStep = this.onNextStep.bind(this);
        this.getCurrentPromptUi = this.getCurrentPromptUi.bind(this);
        this.getObtainTypePromptUi = this.getObtainTypePromptUi.bind(this);
        this.getAttributesPromptUi = this.getAttributesPromptUi.bind(this);
        this.completePromptSteps = this.completePromptSteps.bind(this);
        this.onAddImg = this.onAddImg.bind(this);
        this.onRemoveImg = this.onRemoveImg.bind(this);
        this.getExchangesPromptUi = this.getExchangesPromptUi.bind(this);
        this.getImgPromptUi = this.getImgPromptUi.bind(this);
        this.getServerRequestFileList = this.getServerRequestFileList.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.getConfirmPromptUi = this.getConfirmPromptUi.bind(this);
        this.onFieldValueUpdate = this.onFieldValueUpdate.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onAddSavedImg = this.onAddSavedImg.bind(this);
        this.getServerRequestStoredList = this.getServerRequestStoredList.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
        this.onRemoveTag = this.onRemoveTag.bind(this);
        this.getTagsPromptUi = this.getTagsPromptUi.bind(this);
        this.onLoadPayAccountResponse = this.onLoadPayAccountResponse.bind(this);
        this.getTextUi_getInputFieldStyle = this.getTextUi_getInputFieldStyle.bind(this);
        this.getTextAreaUi_textAreaStyle = this.getTextAreaUi_textAreaStyle.bind(this);
        this.getTextAreaUi_getErrorStyle = this.getTextAreaUi_getErrorStyle.bind(this);
        this.getTextAreaUi_getContainerStyle = this.getTextAreaUi_getContainerStyle.bind(this);
        this.loadPayAccount = this.loadPayAccount.bind(this);
        this.restart = this.restart.bind(this);
        this.state = this.getDefaultState();
    }

    getTextAreaUi_getContainerStyle()
    {

        return theme.getAbstractFieldContainerStyle(theme);
    }

    getTextAreaUi_getErrorStyle()
    {
        return theme.getAbstractSmallTextError(theme);
    }

    getTextAreaUi_textAreaStyle()
    {
        return theme.getAbstractTextAreaSmallField(theme);
    }

    getTextUi_getInputFieldStyle()
    {
        return theme.getAddOfferTextFieldStyle(theme);
    }

    static getValidators()
    {
        return {
            offerSeekType: {
                isValid: OfferSeekTypeEnum.isValid
            },
            visibilityType: {
                isValid: RestrictViewTypeEnum.isValid
            },
            title: {
                isValid: ValidateService.valueRequired
            },
            details: {
                isValid: ValidateService.valueRequired
            },
/*            
            visibilityExpireDate: {
                isValid: ValidateService.textLine
            },
*/
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            title: {
                name: 'Seeking Title*',
                error: 'Provide a title line for your seek',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            details: {
                name: 'Seeking Details*',
                error: 'Provide details about this seek',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
            offerSeekType: {
                name: 'Seek Type*',
                error: 'Provide a seek type',
                value: OfferSeekTypeEnum.OFFER_SEEK_TYPE_ITEM,
                valueList: OfferSeekTypeEnum.getDropDownValues(),
                visible: true, 
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            visibilityType: {
                name: 'Seek Visibility*',
                error: 'Specify the seek visibility',
                value: RestrictViewTypeEnum.RESTRICT_VIEW_TYPE_PUBLIC,
                valueList: RestrictViewTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
/*            
            visibilityExpireDate: {
                name: 'Offer Expiration Date',
                error: 'Specify the date this offer is no longer available',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
*/            
            obtainTypeDetailList: [],
            attributeList: [],
            exchangeList: [],
            imgList: [],
            tagList: [],
            payAccountLoaded: false,
            payAccountSetupComplete: false,
            payCurrencyList: []
        };
    }

    loadPayAccount()
    {
        SeekService.setupAdd({
            onResponse: this.onLoadPayAccountResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadPayAccountResponse(response)
    {
        const nextState = this.state;
        nextState.allowAccess = response.allowAccess;
        nextState.payAccountLoaded = true;
        nextState.payAccountSetupComplete = response.account.accountSetupComplete;
        nextState.payCurrencyList = response.payments.receive.currency;
        nextState.paidAccount = response.subscription ? SubscriptionService.can(response.subscription, BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE) : false;
        this.setState(nextState);
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            promptUxStep: 1,
            addedSeekId: '',
            allowAccess: true,
            savedImgDialogOpen: false,
            paidAccount: false,
            hasUnsaved: false
        };
    }

    /* istanbul ignore next */
    openDialog()
    {
        const nextState = {
            ...this.state,
            savedImgDialogOpen: true
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCloseDialog(event)
    {
        const nextState = {
            ...this.state,
            savedImgDialogOpen: false
        };
        this.setState(nextState);
    }

    componentDidMount()
    {
        this.loadPayAccount();
        this.warnUnSavedFun = WarnUnsavedService.getOnUnload(
            /* istanbul ignore next */
            () => this.state.hasUnsaved, 
            unsavedWarningCopy
        );
        WarnUnsavedService.startListening(this.warnUnSavedFun);
    }

    componentWillUnmount()
    {
        WarnUnsavedService.stopListening(this.warnUnSavedFun);
    }

    onAddResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        nextState.addedSeekId = response.id;
        nextState.hasUnsaved = false;
        this.setState(nextState);
    }

    onAddError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        if(nextState.message === 'Network Error')
        {
            const fileList = this.getServerRequestFileList();
            if(fileList.length > 0)
            {
                nextState.message = 'An error has occurred.  Try using smaller image files.'
            }
        }
        this.setState(nextState);
    }

    onSendToServer()
    {
        SeekService.addSeek({
            request: this.getServerRequest(),
            fileList: this.getServerRequestFileList(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            title: this.state.title.value,
            details: this.state.details.value,
            tagList: this.state.tagList,
            rules: this.getServerRequestRules(),
            savedImgList: this.getServerRequestStoredList()
        };
    }

    /* istanbul ignore next */
    getServerRequestFileList()
    {
        return this.state.imgList.map((img, index) => ({ 
            ...img, 
            order: index 
        })).filter(img => img.id === undefined);
    }

    /* istanbul ignore next */
    getServerRequestStoredList()
    {
        return this.state.imgList.map((img, index) => ({
          ...img,
          order: index  
        })).filter(img => img.id !== undefined).map(img => ({
            id: img.id,
            order: img.order
        }));
    }

    getServerRequestRules()
    {
        return {
            offerSeekType: this.state.offerSeekType.value,
            visibility: {
                type: this.state.visibilityType.value,
                expireDate: '', // this.state.visibilityExpireDate.value
            },
            obtainTypeDetailList: this.state.obtainTypeDetailList.map(
                /* istanbul ignore next */
                aDetail => {
                    let output = {
                        type: aDetail.type,
                        visibility: {
                            ...aDetail.visibility
                        },
                        locationLimit: {
                            distance: aDetail.locationLimitDistance,
                            area: ''
                        }
                    };

                    try
                    {
                        const location = JSON.parse(aDetail.locationLimitArea);
                        output.locationLimit.area = location.id;
                    }
                    catch(err)
                    {
                        output.locationLimit.area = aDetail.locationLimitArea
                    }
                    return output;
                }
            ),
            attributeList: this.state.attributeList,
            exchangeList: this.state.exchangeList,
        };
    }

    /* istanbul ignore next */
    onRemoveObtainTypeDetail(delIndex)
    {
        let nextState = this.state;
        nextState.obtainTypeDetailList = this.state.obtainTypeDetailList.filter((aDetail, index) => index !== delIndex);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onRemoveExchange(delIndex)
    {
        let nextState = this.state;
        nextState.exchangeList = this.state.exchangeList.filter((aDetail, index) => index !== delIndex);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onRemoveAttribute(delIndex)
    {
        let nextState = this.state;
        nextState.attributeList = this.state.attributeList.filter((aDetail, index) => index !== delIndex);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onAddObtainTypeDetail(nextObtainTypeDetail)
    {
        let nextState = this.state;
        if(nextState.obtainTypeDetailList.length < config.countLimits.deliveryOptions)
        {
            nextState.obtainTypeDetailList.push(nextObtainTypeDetail);
            this.setState(nextState);
        }
        else
        {
            toast('Delivery limit reached.  Remove a delivery option before adding another');
        }
    }

    /* istanbul ignore next */
    onAddAttribute(nextAttribute)
    {
        let nextState = this.state;
        if(nextState.attributeList.length < config.countLimits.customFields)
        {
            nextState.attributeList.push(nextAttribute);
            this.setState(nextState);
        }
        else
        {
            toast('Fields limit reached.  Remove a field before adding another');
        }
    }

    /* istanbul ignore next */
    onAddExchange(nextExchange)
    {
        let nextState = this.state;
        if(nextState.exchangeList.length < config.countLimits.exchangeOptions)
        {
            if(nextExchange.type === EXCHANGE_TYPE_ONLINE && nextState.exchangeList.filter(ex => ex.type === EXCHANGE_TYPE_ONLINE).length > 0)
            {
                toast('You are limited to only ONE online payment option');
            }
            else
            {
                nextState.exchangeList.push(nextExchange);
                this.setState(nextState);
                toast('Exchange Option Added.');
            }
        }
        else
        {
            toast('Exchange limit reached.  Remove an exchange before adding another');
        }
    }

    onAddTag(nextTag)
    {
        if(this.state.tagList.length < config.countLimits.tags)
        {
            
            const nextList = [
                ...this.state.tagList,
                nextTag
            ];
            const tagList = nextList.sort(
                /* istanbul ignore next */
                (a, b) => {
                    if(a < b)
                    {
                        return -1;
                    }

                    if(a > b)
                    {
                        return 1;
                    }
                    return 0;
                }
            );
            this.setState({
                ...this.state,
                tagList
            });
        }
        else 
        {
            toast('Tag limit reached.  Remove a tag before adding another');
        }
    }

    onRemoveTag(delTag)
    {
        let nextState = this.state;
        nextState.tagList = this.state.tagList.filter(aTag => aTag !== delTag);
        this.setState(nextState);
    }

    restart()
    {
        const restartState = this.getDefaultState();
        this.setState(restartState);
    }

    getConfirmUi(confirmMessage = 'Seek Added')
    {
        return (
            <section style={{ 
                ...theme.getGeneralBottomSpacerStyle(theme),
            }}>
                <div style={{ 
                    ...theme.getAbstractBottomPaddingSpacerStyle(theme),       
                }}>
                    <h2 style={{ 
                        ...theme.getGeneralTextStyle(theme),      
                    }}>
                        Seek Added
                    </h2>
                    <NavLink to={`/seek/${this.state.addedSeekId}`}>
                        <h3 style={{ 
                            ...theme.getGeneralTextStyle(theme),
                            ...theme.getGeneralTextLinkStyle(theme),   
                        }}>
                            Go to posted Seek.
                        </h3>
                    </NavLink>
                    <NavLink to={`/`}>
                        <h3 style={{ 
                            ...theme.getGeneralTextStyle(theme),
                            ...theme.getGeneralTextLinkStyle(theme),
                        }}>
                            Go back to your Dashboard.
                        </h3>
                    </NavLink> 
                    <h3 
                        onClick={event => { this.restart() }}
                        style={{
                            ...theme.getGeneralTextStyle(theme),
                            ...theme.getGeneralTextLinkStyle(theme),
                            ...theme.getOnClickLinkStyle(theme)
                        }}
                    >
                        Add another seek.
                    </h3>
                </div>
            </section>
        );
    }

    /* istanbul ignore next */
    getSubmitButtonUi(buttonText = 'Publish')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    onNextStep(nextStep)
    {
        const nextState = {
            ...this.state,
            promptUxStep: nextStep,
            hasUnsaved: true
        };
        this.setState(nextState);
    }

    getRootSeekPromptUi()
    {
        return (
            <form onSubmit={this.onFormSubmit} style={{
                ...theme.getGeneralBottomSpacerStyle(theme),
            }}>
                <h2>
                    What are you looking for?
                </h2>
                { this.getServerFeedbackUi() }
                { this.getFormFieldsUi() }
                <button 
                    className="test-addSeekNextBtn"
                    disabled={!this.state.isValid} 
                    onClick={event => { this.onNextStep(2) }}
                >
                    Next
                </button>
            </form>
        );
    }

    getObtainTypePromptUi()
    {
        return (
            <section>
                <ObtainTypeDetailViewPrompt 
                    bidderIsReceiver={false}
                    contentType="seek"
                    onAddObtainTypeDetail={this.onAddObtainTypeDetail} 
                    obtainTypeDetailList={this.state.obtainTypeDetailList}
                    onRemoveObtainTypeDetail={this.onRemoveObtainTypeDetail} 
                    posterName={'you'}
                />

                <button 
                    style={{
                        ...theme.getGeneralBackButtonStyle(theme),
                    }} 
                    className="test-addSeekBackBtn"
                    onClick={event => { this.onNextStep(4) }}
                >
                    Back
                </button>
                <button 
                    className="test-addSeekNextBtn"
                    onClick={event => { this.onNextStep(6) }}
                >
                    Next
                </button>
            </section>
        );
    }

    /* istanbul ignore next */
    onFieldValueUpdate(index, value)
    {
        const nextState = this.state;
        nextState.attributeList[index].value = value;
        this.setState(nextState);
    }

    getAttributesPromptUi()
    {
        return (
            <section>
                <AttributeDetailViewPrompt 
                        promptTitle="Add custom fields."
                        onFieldValueUpdate={this.onFieldValueUpdate}
                        onAddAttribute={this.onAddAttribute}
                        attributeList={this.state.attributeList}
                        onRemoveAttribute={this.onRemoveAttribute} />
                 <button 
                    style={{
                        ...theme.getGeneralBackButtonStyle(theme),
                    }} 
                    className="test-addSeekBackBtn"
                    onClick={event => { this.onNextStep(3) }}
                >
                    Back
                </button>
                <button 
                    className="test-addSeekNextBtn"
                    onClick={event => { this.onNextStep(5) }}
                >
                    Next
                </button>
            </section>
        );
    }

    /* istanbul ignore next */
    getExchangesPromptUi()
    {
        return (
            <section>
                <ExchangeDetailViewPrompt
                    promptTitle="Add exchange options."
                    extraOnlinePaymentCopy="*Actual fees will depend on which user fulfills your request.  Above estimates assume an unsubscribed account."
                    skipOnlinePayValidation={true}
                    onAddExchange={this.onAddExchange}
                    onRemoveExchange={this.onRemoveExchange}
                    exchangeList={this.state.exchangeList} 
                    paidAccount={false}
                    payAccountLoaded={this.state.payAccountLoaded}
                    payAccountSetupComplete={this.state.payAccountSetupComplete}
                    payCurrencyList={this.state.payCurrencyList.length > 0 ? this.state.payCurrencyList : CurrencyService.getNoAccountCurrencyList() }
                />
                 <button 
                    style={{
                        ...theme.getGeneralBackButtonStyle(theme),
                    }} 
                    className="test-addSeekBackBtn"
                    onClick={event => { this.onNextStep(5) }}
                >
                    Back
                </button>
                <button 
                    className="test-addSeekNextBtn"
                    onClick={event => { this.onNextStep(7) }}
                >
                    Next
                </button>
            </section>
        );
    }

    /* istanbul ignore next */
    onAddImg(img)
    {
        let nextState = this.state;
        const imgListLength = nextState.imgList.length;
        if((imgListLength + 1 ) > config.img.maxUploadCount)
        {
            nextState.imgList[imgListLength - 1] = img;
            toast('Max images reached');
        } 
        else
        {
            nextState.imgList.push(img);
        }
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onAddSavedImg(img)
    {
        this.onAddImg(img);
        let nextState = this.state;
        nextState.savedImgDialogOpen = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onRemoveImg(delIndex)
    {
        let nextState = this.state;
        nextState.imgList = this.state.imgList.filter((img, index) => delIndex !== index);
        this.setState(nextState);
    }

    getImgPromptUi()
    {
        return (
            <section>
                <ImgListPrompt 
                    promptTitle="Add Images"
                    onAddImg={this.onAddImg}
                    onRemoveImg={this.onRemoveImg}
                    onCloseDialog={this.onCloseDialog}
                    openDialog={this.openDialog}
                    savedImgDialogOpen={this.state.savedImgDialogOpen}
                    onAddSavedImg={this.onAddSavedImg}
                    imgList={this.state.imgList} />
                <button 
                    style={{
                        ...theme.getGeneralBackButtonStyle(theme),
                    }} 
                    className="test-addSeekBackBtn"
                    onClick={event => { this.onNextStep(1) }}
                >
                    Back
                </button>
                <button 
                    className="test-addSeekNextBtn"
                    onClick={event => { this.onNextStep(3) }}
                >
                    Next
                </button>
            </section>
        );
    }

    /* istanbul ignore next */
    completePromptSteps()
    {
        if(this.state.isValid){
            this.setContactingServer(true);
            this.onSendToServer();
        }
    }

    /* istanbul ignore next */
    getConfirmPromptUi()
    {
        const confirmData = {
            isConfirmView: true,
            title: this.state.title.value,
            details: this.state.details.value,
            offerSeekType: this.state.offerSeekType.value,
            visibility: {
                type: this.state.visibilityType.value,
                expireDate: '', // this.state.visibilityExpireDate.value
            },
            obtainTypeDetailList: this.state.obtainTypeDetailList.map((ot, index) => ({
                ...ot,
                locationLimit: {
                    area: ot.locationLimitArea,
                    distance: ot.locationLimitDistance,
                },
            })),
            attributeList: this.state.attributeList,
            exchangeList: this.state.exchangeList,
            bids: [],
            imgList: this.state.imgList,
            tagList: this.state.tagList,
        };
        return (
            <section>
                <h2>
                    Everything look good?
                </h2>

                <div style={{
                    ...theme.getAddOfferConfirmContainerStyle(theme),
                }}>
                    <SeekDetailViewPanel data={confirmData} />
                
                </div>    

                { this.getServerFeedbackUi() }

                <section>
                    <button 
                        style={{
                            ...theme.getGeneralBackButtonStyle(theme),
                        }} 
                        className="test-addSeekBackBtn"
                        onClick={event => { this.onNextStep(6) }}
                    >
                        Back
                    </button>
                    <button 
                        disabled={!this.state.isValid} 
                        onClick={event => { this.completePromptSteps() }}
                        className="test-addSeekPublishBtn"
                    >
                        Publish
                    </button>
                </section>

            </section>
        );
        
    }

    getTagsPromptUi()
    {
        return (
            <section>
                <h2>
                    Apply Filter(s)
                </h2>
                <p>
                    Use tags to categorize your seek and make it easier to find.
                </p>
                <div style={{
                    ...theme.getAddOfferFilterPageContainerStyle(theme),
                }}>
                    <TagListPrompt 
                        onAddTag={this.onAddTag}
                        onRemoveTag={this.onRemoveTag}
                        tagList={this.state.tagList}
                    />
                    <button 
                        style={{
                            ...theme.getGeneralBackButtonStyle(theme),
                        }} 
                        className="test-addSeekBackBtn"
                        onClick={event => { this.onNextStep(2) }}
                    >
                        Back
                    </button>
                    <button 
                        className="test-addSeekNextBtn"
                        onClick={event => { this.onNextStep(4) }}
                    >
                        Next
                    </button>
                </div>
            </section>
        );
    }

    /* istanbul ignore next */
    getCurrentPromptUi()
    {
        switch(this.state.promptUxStep)
        {
            case 7: 
                return this.getConfirmPromptUi();

            case 6:
                return this.getExchangesPromptUi();

            case 5: 
                return this.getObtainTypePromptUi();
            
            case 4: 
                return this.getAttributesPromptUi();

            case 3: 
                return this.getTagsPromptUi();

            case 2:
                return this.getImgPromptUi();

            case 1: 
                return this.getRootSeekPromptUi();

            default:
                return this.getRootSeekPromptUi();
        }
    }

    getPromptUi(buttonText = 'Add')
    {
        return (
            <AddSeekViewPrompt
                steps={steps}
                currentStep={this.state.promptUxStep}
                allowAccess={this.state.allowAccess}
            >
                <Prompt 
                    when={this.state.hasUnsaved}
                    message={unsavedWarningCopy}
                />
                { this.getCurrentPromptUi() }
            </AddSeekViewPrompt>
        );
    }
}

const AddSeekPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddSeekPrompt);
export default AddSeekPrompt;
