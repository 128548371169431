import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import UiService from './../../../service/ui';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

export const ExchangeSearchActiveSingleFieldPromptContainer = ({ children }) => {
    const getContainerStyle = () => theme.getExchangeSearchSingleFieldContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={containerStyle}>
            { children }
        </div>
    );
};


const ExchangeSearchActiveViewPrompt = ({ exchange }) => {
    const getComposedStyle = () => ({
        ...theme.getExchangeSearchResultTileContainerStyle(theme),
        ...theme.getAbstractTextAlignCenterStyle(theme)
    });
    const [ composedStyle, setComposedStyle ] = useState( getComposedStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setComposedStyle( getComposedStyle() );
    });
    useEffect(resizeEffect);
    return (
        <NavLink 
            to={`/exchange/${exchange.id}`} 
            style={composedStyle}
        >
            <div>
                <h3>
                    { exchange.title }
                </h3>
                <strong style={{
                    ...theme.getExchangeSearchResultTimeStyle(theme),
                }}>
                    { UiService.timeSince(exchange.createdOn) }
                </strong>
                <div>
                    { exchange.amIActor ? 'Action Required' : 'Pending' }
                </div>
            </div>

        </NavLink>
    
    );
};

export default ExchangeSearchActiveViewPrompt;
