import React from 'react';

export const Header = () => 
    <thead>
        <tr>
            <td>
                Request
            </td>
            <td>
                Slowest
            </td>
            <td>
                Average
            </td>
            <td>
                Fastest
            </td>
            <td>
                Total
            </td>
        </tr>
    </thead>

export const SearchMetricsSummaryViewPrompt = ({ metric, setSearchText }) => 
    <tr>
        <td>
            <span 
                className="test-adminSearchMetricsSummaryNameTxt"
                onClick={event => { setSearchText(metric.name) }}
            >
                { metric.name }
            </span>
        </td>
        <td>
            {`${metric.highDurationMS / 1000} seconds`}
        </td>
        <td>
            {`${metric.avgDurationMS / 1000} seconds`}
        </td>
        <td>
            {`${metric.lowDurationMS / 1000} seconds`}
        </td>
        <td>
            { metric.totalRequests }
        </td>
    </tr>

export default SearchMetricsSummaryViewPrompt;