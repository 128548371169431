export const getImgViewContainerStyle = (theme) => ({
    width: theme.isTablet() ? '18rem' : 'calc(100% - 1.5rem)',
    height: 'auto',
    cursor: 'pointer',
    wordWrap: 'anywhere'
});

export const getLargeImgViewContainerStyle = (theme) => ({
    width: theme.isTablet() ? '24rem' : 'calc(100% - 1.5rem)',
    height: 'auto',
});

export const getLargeImgViewStyle = (theme) => ({
    backgroundColor: theme.primaryColor,
    borderRadius: '.25rem',
    maxWidth: theme.isTablet() ? '18rem' : 'calc(100% - 1.5rem)',
    height: 'auto',
    maxHeight: theme.isTablet() ? '18rem' : 'auto',
    width: theme.isTablet() ? 'auto' : 'calc(100% - 1.5rem)',
});

export const getImgWrapperStyle = (theme) => ({
    cursor: 'pointer'
});

export const getImgViewStyle = (theme) => ({
    backgroundColor: theme.primaryColor,
    borderRadius: '.25rem',
    maxWidth: theme.isTablet() ? '18rem' : 'calc(100% - 1.5rem)',
    height: 'auto',
    maxHeight: theme.isTablet() ? '18rem' : 'auto',
    width: theme.isTablet() ? 'auto' : 'calc(100% - 1.5rem)',
});

export const getImgAddViewContainerStyle = (theme) => ({
    width: '9rem',
    height: 'auto'
});

export const getImgAddViewStyle = (theme) => ({
    borderRadius: '.25rem',
    maxWidth: '9rem',
    height: 'auto',
    maxHeight: '9rem',
    width: 'auto',
});

export const getImgTextStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '9rem',
    flexDirection: 'column',
    flexWrap: 'wrap',
    wordBreak: 'break-all',
});

export const getImgListContainerStyle = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '.5rem .5rem 1.25rem'
});

export const getImgAddContainerStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '1.5rem',
    margin: '1rem .75rem 2rem .75rem',
});
