import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

export const ReportIssueContainer = ({ children }) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme),
                ...theme.getGeneralBottomSpacerStyle(theme),
            }}>
                Report an Issue
            </h2>
            <div style={bodyStyle}>
                <p>
                    Please describe in detail any issue you are experiencing
                </p>
                <br />

                { children }

            </div>
        </section>
    );
};

