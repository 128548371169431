export const BX_EVENT_CONTEXT_PAY = 'pay';
export const BX_EVENT_CONTEXT_IP = 'ip';

const validValues = [
    BX_EVENT_CONTEXT_PAY,
    BX_EVENT_CONTEXT_IP
];

const getDropDownValues = () => [
    {
        name: 'Pay API',
        value: BX_EVENT_CONTEXT_PAY
    },
    {
        name: 'IP Event',
        value: BX_EVENT_CONTEXT_IP
    }
];

const isValid = value => !! validValues.includes(value);

const BxEventContextEnum = {
    BX_EVENT_CONTEXT_PAY,
    BX_EVENT_CONTEXT_IP,
    getDropDownValues,
    isValid
};

export default BxEventContextEnum;
