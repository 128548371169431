import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { NavLink } from 'react-router-dom';
import CarrierMemberRoleEnum from './../../prompt/core/enum/carrier-member-role';
import theme from '../../theme';
import UpdateCarrierGroupContactInfoPrompt from './../../prompt/update-carrier-group-contact-info';

const CompanyHeader = ({ 
    carrierGroup, 
    carrierMember,
    locationCount,
    onContactInfoUpdated,
    updateAllowJoins,
    pendingBidCount,
    pendingJobCount,
    updateAcceptDeliveries
}) => {
    const [ isUpdateContactDetailsPromptOpen, setIsUpdateContactDetailsPromptOpen ] = useState(false);
    const unableToAcceptDeliveries = !carrierGroup.isAcceptingDeliveries && locationCount < 1 ? true : false;
    return (
        <div>
            <h3>
                { carrierGroup.name }
            </h3>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>
                                Creator
                            </strong>
                        </td>
                        <td>
                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to={`/user/${carrierGroup.creatorUserId}`}
                            >
                                { carrierGroup.creatorUsername }
                            </NavLink>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Company Phone
                            </strong>
                        </td>
                        <td>
                            { carrierGroup.contactPhone }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Company Email
                            </strong>
                        </td>
                        <td>
                            { carrierGroup.contactEmail }
                        </td>
                    </tr>
                    {
                        carrierMember.roles.includes(CarrierMemberRoleEnum.CARRIER_MEMBER_ROLE_GROUP_ADMIN) && 
                            <tr>
                                <td>

                                </td>
                                <td>
                                    <button 
                                        className="test-carrierGroupDetailsCompanyHeaderUpdateCompanyContactDetailsBtn"
                                        onClick={event => { setIsUpdateContactDetailsPromptOpen(true) }}
                                    >
                                        Update Company Contact Details
                                    </button>
                                </td>
                            </tr>
                    }
                    <tr>
                        <td>
                            <strong>
                                Accepting Deliveries
                            </strong>
                        </td>
                        <td>
                            <div>
                                <span>
                                    { carrierGroup.isAcceptingDeliveries ? 'Yes' : 'No' }
                                </span>
                                {
                                    carrierMember.roles.includes(CarrierMemberRoleEnum.CARRIER_MEMBER_ROLE_DELIVERY_ADMIN) && 
                                        <div>
                                            {
                                                unableToAcceptDeliveries && 
                                                    <span>
                                                        You must have at least one carrier company location to accept deliveries.
                                                        <NavLink 
                                                            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                                            to={`/profile/groups/carrier/${carrierGroup.id}/locations`}
                                                        >
                                                            Add a location
                                                        </NavLink>
                                                    </span>
                                            }
                                            <button 
                                                disabled={unableToAcceptDeliveries}
                                                className="test-carrierGroupDetailsCompanyHeaderUpdateAcceptDeliveriesBtn"
                                                onClick={ event => { 
                                                    updateAcceptDeliveries(!carrierGroup.isAcceptingDeliveries);
                                                } }
                                            >
                                                { carrierGroup.isAcceptingDeliveries ? 'Stop Accepting Deliveries' : 'Accept Deliveries' }
                                            </button>
                                        </div>
                                }
                            </div>                    
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Accepting Join Requests
                            </strong>
                        </td>
                        <td>

                            <div>
                                <span>
                                    { carrierGroup.allowJoinGroupRequests ? 'Yes' : 'No' }
                                </span>
                                {
                                    carrierMember.roles.includes(CarrierMemberRoleEnum.CARRIER_MEMBER_ROLE_GROUP_ADMIN) && 
                                        <button 
                                            className="test-carrierGroupDetailsCompanyHeaderAcceptingJoinRequestsBtn"
                                            onClick={event => { updateAllowJoins(!carrierGroup.allowJoinGroupRequests) }}
                                        >
                                            { carrierGroup.allowJoinGroupRequests ? 'Stop Accepting Join Requests' : 'Accept Join Requests' }
                                        </button>
                                }
                            </div> 
                            
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Actions
                            </strong>
                        </td>
                        <td>
                            {
                                carrierMember.roles.includes(CarrierMemberRoleEnum.CARRIER_MEMBER_ROLE_GROUP_ADMIN) && 
                                    <NavLink 
                                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                        to={`/profile/groups/carrier/${carrierGroup.id}/members`}
                                    >
                                        <div>
                                            Manage Members
                                        </div>
                                    </NavLink>
                            }

                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to={`/profile/groups/carrier/${carrierGroup.id}/locations`}
                            >
                                <div>
                                    Manage Locations
                                </div>
                            </NavLink>

                            {
                                carrierMember.roles.includes(CarrierMemberRoleEnum.CARRIER_MEMBER_ROLE_CARRIER) && 
                                    <NavLink 
                                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                        to={`/profile/groups/carrier/${carrierGroup.id}/trips/my`}
                                    >
                                        <div>
                                            Manage My Trips
                                        </div>
                                    </NavLink>
                            }

                            {
                                /* istanbul ignore next */
                                carrierMember.roles.includes(CarrierMemberRoleEnum.CARRIER_MEMBER_ROLE_DELIVERY_ADMIN) && 
                                    <NavLink 
                                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                        to={`/profile/groups/carrier/${carrierGroup.id}/requests`}
                                    >
                                        <div>
                                            {`${pendingBidCount} Pending Bid Request${pendingBidCount > 1 ? 's' : ''}`}
                                        </div>
                                    </NavLink>
                            }

                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to={`/profile/groups/carrier/${carrierGroup.id}/job/pending`}
                            >
                                <div>
                                    {`${pendingJobCount} Pending Job Request${pendingJobCount > 1 ? 's' : ''}`}
                                </div>
                            </NavLink>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Dialog 
                onClose={ event => { 
                    /* istanbul ignore next */
                    setIsUpdateContactDetailsPromptOpen(false);
                }}
                open={isUpdateContactDetailsPromptOpen}
            >
                <div style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    <h3>
                        { `Update ${carrierGroup.name} Company Contact Details` }
                    </h3>
                    
                    <UpdateCarrierGroupContactInfoPrompt
                        carrierGroupId={carrierGroup.id}
                        contactEmail={carrierGroup.contactEmail}
                        contactPhone={carrierGroup.contactPhone}
                        onCarrierGroupUpdated={onContactInfoUpdated}
                    />

                </div>
            </Dialog>
        </div>
    );
};

export default CompanyHeader;
