import React from 'react';
import {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_PICK_UP,
    OBTAIN_TYPE_REMOTE,
} from './../../prompt/core/enum/obtain-type';
import theme from './../../theme';
import LocationMapPanel from './../location-map';

/* istanbul ignore next */
const getLocationFromDetail = (detail) =>  detail.locationLimit && detail.locationLimit.area && detail.locationLimit.area.id ? detail.locationLimit.area : JSON.parse(detail.locationLimit.area);

/* istanbul ignore next */
const LocationLimits = detail => 
    ! detail.locationLimit.area ? '' : <section>
        <section>
            <span>
                { detail.locationLimit.area }
            </span>    
        </section>
        
        {
            ! detail.locationLimit.distance ? '' : <section>
                { detail.locationLimit.distance }
            </section>
        }

    </section>

const ShipToAddressPanel = (detail, contentType) => {
    try
    {
        const location = getLocationFromDetail(detail);
        return (
            <div>
                <p>
                    { `${contentType === 'offer' ? 'Poster' : 'Bidder' } will use a shipping company to deliver ${contentType === 'offer' ? 'from' : 'to' } ${location.regionalAddress}` }
                </p>

            </div>
        );
    }
    catch(err)
    {
        /* istanbul ignore next */
        return (
            <div>
                <p>
                    { `${contentType === 'offer' ? 'Poster' : 'Bidder' } will ship to ${ contentType === 'offer' ? 'bidder' : 'poster' }` }
                </p>
                { LocationLimits(detail) }
            </div>
        );
    }
};

const PickUpPanel = (detail, contentType) => {
    try
    {
        const location = getLocationFromDetail(detail);
        return (
            <div>
                <p>
                    { 
                        contentType === 'offer' ? `Bidder will pickup from poster in ${location.regionalAddress}` : (
                            detail.locationLimit.distance ? 
                                `Poster will pick up from bidder within ${detail.locationLimit.distance} miles of ${location.regionalAddress}` : 
                                    `Poster will pick up from bidder in ${location.regionalAddress}` 
                        )
                    }
                </p>

                {
                    contentType === 'seek' && detail.locationLimit.distance && 
                        <LocationMapPanel
                            selectedLocation={location}
                            limit={detail.locationLimit.distance}
                            isRegion={true}
                            forceAnon={true}
                        />
                }

            </div>
        );
    }
    catch(err)
    {
        /* istanbul ignore next */
        return (
            <div>
                <p>
                    {`${contentType === 'offer' ? 'Bidder' : 'Poster'} picks up from ${contentType === 'offer' ? 'poster' : 'bidder'}`}
                </p>
                { LocationLimits(detail) }
            </div>
        );
    }
};
    

const HandToHandDeliveryPanel = (detail, contentType) => {
    try
    {
        const location = getLocationFromDetail(detail);
        return (
            <div>
                <p>
                    { 
                        /* istanbul ignore next */
                        contentType === 'offer' && detail.locationLimit.distance ? 
                        `Poster will deliver to bidder within ${detail.locationLimit.distance} miles of ${location.regionalAddress}` :
                        `${contentType === 'offer' ? 'Poster' : 'Bidder'} will deliver to ${contentType === 'offer' ? 'bidder' : 'poster'} ${contentType === 'offer' ? 'from' : 'in'} ${location.regionalAddress}` 
                    }
                </p>

                {
                    contentType === 'offer' && detail.locationLimit.distance && 
                        <LocationMapPanel
                            selectedLocation={location}
                            limit={detail.locationLimit.distance}
                            isRegion={true}
                            forceAnon={true}
                        />
                }
            </div>
        );
    }
    catch(err)
    {
        /* istanbul ignore next */
        return (
            <div>
                <p>
                    { `${contentType === 'offer' ? 'Poster' : 'Bidder' } will personally deliver to ${contentType === 'offer' ? 'bidder' : 'poster'}.` }
                </p>
                { LocationLimits(detail) }
            </div>
        );
    }
};

const RemoteDeliveryPanel = (detail, contentType) => 
    <section>
        <p>
            Remote delivery.
        </p>
        { LocationLimits(detail) }
    </section>

const OnSitePanel = (detail, contentType) => {
    
    try
    {
        const location = getLocationFromDetail(detail);
        return (
            <div>
                <p>
                    { 
                        detail.locationLimit.distance ? 
                            `On site traveling within ${detail.locationLimit.distance} miles of ${location.regionalAddress}` :
                                `On site traveling ${contentType === 'offer' ? 'from' : 'to'} ${location.regionalAddress}` 
                    }
                </p>

                {
                    detail.locationLimit.distance && 
                        <LocationMapPanel
                            selectedLocation={location}
                            limit={detail.locationLimit.distance}
                            isRegion={true}
                            forceAnon={true}
                        />
                }
            </div>
        );
    }
    catch(err)
    {
        /* istanbul ignore next */
        return (
            <div>
                <p>
                    On Site delivery.
                </p>
                { LocationLimits(detail) }
            </div>
        );
    }
};

const SwitchObtainTypeDetailPanel = (detail, contentType) => detail.type === OBTAIN_TYPE_SHIP_TO_ADDRESS ? ShipToAddressPanel(detail, contentType) : (
    detail.type === OBTAIN_TYPE_HAND_TO_HAND ? HandToHandDeliveryPanel(detail, contentType) : (
        detail.type === OBTAIN_TYPE_PICK_UP ? PickUpPanel(detail, contentType) : (
            detail.type === OBTAIN_TYPE_REMOTE ? RemoteDeliveryPanel(detail, contentType) : 
                OnSitePanel(detail, contentType)
        )
    )
);

const ObtainTypeDetailPanel = (detail, index, contentType) => 
    <div 
        style={{
            ...theme.getObtainTypeDetailPanelStyle(theme),
        }}
        key={index}
    >
        { SwitchObtainTypeDetailPanel(detail, contentType) }
    </div>

const ObtainTypeDetailListPanel = ({ obtainTypeDetailList, contentType }) => 
    <div>
        { obtainTypeDetailList.length > 0 ? 
        <section style={{
            ...theme.getAbstractItemDetailFlexStyle(theme),
        }}>
            <h4 style={{
                ...theme.getAbstractTextAlignLeftStyle(theme),
            }}>
                { obtainTypeDetailList.length > 1 ? 'Delivery Options' : 'Delivery Option' }
            </h4>
            <div>
                { obtainTypeDetailList.map((detail, index) => ObtainTypeDetailPanel(detail, index, contentType)) }
            </div>
        </section> : '' }
    </div>
    
export default ObtainTypeDetailListPanel;
