import React from 'react';
import DetailPanel from './../core/detail';
import CarrierMemberService from './../../../service/carrier-member';
import CarrierGroupInvitesViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _CarrierGroupInvitesPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.acceptInvite = this.acceptInvite.bind(this);
        this.declineInvite = this.declineInvite.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierMemberService.listInvites({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }


    onLoadSpecifiedPage(page)
    {
        CarrierMemberService.listInvites({
            request: {
                page
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.loadData({
            page: 0
        });
    }

    getLoadedDataDetailUi()
    {
        return <CarrierGroupInvitesViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            acceptInvite={this.acceptInvite}
            declineInvite={this.declineInvite}
        />
    }

    /* istanbul ignore next */
    acceptInvite(carrierGroupId)
    {
        this.sendUserTo(`/profile/groups/carrier/${carrierGroupId}`);
    }

    declineInvite(invite)
    {
        super.loadData({ });
        CarrierMemberService.declineInvite({
            request: {
                pendingCarrierMemberId: invite.id
            },
            onResponse: (response) => {
                this.loadData({
                    page: 0
                });
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }
}

const CarrierGroupInvitesPanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierGroupInvitesPanel);
export default CarrierGroupInvitesPanel;
