import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import UserSettingsPanel from './../ui/panel/user-settings';
import theme from './../ui/theme';

const ProfileSettingsPage = () => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => theme.getProfilePwdPageStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate childMenu={
            <ProfileMenu />
        }>
            <div style={{
                ...theme.getInLineTextStyle(theme),
            }}>
                <h2>
                    Manage Settings
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Specify your account preferences
                </h4>
            </div>
            <div style={containerStyle}>
                <UserSettingsPanel />
            </div>

            
        </AuthPageTemplate>
    );
};

export default ProfileSettingsPage;
