import React from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const AuthorPanel = ({ author }) => 
    <div style={{
        ...theme.getAuthorFlexStyle(theme),
        ...theme.getAbstractTextBaselineStyle(theme)
    }}>
        <h4 style={{
            ...theme.getInLineTextTitleStyle(theme)
        }}>
            Posted By
        </h4>
        :&nbsp;
        <NavLink style={{
                ...theme.getAuthorUserNameStyle(theme),
                ...theme.getGeneralTextLinkStyle(theme),
        }}
            to={`/user/${author.id}`}>
            { author.username }
        </NavLink>
    </div>

export default AuthorPanel;