import React from 'react';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../abstract';
import SelectAttSetPrompt from './select-att-set';
import Dialog from '@mui/material/Dialog';
import theme from './../../../theme';
import AddOneIcon from './../../../../img/addOne.svg';
import AddManyIcon from './../../../../img/addMany.svg';
import CloseIcon from './../../../../img/close.svg';
import toast from 'react-hot-toast';

class AttributeDetailViewPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, AttributeDetailViewPrompt.getValidators());

        this.getDefaultState = this.getDefaultState.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.triggerOnAddAttribute = () => { throw new Error('Missing onAddAttribute'); };
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getListItemUi = this.getListItemUi.bind(this);
        this.getListUi = this.getListUi.bind(this);
        this.onAttSetSelected = this.onAttSetSelected.bind(this);
        this.openAddFieldDialog = this.openAddFieldDialog.bind(this);
        this.closeAddFieldDialog = this.closeAddFieldDialog.bind(this);
        this.onCloseAddFieldDialog = this.onCloseAddFieldDialog.bind(this);
        this.openSelectFieldSetDialog = this.openSelectFieldSetDialog.bind(this);
        this.closeSelectFieldSetDialog = this.closeSelectFieldSetDialog.bind(this);
        this.onCloseSelectFieldSetDialog = this.onCloseSelectFieldSetDialog.bind(this);

        this.state = this.getDefaultState();

    }

    static getValidators()
    {
        return {
            name: {
                isValid: ValidateService.valueRequired
            },
            value: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getFieldsModel()
    {
        return {
            name: {
                name: 'Field Name*',
                error: 'Specify a field name',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            value: {
                name: this.props.valueFieldTitle !== undefined ? this.props.valueFieldTitle : 'Value*',
                error: 'Specify a value for this field',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            isValid: false,
            addFieldDialogOpen: false,
            selectFieldSetDialogOpen: false,
        };
    }

    /* istanbul ignore next */
    openSelectFieldSetDialog()
    {
        const nextState = {
            ...this.state,
            selectFieldSetDialogOpen: true
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    closeSelectFieldSetDialog()
    {
        const nextState = {
            ...this.state,
            selectFieldSetDialogOpen: false
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCloseSelectFieldSetDialog(event)
    {
        this.closeSelectFieldSetDialog();
    }

    /* istanbul ignore next */
    openAddFieldDialog()
    {
        const nextState = {
            ...this.state,
            addFieldDialogOpen: true
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    closeAddFieldDialog()
    {
        const nextState = {
            ...this.state,
            addFieldDialogOpen: false
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCloseAddFieldDialog(event)
    {
        this.closeAddFieldDialog();
    }

    componentDidMount()
    {
        /* istanbul ignore next */
        this.triggerOnAddAttribute = () => {
            this.props.onAddAttribute(this.getServerRequest());
            const nextState = {
                ...this.state,
                isValid: true
            };
            this.setState(nextState);
            toast('Field Added');
        };
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        this.triggerOnAddAttribute();
        let nextState = this.getBaseStateOnResponse({ message: '', success: false });
        nextState = this.clearFields(nextState);
        nextState.addFieldDialogOpen = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            name: this.state.name.value,
            value: this.state.value.value,
        };
    }

    getListItemUi(attDetail, index)
    {
        return (
            <div 
                style={{
                    ...theme.getAttributeListItemStyle(theme),
                }}
                key={index}
            >
               
                <theme.getIconButtonUi 
                    theme={theme}
                    icon={CloseIcon}
                    className="test-coreAttributeDetailPromptRemoveBtn"
                    onClick={
                        /* istanbul ignore next */
                        event => {
                           this.props.onRemoveAttribute(index)
                        }
                    }
                    customContainerStyle={{
                        ...theme.getAttributeDetailRemoveCustomContainerStyle(theme),
                    }}
                />
                

                <div style={{
                    ...theme.getAttributeDetailNameStyle(theme),
                }}>
                    { attDetail.name }
                </div>

                <div style={{
                    ...theme.getAttributeDetailFieldOuterContainerStyle(theme),
                }}>
                    <input style={{
                        ...theme.getAttributeDetailFieldContainerStyle(theme),
                        }}
                        className="test-coreAttributeDetailPromptTextLine"
                        onChange={event => {
                            this.props.onFieldValueUpdate(index, event.target.value)
                        }}
                        type="text" 
                        value={attDetail.value}
                        
                    />
                </div>
            </div>
        );
    }

    getListUi()
    {
        return this.props.attributeList.length > 0 ? this.props.attributeList.map(this.getListItemUi) : 
            <div style={{
                ...theme.getAbstractCenterFlexContainerStyle(theme)  
            }}>
                <p>
                    Nothing Added.
                </p>
            </div>
    }

    /* istanbul ignore next */
    onAttSetSelected(attSet)
    {
        attSet.attributeList.forEach(att => {
            const { name, value } = att;
            this.props.onAddAttribute({
                name,
                value
            });
        });
        let nextState = this.state;
        nextState.selectFieldSetDialogOpen = false;
        this.setState(nextState);
    }
    
    getPromptUi()
    {
        return (
            <section style={{
                ...theme.getProfilePwdBodyStyle(theme),
            }}>
                <h2>
                    { this.props.promptTitle }
                </h2>
                
                <h4 style={{
                    ...theme.getAbstractTextAlignCenterStyle(theme),
                }}>
                    Define any custom fields specific to your posting such as weight for produce, item amount per order, etc.
                </h4>

                <div style={{
                    ...theme.getAttributeFlexContainerStyle(theme),
                }}>
                
                    <theme.getIconButtonUi 
                        theme={theme}
                        icon={AddOneIcon} 
                        className="test-coreAttributeDetailPromptAddBtn"
                        onClick={this.openAddFieldDialog} 
                    />
                    
                    <theme.getIconButtonUi 
                        theme={theme}
                        icon={AddManyIcon} 
                        className="test-coreAttributeDetailPromptAddManyBtn"
                        onClick={this.openSelectFieldSetDialog} 
                    />
                </div>

                <Dialog 
                    onClose={this.onCloseAddFieldDialog}
                    open={this.state.addFieldDialogOpen}
                >
                    <div style={{
                        ...theme.getAttributeAddDialogStyle(theme),
                    }}> 
                        <h3> 
                            Add Field
                        </h3>
                        { super.getPromptUi() }
                    </div>
                </Dialog>
                
                <Dialog
                    onClose={this.onCloseSelectFieldSetDialog}
                    open={this.state.selectFieldSetDialogOpen}
                >
                    <div style={{
                        ...theme.getAttributeSelectDialogStyle(theme),
                    }}>
                        <SelectAttSetPrompt 
                            onCloseSelectFieldSetDialog={this.onCloseSelectFieldSetDialog}
                            onAttSetSelected={this.onAttSetSelected} 
                        />
                    </div>
                </Dialog>

                <div style={{
                    ...theme.getAttributeListStyle(theme),
                }}>
                    { this.getListUi() }
                </div>
            </section>
        );
    }
}

export default AttributeDetailViewPrompt;
