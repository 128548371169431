import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import UiService from './../../../service/ui';
import Pagination from './../../prompt/core/pagination';
import CloseIcon from './../../../img/close.svg';
import CloseDarkIcon from './../../../img/closeWhite.svg';
import theme from './../../theme';

const NoticeDetails = ({ notice, viewNotice, deleteNotice }) => {
    const getContainerStyle = () => notice.unseen ? theme.getUnSeenNoticeDetailsContainerStyle(theme) : theme.getNoticeDetailsContainerStyle(theme);
    const buttonStyle = notice.unseen ? theme.getUnSeenNoticeDetailsDeleteButtonStyle(theme, CloseDarkIcon) : theme.getNoticeDetailsDeleteButtonStyle(theme, CloseIcon);
    
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={containerStyle}>

            <div 
                className="test-noticesDeleteBtn"
                onClick={event => { deleteNotice(notice) }}
                style={buttonStyle}
            >
                
            </div>

            <div 
                style={{
                    ...theme.getNoticeDetailsTextStyle(theme)
                }}
                className="test-noticesTitleBtn"
                onClick={event => { viewNotice(notice)  }} 
            >
                { notice.title }
            </div>

            <div 
                style={{
                    ...theme.getNoticeDetailsTimeStyle(theme)
                }}
            >
                { UiService.timeSince(notice.createdOn) }
            </div>

            

            <div style={{
                clear: 'both'
            }}></div>
        </div>
    );
};

const Notice = ({ notice, index, viewNotice, deleteNotice }) => {
    return (
        <li key={index}  
            style={{
                ...theme.getNoticeListItemStyle(theme)
            }}
        >
            <NoticeDetails notice={notice} viewNotice={viewNotice} deleteNotice={deleteNotice} />
        </li>
    );
}

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination total={data.total} page={data.page} perPage={data.perPage} onLoadSpecifiedPage={onLoadSpecifiedPage} />

const PopulatedView = ({ data, deleteNotice, viewNotice, deleteAllNotices, markAllNoticesSeen, unseenNoticeCount, onLoadSpecifiedPage }) => {
    const getContainerStyle = () => theme.getNotificationsContainerStyle(theme);
    const getNoticeDetailStyle = () => theme.getNoticeDetailsStyle(theme);

    const [ noticeDetailStyle, setNoticeDetailStyle ] = useState( getNoticeDetailStyle() );
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoticeDetailStyle( getNoticeDetailStyle() );
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <h2>
                Notifications
            </h2>
            <div style={containerStyle}>
                <button
                    className="test-noticesDeleteAllBtn"
                    onClick={event => { deleteAllNotices() }}
                >
                    Delete All
                </button>
                
                { 
                    unseenNoticeCount > 0 ? 
                        <button
                            className="test-noticesMarkAllSeenBtn"
                            onClick={event => { markAllNoticesSeen() }}
                        >
                            Mark All Seen
                        </button> : ''
                }
            </div>
            { getPaginationUi({ data, onLoadSpecifiedPage }) }
            <ul style={noticeDetailStyle}>
                { data.list.map(( notice, index) => Notice({ notice, index, viewNotice, deleteNotice })) }
            </ul>
            { getPaginationUi({ data, onLoadSpecifiedPage }) }
        </div>
    );
};

const EmptyView = () => 
    <div style={{ 
        ...theme.getGeneralTextStyle(theme),
    }}>
        <h2>
            No Notifications
        </h2>
    </div>

const NoticesViewPanel = ({ 
    data, 
    onLoadSpecifiedPage,
    deleteNotice, 
    deleteAllNotices,
    markAllNoticesSeen,
    viewNotice,
    unseenNoticeCount
}) => data.list.length > 0 ? PopulatedView({ 
    data, 
    onLoadSpecifiedPage,
    unseenNoticeCount,
    deleteNotice, 
    viewNotice, 
    deleteAllNotices, 
    markAllNoticesSeen }) : EmptyView()

export default NoticesViewPanel;
