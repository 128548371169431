import React from 'react';
import { useState } from 'react';
import Toolbar from './toolbar';
import PlanDelivery from './../plan-delivery';
import AlignTrips from './../align-trips';
import PlanPricing from './../plan-pricing';
import PlanPricingConfirmations from './../plan-pricing-confirmations';
import {
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_PRICING,
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_MEMBERS_CONFIRMING
} from './../../../prompt/core/enum/carrier-group-delivery-plan-status';

const PlanEditor = ({
    plan,
    price,
    carrierGroupId,
    delivery,
    members,
    onAddStep,
    mapColorKeys,
    setMapColorKeys,
    onRemoveStep,
    cargoItem,
    acceptingCargo,
    carryingCargo,
    onHookTrip,
    onCompleteDraftStatus,
    onCompleteSchedulingStatus,
    onCompletePricingStatus,
    onPriceUpdated,
    onPriceControlsUpdated,
    estimatedDollarsPerGallon,
    estimatedMPG,
    onSetPayout,
    onAddTrip,
    pendingPayoutUpdates,
    onAddExchange,
    onRemoveExchange,
    exchangeList,
    removeCargoFromCarrierGroupTrip
}) => {
    /* istanbul ignore next */
    const iniViewMapToTrips = plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT ? false : true;
    const [ viewMapToTrips, setViewMapToTrips ] = useState(iniViewMapToTrips);
    const toggleViewMapToTrips = event => { setViewMapToTrips(!viewMapToTrips) };
    const iniPricingModeSelectedView = 'plan-pricing';
    const [ pricingModeSelectedView, setPricingModeSelectedView ] = useState(iniPricingModeSelectedView);
    const setPricingModeViewToPlanPricing = event => { setPricingModeSelectedView('plan-pricing') };
    const setPricingModeViewToAlignTrips = event => { setPricingModeSelectedView('align-trips') };
    const setPricingModeViewToPlanDelivery = event => { setPricingModeSelectedView('plan-delivery') };


    return (
        <div>
            <Toolbar 
                plan={plan} 
                carrierGroupId={carrierGroupId}
                viewMapToTrips={viewMapToTrips}
                toggleViewMapToTrips={toggleViewMapToTrips}
                setPricingModeViewToPlanPricing={setPricingModeViewToPlanPricing}
                setPricingModeViewToAlignTrips={setPricingModeViewToAlignTrips}
                setPricingModeViewToPlanDelivery={setPricingModeViewToPlanDelivery}
                pricingModeSelectedView={pricingModeSelectedView}
            />

            {
                /* istanbul ignore next */
                plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_MEMBERS_CONFIRMING ? (
                    pricingModeSelectedView === 'plan-pricing' ? 
                        <PlanPricingConfirmations 
                            plan={plan}
                            price={price}
                            members={members}
                        /> : (
                            pricingModeSelectedView === 'align-trips' ? 
                                <AlignTrips 
                                    carrierGroupId={carrierGroupId}
                                    plan={plan}
                                    onAddTrip={onAddTrip}
                                    cargoItem={cargoItem}
                                    delivery={delivery}
                                    acceptingCargo={acceptingCargo}
                                    carryingCargo={carryingCargo}
                                    onHookTrip={onHookTrip}
                                    onCompleteSchedulingStatus={onCompleteSchedulingStatus}
                                    removeCargoFromCarrierGroupTrip={removeCargoFromCarrierGroupTrip}
                                /> :
                                    <PlanDelivery 
                                        plan={plan}
                                        delivery={delivery}
                                        members={members}
                                        onAddStep={onAddStep}
                                        carrierGroupId={carrierGroupId}
                                        mapColorKeys={mapColorKeys}
                                        setMapColorKeys={setMapColorKeys}
                                        onRemoveStep={onRemoveStep}
                                        onCompleteDraftStatus={onCompleteDraftStatus}
                                    />
                        )
                ) : ''
            }

            {
                /* istanbul ignore next */
                plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_PRICING ? (
                    pricingModeSelectedView === 'plan-pricing' ? 
                        <PlanPricing
                            onPriceControlsUpdated={onPriceControlsUpdated}
                            carrierGroupId={carrierGroupId}
                            onCompletePricingStatus={onCompletePricingStatus}
                            price={price}
                            onSetPayout={onSetPayout}
                            plan={plan}
                            cargoItem={cargoItem}
                            delivery={delivery}
                            carryingCargo={carryingCargo}
                            onAddExchange={onAddExchange}
                            onRemoveExchange={onRemoveExchange}
                            members={members}
                            onPriceUpdated={onPriceUpdated}
                            pendingPayoutUpdates={pendingPayoutUpdates}
                            estimatedMPG={estimatedMPG}
                            estimatedDollarsPerGallon={estimatedDollarsPerGallon}
                            exchangeList={exchangeList}
                        /> : (

                            pricingModeSelectedView === 'align-trips' ? 
                                <AlignTrips 
                                    carrierGroupId={carrierGroupId}
                                    plan={plan}
                                    onAddTrip={onAddTrip}
                                    cargoItem={cargoItem}
                                    delivery={delivery}
                                    acceptingCargo={acceptingCargo}
                                    carryingCargo={carryingCargo}
                                    onHookTrip={onHookTrip}
                                    onCompleteSchedulingStatus={onCompleteSchedulingStatus}
                                    removeCargoFromCarrierGroupTrip={removeCargoFromCarrierGroupTrip}
                                /> :
                                    <PlanDelivery 
                                        plan={plan}
                                        delivery={delivery}
                                        members={members}
                                        onAddStep={onAddStep}
                                        carrierGroupId={carrierGroupId}
                                        mapColorKeys={mapColorKeys}
                                        setMapColorKeys={setMapColorKeys}
                                        onRemoveStep={onRemoveStep}
                                        onCompleteDraftStatus={onCompleteDraftStatus}
                                    />
                        )
                ) : ''
            }

            {
                /* istanbul ignore next */
                [
                    CARRIER_GROUP_DELIVERY_PLAN_STATUS_DRAFT,
                    CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING
                ].includes(plan.status) ? ( viewMapToTrips ? 
                    <AlignTrips 
                        carrierGroupId={carrierGroupId}
                        plan={plan}
                        cargoItem={cargoItem}
                        onAddTrip={onAddTrip}
                        delivery={delivery}
                        acceptingCargo={acceptingCargo}
                        carryingCargo={carryingCargo}
                        onHookTrip={onHookTrip}
                        onCompleteSchedulingStatus={onCompleteSchedulingStatus}
                        removeCargoFromCarrierGroupTrip={removeCargoFromCarrierGroupTrip}
                    /> : 
                        <PlanDelivery 
                            plan={plan}
                            delivery={delivery}
                            members={members}
                            onAddStep={onAddStep}
                            carrierGroupId={carrierGroupId}
                            mapColorKeys={mapColorKeys}
                            setMapColorKeys={setMapColorKeys}
                            onRemoveStep={onRemoveStep}
                            onCompleteDraftStatus={onCompleteDraftStatus}
                        />
                ) : ''
            }
           

        </div>
    );
}
export default PlanEditor;
