import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

export const Header = () => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section style={{
            ...theme.getInLineTextStyle(theme),
        }}>
            <h2>
                Manage my platforms
            </h2>
            <h3 style={noMarginStyle}>
                -
            </h3>
            <h4>
                List other platforms where users can find you.
            </h4>
       
        </section>
    );
}