import React from 'react';
import {
    PaymentElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import theme from './../../theme';
import config from './../../../config';
import CheckoutService from './../../../service/checkout';
import menuDetails from '../../../store/select/menu-details';
import { useSelector } from 'react-redux';

// istanbul ignore next 
const PayViewPrompt = ({ clientSecret }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [ message, setMessage ] = useState('');
    const [ isSubmit, setIsSubmit ] = useState(false);
    const [ formEnabled, setFormEnabled ] = useState(true);
    const { showFullMenu } = useSelector(menuDetails);

    const onSubmit = async (event) => {
        try
        {
          event.preventDefault();
      
          if(!stripe || !elements)
          {
            return;
          }
    
          setFormEnabled(false);
          setIsSubmit(true);
    
          const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: config.pay.paymentMethodAddedUrl
            }
          });
    
          if(result.error)
          {
            const errorMessage = result.error.message ? result.error.message : 'An Error Has Occurred';
            setMessage(errorMessage);
          }
          else
          {
            setMessage('Added');
          }
    
          setFormEnabled(true);
        }
        catch(err)
        {
          setMessage('Error');
          setFormEnabled(true);
        }
      };

      useEffect(() => {
    
        if(!stripe || !elements)
        {
          return;
        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    
          if(isSubmit)
          {
            const message = CheckoutService.getMessageFromPaymentIntent(paymentIntent);
            setMessage(message);
          }
        });
        
      }, [stripe, isSubmit, clientSecret, elements]);

      return (
        <div style={{
            ...theme.getPayFormContainerStyle(theme),
        }}>
            {
              message ? 
              <p>
                { message }
              </p> : ''
            }
            {
              showFullMenu && 
              <form onSubmit={(event) => { onSubmit(event) }}>
                  <PaymentElement />
                  <button disabled={!formEnabled}>
                      Pay
                  </button>
              </form>
            }
        </div>
      );
};

export default PayViewPrompt;
