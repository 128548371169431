import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import Pagination from './../../prompt/core/pagination';
import { NavLink } from 'react-router-dom';
import UiService from './../../../service/ui';
import CurrencyService from './../../../service/currency';
import ResizeService from './../../../service/resize';

// istanbul ignore next 
export const Status = ({ trans }) => {
    const feeLink = <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to="/profile/fees"
                    >
                        Waiting on unpaid fee(s)
                    </NavLink>
    return (
        <span>
            {
                trans.lockedForDispute && !trans.hasRefunded ? (
                    trans.disputeDetails && trans.disputeDetails.length > 0 && trans.disputeDetails[0].isChargeBack ? 
                        'Disputed - Chargeback' : 'Disputed'
                    ) : (
                    trans.payoutFailed ? ( trans.isAllowAccessFailure ? feeLink : 'Failed') : (
                        trans.paidOut ? `Paid - ${UiService.getStoryDate(trans.payoutTriggerDate)}` : (
                            trans.hasRefunded ? 'Refunded' : (
                                trans.flagged && !trans.flagReviewed ? 'Flagged' : (
                                    trans.exchangeConfirmedDate ? `Scheduled ${ UiService.getDateReport(trans.payoutTriggerDate) }` : (
                                        trans.exchangeStartedDate ? 'Awaiting Fulfillment Confirmation' : (
                                            trans.customerPaymentCompleted ? 'Awaiting Fulfillment' : (
                                                'Checkout Started'
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            }
        </span>
    );
};

// istanbul ignore next 
const TransView = ({ trans }) => 
    <tr>
        <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
            {
                trans.exchangeId !== '' ?
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to={`/exchange/${trans.exchangeId}`}
                    >
                        { trans.exchangeId }
                    </NavLink> : 
                    (
                        trans.type === 'offer' ? 
                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to={`/offer/${trans.targetId}`}
                            >
                                Offer
                            </NavLink> : 
                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to={`/seek/${trans.targetId}`}
                            >
                                Seek
                            </NavLink>
                    )
            }
        </td>
        <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
            { UiService.getDateReport(trans.createdOn) }
        </td>
        <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
            { trans.currency.toUpperCase() }
        </td>
        <td style={{
            ...theme.getAdminDataCellStyle(theme)
        }}>
            { 
                trans.exchangeId === '' ? ' - ' :
                    CurrencyService.getDisplayPrice(trans.currency, trans.total, false) 
            }
        </td>
        <td style={{
            ...theme.getAdminDataCellStyle(theme)
        }}>
            { 
                trans.exchangeId === '' || 
                trans.hasRefunded || 
                (trans.disputeDetails && trans.disputeDetails.length > 0 && trans.disputeDetails[0].isChargeBack) ? ' - ' : 
                    CurrencyService.getDisplayPrice(trans.currency, trans.fee, false) 
            }
        </td>
        <td style={{
            ...theme.getAdminDataCellStyle(theme)
        }}>
            { 
                trans.exchangeId === '' ||
                trans.hasRefunded ||
                (trans.disputeDetails && trans.disputeDetails.length > 0 && trans.disputeDetails[0].isChargeBack) ? ' - ' : 
                    CurrencyService.getDisplayPrice(trans.currency, trans.netTotal, false) 
            }
        </td>
        <td style={{
            ...theme.getAdminDataCellStyle(theme)
        }}>
            <Status trans={trans} />
        </td>
    </tr>

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />


const Header = () => 
    <thead>
        <tr>
            <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
                <strong>
                    Exchange
                </strong>
            </td>
            <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
                <strong>
                    Sale Date
                </strong>
            </td>
            <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
                <strong>
                    Currency
                </strong>
            </td>
            <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
                <strong>
                    Payment
                </strong>
            </td>
            <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
                <strong>
                    Fee
                </strong>
            </td>
            <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
                <strong>
                    Payout Amount
                </strong>
            </td>
            <td style={{
                ...theme.getAdminDataCellStyle(theme)
            }}>
                <strong>
                    Status
                </strong>
            </td>
        </tr>
    </thead>

const PopulatedView = ({ data, onLoadSpecifiedPage }) => 
    <section>
        { getPaginationUi({ data, onLoadSpecifiedPage }) }
        <div style={{
            ...theme.getPayoutTransactionContainerStyle(theme)
        }}> 
            <table style={{
                ...theme.getPayoutTransactionStyle(theme)
            }}> 
                <Header />
                <tbody>
                    {
                        data.list.map((trans, index) => <TransView trans={trans} key={index} />)
                    }
                </tbody>
            </table>
        </div>
        { getPaginationUi({ data, onLoadSpecifiedPage }) }
    </section>

const EmptyView = (parms) => 
    <section>
        <p>
            No Online Transactions
        </p>
    </section>

const DisabledView = (parms) => 
    <p>
        Before you can receive payouts you must 
        &nbsp;
        <NavLink 
            to="/profile/income" 
            style={{
                ...theme.getGeneralTextLinkStyle(theme), 
            }}
        >
            setup your account 
        </NavLink>
    </p>

const OnlineTransactionsListViewPanel = ({ data, onLoadSpecifiedPage }) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getAbstractTextAlignCenterStyle(theme)
        }}>
            <h2>
                    Payout Transactions
            </h2>
            
            <div style={bodyStyle}>
                <p>
                    Track your online payment payouts.
                </p>
                {
                    data.enabled ? (
                        data.list.length < 1 ? <EmptyView /> : <PopulatedView data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
                    ) : <DisabledView />
                }
            </div>
        </div>
    );
};

export default OnlineTransactionsListViewPanel;
