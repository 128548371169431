export const getGeneralTextStyle = (theme) => ({
    textAlign: 'center',
});

export const getGeneralStartTextStyle = (theme) => ({
    textAlign: 'start',
});

export const getGeneralBottomSpacerStyle = (theme) => ({
    marginBottom: '1rem',
});

export const getGeneralTopSpacerStyle = (theme) => ({
    marginTop: '1rem',
});

export const getGeneralTextLinkStyle = (theme) => ({
    textDecoration: 'underline',
    fontWeight: '700'
});

export const getOnClickLinkStyle = (theme) => ({
    cursor: 'pointer'
});

export const getGeneralDefaultBulletStyle = (theme) => ({
    margin: 'revert',
    padding: 'revert'
});

export const getFinePrintStyle = (theme) => ({
    fontSize: '8px'
});

export const getInLineTextStyle = (theme) => ({
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60rem',
    margin: '0 auto',
});

export const getInLineTextTitleStyle = (theme) => ({
    whiteSpace: 'nowrap'
});

export const getInLineColumnStyle = (theme) => ({
    flexDirection: 'column',
});

export const getGeneralFlexDirectionResizeStyle = (theme) => ({
    flexDirection: theme.isTablet() ? 'row' : 'column',
});

export const getSmallWarningTextStyle = (theme) => ({
    fontSize: '8pt',
    color: theme.warningColor,
});

export const getMediumBoldTextStyle = (theme) => ({
    fontSize: '14pt',
    fontWeight: '700',
});

export const getWarningTextStyle = (theme) => ({
    color: theme.warningColor,
});

export const getSmallTextStyle = (theme) => ({
    fontSize: '8pt',
});

export const getMediumTextStyle = (theme) => ({
    fontSize: '12pt',
});

export const getStepTileTextStyle = (theme) => ({
    fontSize: '26pt',
    textAlign: 'center',
});

export const generaLinkStyle = (theme)  => ({ 
    textDecoration: 'underline', 
    cursor: 'pointer' 
});
