import React from 'react';
import {
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Logout,
    UpdateError,
    UpdateDeviceNetwork
} from './../../../store/state/action';
import AuthTokenSelector from './../../../store/select/auth-token';
import config from './../../../config';
import CheckoutService from './../../../service/checkout';
import { Redirect } from 'react-router-dom';
import { iniPayClientDetails } from './../../../pay';
import PayNewCardViewPrompt from './view';

// istanbul ignore next 
const PayNewCardPrompt = ({ 
    payClientSecret, 
    payClientDetails,
    setPayClientSecret,
    onSuccessRedirectUrl,
    isFeePayment = false
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [ message, setMessage ] = useState('');
    const [ isSubmit, setIsSubmit ] = useState(false);
    const [ formEnabled, setFormEnabled ] = useState(true);
    const [ redirectTo, setRedirectTo ] = useState('');
    const [ canCheckout, setCanCheckout ] = useState(false);
    const authToken = useSelector(AuthTokenSelector);
    const dispatch = useDispatch();

    const onSubmitCard = async () => {
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: onSuccessRedirectUrl
            }
        });

        if(result.error)
        {
            const errorMessage = result.error.message ? result.error.message : 'An Error Has Occurred';
            setMessage(errorMessage);
            setFormEnabled(true);
        }
        else
        {
            setRedirectTo(onSuccessRedirectUrl);
            setMessage('Paid');
        }
    };

    const onCheckoutStatusResponse = async (response) => {
        setCanCheckout(response.canPurchase);
        if(response.canPurchase)
        {
            onSubmitCard();
        } 
        else
        {
            setMessage(response.message);
            setFormEnabled(true);
        }
    };

    const onAuthError = () => {
        dispatch(Logout());
    };

    const onFatalError = () => {
        dispatch(UpdateError(true, ''));
    };

    const onNetworkOffline = () => {
        dispatch(UpdateDeviceNetwork(false));
    };

    const onErrorResponse = () => {
        onFatalError();
    };

    const onSubmit = async (event) => {
        try
        {
            event.preventDefault();

            if(!stripe || !elements)
            {
                return;
            }

            setFormEnabled(false);
            setIsSubmit(true);

            if(isFeePayment)
            {
                onSubmitCard();
            }
            else
            {
                CheckoutService.isCheckoutAvailable({
                    request: {
                        type: payClientDetails.type,
                        targetId: payClientDetails.targetId
                    },
                    onResponse: onCheckoutStatusResponse,
                    onError: onErrorResponse,
                    authToken,
                    onAuthError,
                    onFatalError,
                    onNetworkOffline
                });
            }
        }
        catch(err)
        {
            setMessage(err.message);
            setFormEnabled(true);
        }
    };

    useEffect(() => {
        if(!stripe || !elements)
        {
            return;
        }

        if(canCheckout)
        {
            stripe.retrievePaymentIntent(payClientSecret).then(({paymentIntent }) => {
                if(isSubmit)
                {
                    const message = CheckoutService.getMessageFromPaymentIntent(paymentIntent);
                    setMessage('Processing');
                    setTimeout(() => {
                        setMessage(message);
                    }, config.pay.submitPaymentMethodFeedbackDelay);
                }
            });
        }
    }, [stripe, isSubmit, payClientSecret, elements, canCheckout]);

    useEffect(() => {
        return () => {
            setPayClientSecret('', iniPayClientDetails);
        }
    }, [setPayClientSecret]);

    return (
        redirectTo ? <Redirect to={redirectTo} /> :
            <PayNewCardViewPrompt 
                payClientDetails={payClientDetails}
                message={message}
                onSubmit={onSubmit}
                formEnabled={formEnabled}
            />
    );

};

export default PayNewCardPrompt;
