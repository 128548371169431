import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class CarrierJobService 
{
    static getListPendingJobsRequest(carrierGroupId, page)
    {
        return {
            carrierGroupId, 
            page
        };
    }

    /* istanbul ignore next */
    static listPendingJobs({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.member.jobs.listPending,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default CarrierJobService;
