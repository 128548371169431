import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import theme from './../../../theme';

/* istanbul ignore next */
export const PurgeLogs = ({
    onPurgeLogs
}) => {
    const [ confirmView, setConfirmView ] = useState(false);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <button onClick={event => { setConfirmView(true) }}>
                Purge Logs
            </button>
            <Dialog 
                onClose={event => { setConfirmView(false) }}
                open={confirmView}
            >
                <p>
                    Are you sure you want to purge all logs?
                </p>
                <button onClick={event => { 
                    onPurgeLogs();
                    setConfirmView(false);
                 }}>
                    Purge All Logs
                </button>
            </Dialog>

        </div>
    );
};

export const Header = () => 
    <thead>
        <tr>
            <td>
                Source
            </td>
            <td>
                Details
            </td>
        </tr>
    </thead>

export const AdminSearchLogsViewPrompt = ({ logRecord }) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const currentStyle = isOpen ? {} : {
        height: '20px',
        overflow: "hidden"
    };
    const changeStyle = event => {
        setIsOpen(!isOpen);
    };

    return (
       <div style={currentStyle} onClick={changeStyle}>
            <tr>
                <td>
                    { logRecord.context }
                </td>
                <td>
                    { logRecord.text }
                </td>
            </tr>
       </div>
    );
};