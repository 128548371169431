import React from 'react';
import theme from './../../../../theme';

const Header = ({
    members,
    record,
    
}) => {
    const foundMember = members.list.find(mem => mem.id === record.memberId);
    return (
        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            <div>
                {
`${foundMember.name}
${foundMember.contactEmail}
${foundMember.contactPhone}`
                    }
                    </div>
                    <div>
                        <img 
                            src={foundMember.photo.data} 
                            alt="File Missing" 
                            style={{
                                ...theme.getImgAddViewStyle(theme),
                            }}
                        />
                    </div>
            </div>
    );
};

export default Header;
