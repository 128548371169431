import React from 'react';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import UserService from './../../../service/user';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../prompt/core/abstract';
import theme from './../../theme';
import { NavLink } from 'react-router-dom';
import ChangePwdPromptContainer from './change-pwd-prompt-container';

class _EditProfileChangePwdPanel extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _EditProfileChangePwdPanel.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.validator.nextPwdRepeat.isValid = ValidateService.makeMatchFieldValue(this, 'nextPwd');
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            currentPwd: {
                isValid: ValidateService.valueRequired
            },
            nextPwd: {
                isValid: ValidateService.valueRequired
            },
            nextPwdRepeat: {
                isValid: ValidateService.textLine
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            currentPwd: {
                name: 'Current Password*',
                error: 'Specify your current password',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.PASSWORD
            },
            nextPwd: {
                name: 'New Password*',
                error: 'Specify your new password',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.PASSWORD
            },
            nextPwdRepeat: {
                name: 'New Password Repeated*',
                error: 'This value does not match the first password',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.PASSWORD
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    getSubmitButtonUi(buttonText = 'Update')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getPromptUi()
    {
        return (
            <ChangePwdPromptContainer>
                { super.getPromptUi() }
            </ChangePwdPromptContainer>
        );
    }

    getServerRequest()
    {
        return {
            currentPwd: this.state.currentPwd.value,
            nextPwd: this.state.nextPwd.value,
        };
    }

    onSendToServer()
    {
        UserService.updatePwd({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    getConfirmUi(confirmMessage = 'Password Updated')
    {
        return ( 
            <section>
                { super.getConfirmUi(confirmMessage) }
                <NavLink style={{ 
                    ...theme.getGeneralTextLinkStyle(theme),
                    ...theme.getAbstractPromptStyle(theme),
                    ...theme.getAbstractDisplayBlockStyle(theme)
                    }} 
                    to="/"
                >
                    Return to your Dashboard
                </NavLink>
            </section>
            
        );
    }

}

const EditProfileChangePwdPanel = connect(mapStateToProps, mapDispatchToProps)(_EditProfileChangePwdPanel);

export default EditProfileChangePwdPanel;
