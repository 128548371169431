import React from 'react';
import { Chart } from 'react-google-charts';

const CandlestickChartPanel = ({
    title,
    dataset,
    width = '100%', 
    height = '400px'
}) => {
    const options = {
        chart: {
            title
        },
        legend: "none"
    };
    return (
        <Chart
            chartType="CandlestickChart"
            width={width}
            height={height}
            data={dataset}
            options={options}
        />
    );
};

export default CandlestickChartPanel;
