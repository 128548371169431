import React from 'react';
import CarrierMemberService from './../../../service/carrier-member';
import EventBusService, { EVENT_CARRIER_MEMBERSHIP_GRANTED } from './../../../service/event-bus';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import DetailPanel from './../core/detail';
import PendingCarrierMembersViewPanel from './view';

class _PendingCarrierMembersPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onInviteSent = this.onInviteSent.bind(this);
        this.declineMembership = this.declineMembership.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierMemberService.listPending({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            carrierGroupId: inputObj.carrierGroupId,
            page: inputObj.page
        };
    }

    onInviteSent()
    {
        /* istanbul ignore next */
        this.onLoadSpecifiedPage(0);
        /* istanbul ignore next */
        EventBusService.dispatch(EVENT_CARRIER_MEMBERSHIP_GRANTED);
    }

    componentDidMount()
    {
        const carrierGroupId = this.props.carrierGroupId;
        this.loadData({
            carrierGroupId,
            page: 0
        });
    }

    /* istanbul ignore next */
    onLoadSpecifiedPage(page)
    {
        const carrierGroupId = this.props.carrierGroupId;
        this.loadData({
            carrierGroupId,
            page
        });
    }

    getLoadedDataDetailUi()
    {
        const carrierGroupId = this.props.carrierGroupId;
        return <PendingCarrierMembersViewPanel 
            data={this.state.data} 
            carrierGroupId={carrierGroupId}
            declineMembership={this.declineMembership}
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            onInviteSent={this.onInviteSent}
        />
    }

    /* istanbul ignore next */
    declineMembership(pendingMember)
    {
        super.loadData({ });
        CarrierMemberService.declineMembership({
            request: {
                pendingCarrierMemberId: pendingMember.id,
                isApproved: false
            },
            fileList: [],
            onResponse: (response) => {
                const carrierGroupId = this.props.carrierGroupId;
                this.loadData({
                    carrierGroupId,
                    page: 0
                });
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }
}

const PendingCarrierMembersPanel = connect(mapStateToProps, mapDispatchToProps)(_PendingCarrierMembersPanel);
export default PendingCarrierMembersPanel;
