import React from 'react';
import { useState, useEffect } from 'react';
import AuthPageTemplate from './template/auth-page';
import { UserWidgetPanel } from './../ui/panel/user-landing/view';
import { useParams } from 'react-router-dom';
import theme from './../ui/theme';
import ResizeService from '../service/resize';

const WidgetPage = () => {
    const getWidgetPageWrapperStyle = () => theme.getWidgetPageWrapperStyle(theme);
    const [ widgetPageWrapperStyle, setWidgetPageWrapperStyle ] = useState( getWidgetPageWrapperStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setWidgetPageWrapperStyle( getWidgetPageWrapperStyle() );
    });
    useEffect(resizeEffect);

    const { widgetType } = useParams();
    return (
        <AuthPageTemplate>
            <div style={widgetPageWrapperStyle}>
                <UserWidgetPanel type={widgetType} />
            </div>
        </AuthPageTemplate>
    );
};

export default WidgetPage;
