export const getUserDetailPlatformsContainerStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    textAlign: 'center',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)'
});

export const getUserDetailAdminPanelContainerStyle = (theme) => ({
    position: 'absolute',
    right: '35px',
    backgroundColor: theme.navColor,
    color: theme.primaryColor
});

export const getUserDetailAPlatformContainerStyle = (theme) => ({
    float: 'left',
    marginRight: '10px',
});

export const getUserDetailYourProfileButtonStyle = (theme) => ({
    marginLeft: '35px',
});

export const getUserDetailFriendPanelButtonStyle = (theme) => ({
    marginLeft: '10px',
    marginRight: '10px',
});

export const getUserDetailBlockedPanelButtonStyle = (theme) => ({
    marginLeft: '10px',
    marginRight: '10px',
});

export const getUserDetailOpenRelationButtonStyle = (theme) => ({
    marginLeft: '10px',
    marginRight: '10px',
});

export const getUserDetailRelationButtonsContainerStyle = (theme) => ({
    width: '400px',
    padding: '4px',
    marginLeft: 'auto',
    marginRight: 'auto',
});
