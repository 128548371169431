import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../../service/resize';
import UiService from './../../../../../service/ui';
import theme from './../../../../theme';
import Pagination from './../../../../prompt/core/pagination';

const StoryView = ({ story }) => 
    <div>
        <h4>
            { story.title }
        </h4>
        <h5>
            { UiService.getStoryDate(story.createdOn) }
        </h5>
        <p>
            { story.details }
        </p>
        <hr />
    </div>

const EmptyView = ({ userWidgetHeaderTextStyle }) => 
    <div style={userWidgetHeaderTextStyle}>
        <p>
            No stories available
        </p>
    </div>

const PopulatedView = ({ data }) => {
    const getNewsBodyStyle = () => theme.getNewsBodyStyle(theme);
    const [ newsBodyStyle, setNewsBodyStyle ] = useState(getNewsBodyStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNewsBodyStyle( getNewsBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={newsBodyStyle}>
            { 
                data.list.map((story, index) => 
                    <StoryView key={index} story={story} />
                )
            }
        </div>
    );
};

const GetPagination = ({ data, onLoadSpecifiedPage  }) => 
    data.page !== undefined && 
    data.perPage && 
    data.total ? <Pagination 
                    total={data.total}
                    page={data.page}
                    onLoadSpecifiedPage={ onLoadSpecifiedPage }
                    perPage={data.perPage} /> : '';



const WidgetNewsViewPanel = ({ data, onLoadSpecifiedPage }) => {
    
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle] = useState(getUserWidgetHeaderTextStyle());
    
    const getPageStyle = () => theme.getProfilePwdPageStyle(theme);
    const [ pageStyle, setPageStyle ] = useState( getPageStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetHeaderTextStyle( getUserWidgetHeaderTextStyle());
        setPageStyle( getPageStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    News
                </div>
            </div>
            <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
                <div style={pageStyle}>
                {
                    data.list.length < 1 ? 
                        <EmptyView userWidgetHeaderTextStyle={userWidgetHeaderTextStyle} /> : 
                            <PopulatedView data={data} />
                }
                </div>
            <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
        </section>
    );
};

export default WidgetNewsViewPanel;
