import React, { 
    useState,
    useEffect
} from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import { NavLink } from 'react-router-dom';
import CloseIcon from './../../../img/close.svg';
import Dialog from '@mui/material/Dialog';
import PayoutIcon from './../../../img/payout.svg';
import AddPayment from './../../../img/payment-add-paymentmethod.svg';

const EmptyView = () => <div style={{

}}>
    <p>
        No Payout Account Setup.  
        &#160;
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to="/payout/add"
        >
            Add a Payout Account
        </NavLink>
    </p>
</div>

const AccountButtons = ({ 
    account, 
    allowRemove, 
    removeAccount, 
    setAsPayoutTarget 
}) => {
    const [ confirmRemoveDialogOpen, setConfirmRemoveDialogOpen ] = useState(false);
    return account.isPayoutTarget ? `${account.currency} Payout Target` :
        <div style={{
            ...theme.getPayoutAccountButtonContainerStyle(theme)  
        }}>
            {
                /* istanbul ignore next */
                allowRemove ? 
                    <theme.getIconButtonUi 
                        theme={theme}
                        icon={CloseIcon}
                        onClick={
                            /* istanbul ignore next */
                            (event) => { setConfirmRemoveDialogOpen(true) }
                        }
                        customContainerStyle={{
                            ...theme.getPayoutAccountButtonStyle(theme)
                        }}
                    /> : ''
            }
                 
            <theme.getIconButtonUi 
                theme={theme}
                icon={PayoutIcon}
                className="test-payoutsSetPayoutTargetBtn"
                onClick={
                    /* istanbul ignore next */    
                    (event) => { setAsPayoutTarget(account.id) }
                }
                customContainerStyle={{
                    ...theme.getPayoutAccountButtonStyle(theme)
                }}
            />
    
            <div style={{ clear: 'both' }}></div>
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setConfirmRemoveDialogOpen(false) }
                }
                open={confirmRemoveDialogOpen}
            >
                <p>
                    Remove Payout Account ?
                </p>
                <button 
                    onClick={
                        /* istanbul ignore next */
                        (event) => { removeAccount(account.id) }
                    }
                >
                    Remove
                </button>
            </Dialog>
        </div>
}

const TableRow = ({ 
    account, 
    allowRemove, 
    removeAccount, 
    showExpireColumn, 
    setAsPayoutTarget 
}) => {

    const getPaymentMethodItemStyle = () => theme.getPaymentMethodItemStyle(theme);
    const getPaymentMethodHeaderStyle = () => theme.getPaymentMethodHeaderStyle(theme);

    const [ paymentMethodItemStyle, setPaymentMethodItemStyle ] = useState( getPaymentMethodItemStyle() );
    const [ paymentMethodHeaderStyle, setpaymentMethodHeaderStyle ] = useState( getPaymentMethodHeaderStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPaymentMethodItemStyle( getPaymentMethodItemStyle() );
        setpaymentMethodHeaderStyle( getPaymentMethodHeaderStyle() );
    });
    useEffect(resizeEffect);

    return (
        <div style={{
            ...theme.getPaymentMethodGridContainerStyle(theme),
        }}>
            <div style={paymentMethodItemStyle}>
                <strong>
                    Bank
                </strong>
                <div style={paymentMethodHeaderStyle}>
                    { account.bank }
                </div>
            </div>

            <div style={paymentMethodItemStyle}>
                <strong>
                    Currency
                </strong>
                <div style={paymentMethodHeaderStyle}>
                    { account.currency }
                </div>
            </div>

            { 
                showExpireColumn && 
                    <div style={paymentMethodItemStyle}>
                        <strong>
                            Expire
                        </strong>
                        <div style={paymentMethodHeaderStyle}>
                            { 
                                account.expire ? `${account.expire.month} / ${account.expire.year}` : ''  
                            }
                        </div>
                    </div>
            }

            <div style={paymentMethodItemStyle}>
                <strong>
                    Account
                </strong>
                <div style={paymentMethodHeaderStyle}>
                    { `******${account.lastFour}` }
                </div>
            </div>

            <div style={{
                ...theme.getPaymentMethodButtonStyle(theme),
            }}>
                <AccountButtons 
                    account={account} 
                    allowRemove={allowRemove} 
                    removeAccount={removeAccount}
                    setAsPayoutTarget={setAsPayoutTarget}
                />
            </div>
        </div>
    );
};

const PopulatedView = ({ bankAccounts, removeAccount, setAsPayoutTarget }) => {
    const allowRemove = bankAccounts.length === 1 ? false : true;
    const showExpireColumn = bankAccounts.filter(account => account.expire).length > 0 ? true : false;
    
    const getContainerStyle = () => ({
        ...theme.getProfilePwdSuggestionContentStyle(theme),
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);

    /* istanbul ignore next */
    return bankAccounts.length < 1 ? <EmptyView /> :
        <div>
            <table>
                <div style={containerStyle}>
                    {
                        bankAccounts.map((account, index) => 
                            <TableRow 
                                key={index} 
                                setAsPayoutTarget={setAsPayoutTarget}
                                allowRemove={allowRemove} 
                                removeAccount={removeAccount} 
                                account={account} 
                                showExpireColumn={showExpireColumn}
                            />
                        )
                    }
                </div>
            </table>
        </div>
};

const EnabledView = ({ bankAccounts, removeAccount, setAsPayoutTarget }) => bankAccounts.length < 1 ? <EmptyView /> : 
    <PopulatedView 
        bankAccounts={bankAccounts} 
        setAsPayoutTarget={setAsPayoutTarget} 
        removeAccount={removeAccount} 
    />

const DisabledView = (parms) => 
    <p>
        Before you can receive payouts you must 
        &nbsp;
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to="/profile/income"
        >
            setup your account 
        </NavLink>
    </p>

const PayoutsViewPanel = ({ data, removeAccount, setAsPayoutTarget }) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <h2>
                Payout Accounts
            </h2>
            <div style={bodyStyle}>
                <NavLink to="/payout/add" style={{
                        ...theme.getProfileButtonStyle(theme)          
                }}>
                    <div style={{
                        ...theme.getGeneralButtonStyle(theme, AddPayment)
                    }}>
                    </div>
                    <div style={{
                        ...theme.getGeneralButtonTextStyle(theme)
                    }}>
                        Add Payout Account
                    </div> 
                </NavLink>
                {
                    data.enabled ? <EnabledView setAsPayoutTarget={setAsPayoutTarget} removeAccount={removeAccount} bankAccounts={data.bankAccounts} /> : <DisabledView />
                }
            </div>
        </div>
    );
};

export default PayoutsViewPanel;
