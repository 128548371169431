import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminSearchFinePrompt from './../ui/prompt/admin/admin-search-fine';
import theme from './../ui/theme';

const AdminSearchFinesPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            Fees
        </h2>
        <AdminSearchFinePrompt />
    </AdminPageTemplate>


export default AdminSearchFinesPage;
