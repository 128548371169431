import React from 'react';
import config from './../../../config';
import ValidateService from './../../../service/validate';
import CurrencyInputService from './../../../service/currency-input';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import ExchangeTypeEnum from './../core/enum/exchange-type';
import RestrictViewTypeEnum from './../core/enum/restrict-view-type';
import UspsDisplayPricePanel from './../../panel/core/usps-display-price';

class AddUSPSPaymentExchangePrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, AddUSPSPaymentExchangePrompt.getValidators());

        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.controlValueChange = this.controlValueChange.bind(this);
        this.displayPriceUi = this.displayPriceUi.bind(this);
        this.getAskingError = this.getAskingError.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.validator.asking.isValid = ValidateService.makeUspsOrderAmountField(this); 
    }

    static getValidators()
    {
        return {
            type: {
                isValid: ExchangeTypeEnum.isValid,
            },
            visibilityType: {
                isValid: RestrictViewTypeEnum.isValid,
            },
            asking: {
                isValid: ValidateService.textLine
            },
        };
    }

    componentDidMount()
    {
        if(this.props.asking)
        {
            const nextState = this.validateState(this.state);
            nextState.asking.value = this.props.asking;
            this.setState(nextState);
        }
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    controlValueChange(name, value, entity = null)
    {
        if(name === 'type' && this.props.onSetTypeField)
        {
            let updateState = this.state;
            const valid = this.validator[name].isValid(value, updateState);
            if(valid)
            {
                this.props.onSetTypeField(value);

                let updateState = this.state;
                const overallState = this.evaluateFieldsVisibility(updateState);
                const finalState = this.validateState(overallState);
                this.setState(finalState);
            }
        }

        super.controlValueChange(name, value, entity);
    }

    getFieldsModel()
    {
        const validateAsking = ValidateService.makeUspsOrderAmountField(this); 
        const askingValue = this.props.asking ? this.props.asking : '';
        const askingValid = validateAsking(askingValue);
        return {
            type: {
                name: 'Exchange Type',
                error: 'Specify the type of exchange you accept',
                value: ExchangeTypeEnum.EXCHANGE_TYPE_USPS_CASH_ORDER,
                valueList: ExchangeTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            visibilityType: {
                name: 'Offer Visibility',
                error: 'Specify the offer visibility',
                value: RestrictViewTypeEnum.RESTRICT_VIEW_TYPE_PUBLIC,
                valueList: RestrictViewTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            asking: {
                name: 'Asking',
                error: this.getAskingError(),
                value: askingValue,
                visible: true,
                valid: askingValid,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    /* istanbul ignore next */
    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        let iniState = {
            ...baseState,
            ...fields
        };

        if(!this.props.skipOnlinePayValidation)
        {
            // omit online payments if details not loaded, setup not complete, or no currecy payout setup
            if(!this.props.payAccountLoaded || !this.props.payAccountSetupComplete || this.props.payCurrencyList.length < 1)
            {
                iniState.type.valueList = ExchangeTypeEnum.getDropDownValues().filter(item => item.value !== ExchangeTypeEnum.EXCHANGE_TYPE_ONLINE);
            }
        }
        return iniState;
    }

    onSendToServer()
    {
        const details = this.getServerRequest();
        this.props.onAddUSPSPaymentExchange(details);

        let nextState = this.getBaseStateOnResponse({ message: '', success: false });
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    getServerRequest()
    {
        return {
            type: this.state.type.value,
            visibility: {
                type: this.state.visibilityType.value,
                expireDate: '',
            },
            asking: CurrencyInputService.parse(this.state.asking.value),
        };
    }

    displayPriceUi()
    {
        return <UspsDisplayPricePanel asking={this.state.asking.value} />
    }

    getAskingError()
    {
        return `*Specify a valid USD amount less than or equal to ${config.pay.usps.limit}`;
    }
    
    getPromptUi(buttonText = 'Add')
    {
        return (
            <div>
                <p>
                    { `USPS Money Orders allow the sender to visit any Post Office location and send up to ${config.pay.usps.limit} in a single order anywhere in the United States.` }
                </p>
                {
                    this.state.asking.valid ? this.displayPriceUi() : ''
                }
                {
                    super.getPromptUi(buttonText)
                }
            </div>
        );
    }
}

export default AddUSPSPaymentExchangePrompt;
