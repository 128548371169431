export const getProfileRemovePlatformButtonStyle = (theme, icon) => ({
    width: '2.5rem',
    height: '2.5rem',
    padding: '.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
});

export const getProfileUserTrailStyle = (theme) => ({
    width: 'fit-content',
});

export const getEditProfileTrailsPromptStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
});

export const getEditProfileTrailsGridStyle = (theme) => ({
    display: 'grid',
    gap: '.75rem 1.5rem',
    margin: '.75rem .75rem 1rem',
    gridTemplateColumns: theme.isTablet() ? 'repeat(auto-fill, 16rem)' : 'repeat(auto-fill, 100%)',
    width: '100%',
    justifyContent: 'center',
});