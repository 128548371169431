import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import ProfileShopTraitsPanel from './../ui/panel/profile-shop-traits';

const ProfileShopTraitsPage = () => {
    return (
        <AuthPageTemplate childMenu={
            <div>
                <ProfileMenu />
            </div>
        }>
            <ProfileShopTraitsPanel />
        </AuthPageTemplate>
    );
};

export default ProfileShopTraitsPage;
