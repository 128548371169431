import React from 'react';
import DetailPanel from './../../core/detail';
import UserService from './../../../../service/user';
import AdminUserDetailViewPanel from './view.js';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';

class _AdminUserDetailPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onToggleAccountAccess = this.onToggleAccountAccess.bind(this);
        this.getToggleAccountAccessRequest = this.getToggleAccountAccessRequest.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onAddInvite = this.onAddInvite.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserService.getAdminUserDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAddInvite()
    {
        super.loadData({ });
        UserService.issueInvites({
            request: {
                inviteCount: 1, 
                shareToUserId: this.props.restrictedUser.id,
            },
            onResponse: (response) => {
                const nextState = {
                    ...this.state,
                    contactingServer: false,
                };
                this.setState(nextState);
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            id: inputObj.restrictedUser.id,
            page: inputObj.page,
        };
    }

    componentDidMount()
    {
        const restrictedUser = this.props.restrictedUser;
        this.loadData({
            restrictedUser,
            page: 0,
        });
    }

    onToggleAccountAccess(enabled)
    {
        super.loadData({ });
        UserService.toggleAccess({
            request: this.getToggleAccountAccessRequest(enabled),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getToggleAccountAccessRequest(loginEnabled)
    {
        return {
            id: this.props.restrictedUser.id,
            loginEnabled,
        };
    }

    getLoadedDataDetailUi()
    {
        return <AdminUserDetailViewPanel 
            data={this.state.data} 
            viewingUserId={this.props.restrictedUser.id}
            onAddInvite={this.onAddInvite}
            onToggleAccountAccess={this.onToggleAccountAccess} 
        />
    }
}

const AdminUserDetailPanel = connect(mapStateToProps, mapDispatchToProps)(_AdminUserDetailPanel);
export default AdminUserDetailPanel;
