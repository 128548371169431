import React from 'react';
import AbstractPrompt from "./../abstract";
import Pagination from './../pagination';
import { Redirect } from 'react-router-dom';
import theme from './../../../theme';
import { SearchResultsViewPrompt } from './view';

// istanbul ignore next
class SearchPrompt extends AbstractPrompt
{
    constructor(parms, validators)
    {
        super(parms, validators);

        this.getBaseStateOnResponse = this.getBaseStateOnResponse.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPaginationUi = this.getPaginationUi.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
        this.applyUrlUpdate = this.applyUrlUpdate.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.render = this.render.bind(this);
    }

    onFormSubmit(e)
    {
        e.preventDefault();
        if(this.state.isValid){
            this.setContactingServer(true);
            let request = this.getServerRequest();
            request.page = 0;
            this.onSendToServer(request);
        }
    }

    getServerRequest()
    {
        throw new Error('Must override SearchPrompt.getServerRequest');
    }

    applyUrlUpdate(url)
    {
        const nextState = { additionalInformation: 'URL Updated with JS' };
        const nextTitle = 'Bears.Exchange';
        window.history.pushState(nextState, nextTitle, `/#${url}`);
    }

    getBaseStateOnResponse({ message = '', success = true } = {})
    {
        return {
            ...this.state,
            message,
            requestComplete: false,
            contactingServer: false
        };
    }

    getFieldsModel()
    {
        throw new Error('Must override SearchPrompt.getFieldsModel');
    }

    componentDidMount()
    {
        this.setContactingServer(true);
        this.onSendToServer();
    }

    onSearchResponse(response, expectedField)
    {
        const updateState = {
            ...this.getBaseStateOnResponse(response),
            data: {
                page: response[expectedField].page ? response[expectedField].page : 0,
                perPage: response[expectedField].perPage ? response[expectedField].perPage : 0,
                total: response[expectedField].total ? response[expectedField].total : 0,
            },
            results: response[expectedField].list ? response[expectedField].list : [],
        };
        this.setState(updateState);
    }

    onSearchError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    getSearchResultUi(aResult, index)
    {
        throw new Error('SearchPrompt.getSearchResultUi must be overriden');
    }

    getNoResultsUi()
    {
        return (
            <section>
                <p style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Nothing Found.
                </p>
            </section>
        );
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : 
            <SearchResultsViewPrompt>
                {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
            </SearchResultsViewPrompt>
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            isValid: true,
            results: [],
        };
    }

    onLoadSpecifiedPage(page)
    {
        throw new Error('Must override SearchPrompt.onLoadSpecifiedPage');
    }

    getPaginationUi()
    {
        return this.state.data && 
                this.state.data.page !== undefined && 
                this.state.data.perPage && 
                this.state.data.total ? <Pagination 
                                    total={this.state.data.total}
                                    page={this.state.data.page}
                                    onLoadSpecifiedPage={this.onLoadSpecifiedPage}
                                    pageToUrl={this.pageToUrl}
                                    perPage={this.state.data.perPage} /> : '';
    }

    getPromptUi()
    {
        const searchPanel = super.getPromptUi();
        return (
            <div style={{
                ...theme.getSearchPromptStyle(theme),
            }}>
                { searchPanel }

                { 
                    this.state.contactingServer ? 
                        this.getContactingServerUi() : 
                            <div>
                                { this.getPaginationUi() }
                                { this.getSearchResultsUi() }
                                { this.getPaginationUi() }
                            </div>
                }
            </div>
        );
    }

    getSubmitButtonUi(buttonText = 'Search')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    render()
    {
        return this.state.redirectTo ? <Redirect to={this.state.redirectTo} /> :
            this.state.requestComplete ? this.getConfirmUi() : this.getPromptUi();
    }

}

export default SearchPrompt;

