import React from 'react';
import DetailPanel from './../core/detail';
import UiService from './../../../service/ui';
import UserService from './../../../service/user';
import RelationshipService from './../../../service/relationship';
import UserDetailViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import UnableToLoadTargetPanel from './../core/unable-to-load-target';

class _UserDetailPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onBlockUser = this.onBlockUser.bind(this);
        this.getBlockUserRequest = this.getBlockUserRequest.bind(this);
        this.onBlockUserResponse = this.onBlockUserResponse.bind(this);
        this.onBlockUserError = this.onBlockUserError.bind(this);
        this.onUnblockUser = this.onUnblockUser.bind(this);
        this.onUnblockUserResponse = this.onUnblockUserResponse.bind(this);
        this.onUnblockUserError = this.onUnblockUserError.bind(this);
        this.onFriendUser = this.onFriendUser.bind(this);
        this.getFriendUserRequest = this.getFriendUserRequest.bind(this);
        this.onFriendUserResponse = this.onFriendUserResponse.bind(this);
        this.onFriendUserError = this.onFriendUserError.bind(this);
        this.onUnsetRelationshipResponse = this.onUnsetRelationshipResponse.bind(this);
        this.onUnfriendUserResponse = this.onUnfriendUserResponse.bind(this);
        this.onUnfriendUserError = this.onUnfriendUserResponse.bind(this);
        this.onUnfriendUser = this.onUnfriendUser.bind(this);
        this.getUnfriendUserRequest = this.getUnfriendUserRequest.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            unableToLoadTarget: false,
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserService.getUserDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getBlockUserRequest()
    {
        const userId = this.props.userId;
        return {
            targetUserId: userId
        };
        
    }

    getFriendUserRequest()
    {
        const userId = this.props.userId;
        return {
            targetUserId: userId
        };
    }

    onBlockUserResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: {
                ...this.state.data,
                relationship: 'blocked',
            }
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onBlockUserError(error)
    {
        super.onLoadDataError(error);
    }

    onUnsetRelationshipResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: {
                ...this.state.data,
                relationship: undefined,
            }
        };
        this.setState(nextState);

    }

    onUnblockUserResponse(response)
    {
        this.onUnsetRelationshipResponse(response);
    }

    onUnfriendUserResponse(response)
    {
        this.onUnsetRelationshipResponse(response);
    }

    /* istanbul ignore next */
    onUnfriendUserError(error)
    {
        super.onLoadDataError(error);
    }

    /* istanbul ignore next */
    onUnblockUserError(error)
    {
        super.onLoadDataError(error);
    }

    onUnblockUser()
    {
        super.loadData({ });
        RelationshipService.unblockUser({
            request: this.getBlockUserRequest(),
            onResponse: this.onUnblockUserResponse,
            onError: this.onUnblockUserError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onBlockUser()
    {
        super.loadData({ });
        RelationshipService.blockUser({
            request: this.getBlockUserRequest(),
            onResponse: this.onBlockUserResponse,
            onError: this.onBlockUserError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onFriendUserResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: {
                ...this.state.data,
                relationship: 'friend',
            }
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onFriendUserError(error)
    {
        super.onLoadDataError(error);
    }

    onFriendUser()
    {
        super.loadData({ });
        RelationshipService.friendUser({
            request: this.getFriendUserRequest(),
            onResponse: this.onFriendUserResponse,
            onError: this.onFriendUserError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getUnfriendUserRequest()
    {
        const userId = this.props.userId;
        return {
            targetUserId: userId
        };
    }

    onUnfriendUser()
    {
        super.loadData({  });
        RelationshipService.unfriendUser({
            request: this.getUnfriendUserRequest(),
            onResponse: this.onUnfriendUserResponse,
            onError: this.onUnfriendUserError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            detailsUserId: inputObj.userId
        };
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load user')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    getUnLoadedDataDetailUi()
    {
        return this.state.unableToLoadTarget ? <UnableToLoadTargetPanel title="User" /> : super.getUnLoadedDataDetailUi();
    }

    componentDidMount()
    {
        const userId = this.props.userId;
        this.loadData({ userId });
        UiService.scrollToTop();
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                <UserDetailViewPanel 
                    data={this.state.data} 
                    isAdmin={this.props.isAdmin}
                    onBlockUser={this.onBlockUser} 
                    onFriendUser={this.onFriendUser}
                    onUnfriendUser={this.onUnfriendUser}
                    onEditProfile={
                        /* istanbul ignore next */
                        (event) => { this.sendUserTo('/profile') }
                    }
                    onUnblockUser={this.onUnblockUser} />
                    
            </section>
        );
    }
}

const UserDetailPanel = connect(mapStateToProps, mapDispatchToProps)(_UserDetailPanel);

export default UserDetailPanel;
