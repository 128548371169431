import React from 'react';
import { useState } from 'react';
import theme from './../../theme';
import Dialog from '@mui/material/Dialog';
import RequestCarrierMembershipPrompt from './../request-carrier-membership';

const SearchCarrierGroupsAcceptingJoinsViewPrompt = ({ carrierGroup }) => {
    const [ isPromptOpen, setIsPromptOpen ] = useState(false);
    return (
        <div>
            <div>
                { carrierGroup.name }
            </div>
            
            <button 
                className="test-searchCarrierGroupsAcceptingJoinsRequestMembershipBtn"
                onClick={event => { setIsPromptOpen(true) }}
            >
                Request Membership
            </button>

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setIsPromptOpen(false) }
                }
                open={isPromptOpen}
            >
                <div style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    <h4>
                        Request Membership with { carrierGroup.name }
                    </h4>
                    
                    <RequestCarrierMembershipPrompt 
                        carrierGroupId={carrierGroup.id} 
                    />

                </div>
            </Dialog>
        </div>
    );
};

export default SearchCarrierGroupsAcceptingJoinsViewPrompt;
