import React from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from './../../prompt/core/pagination';
import theme from './../../theme';

const CarrierLocation = ({
    location
}) => {
    let miles = Math.round(location.distanceInMiles);
    const unit = miles > 1 ? 'miles' : 'mile';
    miles = miles === 0 ? 'less than a' : miles;
    const generalLocation = location.shortState !== '' ? `${location.city}, ${location.shortState}` : 
        `${location.city} ${location.state}`;
    return (
        <li>
            {`${miles} ${unit}  -  ${generalLocation} *`}
        </li>
    );
};

const AvailableCarrier = ({
    availableCarrier,
    delivery,
    requestQuote,
    localCarrierGroupIdList
}) => {
    const isDeclined = delivery.inNetworkCarriers.bidDeclinedCarrierGroupIdList.includes(availableCarrier.carrierGroupId);

    return (
        <div>
            {
                isDeclined ? 
                    <div>
                        <h5>
                            { `${availableCarrier.carrierGroupName} has declined your request`  }
                        </h5>
                        <p>
                            Please don't take it personally.  They might have too many orders or your pickup and drop off points might not be good match for their network.
                        </p>
                    </div> : 
                    <div>
                        <h5>
                            { `Near By ${availableCarrier.carrierGroupName} Locations`  }
                        </h5>
                        {
                            delivery.inNetworkCarriers.bidRequestedCarrierGroupIdList.includes(availableCarrier.carrierGroupId) || 
                            localCarrierGroupIdList.includes(availableCarrier.carrierGroupId) ? 
                                <p>
                                    Quote Requested
                                </p> : 
                                    <button 
                                        className="test-availableForDeliveryCarrierGroupsPanelRequestQuoteBtn"
                                        onClick={event => { requestQuote(availableCarrier.carrierGroupId) }}
                                    >
                                        Request Quote
                                    </button>
                        }
                        <ul>
                            {
                                availableCarrier.carrierLocations.map((loc, index) => 
                                    <CarrierLocation 
                                        location={loc}
                                        key={index}
                                    />
                                )
                            }
                        </ul>
                    </div>
            }

            <hr />

        </div>
    );
};

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const PopulatedView = ({
    availableCarriers,
    onLoadSpecifiedPage,
    delivery,
    requestQuote,
    localCarrierGroupIdList
}) => 
    <div>
        <h3>
            Available In-Network Carriers
        </h3>

        { getPaginationUi({ data: availableCarriers, onLoadSpecifiedPage }) }

        {
            availableCarriers.list.map((ac, index) => 
                <AvailableCarrier 
                    availableCarrier={ac}
                    delivery={delivery}
                    requestQuote={requestQuote}
                    localCarrierGroupIdList={localCarrierGroupIdList}
                    key={index} 
                />
            )
        }

        { getPaginationUi({ data: availableCarriers, onLoadSpecifiedPage }) }

        <p>
            *Approximate distance 
        </p>
    </div>

const EmptyView = () => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <p>
            We're sorry to say that there are no in-network carriers in your area.
        </p>
        <p>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to="/profile/groups/carrier"
            >
                Start an in-network carrier company in your area
            </NavLink>
        </p>
    </div>

const AvailableForDeliveryCarrierGroupsViewPanel = ({
    data, 
    onLoadSpecifiedPage,
    delivery,
    requestQuote,
    localCarrierGroupIdList
}) => data.availableCarriers.list.length < 1 ? 
    <EmptyView /> : 
        <PopulatedView
            availableCarriers={data.availableCarriers}
            onLoadSpecifiedPage={onLoadSpecifiedPage}
            delivery={delivery}
            requestQuote={requestQuote}
            localCarrierGroupIdList={localCarrierGroupIdList}
        />

export default AvailableForDeliveryCarrierGroupsViewPanel;
