import React from 'react';
import AuthPageTemplate from './template/auth-page';
import SeekDetailPanel from './../ui/panel/seek-detail';
import PostsMenu from './../ui/menu/posts';
import { useParams } from 'react-router-dom';

const SeekDetailPage = () => {
    const { seekId } = useParams();
    return (
        <AuthPageTemplate childMenu={ <PostsMenu /> }>
            <SeekDetailPanel seekId={seekId} />
        </AuthPageTemplate>
    );
};

export default SeekDetailPage;
