import React from 'react';
import DetailPanel from './../core/detail';
import CheckoutService from './../../../service/checkout';
import UrlService from './../../../service/url';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import config from '../../../config';

class _WaitForPaymentConfirmedPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.getContactingServerUi = this.getContactingServerUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.updateFailCount = this.updateFailCount.bind(this);
        this.tooManyFailures = this.tooManyFailures.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            failCount: 0
        };
    }

    updateFailCount()
    {
        const nextState = {
            ...this.state,
            failCount: this.state.failCount + 1
        };
        this.setState(nextState);
    }

    tooManyFailures()
    {
        return this.state.failCount > config.pay.maxFailures;
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onLoadDataResponse(response)
    {
        if(response.exchangeId)
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                message: '',
                redirectTo: `${config.redirects.baseExchange}${response.exchangeId}`
            };
            this.setState(nextState);
        }
        else
        {
            this.updateFailCount();
            /* istanbul ignore next */
            if(this.tooManyFailures())
            {
                /* istanbul ignore next */
                this.onLoadDataError({
                    message: 'Your payment verification is taking longer than expected.  Once processed your exhange will appear under exchanges.'
                });
            }
            else
            {
                setTimeout(() => { this.loadData(response); }, config.pay.submitPaymentMethodFeedbackDelay);
            }
        }
        
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CheckoutService.checkPayConfirm({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            paymentIntentId: inputObj.paymentIntentId
        };
    }

    componentDidMount()
    {
        const { paymentIntentId } = UrlService.extractPI();
        
        if(paymentIntentId)
        {
            this.loadData({
                paymentIntentId
            });
        }
        else
        {
            this.sendUserTo('/exchanges/active');
        }
    }

    /* istanbul ignore next */
    getLoadedDataDetailUi()
    {
        return <p>
            Payment Confirmed
        </p>
    }

    getUnLoadedDataDetailUi()
    {
        return (
            <h2>
                { this.state.message }
            </h2>
        );
    }

    getContactingServerUi()
    {
        return (
            <div>
                <p>
                    Confirming Payment
                </p>
                {
                    super.getContactingServerUi()
                }
            </div>
        );
    }
}

const WaitForPaymentConfirmedPanel = connect(mapStateToProps, mapDispatchToProps)(_WaitForPaymentConfirmedPanel);
export default WaitForPaymentConfirmedPanel;
