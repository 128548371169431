import DetailPanel from './../core/detail';
import CarrierJobService from './../../../service/carrier-job';
import CarrierJobListViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _CarrierJobListPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierJobService.listPendingJobs({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onLoadSpecifiedPage(page)
    {
        CarrierJobService.listPendingJobs({
            request: this.getLoadDataRequest({
                page
            }),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.loadData({
            page: 0
        });
    }

    getLoadedDataDetailUi()
    {
        return <CarrierJobListViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            carrierGroupId={this.props.carrierGroupId}
        />
    }
}

const CarrierJobListPanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierJobListPanel);
export default CarrierJobListPanel;
