import React from 'react';
import UiService from './../../../../service/ui';

const SearchUserSystemsViewPrompt = ({ aSystem, key }) => 
    <div key={key}>
        { `Device: ${aSystem.deviceType} ${aSystem.deviceModel} ${aSystem.deviceCpu}`}
        <br />
        { `OS: ${aSystem.osName} ${aSystem.osVersion}` }
        <br />
        { `Browser: ${aSystem.browserName} ${aSystem.browserVersion}` }
        <br />
        { `Detected: ${UiService.timeSince(aSystem.createdOn)}` }
        <br />
        <br />
    </div>

export default SearchUserSystemsViewPrompt;
