import React from 'react';
import IssueService from './../../../service/issue';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import ValidateService from './../../../service/validate';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import { ReportIssueContainer } from './view';

class _ReportIssuePrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _ReportIssuePrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
    }

    static getValidators()
    {
        return {
            details: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    getFieldsModel()
    {
        return {
            details: {
                name: 'Details*',
                error: 'Please provide details about the issue you are seeing',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA,
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        this.setState(nextState);
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        IssueService.reportAppIssue({
            request: this.getServerRequest(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getConfirmUi(confirmMessage = 'Thank you for sharing this.  We will look into it.')
    {
        return (
            <div>
                {super.getConfirmUi(confirmMessage)}

                <NavLink 
                    style={{ 
                        ...theme.getGeneralTextLinkStyle(theme),
                        ...theme.getAbstractPromptStyle(theme),
                        ...theme.getAbstractDisplayBlockStyle(theme),
                        ...theme.getGeneralBottomSpacerStyle(theme)
                    }} 
                    to="/"
                >
                    Return to your Dashboard
                </NavLink>
            </div>
        );
    }

    getSubmitButtonUi(buttonText = 'Report')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getPromptUi()
    {
        return (
            <ReportIssueContainer>
                { super.getPromptUi() }
            </ReportIssueContainer>
        );
    }

    getServerRequest()
    {
        return {
            details: this.state.details.value,
        };
    }
}

const ReportIssuePrompt = connect(mapStateToProps, mapDispatchToProps)(_ReportIssuePrompt);

export default ReportIssuePrompt;
