import React from 'react';
import theme from './../../../../theme';
import {
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_PLAN,
    CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_HOST
} from './../../../../prompt/core/enum/carrier-group-delivery-plan-price-contribution-type';

/* istanbul ignore next */
const getContributionCopy = c => {

    switch(c.type)
    {
        case CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP:
            return `Travel estimated ${c.distanceInMiles} miles 
From: ${c.pickup.city}, ${c.pickup.shortState ? c.pickup.shortState : c.pickup.state} 
To: ${c.dropoff.city}, ${c.dropoff.shortState ? c.dropoff.shortState : c.dropoff.state}`;

        case CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_SORT:
            return `Sorted at ${c.at.city}, ${c.at.shortState ? c.at.shortState : c.at.state}`;

        case CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_HOST:
            return `Hosted at ${c.at.city}, ${c.at.shortState ? c.at.shortState : c.at.state}`;

        case CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_PLAN:
        default: 
            return 'Planning';
    }

};

const Contribution = ({
    contribution
}) => 
    <div style={{
        ...theme.getLocationMailingAddressStyle(theme)  
    }}>
        { getContributionCopy(contribution) }
    </div>

export default Contribution;
