import theme from './ui/theme';
import React from 'react';
import { useEffect } from 'react';
import config from './config';
import { useDispatch, useSelector } from 'react-redux';
import DeviceConnectSelector from './store/select/device-connect';
import CustomViewSelector from './store/select/custom-view';
import { 
    UpdateMetrics,
    UpdateSpeedView,
    UpdateAutoSpeedView,
    UpdateSpeedViewPromptVisible,
    UpdateUserClosedSpeedViewPrompt
} from './store/state/action';
import MetricService from './service/metric';
import {
    MAX_HEALTHY_MS,
    MAX_FAST_MS,
    MAX_API_HISTORY_COUNT
} from './service/metric';

var metricServiceWatchInterval = null;

// istanbul ignore next 
const DeviceConnectDetails = ({ deviceConnect }) => 
    <div>
        <h5>
            Device Connection Details
        </h5>
        {
            deviceConnect.hasMetrics ? 
                <div>
                    { `Connection: ${ deviceConnect.health.isHealthy ? `Healthy` : `UnHealthly` }`}
                    <br />
                    { `Speed: ${ deviceConnect.fast.isFast ? `Fast` : `Slow` }`}
                    <br />
                    { `Average Time: ${deviceConnect.stats.average.value} ms`}
                    <br />
                    { `Fastest Time: ${deviceConnect.stats.min.value} ms`}
                    <br />
                    { `Slowest Time: ${deviceConnect.stats.max.value} ms`}
                    <br />
                    { `Last ${deviceConnect.stats.count.value} api requests analyzed `}
                    <br />
                    { `Healthy Percent: ${100 * deviceConnect.health.healthyPercent} %`}
                    <br />
                    { `Fast Percent: ${100 * deviceConnect.fast.fastPercent} %`}
                    <br />
                    <br />
                    { `Fast requests time is ${MAX_FAST_MS} ms or less` }
                    <br />
                    { `Healthly requests return HTTP 200 and time is ${MAX_HEALTHY_MS} ms or less` }
                    <br />
                    { `API Request Stats Are Dropped At ${MAX_API_HISTORY_COUNT} Collected` }
                </div>
                :
                <p>
                    No Metrics
                </p>
        }
        
    </div>

// istanbul ignore next 
const DebugPanel = ({ deviceConnect }) => 
    <div>
        {
            config.debug.displayDeviceConnection && deviceConnect && 
                <DeviceConnectDetails
                    deviceConnect={deviceConnect}
                />
        }
    </div>

const SpeedViewPromptAutoUpdate = ({ customView }) => {
    const dispatch = useDispatch();
    
    // istanbul ignore next 
    const setAutoUpdate = (isAuto) => {
        dispatch(UpdateAutoSpeedView(
            isAuto
        ));
    };

    return (
        <div>
            <input 
                type="checkbox"
                onChange={
                    // istanbul ignore next 
                    event => {
                        const nextVal = !customView.autoAdjustSpeedViewEnabled;
                        setAutoUpdate(nextVal);
                    }
                }
                checked={customView.autoAdjustSpeedViewEnabled}
            />
            <span>
                Auto adapt view based on network activity
            </span>
        </div>
    );
};

// istanbul ignore next
const SpeedViewPromptSlowSpeed = ({ customView }) => {
    const dispatch = useDispatch();

    // istanbul ignore next
    const toggleSlowSpeedView = (slowSpeedViewEnabled) => {
        dispatch(UpdateSpeedView(slowSpeedViewEnabled));
    };

    return (
        <div>
            <input 
                type="checkbox"
                onChange={event => {
                    const nextVal = !customView.slowSpeedViewEnabled;
                    toggleSlowSpeedView(nextVal);
                }}
                checked={customView.slowSpeedViewEnabled}
            />
            <span>
                Customize for a slow network connection
            </span>
        </div>
    );
};

// istanbul ignore next 
const SpeedViewPromptHide = () => {

    const dispatch = useDispatch();

    // istanbul ignore next 
    const hideSpeedViewPrompt = () => {
        dispatch(UpdateUserClosedSpeedViewPrompt(
            false
        ));
    };

    return (
        <div>
            <button onClick={event => {
                hideSpeedViewPrompt();
            }}>
                Hide
            </button>
        </div>
    );
};

// istanbul ignore next
export const SpeedViewPrompt = ({ deviceConnect, customView }) => {
    
    return (
        <div  style={{
            ...theme.getNetworkSpeedContainerStyle(theme),
        }}>
            <div>
                <span>
                    { `Network Speed: ${ deviceConnect.fast.isFast ? `Fast` : `Slow` }`}
                </span>
                <br />
                <span>
                    { `You are using a view custom for ${ customView.slowSpeedViewEnabled ? `Slow` : `Fast` } Connections`}
                </span>
            </div>

            <SpeedViewPromptSlowSpeed
                customView={customView}
            />

            <SpeedViewPromptAutoUpdate
                customView={customView}
            />
            
            <SpeedViewPromptHide />

        </div>
    );
};

// istanbul ignore next
const CustomViewSpeedPanel = ({ deviceConnect, customView }) => 
    <div>
        {
            customView && customView.showSpeedViewPrompt && 
                <SpeedViewPrompt
                    deviceConnect={deviceConnect}
                    customView={customView}
                />
        }
    </div>

const MetricWrapper = ({ children }) => {
    const dispatch = useDispatch();

    const deviceConnect = useSelector(DeviceConnectSelector);
    const customView = useSelector(CustomViewSelector);

    // istanbul ignore next
    const setCurrentMetrics = (current) => {
        dispatch(UpdateMetrics(
            current
        ));
    };

    // istanbul ignore next
    const setSpeedViewPromptVisible = () => {
        dispatch(
            UpdateSpeedViewPromptVisible(true)
        );
    };

    // istanbul ignore next
    const toggleSlowSpeedView = (slowSpeedViewEnabled) => {
        dispatch(UpdateSpeedView(slowSpeedViewEnabled));
    };

    // istanbul ignore next 
    const watchMetrics = () => {
        
        MetricService.sync({
            setCurrentMetrics,
            setSpeedViewPromptVisible,
            toggleSlowSpeedView,
            customView
        });
                
    };

    useEffect(() => {
        metricServiceWatchInterval = setInterval(
            watchMetrics,
            config.interval.getDeviceConnectionHealth
        );
        // istanbul ignore next 
        return () => {
            clearInterval(metricServiceWatchInterval);
        };
    });

    return (
        <div>

            { children }

            <DebugPanel 
                deviceConnect={deviceConnect} 
            />

            <CustomViewSpeedPanel
                deviceConnect={deviceConnect}
                customView={customView}
            />
        </div>
    );
};

export default MetricWrapper;
