import React, { useEffect } from 'react';
import { useState } from 'react';
import Map, { Marker, Source, Layer } from "react-map-gl";
import { supported } from 'mapbox-gl';
import * as turf from '@turf/turf';
import theme from './../../theme';
import config from './../../../config';
import mapboxgl from 'mapbox-gl';
try
{
    mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line
} catch(err)
{
    console.error(err);
}

export const ZOOM_START = 9;
export const ZOOM_START_WIDE = 1;

export const getStartZoom = (limit) => {
    switch(limit)
    {
        case '5':
            return ZOOM_START;

        case '10':
            return 8;

        case '20':
            return 7;

        case '40':
            return 6;

        case '60':
            return 5;

        case '80':
            return 5;

        case '100':
            return 4;

        case '250':
            return 3;

        case '500':
            return 2;

        case '750':
            return 1;

        case '1000':
            return 1;

        default:
            return 1;
    }
}

export const NoMapView = () => 
    <div>
        Map Data Unavailable
    </div>

/* istanbul ignore next */
const getCircle = (selectedLocation, limit) => {

    return turf.circle(
        [
            selectedLocation.longitude,
            selectedLocation.latitude
        ],
        limit,
        {
            units: 'miles',

        }
    );
};

export const getBorderLine = (circle) => turf.lineString(...circle.geometry.coordinates);

/* istanbul ignore next */
const AnonRegionMapView = ({ selectedLocation, limit }) => {
    const anonRegion = {
        latitude: selectedLocation.anonRegionLatitude,
        longitude: selectedLocation.anonRegionLongitude
    };
    const circle = getCircle(anonRegion, limit);
    const borderLine = getBorderLine(circle);
    const startZoom = getStartZoom(limit);

    const [ mapViewState, setMapViewState ] = useState({
        longitude: anonRegion.longitude,
        latitude: anonRegion.latitude,
        zoom: startZoom,
    });

    useEffect(() => {
        const zoom = getStartZoom(limit);
        const nextState = {
            ...mapViewState,
            zoom
        };
        setMapViewState(nextState);
        // eslint-disable-next-line
    }, [ limit ]);

    return (
        <div style={{
            ...theme.getSmallMapStyle(theme)  
        }}>
            <Map 
                { ...mapViewState }
                mapboxAccessToken={config.map.clientKey}
                onMove={
                    /* istanbul ignore next */
                    (e) => { setMapViewState(e.viewState) }
                }
                onZoom={
                    /* istanbul ignore next */
                    (e) => { setMapViewState(e.viewState) }
                }
                mapStyle='mapbox://styles/mapbox/streets-v11'
            >
                <Source type="geojson" data={circle}>
                    <Layer 
                        type="fill" 
                        paint={{
                            "fill-color": "#088",
                            "fill-opacity": 0.4,
                            "fill-outline-color": "yellow"
                        }}
                    />
                </Source>
                
                
                <Source type="geojson" data={borderLine}>
                    <Layer 
                        type="line"
                        paint={{
                            "line-color": "yellow",
                            "line-width": 2
                        }}
                    />
                </Source>
                
            </Map>
            <div style={{
                ...theme.getMapFinePrint(theme)
            }}>
                <p>
                    * This is an anonymized map. Exact location or delivery range is not depicted.
                </p>
            </div>
        </div>
    );

};

/* istanbul ignore next */
const RegionMapView = ({ selectedLocation, limit }) => {
    const circle = getCircle(selectedLocation, limit);
    const borderLine = getBorderLine(circle);
    const startZoom = getStartZoom(limit);

    const [ mapViewState, setMapViewState ] = useState({
        longitude: selectedLocation.longitude,
        latitude: selectedLocation.latitude,
        zoom: startZoom,
    });

    useEffect(() => {
        const zoom = getStartZoom(limit);
        const nextState = {
            ...mapViewState,
            zoom
        };
        setMapViewState(nextState);
        // eslint-disable-next-line
    }, [ limit ]);

    return (
        <div style={{
            ...theme.getSmallMapStyle(theme)  
        }}>
            <Map 
                { ...mapViewState }
                mapboxAccessToken={config.map.clientKey}
                onMove={
                    /* istanbul ignore next */
                    (e) => { setMapViewState(e.viewState) }
                }
                onZoom={
                    /* istanbul ignore next */
                    (e) => { setMapViewState(e.viewState) }
                }
                mapStyle='mapbox://styles/mapbox/streets-v11'
            >
                <Source type="geojson" data={circle}>
                    <Layer 
                        type="fill" 
                        paint={{
                            "fill-color": "#088",
                            "fill-opacity": 0.4,
                            "fill-outline-color": "yellow"
                        }}
                    />
                </Source>
                
                
                <Source type="geojson" data={borderLine}>
                    <Layer 
                        type="line"
                        paint={{
                            "line-color": "red",
                            "line-width": 2
                        }}
                    />
                </Source>
                
            </Map>
        </div>
    );
};

/* istanbul ignore next */
const GpsMapView = ({ selectedLocation }) => {

    return (
        <div style={{
            ...theme.getSmallMapStyle(theme)  
        }}>
            <Map 
                mapboxAccessToken={config.map.clientKey}
                initialViewState={{
                    longitude: selectedLocation.longitude,
                    latitude: selectedLocation.latitude,
                    zoom: ZOOM_START,
                  }}
                  mapStyle="mapbox://styles/mapbox/streets-v11"
            >
                <Marker 
                    longitude={selectedLocation.longitude}
                    latitude={selectedLocation.latitude}
                >
                    <div style={{
                        ...theme.getMapMarkerStyle(theme)
                    }}>

                    </div>
                </Marker>
            </Map>
        </div>
    );
};

/* istanbul ignore next */
const MapView = ({ selectedLocation, limit, isRegion }) => {
 
    return isRegion && limit ? <RegionMapView selectedLocation={selectedLocation} limit={limit} /> :
        <GpsMapView selectedLocation={selectedLocation} />
}

export const isGps = (selectedLocation) => selectedLocation.hasGps || (selectedLocation.latitude && selectedLocation.longitude);

/* istanbul ignore next */
const isAnonGps = (selectedLocation) => selectedLocation.hasAnonGps || (selectedLocation.anonRegionLatitude && selectedLocation.anonRegionLongitude);

const isMapSupported = () => supported();

const MapsNotSupported = () => 
    <div style={{
        ...theme.getMapFeaturesUnavailbleStyle(theme)
    }}>
        <p>
            This browser does not support maps.
            <br />
            Upgrade your browser for this feature.
        </p>
    </div>

/* istanbul ignore next */
const LocationMapPanel = ({ selectedLocation, limit, isRegion = false, forceAnon = false }) => {
    return !isMapSupported() ? <MapsNotSupported /> :
        !isGps(selectedLocation) || forceAnon ? ( 
            isAnonGps(selectedLocation) && limit ? 
                <AnonRegionMapView 
                    selectedLocation={selectedLocation}
                    limit={limit}
                /> : <NoMapView /> 
            ):
                <MapView 
                    isRegion={isRegion} 
                    selectedLocation={selectedLocation} 
                    limit={limit}
                />

        
};

export default LocationMapPanel;
