import React from 'react';
import DetailPanel from './../core/detail';
import CarrierGroupService from './../../../service/carrier-group';
import CarrierGroupDetailsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _CarrierGroupDetailsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onMemberAdded = this.onMemberAdded.bind(this);
        this.updateAllowJoins = this.updateAllowJoins.bind(this);
        this.updateAcceptDeliveries = this.updateAcceptDeliveries.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        CarrierGroupService.getDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    updateAcceptDeliveries(isAcceptingDeliveries)
    {
        const carrierGroupId = this.props.carrierGroupId;
        super.loadData({ });
        CarrierGroupService.toggleAcceptingDeliveries({
            request: {
                carrierGroupId,
                isAcceptingDeliveries
            },
            onResponse: (response) => {
                this.loadData({
                    carrierGroupId,
                });
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    updateAllowJoins(allowJoinGroupRequests)
    {
        const carrierGroupId = this.props.carrierGroupId;
        super.loadData({ });
        CarrierGroupService.toggleAcceptingJoins({
            request: {
                carrierGroupId,
                allowJoinGroupRequests
            },
            onResponse: (response) => {
                this.loadData({
                    carrierGroupId,
                });
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            carrierGroupId: inputObj.carrierGroupId
        };
    }

    componentDidMount()
    {
        const carrierGroupId = this.props.carrierGroupId;
        this.loadData({
            carrierGroupId,
        });
    }

    /* istanbul ignore next */
    onMemberAdded(response)
    {
        const carrierGroupId = this.props.carrierGroupId;
        this.loadData({
            carrierGroupId,
        });
    }

    getLoadedDataDetailUi()
    {
        return <CarrierGroupDetailsViewPanel 
            data={this.state.data} 
            updateAllowJoins={this.updateAllowJoins}
            updateAcceptDeliveries={this.updateAcceptDeliveries}
            onMemberAdded={this.onMemberAdded}
            onContactInfoUpdated={this.onMemberAdded}
        />
    }
}

const CarrierGroupDetailsPanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierGroupDetailsPanel);
export default CarrierGroupDetailsPanel;
