import React from 'react';
import MenuList from './core/menu-list';
import SeeksIcon from './../../img/menu-seeks-white.svg';
import OffersIcon from './../../img/menu-offers-white.svg';
import theme from './../theme';

const PostsMenu = () => <MenuList list={[
    {
        to: '/offers',
        title: 'Offers',
        customStyle: {
            ...theme.getMenuSubStyle(theme, OffersIcon),
        },
    },
    {
        to: '/seeks',
        title: 'Seeks',
        customStyle: {
            ...theme.getMenuSubStyle(theme, SeeksIcon),
        },
    },
]} />

export default PostsMenu;
