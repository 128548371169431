import React from 'react';
import DetailPanel from './../core/detail';
import PayService from './../../../service/pay';
import UrlService from './../../../service/url';
import SubscriptionService from './../../../service/subscription';
import config from './../../../config';
import PaymentMethodsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _PaymentMethodsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onRemoveCard = this.onRemoveCard.bind(this);
        this.onSetSubscriptionPaymentMethod = this.onSetSubscriptionPaymentMethod.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({ });
        PayService.listPaymentMethods({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onRemoveCard(cardId)
    {
        super.loadData({ });
        PayService.removePayMethod({
            request: {
                paymentMethodId: cardId
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        const tester = /setup_intent/i;
        UrlService.correctUrl(tester, config.pay.paymentMethodAddedUrl);
        this.loadData();
    }

    /* istanbul ignore next */
    onSetSubscriptionPaymentMethod(paymentMethodId)
    {
        super.loadData({ });
        SubscriptionService.updatePaymentMethod({
            request: {
                paymentMethodId
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadedDataDetailUi()
    {
        return (
            <PaymentMethodsViewPanel 
                data={this.state.data} 
                onRemoveCard={this.onRemoveCard} 
                onLoadDataResponse={this.onLoadDataResponse}
                onSetSubscriptionPaymentMethod={this.onSetSubscriptionPaymentMethod}
            />
        );
    }
}

const PaymentMethodsPanel = connect(mapStateToProps, mapDispatchToProps)(_PaymentMethodsPanel);
export default PaymentMethodsPanel;
