import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import UiService from './../../../service/ui';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

export const ExchangeSearchSingleFieldPromptContainer = ({ children }) => {
    const getContainerStyle = () => theme.getExchangeSearchSingleFieldContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={containerStyle}>
            { children }
        </div>
    );
};

const ExchangeSearchViewPrompt = ({ exchange }) => {
    const getComposedStyle = () => ({
        ...theme.getExchangeSearchResultTileContainerStyle(theme),
        ...theme.getAbstractTextAlignCenterStyle(theme)
    });
    const [ composedStyle, setComposedStyle ] = useState( getComposedStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setComposedStyle( getComposedStyle() );
    });
    useEffect(resizeEffect);
    return (
        <NavLink to={`/exchange/${exchange.id}`}>
            <div style={composedStyle}>
                <h3>
                    { exchange.title }
                </h3>
                <strong style={{
                    ...theme.getExchangeSearchResultTimeStyle(theme),
                }}>
                    { UiService.timeSince(exchange.createdOn) }
                </strong>
                <div>
                    {
                        /* istanbul ignore next */
                        exchange.isActive ? (
                            exchange.amIActor ? 
                            'Action Required' : 
                            'Pending'
                        ) : 'Archived'
                    }
                </div>
            </div>
        </NavLink>
    
    );
};

export default ExchangeSearchViewPrompt;
