import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class PayoutService 
{
    static getListRequest(page = 0)
    {
        return {
            page,
        };
    }

    /* istanbul ignore next */
    static list({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.payouts.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getAdminSearchRequest({
        exchangeId = '', 
        flagged,
        paidOut,
        payoutFailed,
        hasRefunded
    }, page = 0)
    {
        return {
            exchangeId,
            flagged,
            paidOut,
            payoutFailed,
            hasRefunded,
            page
        };
    }

    /* istanbul ignore next */
    static adminSearch({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.searchPayouts,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError, 
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getAdminPayTransDetailRequest(paymentIntentId)
    {
        return {
            paymentIntentId
        };
    }

    /* istanbul ignore next */
    static getAdminPayTransDetail({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.viewPayTransDetails,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getReschedulePayoutRequest(payId)
    {
        return {
            payId
        };
    }

    /* istanbul ignore next */
    static reschedulePayout({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.reschedulePayoutJob,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default PayoutService;
