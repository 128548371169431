export const EVENT_CARRIER_MEMBERSHIP_GRANTED = 'carrierMembershipGranted';
export const EVENT_CARRIER_GROUP_DELIVERY_PLAN_STEP_ADDED = 'carrierGroupDeliveryPlanStepAdded';

/* istanbul ignore next */
class EventBusService {
    static on(event, callback)
    {
        document.addEventListener(event, e => { callback(e.payload) });
    }

    static dispatch(event, payload = {})
    {
        document.dispatchEvent(new CustomEvent(event, { payload }));
    }

    static remove(event, callback)
    {
        document.removeEventListener(event, callback);
    }
}

export default EventBusService;
