import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminAddTagPrompt from './../ui/prompt/admin/add-tag';
import theme from './../ui/theme';

const AdminAddTagPage = () => 
    <AdminPageTemplate>
        <div style={{
            ...theme.getAdminAddTagContainerStyle(theme),
        }}>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                Add a Tag
            </h2>
            <AdminAddTagPrompt />
        </div>
        
    </AdminPageTemplate>

export default AdminAddTagPage;