import endpointRequest from './endpoint-request';
import endpointFileUpload from './endpoint-file-upload';
import config from './../config';
import ErrorService from './error';

class CarrierMemberService 
{
    static getAddRequest(name, contactEmail, contactPhone, memberUserId, carrierGroupId)
    {
        return {
            name,
            contactEmail,
            contactPhone,
            memberUserId,
            carrierGroupId,
        };
    }

    /* istanbul ignore next */
    static addMember({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) 
    {
        endpointFileUpload({
            url: config.url.user.group.carrier.member.add,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListRequest(carrierGroupId, page)
    {
        return {
            carrierGroupId,
            page
        };
    }

    /* istanbul ignore next */
    static listMembers({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.member.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRequestMembershipRequest(carrierGroupId, pendingMemberUserId)
    {
        return {
            carrierGroupId, 
            pendingMemberUserId,
            isInvite: false,
            internalNote: '',
        };
    }

    /* istanbul ignore next */
    static requestMembership({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointFileUpload({
            url: config.url.user.group.carrier.member.pending.add,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getInviteMemberRequest(carrierGroupId, pendingMemberUserId, internalNote, roles)
    {
        return {
            carrierGroupId, 
            pendingMemberUserId,
            isInvite: true,
            internalNote,
            roles,
        };
    }

    /* istanbul ignore next */
    static inviteMember({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointFileUpload({
            url: config.url.user.group.carrier.member.pending.add,
            request,
            fileList: [],
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListPendingRequest(carrierGroupId, page)
    {
        return {
            carrierGroupId,
            page
        };
    }

    /* istanbul ignore next */
    static listPending({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.member.pending.list,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListInvitesRequest(page)
    {
        return {
            page
        };
    }

    /* istanbul ignore next */
    static listInvites({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.join.invites,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDeclineInviteRequest(pendingCarrierMemberId)
    {
        return {
            pendingCarrierMemberId
        }
    }

    /* istanbul ignore next */
    static declineInvite({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointFileUpload({
            url: config.url.user.group.carrier.member.pending.respond,
            request,
            fileList: [],
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAcceptInviteRequest(isApproved, name, contactEmail, contactPhone, pendingCarrierMemberId, photo)
    {
        return {
            isApproved,
            name,
            contactEmail,
            contactPhone,
            pendingCarrierMemberId,
            photo
        };
    }

    /* istanbul ignore next */
    static acceptInvite({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) 
    {
        endpointFileUpload({
            url: config.url.user.group.carrier.member.pending.respond,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDeclineMembershipRequest(pendingCarrierMemberId)
    {
        return {
            pendingCarrierMemberId,
            isApproved: false
        };
    }

    /* istanbul ignore next */
    static declineMembership({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) 
    {
        endpointFileUpload({
            url: config.url.user.group.carrier.member.pending.respond,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getGrantMembershipRequest(pendingCarrierMemberId, roles = [])
    {
        return {
            pendingCarrierMemberId,
            isApproved: true,
            roles
        };
    }

    /* istanbul ignore next */
    static grantMembership({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) 
    {
        endpointFileUpload({
            url: config.url.user.group.carrier.member.pending.respond,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdateContactInfoRequest(carrierGroupId, contactEmail, contactPhone)
    {
        return {
            carrierGroupId,
            contactEmail,
            contactPhone
        };
    }

    /* istanbul ignore next */
    static updateContactInfo({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.member.update.contactInfo,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdateRolesRequest(carrierMemberId, roles)
    {
        return {
            carrierMemberId,
            roles
        };
    }

    /* istanbul ignore next */
    static updateRoles({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.group.carrier.member.update.roles,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default CarrierMemberService;