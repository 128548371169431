import LocationService from './../../../service/location';
import CountryService from './../../../service/country';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import UserLocationTypeEnum from './../core/enum/user-location-type';
import ValidateService from '../../../service/validate';

class _AddLocationPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _AddLocationPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);

        this.state = this.getDefaultState();
    }

    /* istanbul ignore next */
    static getValidators()
    {
        return {
            type: {
                isValid: UserLocationTypeEnum.isValid
            },
            isDefault: {
                isValid: ValidateService.anyValue
            },
            pointOfContact: {
                isValid: ValidateService.valueRequired
            },
            street: {
                isValid: ValidateService.valueRequired
            },
            streetLine2: {
                isValid: ValidateService.anyValue
            },
            city: {
                isValid: ValidateService.valueRequired
            },
            shortState: {
                isValid: ValidateService.anyValue,
                isVisible: (thisState) => thisState.countryCode.value === 'US',
            },
            state: {
                isValid: ValidateService.valueRequired,
                isVisible: (thisState) => thisState.countryCode.value !== 'US',
            },
            zipCode: {
                isValid: ValidateService.valueRequired
            },
            countryCode: {
                isValid: ValidateService.anyValue
            },
            hasTruckDocks: {
                isValid: ValidateService.anyValue
            },
            hasLimitedAccess: {
                isValid: ValidateService.anyValue
            },
            notes: {
                isValid: ValidateService.anyValue
            },
        };
    }

    getFieldsModel()
    {
        return {
            type: {
                name: 'Location Type',
                error: 'You must specify a location type',
                value: UserLocationTypeEnum.USER_LOCATION_TYPE_RESIDENTIAL,
                valueList: UserLocationTypeEnum.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            isDefault: {
                name: 'Default Address',
                error: 'Please specify if this is a default address',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            pointOfContact: {
                name: 'Point Of Contact / Name',
                error: 'Please specify the name of the contact person',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            street: {
                name: 'Street',
                error: 'Please specify the street address.',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            streetLine2: {
                name: 'Apt/Suite/Office',
                error: 'Please specify a valid apartment, suite or office number.',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            city: {
                name: 'City',
                error: 'Please specify the city.',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            shortState: {
                name: 'State',
                error: 'Please specify the state.',
                value: CountryService.getRegionDefaultValue('US'),
                valueList: CountryService.getRegionDropDownValues('US'),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            state: {
                name: 'Region',
                error: 'Please specify the region.',
                value: '',
                visible: false,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            zipCode: {
                name: 'Zip Code',
                error: 'Please specify the postal code.',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            countryCode: {
                name: 'Country',
                error: 'Please specify the country.',
                value: CountryService.getDefaultValue(),
                valueList: CountryService.getDropDownValues(),
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
            hasTruckDocks: {
                name: 'Has Truck Dock(s)',
                error: 'Please specify truck dock availability.',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            hasLimitedAccess: {
                name: 'Limited Access Location',
                error: 'Please specify if location is only available at certain times.',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            notes: {
                name: 'Notes',
                error: 'Please add any notes such as specific drop-off area or limited access times.',
                value: '',
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
        };
    }

    getServerRequest()
    {
        return {
            includeGps: this.props.includeGps ? this.props.includeGps : false,
            type: this.state.type.value,
            latitude: '',
            longitude: '',
            countryCode: this.state.countryCode.value,
            countryName: CountryService.getCountryNameByCode(this.state.countryCode.value),
            pointOfContact: this.state.pointOfContact.value,
            street: this.state.street.value,
            streetLine2: this.state.streetLine2.value,
            city: this.state.city.value,
            shortState: this.state.countryCode.value === 'US' ? this.state.shortState.value : '',
            state: this.state.countryCode.value === 'US' ? CountryService.getRegionNameByCode(
                this.state.shortState.value,
                this.state.countryCode.value
            ) : this.state.state.value,
            zipCode: this.state.zipCode.value,
            isDefault: this.state.isDefault.value,
            hasTruckDocks: this.state.hasTruckDocks.value,
            hasLimitedAccess: this.state.hasLimitedAccess.value,
            notes: this.state.notes.value
        };
    }

    onSendToServer()
    {
        LocationService.add({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        if(this.props.onUpdateLocations)
        {
            this.props.onUpdateLocations(response);
        }
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    getConfirmUi(confirmMessage = 'Location Added')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const AddLocationPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddLocationPrompt);
export default AddLocationPrompt;