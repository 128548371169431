import React from 'react';
import PageTemplate from './template/page';
import TermsAndConditionsPanel from './../ui/panel/tandc';
import { PRIVACY } from './../ui/panel/tandc';
import { connect } from 'react-redux';
import ProfileMenu from './../ui/menu/profile';
import mapStateToProps from '../store/map/is-user';

const TermsAndConditions = ({ isUser }) => 
    <PageTemplate 
        isUser={isUser}
        childMenu={
            <ProfileMenu /> 
        }
    >
        <TermsAndConditionsPanel startTab={PRIVACY} />
    </PageTemplate>


const TermsAndConditionsPage = connect(mapStateToProps)(TermsAndConditions);
export default TermsAndConditionsPage;
