import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ExchangesLandingPanel from './../ui/panel/exchanges-landing';
import ExchangesMenu from './../ui/menu/exchanges';

const ExchangesLandingPage = () => 
    <AuthPageTemplate childMenu={ <ExchangesMenu /> }>
        <ExchangesLandingPanel />
    </AuthPageTemplate>

export default ExchangesLandingPage;
