export const BID_CONTENT_TYPE_SEEK = 'seek';
export const BID_CONTENT_TYPE_OFFER = 'offer';
const validValues = [
    BID_CONTENT_TYPE_SEEK,
    BID_CONTENT_TYPE_OFFER,
];

const getDropDownValues = () => [
    {
        name: 'Seek',
        value: BID_CONTENT_TYPE_SEEK
    },
    {
        name: 'Offer',
        value: BID_CONTENT_TYPE_OFFER
    },
];

const isValid = value => !! validValues.includes(value);

const BidContentTypeEnum = {
    BID_CONTENT_TYPE_SEEK,
    BID_CONTENT_TYPE_OFFER,
    getDropDownValues,
    isValid,
};

export default BidContentTypeEnum;
