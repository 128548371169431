import ValidateService from './../../../../../service/validate';
import CurrencyInputService from './../../../../../service/currency-input';

const makeOnMpgBufferUpdated = ({
    setMpgBufferValid,
    setMpgBuffer,
    onSetPayout,
    record,
    payoutBufferValid,
    payoutBuffer,
    useMpgGlobal
}) => event => {
    const value = event.target.value;
    const isValid = ValidateService.numeric(value);
    setMpgBufferValid(isValid);
    setMpgBuffer(value);
    if(isValid)
    {
        /* istanbul ignore next */
        onSetPayout({
            record,
            payout: payoutBufferValid ? CurrencyInputService.parse(payoutBuffer) : null,
            useGlobalMpg: useMpgGlobal,
            mpg: value
        });
    }
};

export default makeOnMpgBufferUpdated;
