import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import CarrierGroupDetailsPanel from './../ui/panel/carrier-group-details';
import config from './../config';
import theme from './../ui/theme';
import { useParams } from 'react-router-dom';

const CarrierGroupDetailsPage = () => {
    const { carrierGroupId } = useParams();
    return (
        <AuthPageTemplate childMenu={ <ProfileMenu /> }>
            {
                /* istanbul ignore next */
                config.features.allowCarrierGroups ? 
                    <CarrierGroupDetailsPanel 
                        carrierGroupId={carrierGroupId} 
                    /> : 
                        <p style={{
                            ...theme.getGeneralTextStyle()
                        }}>
                            This feature is currently disabled
                        </p>
            }
        </AuthPageTemplate>
    );
};

export default CarrierGroupDetailsPage;
