import React from 'react';
import DetailPanel from './../core/detail';
import RatingService from './../../../service/rating';
import UserReviewsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import UnableToLoadTargetPanel from './../core/unable-to-load-target';

class _UserReviewsPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
    }

    onLoadDataResponse(response)
    {
        /* istanbul ignore next */
        if(this.props.setHasReviews)
        {
            const hasReviews = response.positive < 1 && response.negative < 1 ? false : true;
            this.props.setHasReviews(hasReviews);
        }
        super.onLoadDataResponse(response);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            unableToLoadTarget: false,
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        RatingService.getUserRatingsSummary({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            ratedUserId: inputObj.ratedUserId 
        };
    }

    onLoadDataError(error)
    {
        if(error.response && error.response.error && error.response.error === 'Unable to load user review summary')
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                data: undefined,
                message: '',
                unableToLoadTarget: true,
            };
            this.setState(nextState);
        }
        else
        {
            super.onLoadDataError(error);
        }
    }

    getUnLoadedDataDetailUi()
    {
        return this.state.unableToLoadTarget ? <UnableToLoadTargetPanel title="User Review Summary" /> : super.getUnLoadedDataDetailUi();
    }

    componentDidMount()
    {
        const ratedUserId = this.props.ratedUserId;
        this.loadData({ ratedUserId });
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                <UserReviewsViewPanel data={this.state.data} />
            </section>
        );
    }
}

const UserReviewsPanel = connect(mapStateToProps, mapDispatchToProps)(_UserReviewsPanel);
export default UserReviewsPanel;
