import { 
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';
import theme from './../../../theme';

export const getStepSecondCopy = (step, sortedMembers) => {
    switch(step.type)
    {
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP: 
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP:
            const foundMember = sortedMembers.find(m => m.id === step.memberId);
            return <div>
                <div>
                {
`By ${foundMember.name}
${foundMember.contactEmail}
${foundMember.contactPhone}`
                }
                </div>
                <div>
                    <img 
                        src={foundMember.photo.data} 
                        alt="File Missing" 
                        style={{
                            ...theme.getImgAddViewStyle(theme),
                        }}
                    />
                </div>
            </div>
            

        default:
            return '';
    }
};