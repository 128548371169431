import React from 'react';
import CurrencyService from './../../../service/currency';
import theme from './../../theme';
import { ObtainMethodCopy } from './../core/bids';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import FlagPrompt from './../../prompt/flag';
import { FLAGGED_CONTENT_TYPE_ONLINE_PAY } from './../../prompt/core/enum/flagged-content-type';
import IsAdminSelector from './../../../store/select/is-admin';
import { useSelector } from "react-redux";
import RefundPolicyPanel from './../core/refund-policy';

const OpenDisputeChargePrompt = ({ 
    data, 
    onRequestRefund, 
    onFlagged, 
    setDisputePromptOpen 
}) => 
    <div style={{
        ...theme.getExchangeDetailViewOnlinePaymentDisputeModalStyle(theme)
    }}>
        <h2>
            Request Refund
        </h2>
        <p>
            Before submitting a refund request, please consider the following
        </p>
        <ul style={{
            ...theme.getExchangeDetailViewOnlinePaymentDisputeSuggestionListStyle(theme)
        }}>
            <li>
                Have you attempted to resolve your concern via the exchange chat?
            </li>
            <li>
                Have you given a reasonable amount of time for your concern to be resolved?
            </li>
            <li>
                Has the user provided what was described in their post?
            </li>
            <li>
                Refunds are only available prior to a transaction payout
            </li>
            <li>
                Refunds are determined by the User defined Refund Policy and is decided by the User
            </li>
        </ul>
        
        <div>

            <button 
                className="test-onlineTransactionDetailRequestRefundBtn"
                onClick={event => { onRequestRefund() }}
            >
                Request Refund
            </button>

            <button 
                className="test-onlineTransactionDetailCancelBtn"
                onClick={event => { setDisputePromptOpen(false) }}
            >
                Cancel
            </button>
            
        </div>

        <h3>
            Do you need to report an issue to Bears.Exchange ?
        </h3>

        <FlagPrompt 
            contentId={data.id} 
            flaggedContentType={FLAGGED_CONTENT_TYPE_ONLINE_PAY} 
            onFlagged={onFlagged}
            flagButtonTitle="Report Transaction"
            openPromptTitle="Flag Transaction" 
            confirmMessage="This transaction is under review."
        />
    </div>

const ClosedDisputeChargePrompt = ({ setDisputePromptOpen }) => 
    <div>
        <button 
            className="test-onlineTransactionDetailClosedDisputeChargePromptRequestRefundBtn"
            onClick={event => { setDisputePromptOpen(true) }}>
            Request Refund
        </button>
    </div>

const DisputeChargeView = ({ 
    data, 
    onFlagged, 
    onRequestRefund 
}) => {
    const [disputePromptOpen, setDisputePromptOpen ] = useState(false);
    return (
        <div>
            <ClosedDisputeChargePrompt setDisputePromptOpen={setDisputePromptOpen} />
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setDisputePromptOpen(false) }
                }
                open={disputePromptOpen}
            >
                <OpenDisputeChargePrompt 
                    onFlagged={onFlagged} 
                    setDisputePromptOpen={setDisputePromptOpen} 
                    data={data} 
                    onRequestRefund={onRequestRefund}
                />
            </Dialog>
        </div>
    );
};

const DisputeChargePrompt = ({ 
    data, 
    onFlagged, 
    exchange, 
    onRequestRefund 
}) => ( 
        !data.lockedForDispute && 
        !data.paidOut && 
        !data.hasRefunded && 
        !data.payFlagged && 
        (
            exchange.target.hasRefundPolicy || 
            exchange.bid.hasRefundPolicy
        ) 
    ) ? <DisputeChargeView 
            onRequestRefund={onRequestRefund} 
            onFlagged={onFlagged} 
            data={data} 
        /> : '';

const OpenRequestPayoutPrompt = ({ data, exchange }) => 
    <div style={{
        ...theme.getExchangeDetailViewOnlinePaymentDisputeModalStyle(theme)
    }}>
        <h2>
            Request Payout
        </h2>
        <p>
            Payouts are scheduled after an exchange process has been completed.
            If a user receives the benefits of an exchange and does not verify 
            receiving the exchange then it is your responsibility to wait a reasonable 
            period of time for confirmation and then if still 
            no action it is expected for you to provide proof of fulfillment such as 
            shipment tracking details.
            <br />
            Before submitting a payout request, please consider the following
        </p>
        <ul style={{
            ...theme.getExchangeDetailViewOnlinePaymentDisputeSuggestionListStyle(theme)
        }}>
            <li>
                Have you attempted to resolve your concern via the exchange chat?
            </li>
            <li>
                Have you given a reasonable amount of time for your concern to be resolved?
            </li>
            <li>
                Do you have documentation to prove that you have fulfilled the exchange?
            </li>
        </ul>
        <p>
            To submit a manual payout request please send an email to &nbsp;
            <a href="mailto:staff@bears.exhange">
                staff@bears.exhange 
            </a>  
            <br />
            <strong>
                Please include
            </strong>
            <ul style={{ 
                    ...theme.getGeneralDefaultBulletStyle(theme),
            }}>
                <li>
                    Proof of fulfillment
                </li>
                <li>
                    Exchange ID { exchange.id }
                </li>
            </ul>
        </p>
    </div>

const ClosedRequestPayoutPrompt = ({ setRequestPayoutPromptOpen }) => 
    <div>
        <button 
            className="test-onlineTransactionDetailRequestPayoutBtn"
            onClick={event => { setRequestPayoutPromptOpen(true) }}
        >
            Request Payout
        </button>
    </div>

const RequestPayoutView = ({ data, exchange }) => {
    const [requestPayoutPromptOpen, setRequestPayoutPromptOpen ] = useState(false);
    return (
        <div>
            <ClosedRequestPayoutPrompt setRequestPayoutPromptOpen={setRequestPayoutPromptOpen} />
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setRequestPayoutPromptOpen(false) }
                }
                open={requestPayoutPromptOpen}
            >
                <OpenRequestPayoutPrompt data={data} exchange={exchange} />
            </Dialog>
        </div>
    );
};

const RequestPayoutPrompt = ({ data, exchange }) => ( 
        !data.lockedForDispute && 
        !data.paidOut && 
        !data.hasRefunded && 
        !data.payFlagged && 
        data.exchangeStartedDate 
    ) ? <RequestPayoutView 
            exchange={exchange} 
            data={data} 
        /> : '';

const PaymentStatus = ({ data }) => 
    <span>
        {
            /* istanbul ignore next */
            data.lockedForDispute ? (
                data.disputeDetails && data.disputeDetails.isChargeBack ? 
                    'Disputed - Chargeback' : 'Disputed'
                ) : (
                data.paidOut ? 'Poster Paid' : (
                    data.hasRefunded ? 'Refunded' : (
                        (data.payFlagged && !data.flagReviewed ) ? 'Payment Flagged' : (
                            (data.refundRequested && !data.refundRequestReviewed) ? 'Refund Requested' : (
                                data.exchangeConfirmedDate ? 'Payout Scheduled' : (
                                    data.exchangeStartedDate ? 'Paid - Awaiting Fulfillment Confirmation' : (
                                        data.customerPaymentCompleted ? 'Paid - Payout Pending Fulfillment' : (
                                            'Checkout Started'
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        }
    </span>

const OpenApproveEarlyPayoutPrompt = ({ onApproveEarlyPayout, exchange }) => 
    <div style={{
        ...theme.getExchangeDetailViewOnlinePaymentDisputeModalStyle(theme)
    }}>
        <p>
            Are you sure you want to payout prior to fulfillment confirmation?
        </p>
        <button 
            className="test-onlineTransactionDetailYesApproveEarlyPayoutBtn"
            onClick={event => { onApproveEarlyPayout(exchange) }}
        >
            Yes - Approve Early Payout
        </button>
    </div>

const ClosedApproveEarlyPayoutPrompt = ({ setApproveEarlyPayoutPromptOpen }) => 
    <div>
        <button 
            className="test-onlineTransactionDetailApproveEarlyPayoutBtn"
            onClick={event => { setApproveEarlyPayoutPromptOpen(true) }}
        >
            Approve Early Payout
        </button>
    </div>

const ApproveEarlyPayoutView = ({ onApproveEarlyPayout, exchange }) => {

    const [ approveEarlyPayoutPromptOpen, setApproveEarlyPayoutPromptOpen ] = useState(false);
    return (
        <div>
            <ClosedApproveEarlyPayoutPrompt setApproveEarlyPayoutPromptOpen={setApproveEarlyPayoutPromptOpen} />
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setApproveEarlyPayoutPromptOpen(false) }
                }
                open={approveEarlyPayoutPromptOpen}
            >
                <OpenApproveEarlyPayoutPrompt onApproveEarlyPayout={onApproveEarlyPayout} exchange={exchange} />
            </Dialog>
        </div>
    );
};

const OnlineTransactionDetail = ({ 
    data, 
    onFlagged, 
    exchange, 
    onApproveEarlyPayout, 
    onRequestRefund,
    posterName = 'poster',
    bidderName = 'bidder'
}) => {
    const isAdmin = useSelector(IsAdminSelector);
    return (
        <div style={{
            ...theme.getExchangeDetailViewOnlinePaymentDetailsStyle(theme)
        }}>
            <h3>
                Online Payment Details
            </h3>
            <div>
                <strong>
                    Payment Amount
                </strong>
                <br />
                <span>
                    { CurrencyService.getDisplayPrice(data.currency, data.total) }
                </span>
            </div>
            <div>
                <strong>
                    Payment Status
                </strong>
                <br />
                {
                    data.amIPayout ? 
                        <NavLink 
                            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                            to="/transactions/list"
                        >
                            <PaymentStatus data={data} />    
                        </NavLink> : 
                        <PaymentStatus data={data} />
                }

                <RefundPolicyPanel 
                    data={exchange.target.hasRefundPolicy !== undefined ? 
                        exchange.target : 
                            exchange.bid 
                    } 
                />

                {
                    !data.amIPayout &&
                        <DisputeChargePrompt 
                            onFlagged={onFlagged} 
                            data={data} 
                            exchange={exchange} 
                            onRequestRefund={onRequestRefund} 
                        />
                }
                {
                    data.amIPayout &&
                        <RequestPayoutPrompt 
                            exchange={exchange} 
                            data={data} 
                        />
                }
                {
                    !exchange.earlyPayout && 
                    exchange.fulfilled && 
                    isAdmin && 
                        <ApproveEarlyPayoutView 
                            exchange={exchange} 
                            onApproveEarlyPayout={onApproveEarlyPayout} 
                        />
                }

            </div>
            {
                data.obtainMethod ? 
                <div>
                    <h3>
                        Exchange Details
                    </h3>
                    <p>
                        { 
                            ObtainMethodCopy({ 
                                bid: data, 
                                bidderIsReceiver: true,
                                posterName: data.target && data.target.isAuthor ? 'you' : posterName,
                                bidderName: data.isAuthor ? 'you' :  bidderName
                            }) 
                        }
                    </p>
                </div>: ''
            }
            <div>
                {
                    data.obtainMethod ? '' : 
                    <h3>
                        Exchange Details
                    </h3>
                }
                <p style={{
                    ...theme.getOnlineTransactionDetailTextStyle(theme)  
                }}>
                    { data.details }
                </p>
            </div>

            <hr />

        </div>
    );
};

export default OnlineTransactionDetail;
