import AuthService from './../../service/auth';
import ValidateService from './../../service/validate';
import mapStateToProps from '../../store/map/device-is-online';
import mapDispatchToProps from '../../store/map/on-update-device-online';
import { connect } from 'react-redux';
import { FORM_FIELD, AbstractPrompt } from './core/abstract';

class _LoginWithTokenPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, LoginWithTokenPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        
    }

    static getValidators()
    {
        return {
            token: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    getFieldsModel()
    {
        return {
            token: {
                name: '2FA Token*',
                error: 'Please provide the current 2FA Token',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onServerResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        this.props.onLoginSuccess(response);
    }

    getConfirmUi(confirmMessage = 'Welcome Back!')
    {
        return super.getConfirmUi(confirmMessage);
    }

    onServerError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onSendToServer()
    {
        AuthService.loginWithToken({
            request: this.getServerRequest(), 
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        const baseRequest = this.props.getLoginRequest();
        return {
            ...baseRequest,
            token: this.state.token.value,
        };
    }

    getSubmitButtonUi(buttonText = 'Verify')
    {
        return super.getSubmitButtonUi(buttonText);
    }
}

const LoginWithTokenPrompt = connect(mapStateToProps, mapDispatchToProps)(_LoginWithTokenPrompt);
export default LoginWithTokenPrompt;
