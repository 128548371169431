import React from 'react';
import LoadingPanel from './../../../panel/core/loading';
import { Redirect } from 'react-router-dom';
import theme from './../../../theme';
import { AbstractFlexContainer } from './view';
import DateTimePicker from 'react-datetime-picker';
import ResizeService from './../../../../service/resize';

export const FORM_FIELD = {
    FILE: 'file',
    TEXT_AREA: 'textarea',
    TEXT: 'text',
    CHECKBOX: 'checkbox',
    DROP: 'drop',
    PASSWORD: 'password',
    DATETIME: 'datetime'
};

// istanbul ignore next 
const makeOnSelectAbstractFile = ({ onAddFile, parseFileDetails }) => event => {
    const isDrop = event.dataTransfer && event.dataTransfer.files;
    const files = isDrop ? event.dataTransfer.files : 
        event.target.files;
    for(const aFile of files)
    {  
        onAddFile(parseFileDetails(aFile));
    }
};

// istanbul ignore next 
export class AbstractPrompt extends React.Component
{
    constructor(parms, validators)
    {
        super(parms);

        this.resizeFun = () => {};
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.getFormFieldsUi = this.getFormFieldsUi.bind(this);
        this.getFormFieldUi = this.getFormFieldUi.bind(this);
        this.getFileUi = this.getFileUi.bind(this);
        this.getFileUi_labelStyle = this.getFileUi_labelStyle.bind(this);
        this.getFileUi_getContainerStyle = this.getFileUi_getContainerStyle.bind(this);
        this.getFileUi_getErrorStyle = this.getFileUi_getErrorStyle.bind(this);
        this.updateFieldStyles = this.updateFieldStyles.bind(this);
        this.getCheckboxUi = this.getCheckboxUi.bind(this);
        this.getCheckboxUi_styles = this.getCheckboxUi_styles.bind(this);
        this.getCheckboxUi_getContainerStyle = this.getCheckboxUi_getContainerStyle.bind(this);
        this.getCheckboxUi_getErrorStyle = this.getCheckboxUi_getErrorStyle.bind(this);
        this.getCheckboxUi_getFieldContainerStyle = this.getCheckboxUi_getFieldContainerStyle.bind(this);
        this.getCheckboxUi_textSectionStyle = this.getCheckboxUi_textSectionStyle.bind(this);
        this.getDropUi = this.getDropUi.bind(this);
        this.getDropUi_styles = this.getDropUi_styles.bind(this);
        this.getDropUi_getContainerStyle = this.getDropUi_getContainerStyle.bind(this);
        this.getDropUi_getErrorStyle = this.getDropUi_getErrorStyle.bind(this);
        this.getDropUi_getFieldContainerStyle = this.getDropUi_getFieldContainerStyle.bind(this);
        this.getDropUi_getSelectFieldStyle = this.getDropUi_getSelectFieldStyle.bind(this);
        this.getDateTimeUi = this.getDateTimeUi.bind(this);
        this.getDateTimeUi_styles = this.getDateTimeUi_styles.bind(this);
        this.getDateTimeUi_getContainerStyle = this.getDateTimeUi_getContainerStyle.bind(this);
        this.getDateTimeUi_getErrorStyle = this.getDateTimeUi_getErrorStyle.bind(this);
        this.getTextAreaUi = this.getTextAreaUi.bind(this);
        this.getTextAreaUi_styles = this.getTextAreaUi_styles.bind(this);
        this.getTextAreaUi_getContainerStyle = this.getTextAreaUi_getContainerStyle.bind(this);
        this.getTextAreaUi_getErrorStyle = this.getTextAreaUi_getErrorStyle.bind(this);
        this.getTextAreaUi_textAreaStyle = this.getTextAreaUi_textAreaStyle.bind(this);
        this.getPasswordUi = this.getPasswordUi.bind(this);
        this.getPasswordUi_styles = this.getPasswordUi_styles.bind(this);
        this.getPasswordUi_getContainerStyle = this.getPasswordUi_getContainerStyle.bind(this);
        this.getPasswordUi_getErrorStyle = this.getPasswordUi_getErrorStyle.bind(this);
        this.getPasswordUi_getInputFieldStyle = this.getPasswordUi_getInputFieldStyle.bind(this);
        this.getTextUi = this.getTextUi.bind(this);
        this.getTextUi_styles = this.getTextUi_styles.bind(this);
        this.getTextUi_getContainerStyle = this.getTextUi_getContainerStyle.bind(this);
        this.getTextUi_getErrorStyle = this.getTextUi_getErrorStyle.bind(this);
        this.getTextUi_getInputFieldStyle = this.getTextUi_getInputFieldStyle.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.controlValueChange = this.controlValueChange.bind(this);
        this.evaluateFieldsVisibility = this.evaluateFieldsVisibility.bind(this);
        this.validateState = this.validateState.bind(this);
        this.onCustomControlValueChange = this.onCustomControlValueChange.bind(this);
        this.onChildValidControlChange = this.onChildValidControlChange.bind(this);
        this.setContactingServer = this.setContactingServer.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.isUsingSubmitButton = this.isUsingSubmitButton.bind(this);
        this.getSingleFieldPrompt = this.getSingleFieldPrompt.bind(this);
        this.getSingleFieldPrompt_getContainerStyle = this.getSingleFieldPrompt_getContainerStyle.bind(this);
        this.getSingleFieldPrompt_getFieldsStyle = this.getSingleFieldPrompt_getFieldsStyle.bind(this);
        this.getPromptUi_getContainerStyle = this.getPromptUi_getContainerStyle.bind(this);
        this.sendUserTo = this.sendUserTo.bind(this);
        this.getTextUi_singleField_getContainerStyle = this.getTextUi_singleField_getContainerStyle.bind(this);
        this.getTextUi_singleField_getErrorStyle = this.getTextUi_singleField_getErrorStyle.bind(this);
        this.getTextUi_singleField_getInputFieldStyle = this.getTextUi_singleField_getInputFieldStyle.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);

        this.uiRefList = {};

        this.validator = validators;
        this.state = this.getDefaultState(parms);
    }

    isUsingSubmitButton() 
    {
        return true;
    }


    setFocus(aField) {
        this.uiRefList[aField].focus();
    }

    onSendToServer() {
        throw new Error('Must override AbstractPrompt.onSendToServer');
    }

    onFormSubmit(e)
    {
        e.preventDefault();
        if(this.state.isValid){
            this.setContactingServer(true);
            this.onSendToServer();
        }
    }

    getDefaultState(store)
    {
        return {
            requestComplete: false,
            contactingServer: false,
            message: '',
            isValid: false,
            redirectTo: undefined,
            textUiStyles: this.getTextUi_styles(),
            passwordUiStyles: this.getPasswordUi_styles(),
            textAreaUiStyles: this.getTextAreaUi_styles(),
            dateTimeUiStyles: this.getDateTimeUi_styles(),
            dropUiStyles: this.getDropUi_styles(),
            checkboxUiStyles: this.getCheckboxUi_styles(),

        };
    }

    sendUserTo(url)
    {
        const nextState = {
            ...this.state,
            redirectTo: url,
        };
        this.setState(nextState);
    }

    getCheckboxUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getCheckboxUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    getCheckboxUi_getFieldContainerStyle()
    {
        return theme.getAbstractCheckFieldContainer(theme)
    }

    getCheckboxUi_textSectionStyle()
    {
        return theme.getAbstractCheckFieldText(theme);
    }

    getCheckboxUi_styles()
    {
        const containerStyle = this.getCheckboxUi_getContainerStyle();
        const errorStyle = this.getCheckboxUi_getErrorStyle();
        const fieldStyle = this.getCheckboxUi_getFieldContainerStyle();
        const textStyle = this.getCheckboxUi_textSectionStyle();
        return {
            containerStyle,
            errorStyle,
            fieldStyle,
            textStyle
        };
    }

    getCheckboxUi(aField) 
    {
        const {
            containerStyle,
            errorStyle,
            fieldStyle,
            textStyle
        } = this.state.dropUiStyles;
        
        return (
            <div style={containerStyle}>
                <div style={errorStyle}>
                    {
                        !this.state[aField].valid && this.state[aField].touched ? this.state[aField].error : ''
                    }
                </div>
                <div>
                    <div style={fieldStyle}>
                        <input 
                            disabled={this.state[aField].disabled !== undefined ? this.state[aField].disabled : false }
                            type={this.state[aField].type} 
                            ref={inputRef => { this.uiRefList[aField] = inputRef }} 
                            name={aField} 
                            checked={this.state[aField].value} 
                            onChange={this.onInputChange} 
                        />

                        <span style={textStyle}>
                            { this.state[aField].customField ? this.state[aField].customField : this.state[aField].name }
                        </span>
                        
                    </div>
                </div>
            </div>
        );
    }

    getDropUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getDropUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    getDropUi_getFieldContainerStyle()
    {
        return theme.getAbstractDropContainerField(theme);
    }

    getDropUi_getSelectFieldStyle()
    {
        return {
            ...theme.getAbstractDropField(theme),
            ...theme.getMediumTextStyle(theme),
        };
    }

    getDropUi_styles()
    {
        const containerStyle = this.getDropUi_getContainerStyle();
        const errorStyle = this.getDropUi_getErrorStyle();
        const fieldStyle = this.getDropUi_getFieldContainerStyle();
        const selectStyle = this.getDropUi_getSelectFieldStyle();
        return {
            containerStyle,
            errorStyle,
            fieldStyle,
            selectStyle
        };
    }

    getDropUi(aField)
    {
        const {
            containerStyle,
            errorStyle,
            fieldStyle,
            selectStyle
        } = this.state.dropUiStyles;

        return (
            <div style={containerStyle}>
                <div style={errorStyle}>
                    {
                        !this.state[aField].valid && this.state[aField].touched ? this.state[aField].error : ''
                    }
                </div>
                <div>
                    <div style={fieldStyle}>
                        { this.state[aField].name }
                    </div>
                    <select 
                        style={selectStyle}
                        disabled={this.state[aField].disabled !== undefined ? this.state[aField].disabled : false }
                        ref={inputRef => { this.uiRefList[aField] = inputRef }} 
                        onChange={this.onInputChange} 
                        name={aField} 
                        defaultValue={this.state[aField].value}
                    >
                        {
                            this.state[aField].valueList.map(aValue => 
                                <option key={aValue.value} value={aValue.value}>
                                    {aValue.name}
                                </option>
                            )
                        }
                    </select>
                </div>
            </div>
        );
    }

    getTextAreaUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getTextAreaUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    getTextAreaUi_textAreaStyle()
    {
        return theme.getAbstractTextAreaField(theme);
    }

    getDateTimeUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getDateTimeUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    updateFieldStyles()
    {
        let nextState = this.state;
        nextState.textUiStyles = this.getTextUi_styles();
        nextState.passwordUiStyles = this.getPasswordUi_styles();
        nextState.textAreaUiStyles = this.getTextAreaUi_styles();
        nextState.dateTimeUiStyles = this.getDateTimeUi_styles();
        nextState.dropUiStyles = this.getDropUi_styles();
        nextState.checkboxUiStyles = this.getCheckboxUi_styles();
        this.setState(nextState);
    }

    componentDidMount()
    {
        this.resizeFun = ResizeService.getOnResize(this.updateFieldStyles);
        ResizeService.startListening(this.resizeFun);
    }

    componentWillUnmount()
    {
        ResizeService.stopListening(this.resizeFun);
    }

    getDateTimeUi_styles()
    {
        const containerStyle = this.getDateTimeUi_getContainerStyle();
        const errorStyle = this.getDateTimeUi_getErrorStyle();
        const fieldStyle = this.getDropUi_getFieldContainerStyle();
        return {
            containerStyle,
            errorStyle,
            fieldStyle
        };
    }

    getDateTimeUi(aField)
    {
        const {
            containerStyle,
            errorStyle,
            fieldStyle
        } = this.state.dateTimeUiStyles;

        return (
            <div style={containerStyle}>
                <div style={errorStyle}>
                    {
                        !this.state[aField].valid && this.state[aField].touched ? this.state[aField].error : ''
                    }
                </div>
                <div style={fieldStyle}>
                    { this.state[aField].name }
                </div>
                <DateTimePicker
                    value={this.state[aField].value} 
                    onChange={nextVal => {  
                        nextVal = nextVal === null ? '' : nextVal;
                        this.controlValueChange(aField, nextVal);
                    }} 
                />
            </div>
        );
    }

    getTextAreaUi_styles()
    {
        const containerStyle = this.getTextAreaUi_getContainerStyle();
        const errorStyle = this.getTextAreaUi_getErrorStyle();
        const fieldStyle = this.getTextAreaUi_textAreaStyle();
        return {
            containerStyle,
            errorStyle,
            fieldStyle
        };
    }

    getTextAreaUi(aField)
    {
        const {
            containerStyle,
            errorStyle,
            fieldStyle
        } = this.state.textAreaUiStyles;

        return (
            <div style={containerStyle}>
                <div style={errorStyle}>
                    {
                        !this.state[aField].valid && this.state[aField].touched ? this.state[aField].error : ''
                    }
                </div>
                <textarea 
                    placeholder={this.state[aField].name}
                    style={fieldStyle}
                    ref={inputRef => { this.uiRefList[aField] = inputRef }} 
                    disabled={this.state[aField].disabled !== undefined ? this.state[aField].disabled : false }
                    name={aField} 
                    onChange={this.onInputChange} 
                    value={this.state[aField].value} 
                />
            </div>
        );
    }

    getPasswordUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getPasswordUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    getPasswordUi_getInputFieldStyle()
    {
        return theme.getAbstractUserNamePasswordField(theme);
    }

    getPasswordUi_styles()
    {
        const containerStyle = this.getPasswordUi_getContainerStyle();
        const errorStyle = this.getPasswordUi_getErrorStyle();
        const fieldStyle = this.getPasswordUi_getInputFieldStyle();
        return {
            containerStyle,
            errorStyle,
            fieldStyle
        };
    }

    getPasswordUi(aField)
    {
        const {
            containerStyle,
            errorStyle,
            fieldStyle
        } = this.state.passwordUiStyles;
        
        return (
            <div style={containerStyle}>
                <div style={errorStyle}>
                    {
                        !this.state[aField].valid && this.state[aField].touched ? this.state[aField].error : ''
                    }
                </div>
                <div>
                    <input 
                        ref={inputRef => { this.uiRefList[aField] = inputRef }} 
                        style={fieldStyle}
                        placeholder={this.state[aField].name}
                        disabled={this.state[aField].disabled !== undefined ? this.state[aField].disabled : false }
                        type={ this.state[aField].type} 
                        name={aField} 
                        value={this.state[aField].value} 
                        onChange={this.onInputChange} 
                    />
                </div>
                
            </div>
        );
    }

    getTextUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getTextUi_singleField_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getTextUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    getTextUi_singleField_getErrorStyle()
    {
        return theme.getAbstractSingleTextError(theme);
    }

    getTextUi_getInputFieldStyle()
    {
        return theme.getAbstractTextField(theme);
    }

    getTextUi_singleField_getInputFieldStyle()
    {
        return theme.getAbstractSingleTextField(theme);
    }

    getTextUi_styles()
    {
        const isSingleField = Object.keys(this.validator).length === 1 ? true : false;
        
        const containerStyle = isSingleField ? this.getTextUi_singleField_getContainerStyle() : this.getTextUi_getContainerStyle()
        const errorStyle = isSingleField ? this.getTextUi_singleField_getErrorStyle() : this.getTextUi_getErrorStyle();
        const fieldStyle = isSingleField ? this.getTextUi_singleField_getInputFieldStyle() : this.getTextUi_getInputFieldStyle();
        
        return {
            containerStyle,
            errorStyle,
            fieldStyle
        };
    }

    getTextUi(aField)
    {
        const {
            containerStyle,
            errorStyle,
            fieldStyle
        } = this.state.textUiStyles;

        return (
            <div style={containerStyle}>
                <div style={errorStyle}>
                    {
                        !this.state[aField].valid && this.state[aField].touched ? this.state[aField].error : ''
                    }
                </div>

                <div>
                    <input 
                        ref={inputRef => { this.uiRefList[aField] = inputRef }} 
                        style={fieldStyle}
                        placeholder={this.state[aField].name}
                        disabled={this.state[aField].disabled !== undefined ? this.state[aField].disabled : false }
                        type={ this.state[aField].type} 
                        name={aField} 
                        value={this.state[aField].value} 
                        onChange={this.onInputChange} />
                </div>
                
            </div>
        );
    }

    getFileUi_getContainerStyle()
    {
        return theme.getAbstractFieldContainerStyle(theme);
    }

    getFileUi_getErrorStyle()
    {
        return theme.getAbstractTextError(theme);
    }

    getFileUi_labelStyle()
    {
        return theme.getAbstractFileLabelStyle(theme);
    }

    getFileUi(aField)
    {
        const onSelectFile = makeOnSelectAbstractFile({
            onAddFile: this.state[aField].onAddFile,
            parseFileDetails: this.state[aField].parseFileDetails
        });
        return (
            <div style={{
                ...this.getFileUi_getContainerStyle()
            }}>
                <div style={{
                    ...this.getFileUi_getErrorStyle()
                }}>
                    {
                        !this.state[aField].valid && this.state[aField].touched ? this.state[aField].error : ''
                    }
                </div>
                <div style={{
                    ...this.getFileUi_labelStyle()
                }}>
                    { this.state[aField].name }
                </div>
                <div>
                    <input 
                        ref={inputRef => { this.uiRefList[aField] = inputRef }} 
                        type="file" 
                        onChange={onSelectFile}
                        accept={this.state[aField].validFormats}
                        disabled={this.state[aField].disabled !== undefined ? this.state[aField].disabled : false }
                        name={aField}
                    />
                </div>
            </div>
        );
    }

    getFormFieldUi(aField)
    {
        switch(this.state[aField].type)
        {
            case FORM_FIELD.FILE:
                return this.getFileUi(aField);

            case FORM_FIELD.CHECKBOX:
                return this.getCheckboxUi(aField);

            case FORM_FIELD.DROP:
                return this.getDropUi(aField);

            case FORM_FIELD.TEXT_AREA:
                return this.getTextAreaUi(aField);

            case FORM_FIELD.DATETIME:
                return this.getDateTimeUi(aField);

            case FORM_FIELD.PASSWORD:
                return this.getPasswordUi(aField);

            case FORM_FIELD.TEXT:
                // no break
                // falls through
            default:
                return this.getTextUi(aField);
        }
    }

    getFormFieldsUi()
    {
        let output = [];
        for(const aField in this.validator)
        {
            try
            {
                if(this.state[aField].visible)
                {

                    output.push(
                        <section key={aField}>
                            { this.getFormFieldUi(aField) }
                        </section>
                    );
                }
            }
            catch(err)
            {
                throw new Error(`Unable to read ${aField}`);
            }
        }
        return output;
    }

    onInputChange(e) {
        const name = e.target.name;
        const value = this.state[name].type === FORM_FIELD.CHECKBOX ? e.target.checked : e.target.value;
        this.controlValueChange(name, value);
    }

    controlValueChange(name, value, entity = null)
    {
        let updateState = this.state;
        const valid = this.validator[name].isValid(value, updateState);
        updateState[name] = {
            ...updateState[name],
            value,
            valid,
            touched: true
        };

        if(entity) {
            updateState[name].entity = entity;
        }

        const overallState = this.evaluateFieldsVisibility(updateState);

        const finalState = this.validateState(overallState);

        this.setState(finalState);
    }

    evaluateFieldsVisibility(currentState)
    {
        for(const fieldName in this.validator)
        {
            currentState[fieldName].visible = this.validator[fieldName].isVisible !== undefined ? this.validator[fieldName].isVisible(currentState) : (currentState[fieldName].visible !== undefined ? currentState[fieldName].visible : true);
        }
        return currentState;
    }

    validateState(updateState)
    {
        let isValid = true;
        for(const fieldName in this.validator)
        {
            updateState[fieldName].valid = updateState[fieldName].visible ? this.validator[fieldName].isValid(updateState[fieldName].value, updateState) : true;
            if(!updateState[fieldName].valid)
            {
                isValid = false;
            }
        }
        return {
            ...updateState,
            isValid
        };
    }

    onCustomControlValueChange(name, newValue, entity = null)
    {
        this.controlValueChange(name, newValue, entity);
    }

    onChildValidControlChange(name, isValid, valueList)
    {
        const currentState = this.state;
        const nextState = {
            ...currentState,
            [name]: {
                ...currentState[name],
                touched: true,
                value: valueList,
                valid: isValid
            }
        };

        const overallState = this.evaluateFieldsVisibility(nextState);
        const finalState = this.validateState(overallState);
        this.setState(finalState);

    }

    setContactingServer(contactingServer)
    {
        const nextState = {
            ...this.state,
            contactingServer
        };
        this.setState(nextState);
    }

    clearFields(updateState)
    {
        for(const fieldName in this.validator)
        {
            if(updateState[fieldName].type !== FORM_FIELD.DROP)
            {
                updateState[fieldName].value = '';
            }
            updateState[fieldName].valid = false;
            updateState[fieldName].touched = false;
        }
        return this.validateState(updateState);
    }

    getServerFeedbackUi()
    {
        return this.state.message !== '' ? 
            <div style={{
                ...theme.getAbstractTextError(theme),
            }}>
                { this.state.message }
            </div> : '';
    }

    getBaseStateOnError(error)
    {
        return {
            ...this.state,
            message: error.response && error.response.data && error.response.data.error ? error.response.data.error : (
                error.response && error.response.error ? error.response.error : error.message
            ),
            requestComplete: false,
            contactingServer: false,
        };
    }

    getBaseStateOnResponse({ message = '', success = true } = {})
    {
        return {
            ...this.state,
            message,
            requestComplete: success,
            contactingServer: false
        };
    }

    getContactingServerUi()
    {
        return <LoadingPanel />
    }

    getSubmitButtonUi(buttonText = 'Save')
    {
        return (
            this.isUsingSubmitButton() ? <section>
                <button type="submit" disabled={!this.state.isValid}>
                    { buttonText }
                </button>
            </section> : ''
        );
    }

    getSingleFieldPrompt_getContainerStyle()
    {
        return theme.getAbstractSingleFieldContainerStyle(theme);
    }

    getSingleFieldPrompt_getFieldsStyle()
    {
        return theme.getAbstractSingleFieldStyle(theme);
    }

    getSingleFieldPrompt()
    {
        return (
            <div style={{
                ...this.getSingleFieldPrompt_getContainerStyle(),
            }}>
                <form onSubmit={this.onFormSubmit}>
                    { this.getServerFeedbackUi() }
                    <AbstractFlexContainer>
                        <div style={{
                            ...this.getSingleFieldPrompt_getFieldsStyle(),
                        }}>
                            { this.getFormFieldsUi() }
                        </div>

                        <div>
                            { this.getSubmitButtonUi() }
                        </div>
                    </AbstractFlexContainer>
                </form>
            </div>
        );
    }

    getPromptUi_getContainerStyle()
    {
        return theme.getAbstractPromptStyle(theme);
    }

    getFormField_getContainerStyle()
    {
        return theme.getAbstractFormContainerStyle(theme);
    }

    getPromptUi()
    {
        if(Object.keys(this.validator).length === 1)
        {
            return this.getSingleFieldPrompt();
        } 
        else 
        {
            return (
                <div style={{
                    ...this.getPromptUi_getContainerStyle(),
                }}>
                    <form onSubmit={this.onFormSubmit} style={{
                        ...this.getFormField_getContainerStyle(),
                    }}>
                        { this.getServerFeedbackUi() }
                        { this.getFormFieldsUi() }
                        { this.getSubmitButtonUi() }
                    </form>
                </div>
            );
        }
    }

    getConfirmUi(confirmMessage = 'Saved.')
    {
        return (
            <section>
                <h2 style={{ 
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    { confirmMessage }
                </h2>
            </section>
        );
    }

    render()
    {
        return this.state.redirectTo ? <Redirect to={this.state.redirectTo} /> :
            this.state.contactingServer ? this.getContactingServerUi() : 
                this.state.requestComplete ? this.getConfirmUi() : 
                    this.getPromptUi();
    }
}

export default AbstractPrompt;
