import React from 'react';
import { connect } from 'react-redux';
import PageTemplate from './template/page';
import mapStateToProps from './../store/map/is-user';
import UserLandingPanel from './../ui/panel/user-landing';
import ForgotPrompt from './../ui/prompt/forgot';

const _ForgotPage = ({ isUser }) => 
    <PageTemplate isUser={isUser}>
        { 
            /* istanbul ignore next */
            isUser ? <UserLandingPanel /> : <ForgotPrompt /> 
        }
    </PageTemplate>

const ForgotPage = connect(mapStateToProps)(_ForgotPage);
export default ForgotPage;
