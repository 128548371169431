import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

export const LoginPromptContainer = ({ children }) => {
    const getLoginPromptStyle = () => theme.getLoginPromptStyle(theme);
    const [loginPromptStyle, setLoginPromptStyle] = useState(getLoginPromptStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setLoginPromptStyle( getLoginPromptStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={loginPromptStyle}>
            { children }
        </div>
    );

};

