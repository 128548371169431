import React from 'react';
import AuthPageTemplate from './template/auth-page';
import NoticesPanel from './../ui/panel/notices';

const NoticesPage = () => {
    return (
        <AuthPageTemplate>
            <NoticesPanel />
        </AuthPageTemplate>
    );
};

export default NoticesPage;
