import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ImgListPanel from './../ui/panel/img-list';
import ProfileMenu from './../ui/menu/profile';

const ImgListPage = () => {
    return (
        <AuthPageTemplate 
            childMenu={
                <div>
                    <ProfileMenu />
                </div>
            }
        >
            <ImgListPanel />
        </AuthPageTemplate>
    );
};

export default ImgListPage;
