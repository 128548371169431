import React from 'react';
import AuthPageTemplate from './template/auth-page';
import PaymentMethodsPanel from './../ui/panel/payment-methods';
import theme from './../ui/theme';
import ProfileMenu from './../ui/menu/profile';

const PaymentMethodsPage = () => 
    <AuthPageTemplate 
        childMenu={ <ProfileMenu /> }>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <PaymentMethodsPanel />
        </div>
    </AuthPageTemplate>

export default PaymentMethodsPage;
