import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../service/resize';
import theme from './../../../theme';

export const AbstractFlexContainer = ({ children }) => {
    const getAbstractFlexContainerStyle = () => theme.getAbstractFlexFormStyle(theme);
    const [ abstractFlexContainerStyle, setAbstractFlexContainerStyle] = useState(getAbstractFlexContainerStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setAbstractFlexContainerStyle( getAbstractFlexContainerStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={abstractFlexContainerStyle}>
            { children }
        </div>
    );
};