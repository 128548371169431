import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../../service/resize';
import UiService from './../../../../../service/ui';
import { NavLink } from 'react-router-dom';
import theme from './../../../../theme';
import Pagination from './../../../../prompt/core/pagination';

const Bid = ({ bid }) => {
    
    const getWidgetPendingBidsStyle = () => theme.getWidgetPendingBidsStyle(theme);
    const [ widgetPendingBidsStyle, setWidgetPendingBidsStyle ] = useState( getWidgetPendingBidsStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setWidgetPendingBidsStyle( getWidgetPendingBidsStyle() );
    });
    useEffect(resizeEffect);

    const title = bid.type === 'offer' ? bid.offerTitle[0] : bid.seekTitle[0];
    const baseUrl = bid.type === 'offer' ? '/offer/' : '/seek/';
    const url = baseUrl + bid.targetId;
    return (
        <div style={widgetPendingBidsStyle}>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={url}
            >
                <div>
                    <h3>
                        { title }
                    </h3>
                    <p>
                        { UiService.timeSince(bid.createdOn) }
                    </p>
                </div>
            </NavLink>
        </div>
    );
};

const GetPagination = ({ data, onLoadSpecifiedPage }) =>
    data.page !== undefined && 
    data.perPage && 
    data.total ? <Pagination 
                    total={data.total}
                    page={data.page}
                    onLoadSpecifiedPage={onLoadSpecifiedPage}
                    perPage={data.perPage} /> : '';

const PopulatedView = ({ list }) => {
    const getUserWidgetFlexParentStyle = () => theme.getUserWidgetFlexParentStyle(theme);
    const [ userWidgetFlexParentStyle, setUserWidgetFlexParentStyle] = useState(getUserWidgetFlexParentStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetFlexParentStyle(getUserWidgetFlexParentStyle());
    });
    useEffect(resizeEffect);
    return (
        <section style={userWidgetFlexParentStyle}>
            {
                list.map((bid, index) => <Bid key={index} bid={bid} />)
            }
        </section>
    );
};

const EmptyView = ({ userWidgetHeaderTextStyle }) => 
    <div>
        <p style={userWidgetHeaderTextStyle}>
            No Active Bids
        </p>
    </div>

const WidgetPendingBidsViewPanel = ({ data, onLoadSpecifiedPage }) => {
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle] = useState(getUserWidgetHeaderTextStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetHeaderTextStyle( getUserWidgetHeaderTextStyle());
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    My Pending Bids
                </div>
            </div>
            <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
            {
                data.list.length < 1 ? 
                    <EmptyView userWidgetHeaderTextStyle={userWidgetHeaderTextStyle} /> : 
                        <PopulatedView list={data.list} />
            }
            <GetPagination data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} />
        </section>
    );
};

export default WidgetPendingBidsViewPanel;
