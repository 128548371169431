import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../../service/resize';
import UiService from './../../../../../service/ui';
import { NavLink } from 'react-router-dom';
import theme from './../../../../theme';

const Exchange = ({ exchange }) => {
    const getWidgetExchangesStyle = () => theme.getWidgetExchangesStyle(theme);
    const [ widgetExchangesStyle, setWidgetExchangesStyle ] = useState(getWidgetExchangesStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setWidgetExchangesStyle( getWidgetExchangesStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={widgetExchangesStyle}>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/exchange/${exchange.id}`}
            >
                <h4 style={{
                    ...theme.getMediumTextStyle(theme),
                }}>
                    { exchange.title }
                </h4>
                <p style={{
                    ...theme.getSmallTextStyle(theme),  
                }}>
                    { UiService.timeSince(exchange.createdOn) }
                </p>
            </NavLink>
        </div>
    );
};

const PopulatedView = ({ list }) => {
    const getUserWidgetFlexParentStyle = () => theme.getUserWidgetFlexParentStyle(theme);
    const [ userWidgetFlexParentStyle, setUserWidgetFlexParentStyle] = useState(getUserWidgetFlexParentStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetFlexParentStyle(getUserWidgetFlexParentStyle());
    });
    useEffect(resizeEffect);

    return (
        <section style={userWidgetFlexParentStyle}>
            {
                list.map((exchange, index) => <Exchange key={index} exchange={exchange} />)
            }
        </section>
    );
};

const EmptyView = ({ userWidgetHeaderTextStyle }) => {
    const getContainerStyle = () => theme.getUserWidgetSubHeaderTextStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section style={userWidgetHeaderTextStyle}>
            <p style={containerStyle}>
                You have no active exchanges.
            </p>
            
        </section>
    );
};


const WidgetMyActiveExchangesViewPanel = ({ data }) => {
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle ] = useState(getUserWidgetHeaderTextStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetHeaderTextStyle(getUserWidgetHeaderTextStyle());
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    My Active Exchanges
                </div>
            </div>
            {
                data.exchangeList.list.length < 1 ? 
                    <EmptyView userWidgetHeaderTextStyle={userWidgetHeaderTextStyle} /> : 
                        <PopulatedView list={data.exchangeList.list} />
            }
        </section>
    );
};

export default WidgetMyActiveExchangesViewPanel;
