export const PAY_FINE_TYPE_DISPUTE = 'dispute';

const validValues = [
    PAY_FINE_TYPE_DISPUTE
];

const getDropDownValues = () => [
    {
        name: 'Lost Dispute',
        value: PAY_FINE_TYPE_DISPUTE
    }
];

const isValid = value => validValues.includes(value);

const PayFineTypeEnum = {
    PAY_FINE_TYPE_DISPUTE,
    getDropDownValues,
    isValid
};

export default PayFineTypeEnum;
