import { REDUX_STORAGE } from './state/constants';

/* istanbul ignore next */
const Save = store => next => action => {
    const result = next(action);
    localStorage[REDUX_STORAGE] = JSON.stringify(store.getState());
    return result;
};

export default Save;
