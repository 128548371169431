export const getExchangesContainerStyle = (theme) => ({
    backgroundColor: 'rgb(238, 234, 234)',
    borderRadius: '0.25rem',
    margin: '1rem auto',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    maxWidth: '60rem',
    display: 'inline-block',
    boxShadow: 'rgba(29, 29, 29, 0.5) 0px 0.1rem 0.1rem',
    width: theme.isTablet() ? '100%' : 'calc(100% - 1.5rem)',
    textAlign: 'center'
});

export const getExchangesPaddingStyle = (theme) => ({
    padding: '0 1.5rem 1rem',
});

export const getExchangesLandingContainerStyle = (theme) => ({
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center'
});

export const getExchangesLandingShortCutOuterContainerStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '58.5rem',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
});

export const getExchangesLandingShortCutContainerStyle = (theme) => ({
    margin: '.75rem .75rem 2rem',
    display: 'inline-block',
    borderColor: theme.secondaryColor,
    backgroundColor: theme.buttonColor,
    padding: '.5rem',
    height: 'fit-content',
    width: '9rem',
    verticalAlign: 'top',
    borderRadius: '.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)'
});

export const getExchangesLandingShortCutDetailsStyle = (theme) => ({
    fontSize: '8pt',
});
