import React from 'react';
import DetailPanel from './../../core/detail';
import PayoutService from './../../../../service/payout';
import PayService from './../../../../service/pay';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import AdminPayTransDetailsViewPanel from './view';

class _AdminPayTransDetailsPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.reschedulePayout = this.reschedulePayout.bind(this);
        this.issueRefund = this.issueRefund.bind(this);
        this.onReschedulePayoutResponse = this.onReschedulePayoutResponse.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        PayoutService.getAdminPayTransDetail({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            paymentIntentId: inputObj.paymentIntentId
        };
    }

    componentDidMount()
    {
        const paymentIntentId = this.props.paymentIntentRecord.paymentIntentId;
        this.loadData({ paymentIntentId });
    }

    onReschedulePayoutResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
        };
        this.setState(nextState);
    }

    reschedulePayout(payId)
    {
        super.loadData({  });
        PayoutService.reschedulePayout({
            request: {
                payId
            },
            onResponse: this.onReschedulePayoutResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    issueRefund(payId)
    {
        super.loadData({ });
        PayService.adminIssueRefund({
            request: {
                payId
            },
            onResponse: this.onReschedulePayoutResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                <AdminPayTransDetailsViewPanel 
                    data={this.state.data.api} 
                    flagReviewDetails={this.state.data.flagReviewDetails}
                    paymentIntentRecord={this.props.paymentIntentRecord} 
                    reschedulePayout={this.reschedulePayout}
                    issueRefund={this.issueRefund}
                />
            </section>
        );
    }
}

const AdminPayTransDetailsPanel = connect(mapStateToProps, mapDispatchToProps)(_AdminPayTransDetailsPanel);
export default AdminPayTransDetailsPanel;
