import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { codeToCopy } from './../../prompt/core/enum/carrier-member-role';
import theme from '../../theme';
import UpdateCarrierMemberContactInfoPrompt from './../../prompt/update-carrier-member-contact-info';

const MemberHeader = ({ 
    carrierMember, 
    onContactInfoUpdated,
    groupCreatorUserId 
}) => {
    const [ isUpdateContactDetailsPromptOpen, setIsUpdateContactDetailsPromptOpen ] = useState(false);
    return (
        <div>
            <h4>
                Your Details
            </h4>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <img 
                                src={carrierMember.photo.data} 
                                alt="File Missing" 
                                style={{
                                    ...theme.getImgAddViewStyle(theme),
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Name
                            </strong>
                        </td>
                        <td>
                            { carrierMember.name }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Phone
                            </strong>
                        </td>
                        <td>
                            { carrierMember.contactPhone }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Email
                            </strong>
                        </td>
                        <td>
                            { carrierMember.contactEmail }
                        </td>
                    </tr>
                    <tr>
                        <td>

                        </td>
                        <td>
                            <button 
                                className="test-carrierGroupDetailsMemberHeaderUpdateYourContactDetailsBtn"
                                onClick={event => { setIsUpdateContactDetailsPromptOpen(true) }}>
                                Update Your Contact Details
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>
                                Roles
                            </strong>
                        </td>
                        <td>
                            {
                                carrierMember.userId === groupCreatorUserId ? 
                                    'Creator' : 
                                        carrierMember.roles.map(role => codeToCopy(role)).join(', ')
                                            
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setIsUpdateContactDetailsPromptOpen(false) }
                }
                open={isUpdateContactDetailsPromptOpen}
            >
                <div style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    <h3>
                        Update your contact info
                    </h3>

                    <UpdateCarrierMemberContactInfoPrompt 
                        carrierGroupId={carrierMember.carrierGroupId}
                        contactEmail={carrierMember.contactEmail}
                        contactPhone={carrierMember.contactPhone}
                        onCarrierMemberUpdated={onContactInfoUpdated}
                    />

                </div>
            </Dialog>
        </div>
    );
};

export default MemberHeader;
