export const APP_SETTING_ID_SEND_EMAILS = 'send-emails';
export const APP_SETTING_ID_NEWS_EMAILS = 'news-emails';
export const APP_SETTING_ID_SHARE_DETAILS = 'share-system-details';
export const APP_SETTING_ID_SHOW_NOTICE_TOASTS = 'showNoticeToasts';
export const APP_SETTING_ID_SHOW_ALL_NOTICE_TOASTS = 'showAllNoticeToasts';


const validValues = [
    APP_SETTING_ID_SEND_EMAILS,
    APP_SETTING_ID_NEWS_EMAILS,
    APP_SETTING_ID_SHARE_DETAILS,
    APP_SETTING_ID_SHOW_NOTICE_TOASTS,
    APP_SETTING_ID_SHOW_ALL_NOTICE_TOASTS
];

const getDropDownValues = () => [
    {
        name: 'Send Notification Emails To My Address',
        value: APP_SETTING_ID_SEND_EMAILS
    },
    {
        name: 'Send Platform News Emails To My Address',
        value: APP_SETTING_ID_NEWS_EMAILS
    },
    {
        name: 'I want to help improve Bears.Exchange by sharing analytics',
        value: APP_SETTING_ID_SHARE_DETAILS
    },
    {
        name: 'Show toast message when you first transition from no unseen notices to having unseen notice(s)',
        value: APP_SETTING_ID_SHOW_NOTICE_TOASTS
    },
    {
        name: 'Show toast messages every time you receive a new notice',
        value: APP_SETTING_ID_SHOW_ALL_NOTICE_TOASTS
    }
];

const isValid = value => !! validValues.includes(value);

export const isEmailSettingId = value => [
    APP_SETTING_ID_SEND_EMAILS,
    APP_SETTING_ID_NEWS_EMAILS
].includes(value);

const AppSettingIdEnum = {
    APP_SETTING_ID_SEND_EMAILS,
    APP_SETTING_ID_NEWS_EMAILS,
    APP_SETTING_ID_SHARE_DETAILS,
    APP_SETTING_ID_SHOW_NOTICE_TOASTS,
    APP_SETTING_ID_SHOW_ALL_NOTICE_TOASTS,
    getDropDownValues,
    isValid,
    isEmailSettingId
};

export default AppSettingIdEnum;
