import React from 'react';
import DetailPanel from './../core/detail';
import UserService from './../../../service/user';
import TwoFAViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _TwoFAPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onGenerateToken = this.onGenerateToken.bind(this);
        this.onToggleEnabled = this.onToggleEnabled.bind(this);
        this.onVerified = this.onVerified.bind(this);
        this.onDeleteToken = this.onDeleteToken.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserService.load2FAToken({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({ });
    }

    onGenerateToken()
    {
        super.loadData({ });
        UserService.create2FAToken({
            request: {
                label: 'User Login Token'
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onDeleteToken()
    {
        super.loadData({ });
        UserService.remove2FAToken({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onToggleEnabled()
    {
        const nextEnabled = !this.state.data.enabled;
        super.loadData({ });
        UserService.modify2FAToken({
            enabled: nextEnabled,
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onVerified(response)
    {
        this.onLoadDataResponse(response);
    }

    getLoadedDataDetailUi()
    {
        return <TwoFAViewPanel 
            data={this.state.data} 
            onGenerateToken={this.onGenerateToken}
            onToggleEnabled={this.onToggleEnabled}
            onVerified={this.onVerified}
            onDeleteToken={this.onDeleteToken}
        />
    }
}

const TwoFAPanel = connect(mapStateToProps, mapDispatchToProps)(_TwoFAPanel);
export default TwoFAPanel;
