import React from 'react';
import theme from './../../../theme';
import { 
    getStepCopy,
    getStepSecondCopy,
} from './../core';

const DefinedStep = ({ 
    step,
    sortedMembers,
    onStepSelected,
    selectedStep,
    getColorKeyByLocation,
    allowDelete,
    onRemoveStep
}) => 
    <div 
        className="test-carrierGroupPlanDeliveryPlanDeliveryDefinedStepStepLine"
        onClick={event => { onStepSelected(step) }}
    >
        <div style={{
            display: 'flex',
            ...(selectedStep && selectedStep.stepId === step.stepId ? {
                backgroundColor: 'red' 
            } : {}),
            cursor: 'pointer',
        }}>

            <h1 style={{
                ...theme.getGeneralTextStyle(theme),
                flexDirection: 'column'
            }}>
                {step.sortOrder}
            </h1>

            <div style={{
                ...theme.getLocationMailingAddressStyle(theme),
                flexDirection: 'column'
            }}>
                { getStepCopy(step, getColorKeyByLocation) }
            </div>

            <div style={{
                ...theme.getLocationMailingAddressStyle(theme),
                flexDirection: 'column'
            }}>
                { getStepSecondCopy(step, sortedMembers) }
            </div>

            <div style={{

            }}>
                {
                    allowDelete && 
                        <button 
                            className="test-carrierGroupPlanDeliveryPlanDeliveryDefinedStepRemoveBtn"
                            onClick={event => { onRemoveStep(step) }}
                        >
                            Remove
                        </button>
                }
            </div>
        </div>

        <hr />

    </div>

const DefinedSteps = ({
    steps,
    canEdit,
    sortedMembers,
    onStepSelected,
    selectedStep,
    getColorKeyByLocation,
    onRemoveStep
}) => {
    const maxIndex = steps.length - 1;
    return (
        <div>
            {
                steps.map((aStep, index) => 
                    <DefinedStep
                        step={aStep}
                        canEdit
                        onStepSelected={onStepSelected}
                        sortedMembers={sortedMembers}
                        selectedStep={selectedStep}
                        key={index}
                        allowDelete={ index === maxIndex && canEdit ? true : false}
                        onRemoveStep={onRemoveStep}
                        getColorKeyByLocation={getColorKeyByLocation}
                    />
                )
            }
        </div>
    );
};

export default DefinedSteps;
