import React from 'react';
import UiService from '././../../../service/ui';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const ClosedExchangeDetailsAdmin = ({ setOpen }) => 
    <div>
        <button 
            className="test-exchangeDetailExchangeDetailsAdminAdminBtn"
            onClick={event => { setOpen(true); }}
        >
            Admin
        </button>
    </div>


export const ExchangeDetailsAdminIpHeader = () => 
    <thead>
        <tr>
            <td>
                IP Address
            </td>
            <td>
                Associated User
            </td>
            <td>
                Event
            </td>
            <td>
                Recorded
            </td>
        </tr>
    </thead>


export const ExchangeDetailsIp = ({ ipRecord, exchange }) => {
    const user = exchange.usersDetails.find(aUser => aUser.userId === ipRecord.payload.userId);
    return (
        <tr>
            <td>
                { ipRecord.payload.ip }
            </td>
            <td>
                {
                    /* istanbul ignore next */
                    ipRecord.payload.userId ? 
                        <NavLink 
                            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                            to={`/user/${ipRecord.payload.userId}`}
                        >
                            { user.username }
                        </NavLink> : 'Guest'
                }
            </td>
            <td>
                { ipRecord.payload.event }
            </td>
            <td>
                { UiService.getStoryDate(ipRecord.createdOn) }
            </td>
        </tr>
    );
};

const ExchangeDetailsIps = ({ exchange }) => 
<div>
    <table>
        <ExchangeDetailsAdminIpHeader />
        <tbody>
        {
            /* istanbul ignore next */
            exchange.adminDetails.ipEvents && 
                exchange.adminDetails.ipEvents.list ? 
                exchange.adminDetails.ipEvents.list.map(
                    (ipRec, index) => <ExchangeDetailsIp ipRecord={ipRec} exchange={exchange} key={index} />
                ) : 'No Records'
        }
        </tbody>
    </table>
</div>


const OpenExchangeDetailsAdmin = ({ setOpen, exchange }) => 
<div>
    <ExchangeDetailsIps exchange={exchange} />
    <div>
        <button 
            className="test-exchangeDetailExchangeDetailsAdminHideAdminBtn"
            onClick={event => { setOpen(false); }}
        >
            Hide Admin
        </button>
    </div>
</div>



export const ExchangeDetailsAdmin = ({ exchange }) => {
    const [ open, setOpen ] = useState(false);
    return open ? 
        <OpenExchangeDetailsAdmin 
            setOpen={setOpen} 
            exchange={exchange} 
        /> : <ClosedExchangeDetailsAdmin setOpen={setOpen} />
};

export default ExchangeDetailsAdmin;
