export const getErrorImageBlockStyle = (theme, ErrorImg) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '350px',
    height: '250px',
    backgroundImage: `url(${ErrorImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
});

export const getErrorButtonsContainerStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    paddingTop: '20px',
});

export const getErrorButtonReportIssueStyle = (theme) => ({
    marginLeft: '10px',
});