export const BX_EVENT_TYPE_PAY_SUCCESS = 'pay-success';
export const BX_EVENT_TYPE_SUB_CANCELLED = 'sub-cancelled';
export const BX_EVENT_TYPE_SUB_FORCE_CANCEL = 'sub-force-cancel';
export const BX_EVENT_TYPE_SUB_PAID = 'sub-paid';
export const BX_EVENT_TYPE_SUB_PAYMENT_FAILED = 'sub-payment-failed';
export const BX_EVENT_TYPE_PAY_ACCOUNT_UPDATED = 'pay-account-updated';
export const BX_EVENT_TYPE_PAYMENT_DISPUTED = 'payment-disputed';
export const BX_EVENT_TYPE_PAYMENT_DISPUTE_UPDATED = 'payment-dispute-updated';
export const BX_EVENT_TYPE_TRANSFER_FAIL = 'transfer-fail';
export const BX_EVENT_TYPE_TRANSFER_SUCCESS = 'transfer-success';

const validValues = [
    BX_EVENT_TYPE_PAY_SUCCESS,
    BX_EVENT_TYPE_SUB_CANCELLED,
    BX_EVENT_TYPE_SUB_FORCE_CANCEL,
    BX_EVENT_TYPE_SUB_PAID,
    BX_EVENT_TYPE_SUB_PAYMENT_FAILED,
    BX_EVENT_TYPE_PAY_ACCOUNT_UPDATED,
    BX_EVENT_TYPE_PAYMENT_DISPUTED,
    BX_EVENT_TYPE_PAYMENT_DISPUTE_UPDATED,
    BX_EVENT_TYPE_TRANSFER_FAIL,
    BX_EVENT_TYPE_TRANSFER_SUCCESS
];

const getDropDownValues = () => [
    {
        name: 'Pay API - Successful Payment',
        value: BX_EVENT_TYPE_PAY_SUCCESS
    },
    {
        name: 'Pay API - Subscription Paid',
        value: BX_EVENT_TYPE_SUB_PAID,
    },
    {
        name: 'Pay API - Subscription Payment Failed',
        value: BX_EVENT_TYPE_SUB_PAYMENT_FAILED,
    },
    {
        name: 'Pay API - Subscription Force Cancelled',
        value: BX_EVENT_TYPE_SUB_FORCE_CANCEL
    },
    {
        name: 'Pay API - Subscription Cancelled',
        value: BX_EVENT_TYPE_SUB_CANCELLED
    },
    {
        name: 'Pay API - Account Updated',
        value: BX_EVENT_TYPE_PAY_ACCOUNT_UPDATED
    },
    {
        name: 'Pay API - Payment Disputed',
        value: BX_EVENT_TYPE_PAYMENT_DISPUTED
    },
    {
        name: 'Pay API - Payment Dispute Updated',
        value: BX_EVENT_TYPE_PAYMENT_DISPUTE_UPDATED
    },
    {
        name: 'Pay API - Transfer Confirmed',
        value: BX_EVENT_TYPE_TRANSFER_SUCCESS
    },
    {
        name: 'Pay API - Transfer Failure',
        value: BX_EVENT_TYPE_TRANSFER_FAIL
    }
];

const isValid = value => !! validValues.includes(value);

const BxEventTypeEnum = {
    BX_EVENT_TYPE_PAY_SUCCESS,
    BX_EVENT_TYPE_SUB_FORCE_CANCEL,
    BX_EVENT_TYPE_SUB_CANCELLED,
    BX_EVENT_TYPE_SUB_PAID,
    BX_EVENT_TYPE_SUB_PAYMENT_FAILED,
    BX_EVENT_TYPE_PAY_ACCOUNT_UPDATED,
    BX_EVENT_TYPE_PAYMENT_DISPUTED,
    BX_EVENT_TYPE_PAYMENT_DISPUTE_UPDATED,
    BX_EVENT_TYPE_TRANSFER_FAIL,
    BX_EVENT_TYPE_TRANSFER_SUCCESS,
    getDropDownValues,
    isValid
};

export default BxEventTypeEnum;
