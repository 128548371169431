import React from 'react';
import DetailPanel from './../core/detail';
import PayService from './../../../service/pay';
import PayoutsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _PayoutsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onRemoveAccount = this.onRemoveAccount.bind(this);
        this.setAsPayoutTarget = this.setAsPayoutTarget.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData({ });
        PayService.listBankAccounts({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData();
    }

    /* istanbul ignore next */
    onRemoveAccount(bankAccountId)
    {
        super.loadData({ });
        PayService.removeBankAccount({
            request: {
                bankAccountId
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    setAsPayoutTarget(bankAccountId)
    {
        /* istanbul ignore next */
        super.loadData({ });
        /* istanbul ignore next */
        PayService.setAsPayoutTarget({
            request: {
                bankAccountId
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadedDataDetailUi()
    {
        return <PayoutsViewPanel 
                    data={this.state.data} 
                    setAsPayoutTarget={this.setAsPayoutTarget} 
                    removeAccount={this.onRemoveAccount} 
                />
    }


}

const PayoutsPanel = connect(mapStateToProps, mapDispatchToProps)(_PayoutsPanel);
export default PayoutsPanel;
