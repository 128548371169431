import React from 'react';
import Menu from './menu';
import FrameContent from './frame-content';

const DynamicFrame = ({
    exitOnboarding,
    updateCrawler,
    selectedFrame
}) => 
    <div>
        <FrameContent
            updateCrawler={updateCrawler}
            selectedFrame={selectedFrame}
            exitOnboarding={exitOnboarding}
        />

        <Menu
            exitOnboarding={exitOnboarding}
            updateCrawler={updateCrawler}
            selectedFrame={selectedFrame}
        />
        
    </div>

export default DynamicFrame;
