import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingPanel from './loading';
import theme from './../../theme';

class DetailPanel extends React.Component {

    constructor(parms)
    {
        super(parms);

        this.componentDidMount = this.componentDidMount.bind(this);
        this.render = this.render.bind(this);
        this.getContactingServerUi = this.getContactingServerUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getDataDetailUi = this.getDataDetailUi.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.onLoadDataError = this.onLoadDataError.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.getUnLoadedDataDetailUi = this.getUnLoadedDataDetailUi.bind(this);
        this.sendUserTo = this.sendUserTo.bind(this);
        this.onContactingServer = this.onContactingServer.bind(this);
        this.onContactingServerResponse = this.onContactingServerResponse.bind(this);

        this.state = this.getDefaultState();
    }

    loadData(inputObj)
    {
        const nextState = {
            ...this.state,
            contactingServer: true,
        };

        this.setState(nextState);

    }

    // istanbul ignore next
    onContactingServer()
    {
        let nextState = this.state;
        nextState.contactingServer = true;
        this.setState(nextState);
    }

    onContactingServerResponse()
    {
        let nextState = this.state;
        nextState.contactingServer = false;
        this.setState(nextState);
    }

    // istanbul ignore next
    getLoadDataRequest(inputObj)
    {
        throw new Error('override DetailPanel.getLoadDataRequest');
    }

    onLoadDataResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: response
        };
        this.setState(nextState);
    }

    onLoadDataError(error)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            data: undefined,
            message: error.response && error.response.error ? error.response.error : error.message,
        };
        this.setState(nextState);
    }

    getDefaultState()
    {
        return {
            contactingServer: false,
            message: '',
            data: undefined,
            redirectTo: undefined,
        };
    }

    sendUserTo(url)
    {
        const nextState = {
            ...this.state,
            redirectTo: url
        };
        this.setState(nextState);
    }
    
    // istanbul ignore next
    componentDidMount()
    {
        this.loadData({ });
    }

    getContactingServerUi()
    {
        return <LoadingPanel />
    }

    // istanbul ignore next
    getLoadedDataDetailUi()
    {
        throw new Error('Must override DetailPanel.getLoadedDataDetailUi');
    }

    getUnLoadedDataDetailUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                { this.state.message }
            </div>
        );
    }

    getDataDetailUi()
    {
        return this.state.data ? this.getLoadedDataDetailUi() : this.getUnLoadedDataDetailUi();
    }

    render()
    {
        return this.state.redirectTo ? <Redirect to={this.state.redirectTo} /> : 
                this.state.contactingServer ? this.getContactingServerUi() : this.getDataDetailUi();
    }
}


export default DetailPanel;
