import React from 'react';
import theme from './../../../theme';
import Contributor from './contributor';
import MathService from './../../../../service/math';
import CurrencyService from './../../../../service/currency';
import CurrencyInputService from './../../../../service/currency-input';
import { CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP } from './../../../prompt/core/enum/carrier-group-delivery-plan-price-contribution-type';

const filterToTrip = c => c.type === CARRIER_GROUP_DELIVERY_PLAN_PRICE_CONTRIBUTION_TYPE_TRIP;

const Contributors = ({
    members,
    price,
    estimatedDollarsPerGallon,
    estimatedMPG,
    onSetPayout,
    pendingPayoutUpdates
}) => {

    /* istanbul ignore next */
    const getSelectedMpg = foundPayout => {
        return foundPayout ? (
            foundPayout.useGlobalMpg || !foundPayout.mpg ? estimatedMPG : foundPayout.mpg 
        ) : estimatedMPG;
    };

    /* istanbul ignore next */
    const getSelectedGasPrice = foundPayout => {
        return foundPayout ? (
            foundPayout.useGasPriceGlobal || !foundPayout.gasPrice ? estimatedDollarsPerGallon : foundPayout.gasPrice
        ) : estimatedDollarsPerGallon;
    };

    /* istanbul ignore next */
    const makeGetEstFuelCostDetails = memberId => trip => {
        const foundPayout = pendingPayoutUpdates.find(po => po.memberId === memberId);
        const selectedMpg = getSelectedMpg(foundPayout);
        const selectedGasPrice = getSelectedGasPrice(foundPayout);
        return MathService.getFuelCost('usd', trip.distanceInMiles, selectedMpg, CurrencyInputService.parse(selectedGasPrice));
    };

    const estFuelCosts = price
        .memberContributionList
        .map(conMem => conMem
            .contributions
            .filter(filterToTrip)
            .map(makeGetEstFuelCostDetails(conMem.memberId))
        ).reduce((prev, curr) => {
            return [
                ...prev,
                ...curr
            ];
        }, []);


    const totalEstFuelCost = estFuelCosts
        .map(est =>  est.cost)
        .reduce((prev, curr) => prev + curr, 0);
    
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h3>
                Cost Estimates
            </h3>

            <p>
                Cost estimates are rounded estimates and may not match actual costs
            </p>

            <p>
                { `Total Estimated Fuel Cost: ${CurrencyService.usd(totalEstFuelCost.toString(), false)}` }
            </p>

            <div>
                {
                    estFuelCosts.map(
                        /* istanbul ignore next */
                        (est, index) => 
                            est.error ? 
                            <div>
                                Error
                            </div> : 
                            <div key={index}>
                                {`${est.distance} miles @ ${est.mpg} MPG ~ ${est.gallons} gallons @ ${CurrencyService.usd(est.dollarsPerGallon)} /gallon = ${CurrencyService.usd(est.cost.toString(), false)}`}
                            </div>
                    )
                }
            </div>
            
            <h3>
                Contributors
            </h3>

            {
                price.memberContributionList.map((memCon, index) => 
                    <Contributor 
                        members={members}
                        pendingPayoutUpdates={pendingPayoutUpdates}
                        record={memCon}
                        onSetPayout={onSetPayout}
                        estimatedDollarsPerGallon={estimatedDollarsPerGallon}
                        estimatedMPG={estimatedMPG}
                        key={index}
                    />
                )
            }

        </div>
    );
};

export default Contributors;