import React from 'react';
import './App.css';
import { Provider, useSelector } from 'react-redux';
import OnBoardingSelector from './store/select/onboarding';
import GenerateStore from './store';
import config from './config';
import { useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Routes from './routes';
import { iniPayClientDetails } from './pay';
import theme from './ui/theme';
import NoticeWrapper from './NoticeWrapper';
import MetricWrapper from './MetricWrapper';
import OnboardingPanel from './ui/panel/onboarding';
import ClientService from './service/client';

ClientService.loadIP();
const store = GenerateStore();
const stripePromise = loadStripe(config.pay.clientKey);

const AppView = ({
  payClientSecret,
  payClientDetails,
  setPayClientSecretValue,
  setPayClientDetails
}) => {
  const stripOptions = {
    clientSecret: payClientSecret,
    business: {
      name: 'Bears Exchange LLC'
    },
    style: {
      base: {
        fontFamily: 'Urbanist',
        fontSize: '10pt',
        color: theme.focusColor,
        backgroundColor: theme.primaryColor
      }
    }
  };

  /* istanbul ignore next */
  const setPayClientSecret = (secret, details) => {
    setPayClientSecretValue(secret);
    setPayClientDetails(details);
  };

  const onboarding = useSelector(OnBoardingSelector);

  // istanbul ignore next 
  return (onboarding && onboarding.showOnboarding) ? 
    <OnboardingPanel
      onboarding={onboarding}
    /> : 
    <div>
      { 
          /* istanbul ignore next */
          payClientSecret && payClientDetails && payClientDetails.type !== '' ? 
            <Elements stripe={stripePromise} options={stripOptions}>
              <Routes 
                setPayClientSecret={setPayClientSecret} 
                payClientDetails={payClientDetails}
                payClientSecret={payClientSecret}
              />
            </Elements> : 
              <Routes 
                setPayClientSecret={setPayClientSecret} 
                payClientDetails={payClientDetails}
                payClientSecret={payClientSecret}
              />   
        }
    </div>
};


function App() {

  const [ payClientDetails, setPayClientDetails ] = useState(iniPayClientDetails);
  const [ payClientSecret, setPayClientSecretValue ] = useState('');
  
  return (
   <Provider store={store}>
     <NoticeWrapper>
       <MetricWrapper>
          
          <AppView 
            payClientSecret={payClientSecret}
            payClientDetails={payClientDetails}
            setPayClientSecretValue={setPayClientSecretValue}
            setPayClientDetails={setPayClientDetails}
          />
          
        </MetricWrapper>
    </NoticeWrapper>
   </Provider>
  );
}

export default App;
