import DetailPanel from './../../core/detail';
import LocationService from './../../../../service/location';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import AdminFailedGeoLocationsViewPanel from './view';

class _AdminFailedGeoLocationsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj)
        LocationService.adminSearchFailedGeoLocations({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        super.loadData({ });
        LocationService.adminSearchFailedGeoLocations({
            request: {
                page
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.loadData({
            page: 0
        });
    }

    getLoadedDataDetailUi()
    {
        return <AdminFailedGeoLocationsViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            onUpdated={this.onLoadDataResponse}
        />
    }
}

const AdminFailedGeoLocationsPanel = connect(mapStateToProps, mapDispatchToProps)(_AdminFailedGeoLocationsPanel);
export default AdminFailedGeoLocationsPanel;
