import { 
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT,
    CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP
} from './../../../prompt/core/enum/carrier-group-delivery-plan-step-type';


export const buildOnStepSelected = (
    mapViewState, 
    setMapViewState,
    setSelectedStep
) => step => {

    let selectedLocation = null;
    switch(step.type)
    {
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_INI_PICKUP:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_PICKUP:
            selectedLocation = step.from;
            break;

        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_FINAL_DROP:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_DROP_OFF:
        case CARRIER_GROUP_DELIVERY_PLAN_STEP_TYPE_SORT: 
            selectedLocation = step.to;
            break;

        default: 
            break;

    }

    if(selectedLocation)
    {
        setMapViewState({
            ...mapViewState,
            longitude: selectedLocation.longitude,
            latitude: selectedLocation.latitude,
        });
    }

    setSelectedStep(step);
};

