import React from 'react';
import Header from '../plan-pricing/contributor/header';
import Contributions from '../plan-pricing/contributor/contributions';

const PlanPricingConfirmation = ({
    plan,
    memberContribution,
    members
}) => 
    <div>
        
        {
            !memberContribution.confirmed && 
                <h2>
                    Awaiting Confirmation
                </h2>
        }
        
        <Header 
            members={members}
            record={memberContribution}
        />

        <Contributions
            contributionsList={memberContribution.contributions}
        />

        <div>
            {
                memberContribution.confirmed ? 
                    <span>
                        Confirmed
                    </span> : 
                    <div>
                        {
                            plan.lockedForEditByUserId === memberContribution.userId && 
                                <div>
                                    <button>
                                        Confirm Job
                                    </button>
                                    <button>
                                        Decline Job
                                    </button>
                                </div>
                        }
                    </div>
            }
        </div>
        <hr />
    </div>

export default PlanPricingConfirmation;
