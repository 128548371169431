import React from 'react';
import DetailPanel from './../core/detail';
import RatingService from './../../../service/rating';
import UserReviewHistoryViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _UserReviewHistoryPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        RatingService.getUserRatings({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getLoadDataRequest(inputObj)
    {
        return {
            ratedUserId: inputObj.userId,
            page: inputObj.page !== undefined ? inputObj.page : 0
        };
    }

    onLoadSpecifiedPage(page)
    {
        const userId = this.props.userId;
        this.loadData({ 
            userId, 
            page
        });
    }

    componentDidMount()
    {
        const userId = this.props.userId;
        this.loadData({ 
            userId, 
            page: 0
        });
    }

    getLoadedDataDetailUi()
    {
        return (
            <section>
                <UserReviewHistoryViewPanel data={this.state.data} onLoadSpecifiedPage={ this.onLoadSpecifiedPage } />
            </section>
        );
    }
}

const UserReviewHistoryPanel = connect(mapStateToProps, mapDispatchToProps)(_UserReviewHistoryPanel);
export default UserReviewHistoryPanel;
