export const STORE_TRAIT_TYPE_RETURN_POLICY = 'returnPolicy';
export const STORE_TRAIT_TYPE_CONTACT_DETAILS = 'contactDetails';

const validValues = [
    STORE_TRAIT_TYPE_CONTACT_DETAILS,
    STORE_TRAIT_TYPE_RETURN_POLICY
];

const getDropDownValues = () => [
    {
        name: 'Contact Details',
        value: STORE_TRAIT_TYPE_CONTACT_DETAILS
    },
    {
        name: 'Return Policy',
        value: STORE_TRAIT_TYPE_RETURN_POLICY
    }
];

const isValid = value => validValues.includes(value);

const StoreTraitTypeEnum = {
    STORE_TRAIT_TYPE_CONTACT_DETAILS,
    STORE_TRAIT_TYPE_RETURN_POLICY,
    isValid,
    getDropDownValues
};

export default StoreTraitTypeEnum;
