export const getUserReviewSummaryStyle = (theme) => ({
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    width: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    margin: '0 auto .5rem',
    padding: '.75rem',
    display: 'flex',
    gap: '.75rem'
});

export const getUserReviewPositiveStyle = (theme) => ({
    color: theme.positiveRatingColor,
    fontSize: '16pt',
});

export const getUserReviewNegativeStyle = (theme) => ({
    color: theme.warningColor,
    fontSize: '16pt',
});

export const getUserReviewHistoryOuterContainer = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
});
