class WarnUnsavedService {
    
    static getOnUnload(isDataUnsaved, warningMessage)
    {
        return (e) => {
            if(!isDataUnsaved())
            {
                return undefined;
            }
            /* istanbul ignore next */
            (e || window.event).returnValue = warningMessage;
            return warningMessage;
        };
    }

    static buildOnUnload(isDataUnsaved, warningMessage)
    {
        const onUnload = this.getOnUnload(isDataUnsaved, warningMessage);
        return () => {
            this.startListening(onUnload);
            return () => {
               this.stopListening(onUnload);
            };
        };
    }

    static startListening(onUnload)
    {
        window.addEventListener('beforeunload', onUnload);
    }

    static stopListening(onUnload)
    {
        window.removeEventListener('beforeunload', onUnload);
    }
}

export default WarnUnsavedService;
