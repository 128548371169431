import React from 'react';
import theme from './../../../theme';
import TripHook from './trip-hook';
import { 
    isStepRelatedToAlignTrips,
    getTripFromCarryingCargoByStep
} from './../core';
import {
    CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING
} from './../../../prompt/core/enum/carrier-group-delivery-plan-status';


const AlignTrips = ({
    plan,
    delivery,
    carrierGroupId,
    cargoItem,
    onAddTrip,
    acceptingCargo,
    carryingCargo,
    onHookTrip,
    onCompleteSchedulingStatus,
    removeCargoFromCarrierGroupTrip
}) => {

    const planMappedSteps = plan.planSteps.filter(isStepRelatedToAlignTrips).map(step => {
        const foundTrip = getTripFromCarryingCargoByStep(carryingCargo, step);
        const isHooked = foundTrip ? true : false;
        return {
            ...step,
            from: step.from,
            to: step.to,
            isHooked,
            foundTrip
        }
    });
    const hookedList = planMappedSteps.filter(step => step.isHooked);
    const unHookedList = planMappedSteps.filter(step => !step.isHooked);
    const hookPromptStepId = unHookedList.length > 0 ? unHookedList[0].stepId : undefined;
    const lastHookedStepId = hookedList.length > 0 ? hookedList[hookedList.length - 1].stepId : undefined;
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <p>
                Align member trips to move along your delivery plan
            </p>
            <div>
                {
                    planMappedSteps.map((aStep, index) => {
                        const shouldPromptRemoveHook = plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING && aStep.stepId === lastHookedStepId ? true : false;
    
                        return <TripHook
                            step={aStep}
                            onHookTrip={onHookTrip}
                            onAddTrip={onAddTrip}
                            delivery={delivery}
                            carryingCargo={carryingCargo}
                            shouldPromptUnHooked={aStep.stepId === hookPromptStepId ? true : false}
                            shouldPromptRemoveHook={shouldPromptRemoveHook}
                            key={index}
                            foundTrip={aStep.foundTrip}
                            carrierGroupId={carrierGroupId}
                            cargoItem={cargoItem}
                            acceptingCargo={acceptingCargo}
                            removeCargoFromCarrierGroupTrip={removeCargoFromCarrierGroupTrip}
                        />
                    })
                }

                {
                    /* istanbul ignore next */
                    plan.status === CARRIER_GROUP_DELIVERY_PLAN_STATUS_SCHEDULING && unHookedList.length < 1 && 
                        <div>
                            <button onClick={
                                /* istanbul ignore next */
                                event => { onCompleteSchedulingStatus() }
                            }>
                                Plan Delivery Cost
                            </button>
                        </div>
                }
                
            </div>
        </div>
    );
};

export default AlignTrips;
