import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

export const EMAIL_CHANGE_EMAIL = 'changeEmail';
export const EMAIL_RESET_PWD = 'resetPwd';
export const EMAIL_NOTIFY_USER = 'notifyUser';
export const EMAIL_CREATE_USER = 'createUser';
export const EMAIL_NEWS = 'news';

class ApiService {

    static getTotalRequestsByServiceRequest()
    {
        return {

        };
    }

    static getTotalRequestsByService({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.requestVolume,
            request: ApiService.getTotalRequestsByServiceRequest(),
            onResponse, 
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getListLogRequest(service, page)
    {
        return {
            service,
            page
        };
    }

    /* istanbul ignore next */
    static listLog({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.listApiLogs,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getDefaultTestEmailTemplateNameValue()
    {
        return EMAIL_NEWS;
    }

    static getTestEmailTemplateDropDownValues()
    {
        return [
            {
                name: 'News',
                value: EMAIL_NEWS
            },
            {
                name: 'Change Email',
                value: EMAIL_CHANGE_EMAIL
            },
            {
                name: 'Reset Password',
                value: EMAIL_RESET_PWD
            },
            {
                name: 'Notify User',
                value: EMAIL_NOTIFY_USER
            },
            {
                name: 'Create User',
                value: EMAIL_CREATE_USER
            },
        ];
    }

    static getSendEmailRequest(emailTemplate, toAddress)
    {
        return {
            emailTemplate, 
            toAddress
        };
    }

    /* istanbul ignore next */
    static sendEmail({
        request,
        onResponse,
        onError,
        authToken,
        onAuthError,
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.admin.sendEmail,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError,
                onAuthError,
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default ApiService;
