import React from 'react';
import theme from './../../theme';

const RemotePanel = ({ obtainTypeDetail }) => obtainTypeDetail.locationLimit && (obtainTypeDetail.locationLimit.area || obtainTypeDetail.locationLimit.distance ) ? 
    <div style={{
        ...theme.getGeneralTextStyle(theme)  
    }}>
        <h3>
            Remote Fulfillment
        </h3>

        {
            obtainTypeDetail.locationLimit.area && 
            <div>
                <span>
                    Virtual Meeting Service:
                </span>
                &nbsp;
                <span>
                    { obtainTypeDetail.locationLimit.area }
                </span>
            </div>
        }

        {
            obtainTypeDetail.locationLimit.distance && 
            <div>
                <span>
                    Availability:
                </span>
                &nbsp;
                <span>
                    { obtainTypeDetail.locationLimit.distance }
                </span>
            </div>
        }

    </div> : '';

export default RemotePanel;
