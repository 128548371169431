import DetailPanel from './../core/detail';
import DeliveryService from './../../../service/delivery';
import AvailableForDeliveryCarrierGroupsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _AvailableForDeliveryCarrierGroupsPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.requestQuote = this.requestQuote.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        DeliveryService.listAvailableCarriers({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        super.loadData({ });
        DeliveryService.listAvailableCarriers({
            request: this.getLoadDataRequest({ page }),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            localCarrierGroupIdList: []
        };
    }

    requestQuote(carrierGroupId)
    {
        super.loadData({ });
        DeliveryService.addToCarrierQueue({
            request: {
                deliveryId: this.props.delivery.id,
                bidRequestedCarrierGroupIdList: [
                    carrierGroupId
                ]
            }, 
            onResponse: (response) => {
                this.onContactingServerResponse()
                let nextState = this.state;
                nextState.contactingServer = false;
                nextState.localCarrierGroupIdList.push(carrierGroupId);
                this.setState(nextState);
            },
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            deliveryId: this.props.delivery.id,
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.loadData({
            page: 0
        });
    }

    getLoadedDataDetailUi()
    {
        return <AvailableForDeliveryCarrierGroupsViewPanel 
            data={this.state.data} 
            onLoadSpecifiedPage={this.onLoadSpecifiedPage}
            delivery={this.props.delivery}
            requestQuote={this.requestQuote}
            localCarrierGroupIdList={this.state.localCarrierGroupIdList}
        />
    }
}

const AvailableForDeliveryCarrierGroupsPanel = connect(mapStateToProps, mapDispatchToProps)(_AvailableForDeliveryCarrierGroupsPanel);
export default AvailableForDeliveryCarrierGroupsPanel;
