export const sortMembers = members => members.list.sort((a, b) => {
    if(a.name < b.name)
    {
        return -1;
    }

    if(a.name > b.name)
    {
        return 1;
    }

    return 0;
});
