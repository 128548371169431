import React from 'react';
import { useState } from 'react';
import theme from './../../theme';
import UiService from './../../../service/ui';
import LocationService from './../../../service/location';
import DeliveryService from '../../../service/delivery';
import DeliveryCarrierTypeEnum from './../../prompt/core/enum/delivery-carrier-type';

const TrackingIdPanel = ({ delivery }) => {
    /* istanbul ignore next */
    const isTracking = delivery.carrierTrackingId ? DeliveryService.isTrackingCarrier(delivery.carrierType) : false;
    const url = isTracking ? DeliveryService.getShippingCompanyTrackingUrl(delivery.carrierType, delivery.carrierTrackingId) : '';
    const trackingCopy = `Tracking ID: ${ delivery.carrierTrackingId }`;
    return (
        <div>
            { 
                isTracking ? 
                    <a 
                        href={url} 
                        target="_blank" 
                        rel="noreferrer"
                        style={{
                            ...theme.getGeneralTextLinkStyle(theme)
                        }}
                    >
                        { trackingCopy }
                    </a> :
                        <span>
                            { trackingCopy }
                        </span>
            }
        </div>
    );
};    

const DeliveryStatus = ({ delivery, postAuthor }) => 
    <div>
        <div>
            { `Status: ${delivery.cancelled ? 'Cancelled' : (
                    delivery.delivered ? 'Delivered' : (
                        delivery.acceptedByCarrier ? 'In Transit' : 
                            'Pending'
                    )
                )}` }
        </div>

        {
            delivery.acceptedByCarrier && delivery.carrierType !== DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_SELF && delivery.carrierTrackingId && 
                <TrackingIdPanel delivery={delivery} />
        }

    </div>

export const getOutOfNetworkCarrierName = (carrierType, otherCarrierName = 'Unknown') => {
    switch(carrierType)
    {
        case DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_USPS:
            return 'US Postal';

        case DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_FEDEX:
            return 'FedEx';

        case DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_UPS:
            return 'UPS';

        case DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_DHL:
            return 'DHL';

        default:
            return otherCarrierName;
    }
};

const getCarrier = (delivery, postAuthor) => {
    if(delivery.carrierType === DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_SELF)
    {
        return postAuthor.username;
    }

    if(delivery.carrierType === DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_UNASSIGNED)
    {
        return 'Unassigned';
    }

    return getOutOfNetworkCarrierName(delivery.carrierType, delivery.otherCarrierName);

};

const CarrierSection = ({ delivery, postAuthor }) => 
    <div>
        <span>
            { `Carrier: ${ getCarrier(delivery, postAuthor) }` }
        </span>
        
    </div>

const FromToSection = ({
    delivery
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)   
    }}>
        {   
            delivery.pickupLocation && 
                <div style={{
                    ...theme.getLocationMailingAddressStyle(theme)  
                }}>
                    <strong>
                        Origin
                    </strong>
                    <p>
                        {
                            LocationService.displayMailingAddress(delivery.pickupLocation)
                        }
                    </p>
                </div>
        }

        <div style={{
          ...theme.getLocationMailingAddressStyle(theme)  
        }}>
            <strong>
                Destination
            </strong>
            <p>
                {
                    LocationService.displayMailingAddress(delivery.deliveryLocation)
                }
            </p>
        </div>
    </div>


const DeliveryRoutingEstimates = ({ delivery }) => delivery.routingEstimates && 
    !delivery.cancelled && 
    !delivery.delivered && 
    [
        DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_SELF,
        DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_UNASSIGNED

    ].includes(delivery.carrierType) ? 
        <div>
            <div>
                { `Estimated Distance: ${delivery.routingEstimates.distance.amount} ${delivery.routingEstimates.distance.unit}` }
            </div>
            <div>
                { `Estimated Travel Time: ${ UiService.secToHumanTime(parseInt(delivery.routingEstimates.travelTime.time), '*approximately', 'quick') }` }
            </div>
            <div>
                { `Weight: ${ UiService.getWeight(delivery.weight) }` }
            </div>
        </div> : <span></span>


const DeliveryDetailsPanel = ({ 
    delivery,
    postAuthor,
    bidAuthor
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)   
    }}>

        <CarrierSection delivery={delivery} postAuthor={postAuthor} />
        
        <DeliveryStatus delivery={delivery} postAuthor={postAuthor} />

        <DeliveryRoutingEstimates delivery={delivery} />

        <FromToSection delivery={delivery} />
        
    </div>

const DeliveryPanel = ({ 
    selectedDelivery, 
    hasMany,
    clearSelection,
    postAuthor,
    bidAuthor
}) => 
    <div>
        {
            hasMany && 
                <div>
                    <button 
                        className="test-exchangeDetailDeliveriesPanelBackBtn"
                        onClick={event => { clearSelection() }}
                    >
                        Back
                    </button>
                </div>
        }
        <DeliveryDetailsPanel 
            delivery={selectedDelivery} 
            postAuthor={postAuthor}
            bidAuthor={bidAuthor}
        />
    </div>

const getDeliverySummaryText = (delivery) => delivery.carrierType === DeliveryCarrierTypeEnum.DELIVERY_CARRIER_TYPE_SELF ? 
    'Hand Delivery' : 'Pending';

const DeliveryOptionPanel = ({ 
    delivery,
    index,
    setSelectedDeliveryIndex
}) => 
    <div 
        className="test-exchangeDetailDeliveriesPanelDeliveryLine"
        onClick={event => { setSelectedDeliveryIndex(index) }}
    >
        {
            getDeliverySummaryText(delivery)
        }
    </div>

const SelectDeliveryPanel = ({ 
    deliveryDetails,
    setSelectedDeliveryIndex,
   
}) => 
    <div>
        {
            deliveryDetails.list.map((delivery, index) => 
                <DeliveryOptionPanel 
                    key={index} 
                    index={index}
                    setSelectedDeliveryIndex={setSelectedDeliveryIndex}
                    delivery={delivery} 
                />
            )
        }
    </div>


const DeliveriesPanel = ({ 
    deliveryDetails,
    postAuthor,
    bidAuthor
}) => {
    
    const hasMany = deliveryDetails.list.length > 1 ? true : false;
    const startSelectedDeliveryIndex = hasMany ? null : 0;
    
    const [ selectedDeliveryIndex, setSelectedDeliveryIndex ] = useState(startSelectedDeliveryIndex);
    const clearSelection = () => { setSelectedDeliveryIndex(null) };
    
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <h3>
                Delivery Details
            </h3>
            {
                hasMany && selectedDeliveryIndex === null ? 
                <SelectDeliveryPanel 
                    deliveryDetails={deliveryDetails} 
                    setSelectedDeliveryIndex={setSelectedDeliveryIndex}
                /> : 
                <DeliveryPanel 
                    selectedDelivery={
                        /* istanbul ignore next */
                        selectedDeliveryIndex !== null ? deliveryDetails.list[selectedDeliveryIndex] : null
                    }
                    hasMany={hasMany}
                    clearSelection={clearSelection}
                    postAuthor={postAuthor}
                    bidAuthor={bidAuthor}
                />
            }
        </div>
    );
};

export default DeliveriesPanel;
