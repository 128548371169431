import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminAddAppNewsPrompt from './../ui/prompt/admin/add-app-news';
import theme from './../ui/theme';

const AdminAddAppNewsPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            Publish a News Story
        </h2>
        <AdminAddAppNewsPrompt />
    </AdminPageTemplate>

export default AdminAddAppNewsPage;
