import DetailPanel from './../core/detail';
import CarrierGroupDeliveryPlanService from './../../../service/carrier-group-delivery-plan';
import CarrierGroupTripService from './../../../service/carrier-group-trip';
import CarrierGroupPlanDeliveryViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import EventBusService, { EVENT_CARRIER_GROUP_DELIVERY_PLAN_STEP_ADDED } from './../../../service/event-bus';
import { generateIniMapColorKeys } from './core';
import { EXCHANGE_TYPE_ONLINE } from './../../prompt/core/enum/exchange-type';
import config from './../../../config';
import toast from 'react-hot-toast';

class _CarrierGroupPlanDeliveryPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.unlockPlan = this.unlockPlan.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.getUnlockRequest = this.getUnlockRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getAddStepRequest = this.getAddStepRequest.bind(this);
        this.onAddStep = this.onAddStep.bind(this);
        this.onAddStepResponse = this.onAddStepResponse.bind(this);
        this.setMapColorKeys = this.setMapColorKeys.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.onRemoveStep = this.onRemoveStep.bind(this);
        this.getRemoveStepRequest = this.getRemoveStepRequest.bind(this);
        this.onRemoveStepResponse = this.onRemoveStepResponse.bind(this);
        this.onHookTrip = this.onHookTrip.bind(this);
        this.onAddStopResponse = this.onAddStopResponse.bind(this);
        this.onCompleteDraftStatus = this.onCompleteDraftStatus.bind(this);
        this.onCompleteDraftStatusResponse = this.onCompleteDraftStatusResponse.bind(this);
        this.onCompleteSchedulingStatus = this.onCompleteSchedulingStatus.bind(this);
        this.onCompleteSchedulingStatusResponse = this.onCompleteSchedulingStatusResponse.bind(this);
        this.onCompletePricingStatus = this.onCompletePricingStatus.bind(this);
        this.onCompletePricingStatusResponse = this.onCompletePricingStatusResponse.bind(this);
        this.onPriceUpdated = this.onPriceUpdated.bind(this);
        this.onPriceControlsUpdated = this.onPriceControlsUpdated.bind(this);
        this.onSetPayout = this.onSetPayout.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onAddExchange = this.onAddExchange.bind(this);
        this.onRemoveExchange = this.onRemoveExchange.bind(this);
        this.onAddTrip = this.onAddTrip.bind(this);
        this.removeCargoFromCarrierGroupTrip = this.removeCargoFromCarrierGroupTrip.bind(this);
        this.forceUnlockPlan = this.forceUnlockPlan.bind(this);
        this.getForceUnlockPlanRequest = this.getForceUnlockPlanRequest.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    /* istanbul ignore next */
    onPriceControlsUpdated(controlRequest)
    {
        let nextState = this.state;
        nextState.estimatedMPG = controlRequest.estimatedMPG;
        nextState.estimatedDollarsPerGallon = controlRequest.estimatedDollarsPerGallon;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onSetPayout({
        record, 
        useGlobalMpg = null, 
        mpg = null, 
        payout = null,
        useGasPriceGlobal = null,
        gasPrice = null
    })
    {
        let nextState = this.state;
        const findIndex = nextState.pendingPayoutUpdates.findIndex(i => i.memberId === record.memberId);
        if(findIndex > -1)
        {
            if(payout !== null)
            {
                nextState.pendingPayoutUpdates[findIndex].payout = payout;
            }

            if(mpg !== null)
            {
                
                nextState.pendingPayoutUpdates[findIndex].mpg = mpg;
            }

            if(useGlobalMpg !== null)
            {
                nextState.pendingPayoutUpdates[findIndex].useGlobalMpg = useGlobalMpg;
            }

            if(useGasPriceGlobal !== null)
            {
                nextState.pendingPayoutUpdates[findIndex].useGasPriceGlobal = useGasPriceGlobal;
            }

            if(gasPrice !== null)
            {
                nextState.pendingPayoutUpdates[findIndex].gasPrice = gasPrice;
            }
        }
        else 
        {
            nextState.pendingPayoutUpdates.push({
                memberId: record.memberId,
                userId: record.userId,
                payout,
                useGlobalMpg: mpg === null ? true : useGlobalMpg,
                mpg,
                useGasPriceGlobal: gasPrice === null ? true : useGasPriceGlobal,
                gasPrice
            });
        }
        this.setState(nextState);
    }

    getDefaultState()
    {
        let baseState = super.getDefaultState();
        return {
            ...baseState,
            estimatedMPG: '20',
            estimatedDollarsPerGallon: '3.88',
            pendingPayoutUpdates: [],
            exchangeList: []
        };
        
    }

    /* istanbul ignore next */
    onAddExchange(nextExchange)
    {
        let nextState = this.state;
        if(nextState.exchangeList.length < config.countLimits.exchangeOptions)
        {
            if(nextExchange.type === EXCHANGE_TYPE_ONLINE && nextState.exchangeList.filter(ex => ex.type === EXCHANGE_TYPE_ONLINE).length > 0)
            {
                toast('You are limited to only ONE online payment option');
            }
            else
            {
                nextState.exchangeList.push(nextExchange);
                this.setState(nextState);
                toast('Exchange Option Added.');
            }
        }
        else
        {
            toast('Exchange limit reached.  Remove an exchange before adding another');
        }
    }

    /* istanbul ignore next */
    onRemoveExchange(delIndex)
    {
        let nextState = this.state;
        nextState.exchangeList = this.state.exchangeList.filter((aDetail, index) => index !== delIndex);
        this.setState(nextState);
    }

    onLoadDataResponse(response)
    {
        let nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: response
        };
        /* istanbul ignore next */
        if(!response.isLockedOut)
        {
            if(nextState.data && nextState.data.plan && nextState.data.plan.planSteps)
            {
                nextState.data.plan.mapColorKeys = generateIniMapColorKeys(
                    nextState.data.plan.planSteps.list,
                    nextState.data.plan.mapColorKeys
                );
            }
            nextState.data.mapColorKeyServerString = JSON.stringify(nextState.data.plan.mapColorKeys);
        }
        
        this.setState(nextState);
    }

    loadData()
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.startEditing({
            request: this.getLoadDataRequest(),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onAddTrip()
    {
        this.loadData();
    }

    /* istanbul ignore next */
    onAddStep(nextStep)
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.addStep({
            request: this.getAddStepRequest(nextStep),
            onResponse: this.onAddStepResponse,
            onLoadDataError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    removeCargoFromCarrierGroupTrip(trip)
    {
        super.loadData({ });
        CarrierGroupTripService.removeCargo({
            request: {
                carrierGroupId: this.props.carrierGroupId,
                deliveryId: this.state.data.delivery.id,
                tripId: trip.id
            },
            onResponse: this.onAddStopResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onHookTrip(hookDetails)
    {
        const isUpdate = (aStop, index) => hookDetails.addIndex === null || index > hookDetails.addIndex ? true : false;
        const originalList = this.state.data && this.state.data.carryingCargo && this.state.data.carryingCargo.list ? this.state.data.carryingCargo.list : [];
        let sortUpdateStopIdList = originalList.filter(isUpdate).map(stop => stop.stopId);
        const addStop = {
            ...hookDetails,
            deliveryId: undefined,
            addIndex: undefined,
            location: hookDetails.location,
            sortOrder: originalList[hookDetails.addIndex] ? (1 + originalList[hookDetails.addIndex].sortOrder) : 1
        };
        const request = {
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: hookDetails.deliveryId,
            sortUpdateStopIdList,
            addStop
        };

        super.loadData({ });
        CarrierGroupTripService.addStop({
            request,
            onResponse: this.onAddStopResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onAddStopResponse(response)
    {
        let nextState = this.state;
        nextState.contactingServer = false;
        if(nextState.data && nextState.data.acceptingCargo)
        {
            nextState.data.acceptingCargo = response.acceptingCargo;
            nextState.data.carryingCargo = response.carryingCargo;
            nextState.data.plan.planSteps = response.planSteps;
        }
        this.setState(nextState);
    }

    /* istanbul ignore next */
    getRemoveStepRequest(delStep)
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: this.props.deliveryId,
            stepId: delStep.stepId
        };
    }

    /* istanbul ignore next */
    onRemoveStepResponse(response)
    {
        let nextState = this.state;
        nextState.contactingServer = false;
        if(nextState.data && nextState.data.plan && nextState.data.plan.planSteps)
        {
            nextState.data.plan.planSteps = response.planSteps;
        }
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onRemoveStep(delStep)
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.removeStep({
            request: this.getRemoveStepRequest(delStep),
            onResponse: this.onRemoveStepResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onAddStepResponse(response)
    {
        let nextState = this.state;
        nextState.contactingServer = false;
        if(nextState.data && nextState.data.plan && nextState.data.plan.planSteps)
        {
            nextState.data.plan.planSteps = response.planSteps;
        }
        this.setState(nextState);

        if(response.planSteps && response.planSteps.list && response.planSteps.list.length > 0)
        {
            const lastStep = response.planSteps.list[response.planSteps.list.length - 1];
            EventBusService.dispatch(EVENT_CARRIER_GROUP_DELIVERY_PLAN_STEP_ADDED, { lastStep });
        }
    }

    /* istanbul ignore next */
    setMapColorKeys(nextMap)
    {
        let nextState = this.state;
        if(nextState.data)
        {
            nextState.data.plan.mapColorKeys = nextMap;
            this.setState(nextState);
        }
    }

    /* istanbul ignore next */
    getAddStepRequest(nextStep)
    {
        let request = {
            ...nextStep,
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: this.props.deliveryId,
            from: nextStep.from,
            to: nextStep.to,
            updateMapColorKeys: null
        };

        const updateMapColorKeys = generateIniMapColorKeys(
            [
                ...this.state.data.plan.planSteps,
                nextStep
            ],
            this.state.data.plan.mapColorKeys
        );
        
        if(JSON.stringify(updateMapColorKeys) !== this.state.data.mapColorKeyServerString)
        {
            request.updateMapColorKeys = updateMapColorKeys;
        }

        return request;
    }

    /* istanbul ignore next */
    componentWillUnmount()
    {
        const carrierGroupDeliveryPlanId = this.state.data && this.state.data.plan && this.state.data.plan.carrierGroupDeliveryPlanId ? this.state.data.plan.carrierGroupDeliveryPlanId : null;
        if(carrierGroupDeliveryPlanId)
        {
            this.unlockPlan(carrierGroupDeliveryPlanId);
        }
    }

    /* istanbul ignore next */
    forceUnlockPlan()
    {
        CarrierGroupDeliveryPlanService.forceUnlock({
            request: this.getForceUnlockPlanRequest(),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getForceUnlockPlanRequest()
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: this.props.deliveryId
        };
    }

    /* istanbul ignore next */
    unlockPlan(carrierGroupDeliveryPlanId)
    {
        CarrierGroupDeliveryPlanService.stopEditing({
            request: this.getUnlockRequest(carrierGroupDeliveryPlanId),
            onResponse: (response) => {
                const nextState = {
                    ...this.state,
                    contactingServer: false,
                    message: '',
                    data: undefined
                };
                this.setState(nextState);
            },
            onError: (error) => {},
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest()
    {
        return {
            carrierGroupId: this.props.carrierGroupId,
            deliveryId: this.props.deliveryId
        };
    }

    /* istanbul ignore next */
    getUnlockRequest(carrierGroupDeliveryPlanId)
    {
        return {
            carrierGroupDeliveryPlanId
        };
    }

    componentDidMount()
    {
        this.loadData();
    }

    /* istanbul ignore next */
    onCompletePricingStatus()
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.completePricingStatus({
            request: {
                carrierGroupId: this.props.carrierGroupId,
                deliveryId: this.props.deliveryId,
                exchangeList: this.state.exchangeList,
                pendingPayoutUpdates: this.state.pendingPayoutUpdates.map(pend => ({
                    payout: pend.payout,
                    carrierMemberUserId: pend.userId,
                }))
            },
            onResponse: this.onCompletePricingStatusResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onCompletePricingStatusResponse(response)
    {
        let nextState = this.state;
        nextState.contactingServer = false;
        nextState.data.plan = response.plan;
        nextState.data.price = response.price
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCompleteDraftStatus()
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.completeDraftStatus({
            request: {
                carrierGroupId: this.props.carrierGroupId,
                deliveryId: this.props.deliveryId
            },
            onResponse: this.onCompleteDraftStatusResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onCompleteDraftStatusResponse(response)
    {
        let nextState = this.state;
        nextState.contactingServer = false;
        nextState.data.plan.status = response.status;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCompleteSchedulingStatus()
    {
        super.loadData({ });
        CarrierGroupDeliveryPlanService.completeSchedulingStatus({
            request: {
                carrierGroupId: this.props.carrierGroupId,
                deliveryId: this.props.deliveryId
            },
            onResponse: this.onCompleteDraftStatusResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onCompleteSchedulingStatusResponse(response)
    {
        let nextState = this.state;
        nextState.contactingServer = false;
        nextState.data.plan.status = response.status;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onPriceUpdated(nextPrice)
    {
        let nextState = this.state;
        nextState.data.price = nextPrice;
        this.setState(nextState);
    }

    getLoadedDataDetailUi()
    {
        return <CarrierGroupPlanDeliveryViewPanel 
            data={this.state.data} 
            setMapColorKeys={this.setMapColorKeys}
            onAddStep={this.onAddStep}
            onSetPayout={this.onSetPayout}
            onPriceControlsUpdated={this.onPriceControlsUpdated}
            onRemoveStep={this.onRemoveStep}
            onHookTrip={this.onHookTrip}
            onCompleteDraftStatus={this.onCompleteDraftStatus}
            onCompleteSchedulingStatus={this.onCompleteSchedulingStatus}
            onCompletePricingStatus={this.onCompletePricingStatus}
            onPriceUpdated={this.onPriceUpdated}
            estimatedMPG={this.state.estimatedMPG}
            onAddExchange={this.onAddExchange}
            onAddTrip={this.onAddTrip}
            onRemoveExchange={this.onRemoveExchange}
            pendingPayoutUpdates={this.state.pendingPayoutUpdates}
            estimatedDollarsPerGallon={this.state.estimatedDollarsPerGallon}
            exchangeList={this.state.exchangeList}
            removeCargoFromCarrierGroupTrip={this.removeCargoFromCarrierGroupTrip}
            forceUnlockPlan={this.forceUnlockPlan}
        />
    }
}

const CarrierGroupPlanDeliveryPanel = connect(mapStateToProps, mapDispatchToProps)(_CarrierGroupPlanDeliveryPanel);
export default CarrierGroupPlanDeliveryPanel;
