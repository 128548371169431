import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../../../service/resize';
import UiService from "../../../../../service/ui";
import { NavLink } from 'react-router-dom';
import theme from './../../../../theme';

const Seek = ({ seek }) => {
    const getWidgetMyActiveSeeksStyle = () => theme.getWidgetMyActiveSeeksStyle(theme);
    const [ widgetMyActiveSeeksStyle, setWidgetMyActiveSeeksStyle ] = useState( getWidgetMyActiveSeeksStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setWidgetMyActiveSeeksStyle( getWidgetMyActiveSeeksStyle() );
    });
    useEffect(resizeEffect);
    return (
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/seek/${seek.id}`}
        >
            <div style={widgetMyActiveSeeksStyle}>
                <div style={{
                    ...theme.getWidgetMyActiveSeeksTitleStyle(theme),
                }}>
                    { seek.title }
                </div>
                <p>
                    { UiService.timeSince(seek.createdOn) }
                </p>
            </div>
        </NavLink>
    );
};

const PopulatedView = ({ list }) => {
    const getUserWidgetFlexParentStyle = () => theme.getUserWidgetFlexParentStyle(theme);
    const [ userWidgetFlexParentStyle, setUserWidgetFlexParentStyle] = useState(getUserWidgetFlexParentStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetFlexParentStyle(getUserWidgetFlexParentStyle());
    });
    useEffect(resizeEffect);
    return (
        <section style={userWidgetFlexParentStyle}>
            {
                list.map((seek, index) => <Seek key={index} seek={seek} />)
            }
        </section>
    );
};
const EmptyView = ({ userWidgetHeaderTextStyle }) => {
    const getContainerStyle = () => theme.getUserWidgetSubHeaderTextStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <p style={containerStyle}>
                    You have no active seeks.
                <br />
                    <NavLink 
                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                        to="/seek/add"
                    >
                        Publish a Seek.
                    </NavLink>
                </p>
            </div>
            
        </section>
    );
}

const WidgetMyActiveSeeksViewPanel = ({ data }) => {
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle ] = useState(getUserWidgetHeaderTextStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetHeaderTextStyle( getUserWidgetHeaderTextStyle());
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    My Active Seeks
                </div>
            </div>
            {
                data.list.length < 1 ? 
                    <EmptyView userWidgetHeaderTextStyle={userWidgetHeaderTextStyle} /> : 
                        <PopulatedView list={data.list} />
            }
        </section>
    );
};


export default WidgetMyActiveSeeksViewPanel;
