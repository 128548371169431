import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import Dialog from '@mui/material/Dialog';
import AddLocationPrompt from './../../prompt/add-location';
import UserRepairGeoLocationPrompt from './../../prompt/user-repair-geo-location';
import UserCorrectGeoLocationPrompt from './../../prompt/user-correct-geo-location';
import LocationMapPanel from './../location-map';
import { NavLink } from 'react-router-dom';

const EmptyView = () => 
    <h3>
        You have no locations stored.
    </h3>

const LocationNotice = ({ 
    notice, 
    removeNotice,
    locationId
}) => 
    <div>
        <NavLink
            to={`/exchange/${notice.targetId}`}
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
        >
            Exchange Details
        </NavLink>
        <button 
            onClick={event => {
                removeNotice(locationId, notice.id)
            }}
            className="test-manageLocNoticeRemoveBtn"
        >
            Remove
        </button>
    </div>

const LocationNotices = ({ 
    loc,
    removeNotice 
}) => loc.locationNotices && loc.locationNotices.length > 0 ? 
    <div style={{
        ...theme.getProfileButtonStyle(theme),
        ...theme.getUserWidgetOuterContainerStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    }}>
        <p style={{
            ...theme.getWarningTextStyle(theme)
        }}>
            {
                
                loc.locationNotices.length > 1 ? 
                    'You have sent now outdated geo details to user(s) involved in several exchanges.' :
                        'You have sent now outdated geo details to user(s) involved in an exchange.'
                        
            }
        </p>
        <div>
            Directly reach out to users to avoid disruptions to your exchange.
        </div>
        {
            loc.locationNotices.map((aLocNotice, index) => 
                <LocationNotice 
                    key={index}
                    locationId={loc.id}
                    notice={aLocNotice}
                    removeNotice={removeNotice}
                />
            )
        }
    </div> : ''

const GeoStatusFound = ({ 
    loc, 
    onLocationUpdate,
    removeNotice,
    reloadData
}) => {
    const [ displayPrompt, setDisplayPrompt ] = useState(false);
    /* istanbul ignore next */
    const onRepaired = response => {
        setDisplayPrompt(false);
        onLocationUpdate(response);
        reloadData();
    };

    return (
        <div>
            <h3
                style={{
                    ...theme.getLocationMapLabelsStyle(theme)
                }}
            >
                Location Map
            </h3>
            <LocationMapPanel
                selectedLocation={loc}
            />

            <LocationNotices 
                removeNotice={removeNotice}
                loc={loc} 
            />

            <p
                style={{
                    ...theme.getLocationMapLabelsStyle(theme)
                }}
            >
                Wrong Location?
            </p>
            <button 
                className="test-manageLocationsUpdateGeoBtn"
                onClick={event => { setDisplayPrompt(true) }}
            >
                Update Geo Details
            </button>


            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setDisplayPrompt(false) }
                }
                open={displayPrompt}
            >
                <h4>
                    Correct Geo Details for 
                </h4>
                <div style={{
                    ...theme.getLocationMailingAddressStyle(theme)
                }}>
                    <p>
                        { loc.mailingAddress }
                    </p>
                    <p>
                        Specify anonymous geo details for the anonymous versions of location maps, we suggest the geo location of your city.
                    </p>

                    <UserCorrectGeoLocationPrompt
                        locationId={loc.id}
                        latitude={loc.latitude}
                        longitude={loc.longitude}
                        anonLatitude={loc.anonRegionLatitude}
                        anonLongitude={loc.anonRegionLongitude}
                        onRepaired={onRepaired}
                    />

                </div>
            </Dialog>
        </div>
    );
};

const GeoStatusProcessing = () => 
    <div style={{
        ...theme.getAbstractTextAlignCenterStyle(theme)
    }}>
        <div className="spin">
        </div>
        <p style={{
                    ...theme.getAbstractTextAlignCenterStyle(theme)
        }}>
            Processing
        </p>
    </div>

const GeoStatusPending = () => 
    <div style={{
        ...theme.getAbstractTextAlignCenterStyle(theme)
    }}>
        <div className="spin">
        </div>
        <p style={{
                        ...theme.getAbstractTextAlignCenterStyle(theme)
            }}>
                Pending
        </p>
    </div>

const GeoStatusFailed = ({ loc, onLocationUpdate }) => {
    const [ displayPrompt, setDisplayPrompt ] = useState(false);
    /* istanbul ignore next */
    const onRepaired = response => {
        setDisplayPrompt(false);
        onLocationUpdate(response);
    };
    return (
        <div>
            <p>
                We were unable to automatically identify these geo details.
            </p>
            <button 
                className="test-manageLocationsManuallyAddGeoBtn"
                onClick={event => { setDisplayPrompt(true) }}
            >
                Manually Add
            </button>

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setDisplayPrompt(false) }
                }
                open={displayPrompt}
            >
                <h4>
                    Add Geo Details for 
                </h4>
                <div style={{
                    ...theme.getLocationMailingAddressStyle(theme)
                }}>
                    <p>
                        { loc.mailingAddress }
                    </p>
                    <p>
                        Specify anonymous geo details for the anonymous versions of location maps, we suggest the geo location of your city.
                    </p>
                </div>

                <UserRepairGeoLocationPrompt
                    locationId={loc.id}
                    onRepaired={onRepaired}
                />
            </Dialog>
        </div>
    );
};

const LocationGeoStatus = ({ 
    loc,
    removeNotice,
    onLocationUpdate,
    reloadData
}) => loc.hasGps ? <GeoStatusFound 
                        loc={loc} 
                        removeNotice={removeNotice}
                        onLocationUpdate={onLocationUpdate}
                        reloadData={reloadData}
                    /> : (
    loc.pendingGeoLocProcessing ? <GeoStatusPending /> : (
        loc.geoLocFailed ? <GeoStatusFailed 
                                onLocationUpdate={onLocationUpdate} 
                                loc={loc} 
                            /> :
            <GeoStatusProcessing /> 
    )
);

const Location = ({ 
    loc, 
    onDeleteLocation, 
    onSetAsDefault,
    onLocationUpdate,
    removeNotice,
    reloadData
}) => {
    const [ confirmDeleteDialogOpen, setConfirmDeleteDialogOpen ] = useState(false);

    return (
        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            
            <button 
                className="test-manageLocationsDeleteLocBtn"
                onClick={event => { setConfirmDeleteDialogOpen(true) }}
            >
                <div style={{
                    ...theme.getAbstractCenterFlexContainerStyle(theme),
                }}>
                    <svg viewBox="0 0 250 250" style={{
                        ...theme.getGeneralButtonInlineStyle(theme),
                    }}>
                        <path className="st0" d="M125,13C63.1,13,13,63.1,13,125s50.1,112,112,112s112-50.1,112-112S186.9,13,125,13z M170.7,154
                        c4.7,4.7,4.7,12.3,0,17c-2.3,2.3-5.4,3.5-8.5,3.5s-6.1-1.2-8.5-3.5l-29.1-29.1L95.5,171c-2.3,2.3-5.4,3.5-8.5,3.5s-6.1-1.2-8.5-3.5
                        c-4.7-4.7-4.7-12.3,0-17l29.1-29.1L78.6,95.8c-4.7-4.7-4.7-12.3,0-17s12.3-4.7,17,0l29.1,29.1l29.1-29.1c4.7-4.7,12.3-4.7,17,0
                        c4.7,4.7,4.7,12.3,0,17l-29.1,29.1L170.7,154z"/>
                    </svg>
                </div>
                Delete Location
            </button>
            
            <Dialog
                onClose={
                    /* istanbul ignore next */
                    event => { setConfirmDeleteDialogOpen(false) }
                }
                open={confirmDeleteDialogOpen}
            >
                <div style={{
                    ...theme.getConfirmDeleteLocationStyle(theme)
                }}>
                    <h3>
                        Delete Location?
                    </h3>
                    <p
                        style={{
                        ...theme.getLocationMailingAddressStyle(theme)
                        }}
                    >
                        { loc.mailingAddress }
                    </p>
                    <button
                        onClick={event => { onDeleteLocation(loc) }}
                    >
                        Delete
                    </button>
                </div>
            </Dialog>

            <p>
                { loc.mailingAddress }
            </p>
            
            {
                loc.hasTruckDocks && 
                    <h4>
                        <strong>
                            Has Truck Dock(s)
                        </strong>
                    </h4>
            }

            {
                loc.hasLimitedAccess && 
                    <h4>
                        <strong>
                            Limited Access Location
                        </strong>
                    </h4>
            }

            {
                loc.notes !== '' && 
                    <p>
                        <strong>
                            Notes:
                        </strong>
                        <br />
                        { loc.notes }
                    </p>
            }

            {
                loc.isDefault ? 
                    <h3>
                        Default Location
                    </h3> : 
                        <button 
                            className="test-manageLocationsSetAsDefaultBtn"
                            onClick={event => { onSetAsDefault(loc) }}
                        >
                            Set as Default
                        </button>
            }

            <LocationGeoStatus 
                loc={loc} 
                removeNotice={removeNotice}
                onLocationUpdate={onLocationUpdate}
                reloadData={reloadData}
            />

        </div>
    );
};

const PopulatedView = ({ 
    locations, 
    onDeleteLocation, 
    onLocationUpdate,
    onSetAsDefault,
    removeNotice,
    reloadData
}) => 
    <div style={{
        ...theme.getAbstractLeftFlexContainerStyle(theme),
        ...theme.getAbstractFlexWrapStyle(theme),
    }}>
        {
            locations.map(aLoc => 
                <Location 
                    onSetAsDefault={onSetAsDefault}
                    onDeleteLocation={onDeleteLocation} 
                    onLocationUpdate={onLocationUpdate}
                    loc={aLoc} 
                    removeNotice={removeNotice}
                    key={aLoc.id} 
                    reloadData={reloadData}
                />
            )
        }
    </div>

const ManageLocationsViewPanel = ({ 
    data, 
    onDeleteLocation, 
    onLoadDataResponse, 
    onSetAsDefault,
    removeNotice,
    onLocationUpdate,
    reloadData
}) => {
    const [ showAddPrompt, setShowAddPrompt ] = useState(false);
    
    const getContainerStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );

    const getDialogContainerStyle = () => ({
        ...theme.getDialogContainerContentStyle(theme),
        ...theme.getAbstractTextAlignCenterStyle(theme)
    });
    const [ dialogContainerStyle, setDialogContainerStyle ] = useState( getDialogContainerStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
        setDialogContainerStyle( getDialogContainerStyle() );
    });

    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h2>
                Manage your locations
            </h2>

            <div style={containerStyle}>
                <button 
                    className="test-manageLocationsAddBtn"
                    onClick={event => { setShowAddPrompt(true) }}
                >
                    Add
                </button>
                <div>               
                    {
                        data.locations.total < 1 ? 
                            <EmptyView /> : 
                                <PopulatedView 
                                    locations={data.locations.list} 
                                    removeNotice={removeNotice}
                                    onSetAsDefault={onSetAsDefault}
                                    onLocationUpdate={onLocationUpdate}
                                    onDeleteLocation={onDeleteLocation} 
                                    reloadData={reloadData}
                                />
                    }

                    <Dialog 
                        //sx={{
                        //    ...theme.getBasicDialogContainerStyle(theme)
                        //}}
                        onClose={
                            /* istanbul ignore next */
                            event => { setShowAddPrompt(false) }
                        }
                        open={showAddPrompt}
                    >
                        <div style={dialogContainerStyle}>
                            <h3>
                                Add a Location
                            </h3>

                            <div>
                                <AddLocationPrompt
                                    onUpdateLocations={
                                        /* istanbul ignore next */
                                        response => { 
                                            onLoadDataResponse(response); 
                                            setShowAddPrompt(false);
                                        }
                                    } 
                                />
                            </div>
                        </div>
                        
                    </Dialog>
                    
                </div>
            </div>
        </div>
    );
};

export default ManageLocationsViewPanel;
