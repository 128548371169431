export const getPaymentMethodRemoveStyle = (theme) => ({
    padding: '.75rem 1rem',
});

export const getPaymentMethodAddButtonStyle = (theme, icon) => ({
    width: '2.25rem',
    height: '2.25rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.75rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
    margin: '.5rem 0 .5rem .75rem'
});

export const getPaymentMethodAddButtonTextStyle = (theme) => ({
    textAlign: 'center',
    fontWeight: '750',
    fontSize: '11pt',
    lineHeight: '.9rem',
    margin: '0 .75rem .75rem .75rem',
});

export const getPaymentMethodTableWidthStyle = (theme) => ({
    width: '100%',
    minWidth: '100%'
});

export const getPaymentMethodGridContainerStyle = (theme) => ({
    display: 'flex',
    flexDirection: theme.isDesktop() ? 'row' : 'column',
    justifyContent: 'end',
    padding: '.75rem',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    width: '100%',
    margin: '0 0 1rem 0'
});

export const getPaymentMethodItemStyle = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'baseline',
    margin: '0 .75rem',
    width: 'calc(100% - 1.5rem)'
});

export const getPaymentMethodHeaderStyle = (theme) => ({
    wordWrap: 'anywhere',
    minHeight: '1.5rem'
});

export const getPaymentMethodButtonStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'end',
    margin: '0 .75rem .5rem',
    width: 'calc(100% - 1.5rem)'
});

export const getPaymentMethodMinWidthStyle = (theme) => ({
    minWidth: '60rem'
});

export const getPaymentCloseButtonStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '.5rem',
    borderRadius: '.25rem',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    borderColor: theme.secondaryColor,
    backgroundColor: theme.panelColor,
});
