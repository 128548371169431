import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileLandingPanel from './../ui/panel/profile-landing';
import ProfileMenu from './../ui/menu/profile';

const ProfilePage = () => {
    return (
        <AuthPageTemplate childMenu={ <ProfileMenu /> }>
            <ProfileLandingPanel />
        </AuthPageTemplate>
    );
};

export default ProfilePage;
