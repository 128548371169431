import React from 'react';
import MenuList from './core/menu-list';
import AdminPayoutsIcon from './../../img/menu-admin-payouts.png';
import EventIcon from './../../img/menu-event.png';
import AdminEditIcon from './../../img/menu-admin-edit.png';
import AdminUsersIcon from './../../img/menu-people.svg';
import StatsIcon from './../../img/menu-stats-white.svg';
import IpIcon from './../../img/menu-admin-ip.png';
import LogsIcon from './../../img/menu-logs.png';
import theme from './../theme';

const AdminMenu = () => <MenuList list={[
    {
        to: '/admin/wiki',
        title: 'Admin Wiki',
        customStyle: {
            ...theme.getMenuSubStyle(theme, AdminEditIcon),
        },
    },
    {
        to: '/admin/users',
        title: ['Admin', <br/>, 'Users'],
        customStyle: {
            ...theme.getMenuSubStyle(theme, AdminUsersIcon),
        },
    },
    {
        to: '/admin/stats',
        title: 'User Stats',
        customStyle: {
            ...theme.getMenuSubStyle(theme, StatsIcon),
        },
    },
    
    {
        to: '/admin/money',
        title: 'Admin Money',
        customStyle: {
            ...theme.getMenuSubStyle(theme, AdminPayoutsIcon),
        }
    },
    {
        to: '/admin/events',
        title: 'Bx Events',
        customStyle: {
            ...theme.getMenuSubStyle(theme, EventIcon),
        }
    },
    {
        to: '/admin/ip',
        title: 'IP Addresses',
        customStyle: {
            ...theme.getMenuSubStyle(theme, IpIcon),
        }
    },
    {
        to: '/admin/logs',
        title: 'Logs',
        customStyle: {
            ...theme.getMenuSubStyle(theme, LogsIcon),
        }
    },
    {
        to: '/admin/metrics/summary',
        title: 'Metrics',
        customStyle: {
            ...theme.getMenuSubStyle(theme, LogsIcon),
        }
    }
]} />


export default AdminMenu;
