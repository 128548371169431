import React from 'react';

// istanbul ignore next
const parseMilesCopy = distance => {
    let miles = Math.round(distance);
    const unit = miles > 1 ? 'miles' : 'mile';
    miles = miles === 0 ? 'less than a' : miles;
    return `${miles} ${unit}`;
};

// istanbul ignore next
const parseLocationName = aHub => {
    const street = aHub.streetLine2 ? 
        `${aHub.street} ${aHub.streetLine2}` : 
            aHub.street;
    const cityState = aHub.shortState ? 
    `${aHub.city}, ${aHub.shortState}` : 
        `${aHub.city}, ${aHub.state}`;

    return `${street} ${cityState} ${aHub.zipCode}`;
};

const SelectDestinationViewPanel = ({
    data,
    delivery,
    onDestinationSelected
}) => 
    <div>
        <select defaultValue="" onChange={onDestinationSelected}>
            <option value="" >
                Select a Destination
            </option>
            <option value="final">
                {`Final Destination - ${parseMilesCopy(data.directShipDetails.distanceInMiles)} -- ${parseLocationName(delivery.deliveryLocation)}`}
            </option>
            {
                data.shipToHubDetails.list.map((aHub, index) => {
                    
                    return (
                        <option 
                            value={JSON.stringify(aHub)} 
                            key={index}
                        >
                            {`------Delivery Hub - ${parseMilesCopy(aHub.distanceInMiles)} -- ${parseLocationName(aHub)}`}
                        </option>
                    );
                })
            }
        </select>
    </div>

export default SelectDestinationViewPanel;
