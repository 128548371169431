export const getAddUspsPaymentExchangePromptPriceTitleStyle = (theme) => ({
    alignSelf: 'start'
});

export const getAddUspsPaymentExchangePromptPriceValueStyle = (theme) => ({
    alignSelf: 'end',
});

export const getAddUspsPaymentExchangePromptPriceLineStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 .5rem'
});

export const getAddUspsPaymentExchangePromptPriceContainerStyle = (theme) => ({
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    marginLeft: 'auto',
    marginRight: 'auto'
});
