import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../../../../service/resize';
import UiService from './../../../../../service/ui';
import theme from './../../../../theme';

const Offer = ({ offer }) => {
    const getWidgetMyActiveOffersStyle = () => theme.getWidgetMyActiveOffersStyle(theme);
    const [ widgetMyActiveOffersStyle, setWidgetMyActiveOffersStyle ] = useState(getWidgetMyActiveOffersStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setWidgetMyActiveOffersStyle(getWidgetMyActiveOffersStyle());
    });
    useEffect(resizeEffect);
    return (
        <NavLink 
            style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
            to={`/offer/${offer.id}`}
        >
            <div style={widgetMyActiveOffersStyle}>
                <div>
                    { offer.title }
                </div>
                <strong>
                    { UiService.timeSince(offer.createdOn) }
                </strong>
            </div>
        </NavLink>
    );
};

const PopulatedView = ({ list }) => {
    const getUserWidgetFlexParentStyle = () => theme.getUserWidgetFlexParentStyle(theme);
    const [ userWidgetFlexParentStyle, setUserWidgetFlexParentStyle] = useState(getUserWidgetFlexParentStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetFlexParentStyle(getUserWidgetFlexParentStyle());
    });
    useEffect(resizeEffect);
    return (
        <section style={userWidgetFlexParentStyle}>
            {
                list.map((offer, index) => <Offer key={index} offer={offer} />)
            }
        </section>
    );
};

const EmptyView = ({ userWidgetHeaderTextStyle }) => {
    const getContainerStyle = () => theme.getUserWidgetSubHeaderTextStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section style={userWidgetHeaderTextStyle}>
            <p style={containerStyle}>
                You have no active offers. 
                <br />
                <NavLink 
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to="/offer/add"
                >
                    Publish an Offer.
                </NavLink>
            </p>
        </section>
    );
};

const WidgetMyActiveOffersViewPanel = ({ data }) => {
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle] = useState(getUserWidgetHeaderTextStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setUserWidgetHeaderTextStyle(getUserWidgetHeaderTextStyle());
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    My Active Offers
                </div>
            </div>
            {
                data.list.length < 1 ? 
                    <EmptyView userWidgetHeaderTextStyle={userWidgetHeaderTextStyle} /> : 
                        <PopulatedView list={data.list} />
            }
        </section>
    );
};


export default WidgetMyActiveOffersViewPanel;
