import React from 'react';
import ActorDetails from './actor-details';
import SetupDeliveryView from './setup-delivery-view';
import {
    isHandDelivery,
    hasDelivery
} from './util';

const PaymentConfirmedView = ({ 
    exchange, 
    onFulfilled, 
    onLoadDataResponse, 
    onProvidedToShipping 
}) => 
    <section>
        {
            exchange.bid.hasRefunded ? '' : 
            <div>
                <h2>
                    Awaiting Exchange from 
                    <ActorDetails 
                        usersDetails={exchange.usersDetails} 
                        actorId={exchange.actorId} 
                        amIActor={exchange.amIActor}
                    />
                </h2>
                <p>
                    {
                        exchange.bid.isFree ? 
                        'It is time to deliver the item or service.  Use the chat to gather any unknown details.' :
                            'Payment has been received.  It is time to deliver the item or service.  Use the chat to gather any unknown details.'
                    }
                </p>
            </div>
        }
        {
            ((exchange.bid.payFlagged || exchange.bid.refundRequested) && (exchange.bid.flagReviewed || exchange.bid.refundRequestReviewed) && !exchange.bid.hasRefunded) ? 
                <p>
                    Transaction has been reviewed and the refund request was rejected.
                </p> : ''
        }
        {
            ((exchange.bid.payFlagged && !exchange.bid.flagReviewed) || (exchange.bid.refundRequested && !exchange.bid.refundRequestReviewed) || exchange.bid.hasRefunded) ? (
                exchange.bid.hasRefunded ? 'Transaction refunded.  Exchange has halted.' :
                    exchange.bid.payFlagged ? 'Transaction has been flagged for review.' : 'Transaction has been paused for refund request'
            ) : (
                exchange.amIActor ? (
                    hasDelivery(exchange) && !isHandDelivery(exchange) ? 
                        <SetupDeliveryView 
                            onProvidedToShipping={onProvidedToShipping}
                            exchange={exchange} 
                            onLoadDataResponse={onLoadDataResponse} 
                        /> : 
                            <button 
                                className="test-exchangeDetailPaymentConfirmedViewCompletedBtn"
                                onClick={event => { onFulfilled() }}
                            >
                                { isHandDelivery(exchange) ? 'Delivery Completed ' : 'Transaction Fulfilled' }
                            </button>
                ) : ''
            )
        }
    </section>

export default PaymentConfirmedView;
