export const getOnboardingDynamicFrameStyle = (theme) => ({
    maxWidth: '60rem',
    marginLeft: 'auto',
    marginRight: 'auto'
});

export const getOnboardingDynamicFramePhoneStyle = (theme) => ({
    marginLeft: 'auto',
    marginRight: 'auto'
});

export const getOnboardingDynamicButtonStyle = (theme) => ({
    textAlign: 'center'

});
