import { 
    LOGIN,
    AUTH_UPDATE,
    LOGOUT,
    UPDATE_NOTICE_COUNT,
    UPDATE_ERROR,
    UPDATE_MENU,
    UPDATE_CLIENT_SETTINGS,
    UPDATE_METRICS,
    UPDATE_SPEED_VIEW,
    UPDATE_AUTO_SPEED_VIEW,
    UPDATE_SPEED_VIEW_PROMPT_VISIBLE,
    UPDATE_USER_CLOSED_SPEED_VIEW_PROMPT,
    UPDATE_ONBOARDING_VISIBLE,
    UPDATE_ONBOARDING_CRAWLER,
    UPDATE_DEVICE_ONLINE
} from './constants';
import iniOnboardingCrawler from './ini-onboarding-crawler';

export const Login = (authToken, userId, isAdmin, unseenNoticeCount, clientSettings) => ({
        type: LOGIN,
        authToken,
        userId,
        isAdmin,
        unseenNoticeCount,
        clientSettings
});

export const UpdateMetrics = (metrics) => ({
    type: UPDATE_METRICS,
    metrics
});

export const UpdateClientSettings = (clientSettings) => ({
    type: UPDATE_CLIENT_SETTINGS,
    clientSettings
});

export const AccessUpdate = (authToken) => ({
    type: AUTH_UPDATE,
    authToken
});

export const Logout = () => ({
    type: LOGOUT
});

export const UpdateError = (displayError, errorMessage) => ({
    type: UPDATE_ERROR,
    displayError,
    errorMessage,
});

export const UpdateNoticeCount = (unseenNoticeCount) => ({
    type: UPDATE_NOTICE_COUNT,
    unseenNoticeCount,
});

export const UpdateMenuState = (showFullMenu) => ({
    type: UPDATE_MENU,
    showFullMenu
});

export const UpdateSpeedView = (slowSpeedViewEnabled) => ({
    type: UPDATE_SPEED_VIEW,
    slowSpeedViewEnabled
});

export const UpdateDeviceNetwork = (deviceIsOnline) => ({
    type: UPDATE_DEVICE_ONLINE,
    deviceIsOnline
});

export const UpdateAutoSpeedView = (autoAdjustSpeedViewEnabled) => ({
    type: UPDATE_AUTO_SPEED_VIEW,
    autoAdjustSpeedViewEnabled
});

export const UpdateSpeedViewPromptVisible = (showSpeedViewPrompt) => ({
    type: UPDATE_SPEED_VIEW_PROMPT_VISIBLE,
    showSpeedViewPrompt
});

export const UpdateUserClosedSpeedViewPrompt = (showSpeedViewPrompt) => showSpeedViewPrompt ? 
{
    type: UPDATE_USER_CLOSED_SPEED_VIEW_PROMPT,
    showSpeedViewPrompt

} : {
    type: UPDATE_USER_CLOSED_SPEED_VIEW_PROMPT,
    showSpeedViewPrompt,
    userHasClosedSpeedViewPrompt: true
};

export const UpdateOnboardingVisible = (showOnboarding, onboardingCrawler = iniOnboardingCrawler) => ({
    type: UPDATE_ONBOARDING_VISIBLE,
    showOnboarding,
    onboardingCrawler
});

export const UpdateOnboardingCrawler = (onboardingCrawler = iniOnboardingCrawler) => ({
    type: UPDATE_ONBOARDING_CRAWLER,
    onboardingCrawler
});
