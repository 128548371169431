import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import OfferSearchPrompt from './../ui/prompt/offer-search';
import PostsMenu from './../ui/menu/posts';
import { useParams } from 'react-router-dom';
import theme from './../ui/theme';
import TagService from './../service/tag';

const OffersPage = () => {
    const { page, searchText, tags } = useParams();
    /* istanbul ignore next */
    const passPage = page ? parseInt(page) : 0;
    /* istanbul ignore next */
    const passSearchText = searchText ? searchText : '';
    /* istanbul ignore next */
    const foundTagsRaw = tags ? tags : '';
    const parsedTags = TagService.parseUrlTags(foundTagsRaw);
    
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate childMenu={ <PostsMenu /> }>
            <div style={containerStyle}>
                <h2>
                    Offers
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Items and services offered by other users
                </h4>
            </div>
            <OfferSearchPrompt page={passPage} searchText={passSearchText} tags={parsedTags} />
        </AuthPageTemplate>
    );
};

export default OffersPage;
