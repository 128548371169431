import {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_PICK_UP,
    OBTAIN_TYPE_ON_SITE,
} from './../enum/obtain-type';

export const getObtainTypeLocationTitleCopy = (obtainMethodType, isOffer) => {
    switch(obtainMethodType)
    {
        case OBTAIN_TYPE_SHIP_TO_ADDRESS:
            return isOffer ? 'Where do you wish to receive shipment?' : 'Where do you intend to ship from?';

        case OBTAIN_TYPE_HAND_TO_HAND:
            return isOffer ? 'Where do you wish to receive delivery?' : 'Where are you traveling from?';

        case OBTAIN_TYPE_ON_SITE:
            return isOffer ? 'Where is the site?' : 'Where are you traveling from?';

        case OBTAIN_TYPE_PICK_UP:
            return isOffer ? 'Where is the pick up location?' : 'Where is the pick up location?';

        default:
            return 'Select a Location';
    }
};