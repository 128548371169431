import React from 'react';
import AuthPageTemplate from './template/auth-page';
import AddOfferPrompt from './../ui/prompt/add-offer';
import PostsMenu from './../ui/menu/posts';

const AddOfferPage = () => {
    /* istanbul ignore next */
    return (
        <AuthPageTemplate childMenu={ <PostsMenu /> }>
            <AddOfferPrompt />
        </AuthPageTemplate>
    );
};

export default AddOfferPage;
