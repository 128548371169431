import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import UserSearchPrompt from './../ui/prompt/user-search';
import ProfileMenu from './../ui/menu/profile';
import theme from './../ui/theme';
import { useParams } from 'react-router-dom';


const UsersPage = () => {
    const { page, searchText } = useParams();
    /* istanbul ignore next */
    const passPage = page ? parseInt(page) : 0;
    /* istanbul ignore next */
    const passSearchText = searchText ? searchText : '';
    
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate 
            childMenu={
                <div>
                    <ProfileMenu />
                </div>
        }>
            <div style={containerStyle}>
                <h2>
                    Users
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Search for and friend users you know. Friends are granted visibility into restricted areas of postings such as in-person pick up.
                </h4>
            </div>
            
            <UserSearchPrompt page={passPage} searchText={passSearchText} />
        </AuthPageTemplate>
    );
};
export default UsersPage;
