import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminMoneyPanel from './../ui/panel/admin-money';
import theme from './../ui/theme';

const AdminMoneyPage = () => 
    <AdminPageTemplate>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h2>
                Admin Money
            </h2>
            
            <p>
                Manage money related features
            </p>
            <AdminMoneyPanel />
        </div>     
    </AdminPageTemplate>

export default AdminMoneyPage;
