import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from '../../../service/resize';
import UiService from './../../../service/ui';
import Pagination from './../../prompt/core/pagination';
import Dialog from '@mui/material/Dialog';

const ImgView = ({ 
    image, 
    viewLarge,
    onRemoveImg 
}) => {
    const getImgViewStyle = () => theme.getImgViewStyle(theme);
    const [ imgViewStyle, setImgViewStyle ] = useState( getImgViewStyle() );
    
    const getImgViewContainerStyle = () => theme.getImgViewContainerStyle(theme);
    const [ imgViewContainerStyle, setImgViewContainerStyle ] = useState(getImgViewContainerStyle());
     
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setImgViewStyle(  getImgViewStyle() );
        setImgViewContainerStyle( getImgViewContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={imgViewContainerStyle}>
            <div 
                style={{
                    ...theme.getImgWrapperStyle(theme)
                }} 
                className="test-imgListViewLargeBtn"
                onClick={event => { viewLarge() }}
            >
                <img 
                    src={image.previewImage ? image.previewImage : image.data } 
                    alt="File Missing" 
                    style={imgViewStyle}
                />
            </div>
            <div>
                <p>
                    Filename: { image.filename }
                </p>
                <p>
                    Uploaded: { UiService.timeSince(image.createdOn) }
                </p>
                <button 
                    className="test-imgListRemoveBtn"
                    onClick={ event => { onRemoveImg(image) }}
                >
                    Delete
                </button>
            </div>        
        </div>
    );
};

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            hideAllWhenNoButtonsVisible={true}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const LargeImageView = ({ 
    image,
    setToNextImage,
    setToLastImage,
    hasManyImages
}) => {
    const getImgViewStyle = () => theme.getLargeImgViewStyle(theme);
    const [ imgViewStyle, setImgViewStyle ] = useState( getImgViewStyle() );

    const getImgViewContainerStyle = () => theme.getLargeImgViewContainerStyle(theme);
    const [ imgViewContainerStyle, setImgViewContainerStyle ] = useState( getImgViewContainerStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setImgViewStyle(  getImgViewStyle() );
        setImgViewContainerStyle( getImgViewContainerStyle() );
    });
    useEffect(resizeEffect);

    return (
        <div>
            <div style={imgViewContainerStyle}>
                {
                    image && 
                    <img 
                        src={image.previewImage ? image.previewImage : image.data } 
                        alt="File Missing" 
                        style={imgViewStyle}
                    />
                }
            </div>
            {
                hasManyImages &&
                    <div>
                        <button 
                            className="test-imgListLargeViewLastBtn"
                            onClick={event => { setToLastImage() }}
                        >
                            Last
                        </button>
                        <button 
                            className="test-imgListLargeViewNextBtn"
                            onClick={event => { setToNextImage() }}
                        >
                            Next
                        </button>
                    </div>
            }
        </div>
    );
};
const PopulatedView = ({ data, onRemoveImg, onLoadSpecifiedPage }) => {

    const [ selectedImageIndex, setSelectedImageIndex ] = useState(null);
    const isLargeViewOpen = () => selectedImageIndex !== null;
    const hasManyImages = data.list.length > 1 ? true : false;
    /* istanbul ignore next */
    const setToNextImage = () => {
        if(isLargeViewOpen() && hasManyImages)
        {
            let nextIndex = selectedImageIndex + 1;
            nextIndex = nextIndex >= data.list.length ? 0 : nextIndex;
            setSelectedImageIndex(nextIndex);
        }
    };
    /* istanbul ignore next */
    const setToLastImage = () => {
        if(isLargeViewOpen() && hasManyImages)
        {
            let nextIndex = selectedImageIndex - 1;
            nextIndex = nextIndex < 0 ? (data.list.length - 1) : nextIndex;
            setSelectedImageIndex(nextIndex);
        }
    };

    const getOfferSearchResultStyle = () => theme.getOfferSearchResultStyle(theme);
    const [ offerSearchResultStyle, setOfferSearchResultStyle ] = useState( getOfferSearchResultStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferSearchResultStyle( getOfferSearchResultStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            { getPaginationUi({ data, onLoadSpecifiedPage }) }
            <div  style={offerSearchResultStyle}>
                {
                    data.list.map((img, index) => 
                        <ImgView 
                            image={img} 
                            viewLarge={() => { setSelectedImageIndex(index) }}
                            onRemoveImg={onRemoveImg} 
                            key={index} 
                        />
                    )
                }
            </div>
            { getPaginationUi({ data, onLoadSpecifiedPage }) }

            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setSelectedImageIndex(null) }
                }
                open={data.list[selectedImageIndex] ? true : false}
            >
                <LargeImageView 
                    image={data.list[selectedImageIndex]} 
                    setToNextImage={setToNextImage}
                    setToLastImage={setToLastImage}
                    hasManyImages={hasManyImages}
                />
            </Dialog>

        </section>
    );
};

const EmptyView = () => 
    <section>
        <p>
            No images saved.
        </p>
    </section>

const ImgListViewPanel = ({ data, onRemoveImg, onLoadSpecifiedPage }) => {
    
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getBodyStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getImgListContainerStyle(theme),
            ...theme.getGeneralTextStyle(theme),
        }}>
            <div style={{
                ...theme.getInLineTextStyle(theme)  
            }}>
                <h2>
                    Image List
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Images added to your offers and seeks will appear here.
                </h4>
            </div>
            <div style={bodyStyle}>
                {
                    data.list.length < 1 ? <EmptyView /> : <PopulatedView data={data} onLoadSpecifiedPage={onLoadSpecifiedPage} onRemoveImg={onRemoveImg} />
                }
            </div>
        </div>
    );
};

export default ImgListViewPanel;
