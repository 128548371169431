export const getTrailsPlatformContainerStyle = (theme) => ({
    maxWidth: '58.5rem',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
});

export const getTrailsCustomContainerStyle = (theme) => ({
    display: 'flex',
});

export const getTrailsCustomStyle = (theme, img) => ({
    width: '7.5rem',
    height: '7.5rem',
    backgroundImage: `url(${img})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    marginLeft: '.75rem',
    marginRight: '.75rem',
    marginTop: '.75rem',
    backgroundColor: '#000',
    borderRadius: '.3rem',
    boxShadow: 'rgba(29, 29, 29, .5) -.1rem .1rem .075rem',
});
