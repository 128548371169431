import CurrencyTypeEnum from './../ui/prompt/core/enum/currency-type';

class CurrencyInputService
{
    static currencyToInput(value)
    {
        const position = value.length - 2;
        return /\./.test(value) ? value : (
            value.substring(0, position) + '.' + value.substring(position)
        );
    }
    
    static parse(value)
    {
        return /\./.test(value) ? value.replace('.', '') : (
            '' + value + '00'
        );
    }

    static bankAccountHasSortCode(state)
    {
        const selectedCurrency = state.currency.value;
        switch(selectedCurrency)
        {
            case CurrencyTypeEnum.CURRENCY_TYPE_GBP:
                return true;

            default:
                return false;
        }
    }

    static bankAccountHasTransitNumber(state)
    {
        const selectedCurrency = state.currency.value;
        switch(selectedCurrency)
        {
            case CurrencyTypeEnum.CURRENCY_TYPE_CAD:
                return true;
            
            default:
                return false;
        }
    }

    static bankAccountHasInstitutionNumber(state)
    {
        const selectedCurrency = state.currency.value;
        switch(selectedCurrency)
        {
            case CurrencyTypeEnum.CURRENCY_TYPE_CAD:
                return true;
            
            default:
                return false;
        }
    }

    static bankAccountHasRoutingNumber(state)
    {
        const selectedCurrency = state.currency.value;
        switch(selectedCurrency)
        {
            case CurrencyTypeEnum.CURRENCY_TYPE_USD:
                return true;
            
            default:
                return false;
        }
    }

    static bankAccountHasBSB(state)
    {
        const selectedCurrency = state.currency.value;
        switch(selectedCurrency)
        {
            case CurrencyTypeEnum.CURRENCY_TYPE_AUD:
                return true;

            default:
                return false;
        }
    }
}

export default CurrencyInputService;
