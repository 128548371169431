import React from 'react';
import CurrencyService from './../../../../service/currency';
import theme from './../../../theme';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

// istanbul ignore next
const DepositQueue = ({ list, onDepositFunds }) => {
    const [ depositPromptOpen, setDepositPromptOpen ] = useState(false);
    return (
        <div>
            <button onClick={event => { setDepositPromptOpen(true) }}>
                Deposit Funds
            </button>

            <Dialog 
                onClose={ event => { setDepositPromptOpen(false) }}
                open={depositPromptOpen}
            >
                <p>
                    This process is triggered in the Stripe Dashboard
                    then confirmed here.  Start by transfering 
                </p>

                <ul>
                    {
                        list.map((item, index) => 
                            <li key={index}>
                                { CurrencyService.getDisplayPrice(item.currency, item.netTotal, true) }
                            </li>
                        )   
                    }
                </ul>

                <p>
                    Into the company bank account.  Then click
                </p>

                <button onClick={
                    // istanbul ignore next
                    event => { onDepositFunds() }
                }>
                    Confirm Deposit
                </button>

            </Dialog>
        </div>
    );
};

const ByCurrency = ({ title, list }) => 
    <div>
        <h4>
            { title }
        </h4>
        {
            // istanbul ignore next
            list.length < 1 ? 'None' : list.map((item, index) => 
                <div key={index} style={{
                    ...theme.getAdminLedgerSummaryPanelStyle(theme)  
                }}>
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    Transactions Total
                                </td>
                                <td>
                                    Provider Fees
                                </td>
                                <td>
                                    Net Total
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {
                                    [item.total, item.providerFee, item.netTotal].map((aLine, index) => 
                                        <td key={index}>
                                            {
                                                CurrencyService.getDisplayPrice(item.currency, aLine, true)
                                            }
                                        </td>
                                    )
                                }
                            </tr>
                        </tbody>
                    </table>
                    

                </div>
            )
        }
    </div>

const AdminLedgerSummaryViewPanel = ({ data, onDepositFunds }) => 
    <div>
        <ByCurrency title="Ready For Withdrawl" list={data.readyForWithdrawl} />
        <ByCurrency title="Exchange in Progress" list={data.exchangeInProgress} />
        <ByCurrency title="Refunded" list={data.totalRefunded} />
        <ByCurrency title="Queued For Deposit" list={data.queuedForDeposit} />
        {
            data.queuedForDeposit.length > 0 && 
                <DepositQueue list={data.queuedForDeposit} onDepositFunds={onDepositFunds} />
        }
        <ByCurrency title="Company Deposits" list={data.depositedToCompany} />
    </div>

export default AdminLedgerSummaryViewPanel;
