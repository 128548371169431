import React from 'react';

const SelectMember = ({
    sortedMembers,
    onMemberSelected
}) => 
    <select 
        defaultValue="" 
        onChange={ 
            /* istanbul ignore next */
            event => {
                if(event.target.value)
                {
                    onMemberSelected(event.target.value);
                }
            }
        }
    >
        <option value="" >
            Select a Member
        </option>
        {
            sortedMembers.map((aMember, index) => 
                <option value={aMember.id} key={aMember.id}>
                    { aMember.name }
                </option>
            )
        }
    </select>

export default SelectMember;
