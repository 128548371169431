import { 
    Logout,
    UpdateNoticeCount,
    UpdateError,
    UpdateClientSettings,
    UpdateDeviceNetwork
} from './../state/action';

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
    onLogout()
    {
        dispatch(Logout());
    },
    onUpdateUnseenNoticeCount(unseenNoticeCount)
    {
        dispatch(UpdateNoticeCount(unseenNoticeCount));
    },
    onUpdateError(displayError, errorMessage)
    {
        dispatch(UpdateError(displayError, errorMessage));
    },
    onLoadClientSettings(clientSettings)
    {
        dispatch(UpdateClientSettings(clientSettings));
    },
    onUpdateDeviceOnline(deviceIsOnline)
    {
        dispatch(UpdateDeviceNetwork(deviceIsOnline));
    }
});

export default mapDispatchToProps;
