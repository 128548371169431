import React from 'react';
import theme from './../../theme';

const getTagsLine = tagList => tagList.join(' - ');

const TagListPanel = ({ tagList }) => tagList.length < 1 ? '' :
    <div style={{
        ...theme.getTagListPostedOfferStyle(theme),
        ...theme.getAbstractTextBaselineStyle(theme),
    }}>
        <h4>
            Categories
        </h4>
        <p>
            { getTagsLine(tagList) }
        </p>
    </div>

export default TagListPanel;