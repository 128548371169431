import React from 'react';
import { useEffect } from 'react';
import config from './config';
import { useDispatch, useSelector } from 'react-redux';
import AuthTokenSelector from './store/select/auth-token';
import ClientSettingsSelector from './store/select/client-settings';
import UnSeenNoticeCountSelector from './store/select/unseen-notice-count';
import DeviceOnlineSelector from './store/select/device-online';
import { 
    UpdateNoticeCount, 
    Logout, 
//    UpdateError,
    UpdateDeviceNetwork
} from './store/state/action';
import NoticeService from './service/notice';
import ErrorService from './service/error';
import NetworkService from './service/network';
import toast from 'react-hot-toast';

var noticeServiceWatchNoticeCountInterval = null;
var networkServiceWatchNetworkInverval = null;

const NoticeWrapper = ({ children }) => {

    const dispatch = useDispatch();
    
    const authToken = useSelector(AuthTokenSelector);
    const clientSettings = useSelector(ClientSettingsSelector);
    const currentNoticeCount = useSelector(UnSeenNoticeCountSelector);
    const isDeviceOnline = useSelector(DeviceOnlineSelector);

    // istanbul ignore next
    const shouldSendToast = (response) => (
        (clientSettings.showAllNoticeToasts && response.unseenNoticeCount > currentNoticeCount)
        || 
        (clientSettings.showNoticeToasts && response.unseenNoticeCount > 0 && currentNoticeCount < 1)
    ) ? true : false;

    // istanbul ignore next
    const onWatchNoticeCountResponse = (response) => {

        if(shouldSendToast(response))
        {
            const toastMessage = clientSettings.showAllNoticeToasts ? 
                `You have ${response.unseenNoticeCount} unseen notice${response.unseenNoticeCount > 1 ? 's' : ''} ` : 
                    'You have unseen notices';
            toast(toastMessage);
        }

        dispatch(UpdateNoticeCount(response.unseenNoticeCount));
    };
    
    // istanbul ignore next
    const onWatchNoticeCountAuthError = (err) => {
        dispatch(Logout());
    };
    
    // istanbul ignore next
    const onWatchNoticeCountFatalError = (err) => {
        // disable fatal error on notice polling
        // dispatch(UpdateError(true, ''));
    };
  
    // istanbul ignore next
    const onWatchNoticeCountNetworkOffline = () => {
        dispatch(UpdateDeviceNetwork(false));
    };

    const watchNoticeCountErrorHandler = ErrorService.makeHandle({
        onError: (error) => {}
    });

    // istanbul ignore next
    const watchNoticeCount = () => {
        if(authToken !== '')
            {
                NoticeService.getUnseenNoticeCount({
                    onResponse: onWatchNoticeCountResponse,
                    onError: watchNoticeCountErrorHandler,
                    authToken,
                    onAuthError: onWatchNoticeCountAuthError,
                    onFatalError: onWatchNoticeCountFatalError,
                    onNetworkOffline: onWatchNoticeCountNetworkOffline
                });
            }
    };

    useEffect(() => {
        noticeServiceWatchNoticeCountInterval = setInterval(
            watchNoticeCount,
            config.interval.getUnseenNotices
        );
        // istanbul ignore next
        return () => {
            clearInterval(noticeServiceWatchNoticeCountInterval);
        };
    });
  
    
    const isBackOnline = () => {
        dispatch(UpdateDeviceNetwork(true));
    };
    const isOffline = () => {};
    const watchNetwork = () => {
        if(!isDeviceOnline)
        {
            NetworkService.syncIsOnline(isBackOnline, isOffline);
        }
    };

    useEffect(() => {
        networkServiceWatchNetworkInverval = setInterval(
            watchNetwork,
            config.interval.offlineNetworkBackOnline
        );
        return () => {
            clearInterval(networkServiceWatchNetworkInverval);
        };
    });

    return (
        <div>
            { children }
        </div>
    );

};

export default NoticeWrapper;
