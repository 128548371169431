import CarrierMemberService from './../../../service/carrier-member';
import ValidateService from './../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import CarrierMemberRoleEnum from './../core/enum/carrier-member-role';
import theme from './../../theme';

class _GrantCarrierMembershipRequestPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _GrantCarrierMembershipRequestPrompt.getValidators());

        this.getDynamicFields = this.getDynamicFields.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.validateState = this.validateState.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.getSelectedRoles = this.getSelectedRoles.bind(this);
        
        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        let validators = {};
        const roleList = CarrierMemberRoleEnum.getDropDownValues();
        roleList.forEach((role => {
            validators[role.value] = {
                isValid: ValidateService.anyValue
            };
        }));

        return validators;
    }

    getDynamicFields()
    {
        const roleList = CarrierMemberRoleEnum.getDropDownValues();
        let dynamicFields = {};
        roleList.forEach((role => {
            dynamicFields[role.value] = {
                name: `${role.name} Role`,
                error: `Check to Grant ${role.name} Role`,
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            };
        }));
        
        return dynamicFields;
    }

    getSelectedRoles()
    {
        let roles = [];
        const roleList = CarrierMemberRoleEnum.getDropDownValues();
        roleList.forEach((role) => {
            if(this.state[role.value].value)
            {
                roles.push(role.value);
            }
        });
        return roles;
    }

    getServerRequest()
    {
        return {
            pendingCarrierMemberId: this.props.pendingCarrierMemberId,
            isApproved: true,
            roles: this.getSelectedRoles(),
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const dynamicFields = this.getDynamicFields();
        return {
            ...baseState,
            ...dynamicFields,
        };
    }

    onSendToServer()
    {
        CarrierMemberService.grantMembership({
            request: this.getServerRequest(),
            fileList: [],
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken, 
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onAddResponse(response)
    {
        let updateState = this.getBaseStateOnResponse(response);
        updateState = this.clearFields(updateState);
        this.setState(updateState);
        if(this.props.onResponse)
        {
            this.props.onResponse();
        }
    }

    onAddError(error)
    {
        const updateState = this.getBaseStateOnError(error);
        this.setState(updateState);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getConfirmUi(confirmMessage = 'Member Added')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Grant')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    validateState(state)
    {
        let baseReturn = super.validateState(state);
        const oneRuleCopy = 'Select at least one role';
        const selectedRoles = this.getSelectedRoles();
        if(selectedRoles.length < 1)
        {
            baseReturn.message = oneRuleCopy;
            baseReturn.isValid = false;
        }
        else 
        {
            if(baseReturn.message === oneRuleCopy)
            {
                baseReturn.message = '';
            }
        }

        return baseReturn;
    }

    getPromptUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <h4>
                    Assign Roles
                </h4>
                {
                    super.getPromptUi()
                }
            </div>
        );
    }
}

const GrantCarrierMembershipRequestPrompt = connect(mapStateToProps, mapDispatchToProps)(_GrantCarrierMembershipRequestPrompt);
export default GrantCarrierMembershipRequestPrompt;
