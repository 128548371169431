import React from 'react';
import DetailPanel from './../../core/detail';
import UserActionService from './../../../../service/user-action';
import AdminUserPermissionsViewPanel from './view.js';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';

class _AdminUserPermissionsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onDeleteAction = this.onDeleteAction.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserActionService.adminList({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            targetUserId: inputObj.targetUserId,
        };
    }

    onDeleteAction(type)
    {
        super.loadData({  });

        UserActionService.adminDelete({
            request: {
                targetUserId: this.props.userId,
                type
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        const targetUserId = this.props.userId;
        this.loadData({
            targetUserId
         });
    }

    getLoadedDataDetailUi()
    {
        return <AdminUserPermissionsViewPanel 
            data={this.state.data} 
            targetUserId={this.props.userId} 
            onDeleteAction={this.onDeleteAction} 
            onLoadDataResponse={this.onLoadDataResponse}
        />
    }
}

const AdminUserPermissionsPanel = connect(mapStateToProps, mapDispatchToProps)(_AdminUserPermissionsPanel);
export default AdminUserPermissionsPanel;
