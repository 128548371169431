import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../service/resize';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import UserEmailPanel from './../ui/panel/user-email';
import theme from './../ui/theme';

const ProfileEmailPage = () => {
    const getContainerStyle = () => theme.getProfilePwdPageStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <AuthPageTemplate childMenu={
            <ProfileMenu />
        }>
            <div style={{
            ...theme.getInLineTextStyle(theme),
            ...theme.getInLineColumnStyle(theme),
            }}>  
                <h2>
                    Manage Email
                </h2>
                <div style={containerStyle}>            
                    <UserEmailPanel />
                </div>
            </div>
        </AuthPageTemplate>
    );
};

export default ProfileEmailPage;
