import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../../../theme';
import CurrencyService from './../../../../../service/currency';
import ResizeService from './../../../../../service/resize';
import CurrencyInputService from './../../../../../service/currency-input';

const Payout = ({
    payoutBuffer,
    onPayoutBufferUpdated,
    payoutBufferValid,
    tripCost
}) => {
    const noPayout = ['', '0', '000'].includes(payoutBuffer);
    const parsedPayoutBuffer = !noPayout ? CurrencyInputService.parse(payoutBuffer) : payoutBuffer;
    const isValidPayout = parseInt(parsedPayoutBuffer) >= tripCost ? true : false;
    const estimatedPayoutProfit = parseInt(parsedPayoutBuffer) - tripCost;
    const getSmallTextErrorStyle = () => theme.getAbstractSmallTextError(theme);
    const [ smallTextErrorStyle, setSmallTextErrorStyle ] = useState( getSmallTextErrorStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSmallTextErrorStyle( getSmallTextErrorStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <h4>
                Payout
            </h4>

            <div>
                <span>
                    Payout
                </span>
                <input type="text" value={payoutBuffer} onChange={onPayoutBufferUpdated} />
                {
                    !isValidPayout && 
                        <div style={smallTextErrorStyle}>
                            Are you sure you want to payout less than estimated costs?
                        </div>
                }
                {
                    !payoutBufferValid && 
                        <div style={smallTextErrorStyle}>
                            Specify a valid payout amount
                        </div>
                }
            </div>

            <div>
                { 
                    noPayout ? 
                        <div style={smallTextErrorStyle}>
                            No payout amount specified
                        </div> : 
                            <div>
                                { `Payout: ${CurrencyService.usd(parsedPayoutBuffer, false)}` }
                            </div>
                }
            </div>

            {
                noPayout ? '' : (
                    isValidPayout ? 
                    <div>
                        { `Estimated Profit: ${CurrencyService.usd(estimatedPayoutProfit, false)}` }
                    </div> : 
                        <div style={smallTextErrorStyle}>
                            Projected payout is less than estimated costs
                        </div>
                )
            }
        </div>
    );
};

export default Payout;
