import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import ResizeService from './../../../service/resize';
import CreatePayAccountPrompt from './../../prompt/create-pay-account';
import FinishPayAccountPrompt from './../../prompt/finish-pay-account';
import UpdatePayAccountPrompt from './../../prompt/update-pay-account';
import { NavLink } from 'react-router-dom';

const Copy = (parms) =>
    <p>
        Cash, USPS postal payments, trade, and free exchange options are available without fees.
        <br />
        We also offer online payments.  
        <br />
        Online payments are received from other users via our platform and paid out into your bank account after your exchange has completed.  
        <br />
        Transactions are deducted a processing fee.  
        See our &nbsp;
        <NavLink to="/fees" style={{
            ...theme.getGeneralTextLinkStyle(theme),
        }}>
            online payment fees
        </NavLink> 
        &nbsp; for more details.
    </p>


const shortcutList = [
    {
        title: 'Payout Accounts',
        url: '/payouts',
        details: 'Manage your bank accounts that receive money from Bears.Exchange'
    },
    {
        title: 'Payment Methods',
        url: '/payment/methods',
        details: 'Manage your payment methods used to send online payments'
    },
    {
        title: 'Payout Transactions',
        url: '/transactions/list',
        details: 'Track your online income payouts'
    },
    {
        title: 'Chargeback Fees',
        url: '/profile/fees',
        details: 'Review past and current chargeback fees'
    }
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme)
        }}>
            <h3>
                { title }
            </h3>
            <p>
                { details }
            </p>
        </div>
    </NavLink>

const Shortcuts = (parms) => {
    const getContainerStyle = () => theme.getProfilePeopleShortCutOuterContainerStyle(theme);
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={containerStyle}>
            {
                shortcutList.map((cut, index) => 
                    <Shortcut 
                        details={cut.details} 
                        title={cut.title} 
                        url={cut.url} 
                        key={index} 
                    />)
            }
        </div>
    );
};

const SetupIncompleteView = ({ 
    onAccountCreated, 
    containerStyle,
    bodyStyle
}) => 
    <section>
        <div style={containerStyle}>
            <h2>
                Finish your online payments setup
            </h2>
        </div>
        <div style={bodyStyle}>
            <div style={{
                ...theme.getProfilePwdContainerStyle(theme),
            }}>
                <p>
                    You need to finish setting up your online payment details before you can receive online payments.
                    Have you already finished? A delay may exist before your online payment setup is live.  
                    If you've completed all setup steps, try waiting a moment and then refreshing the page.
                </p>
                
                <Copy />
                
                
                <FinishPayAccountPrompt onAccountCreated={onAccountCreated} />

                <Shortcuts />
            </div>
        </div>
    </section>
const SetupAccountView = ({ 
    onAccountCreated, 
    containerStyle,
    bodyStyle
}) => 
    <section>
        <div style={containerStyle}>
            <h2>
                Setup to receive online payments
            </h2>
        </div>
        <div style={bodyStyle}>
            <div style={{
                ...theme.getProfilePwdContainerStyle(theme),
            }}>   
                <Copy />
                
                <CreatePayAccountPrompt onAccountCreated={onAccountCreated} />

                <Shortcuts />
            </div>
        </div>
    </section>

const PayAccountView = ({ 
    onAccountCreated, 
    containerStyle,
    bodyStyle
}) => 
    <section>
        <div style={containerStyle}>
                <h2>
                    Manage Online Payments
                </h2>
        </div>
        <div style={bodyStyle}>
            <div style={{
                ...theme.getProfilePwdContainerStyle(theme),
            }}>
                <UpdatePayAccountPrompt onAccountUpdated={onAccountCreated} />

                <Shortcuts />
            </div>
        </div>
    </section>

const ProfileIncomeLandingViewPanel = ({ data, onAccountCreated }) => {
    const getContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    
    const getBodyStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getExchangesLandingContainerStyle(theme)
        }}>
            {
                data.account.created ? (
                    data.account.accountSetupComplete ? 
                        <PayAccountView 
                            containerStyle={containerStyle}
                            bodyStyle={bodyStyle}
                            onAccountCreated={onAccountCreated} 
                        /> : 
                            <SetupIncompleteView 
                                onAccountCreated={onAccountCreated} 
                                containerStyle={containerStyle}
                                bodyStyle={bodyStyle}
                            />
                ) :  
                    <SetupAccountView 
                        onAccountCreated={onAccountCreated} 
                        containerStyle={containerStyle}
                        bodyStyle={bodyStyle}
                    />
            }
        </div>
    );
};

export default ProfileIncomeLandingViewPanel;
