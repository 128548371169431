import React from 'react';
import { PlatformsViewPanel } from './../../../user-detail/view';
import Empty from './empty';
import LoadedContainer from './loaded-container';
import DetailPanel from './../../../core/detail';
import UserService from './../../../../../service/user';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../../store/map/on-logout';

class _WidgetTrailsPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind();

        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserService.getUserDetails({
            request: {},
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({ });
    }

    onLoadDataResponse(response)
    {
        const nextState = {
            ...this.state,
            contactingServer: false,
            message: '',
            data: {
                trailList: response.user.trailList
            }
        };
        this.setState(nextState);
    }

    getLoadedDataDetailUi()
    {
        return (
            <LoadedContainer>
                {
                    this.state.data && 
                    this.state.data.trailList && 
                    this.state.data.trailList.length > 0 ? 
                        <PlatformsViewPanel trailList={this.state.data.trailList} /> : 
                            <Empty />      
                }
            </LoadedContainer>
        );
    }
}

const WidgetTrailsPanel = connect(mapStateToProps, mapDispatchToProps)(_WidgetTrailsPanel);

export default WidgetTrailsPanel;
