import React from 'react';
import theme from './../../../theme';
import {
    BX_SUBSCRIPTION_ACTIVE,
    BX_SUBSCRIPTION_CANCELLED
} from './../../../prompt/core/enum/bx-subscription-state';
import JoinCopy from './join-copy';
import Active from './active';
import Dialog from '@mui/material/Dialog';
import ResizeService from './../../../../service/resize';
import { useState, useEffect } from 'react';

const Onboarding = ({ status, onStartSubscription }) => 
    <section>
        <JoinCopy />
        <button 
            className="test-subscriptionOnboardingStartBtn"
            onClick={event => { onStartSubscription() }}
        >
            Start Subscription
        </button>
    </section>

const Cancelled = ({ status, onStartSubscription }) => {
    const [ confirmRestoreDialogOpen, setConfirmRestoreDialogOpen ] = useState(false);
    
    const getPanelStyle = () => theme.getProfilePwdPanelStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPanelStyle( getPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <JoinCopy />
            <button 
                className="test-subscriptionRestoreBtn"
                onClick={
                    /* istanbul ignore next */
                    event => { setConfirmRestoreDialogOpen(true)  }
                }
            >
                Restore Subscription
            </button>
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setConfirmRestoreDialogOpen(false) }
                }
                open={confirmRestoreDialogOpen}
            >
                <div style={panelStyle}>
                    <p>
                        Restoring a cancelled subscription will create a new charge.
                    </p>
                </div>
                <button onClick={
                    /* istanbul ignore next */
                    event => {
                        onStartSubscription();
                        setConfirmRestoreDialogOpen(false);
                    }
                }>
                    Restore Subscription
                </button>
            </Dialog>
        </section>
    );
};

const NoRecord = ({ status, onStartSubscription }) => 
    <section>
        <JoinCopy />
        <button 
            className="test-subscriptionStartBtn"
            onClick={event => { onStartSubscription() }}
        >
            Start Subscription
        </button>
    </section>

const Record = ({ status, onStartSubscription, onCancelSubscription }) => status.status === BX_SUBSCRIPTION_ACTIVE ? <Active status={status} onCancelSubscription={onCancelSubscription} /> : (
    status.status === BX_SUBSCRIPTION_CANCELLED ? <Cancelled status={status} onStartSubscription={onStartSubscription} /> : 
        <Onboarding status={status} onStartSubscription={onStartSubscription} />
);


const Status = ({ status, onStartSubscription, onCancelSubscription }) => status.hasRecord ? 
    <Record 
        status={status} 
        onStartSubscription={onStartSubscription} 
        onCancelSubscription={onCancelSubscription}
    /> : 
        <NoRecord 
            status={status} 
            onStartSubscription={onStartSubscription} 
        />

const SubscriptionViewPanel = ({ 
    data: status,
    onStartSubscription,
    onCancelSubscription
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <Status 
            status={status} 
            onStartSubscription={onStartSubscription} 
            onCancelSubscription={onCancelSubscription}
        />
    </div>

export default SubscriptionViewPanel;
