export const getGeneralBackButtonStyle = (theme) => ({
    marginRight: '3rem'
});

export const getGeneralButtonStyle = (theme, icon) => ({
    width: '2.75rem',
    height: '2.75rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
    margin: '.5rem'
});

export const getGeneralButtonInlineStyle = (theme) => ({
    width: '2.5rem',
    height: '2.5rem',
    cursor: 'pointer',
    margin: '.5rem'
});

export const getGeneralButtonTextStyle = (theme) => ({
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '12pt',
    lineHeight: '.9rem',
    margin: '.75rem',
});