import React from 'react';
import { useParams } from 'react-router-dom';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import config from './../config';
import theme from './../ui/theme';
import CarrierGroupUserTripsPanel from './../ui/panel/carrier-group-user-trips';

const CarrierGroupUserTripsPage = () => {
    const { carrierGroupId } = useParams();
    return <AuthPageTemplate childMenu={ <ProfileMenu /> }>
        {
            /* istanbul ignore next */
            config.features.allowCarrierGroups ? 
                <div style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    <CarrierGroupUserTripsPanel
                        carrierGroupId={carrierGroupId}
                    />
                </div> : 
                        <p style={{
                            ...theme.getGeneralTextStyle()
                        }}>
                            This feature is currently disabled
                        </p>
        }

    </AuthPageTemplate>
};

export default CarrierGroupUserTripsPage;
