import UiService from './../../../service/ui';
import CurrencyService from './../../../service/currency';
import payFineTypeEnum from './../core/enum/pay-fine-type';
import PayPrompt from './../pay';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';

const getSourceLink = (fineRecord) => <NavLink 
                                        style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                        to={`/exchange/${fineRecord.sourceId}`}
                                      >
                                        Exchange
                                    </NavLink>

export const Header = (parms) => {
    return (
        <thead>
            <tr>
                <td style={{
                    ...theme.getAdminDataCellStyle(theme)
                }}>
                    Actions
                </td>
                <td style={{
                    ...theme.getAdminDataCellStyle(theme)
                }}>
                    Type
                </td>
                <td style={{
                    ...theme.getAdminDataCellStyle(theme)
                }}>
                    Source
                </td>
                <td style={{
                    ...theme.getAdminDataCellStyle(theme)
                }}>
                    Amount
                </td>
                <td style={{
                    ...theme.getAdminDataCellStyle(theme)
                }}>
                    Status
                </td>
                <td style={{
                    ...theme.getAdminDataCellStyle(theme)
                }}>
                    Last Update
                </td>
            </tr>
        </thead>
    );
};

export const UserSearchFineViewPrompt = ({ 
    fineRecord, 
    isSelectedFine, 
    removeFineById, 
    addFineById,
    onContactingServerResponse,
    onContactingServer,
    setPayClientSecret
}) => {
    const hasPendingAutoPay = !fineRecord.finePaid && fineRecord.canAutoPay && fineRecord.pendingProcessing;
    const promptPay = !fineRecord.finePaid && (
        ( fineRecord.canAutoPay && fineRecord.autoPayFailed ) || !fineRecord.canAutoPay 
    );
    /*
    const onToggleEnabled = event => {
        if(isSelectedFine)
        {
            removeFineById(fineRecord.id);
        }
        else
        {
            addFineById(fineRecord.id);
        }
    };
    */
    const displayPrice = CurrencyService.getDisplayPrice(fineRecord.currency, fineRecord.total, false);
    const feeTitle = fineRecord.type === payFineTypeEnum.PAY_FINE_TYPE_DISPUTE ? 'Lost Dispute' : 'General';
    return (
        <tr>
            <td>
                {
                    promptPay && !hasPendingAutoPay && 
                        <PayPrompt
                            buttonStyle={{
                                ...theme.getGeneralTextStyle(theme)
                            }}
                            closedButtonText={`Pay ${displayPrice}`}
                            openPromptTitle={`${feeTitle} Fee Payment`}
                            checkoutButtonText={'Pay'}
                            omitOrderDetails={true}
                            onContactingServerResponse={onContactingServerResponse}
                            onContactingServer={onContactingServer}
                            setPayClientSecret={setPayClientSecret}
                            content={fineRecord}
                            contentType={fineRecord.type} 
                        />
                }

                {
                    hasPendingAutoPay && 
                        <span>
                            Pending Auto Pay With 
                            <NavLink 
                                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                                to="/payment/methods"
                            >
                                Default Payment Method
                            </NavLink>
                        </span>
                }

            </td>
            <td style={{
                    ...theme.getAdminDataCellStyle(theme)
            }}>
                { feeTitle }
            </td>
            <td style={{
                    ...theme.getAdminDataCellStyle(theme)
            }}>
                { getSourceLink(fineRecord) }
            </td>
            <td style={{
                    ...theme.getAdminDataCellStyle(theme)
            }}>
                { displayPrice }
            </td>
            <td style={{
                    ...theme.getAdminDataCellStyle(theme)
            }}>
                {
                    fineRecord.finePaid ? 'Paid' : 'UnPaid'
                }
            </td>
            <td style={{
                    ...theme.getAdminDataCellStyle(theme)
            }}>
                { UiService.getDateReport(fineRecord.updatedOn) }
            </td>
            
        </tr>
    );
};