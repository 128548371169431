import React from 'react';
import AvailableForDeliveryCarrierGroupsPanel from './../available-for-delivery-carrier-groups';

const SetupInNetworkDelivery = ({
    delivery
}) => {

    return (
        <div>
                        
            <AvailableForDeliveryCarrierGroupsPanel delivery={delivery} />
            
        </div>
    );
};

export default SetupInNetworkDelivery;