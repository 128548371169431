import React from 'react';

export const getAvailableWidgetOuterContainerStyle = (theme) => ({
    textAlign: 'center',
});

export const getAvailableWidgetContainerStyle = (theme) => ({
    display: 'inline-block',
    marginLeft: '1.5rem',
    marginTop: '.75rem',
    marginRight: '1.5rem',
    borderColor: theme.panelSecondary,
    borderStyle: 'solid',
    borderWidth: '.2rem 0 0 0',
    padding: '.25rem',
    textAlign: 'center',
    width: 'calc(100% - 3rem)',
    verticalAlign: 'top',
    maxWidth: theme.isTablet() ? '20rem' : 'auto' ,
    height: theme.isTablet() ? '8rem' : 'auto', 
});


export const getAvailableWidgetButtonStyle = (theme, icon) => ({
    padding: '0 .25rem',
    marginTop: '.5rem',
    width: '2.5rem',
    height: '2.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
    float: 'right',
});

export const getAvailableWidgetSpacerStyle = (theme) => ({
    padding: '0 .25rem',
    marginTop: '.5rem',
    width: '2.5rem',
    height: '2.5rem',
    float: 'left',
});

export const getAvailableWidgetButtonUi = ({ theme, onClick, icon }) => 
    <div style={{
        ...getAvailableWidgetButtonStyle(theme, icon),
    }} onClick={onClick}></div> 
