import React from 'react';
import theme from '../../theme';
import AddCarrierMemberPrompt from './../../prompt/add-carrier-member';

const SetupCreatorMember = ({ 
    carrierGroup,
    onMemberAdded
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)  
    }}>
        <h2>
            Setup your member profile for { carrierGroup.name }
        </h2>
        
        <AddCarrierMemberPrompt
            selectedUserId={carrierGroup.creatorUserId}
            carrierGroupId={carrierGroup.id}
            onCarrierMemberAdded={onMemberAdded}
        />

    </div>

export default SetupCreatorMember;
