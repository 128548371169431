import React from 'react';
import DetailPanel from './../core/detail';
import UserService from './../../../service/user';
import UserSettingsViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _UserSettingsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.onUpdateUserSetting = this.onUpdateUserSetting.bind(this);
        this.onUserEmailConsent = this.onUserEmailConsent.bind(this);
        this.refreshClientSettings = this.refreshClientSettings.bind(this);
        this.onLoadClientSettings = this.onLoadClientSettings.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        UserService.getSettings({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onUpdateUserSetting(request)
    {
        super.loadData({ });
        UserService.applySettingUpdate({
            request,
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onLoadClientSettings(response)
    {
        this.props.onLoadClientSettings(response);
    }

    refreshClientSettings()
    {
        UserService.getUserClientSettings({
            onResponse: this.onLoadClientSettings,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadDataResponse(response)
    {
        super.onLoadDataResponse(response);
        if(response.refreshClientSettings)
        {
            this.refreshClientSettings();
        }
    }

    /* istanbul ignore next */
    onUserEmailConsent()
    {
        super.loadData({ });
        UserService.consentToEmail({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({

        });
    }

    getLoadedDataDetailUi()
    {
        return <UserSettingsViewPanel 
            data={this.state.data} 
            reloadData={this.loadData}
            onUpdateUserSetting={this.onUpdateUserSetting} 
            onUserEmailConsent={this.onUserEmailConsent}
        />
    }
}

const UserSettingsPanel = connect(mapStateToProps, mapDispatchToProps)(_UserSettingsPanel);
export default UserSettingsPanel;
