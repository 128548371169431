import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

const PrivacyCopyPanel = (parms) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                Privacy Policy
            </h2>
            <div style={bodyStyle}>
                Effective Date: 9/01/2023.
                <br />
                <br />

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Introduction
                </h3>

                <p>
                    Bears Exchange LLC owns and operates an online platform called “Bears.Exchange” at bears.exchange on which users may exchange goods and services (the “Site”).  In order to deliver these services, we must collect certain personal information about you.
                </p>

                <p>
                    The purpose of this privacy policy is to inform you about the types of data gathered, how the information collected is used, and how you can update your communications preferences.  By visiting bears.exchange, you are accepting the practices of this privacy policy.  Please read this policy carefully to understand how we handle your Personally Identifiable Information (PII).
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Collection of Information
                </h3>

                <p>
                    Bears Exchange LLC collects information automatically when you visit our website, and any information you willingly provide when you contact us via contact form or sign up for our newsletter.  We do not use cookies for tracking purposes. 
                </p>

                <p>
                    Information we collect may include:
                </p>
                    
                <ul style={{
                    ...theme.getGeneralDefaultBulletStyle(theme),
                }}>
                    <li>
                        IP address.
                    </li>
                    <li>
                        Location (city and country).
                    </li>
                    <li>
                        Computer and device information (OS, mobile device type, browser and version used, service provider, and other technical identifiers).
                    </li>
                    <li>
                        Demographics (age).
                    </li>
                    <li>
                        Interests.
                    </li>
                    <li>
                        Referring URLs, keywords used to reach our site, pages of our site that you visited, and the order in which you browsed our site.
                    </li>
                    <li>
                        Behavior (date and time you visited our site, frequency of your visits, duration of time you spent on our site).
                    </li>
                </ul>

                <p>
                    Information you voluntarily submit online may include:
                </p>

                <ul style={{
                    ...theme.getGeneralDefaultBulletStyle(theme),
                }}>
                    <li>
                        Personal (name, email address, phone number, company you work for, and any information you include in your comments).
                    </li>
                </ul>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Use of Information
                </h3>

                <p>
                    Bears Exchange LLC uses information collected in the following ways to:
                </p>

                <ul style={{
                    ...theme.getGeneralDefaultBulletStyle(theme),
                }}>
                    <li>
                        Enable you to use the Site to fund and/or purchase a product or service (“Item”) via the Site.
                    </li>
                    <li>
                        Improve the Site to better meet your needs.
                    </li>
                    <li>
                        Improve user experience on the Site.
                    </li>
                    <li>
                        Respond to your inquiries or comments.
                    </li>
                    <li>
                        Provide you with requested notifications or other marketing materials.
                    </li>
                </ul>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Disclosure of Information
                </h3>

                <p>
                    We understand we have a responsibility to handle sensitive personal data carefully and consider it confidential, unless we have your permission to share or publish information.  We do not sell or trade the information we collect to third parties; however, we may disclose certain pertinent information to third parties to assist us in providing our services you requested.  This may include outside consultants, vendors, or independent contractors with whom we acquire assistance.  We will also provide information to authorities if required by law.  
                </p>

                <p>
                    Bears Exchange LLC uses various social media platforms for branding and publicity.  Please use care when posting comments or personal information on our company pages.  This information is open to the public.  
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Data Security
                </h3>

                <p>
                    Bears Exchange LLC uses a variety of administrative practices and industry standard technical safeguards to ensure the security of your information.  Although we use the necessary technology to protect your information, internet transmissions are never completely secure.  Security measures we may administer include, but are not limited to:
                </p>

                <ul style={{
                    ...theme.getGeneralDefaultBulletStyle(theme),
                }}>
                    <li>
                        Firewalls;
                    </li>
                    <li>
                        Proxy servers;
                    </li>
                    <li>
                        SSL & encryption;
                    </li>
                    <li>
                        Username & password management;
                    </li>
                    <li>
                        Network access controls;
                    </li>
                    <li>
                        Confidentiality Agreements;
                    </li>
                    <li>
                        Two-step email security; and
                    </li>
                    <li>
                        Anti-virus software.
                    </li>
                </ul>

                <p>
                    In the event of a data breach, if we suspect any of your personal information may have been accessed, you will be notified within seven (7) business days of us discovering the breach.  You will be contacted via email, and a notice will be posted to our website.  
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Your Privacy Choices
                </h3>

                <p>
                    If you prefer not to receive notifications or marketing materials from Bears Exchange LLC, you can specify this at bears.exchange/#/unsubscribe or email us at staff@bears.exchange, and we will remove you from our mailing list. 
                </p>

                <p>
                    You have the right to access, change, or request removal of your personally identifiable information.  Just notify us at bears.exchange/#/profile/report/issue or email staff@bears.exchange with your request.
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Third Party Links
                </h3>

                <p>
                    Bears Exchange LLC uses third party links on its website.  These include links to our partners, customers, news articles, and any other information we believe you might appreciate.  We encourage you to review the privacy policies of each of those websites before providing any information to them.
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Changes to Our Privacy Policy
                </h3>

                <p>
                    Bears Exchange LLC reserves the right to change this Privacy Policy at any time.  You can view changes to this policy on this page.
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Children Online Privacy Protection Act (COPPA)
                </h3>

                <p>
                    Bears Exchange LLC complies with the Children’s Online Privacy Protection Act (COPPA).  We do not market to children under the age of 13 years old or willingly collect personal information from children.  Please contact us if you believe we have unintentionally acquired information about your child.  We are happy to investigate and delete information if we find it.
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    California Online Privacy Protection Act (CalOPPA)
                </h3>

                <p>
                    Bears Exchange LLC complies with CalOPPA, and we agree to the following:
                </p>

                <ul style={{
                    ...theme.getGeneralDefaultBulletStyle(theme),
                }}>
                    <li>
                        You can visit our website anonymously.
                    </li>
                    <li>
                        The link to this Privacy Policy is easily identifiable and contains the word “Privacy.”
                    </li>
                    <li>
                        Any changes to our Privacy Policy will be posted on our Privacy Policy page.
                    </li>
                    <li>
                        You have the right to change your personal information and can do so by  emailing us.
                    </li>
                    <li>
                        We adhere to the practices included in this Privacy Policy.
                    </li>
                </ul>

                <p>
                    For more information, you can visit the CFC’s website: https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/#sthash.0FdRbT51.dpuf. 
                </p>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    EU Data Privacy Directive and General Data Protection Regulation
                </h3>

                <p>
                    According to the rules and regulations outlined in the European Data Privacy Directive and changes in the General Data Protection Regulation, Bears Exchange LLC agrees to the following:
                </p>

                <ul style={{
                    ...theme.getGeneralDefaultBulletStyle(theme),
                }}>
                    <li>
                        It is as easy to withdraw your consent as it is to give consent for us to have and retain your information.  Just email us to change or remove your information from our database.
                    </li>
                    <li>
                        You have the right to access the information we have about you.  Email us for access to this information.  If you wish for us to change or remove the information from our database, we are happy to do so.
                    </li>
                    <li>
                        In the event of a data breach, you will be notified within seven (7) business days of us becoming aware of the breach both via email and a notification posted to our website.
                    </li>
                </ul>

                <h3 style={{
                    ...theme.getGeneralTextStyle(theme),
                }}>
                    Contact Information
                </h3>

                <p>
                    If you have any questions or concerns regarding our Privacy Policy, please email us at staff@bears.exchange.
                </p>

            </div>
        </div>
    );
};

export default PrivacyCopyPanel;
