import React from 'react';
import AuthPageTemplate from './template/auth-page';
import AddAttSetPrompt from './../ui/prompt/add-att-set';
import ProfileMenu from './../ui/menu/profile';

const AddAttSetPage = () => {
    return (
        <AuthPageTemplate 
            childMenu={
                <div>
                    <ProfileMenu />
                </div>
            }
        >
            <AddAttSetPrompt />
        </AuthPageTemplate>
    );
};

export default AddAttSetPage;
