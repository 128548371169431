import React from 'react';
import { useState } from 'react';
import theme from './../../../theme';
import CreateCarrierGroupTripPrompt from './../../../prompt/create-carrier-group-trip';
import Dialog from '@mui/material/Dialog';

const NoAcceptingTrips = ({
    carrierGroupId,
    onAddTrip
}) => {
    const [ viewAddPrompt, setViewAddPrompt ] = useState(false);
    /* istanbul ignore next */
    const onAdded = response => {
        onAddTrip();
        setViewAddPrompt(false);
    };
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <p>
                <span>
                    No scheduled trips are available.  
                </span>
                
                <button 
                    className="test-carrierGroupPlanDeliveryAlignTripsNoAcceptingTripsScheduleBtn"
                    onClick={event => { setViewAddPrompt(true) }}
                >
                    Schedule a trip
                </button>
            </p>

            <Dialog
                onClose={
                    /* istanbul ignore next */
                    event => { setViewAddPrompt(false) }
                }
                open={viewAddPrompt}
            >
                <h2>
                    Add a new trip
                </h2>

                <CreateCarrierGroupTripPrompt
                    onTripAdded={onAdded}
                    carrierGroupId={carrierGroupId}
                />
            </Dialog>

        </div>
    );
};

export default NoAcceptingTrips;
