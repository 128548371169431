import React from 'react';
import CurrencyService from './../../../../service/currency';
import config from './../../../../config';
import { NavLink } from 'react-router-dom';
import UiService from './../../../../service/ui';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import {
    CURRENCY_TYPE_USD
} from './../../../prompt/core/enum/currency-type';
import theme from './../../../theme';

const FailedPayment = () => 
    <div>
        <h2>
            Your subscription payment has failed.  
        </h2>
        <p>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to="/payment/method/add"
            >
                Add a new payment method
            </NavLink> 
            <br />
            or select an existing 

            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to="/payment/methods"
            >
                payment method
            </NavLink> 
            <br />
            and set it for use with your subscription before your subscription cancels.
        </p>
    </div>


const Active = ({ status, onCancelSubscription }) => {
    const [ modalOpen, setModalOpen ] = useState(false);
    return (
        <section>
            <div>
                {
                    status.paymentFailed && 
                        <FailedPayment />
                }
                { 
                    !status.paymentFailed && 
                    status.nextChargeDate !== '' &&  
                        <div>
                            <h2>
                                Thank you for your subscription!
                            </h2>
                            <p>
                                <strong>
                                    <span>
                                        Next charge of &nbsp;
                                    </span>
                                    <span>
                                        { 
                                            CurrencyService.getDisplayPrice(
                                                CURRENCY_TYPE_USD,
                                                config.pay.subscriptionPrice
                                            )
                                        }
                                        &nbsp;
                                    </span>
                                    <span>
                                        on &nbsp;
                                        { UiService.getDateReport(status.nextChargeDate) }
                                    </span>
                                </strong>
                            </p>
                        </div>
                }

                <p style={{ 
                    ...theme.getGeneralTextLinkStyle(theme),
                    ...theme.getGeneralTextStyle(theme),
                    ...theme.getGeneralBottomSpacerStyle(theme)
                }}>
                    <NavLink 
                        to="/profile/report/issue"
                    >
                        Report an issue
                    </NavLink>
                </p>
                <p>
                    You can also contact us at &nbsp;
                    <a href="mailto:staff@bears.exchange">staff@bears.exchange</a>
                </p>
                <button 
                    className="test-subscriptionCancelBtn"
                    onClick={event => { setModalOpen(true) }}
                >
                    Cancel Subscription
                </button>
            </div>
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setModalOpen(false) } 
                }
                open={modalOpen}
            >
                <div style={{
                    ...theme.getSubscriptionActiveCancelModalContainerStyle(theme)
                }}>
                    <div style={{
                        ...theme.getConfirmCancelSubscriptionTextStyle(theme)
                    }}>
                        Are you sure you would like to cancel your subscription?
                    </div>

                    <div>
                        <div style={{
                            ...theme.getSubscriptionActiveCancelModalButtonContainerStyle(theme)  
                        }}>
                            <button 
                                className="test-subscriptionYesCancelBtn"
                                onClick={
                                    /* istanbul ignore next */
                                    event => { onCancelSubscription() }
                                }
                            >
                                Yes, Cancel Subscription
                            </button>
                        </div>
                        <div style={{
                          ...theme.getSubscriptionActiveCancelModalButtonContainerStyle(theme)  
                        }}>
                            <button 
                                className="test-subscriptionNoKeepBtn"
                                onClick={
                                    /* istanbul ignore next */
                                    event => { setModalOpen(false) }
                                }
                            >
                                No, Keep My Subscription
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </section>
    );
};

export default Active;
