import React from 'react';
import AuthPageTemplate from './template/auth-page';
import OnlineTransactionsListPanel from './../ui/panel/online-transactions-list';
import ProfileMenu from './../ui/menu/profile';

const TransactionsPage = (parms) => 
    <AuthPageTemplate 
        childMenu={
            <div>
                <ProfileMenu />
            </div>
    }>
        <OnlineTransactionsListPanel />
    </AuthPageTemplate>

export default TransactionsPage;
