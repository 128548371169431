export const USER_LOCATION_TYPE_RESIDENTIAL = 'residential';
export const USER_LOCATION_TYPE_COMMERCIAL = 'commercial';

const validValues = [
    USER_LOCATION_TYPE_RESIDENTIAL,
    USER_LOCATION_TYPE_COMMERCIAL
];

const isValid = (type) => validValues.includes(type);

const getDropDownValues = () => [
    {
        name: 'Residential Address',
        value: USER_LOCATION_TYPE_RESIDENTIAL
    },
    {
        name: 'Commercial Address',
        value: USER_LOCATION_TYPE_COMMERCIAL
    }
];

const UserLocationTypeEnum = {
    USER_LOCATION_TYPE_RESIDENTIAL,
    USER_LOCATION_TYPE_COMMERCIAL,
    isValid,
    getDropDownValues
};

export default UserLocationTypeEnum;
