import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminUsersPanel from './../ui/panel/admin-users';
import theme from './../ui/theme';

const AdminUsersPage = () => 
    <AdminPageTemplate>
        <section style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <h2>
                Admin Users
            </h2>
            
            <p>
                Support User Needs
            </p>

            <AdminUsersPanel />

        </section>
    </AdminPageTemplate>

export default AdminUsersPage;
