export const getExchangeSearchResultTileContainerStyle = (theme) => ({
    width: theme.isTablet() ? 'fit-content' : 'calc(100% - 1.5rem)',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    padding: '0 .5rem 1rem'
});

export const getExchangeSearchResultTimeStyle = (theme) => ({
    marginBottom: '1rem',
});

export const getExchangeSearchSingleFieldContainerStyle = (theme) => ({
    margin: '0 auto 1rem auto', 
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    width: '100%',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    display: 'flex',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    alignItems: 'center'
});
export const getExchangeSearchResultCenterStyle = (theme) => ({
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
});

export const getExchangeSearchResultTileStyle = (theme) => ({
    display: 'flex',
    margin: '0 0 .75rem',
    width: theme.isTablet() ? '19rem' : '100%',
    boxShadow: '0 .05rem .05rem rgba(29, 29, 29, .3)',
    flexDirection: theme.isTablet() ? 'row' : 'column',
});

export const getExchangeSearchResultDetailsStyle = (theme) => ({
    width: theme.isTablet() ? '20rem' : '100%',

});

export const getExchangeSearchResultNoExchangeStyle = (theme) => ({
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '700',
    padding: '0 0 .75rem 0',
});