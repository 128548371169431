import React from 'react';
import UnknownRenderType from './unknown';
import GeneralRenderType from './general';
import VideoRenderType from './video';
import { 
    ONBOARDING_RENDER_TYPE_GENERAL,
    ONBOARDING_RENTER_TYPE_VIDEO
} from '../../../../../service/onboarding/render-types';

const FrameContent = ({
    selectedFrame,
    updateCrawler,
    exitOnboarding
}) => selectedFrame.frameDetails.renderType === ONBOARDING_RENDER_TYPE_GENERAL ? 
    <GeneralRenderType
        selectedFrame={selectedFrame}
        updateCrawler={updateCrawler}
        {
            ...selectedFrame.frameDetails.frameParms
        }
        moreOptions={selectedFrame.moreOptions}
    /> : (
        selectedFrame.frameDetails.renderType === ONBOARDING_RENTER_TYPE_VIDEO ? 
            <VideoRenderType
                selectedFrame={selectedFrame}
                {
                    ...selectedFrame.frameDetails.frameParms
                }
                onVideoFinished={exitOnboarding}
            /> : 
                <UnknownRenderType 
                    selectedFrame={selectedFrame} 
                />
    )

export default FrameContent;
