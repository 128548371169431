import endpointRequest from './endpoint-request';
import endpointFileUpload from './endpoint-file-upload';
import config from './../config';
import ErrorService from './error';

class OfferService {

    static getRetiredOfferDetailsRequest(offerId)
    {
        return {
            offerId,
        };
    }

    /* istanbul ignore next */
    static getRetiredOfferDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.offer.retired,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getOfferDetailsRequest(offerId) {
        return {
            offerId
        };
    }
    
    /* istanbul ignore next */
    static getOfferDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.offer.details,
            request,
            onResponse, 
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getUserListingsRequest(userId, page = 0) {
        return {
            detailsUserId: userId,
            page,
        };
    }

    /* istanbul ignore next */
    static getUserListings({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.listings.offerListings,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getAddOfferRequest(title, details, rules, savedImgList, hasRefundPolicy, refundPolicyDetails, saveRefundPolicy) {
        return {
            title,
            details,
            rules,
            savedImgList,
            hasRefundPolicy,
            refundPolicyDetails,
            saveRefundPolicy
        };
    }

    /* istanbul ignore next */
    static addOffer({
        request, 
        fileList, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointFileUpload({
            url: config.url.offer.add,
            request,
            fileList,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getArchiveOfferRequest(offerId) {
        return {
            offerId
        };
    }

    /* istanbul ignore next */
    static archiveOffer({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }){
        endpointRequest({
            url: config.url.offer.archive,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getUpdateOfferRequest(offerId, title, details, rules) {
        return {
            offerId,
            title,
            details,
            rules
        };
    }

    /* istanbul ignore next */
    static updateOffer({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.offer.update,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSearchRequest(searchText = '', page = 0) {
        return {
            searchText,
            page
        };
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.offer.search,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
            debounce: true
        });
    }

    static getArchivesRequest(page = 0)
    {
        return {
            page,
        };
    }

    /* istanbul ignore next */
    static getArchives({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.user.archives.offers,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getSetupAddRequest()
    {
        return {};
    }

    /* istanbul ignore next */
    static setupAdd({
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    }) 
    {
        endpointRequest({
            url: config.url.offer.setupAdd,
            request: this.getSetupAddRequest(),
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default OfferService;
