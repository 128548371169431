import React from 'react';
import TagService from './../../../../service/tag';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD, AbstractPrompt } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';
import theme from './../../../theme';

class _AdminAddTagPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _AdminAddTagPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.loadTags = this.loadTags.bind(this);
        this.onLoadTagsResponse = this.onLoadTagsResponse.bind(this);
        this.getTagListUi = this.getTagListUi.bind(this);
        this.getPopulatedTagListUi = this.getPopulatedTagListUi.bind(this);
        this.getEmptyTagListUi = this.getEmptyTagListUi.bind(this);
        this.getTagUi = this.getTagUi.bind(this);
        this.onRemoveTag = this.onRemoveTag.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            title: {
                isValid: ValidateService.valueRequired
            }
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            title: {
                name: 'Tag Title*',
                error: 'Provide a tag title',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            }
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            tagList: []
        };
    }

    /* istanbul ignore next */
    onRemoveTag(tag)
    {
        const confirmed = window.confirm('Delete Tag?');
        if(confirmed)
        {
            this.setContactingServer(true);
            TagService.remove({
                request: {
                    id: tag.id
                },
                onResponse: this.onLoadTagsResponse,
                onError: this.onServerError,
                authToken: this.props.authToken,
                onAuthError: this.onAuthError,
                onFatalError: this.onFatalError,
                onNetworkOffline: this.onNetworkOffline
            });
        }
    }

    loadTags()
    {
        this.setContactingServer(true);
        TagService.list({
            onResponse: this.onLoadTagsResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadTagsResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        nextState.requestComplete = false;
        nextState.tagList = response.list;
        this.setState(nextState);
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    componentDidMount()
    {
        this.loadTags();
    }

    onSendToServer()
    {
        TagService.add({
            request: this.getServerRequest(),
            onResponse: this.onLoadTagsResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            title: this.state.title.value
        };
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Tag Added')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Add')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getTagUi(aTag, index)
    {
        return (
            <div 
                style={{
                    ...theme.getAdminTagLineStyle(theme)
                }}
                key={index}
            >
                
                <button 
                    onClick={event => { this.onRemoveTag(aTag) }}
                    className="test-adminAddTagRemoveBtn"
                    style={{
                        ...theme.getAdminTagLineButtonStyle(theme)
                    }}
                >
                    Remove
                </button>  
                
                <span>
                    { aTag.title }
                </span>          
                
            </div>
        );
    }

    getPopulatedTagListUi()
    {
        return (
            <section>
                <h2>
                    Filters
                </h2>
                { 
                    this.state.tagList.map((aTag, index) => this.getTagUi(aTag, index)) 
                }
            </section>
        );
    }

    getEmptyTagListUi()
    {
        return (
            <section>
                <p>
                    No Tags Available.
                </p>
            </section>
        );
    }

    getTagListUi()
    {
        return this.state.tagList.length < 1 ? this.getEmptyTagListUi() : this.getPopulatedTagListUi();
    }

    getPromptUi()
    {
        return (
            <section>
                { super.getPromptUi() }
                { this.getTagListUi() }
            </section>
        );
    }
}

const AdminAddTagPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminAddTagPrompt);
export default AdminAddTagPrompt;
