import IssueService from '../../../service/issue';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import ValidateService from './../../../service/validate';
import ImgListPrompt from './../core/img-list';
import config from './../../../config';
import toast from 'react-hot-toast';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import theme from './../../theme';
import { connect } from 'react-redux';

class _ReportedIssueChatPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _ReportedIssueChatPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onServerError = this.onServerError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAddImg = this.onAddImg.bind(this);
        this.onAddSavedImg = this.onAddSavedImg.bind(this);
        this.onRemoveImg = this.onRemoveImg.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.getServerRequestStoredImgList = this.getServerRequestStoredImgList.bind(this);
    }

    static getValidators()
    {
        return {
            includeImage: {
                isValid: ValidateService.anyValue
            },
            messageField: {
                isValid: ValidateService.valueRequired
            },
        };
    }

    getFieldsModel()
    {
        return {
            includeImage: {
                name: 'Attach Images',
                error: '',
                value: false,
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.CHECKBOX
            },
            messageField: {
                name: 'Please provide a message*',
                error: '',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT_AREA
            },
            imgList: [],
            savedImgDialogOpen: false,
        };
    }

    /* istanbul ignore next */
    onAddImg(img)
    {
        let nextState = this.state;

        const imgListLength = nextState.imgList.length;
        if((imgListLength + 1 ) > config.img.maxUploadCount)
        {
            nextState.imgList[imgListLength - 1] = img;
            toast('Max images reached');
        } 
        else
        {
            nextState.imgList.push(img);
        }
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onAddSavedImg(img)
    {
        let nextState = this.state;

        const imgListLength = nextState.imgList.length;
        if((imgListLength + 1 ) > config.img.maxUploadCount)
        {
            nextState.imgList[imgListLength - 1] = img;
            toast('Max images reached');
        } 
        else
        {
            nextState.imgList.push(img);
        }
        nextState.savedImgDialogOpen = false;        
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onRemoveImg(delIndex)
    {
        let nextState = this.state;
        nextState.imgList = this.state.imgList.filter((img, index) => delIndex !== index);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    openDialog()
    {
        const nextState = {
            ...this.state,
            savedImgDialogOpen: true
        };
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onCloseDialog(event)
    {
        const nextState = {
            ...this.state,
            savedImgDialogOpen: false
        };
        this.setState(nextState);
    }

    getImgPromptUi()
    {
        return this.state.includeImage.value && 
            <ImgListPrompt 
                promptTitle="Images"
                onAddImg={this.onAddImg}
                onRemoveImg={this.onRemoveImg}
                openDialog={this.openDialog}
                onCloseDialog={this.onCloseDialog}
                savedImgDialogOpen={this.state.savedImgDialogOpen}
                onAddSavedImg={this.onAddSavedImg}
                imgList={this.state.imgList} />
    }

    getPromptUi() 
    {
        return (
            <span style={{
                ...theme.getDynamicAbstractLeftFlexContainerStyle(theme),
            }}>
                <div>
                    { this.getImgPromptUi() }
                </div>
                <div>
                    { super.getPromptUi() }
                </div>
            </span>
        );
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
        };
    }

    onServerResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response, false);
        nextState = this.clearFields(nextState);
        nextState.requestComplete = false;
        this.setState(nextState);
        this.props.onLoadChats();
    }

    onServerError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    onSendToServer()
    {
        IssueService.speak({
            request: this.getServerRequest(),
            fileList: this.getServerRequestFileList(),
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getSubmitButtonUi(buttonText = 'Send')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    getServerRequest()
    {
        return {
            appIssueId: this.props.appIssueId,
            message: this.state.messageField.value,
            savedImgList: this.getServerRequestStoredImgList()
        };
    }

    /* istanbul ignore next */
    getServerRequestFileList()
    {
        return this.state.includeImage.value ? this.state.imgList.map((img, index) => ({
            ...img,
            order: index
        })).filter(img => img.id === undefined) : [];
    }

    /* istanbul ignore next */
    getServerRequestStoredImgList()
    {
        return this.state.includeImage.value ? this.state.imgList.map((img, index) => ({
            ...img,
            order: index
        })).filter(img => img.id !== undefined).map(img => ({
            id: img.id,
            order: img.order
        })) : [];
    }
}

const ReportedIssueChatPrompt = connect(mapStateToProps, mapDispatchToProps)(_ReportedIssueChatPrompt);
export default ReportedIssueChatPrompt;
