export const getUserJoinedStyle = (theme) => ({
    marginBottom: '.5rem',
});

export const getPendingInviteAvailableStyle = (theme) => ({
    maxWidth: '40%',
});

export const getPendingInviteCodeStyle = (theme) => ({
    maxWidth: '60%',
    minWidth: '30%',
    wordWrap: 'anywhere',
    paddingTop: '.25rem',
});

export const getPendingInviteStyle = (theme) => ({
    width: 'calc(100% - 1.5rem)',
    maxWidth: '60rem',
    marginBottom: '.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});