import React from 'react';
import DetailPanel from './../../../core/detail';
import WidgetService from './../../../../../service/widget';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../../store/map/on-logout';
import WidgetNewsViewPanel from './view';

class _WidgetNewsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    /* istanbul ignore next */
    loadData(inputObj)
    {
        super.loadData(inputObj)
        WidgetService.listNews({
            request: { 
                page: inputObj.page ? inputObj.page : 0 
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({
            page: 0
        });
    }

    onLoadSpecifiedPage(page)
    {
        super.loadData({ })
        WidgetService.listNews({
            request: { page },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadedDataDetailUi()
    {
        return <WidgetNewsViewPanel data={this.state.data} onLoadSpecifiedPage={this.onLoadSpecifiedPage} />
    }
}

const WidgetNewsPanel = connect(mapStateToProps, mapDispatchToProps)(_WidgetNewsPanel);

export default WidgetNewsPanel;
