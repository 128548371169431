import React from 'react';
import { useState, useEffect } from 'react';
import AttributeListPanel from './../core/attribute-list';
import DatePanel from './../core/date';
import OfferSeekTypePanel from './../core/offer-seek-type';
import ExchangeListPanel from './../core/exchange-list';
import ObtainTypeDetailListPanel from './../core/obtain-type-detail-list';
import ItemAuthorMenu from './../../menu/item-author';
import ItemViewerMenu from './../../menu/item-viewer';
import FlaggedContentTypeEnum from './../../prompt/core/enum/flagged-content-type';
import BidContentTypeEnum from './../../prompt/core/enum/bid-content-type';
import BidsPanel from './../core/bids';
import AuthorPanel from './../core/author';
import FlagPrompt from '../../prompt/flag';
import ImgPanel from './../core/img';
import UserReviewsPanel from './../user-reviews';
import theme from './../../theme';
import TagListPanel from './../core/tag-list';
import { isBidButtonVisible } from './../../menu/item-viewer';
import SubscriptionService from './../../../service/subscription';
import ResizeService from './../../../service/resize';
import { BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE } from './../../prompt/core/enum/bx-subscription-access';

const SeekDetailViewPanel = ({ 
    data: seek, 
    viewingUserId, 
    onArchiveItem, 
    setBids, 
    onLoadSpecifiedPage,
    onContactingServerResponse,
    onContactingServer,
    paidAccount
}) => {
    const getSeekDetailViewPanelStyle = () => theme.getSeekDetailViewPanelStyle(theme);
    const [ seekDetailViewPanelStyle, setSeekDetailViewPanelStyle ] = useState( getSeekDetailViewPanelStyle() );
    
    const getSeekDetailTitleStyle = () => ({
        ...theme.getAbstractFlexContainerNoGutterStyle(theme),
        ...theme.getOfferAbstractFlexColumnStyle(theme)
    });
    const [ seekDetailTitleStyle, setSeekDetailTitleStyle] = useState(getSeekDetailTitleStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSeekDetailViewPanelStyle( getSeekDetailViewPanelStyle() );
        setSeekDetailTitleStyle( getSeekDetailTitleStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={seekDetailViewPanelStyle}>
            <div style={seekDetailTitleStyle}>
                <div style={{
                    ...theme.getOfferDetailViewHeaderStartStyle(theme),
                }}>
                    <h2>
                        Title:
                    </h2>
                    <h4>
                        { seek.title }
                    </h4>
                </div>
                <div style={{
                    ...theme.getOfferDetailViewHeaderEndStyle(theme),
                }}>
                    {
                        /* istanbul ignore next */
                        seek.isConfirmView ? '' :
                            seek.author.id === viewingUserId ? <ItemAuthorMenu item={seek} onArchiveItem={onArchiveItem} /> : 
                                <ItemViewerMenu 
                                    onContactingServerResponse={onContactingServerResponse}
                                    onContactingServer={onContactingServer}
                                    item={seek} 
                                    setBids={setBids}
                                    paidAccount={SubscriptionService.can(seek.subscription, BX_SUBSCRIPTION_ACCESS_LOW_TRANS_FEE)}
                                    openPromptTitle="Flag this seek" 
                                    bidContentType={BidContentTypeEnum.BID_CONTENT_TYPE_SEEK}
                                    flaggedContentType={FlaggedContentTypeEnum.FLAGGED_CONTENT_TYPE_SEEK} 
                                />
                    }
                </div>
            </div>
            
            <div style={{
                ...theme.getOfferDetailViewBodyStyle(theme),
            }}>
                <div style={{
                            ...theme.getOfferDetailViewGroupStyle(theme),
                            ...theme.getOfferAbstractFlexColumnStyle(theme),
                }}>
                    <h3>
                        {
                            seek.imgList.length > 0 ? 'Images:' : 'No Images'
                        }
                    </h3>
                    {
                        seek.imgList.length > 0 ? <ImgPanel imgList={seek.imgList} /> : ''
                    }
                </div>

                <div style={{
                    ...theme.getOfferDetailViewGroupStyle(theme),
                    ...theme.getOfferAbstractFlexColumnStyle(theme),
                }}>
                    <h3>
                        Seek Details:
                    </h3>
                    <p style={{
                    ...theme.getAbstractTextAlignLeftStyle(theme),
                    ...theme.getOfferDetailsBodyStyle(theme),
                    }}>
                        { seek.details }
                    </p>
                </div>

                <TagListPanel tagList={seek.tagList} />

                <div style={{
                    ...theme.getOfferDetailViewGroupStyle(theme),
                    ...theme.getAbstractTextBaselineStyle(theme),
                }}>
                    <h4>
                        Seek Type:
                    </h4>
                    &nbsp;
                    <OfferSeekTypePanel offerSeekType={seek.offerSeekType} />
                </div>

                <div style={{
                    ...theme.getTagListPostedOfferFlexStyle(theme),
                }}>
                    <AttributeListPanel attributeList={seek.attributeList} />
                </div>

                <div style={{
                    ...theme.getTagListPostedOfferFlexStyle(theme),
                }}>
                    <ExchangeListPanel exchangeList={seek.exchangeList} />
                </div>
                
                <ObtainTypeDetailListPanel 
                    obtainTypeDetailList={seek.obtainTypeDetailList} 
                    contentType="seek"
                />
                <div style={{ 
                    ...theme.getOfferDetailViewSubHeaderStyle(theme),
                    ...theme.getAbstractTextBaselineStyle(theme),
                }}>
                    <div style={{ 
                        ...theme.getAbstractTextAlignLeftStyle(theme),
                        ...theme.getOfferDetailViewAdjustingWidthStyle(theme)
                    }}>
                        {
                            seek.isConfirmView ? '' : <AuthorPanel author={seek.author} />
                        }
                    </div>

                    <div style={{ 
                        ...theme.getAbstractTextAlignCenterStyle(theme),
                        ...theme.getAbstractFullWidthtStyle(theme)
                    }}>
                        {
                            seek.isConfirmView ? '' : <UserReviewsPanel ratedUserId={seek.author.id} />
                        }
                    </div>

                    <div style={{ 
                        ...theme.getAbstractTextAlignRightStyle(theme),
                        ...theme.getOfferDetailViewAdjustingWidthStyle(theme)
                    }}>
                        {
                            seek.isConfirmView ? '' : <DatePanel label="Posted" date={seek.createdOn} />
                        }
                    </div>

                    
                </div>
                {
                    /* istanbul ignore next */
                    seek.isConfirmView ? '' : 
                        isBidButtonVisible(seek, BidContentTypeEnum.BID_CONTENT_TYPE_SEEK) ? 
                            <BidsPanel 
                                bidderIsReceiver={false} 
                                isActive={true} 
                                bids={seek.bids} 
                                targetType="seek"
                                onLoadSpecifiedPage={onLoadSpecifiedPage} 
                            /> : ''
                }
            </div>


            <div style={{
                ...theme.getOfferDetailViewHeaderEndStyle(theme),
            }}>
                {
                    /* istanbul ignore next */
                    seek.isConfirmView ? '' :
                        seek.author.id === viewingUserId ? '' : 
                            <FlagPrompt
                                buttonStyle={{}} 
                                contentId={seek.id} 
                                flaggedContentType={FlaggedContentTypeEnum.FLAGGED_CONTENT_TYPE_SEEK} 
                                openPromptTitle={"Flag this seek"} 
                            />
                }

            </div>

        </div>
    );
};


export default SeekDetailViewPanel;
