const makeGetGrossTotal = ({
    price,
    pendingPayoutUpdates
}) => () => price
    .memberContributionList
    .map(memCon => {
        const foundPayout = pendingPayoutUpdates.find(ppu => ppu.memberId === memCon.memberId);
        const output = foundPayout ? foundPayout.payout : memCon.payout;
        return parseInt(output);
    })
    .reduce((prev, curr) => prev + curr, 0);

export default makeGetGrossTotal;
