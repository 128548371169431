import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminRequestVolumePanel from '../ui/panel/admin/admin-request-volume';
import theme from '../ui/theme';
import { NavLink } from 'react-router-dom';

const AdminSearchMetricsGraphPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            Metrics Graphs
        </h2>
        <div>
            <NavLink to="/admin/metrics/summary">
                Summary
            </NavLink>
            <span>
                |
            </span>
            <NavLink to="/admin/metrics">
                All Metrics
            </NavLink>
        </div>
        <AdminRequestVolumePanel />
    </AdminPageTemplate>

export default AdminSearchMetricsGraphPage;
