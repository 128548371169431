import React from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import theme from './../../../theme';
import Pagination from './../../../prompt/core/pagination';
import AdminRepairGeoLocationPrompt from './../../../prompt/admin/admin-repair-geo-location';

const EmptyView = () => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <p>
            No Failed Jobs
        </p>
    </div>

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />


const LocationDetails = ({
    location
}) => {
    const state = location.shortState !== '' ? location.shortState : location.state;
    const street = location.streetLine2 !== '' ? `${location.street}
${location.streetLine2}` : location.street;
    return (
        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            {
                `${street}
${location.city}, ${state} ${location.zipCode}
${location.countryName}`
            }
        </div>
    );
};

// istanbul ignore next
const Location = ({
    location,
    onUpdated
}) => {
    const [ showUpdatePrompt, setShowUpdatePrompt ] = useState(false);
    const onRepaired = response => {
        setShowUpdatePrompt(false);
        onUpdated(response);
    };
    
    return (
        <div>
            <p>
                {location.geoLocFailMessage}
            </p>
            
            <LocationDetails location={location} />
            
            <button onClick={event => { setShowUpdatePrompt(true) }}>
                Update Geo Location Details
            </button>

            <Dialog
                onClose={event => { setShowUpdatePrompt(false) }}
                open={showUpdatePrompt}
            >
                <p>
                    Repair location geo data
                </p>

                <LocationDetails location={location} />

                <AdminRepairGeoLocationPrompt
                    locationId={location.id}
                    onRepaired={onRepaired}
                />

            </Dialog>



            <hr />


        </div>
    );

};

const PopulatedView = ({
    locations,
    onLoadSpecifiedPage,
    onUpdated
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
       
        { getPaginationUi({ data: locations, onLoadSpecifiedPage }) }

        {
            locations.list.map((loc, index) => 
                <Location
                    location={loc}
                    key={index}
                    onUpdated={onUpdated}
                />
            )
        }

        { getPaginationUi({ data: locations, onLoadSpecifiedPage }) }
    </div>

const AdminFailedGeoLocationsViewPanel = ({
    data, 
    onLoadSpecifiedPage,
    onUpdated
}) => data.failedJobs.list.length < 1 ? <EmptyView /> : 
    <PopulatedView 
        locations={data.failedJobs}
        onUpdated={onUpdated}
        onLoadSpecifiedPage={onLoadSpecifiedPage}
    />
   

export default AdminFailedGeoLocationsViewPanel;
