import React from 'react';
import DetailPanel from './../../core/detail';
import LedgerService from './../../../../service/ledger';
import AdminLedgerSummaryViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';

class _AdminLedgerSummaryPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onDepositFunds = this.onDepositFunds.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        LedgerService.summary({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onDepositFunds()
    {
        super.loadData({});
        LedgerService.confirmDeposit({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData({ });
    }

    getLoadedDataDetailUi()
    {
        return <AdminLedgerSummaryViewPanel data={this.state.data} onDepositFunds={this.onDepositFunds} />
    }
}

const AdminLedgerSummaryPanel = connect(mapStateToProps, mapDispatchToProps)(_AdminLedgerSummaryPanel);
export default AdminLedgerSummaryPanel;
