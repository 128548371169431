import React from 'react';
import { useState } from 'react';
import ActorDetails from './actor-details';
import DisputeRespondPanel from './../dispute-respond';
import Dialog from '@mui/material/Dialog';

const DisputedTransactionAwaitingResponseView = ({ exchange }) => 
    <span>
        Payment Disputed - Awaiting Response from 
        <ActorDetails
            usersDetails={exchange.usersDetails}
            actorId={exchange.bid.payoutUserId}
            amIActor={exchange.bid.amIPayout}
        />
    </span>
        
const DisputedTransactionResponsePrompt = ({ exchange, onResponded, onAcceptDisputeLoss }) => {
    const [showPrompt, setShowPrompt] = useState(false);
    return (
        <div>
            <button 
                className="test-exchangeDetailDisputedTransactionViewRespondToDisputeBtn"
                onClick={event => { setShowPrompt(true) }}
            >
                Respond to Dispute
            </button>
            <Dialog 
                onClose={ 
                    /* istanbul ignore next */
                    event => { setShowPrompt(false) }
                }
                open={showPrompt}
            >
                <DisputeRespondPanel 
                    exchangeId={exchange.id} 
                    onAcceptDisputeLoss={onAcceptDisputeLoss} 
                    onResponded={onResponded} 
                />
            </Dialog>
        </div>
    );
};


const DisputedTransactionView = ({ 
    exchange, 
    onResponded, 
    onAcceptDisputeLoss 
}) => {
    const awaitingResponse = exchange.bid && exchange.bid.disputeDetails && exchange.bid.disputeDetails.awaitingResponse ? true : false;
    const statusText = exchange.bid && exchange.bid.disputeDetails && exchange.bid.disputeDetails.isChargeBack ? 'Disputed - Chargeback' : 'Locked due to disputed charge';
    return (
        <section>
            <div>
                <h2>
                    {
                        awaitingResponse && !exchange.bid.disputeDetails.userResponseSent ? 
                            <DisputedTransactionAwaitingResponseView 
                                exchange={exchange} 
                            /> : 
                            <span>
                                { statusText }
                            </span>
                    }
                </h2>
                {
                    awaitingResponse  && !exchange.bid.disputeDetails.userResponseSent && exchange.bid.amIPayout ? 
                        <DisputedTransactionResponsePrompt 
                            onAcceptDisputeLoss={onAcceptDisputeLoss} 
                            exchange={exchange} 
                            onResponded={onResponded} 
                        /> : 
                        (
                            exchange.bid && exchange.bid.disputeDetails && exchange.bid.disputeDetails.isChargeBack ? '' :
                            <p>
                                Awaiting third party review.
                            </p>
                        )
                }
            </div>
        </section>
    );
};

export default DisputedTransactionView;
