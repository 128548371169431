import React from 'react';
import { useState, useEffect } from 'react';
import AddBankAccountPrompt from './../../prompt/add-bank-account';
import { NavLink } from 'react-router-dom';
import theme from './../../theme';
import ResizeService from './../../../service/resize';

const BankView = () => 
    <div>
        <p>
            Add a Bank Account for payouts
        </p>
        <AddBankAccountPrompt />
    </div>

const DisabledView = (parms) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={bodyStyle}>
            <p>
                Before you can add payout accounts you must
            </p>
            <p>
                <NavLink to="/profile/income" style={{
                    ...theme.getGeneralTextLinkStyle(theme), 
                }}>
                    setup your account
                </NavLink>
            </p>
        </div>
    );
};


const AddPayoutViewPanel = ({ data }) => {
    const getPanelStyle = () => theme.getProfilePwdPanelStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPanelStyle( getPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={{
                ...theme.getGeneralTextStyle(theme),
            }}>
                <h2>
                    Add a Payout Account
                </h2>
            </div>
            <div style={panelStyle}>
                {
                    data.account.accountSetupComplete ? <BankView /> : <DisabledView />
                }
            </div>
        </section>
    );
};

export default AddPayoutViewPanel;
