const RESTRICT_VIEW_TYPE_PUBLIC = 'public';
const RESTRICT_VIEW_TYPE_FRIENDS = 'friends';
const validValues = [
    RESTRICT_VIEW_TYPE_PUBLIC,
    RESTRICT_VIEW_TYPE_FRIENDS,
];

const getDropDownValues = () => [
    {
        name: 'Public',
        value: RESTRICT_VIEW_TYPE_PUBLIC,
    },
    {
        name: 'Private (Friends Only)',
        value: RESTRICT_VIEW_TYPE_FRIENDS
    }
];

const isValid = value => !! validValues.includes(value);

const RestrictViewTypeEnum = {
    RESTRICT_VIEW_TYPE_PUBLIC,
    RESTRICT_VIEW_TYPE_FRIENDS,
    getDropDownValues,
    isValid,
};

export default RestrictViewTypeEnum;
