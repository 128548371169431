import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import config from './../config';
import theme from './../ui/theme';
import SearchCarrierGroupsAcceptingJoins from './../ui/prompt/search-carrier-groups-accepting-joins';

const CarrierGroupsSearchPage = () => 
    <AuthPageTemplate childMenu={ <ProfileMenu /> }>
        {
            /* istanbul ignore next */
            config.features.allowCarrierGroups ? 
                <div style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    <h3>
                        Search for Carrier Companies Accepting Join Requests
                    </h3>
                    <SearchCarrierGroupsAcceptingJoins /> 
                </div> : 
                    <p style={{
                        ...theme.getGeneralTextStyle()
                    }}>
                        This feature is currently disabled
                    </p>
        }
    </AuthPageTemplate>

export default CarrierGroupsSearchPage;
