import React from 'react';
import { Prompt } from 'react-router-dom';
import AttSetService from './../../../service/att-set';
import ValidateService from '../../../service/validate';
import WarnUnsavedService from './../../../service/warn-unsaved';
import { FORM_FIELD, AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import AttributeDetailViewPrompt from './../core/attribute-detail-view';
import theme from '../../theme';
import config from './../../../config';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../../service/resize';

const unsavedWarningCopy = 'Are you sure you want to lose unsaved data?';

class _AddAttSetPrompt extends AbstractPrompt
{
    constructor(parms)
    {
        super(parms, _AddAttSetPrompt.getValidators());

        this.resizeFun = () => {};
        this.warnUnSavedFun = () => {};
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.onAddResponse = this.onAddResponse.bind(this);
        this.onAddError = this.onAddError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.onRemoveAttribute = this.onRemoveAttribute.bind(this);
        this.onAddAttribute = this.onAddAttribute.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onFieldValueUpdate = this.onFieldValueUpdate.bind(this);
        this.validateState = this.validateState.bind(this);
        this.updateContainerStyle = this.updateContainerStyle.bind(this);
        this.getContainerStyle = this.getContainerStyle.bind(this);
        this.restart = this.restart.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {
            title: {
                isValid: ValidateService.valueRequired
            },
        };
    }

    validateState(updateState)
    {
        let result = super.validateState(updateState);
        /* istanbul ignore next */
        if(this.state.attributeList.length < 1)
        {
            result.isValid = false;
        }
        result.hasUnsaved = true;
        return result;
    }

    /* istanbul ignore next */
    onAuthError()
    {
        this.props.onLogout();
    }

    /* istanbul ignore next */
    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            title: {
                name: 'Field Set Name*',
                error: 'Provide a title for this set of fields',
                value: '',
                visible: true,
                valid: false,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            attributeList: [],
        };
    }

    getContainerStyle()
    {
        return {
            ...theme.getInLineTextStyle(theme),
            ...theme.getGeneralFlexDirectionResizeStyle(theme)
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            addedId: '',
            hasUnsaved: false,
            containerStyle: this.getContainerStyle(),
            noMarginStyle: theme.getAbstractNoMarginStyle(theme)
        };
    }

    /* istanbul ignore next */
    updateContainerStyle()
    {
        const nextState = this.state;
        nextState.containerStyle = this.getContainerStyle();
        nextState.noMarginStyle = theme.getAbstractNoMarginStyle(theme);
        this.setState(nextState);
    }

    componentDidMount()
    {
        this.resizeFun = ResizeService.getOnResize(this.updateContainerStyle);
        ResizeService.startListening(this.resizeFun);

        /* istanbul ignore next */
        this.warnUnSavedFun = WarnUnsavedService.getOnUnload(
            () => this.state.hasUnsaved, 
            unsavedWarningCopy
        );
        WarnUnsavedService.startListening(this.warnUnSavedFun);
    }

    componentWillUnmount()
    {
        ResizeService.stopListening(this.resizeFun);
        WarnUnsavedService.stopListening(this.warnUnSavedFun);
    }

    /* istanbul ignore next */
    onAddResponse(response)
    {
        let nextState = this.getBaseStateOnResponse(response);
        nextState = this.clearFields(nextState);
        nextState.addedId = response.id;
        nextState.hasUnsaved = false;
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onAddError(error)
    {
        const nextState = this.getBaseStateOnError(error);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        AttSetService.addAttSet({
            request: this.getServerRequest(),
            onResponse: this.onAddResponse,
            onError: this.onAddError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getServerRequest()
    {
        return {
            title: this.state.title.value,
            attributeList: this.state.attributeList
        };
    }

    /* istanbul ignore next */
    onRemoveAttribute(delIndex)
    {
        let nextState = this.state;
        nextState.attributeList = this.state.attributeList.filter((aDetail, index) => index !== delIndex);
        nextState = this.validateState(nextState);
        this.setState(nextState);
    }

    /* istanbul ignore next */
    onAddAttribute(nextAttribute)
    {
        let nextState = this.state;
        if(nextState.attributeList.length < config.countLimits.customFields)
        {
            nextState.hasUnsaved = true;
            nextState.attributeList.push(nextAttribute);
            nextState = this.validateState(nextState);
            this.setState(nextState);
        }
        else 
        {
            toast('Fields limit reached.  Remove a field before adding another');
        }
    }

    restart()
    {
        const restartState = this.getDefaultState();
        this.setState(restartState);
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Field Set Added')
    {
        return (
            <section>
                <h2 style={{ 
                    ...theme.getGeneralTextStyle(theme),
                    ...theme.getGeneralTextLinkStyle(theme), 
                }}>
                    <NavLink to={`/profile/att-set/${this.state.addedId}`}>
                        Field Set Added
                    </NavLink>
                </h2>
                    <NavLink to={`/`}>
                        <h3 style={{ 
                            ...theme.getGeneralTextStyle(theme),
                            ...theme.getGeneralTextLinkStyle(theme),   
                        }}>
                            Go back to your Dashboard.
                        </h3>
                    </NavLink> 
                    <h3 
                        onClick={event => { this.restart() }}
                        style={{
                            ...theme.getGeneralTextStyle(theme),
                            ...theme.getGeneralTextLinkStyle(theme),
                            ...theme.getOnClickLinkStyle(theme)
                        }}
                    >
                        Add another field set.
                    </h3>
            </section>
        );
    }

    getSubmitButtonUi(buttonText = 'Save')
    {
        return super.getSubmitButtonUi(buttonText);
    }

    /* istanbul ignore next */
    onFieldValueUpdate(index, value)
    {
        const nextState = this.state;
        nextState.attributeList[index].value = value;
        this.setState(nextState);
    }

    getPromptUi(buttonText = 'Add')
    {   
        return (
            <section>
                <Prompt 
                    when={this.state.hasUnsaved}
                    message={unsavedWarningCopy}
                />

                <form onSubmit={this.onFormSubmit}>
                    <section style={{
                        ...theme.getGeneralTextStyle(theme),
                    }}>
                        <div style={this.state.containerStyle}>
                            <h2>
                                Add a field set
                            </h2>
                            <h3 style={this.state.noMarginStyle}>
                                -
                            </h3>
                            <h4>
                                Define a set of fields to quickly add into seek or offer posts
                            </h4>
                        </div>
                    </section>
                    <div style={{
                        ...theme.getOfferSearchSingleFileContainerFileStyle(theme),
                    }}>                                      
                        <div style={{
                            ...theme.getOfferSearchSingleFormContainerStyle(theme),
                            ...theme.getInLineTextStyle(theme),
                        }}>
                            <div style={{
                                ...theme.getOfferSearchSingleFormStyle(theme),
                                ...theme.getAbstractSingleFieldButtonStyle(theme),
                            }}> 
                                { this.getServerFeedbackUi() }

                                { this.getFormFieldsUi() }
                            </div>
                            <div>
                                { this.getSubmitButtonUi() }
                            </div>
                        </div>
                    </div>
                </form>

                <AttributeDetailViewPrompt 
                    promptTitle=""
                    onFieldValueUpdate={this.onFieldValueUpdate}
                    valueFieldTitle="Default Value*"
                    onAddAttribute={this.onAddAttribute}
                    attributeList={this.state.attributeList}
                    onRemoveAttribute={this.onRemoveAttribute} />

            </section>
        );
    }
}

const AddAttSetPrompt = connect(mapStateToProps, mapDispatchToProps)(_AddAttSetPrompt);
export default AddAttSetPrompt;
