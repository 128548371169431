import {
    UpdateDeviceNetwork
} from '../state/action';
import ResizeService from '../../service/resize';
import config from '../../config';

const mapDispatchToProps = dispatch => ({
    onUpdateDeviceOnline(deviceIsOnline)
    {
        dispatch(UpdateDeviceNetwork(deviceIsOnline));
        setTimeout(() => {
            ResizeService.triggerResizeEvent();
        },
        config.interval.postLoginResizeEvent
    );
    }
});

export default mapDispatchToProps;
