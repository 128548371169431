
export const METRIC_API_RESPONSE_TIME = 'api';
export const MAX_HEALTHY_MS = 60000; // 60 seconds
export const MAX_FAST_MS = 2000; // 2 seconds
export const MAX_SEC_FOR_APP_STARTING_PERIOD = 10;
export const MAX_API_HISTORY_COUNT = 25;
export const PERCENT_FOR_POSITIVE = .8

let apiResponseHistory = [];
let pageLoadStart;

export class MetricService {

    static getSecSincePageLoad()
    {
        return Math.floor((new Date() - new Date(pageLoadStart)) / 1000);
    }
    
    static isInAppStartingPeriod()
    {
        const seconds = MetricService.getSecSincePageLoad();
        return MAX_SEC_FOR_APP_STARTING_PERIOD >= seconds ? true : false;
    }

    static autoAdjustView({
        customView, 
        deviceConnect,
        toggleSlowSpeedView
    })
    {
        // if approprate then set to Fast View
        if(deviceConnect.fast.isFast && customView.slowSpeedViewEnabled)
        {
            toggleSlowSpeedView(false);
        }

        // is approprate then set to Slow View
        if(!deviceConnect.fast.isFast && !customView.slowSpeedViewEnabled)
        {
            toggleSlowSpeedView(true);
        }
    }

    static reactToSlow({
        setSpeedViewPromptVisible,
        toggleSlowSpeedView,
        customView
    })
    {
        // slow in starting period
        if(MetricService.isInAppStartingPeriod())
        {
            // and slow view is not enabled -> enable slow view
            if(!customView.slowSpeedViewEnabled)
            {
                toggleSlowSpeedView(true);
            }
        } 
        else 
        {
            // user has not been prompted
            if(!customView.userHasClosedSpeedViewPrompt)
            {
                setSpeedViewPromptVisible(true);
            }
        }
    }

    static sync({ 
        setCurrentMetrics, 
        setSpeedViewPromptVisible,
        toggleSlowSpeedView,
        customView 
    }) 
    {
        // save current metrics to redux
        const current = MetricService.getCurrent();
        setCurrentMetrics(current);

        // user has enabled auto adjust view by connection speed
        if(customView && customView.autoAdjustSpeedViewEnabled)
        {
            MetricService.autoAdjustView({
                customView,
                deviceConnect: current.deviceConnect,
                setSpeedViewPromptVisible,
                toggleSlowSpeedView
            });
        }

        // react to slow connection
        if(customView && !current.deviceConnect.fast.isFast)
        {
            MetricService.reactToSlow({
                setSpeedViewPromptVisible,
                toggleSlowSpeedView,
                customView
            });
        }
    
    }

    static pageLoaded()
    {
        pageLoadStart = new Date();
    }

    // start api metrics - returns object with complete() method to publish metric details
    static startApi(url)
    {
        return MetricService.getMetricObj(
            METRIC_API_RESPONSE_TIME,
            {
                url
            }
        );
    }
    
    // returns an object with complete() method to run metrics logic
    static getMetricObj(name, details)
    {
        const startDate = new Date();
        return {
            complete: (completeDetails) => {
                const fullDetails = {
                    ...details,
                    ...completeDetails
                };
                const endDate = new Date();
                MetricService.completeMetric(
                    name,
                    fullDetails,
                    startDate,
                    endDate
                )
            }
        };
    }

    // is this time between start and end of api call considered healthy?
    static isHealthyApi(msDistance)
    {
        return MAX_HEALTHY_MS >= msDistance;
    }

    // is this time between start and end of api call considered fast?
    static isFastApi(msDistance)
    {
        return MAX_FAST_MS >= msDistance;
    }

    // empty history of api metrics
    // istanbul ignore next 
    static emptyApiHistory()
    {
        apiResponseHistory = [];
    }

    // return stats as deviceConnect object for storage in redux
    static getDeviceConnect(
        total = 0, 
        healthyCount = 0, 
        fastCount = 0, 
        averageMsDistance = 0,
        minMsDistance = 0,
        maxMsDistance = 0
    )
    {
        const hasMetrics = total > 0;
        const healthyPercent = hasMetrics ? (healthyCount / total) : 0;
        const isHealthy = hasMetrics ? ( healthyPercent >= PERCENT_FOR_POSITIVE ) : true;
        const fastPercent = hasMetrics ? ( fastCount / total) : 0;
        const isFast = hasMetrics ? (fastPercent >= PERCENT_FOR_POSITIVE) : true;
        return {
            hasMetrics,
            health: {
                isHealthy,
                healthyPercent
            },
            fast: {
                isFast,
                fastPercent
            },
            stats: {
                average: {
                    value: averageMsDistance,
                    unit: 'ms'
                },
                min: {
                    value: minMsDistance,
                    unit: 'ms'
                },
                max: {
                    value: maxMsDistance,
                    unit: 'ms'
                },
                count: {
                    value: total,
                    unit: 'request count'
                },
            }
        }
    }

    // return most recent version of generated deviceConnect object
    // istanbul ignore next
    static getCurrent_deviceConnect()
    {
        return currentApiStat;
    }

    // return most recent version of generated metrics objects
    // istanbul ignore next
    static getCurrent()
    {
        return {
            deviceConnect: MetricService.getCurrent_deviceConnect()
        };
    }

    // publish passed deviceConnect object most recent
    // istanbul ignore next
    static publishDeviceConnect(deviceConnect)
    {
        currentApiStat = deviceConnect;
    }

    // return base / default stats struct for getting reduce on metric api details
    static getStatsObj()
    {
        return {
            max: 0,
            min: 0,
            sum: 0,
            count: 0
        };
    }

    // method to reduce getStatsObj() struct into details about a given metric type's history in stats
    static reduceMetricsToStats(stats, item)
    {
        stats.sum += item.msDistance;
        stats.count++;

        if(stats.min === 0)
        {
            stats.min = item.msDistance;
        } 
        else 
        {
            if(stats.min > item.msDistance) 
            {
                stats.min = item.msDistance;
            }
        }
        
        if(stats.max < item.msDistance) 
        {
            stats.max = item.msDistance;
        }

        return stats;
    }

    // convert api response metrics history into a deviceConnect object
    static examineApiMetricHistory()
    {
        const history = MetricService.getApiResponseHistory();
        const total = history.length;
        const healthyCount = history.filter(r => r.isHealthy).length;
        const fastCount = history.filter(r => r.isFast).length;
        const msStats = history.reduce(
            MetricService.reduceMetricsToStats,
            MetricService.getStatsObj()
        );
        const averageMsDistance = total > 0 ? 
            msStats.sum / total : 
            0;

        const deviceConnect = MetricService.getDeviceConnect(
            total,
            healthyCount,
            fastCount,
            averageMsDistance,
            msStats.min,
            msStats.max
        );

        MetricService.publishDeviceConnect(deviceConnect);

        if(MAX_API_HISTORY_COUNT <= total)
        {
            MetricService.emptyApiHistory();
        }
    }

    // istanbul ignore next
    static getApiResponseHistory()
    {
        return apiResponseHistory;
    }

    // add a api performance metric to the temp history for deviceConnect generation
    // istanbul ignore next
    static saveApiMetric(apiMetric)
    {
        apiResponseHistory.push(apiMetric);
    }

    // generate a api performance metric object, save to history, examine history to generate deviceConnect object and if needed purge history
    static reactToApiMetric(details, startDate, endDate)
    {
        const msDistance = MetricService.getMSDistance(startDate, endDate);
        const apiMetric = {
            url: details.url,
            isHealthy: details.isSuccess && MetricService.isHealthyApi(msDistance),
            isFast: MetricService.isFastApi(msDistance),
            msDistance
        };
        
        MetricService.saveApiMetric(apiMetric);
        MetricService.examineApiMetricHistory();
    }

    // determine time a task took
    static getMSDistance(startDate, endDate)
    {
        return endDate.getTime() - startDate.getTime()
    }

    // mark a metric as finished and process the results
    // istanbul ignore next
    static completeMetric(name, details, startDate, endDate)
    {
        switch(name)
        {
            case METRIC_API_RESPONSE_TIME:
                MetricService.reactToApiMetric(details, startDate, endDate);
                break;
                
            default:

        }
    }
}

let currentApiStat = MetricService.getDeviceConnect();

export default MetricService;
