export const getObtainTypeDialogStyle = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
    flexDirection: 'column',
    padding: '.75rem'
});

export const getObtainTypeLocationStyle = (theme) => ({
    textAlign: 'left',
    width: '18rem',
    overflowWrap: 'anywhere',
    margin: '0 .75rem',
    flexGrow: '1'
});

export const getObtainTypeObtainMethodStyle = (theme) => ({
    textAlign: 'right',
    maxWidth: '18rem',
    overflowWrap: 'anywhere'
});

export const getObtainTypeCloseIconStyle = (theme) => ({
    width: '3rem',
    marginRight: '1.5rem',
    marginLeft: '0.5rem',
});

export const getObtainTypeListItemStyle = (theme) => ({
    margin: '.75rem',
    display: 'flex',
    flexWrap: 'wrap',
    width: theme.isTablet() ? '17.5rem' : '100%',
    flexDirection: 'column',
});

export const getObtainTypeDetailPanelStyle = (theme) => ({
    margin: '.5rem',
    textAlign: 'left',
});
