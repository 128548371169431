import React from 'react';
import DetailPanel from './../core/detail';
import ExchangeService from './../../../service/exchange';
import ExchangeChatViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';

class _ExchangeChatPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        ExchangeService.getChats({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            exchangeId: inputObj.exchangeId,
            page: inputObj.page,
        };
    }

    onLoadSpecifiedPage(page)
    {
        super.loadData({ });
        ExchangeService.getChats({
            request: {
                page,
                exchangeId: this.props.exchangeId,  
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        const exchangeId = this.props.exchangeId;
        this.loadData({
            exchangeId,
            page: 0,
        });
    }

    /* istanbul ignore next */
    reloadData()
    {
        const exchangeId = this.props.exchangeId;
        this.loadData({
            exchangeId,
            page: 0,
        });
    }

    getLoadedDataDetailUi()
    {
        return <ExchangeChatViewPanel 
                exchangeId={this.props.exchangeId}
                chatPromptVisible={this.props.chatPromptVisible}
                onLoadSpecifiedPage={this.onLoadSpecifiedPage}
                onLoadChats={this.reloadData} 
                data={this.state.data} />
    }
}

const ExchangeChatPanel = connect(mapStateToProps, mapDispatchToProps)(_ExchangeChatPanel);
export default ExchangeChatPanel;
