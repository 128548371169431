export const getFlagPromptStyle = (theme) => ({
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getFlagImageStyle = (theme, icon) => ({
    backgroundImage: `url(${icon})`,
    width: '2.5rem',
    height: '2.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    cursor: 'pointer',
});

export const getFlagTextStyle = (theme) => ({
    textAlign: 'center',
    color: theme.warningColor,
    fontWeight: '700'
});


export const getAdminReviewFlagsViewPanelStyle = (theme) => ({
    textAlign: 'center',
    width: '480px',
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const getAdminReviewFlagSummaryLineStyle = (theme) => ({
    cursor: 'pointer',
    marginBottom: '10px',
});
