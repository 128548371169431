export const CURRENCY_TYPE_USD = 'usd';
export const CURRENCY_TYPE_AUD = 'aud';
export const CURRENCY_TYPE_CAD = 'cad';
export const CURRENCY_TYPE_GBP = 'gbp';
export const CURRENCY_TYPE_MXN = 'mxn';

const validValues = [
    CURRENCY_TYPE_USD,
    CURRENCY_TYPE_AUD,
    CURRENCY_TYPE_CAD,
    CURRENCY_TYPE_GBP,
    CURRENCY_TYPE_MXN,
];

const getDropDownValues = () => [
    { 
        name: 'USD', 
        value: CURRENCY_TYPE_USD
    },
/*    
    { 
        name: 'AUD', 
        value: CURRENCY_TYPE_AUD
    },
    { 
        name: 'CAD', 
        value: CURRENCY_TYPE_CAD
    },
    { 
        name: 'GBP', 
        value: CURRENCY_TYPE_GBP
    },
    { 
        name: 'MXN', 
        value: CURRENCY_TYPE_MXN
    },
*/
];

const isValid = value => !! validValues.includes(value);

const CurrencyTypeEnum = {
    CURRENCY_TYPE_USD,
    CURRENCY_TYPE_AUD,
    CURRENCY_TYPE_CAD,
    CURRENCY_TYPE_GBP,
    CURRENCY_TYPE_MXN,
    getDropDownValues,
    isValid
};

export default CurrencyTypeEnum;

