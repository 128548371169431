export const getTagFilterHeaderStyle = (theme) => ({
    textAlign: 'left',
    cursor: 'pointer',
    fontWeight: '700',
    fontSize: '14pt',
    margin: '0 0 .25rem 0',
});

export const getTagFilterOpenLeftStyle = (theme) => ({
    height: 'auto',
    maxHeight: '12rem',
    display: 'flex',
    overflow: 'auto',
    alignContent: 'flex-start',
    flexDirection: 'column',
    scrollbarGutter: 'stable both-edges',
    flexShrink: '0'
});

export const getTagFilterContainerIconStyle = (theme) => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
});

export const getTagFilterContainerStyle = (theme) => ({
    width: '100%',
});

export const getTagFilterOpenViewButtonContainerStyle = (theme) => ({
    height: 'auto'
});

export const getTagFilterOpenFlexContainerStyle = (theme) => ({
    display: 'flex',
    margin: '0 .5rem 1rem .5rem',
});

export const getTagFilterOpenFlexContainerTitleStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 .5rem .5rem .5rem',
    alignItems: 'center',
});

export const getTagFilterOpenHeaderSpacerStyle = (theme) => ({
    width: '3rem'
});

export const getTagFilterOpenHeaderStyle = (theme) => ({
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '14pt',
});

export const getTagFilterNoSelectHeaderStyle = (theme) => ({
    alignSelf: 'center',
    height: 'auto',
    minWidth: '2rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

export const getTagFilterRightStyle = (theme) => ({
    textAlign: 'center',
    display: 'flex',
    height: '2.4rem',
});

export const getTagFilterTagContainerStyle = (theme) => ({
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    width: '100%',
    height: 'auto',
});

export const getTagFilterRightContainerStyle = (theme) => ({
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    height: 'auto',
    maxHeight: '12rem',
    width: '100%',
    overflow: 'auto',
});

export const getTagFilterOpenLeftLineStyle = (theme) => ({
    margin: '0 1rem 1rem 0.5rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center'
});

export const getTagFilterOpenClearBarStyle = (theme) => ({
    textAlign: 'center'
});