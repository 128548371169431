import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import config from './../config';
import theme from './../ui/theme';
import CarrierMembersListPanel from './../ui/panel/carrier-members-list';
import PendingCarrierMembersPanel from './../ui/panel/pending-carrier-members';
import { useParams } from 'react-router-dom';

const CarrierMembersPage = () => {
    const { carrierGroupId } = useParams();
    return (
        <AuthPageTemplate childMenu={ <ProfileMenu /> }>
            {
                /* istanbul ignore next */
                config.features.allowCarrierGroups ? 
                    <div>

                        <PendingCarrierMembersPanel 
                            carrierGroupId={carrierGroupId} 
                        />

                        <CarrierMembersListPanel 
                            carrierGroupId={carrierGroupId} 
                        /> 
                    </div> : 
                        <p style={{
                            ...theme.getGeneralTextStyle()
                        }}>
                            This feature is currently disabled
                        </p>
            }
        </AuthPageTemplate>
    );
};

export default CarrierMembersPage;
