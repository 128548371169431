import React from 'react';
import AuthPageTemplate from './template/auth-page';
import WaitForSubscriptionConfirmedPanel from './../ui/panel/wait-for-subscription-confirmed';
import ProfileMenu from './../ui/menu/profile';
import theme from './../ui/theme';

const SubscriptionProcessingPage = () => 
    <AuthPageTemplate childMenu={ <ProfileMenu /> }>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <WaitForSubscriptionConfirmedPanel />
        </div>
    </AuthPageTemplate>

export default SubscriptionProcessingPage;
