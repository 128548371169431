import React from 'react';
import { NavLink } from 'react-router-dom';
import UiService from './../../../../service/ui';
import theme from './../../../theme';

const issueView = ({ issue, onArchive, index }) => 
    <div 
        style={{
            ...theme.getGeneralTextStyle(theme),
        }}
        key={index}
    >
        <section>
            <div style={{
                ...theme.getMediumTextStyle(theme),
            }}>
                <NavLink 
                    style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                    to={`/user/${issue.userId}`}
                >
                    Reporter
                </NavLink>
            </div>
            
            <div style={{
                ...theme.getSmallTextStyle(theme),
            }}>
                { UiService.timeSince(issue.createdOn) }
            </div>

        </section>
        <p style={ issue.unseen ? theme.getReportedIssueUnseenStyle(theme) : theme.getReportedIssueSeenStyle(theme) }>
            { issue.details }
        </p>
        <div>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/profile/reported/issue/${issue.id}`}
            >
                Issue Chat
            </NavLink>
        </div>
        <div>
            <NavLink 
                style={{ ...theme.getGeneralTextLinkStyle(theme) }} 
                to={`/admin/reported/issue/${issue.id}`}
            >
                More Details
            </NavLink>
        </div>
        <button 
            className="test-reportedIssuesArchiveBtn"
            onClick={(event) => { onArchive(issue.id) }}
        >
            Archive
        </button>
        <hr />
    </div>

const populatedIssues = ({ issues, onArchive }) => 
    <div>
        { issues.list.map((issue, index) => issueView({ issue, onArchive, index })) }
    </div>

const emptyIssues = () => 
    <p>
        No reported issues.
    </p>

const ReportedIssuesViewPanel = ({ data: issues, onArchive }) => 
    <div style={{
        ...theme.getReportedIssuesViewPanelStyle(theme),
    }}>
        <h2>
            Reported Issues
        </h2>

        {
            issues.total > 0 ? populatedIssues({ issues, onArchive }) : emptyIssues()
        }

    </div>

export default ReportedIssuesViewPanel;
