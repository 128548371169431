import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import ProfileStorePanel from './../ui/panel/profile-store';

const ProfileStorePage = () => {
    return (
        <AuthPageTemplate childMenu={
            <div>
                <ProfileMenu />
            </div>
        }>
            <ProfileStorePanel />
        </AuthPageTemplate>
    );
};

export default ProfileStorePage;
