export const getAdminUserDetailControlPanelButtonStyle = (theme) => ({
    marginRight: '10px'
});

// istanbul ignore next 
export const getAdminUserDetailFlagViewStyle = (theme, flag) => flag.reviewed ? ( flag.restored ? { color: 'red' } : { color: 'green' } ) : { color: 'grey' };

export const getAdminStatsViewPanelStyle = (theme) => ({
    textAlign: 'center',
    width: '535px',
    marginLeft: 'auto',
    marginRight: 'auto',
});

export const getAdminStatsGroupStyle = (theme) => ({
    width: '250px',
    float: 'left',
    marginLeft: '5px',
    marginBottom: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#000000',
    marginTop: '2px',
    marginRight: '5px'
});