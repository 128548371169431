import React from 'react';
import DetailPanel from './../core/detail';
import NoticeService from './../../../service/notice';
import NoticesViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-update-unseen-notice-count';
import config from './../../../config';

class _NoticesPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.viewNotice = this.viewNotice.bind(this);
        this.viewChatNotice = this.viewChatNotice.bind(this);
        this.viewExchangeNotice = this.viewExchangeNotice.bind(this);
        this.viewBidNotice = this.viewBidNotice.bind(this);
        this.deleteNotice = this.deleteNotice.bind(this);
        this.deleteAllNotices = this.deleteAllNotices.bind(this);
        this.markAllNoticesSeen = this.markAllNoticesSeen.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.viewFriendNotice = this.viewFriendNotice.bind(this);
        this.viewANotice = this.viewANotice.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.viewInviteNotice = this.viewInviteNotice.bind(this);
        this.viewPayAccountNotice = this.viewPayAccountNotice.bind(this);
        this.viewSubPaymentFailedNotice = this.viewSubPaymentFailedNotice.bind(this);
        this.viewFineNotice = this.viewFineNotice.bind(this);
        this.viewCarrierGroupInviteNotice = this.viewCarrierGroupInviteNotice.bind(this);
        this.viewCarrierMemberRequestNotice = this.viewCarrierMemberRequestNotice.bind(this);
        this.viewCarrierGroupQueueNotice = this.viewCarrierGroupQueueNotice.bind(this);
        this.viewCarrierGroupQuoteDeclineNotice = this.viewCarrierGroupQuoteDeclineNotice.bind(this);
        this.viewIssueNotice = this.viewIssueNotice.bind(this);
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        /* istanbul ignore next */
        if(this.props.onUpdateError)
        {
            this.props.onUpdateError(true, '');
        }
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        NoticeService.getNotices({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        super.loadData({ });
        NoticeService.getNotices({
            request: {
                page,
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            page: inputObj.page
        };
    }

    componentDidMount()
    {
        this.loadData({
            page: 0
        });
    }

    viewANotice(notice, sendToUrl)
    {
        super.loadData({ });
        
        const onResponse = (response) => {
            this.props.onUpdateUnseenNoticeCount(response.unseenNoticeCount);
            this.sendUserTo(sendToUrl);
        };
        const onError = (error) => {
            this.sendUserTo(sendToUrl);
        };
        NoticeService.seen({
            notice, 
            onResponse, 
            onError, 
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }
    viewBidNotice(notice)
    {
        const url = `${config.redirects.baseBid}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewFriendNotice(notice)
    {
        const url = `${config.redirects.baseFriend}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewPayAccountNotice(notice)
    {
        this.viewANotice(notice, config.redirects.payAccount);
    }

    viewChatNotice(notice)
    {
        const url = `${config.redirects.baseExchange}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewExchangeNotice(notice)
    {
        const url = `${config.redirects.baseExchange}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewCarrierGroupQuoteDeclineNotice(notice)
    {
        const url = `${config.redirects.baseExchange}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewInviteNotice(notice)
    {
        this.viewANotice(notice, config.redirects.invite);
    }

    viewSubPaymentFailedNotice(notice)
    {
        this.viewANotice(notice, config.redirects.subscription);
    }

    viewFineNotice(notice)
    {
        this.viewANotice(notice, config.redirects.userFines);
    }

    viewCarrierGroupInviteNotice(notice)
    {
        this.viewANotice(notice, config.redirects.carrierGroupInvites);
    }

    viewCarrierMemberRequestNotice(notice)
    {
        const url = `${config.redirects.carrierMembersPre}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewCarrierGroupQueueNotice(notice)
    {
        const url = `${config.redirects.carrierMembersPre}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewConvertDataOfferNotice(notice)
    {
        const url = `${config.redirects.baseOffer}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewConvertDataSeekNotice(notice)
    {
        const url = `${config.redirects.baseSeek}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewConvertDataBidNotice(notice)
    {
        const url = `${config.redirects.baseBid}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewIssueNotice(notice)
    {
        const url = `${config.redirects.baseIssue}${notice.targetId}`;
        this.viewANotice(notice, url);
    }

    viewNotice(notice)
    {
        /* istanbul ignore next */
        switch(notice.type)
        {
            case 'bid':
                this.viewBidNotice(notice);
                break;
            case 'friend':
                this.viewFriendNotice(notice);
                break;

            case 'chat':
                this.viewChatNotice(notice);
                break;

            case 'exchange':
                this.viewExchangeNotice(notice);
                break;

            case 'invite':
                this.viewInviteNotice(notice);
                break;

            case 'pay-account':
                this.viewPayAccountNotice(notice);
                break;

            case 'sub-payment-failed':
                this.viewSubPaymentFailedNotice(notice);
                break;

            case 'fine':
                this.viewFineNotice(notice);
                break;

            case 'carrier-group-invite':
                this.viewCarrierGroupInviteNotice(notice);
                break;

            case 'carrier-member-request':
                this.viewCarrierMemberRequestNotice(notice);
                break;

            case 'carrier-group-queue':
                this.viewCarrierGroupQueueNotice(notice);
                break;

            case 'carrier-group-quote-decline':
                this.viewCarrierGroupQuoteDeclineNotice(notice);
                break;

            case 'convert-data-v2-offer':
                this.viewConvertDataOfferNotice(notice);
                break;

            case 'convert-data-v2-seek':
                this.viewConvertDataSeekNotice(notice);
                break;

            case 'convert-data-v2-bid':
            case 'convert-data-v2-bid-admin-review':
                this.viewConvertDataBidNotice(notice);
                break;

            case 'issue':
                this.viewIssueNotice(notice);
                break;

            default:
                throw new Error('Unknown Notice Type');
        }
    }

    deleteNotice(notice)
    {
        super.loadData({ });
        NoticeService.delete({
            notice, 
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    deleteAllNotices()
    {
        super.loadData({ });
        NoticeService.deleteAll({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    markAllNoticesSeen()
    {
        super.loadData({ });
        NoticeService.markAllSeen({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadedDataDetailUi()
    {
        return <NoticesViewPanel 
                    data={this.state.data}
                    onLoadSpecifiedPage={this.onLoadSpecifiedPage}
                    unseenNoticeCount={this.props.unseenNoticeCount}
                    deleteAllNotices={this.deleteAllNotices}
                    markAllNoticesSeen={this.markAllNoticesSeen}
                    deleteNotice={this.deleteNotice}
                    viewNotice={this.viewNotice} />
    }

    onLoadDataResponse(response)
    {
        this.props.onUpdateUnseenNoticeCount(response.unseenNoticeCount);
        super.onLoadDataResponse(response);
    }
    
}

const NoticesPanel = connect(mapStateToProps, mapDispatchToProps)(_NoticesPanel);
export default NoticesPanel;