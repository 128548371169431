export const getExchangeListPanelStyle = (theme) => ({
    paddingBottom: '1rem',
});

export const getExchangeListValueStyle = (theme) => ({
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: '700',
    padding: '0 0 0 .75rem',
    width: '100%',
    wordWrap: 'anywhere',
    borderLeftWidth: '.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.secondaryColor,
});

export const getExchangeListNoValueStyle = (theme) => ({
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '700',
    padding: '0 0 .75rem 0',
});

export const getExchangeListLabelStyle = (theme) => ({
    fontSize: '16px',
    width: '8.75rem',
    textAlign: 'right',
    paddingRight: '.75rem',
    width: '100%',
    wordWrap: 'anywhere',
    minWidth: '7rem'
});

export const getExchangeListContainerStyle = (theme) => ({
    display: 'flex',
    alignItems: 'center'
});