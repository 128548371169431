import endpointRequest from './endpoint-request';
import config from './../config';
import ErrorService from './error';

class AttSetService {
    static getAddAttSetRequest(title, attributeList) {
        return {
            title,
            attributeList,
        };
    }

    /* istanbul ignore next */
    static addAttSet({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    }) {
        endpointRequest({
            url: config.url.attSet.add,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getSearchRequest(searchText, page = 0)
    {
        return {
            searchText,
            page,
        };
    }

    /* istanbul ignore next */
    static search({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.attSet.search,
            request, 
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }

    static getDetailsRequest(attSetId)
    {
        return {
            attSetId
        };
    }

    /* istanbul ignore next */
    static getDetails({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.attSet.details,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getRemoveRequest(attSetId)
    {
        return {
            attSetId,
        };
    }

    /* istanbul ignore next */
    static remove({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError, 
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.attSet.remove,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken,
        });
    }
}

export default AttSetService;
