import CrawlerUtil from './crawler/util';
import CrawlerBuilder from './crawler/builder';

const COPY_PREV = 'Prev';
const COPY_NEXT = 'Next';
const COPY_EXIT_MORE = 'Back';
const COPY_EXIT_END = 'Exit';

export class FramesBuilder {
    
    static getPrev(crawler, topCrawler)
    {
        return {
            hasPrev: !CrawlerUtil.isNoPrev(crawler),
            title: COPY_PREV,
            nextCrawler: CrawlerBuilder.getPrev(crawler, topCrawler)
        }
    }

    static getNext(map, crawler, topCrawler)
    {
        const isExit = CrawlerUtil.isNextExit(map, crawler);
        const isBack = isExit ? false : CrawlerUtil.isNextBack(map, crawler);
        return {
            isExit,
            title: isExit ? COPY_EXIT_END : (
                isBack ? COPY_EXIT_MORE : COPY_NEXT
            ),
            nextCrawler: CrawlerBuilder.getNext(map, crawler, topCrawler)
        };
    }

    static getFrameDetails(map, crawler, topCrawler)
    {
        const mapItem = map[crawler.index];
        const {
            moreList,
            ...frameDetails
        } = mapItem;

        return {
            frameDetails: frameDetails[0] ? frameDetails[0] : frameDetails,
            prev: FramesBuilder.getPrev(crawler, topCrawler),
            next: FramesBuilder.getNext(map, crawler, topCrawler), 
            moreOptions: moreList ? moreList.map((moreOption, index) => {
                const headerItem = moreOption[0];
                return {
                    id: headerItem.id,
                    title: headerItem.moreTitle,
                    more: CrawlerBuilder.getMore(index, crawler, topCrawler)
                };
            }) : []
        };
    }

    
}

export default FramesBuilder;
