import React from 'react';
import { useState, useEffect } from 'react';
import theme from './../../theme';
import config from './../../../config';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../../service/resize';

/* istanbul ignore next */
const shortcutList = [
    {
        title: 'Subscription',
        url: '/subscription',
        details: `Pay for what you wish to keep.`
    }, 
    {
        title: 'Info',
        url: '/profile/info',
        details: 'News, Help, TOS, Fee details, and issue reporting'
    },
    {
        title: 'Manage Account',
        url: '/profile/store',
        details: 'Manage your images, data and settings'
    },
    {
        title: 'Users & Networks',
        url: '/profile/people',
        details: `Search users, ${config.features.allowCarrierGroups ? 'form carrier groups, ' : ''}Beartarian platforms, and invite new members`
    },
    {
        title: 'Access',
        url: '/profile/pwd',
        details: 'Manage your account access',
    },
    {
        title: 'Online Payments',
        url: '/profile/income',
        details: 'Receive online payments, payment methods, payouts',
    }
];

const Shortcut = ({ title, url, details }) => 
    <NavLink to={url}>
        <div style={{
            ...theme.getProfilePwdSuggestionContainerStyle(theme)
        }}>
            <h3>
                { title }
            </h3>
            <p> 
                { details }
            </p>
        </div>
    </NavLink>

const ProfileLandingPanel = () => {
    
    const getExContainerStyle = () => theme.getExchangesContainerStyle(theme);
    const [ exContainerStyle, setExContainerStyle ] = useState( getExContainerStyle() );
    
    const getShortCutStyle = () => theme.getProfilePeopleShortCutOuterContainerStyle(theme);
    const [ shortCutStyle, setShortCutStyle ] = useState( getShortCutStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setExContainerStyle( getExContainerStyle() );
        setShortCutStyle( getShortCutStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <div style={exContainerStyle}>
                <div style={{ 
                    ...theme.getInLineTextStyle(theme),
                }}>
                    <h2 >
                        Manage
                    </h2>
                </div>

                <div style={shortCutStyle}>

                    {
                        shortcutList.map((cut, index) => 
                            <Shortcut 
                                details={cut.details} 
                                title={cut.title} 
                                url={cut.url} 
                                key={index} 
                            />)
                    }

                </div>
            </div>

        </section>
    );
};

export default ProfileLandingPanel;
