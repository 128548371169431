import React from 'react';
import AdminPageTemplate from './template/admin-page';
import ReportedIssuesPanel from './../ui/panel/admin/reported-issues';

const ReportedIssuesPage = () => 
    <AdminPageTemplate>
        <ReportedIssuesPanel />
    </AdminPageTemplate>

export default ReportedIssuesPage;
