import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import UiService from './../../../service/ui';
import ResizeService from './../../../service/resize';
import theme from './../../theme';

const AvailableInviteUi = ({invite}) => {
    const [copied, setCopied] = useState(false);
    return (
        <span>
            <CopyToClipboard onCopy={event => { setCopied(true) }} text={invite.inviteCode}>
                <button className="test-userInvitesCopyBtn">
                    { copied ? 'Copied' : 'Copy' }
                </button>
                
            </CopyToClipboard>
        </span>
    );
}

const PendingInviteUi = (invite, index) => 
    <div 
        style={{
            ...theme.getPendingInviteStyle(theme),
        }}
        key={index}>
            <div style={{
                ...theme.getPendingInviteCodeStyle(theme),
            }}>
                { invite.inviteCode } 
            </div>
            
            <div style={{
                ...theme.getPendingInviteAvailableStyle(theme),
            }}>
                { invite.isAvailable ? <AvailableInviteUi invite={invite} /> : ' No longer active' }
            </div>
    </div>

const UserJoinedUi = (user, index) => 
    <div
        style={{
            ...theme.getUserJoinedStyle(theme),
            ...theme.getGeneralTextLinkStyle(theme)
        }}
        key={index}>
        <NavLink to={`/user/${user.id}`}>
            { `${user.username} joined ${UiService.timeSince(user.createdOn)}` }
        </NavLink>
    </div>

const UsersJoinedUi = (list) => list.length > 0 ? 
    <section>
        <h3>
            Users Invited By You
        </h3>
        <div>
            { list.map((invite, index) => invite.inviteAcceptedBy.map((user, index) => UserJoinedUi(user, index))) }
        </div>
    </section> : ''

/* istanbul ignore next */
const InviteCodesUi = (list) => list.length < 1 ? '' :
    <section>
        <h3>
            { list.length > 1 ? 'Invite Codes' : 'Invite Code' }
        </h3>
        <div>
            { list.map((invite, index) => PendingInviteUi(invite, index)) }
        </div>
        
    </section>

const UserInviteListUi = (list) => {
    const joinList = list.filter(invite => invite.inviteAcceptedBy.length > 0);
    const inviteList = list.filter(invite => invite.inviteAcceptedBy.length < 1);
    
    return (
        <section>
            
            { InviteCodesUi(inviteList) }
            
            { UsersJoinedUi(joinList) }

        </section>
    );
};
    
const UserInvitesViewPanel = ({ data: invitesDetail }) => {
    const getContainerStyle = () => ({
        ...theme.getProfilePwdBodyStyle(theme),
        ...theme.getProfilePwdPageAddPadTopStyle(theme)
    });
    const [ containerStyle, setContainerStyle ] = useState( getContainerStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setContainerStyle( getContainerStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <div style={containerStyle}>
                <div style={{
                    ...theme.getAbstractCenterFlexContainerStyle(theme),
                }}>
                    { invitesDetail.list.length > 0 ? UserInviteListUi(invitesDetail.list) : 'You have not yet been issued any invites' }
                </div>
                <h3> 
                    <NavLink 
                        style={{...theme.getGeneralTextLinkStyle(theme) }}  
                        to="/"
                    >   
                        Go to your Dashboard
                    </NavLink>
                </h3>
            </div>
        </section>
    );
};

export default UserInvitesViewPanel;
