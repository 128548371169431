import React from 'react';
import UiService from './../../../service/ui';

const HistoryDetail = ({ historyAction }) => 
    <div>
        {`${historyAction.username}  ${historyAction.action}  ${UiService.timeSince(historyAction.createdOn)}`}
    </div>

const PopulatedView = ({
    history
}) => 
    <div>
        {
            history.authorList.map((historyAction, index) => 
                <HistoryDetail 
                    historyAction={historyAction}
                    key={index}
                />
            )
        }
    </div>

const EmptyView = () => 
    <div>
        <p>
            No editing history
        </p>
    </div>
                                                            

const CarrierGroupPlanDeliveryEditHistoryViewPanel = ({ data }) => data.history.authorList.length < 1 ? <EmptyView /> : 
    <PopulatedView 
        history={data.history}
    />

export default CarrierGroupPlanDeliveryEditHistoryViewPanel;
