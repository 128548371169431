import React from 'react';
import AdminPageTemplate from './template/admin-page';
import AdminLedgerSearchPrompt from './../ui/prompt/admin-ledger-search';
import theme from './../ui/theme';

const AdminLedgerPage = (parms) => 
    <AdminPageTemplate>
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <h2>
                Admin Ledger
            </h2>
            <AdminLedgerSearchPrompt />
        </div>
    </AdminPageTemplate>

export default AdminLedgerPage;
