export const getOfferSearchResultCenterStyle = (theme) => ({
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
});

export const getOfferSearchResultStyle = (theme) => ({
    display: 'grid',
    gap: '.75rem 1.5rem',
    margin: theme.isTablet() ? '.75rem' : '.75rem 0',
    gridTemplateColumns: theme.isTablet() ? 'repeat(auto-fill, 20rem)' : 'repeat(auto-fill, 100%)',
    width: '100%',
    justifyContent: 'center',
});

export const getOfferSearchResultBidsContainerStyle = (theme) => ({
    display: 'flex',
    margin: '.5rem .75rem 1rem',
    gap: '.75rem',
    justifyContent: 'start',
    flexWrap: 'wrap',
});

export const getOfferSearchResultTileContainerStyle = (theme) => ({
    width: theme.isTablet() ? 'auto' : '100%',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    padding: '.5rem'
});

export const getOfferSearchResultTileStyle = (theme) => ({
    display: 'flex',
    margin: '0 0 .75rem',
    width: theme.isTablet() ? '19rem' : '100%',
    boxShadow: '0 .05rem .05rem rgba(29, 29, 29, .3)',
    flexDirection: theme.isTablet() ? 'row' : 'column',
});

export const getOfferSearchResultDetailsStyle = (theme) => ({
    width: theme.isTablet() ? '19rem' : '100%',
});

export const getOfferSearchResultNoExchangeStyle = (theme) => ({
    textAlign: 'center',
    fontSize: '18pt',
    fontWeight: '700',
    padding: '0 0 .75rem 0',
});

export const getOfferSearchResultImageStyle = (theme) => ({
    width: theme.isTablet() ? 'auto' : '100%', 
    height: theme.isTablet() ? 'auto' : 'fit-content',
    maxWidth: theme.isTablet() ? '8.75rem' : 'auto',
    maxHeight: theme.isTablet() ? '8.75rem' : 'auto',
    borderRadius: theme.isTablet() ? '.15rem' : '0',
    padding: theme.isTablet() ? '.5rem 0 .5rem .5rem' : '.5rem' ,
    margin: 'auto'
});

export const getOfferSearchResultNoImageStyle = (theme) => ({
    width: theme.isTablet() ? '7.5rem' : 'calc(100% - 1.5rem)',
    height: theme.isTablet() ? '7.5rem' : 'auto',
    margin: '.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '.15rem',
    color: theme.selectedTextColor,
    backgroundColor: theme.navColor,
    textAlign: 'center',
    minHeight: theme.isTablet() ? '7.5rem' : '12rem',
    flexShrink: '0',
});

export const getOfferSearchResultTimeStyle = (theme) => ({
    margin: '.75rem',
    textAlign: theme.isTablet() ? 'left' : 'center',
});

export const getOfferSearchSingleFileContainerFileStyle = (theme) => ({
    margin: '0 auto 1rem', 
    maxWidth: theme.isDesktop() ? '60rem' : '100%',
    width: '100%',
    minWidth: '15rem',
    display: 'flex',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: theme.isPhone() ? 'row' : 'column',
});

export const getOfferSearchSingleFormStyle = (theme) => ({
    flexGrow: '30',
    margin: '0 0 0 .5rem',
    width: '100%',
});

export const getOfferSearchSingleFormContainerStyle = (theme) => ({
    margin: '.5rem .75rem',
    flexGrow: '30',
});

export const getOfferSearchAddOfferButtonStyle = (theme, icon) => ({
    width: '4.5rem',
    height: '3.5rem',
    margin: '.75rem .75rem 0 .75rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '3rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
});

export const getOfferSearchFilterIconStyle = (theme, icon) => ({
    width: '2.25rem',
    height: '2.25rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.75rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
    margin: '.5rem 0 .5rem .75rem'
});

export const getOfferSearchAddOfferButtonTextStyle = (theme, icon) => ({
    textAlign: 'center',
    fontWeight: '750',
    fontSize: '11pt',
    lineHeight: '.9rem',
    margin: '0 .75rem .75rem .75rem',
});
