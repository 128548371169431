import React from 'react';
import theme from './../../../theme';

const TripStop = ({
    stop,
    cargoItem
}) => 
    <div>
        <p>
            {`${stop.isPickup ? 'Pickup' : 'Drop off'} cargo delivery ID ${cargoItem.deliveryId}`}
        </p>
        <p style={{
            ...theme.getLocationMailingAddressStyle(theme)  
        }}>
            At &nbsp;
            {stop.location.mailingAddress}
        </p>
        
        <hr />
    </div>

export default TripStop;
