import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import ProfileIncomeLandingPanel from './../ui/panel/profile-income-landing';

const ProfileIncomePage = () => 
    <AuthPageTemplate childMenu={
        <ProfileMenu />
    }>
        <ProfileIncomeLandingPanel />
    </AuthPageTemplate>

export default ProfileIncomePage;
