import React from 'react';
import { useState, useRef, useEffect } from 'react';
import menuData from './menu-data';
import menuDetails from '../../../../../store/select/menu-details';
import { useSelector } from 'react-redux';
import theme from './../../../../theme';
import ReactPlayer from 'react-player'
import ResizeService from './../../../../../service/resize';
import config from './../../../../../config';

const getPageByKey = (key) => menuData.find(m => m.key === key);

const Menu = ({ setSelectedPage }) => {
    const getHelpMenuStyle = () => theme.getHelpMenuStyle(theme);
    const [ helpMenuStyle, setHelpMenuStyle] = useState(getHelpMenuStyle() );
    
    const getUserWidgetFlexParentStyle = () => theme.getUserWidgetFlexParentStyle(theme);
    const [ userWidgetFlexParentStyle, setUserWidgetFlexParentStyle] = useState(getUserWidgetFlexParentStyle());
        
    const getUserWidgetHeaderTextStyle = () => theme.getUserWidgetHeaderTextStyle(theme);
    const [ userWidgetHeaderTextStyle, setUserWidgetHeaderTextStyle] = useState(getUserWidgetHeaderTextStyle());

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setHelpMenuStyle(getHelpMenuStyle());
        setUserWidgetFlexParentStyle( getUserWidgetFlexParentStyle());
        setUserWidgetHeaderTextStyle( getUserWidgetHeaderTextStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getHelpMenuContainerStyle(theme),
        }}>
            <div style={userWidgetHeaderTextStyle}>
                <div>
                    Help Topics
                </div>
            </div>
            <div style={userWidgetFlexParentStyle}>
                {
                    menuData
                        .filter(aPage => aPage.top)
                        .map((aPage, index) => 
                        <div 
                            style={helpMenuStyle}
                            className="test-userLandingWidgetHelpTitleBtn"
                            onClick={event => { setSelectedPage(aPage.key) }}
                            key={index}
                        >
                            { aPage.title }
                        </div>
                    )
                }
            </div>
        </div>
    );
};


const PageContent = ({ page, setSelectedPage }) => {
    const {
        showFullMenu
    } = useSelector(menuDetails);

    const getVideoUrl = () => theme.isDesktop() ? 
        `${config.url.videoDir}desktop-${page.videoFile}` :
            `${config.url.videoDir}mobile-${page.videoFile}`

    return (
        <section>
            <h3 style={{ 
                ...theme.getGeneralTextStyle(theme),
            }}>
                { page.title }
            </h3>
            <p style={{
                ...theme.getHelpPageContentDetailStyle(theme),
            }}>
                { page.details }
            </p>
        

            { 
                page.relatedItems.length > 0 && 
                <div style={{
                    ...theme.getHelpMenuContainerStyle(theme),
                }}>
                    <h3 style={{ 
                        ...theme.getGeneralTextStyle(theme),
                    }}>
                        Related Topics
                    </h3>
                
                        {
                            page.relatedItems.map((item, index) => 
                                <div 
                                    style={{
                                        ...theme.getHelpRelatedTopicStyle(theme),
                                    }}
                                    key={index}
                                    className="test-userLandingWidgetHelpRelatedTitleBtn"
                                    onClick={ event => { setSelectedPage(item.key) }}
                                >
                                    { item.title }
                                </div>
                            )
                        }
                </div>
            }

            {
                /* istanbul ignore next */
                page.videoFile !== null ?
                <div style={{
                    height: '645px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center'

                }}>
                    {
                        showFullMenu && 
                        <ReactPlayer 
                            loop={true} 
                            playing={true} 
                            url={getVideoUrl()} 
                            controls={true}
                            width='100%'
                        />
                    }
                </div> :
                <div style={{
                    ...theme.getHelpPageContentImagesContainerStyle(theme),
                }}>
                    {
                        page.imgList.map(
                            /* istanbul ignore next */
                            (img, index) => 
                                <div 
                                    key={index}
                                    style={{
                                        ...theme.getHelpImageStyle(theme, img),
                                }}>

                                </div>
                        )
                    }
                </div>

            }

        </section>
    );
}

const PageDetail = ({ selectedPage, setSelectedPage }) => {
    const page = getPageByKey(selectedPage);
    return (
        <section>
            <div style={{
                ...theme.getHelpMenuContainerStyle(theme),
            }}>
                <div 
                    style={{
                        ...theme.getHelpToMenuStyle(theme),
                    }}
                    className="test-userLandingWidgetHelpMainBtn"
                    onClick={event => { setSelectedPage(null) }}
                >
                    Help Topics
                </div>
            </div>
            <PageContent page={page} setSelectedPage={setSelectedPage} />
        </section>
    );
};

const HomePage = ({ setSelectedPage } ) => 
    <section>
        <Menu setSelectedPage={setSelectedPage} />
    </section>

const WidgetHelpPanel = (parms) => {
    const helpRef = useRef(null);
    const [ selectedPage, setSelectedPage ] = useState(null);
    const loadPage = (pageKey) => {
        /* istanbul ignore next */
        if(helpRef.current.scrollIntoView)
        {
            helpRef.current.scrollIntoView();
        }
        setSelectedPage(pageKey);
    };
    return <section>
            <span ref={helpRef}></span>
        {
            selectedPage === null ? 
                <HomePage 
                    setSelectedPage={loadPage}
                /> : 
                <PageDetail 
                    setSelectedPage={loadPage}
                    selectedPage={selectedPage} 
                />
        }
    </section>
};

export default WidgetHelpPanel;
