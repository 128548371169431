import React from 'react';
import PayService from './../../../service/pay';
import { AbstractPrompt } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import theme from '../../theme';

class _UpdatePayAccountPrompt extends AbstractPrompt 
{
    constructor(parms)
    {
        super(parms, _UpdatePayAccountPrompt.getValidators());
        this.getDefaultState = this.getDefaultState.bind(this);
        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getConfirmUi = this.getConfirmUi.bind(this);
        this.getSubmitButtonUi = this.getSubmitButtonUi.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.getPromptUi = this.getPromptUi.bind(this);

        this.state = this.getDefaultState();
    }

    static getValidators()
    {
        return {

        };
    }

    /* istanbul ignore next */
    onAuthError()
    {
        this.props.onLogout();
    }

    /* istanbul ignore next */
    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            
        };
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        const fields = this.getFieldsModel();
        return {
            ...baseState,
            ...fields,
            isValid: true
        };
    }

    /* istanbul ignore next */
    onSendToServer()
    {
        PayService.updateAccount({
            onResponse: this.onServerResponse,
            onError: this.onServerError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    getConfirmUi(confirmMessage = 'Redirecting...')
    {
        return super.getConfirmUi(confirmMessage);
    }

    getSubmitButtonUi(buttonText = 'Update Online Payment Profile')
    {
        return (
            <div style={{
                ...theme.getAbstractPromptStyle(theme),
            }}>
                { super.getSubmitButtonUi(buttonText) }
            </div>
        );
    }

    /* istanbul ignore next */
    onServerResponse(response)
    {
        if(this.props.onAccountUpdated)
        {
            this.props.onAccountUpdated(response);
        }
    }
}

const UpdatePayAccountPrompt = connect(mapStateToProps, mapDispatchToProps)(_UpdatePayAccountPrompt);
export default UpdatePayAccountPrompt;
