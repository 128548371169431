import config from './../config';
import ErrorService from './error';
import endpointRequest from './endpoint-request';

class CheckoutService 
{
    static getStartRequest(
        type,
        targetId,
        details,
        obtainMethod,
        bidId = '',
        selectedLocation = ''
    )
    {
        return {
            type,
            offerId: type === 'offer' ? targetId : undefined,
            seekId: type === 'seek' ? targetId: undefined,
            details,
            obtainMethod,
            bidId: type === 'seek' ? bidId : undefined,
            selectedLocation: type === 'offer' ? selectedLocation : undefined,
        };
    }

    /* istanbul ignore next */
    static start({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.checkout.start,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getMessageFromPaymentIntent(paymentIntent)
    {
        switch (paymentIntent.status) {
            case "succeeded":
              return "Payment Successful";
              
            case "processing":
              return "Processing";

            case "requires_payment_method":
              return "Payment unsuccessful, please try again.";

            default:
              return "Something went wrong.";
          }
    }

    static getPayConfirmRequest(paymentIntentId)
    {
        return {
            paymentIntentId
        };
    }

    /* istanbul ignore next */
    static checkPayConfirm({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.exchange.confirmPay,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }

    static getIsCheckoutAvailableRequest(type, targetId)
    {
        return {
            type,
            targetId
        };
    }

    /* istanbul ignore next */
    static isCheckoutAvailable({
        request, 
        onResponse, 
        onError, 
        authToken, 
        onAuthError, 
        onFatalError,
        onNetworkOffline
    })
    {
        endpointRequest({
            url: config.url.user.checkout.isAvailable,
            request,
            onResponse,
            onError: ErrorService.makeHandle({
                onError, 
                onAuthError, 
                onFatalError,
                onNetworkOffline,
                authToken
            }),
            authToken
        });
    }
}

export default CheckoutService;
