import React from 'react';
import AdminPageTemplate from './template/admin-page';
import SearchApiLogPrompt from './../ui/prompt/admin/search-api-log';
import { useParams } from 'react-router-dom';
import theme from './../ui/theme';

const AdminSearchApiLogPage = () => {
    const { service } = useParams();
    return (
        <AdminPageTemplate>
            <h2 style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                API Logs
            </h2>
            <SearchApiLogPrompt service={service} />
        </AdminPageTemplate>
    );
};

export default AdminSearchApiLogPage;
