import React from 'react';
import AdminPageTemplate from './template/admin-page';
import SearchIpPrompt from './../ui/prompt/admin/search-ip';
import theme from './../ui/theme';

const AdminIpPage = () => 
    <AdminPageTemplate>
        <h2 style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            IP Addresses
        </h2>
        <SearchIpPrompt />
    </AdminPageTemplate>

export default AdminIpPage;
