import React from 'react';
import theme from './../../../theme';
import UiService from './../../../../service/ui';

const TripSummary = ({
    trip,
    selectedMemberId,
    onAddStopClicked
}) => 
    <div>
        {
            trip.carrierMemberId !== selectedMemberId && 
                <div>
                    <strong>
                        This trip is not aligned to the selected member
                    </strong>
                </div>
        }

        {
            trip.estimatedStart && 
                <div>
                    <span>
                        Estimated Start
                    </span>
                    <span>
                        { UiService.getStoryDate(trip.estimatedStart) }
                    </span>    
                </div>
        }

        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            <span>
                From
            </span>
            <p>
                { `${trip.from.city}, ${trip.from.shortState ? trip.from.shortState : trip.from.state}` }
            </p>
        </div>

        <div>
            <button 
                className="test-carrierGroupPlanDeliveryAlignTripsTripSummaryAddStopBtn"
                onClick={onAddStopClicked}
            >
                Add Stop
            </button>
        </div>


        {
            trip.estimatedEnd && 
                <div>
                    <span>
                        Estimated End
                    </span>
                    <span>
                        { UiService.getStoryDate(trip.estimatedEnd) }
                    </span>    
                </div>
        }

        <div style={{
            ...theme.getLocationMailingAddressStyle(theme)
        }}>
            <span>
                To
            </span>
            <p>
                { `${trip.to.city}, ${trip.to.shortState ? trip.to.shortState : trip.to.state}` }
            </p>
        </div>


    </div>

export default TripSummary;
