export const getBidPromptStyle = (theme) => ({
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getBidDetailsStyle = (theme) => ({
    width: theme.isTablet() ? 'fit-content' : '100%',
    minWidth: '14rem',
    backgroundColor: theme.buttonColor,
    borderRadius: '.25rem',
    height: 'fit-content',
    boxShadow: '0 .075rem .075rem rgba(29, 29, 29, .5)',
    padding: '.5rem .75rem'
});

export const getBidStatusTextStyle = (theme, bid) => ({
    color: !bid.rejected ? theme.secondaryColor : theme.warningColor,
});

export const getBidPopulatedPanelStyle = (theme) => ({
    fontWeight: 'bold',
});

export const getBidPopulatedPanelContainerStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    gap: '.75rem',
    margin: '.5rem .75rem .1rem'
});
