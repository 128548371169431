import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import config from './../config';
import theme from './../ui/theme';
import CarrierGroupInvitesPanel from './../ui/panel/carrier-group-invites';

const CarrierGroupInvitesPage = () => 
    <AuthPageTemplate childMenu={ <ProfileMenu /> }>
        {
            /* istanbul ignore next */
            config.features.allowCarrierGroups ? 
                <div style={{
                    ...theme.getGeneralTextStyle(theme)
                }}>
                    <h3>
                        Review Join Invites From Carrier Companies
                    </h3>

                    <CarrierGroupInvitesPanel />

                </div> : 
                    <p style={{
                        ...theme.getGeneralTextStyle()
                    }}>
                        This feature is currently disabled
                    </p>
        }
    </AuthPageTemplate>

export default CarrierGroupInvitesPage;
