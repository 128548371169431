import React from 'react';
import LoadingIcon from './../../../img/loading.gif';
import theme from './../../theme';

const SplashContactingServerPanel = () => 
    <div style={{
        ...theme.getSplashContactingStyle(theme, LoadingIcon),
    }}></div>

export default SplashContactingServerPanel;
