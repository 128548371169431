import React from 'react';
import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Verify2FAPrompt from './../../prompt/verify-2fa';
import theme from './../../theme';
import ResizeService from './../../../service/resize';

const TwoFAClient = () => 
    <p> 
        <a 
            style={{
                ...theme.getGeneralTextLinkStyle(theme)
            }}
            href="https://authy.com/" 
            target="_blank" 
            rel="noreferrer"
        >
            Need a 2FA Client?
        </a>
    </p>

const VerifiedKeyView = ({ data, onToggleEnabled, onDeleteToken }) => {
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <h2>
                Two-Factor Authentication    
            </h2>
            <div style={bodyStyle}>
                <p>
                    {
                        data.enabled ? '2FA is Enabled' : '2FA is Disabled'
                    }
                </p>

                <input type="checkbox" checked={data.enabled} onChange={onToggleEnabled} />
                <span>
                    { data.label }
                </span>
                <button 
                    className="test-twoFAPanelDeleteBtn"
                    onClick={event => { onDeleteToken() }}
                >
                    Delete
                </button>
            </div>
        </section> 
    );
};

const UnVerifiedKeyDetailsViewHasKey = ({ data }) => {
    const [copied, setCopied] = useState(false);
    return (
        <section>
            <div>
                <strong style={{
                    ...theme.getWarningTextStyle(theme)
                }}>
                    WARNING 
                    <br />
                    This is the only time this information will be displayed!
                    <br />
                    <br />
                    If you lose this information you will be unable to access your account.
                </strong>
            </div>
            <div>
                <h3>
                    1a) Scan into 2FA Client
                </h3>
                <img src={data.qrCode} alt="QR Code" />
            </div>
            <div>
                <h3>
                    Or
                    <br />
                    1b) Copy code
                </h3>
                <CopyToClipboard onCopy={event => { setCopied(true) }} text={data.secret}>
                    <button className="test-twoFACopyBtn">
                        { copied ? 'Copied' : 'Copy' }
                    </button>
                </CopyToClipboard>
            </div>
        </section>
    );
};

const UnVerifiedKeyDetailsViewKeyUnavailable = ({ data, onDeleteToken }) => 
    <section>
        <h3>
            1) code is no longer available
        </h3>
        <input type="checkbox" checked={data.enabled} disabled={true} />
        <span>
            { data.label }
        </span>
        <button 
            className="test-twoFAPanelNoLongerDeleteBtn"
            onClick={event => { onDeleteToken() }}
        >
            Delete
        </button>
    </section>

const UnVerifiedKeyDetailsView = ({ data, onDeleteToken }) => data.url ? 
    <UnVerifiedKeyDetailsViewHasKey 
        data={data} 
    /> : 
    <UnVerifiedKeyDetailsViewKeyUnavailable 
        data={data} 
        onDeleteToken={onDeleteToken} 
    />

const UnVerifiedKeyView = ({ 
    data, 
    onVerified, 
    onDeleteToken,
    panelStyle
}) => 
    <section>
        <h2>
            Two-Factor Authentication    
        </h2>
        <div style={panelStyle}>
            <UnVerifiedKeyDetailsView data={data} onDeleteToken={onDeleteToken} />
            <h3>
                2) Save the code in your 2FA client
            </h3>
            
            <TwoFAClient />
            
            <h3>
                3) Enter the current 2FA code from your client
            </h3>
            <Verify2FAPrompt onVerified={onVerified} />
        </div>
    </section>


const KeyView = ({ 
    data, 
    onToggleEnabled, 
    onVerified, 
    onDeleteToken,
    panelStyle
}) => data.verified ? 
    <VerifiedKeyView 
        onDeleteToken={onDeleteToken}
        onToggleEnabled={onToggleEnabled} 
        data={data} 
    /> : <UnVerifiedKeyView 
            onDeleteToken={onDeleteToken} 
            onVerified={onVerified} 
            panelStyle={panelStyle}
            data={data} 
        />
    

const NoKeyView = ({ onGenerateToken, panelStyle }) => 
    <section>
        <h2>
            Two-Factor Authentication    
        </h2>
        <div style={panelStyle}>
            <p>
                Keep your account as secure as possible by enabling 2FA.
            </p>
            <TwoFAClient />
            <button 
                className="test-twoFAPanelGenerateTokenBtn"
                onClick={event => { onGenerateToken() }}
            >
                Generate Token
            </button>
        </div>
    </section>

const TwoFAViewPanel = ({ 
    data, 
    onGenerateToken, 
    onToggleEnabled, 
    onVerified, 
    onDeleteToken 
}) => {
    const getPanelStyle = () => theme.getProfilePwdPanelStyle(theme);
    const [ panelStyle, setPanelStyle ] = useState( getPanelStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setPanelStyle( getPanelStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div style={{
            ...theme.getTwoFaPanelStyle(theme),
        }}>
            { 
                data.hasToken ? 
                    <KeyView 
                        onToggleEnabled={onToggleEnabled} 
                        onVerified={onVerified} 
                        data={data} 
                        panelStyle={panelStyle}
                        onDeleteToken={onDeleteToken} 
                    /> : 
                    <NoKeyView 
                        onGenerateToken={onGenerateToken} 
                        panelStyle={panelStyle}
                    /> 
            }
        </div>
    );
};

export default TwoFAViewPanel;
