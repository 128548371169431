import React from 'react';
import { useState } from 'react';
import Pagination from './../../prompt/core/pagination';
import theme from './../../theme';
import Dialog from '@mui/material/Dialog';
import CurrencyService from './../../../service/currency';
import Contributions from '../carrier-group-plan-delivery/plan-pricing/contributor/contributions';
import CarrierGroupNavMenu from './../../menu/carrier-group/carrier-group-nav-menu';

const getPaginationUi = ({ data, onLoadSpecifiedPage }) => <Pagination 
                                                            total={data.total}
                                                            page={data.page}
                                                            onLoadSpecifiedPage={onLoadSpecifiedPage}
                                                            perPage={data.perPage} />

const PendingJob = ({
    job
}) => {
    const [ showDetails, setShowDetails ] = useState(false);
    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)  
        }}>
            <p>
                { CurrencyService.usd(job.payout, false) }
            </p>

            <button 
                className="test-carrierJobListPendingJobDetailsBtn"
                onClick={event => {
                setShowDetails(true)
            }}>
                Details
            </button>

            <Dialog
                onClose={
                    /* istanbul ignore next */
                    event => { setShowDetails(false) }
                }
                open={showDetails}
            >
                <Contributions 
                    contributionsList={job.contributions}
                />
            </Dialog>

        </div>
    );
};

const EmptyView = () => 
    <div>
        <p>
            No Pending Jobs Found
        </p>
    </div>

const PopulatedView = ({ 
    pendingContributions, 
    onLoadSpecifiedPage
}) => 
    <div>
        <h3>
            Possible Jobs
        </h3>

        { getPaginationUi({ data: pendingContributions, onLoadSpecifiedPage }) }

        {
            pendingContributions.list.map((pendingContribution, index) => 
                <PendingJob 
                    job={pendingContribution} 
                    key={index} 
                />
            )
        }

        { getPaginationUi({ data: pendingContributions, onLoadSpecifiedPage }) }

    </div>

const CarrierJobListViewPanel = ({
    data,
    onLoadSpecifiedPage,
    carrierGroupId
}) => 
    <div style={{
        ...theme.getGeneralTextStyle(theme)
    }}>
        <CarrierGroupNavMenu
            carrierGroupName={data.carrierGroupName}
            carrierGroupId={carrierGroupId}
        />
        {
            data.pendingContributions.list.length < 1 ? 
                <EmptyView /> : 
                    <PopulatedView 
                        pendingContributions={data.pendingContributions}
                        onLoadSpecifiedPage={onLoadSpecifiedPage}
                    />
        }
    </div>

export default CarrierJobListViewPanel;
