import React from 'react';
import PayoutService from './../../../service/payout';
import ValidateService from './../../../service/validate';
import { FORM_FIELD } from './../core/abstract';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../core/search';
import { Header, AdminPayoutsSearchViewPrompt }  from './view';
import theme from './../../theme';
import AdminPayTransDetailsPanel from './../../panel/admin/admin-pay-trans-details';
import Dialog from '@mui/material/Dialog';

const FILTER_ALL = 'all';
const FILTER_FLAGGED = 'flagged';
const FILTER_NOT_FLAGGED = 'not-flagged';
const FILTER_PAID = 'paid';
const FILTER_NOT_PAID = 'not-paid';
const FILTER_FAILED = 'failed';
const FILTER_NOT_FAILED = 'not-failed';
const FILTER_REFUNDED = 'refund';
const FILTER_NOT_REFUNDED = 'not-refunded';


class _AdminPayoutsSearchPrompt extends SearchPrompt 
{
    constructor(parms)
    {
        super(parms, _AdminPayoutsSearchPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
        this.getNoResultsUi = this.getNoResultsUi.bind(this);
        this.setDialogOpened = this.setDialogOpened.bind(this);
        this.selectPayoutRecord = this.selectPayoutRecord.bind(this);
        this.getSelectedPayoutRecordUi = this.getSelectedPayoutRecordUi.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
    }

    static getValidators()
    {
        return {
            exchangeId: {
                isValid: ValidateService.anyValue
            },
            status: {
                isValid: ValidateService.anyValue
            },
        };
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            exchangeId: {
                name: 'Exchange ID',
                error: '',
                value: '',
                visible: true,
                touched: false,
                type: FORM_FIELD.TEXT
            },
            status: {
                name: 'Status',
                error: '',
                value: FILTER_ALL,
                valueList: [
                    {
                        name: 'Any',
                        value: FILTER_ALL
                    },
                    {
                        name: 'Flagged',
                        value: FILTER_FLAGGED
                    },
                    {
                        name: 'Not Flagged',
                        value: FILTER_NOT_FLAGGED
                    },
                    {
                        name: 'Paid Out',
                        value: FILTER_PAID
                    },
                    {
                        name: 'Not Paid Out',
                        value: FILTER_NOT_PAID
                    },
                    {
                        name: 'Failed',
                        value: FILTER_FAILED
                    },
                    {
                        name: 'Not Failed',
                        value: FILTER_NOT_FAILED
                    },
                    {
                        name: 'Refunded',
                        value: FILTER_REFUNDED
                    },
                    {
                        name: 'Not Refunded',
                        value: FILTER_NOT_REFUNDED
                    }
                ],
                visible: true,
                valid: true,
                touched: false,
                type: FORM_FIELD.DROP
            },
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'payoutList');
    }

    onSendToServer(request = undefined)
    {
        PayoutService.adminSearch({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        }); 
    }

    getDefaultState(store)
    {
        const baseState = super.getDefaultState(store);
        return {
            ...baseState,
            dialogOpen: false, 
            selectedPayoutRecord: null
        };
    }

    /* istanbul ignore next */
    setDialogOpened(dialogOpen)
    {
        const nextState = {
            ...this.state,
            dialogOpen
        };
        this.setState(nextState);
    }

    selectPayoutRecord(selectedPayoutRecord)
    {
        const nextState = {
            ...this.state,
            dialogOpen: true,
            selectedPayoutRecord
        };
        this.setState(nextState);
    }

    getServerRequest()
    {
        let request = {
            exchangeId: this.state.exchangeId.value,
            page: 0
        };
        
        /* eslint-disable */
        switch(this.state.status.value)
        {
            case FILTER_FLAGGED:
                request.flagged = true;
                break;

            case FILTER_NOT_FLAGGED:
                request.flagged = false;
                break;

            case FILTER_PAID:
                request.paidOut = true;
                break;

            case FILTER_NOT_PAID:
                request.paidOut = false;
                break;

            case FILTER_FAILED:
                request.payoutFailed = true;
                break;

            case FILTER_NOT_FAILED:
                request.payoutFailed = false;
                break;

            case FILTER_REFUNDED:
                request.hasRefunded = true;
                break;

            case FILTER_NOT_REFUNDED:
                request.hasRefunded = false;
                break;
        }
        /* eslint-enable */
        return request;
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        PayoutService.adminSearch({
            request,
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getNoResultsUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <p>
                    No Payouts Found.
                </p>
            </div>
        );
    }

    getSearchResultUi(aResult, index)
    {
        return <AdminPayoutsSearchViewPrompt trans={aResult} key={index} selectPayoutRecord={this.selectPayoutRecord} />
    }

    getSelectedPayoutRecordUi()
    {
        return (
            <section>
                <Dialog 
                    onClose={ 
                        /* istanbul ignore next */
                        event => { this.setDialogOpened(false) }
                    }
                    open={this.state.dialogOpen}
                >
                    <AdminPayTransDetailsPanel paymentIntentRecord={this.state.selectedPayoutRecord} />
                </Dialog>
            </section>
        );
    }

    getSearchResultsUi()
    {
        return this.state.results.length < 1 ? this.getNoResultsUi() : (
            <div style={{
                ...theme.getAdminPayoutsSearchContainerStyle(theme)
            }}>
                <table>
                    <Header />
                    <tbody>
                        {  this.state.results.map((aResult, index) => this.getSearchResultUi(aResult, index)) }
                    </tbody>
                </table>
                {
                    /* istanbul ignore next */
                    this.state.selectedPayoutRecordId === null ? '' : 
                        this.getSelectedPayoutRecordUi()
                }
            </div>
        );
    }
}

const AdminPayoutsSearchPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminPayoutsSearchPrompt);
export default AdminPayoutsSearchPrompt;
