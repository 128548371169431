import React from 'react';
import { useRef, useState, useEffect } from 'react';
import AddOneIcon from './../../../img/addOne.svg';
import ImgStackIcon from './../../../img/img-stack.svg';
import CloseOverlayIcon from './../../../img/close-overlay.svg';
import theme from './../../theme';
import Dialog from '@mui/material/Dialog';
import AddImgListPanel from './../../panel/add-img-list';
import ResizeService from './../../../service/resize';

/* istanbul ignore next */
const makeOnSelectFile = ({ onAddImg }) => event => {
    const isDrop = event.dataTransfer && event.dataTransfer.files;
    const files = isDrop ? event.dataTransfer.files : 
        event.target.files;
    for(const aFile of files)
    {  
        onAddImg(getImgDetails(aFile));
    }
};

/* istanbul ignore next */
const getImgDetails = file => {
    let previewImage;
    try
    {
        previewImage = URL.createObjectURL(file);
    }
    catch(err)
    {
        previewImage = file;
    }
    return {
        previewImage,
        filename: file.name,
        file
    };
};

const ImgView = ({ img, index, onRemoveImg }) => 
    <div 
        style={{
            ...theme.getImageViewContainerStyle(theme),
        }}
        key={index}
    >
        <div style={{
            ...theme.getImageViewFlexContainerStyle(theme),
        }}>
            <div 
                style={{
                    ...theme.getIconOverlayButtonStyle(theme, CloseOverlayIcon),
                }}
                onClick={
                    /* istanbul ignore next */
                    event => onRemoveImg(index) 
                }
            >
            </div>
            <img src={img.previewImage ? img.previewImage : img.data} alt="File Missing" style={{
                ...theme.getImageViewStyle(theme),
            }}/>
        </div>
    </div>

/* istanbul ignore next */
const EmptyView = ({ drag }) => drag ? 
        <p style={{
            ...theme.getImageTextStyle(theme),
            ...theme.getImageDropTextStyle(theme),
        }}>
            DROP HERE
        </p> :
            <div style={{
                ...theme.getImageTextStyle(theme),
            }}>
            <p>Drag images here </p> 
            <p>or click the add button.</p>
            </div>

/* istanbul ignore next*/
const ImgListPrompt = ({ 
    imgList, 
    promptTitle, 
    onAddImg, 
    onRemoveImg,
    openDialog,
    onCloseDialog,
    savedImgDialogOpen,
    onAddSavedImg
}) => {
    const inputFileRef = useRef(null);
    const dropFileRef = useRef(null);

    const [drag, setDrag] = useState(false);
    const [dragDepth, setDragDepth] = useState(0);

    const onSelectFile = makeOnSelectFile({ onAddImg });

    /* istanbul ignore next */
    const handleDrag = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    const isValidDrag = event => event.dataTransfer.items && event.dataTransfer.items.length > 0;

    /* istanbul ignore next */
    const handleDragIn = event => {
        event.preventDefault();
        event.stopPropagation();
        setDragDepth(dragDepth+1);
        if(isValidDrag(event))
        {
            setDrag(true);
        }
    };

    /* istanbul ignore next */
    const handleDragOut = event => {
        event.preventDefault();
        event.stopPropagation();        
        const nextDragDepth = dragDepth - 1;
        setDragDepth(nextDragDepth);
        setDrag(false);
    };

    /* istanbul ignore next */
    const handleDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        setDrag(false);
        if(isValidDrag(event))
        {
            onSelectFile(event);
            try
            {
                event.dataTransfer.clearData();
            }
            catch(err)
            {

            }
            setDragDepth(0);
        }
    };

    useEffect(() => {

        const ignoreOutside = e => {
            e.preventDefault();
        };
        const dropzone = dropFileRef.current;
        if(dropzone)
        {
            dropzone.addEventListener('dragover', handleDrag);
            dropzone.addEventListener('dragenter', handleDragIn);
            dropzone.addEventListener('dragleave', handleDragOut);
            dropzone.addEventListener('drop', handleDrop);
        
        }
        document.addEventListener('dragover', ignoreOutside);
        document.addEventListener('drop', ignoreOutside);
        return () => {
            if(dropzone)
            {
                dropzone.removeEventListener('dragover', handleDrag);
                dropzone.removeEventListener('dragenter', handleDragIn);
                dropzone.removeEventListener('dragleave', handleDragOut);
                dropzone.removeEventListener('drop', handleDrop);
            }
            document.removeEventListener('dragover', ignoreOutside);
            document.removeEventListener('drop', ignoreOutside);
        };
    });

    const getImgListStyle = () => theme.getImageListContainerStyle(theme);
    const [ imgListStyle, setImgListStyle ] = useState( getImgListStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setImgListStyle( getImgListStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section>
            <h2>
                { promptTitle }
            </h2>

            <div style={{
                    ...theme.getImageFlexStyle(theme),
                }}>    
                    <theme.getIconButtonUi
                        theme={theme}
                        icon={AddOneIcon}
                        onClick={event => { inputFileRef.current.click() }}
                    />

                    <theme.getIconButtonUi 
                        theme={theme}
                        icon={ImgStackIcon} 
                        onClick={event => { openDialog() }} 
                    />

                    <input 
                        style={{display:'none'}} 
                        type="file" 
                        accept="image/*" 
                        onChange={onSelectFile} 
                        multiple 
                        ref={inputFileRef} 
                    />

                    

                </div>

            <div 
                style={imgListStyle}
                ref={dropFileRef}
            >
                { imgList.length < 1 ? <EmptyView drag={drag} /> : imgList.map((img, index) => ImgView({ img, index, onRemoveImg })) }

                <div style={{ clear: 'both' }}></div>
            </div>
            <Dialog
                onClose={onCloseDialog}
                open={savedImgDialogOpen}
            >
                <AddImgListPanel onAddSavedImg={onAddSavedImg} />
            </Dialog>
        </section>
    );
};

export default ImgListPrompt;
