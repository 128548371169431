import React from 'react';
import IssueService from './../../../../service/issue';
import ValidateService from './../../../../service/validate';
import { FORM_FIELD } from './../../core/abstract';
import mapStateToProps from './../../../../store/map/auth-token';
import mapDispatchToProps from './../../../../store/map/on-logout';
import { connect } from 'react-redux';
import SearchPrompt from './../../core/search';
import AdminUserReportedHistoryViewPrompt from './view';
import theme from './../../../theme';

class _AdminUserReportedHistoryPrompt extends SearchPrompt 
{
    constructor(parms)
    {
        super(parms, _AdminUserReportedHistoryPrompt.getValidators());

        this.getFieldsModel = this.getFieldsModel.bind(this);
        this.onSearchResponse = this.onSearchResponse.bind(this);
        this.onSearchError = this.onSearchError.bind(this);
        this.onSendToServer = this.onSendToServer.bind(this);
        this.getServerRequest = this.getServerRequest.bind(this);
        this.getSearchResultUi = this.getSearchResultUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.isUsingSubmitButton = this.isUsingSubmitButton.bind(this);
        this.getSearchResultsUi = this.getSearchResultsUi.bind(this);
    }

    static getValidators()
    {
        return {
            reporterUserId: {
                isValid: ValidateService.anyValue
            }
        };
    }

    isUsingSubmitButton() 
    {
        return false;
    }


    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    getFieldsModel()
    {
        return {
            reporterUserId: {
                name: 'User ID',
                error: 'Specify a userId',
                value: this.props.userId,
                visible: false,
                touched: false,
                type: FORM_FIELD.TEXT
            }
        };
    }

    onSearchResponse(response)
    {
        super.onSearchResponse(response, 'foundReportedIssues');
    }

    onSendToServer(request = undefined)
    {
        IssueService.getUserReportedIssues({
            request: request ? request : this.getServerRequest(),
            onResponse: this.onSearchResponse,
            onError: this.onSearchError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getServerRequest()
    {
        return {
            page: 0,
            reporterUserId: this.state.reporterUserId.value
        };
    }

    onLoadSpecifiedPage(page)
    {
        this.setContactingServer(true);
        let request = this.getServerRequest();
        request.page = page;
        this.onSendToServer(request);
    }

    getNoResultsUi()
    {
        return (
            <div style={{
                ...theme.getGeneralTextStyle(theme)
            }}>
                <p>
                    No reported issues from this user
                </p>
            </div>
        );
    }

    getSearchResultUi(aResult, index)
    {
        return <AdminUserReportedHistoryViewPrompt aReportedIssue={aResult} key={index} />
    }
}

const AdminUserReportedHistoryPrompt = connect(mapStateToProps, mapDispatchToProps)(_AdminUserReportedHistoryPrompt);
export default AdminUserReportedHistoryPrompt;
