import React from 'react';
import { HashRouter, Switch, Route, withRouter } from 'react-router-dom';
import LandingPage from './page/landing';
import MissingPage from './page/missing';
import JoinPage from './page/join';
import ForgotPage from './page/forgot';
import UsersPage from './page/users';
import UserDetailPage from './page/user-detail';
import TrailsPage from './page/trails';
import AdminPage from './page/admin';
import AddTrailPage from './page/add-trail';
import ProfilePage from './page/profile';
import ProfileTrailsPage from './page/profile-trails';
import ProfileUpdatePwdPage from './page/profile-update-pwd';
import OffersPage from './page/offers';
import AddOfferPage from './page/add-offer';
import OfferDetailPage from './page/offer-detail';
import OfferArchivedDetailPage from './page/offer-archived-detail';
import SeeksPage from './page/seeks';
import AddSeekPage from './page/add-seek';
import SeekDetailPage from './page/seek-detail';
import SeekArchivedDetailPage from './page/seek-archived-detail';
import AttSetsPage from './page/att-sets';
import AddAttSetPage from './page/add-att-set';
import AttSetDetailPage from './page/att-set-detail';
import TermsAndConditionsPage from './page/tandc';
import AdminStatsPage from './page/admin-stats';
import AdminSendInvitePage from './page/admin-send-invite';
import AdminReviewFlagsPage from './page/admin-review-flags';
import NoticesPage from './page/notices';
import BidDetailPage from './page/bid-detail';
import ReportIssuePage from './page/report-issue';
import ReportedIssuesPage from './page/reported-issues';
import AdminAddAppNewsPage from './page/admin-add-app-news';
import WidgetPage from './page/widget';
import ExchangeDetailPage from './page/exchange-detail';
import ExchangesPage from './page/exchanges';
import ActiveExchangesPage from './page/active-exchanges';
import ExchangesLandingPage from './page/exchanges-landing';
import PayoutsPage from './page/payouts';
import PaymentMethodsPage from './page/payment-methods';
import TransactionsPage from './page/transactions';
import PostsPage from './page/posts';
import UnsubscribePage from './page/unsubscribe';
import ProfileUpdate2FaPage from './page/profile-update-2fa';
import ProfilePwdPage from './page/profile-pwd';
import ImgListPage from './page/img-list';
import AdminAddTagPage from './page/admin-add-tag';
import AddPayMethodPage from './page/add-pay-method';
import AddPayoutPage from './page/add-payout';
import AdminPayoutsPage from './page/admin-payouts';
import AdminBxePage from './page/admin-bxe';
import PaymentProcessingPage from './page/payment-processing';
import ProfileInfoPage from './page/profile-info';
import ProfileStorePage from './page/profile-store';
import ProfilePeoplePage from './page/profile-people';
import AdminWikiPage from './page/admin-wiki';
import AdminUsersPage from './page/admin-users';
import SubscriptionPage from './page/subscription';
import SubscriptionProcessingPage from './page/subscription-processing';
import FeesPage from './page/fees';
import NewsPage from './page/news';
import HelpPage from './page/help';
import AdminMoneyPage from './page/admin-money';
import AdminDisputeSearchPage from './page/admin-dispute-search';
import ProfileIncomePage from './page/profile-income';
import AdminLedgerPage from './page/admin-ledger';
import AdminLedgerSummaryPage from './page/admin-ledger-summary';
import PrivacyPage from './page/privacy';
import ProfileShopTraitsPage from './page/profile-shop-traits';
import AdminClientStatsPage from './page/admin-client-stats';
import AdminIpPage from './page/admin-ip';
import AdminSearchApiLogPage from './page/admin-search-api-log';
import ReportedIssuePage from './page/reported-issue';
import AdminMaintanceTasksPage from './page/admin-maintance-tasks';
import AdminSearchFinesPage from './page/admin-search-fines';
import UserFinesPage from './page/user-fines';
import TaskCompletedRedirectPage from './page/task-completed-redirect';
import ManageLocationsPage from './page/manage-locations';
import ProfileEmailPage from './page/profile-email';
import ProfileSettingsPage from './page/profile-settings';
import ProfileCarrierGroupsPage from './page/profile-carrier-groups';
import CarrierGroupDetailsPage from './page/carrier-group-details';
import CarrierMembersPage from './page/carrier-members';
import CarrierGroupSearchPage from './page/carrier-groups-search';
import CarrierGroupInvitesPage from './page/carrier-group-invites';
import CarrierLocationsPage from './page/carrier-locations';
import CarrierGroupPendingBidQueuePage from './page/carrier-group-pending-bid-queue';
import CarrierGroupPlanDeliveryPage from './page/carrier-group-plan-delivery';
import CarrierGroupUserTripsPage from './page/carrier-group-user-trips';
import AdminFailedGeoLocationsPage from './page/admin-failed-geo-locations';
import CarrierJobListPage from './page/carrier-job-list';
import AdminSearchLogsPage from './page/admin-search-logs';
import AdminSearchMetricsPage from './page/admin-search-metrics';
import AdminSearchMetricsSummaryPage from './page/admin-search-metrics-summary';
import AdminSearchMetricsGraphPage from './page/admin-search-metrics-graph';
import AdminTestEmailPage from './page/admin-test-email';
import MyReportedIssuesPage from './page/my-reported-issues';
import ReportedIssueDetailsPage from './page/reported-issue-details';

// istanbul ignore next
const Routes = ({ setPayClientSecret, payClientDetails, payClientSecret }) => 
  <HashRouter>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/unsubscribe/:email?" component={UnsubscribePage} />
      <Route path="/terms" component={TermsAndConditionsPage} />
      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/join" component={JoinPage} />
      <Route path="/forgot" component={ForgotPage} />
      <Route path="/fees" component={FeesPage} />
      <Route path="/users/:page?/:searchText?" component={withRouter(UsersPage)} />
      <Route path="/user/:userId" component={UserDetailPage} />
      <Route path="/trails" component={TrailsPage} />
      <Route path="/completed/:redirectType" component={TaskCompletedRedirectPage} />
      <Route path="/admin/api/log/:service" component={AdminSearchApiLogPage} />
      <Route path="/admin/geolocation/failed" component={AdminFailedGeoLocationsPage} />
      <Route path="/admin/reported/issue/:appIssueId" component={ReportedIssuePage} />
      <Route path="/admin/reported/issues" component={ReportedIssuesPage} />
      <Route path="/admin/review/flags" component={AdminReviewFlagsPage} />
      <Route path="/admin/client/stats" component={AdminClientStatsPage} />
      <Route path="/admin/stats" component={AdminStatsPage} />
      <Route path="/admin/maintance-mode" component={AdminMaintanceTasksPage} />
      <Route path="/admin/invite/send" component={AdminSendInvitePage} />
      <Route path="/admin/trail/add" component={AddTrailPage} />
      <Route path="/admin/news/add" component={AdminAddAppNewsPage} />
      <Route path="/admin/fines/search" component={AdminSearchFinesPage} />
      <Route path="/admin/tag/add" component={AdminAddTagPage} />
      <Route path="/admin/disputed" component={AdminDisputeSearchPage} />
      <Route path="/admin/money" component={AdminMoneyPage} />
      <Route path="/admin/payouts" component={AdminPayoutsPage} />
      <Route path="/admin/events" component={AdminBxePage} />
      <Route path="/admin/metrics/graph" component={AdminSearchMetricsGraphPage} />
      <Route path="/admin/metrics/summary" component={AdminSearchMetricsSummaryPage} />
      <Route path="/admin/metrics" component={AdminSearchMetricsPage} />
      <Route path="/admin/users" component={AdminUsersPage} />
      <Route path="/admin/ip" component={AdminIpPage} />
      <Route path="/admin/logs" component={AdminSearchLogsPage} />
      <Route path="/admin/test/email" component={AdminTestEmailPage} />
      <Route exact path="/admin/ledger" component={AdminLedgerPage} />
      <Route path="/admin/ledger/summary" component={AdminLedgerSummaryPage} />
      <Route path="/news" component={NewsPage} />
      <Route path="/admin/wiki" component={AdminWikiPage} />
      <Route exact path="/admin" component={AdminPage} />    
      <Route exact path="/profile" component={ProfilePage} />
      <Route path="/profile/trails" component={ProfileTrailsPage} />
      <Route exact path="/profile/pwd" component={ProfilePwdPage} />
      <Route path="/profile/info" component={ProfileInfoPage} />
      <Route path="/img/list" component={ImgListPage} />
      <Route path="/location/list" component={ManageLocationsPage} />
      <Route path="/profile/pwd/update" component={ProfileUpdatePwdPage} />
      <Route path="/profile/pwd/token" component={ProfileUpdate2FaPage} />
      <Route path="/profile/shop" component={ProfileShopTraitsPage} />
      <Route path="/profile/fees" render={(props) => (
        <UserFinesPage 
          {...props}
          setPayClientSecret={setPayClientSecret} 
          payClientDetails={payClientDetails} 
          payClientSecret={payClientSecret}
        />
      )} />
      <Route path="/help" component={HelpPage} />
      <Route path="/offers/:page?/:searchText?/:tags?" component={withRouter(OffersPage)} />
      <Route path="/offer/add" component={AddOfferPage} />
      <Route path="/offer/:offerId" render={(props) => (
        <OfferDetailPage 
          {...props} 
          setPayClientSecret={setPayClientSecret} 
          payClientDetails={payClientDetails} 
          payClientSecret={payClientSecret}
        />
       )} />
      <Route path="/archived/offer/:offerId" component={OfferArchivedDetailPage} />
      <Route path="/seeks/:page?/:searchText?/:tags?" component={withRouter(SeeksPage)} />
      <Route path="/seek/add" component={AddSeekPage} />
      <Route path="/seek/:seekId" component={SeekDetailPage} />
      <Route path="/archived/seek/:seekId" component={SeekArchivedDetailPage} />
      <Route path="/profile/att-sets/:page?/:searchText?" component={withRouter(AttSetsPage)} />
      <Route path="/profile/att-set/add" component={AddAttSetPage} />
      <Route path="/profile/income" component={ProfileIncomePage} />
      <Route path="/profile/att-set/:attSetId" component={AttSetDetailPage} />
      <Route path="/notices" component={NoticesPage} />
      <Route path="/bid/:bidId" render={(props) => (
        <BidDetailPage 
          {...props} 
          setPayClientSecret={setPayClientSecret} 
          payClientDetails={payClientDetails} 
          payClientSecret={payClientSecret}
        />
      )} />
      <Route path="/profile/report/issue" component={ReportIssuePage} />
      <Route path="/profile/reported/issues" component={MyReportedIssuesPage} />
      <Route path="/profile/reported/issue/:appIssueId" component={ReportedIssueDetailsPage} />
      <Route path="/profile/store" component={ProfileStorePage} />
      <Route path="/profile/email" component={ProfileEmailPage} />
      <Route path="/profile/settings" component={ProfileSettingsPage} />
      <Route path="/profile/people" component={ProfilePeoplePage} />
      <Route exact path="/profile/groups/carrier" component={ProfileCarrierGroupsPage} />
      <Route exact path="/profile/groups/carrier/search" component={CarrierGroupSearchPage} />
      <Route exact path="/profile/groups/carrier/invites" component={CarrierGroupInvitesPage} />
      <Route exact path="/profile/groups/carrier/:carrierGroupId" component={CarrierGroupDetailsPage} />
      <Route path="/profile/groups/carrier/:carrierGroupId/requests" component={CarrierGroupPendingBidQueuePage} />
      <Route path="/profile/groups/carrier/:carrierGroupId/plan/:deliveryId" component={CarrierGroupPlanDeliveryPage} />
      <Route path="/profile/groups/carrier/:carrierGroupId/members" component={CarrierMembersPage} />
      <Route path="/profile/groups/carrier/:carrierGroupId/locations" component={CarrierLocationsPage} />
      <Route path="/profile/groups/carrier/:carrierGroupId/job/pending" component={CarrierJobListPage} />
      <Route path="/profile/groups/carrier/:carrierGroupId/trips/my" component={CarrierGroupUserTripsPage} />
      <Route path="/widget/:widgetType" component={WidgetPage} />
      <Route path="/payouts" component={PayoutsPage} />
      <Route path="/payment/methods" component={PaymentMethodsPage} />
      <Route path="/payment/processing" component={PaymentProcessingPage} />
      <Route exact path="/payment/method/add" render={(props) => (
        <AddPayMethodPage 
          {...props} 
          setPayClientSecret={setPayClientSecret} 
          payClientDetails={payClientDetails} 
          payClientSecret={payClientSecret}
        />
      )} />
      <Route exact path="/payout/add" component={AddPayoutPage} />
      <Route exact path="/exchanges" component={ExchangesLandingPage} />
      <Route path="/exchanges/all/:page?/:searchText?" component={withRouter(ExchangesPage)} />
      <Route path="/exchanges/active/:page?/:searchText?" component={withRouter(ActiveExchangesPage)} />
      <Route path="/exchange/:exchangeId" component={ExchangeDetailPage} />
      <Route path="/posts" component={PostsPage} />
      <Route path="/transactions/list" component={TransactionsPage} />
      <Route exact path="/subscription" render={(props) => (
        <SubscriptionPage
          {...props}
          setPayClientSecret={setPayClientSecret} 
          payClientDetails={payClientDetails} 
          payClientSecret={payClientSecret}
       />
      )} />
      <Route path="/subscription/paid" component={SubscriptionProcessingPage} />
      <Route component={MissingPage} />
    </Switch>
  </HashRouter>

export default Routes;
