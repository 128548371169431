import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Pagination from './../../prompt/core/pagination';
import { 
    EXCHANGE_TYPE_CASH,
    EXCHANGE_TYPE_USPS_CASH_ORDER,
    EXCHANGE_TYPE_ONLINE,
    EXCHANGE_TYPE_TRADE,
    EXCHANGE_TYPE_FREE,
} from './../../prompt/core/enum/exchange-type';

import {
    OBTAIN_TYPE_SHIP_TO_ADDRESS,
    OBTAIN_TYPE_HAND_TO_HAND,
    OBTAIN_TYPE_PICK_UP,
    OBTAIN_TYPE_REMOTE,
    OBTAIN_TYPE_ON_SITE,
} from './../../prompt/core/enum/obtain-type';

import theme from './../../theme';
import CurrencyService from './../../../service/currency';
import ResizeService from './../../../service/resize';

const NoBidsPanel = () => 
    <h2>
        No Bids Yet
    </h2>

const isViewerOnlyBidder = (bids) => bids.total === 1 && bids.list[0].isAuthor;

const BidDetails = ({ 
    bid, 
    index, 
    isActive, 
    bidderIsReceiver,
    targetType,
    posterName = 'poster',
    bidderName = 'bidder'
}) => {
    const getBidDetailStyle = () => theme.getBidDetailsStyle(theme);
    const [ bidDetailStyle, setBidDetailStyle ] = useState( getBidDetailStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setBidDetailStyle( getBidDetailStyle() );
    });
    useEffect(resizeEffect);
    return (
        <div 
            style={bidDetailStyle}
            key={index}
        >
            <div style={{
                ...theme.getBidStatusTextStyle(theme, bid),
                ...theme.getMediumBoldTextStyle(theme),
            }}>
                { !bid.rejected ? ( isActive ? 'Pending' : '') : 'Rejected' }
            </div>
        

            <NavLink to={`/bid/${bid.id}`}>

                { 
                    !bid.isAuthor ? '' :
                        <div>
                            Your Bid
                        </div>
                }
                <h5>
                    { 
                        bid.exchangeMethod === EXCHANGE_TYPE_ONLINE ? 
                            `${ExchangeMethodCopy({ bid })} ${CurrencyService.getDisplayPrice(bid.currency, bid.title)}` : (
                                [EXCHANGE_TYPE_USPS_CASH_ORDER, EXCHANGE_TYPE_CASH].includes(bid.exchangeMethod) ? 
                                    `${ExchangeMethodCopy({ bid })} ${CurrencyService.getDisplayPrice('usd', bid.title)}` : (
                                        bid.exchangeMethod === EXCHANGE_TYPE_FREE ? 
                                            ExchangeMethodCopy({ bid })  : 
                                                `${ExchangeMethodCopy({ bid })} ${bid.title} ` 
                                    )
                            )
                    }
                </h5>
                <p>
                    { bid.details }
                </p>

                { 
                    ObtainMethodView({ 
                        bid, 
                        bidderIsReceiver, 
                        posterName, 
                        bidderName: bid.isAuthor ? 'you' :  bidderName
                    }) 
                }

                {
                    bid.selectedLocation && bid.selectedLocation.regionalAddress && 
                    <p>
                        {`${targetType === 'seek' && [
                            OBTAIN_TYPE_ON_SITE, 
                            OBTAIN_TYPE_SHIP_TO_ADDRESS,
                            OBTAIN_TYPE_HAND_TO_HAND,
                        ].includes(bid.obtainMethod) ? 'Coming from' : 'Location:'} ${ bid.selectedLocation.regionalAddress }`}
                    </p>
                }

            </NavLink>
        </div>
    );
};

/* istanbul ignore next */
const ViewerIsOnlyBidder = ({ bids, isActive, targetType, bidderIsReceiver }) => {
    return <section>
        <h2 
            style={{ 
                ...theme.getBidPopulatedPanelStyle(theme),
            }} 
        >
            Currently, you are the only bidder
        </h2>
        
        <div style={{ 
            ...theme.getBidPopulatedPanelContainerStyle(theme),
        }}>
            { 
                bids.list.map((bid, index) => 
                    <BidDetails 
                        bid={bid} 
                        key={index}
                        index={index} 
                        isActive={isActive}
                        targetType={targetType}
                        bidderIsReceiver={bidderIsReceiver}
                    />
                ) 
            }
        </div>
    
    </section>
}

const getPaginationUi = ({ bids, onLoadSpecifiedPage }) => <Pagination 
                                        total={bids.total} 
                                        page={bids.page} 
                                        onLoadSpecifiedPage={onLoadSpecifiedPage}
                                        perPage={bids.perPage} />

/* istanbul ignore next */                                        
const PopulatedBidsPanel = ({ bids, onLoadSpecifiedPage, isActive, bidderIsReceiver, targetType }) => {
    return isViewerOnlyBidder(bids) ? 
        <ViewerIsOnlyBidder 
            bidderIsReceiver={bidderIsReceiver} 
            bids={bids} 
            targetType={targetType} 
        /> : 
        <section>
            <h2 
                style={{ 
                    ...theme.getBidPopulatedPanelStyle(theme),
                }} 
            >
                {bids.total} Bid{ bids.total > 1 ? 's' : '' }
            </h2>
                
            <section>
                { getPaginationUi({ bids, onLoadSpecifiedPage }) }
                <div style={{
                    ...theme.getOfferSearchResultBidsContainerStyle(theme),
                }}>
                    { 
                        bids.list.map((bid, index) => 
                            <BidDetails
                                bid={bid}
                                key={index}
                                index={index}
                                isActive={isActive}
                                bidderIsReceiver={bidderIsReceiver}
                                targetType={targetType}
                            />
                        )
                    }
                </div> 
                { getPaginationUi({ bids, onLoadSpecifiedPage }) }
            </section>
        
        </section>
}

export const ExchangeMethodCopy = ({ bid }) => bid.exchangeMethod ? (
    bid.exchangeMethod === EXCHANGE_TYPE_CASH  ? 'Cash' : (
        bid.exchangeMethod === EXCHANGE_TYPE_ONLINE ? 'Online Payment' : (
            bid.exchangeMethod === EXCHANGE_TYPE_TRADE ? 'Trade' : (
                bid.exchangeMethod === EXCHANGE_TYPE_USPS_CASH_ORDER ? 'USPS Postal Payment' : 'Free'
            )
        )
    )
) : '';

/* istanbul ignore next */
export const ObtainMethodCopy = ({ 
    bid, 
    bidderIsReceiver,
    posterName = 'poster',
    bidderName = 'bidder'
}) => {
    
    const formatToFirstCharUpper = word => word.charAt(0).toUpperCase() + word.slice(1);
    
    const poster = posterName;
    const firstPoster = formatToFirstCharUpper(poster);
    const posterTravels = posterName === 'poster' ? 'travels' : 'travel';

    const bidder = bidderName;
    const firstBidder = formatToFirstCharUpper(bidder);
    const bidderTravels = bidderName === 'bidder' ? 'travels' : 'travel';

    return bid.obtainMethod ? (
        bid.obtainMethod === OBTAIN_TYPE_HAND_TO_HAND ? ( bidderIsReceiver ? `Hand delivered to ${bidder} by ${poster}` : `Hand delivered to ${poster} by ${bidder}`) : (
            bid.obtainMethod === OBTAIN_TYPE_SHIP_TO_ADDRESS ? ( bidderIsReceiver ? `Shipped to ${bidder} from ${poster}` : `Shipped to ${poster} from ${bidder}`) : (
                bid.obtainMethod === OBTAIN_TYPE_PICK_UP ? ( bidderIsReceiver ? `${firstBidder} ${bidderTravels} to ${poster} to pick up` : `${firstPoster} ${posterTravels} to ${bidder} to pick up`) : (
                    bid.obtainMethod === OBTAIN_TYPE_REMOTE ? ( bidderIsReceiver ? `${firstPoster} will remotely fulfill` : `${firstBidder} will remotely fulfill` ) : 
                    ( bidderIsReceiver ? `${firstPoster} will work on site` : `${firstBidder} will work on site`)
                )
            )
        )
    ) : '';
};

/* istanbul ignore next */
const ObtainMethodView = ({ 
    bid, 
    bidderIsReceiver,
    posterName = 'poster',
    bidderName = 'bidder'
}) => {
    const copy = ObtainMethodCopy({ bid, bidderIsReceiver, posterName, bidderName });
    return copy === '' ? '' : 
    <p>
        { copy }
    </p>
};

const BidsPanel = ({ bids, onLoadSpecifiedPage, isActive, bidderIsReceiver, targetType }) => bids.total === 0 ? 
    <NoBidsPanel /> : 
        <PopulatedBidsPanel 
            bids={bids}
            onLoadSpecifiedPage={onLoadSpecifiedPage} 
            isActive={isActive} 
            bidderIsReceiver={bidderIsReceiver} 
            targetType={targetType} 
        />
    
    
export default BidsPanel;
