import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ResizeService from './../../../service/resize';
import UiService from './../../../service/ui';
import theme from './../../theme';
import ExchangeListPanel from './../../panel/core/exchange-list';

export const SeekSearchSingleFieldPromptContainer = ({ children }) => {
    const getOfferSearchSingleFileContainerFileStyle = () => theme.getOfferSearchSingleFileContainerFileStyle(theme);
    const [ offerSearchSingleFileContainerFileStyle, setOfferSearchSingleFileContainerFileStyle] = useState( getOfferSearchSingleFileContainerFileStyle());
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setOfferSearchSingleFileContainerFileStyle( getOfferSearchSingleFileContainerFileStyle());
    });
    useEffect(resizeEffect);
    return (
        <div style={offerSearchSingleFileContainerFileStyle}>
            { children }
        </div>
    );
};

export const SeekSearchViewPromptContainer = ({ children }) => {
    const getSecStyle = () => theme.getOfferSearchResultTileContainerStyle(theme);
    const [ secStyle, setSecStyle ] = useState( getSecStyle() );
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSecStyle( getSecStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section style={secStyle}>
            { children }
        </section>
    );
};

/* istanbul ignore next */
const SeekSearchViewPromptImgThumb = ({ seek }) => {
    const hasImg = seek.imgList.length > 0;
    const imgSrc = hasImg ? seek.imgList[0].data : '';

    const getSeekSearchResultImageStyle = () => theme.getSeekSearchResultImageStyle(theme);
    const [ seekSearchResultImageStyle, setSeekSearchResultImageStyle ] = useState ( getSeekSearchResultImageStyle() );

    const getSeekSearchResultNoImageStyle = () => theme.getSeekSearchResultNoImageStyle(theme);
    const [ seekSearchResultNoImageStyle, setSeekSearchResultNoImageStyle ] = useState( getSeekSearchResultNoImageStyle() );
    
    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSeekSearchResultNoImageStyle( getSeekSearchResultNoImageStyle() );
        setSeekSearchResultImageStyle( getSeekSearchResultImageStyle() );
    });
    useEffect(resizeEffect);
    return hasImg ? 
        <img style={seekSearchResultImageStyle}
            src={imgSrc} 
            alt="Seek thumbnail" 
        /> :
        <div style={seekSearchResultNoImageStyle}> 
            <p>
                No images saved.
            </p>
        </div>
            
};    

const SeekSearchViewPrompt = ({ seek }) => {
    const getSeekSearchResultStyle = () => theme.getSeekSearchResultStyle(theme);
    const [ seekSearchResultStyle, setSeekSearchResultStyle ] = useState( getSeekSearchResultStyle() );

    const getSeekSearchResultDetailsStyle = () => theme.getSeekSearchResultDetailsStyle(theme);
    const [ seekSearchResultDetailsStyle, setSeekSearchResultDetailsStyle ] = useState( getSeekSearchResultDetailsStyle() );

    const getTimeStyle = () => theme.getSeekSearchResultTimeStyle(theme);
    const [ timeStyle, setTimeStyle ] = useState( getTimeStyle() );    

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setSeekSearchResultStyle( getSeekSearchResultStyle() );
        setSeekSearchResultDetailsStyle( getSeekSearchResultDetailsStyle() );
        setTimeStyle( getTimeStyle() );
    });
    useEffect(resizeEffect);
    return (
        <NavLink 
            to={`/seek/${seek.id}`}
        >
            <div style={seekSearchResultStyle}>
                <SeekSearchViewPromptImgThumb seek={seek} />
                <div>
                    <h3>
                        { seek.title }
                    </h3>
                    <p style={timeStyle}>
                        { UiService.timeSince(seek.createdOn) }
                    </p>
                </div>
            </div>
            <div style={seekSearchResultDetailsStyle}>
                <ExchangeListPanel summaryMode={true} exchangeList={seek.exchangeList} showCopyOnEmpty={true} />
            </div>
        </NavLink>
    );
};

export default SeekSearchViewPrompt;
