import React from 'react';
import DetailPanel from './../core/detail';
import IssueService from '../../../service/issue';
import UiService from '../../../service/ui';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';
import ReportedIssueDetailsViewPanel from './view';

class _ReportedIssueDetailsPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);
        this.loadData = this.loadData.bind(this);
        this.getLoadDataRequest = this.getLoadDataRequest.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onLoadSpecifiedPage = this.onLoadSpecifiedPage.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadChats = this.onLoadChats.bind(this);
        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData(inputObj)
    {
        super.loadData(inputObj);
        IssueService.getReportedIssueDetails({
            request: this.getLoadDataRequest(inputObj),
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    onLoadSpecifiedPage(page)
    {
        super.loadData({ });
        IssueService.getReportedIssueDetails({
            request: {
                appIssueId: this.props.appIssueId,
                page
            },
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadDataRequest(inputObj)
    {
        return {
            appIssueId: inputObj.appIssueId,
            page: 0
        };
    }

    componentDidMount()
    {
        this.onLoadChats();
    }

    onLoadChats()
    {
        const appIssueId = this.props.appIssueId;
        this.loadData({ appIssueId });
        UiService.scrollToTop();
    }

    getLoadedDataDetailUi()
    {
        return <ReportedIssueDetailsViewPanel 
            data={this.state.data} 
            onLoadChats={this.onLoadChats }
            onLoadSpecifiedPage={ this.onLoadSpecifiedPage } 
            appIssueId={this.props.appIssueId}
        />
    }
}

const ReportedIssueDetailsPanel = connect(mapStateToProps, mapDispatchToProps)(_ReportedIssueDetailsPanel);
export default ReportedIssueDetailsPanel;
