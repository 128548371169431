import { 
    Logout,
    UpdateDeviceNetwork,
    UpdateNoticeCount 
} from './../state/action';

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
    onUpdateUnseenNoticeCount(unseenNoticeCount)
    {
        dispatch(UpdateNoticeCount(unseenNoticeCount));
    },
    onLogout()
    {
        dispatch(Logout());
    },
    onUpdateDeviceOnline(deviceIsOnline)
    {
        dispatch(UpdateDeviceNetwork(deviceIsOnline));
    }
});

export default mapDispatchToProps;
