export const addSeekStepPanelStyle = (theme) => ({
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    marginBottom: '2rem',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
});

export const getSeekDetailViewPanelStyle = (theme) => ({
    maxWidth: theme.isDesktop() ? '60rem' : '100%',
    marginBottom: '2rem',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
});
