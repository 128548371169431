import React from 'react';
import DetailPanel from './../core/detail';
import SubscriptionService from './../../../service/subscription';
import SubscriptionViewPanel from './view';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/user-id';
import mapDispatchToProps from './../../../store/map/on-logout';

class _SubscriptionPanel extends DetailPanel 
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onStartSubscription = this.onStartSubscription.bind(this);
        this.onStartSubscriptionResponse = this.onStartSubscriptionResponse.bind(this);
        this.onCancelSubscription = this.onCancelSubscription.bind(this);

        this.state = this.getDefaultState();
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    loadData()
    {
        super.loadData();
        SubscriptionService.getCurrentStatus({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        this.loadData();
    }

    /* istanbul ignore next */
    onStartSubscriptionResponse(response)
    {
        if(this.props.setPayClientSecret)
        {
            this.props.setPayClientSecret(response.clientSecret, response.clientDetails);
        }
    }

    onStartSubscription()
    {
        super.loadData();
        SubscriptionService.start({
            onResponse: this.onStartSubscriptionResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    /* istanbul ignore next */
    onCancelSubscription()
    {
        super.loadData();
        SubscriptionService.cancel({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    getLoadedDataDetailUi()
    {
        return (
            <SubscriptionViewPanel 
                data={this.state.data} 
                onStartSubscription={this.onStartSubscription} 
                onCancelSubscription={this.onCancelSubscription}
            />
        );
    }
}

const SubscriptionPanel = connect(mapStateToProps, mapDispatchToProps)(_SubscriptionPanel);
export default SubscriptionPanel;
