import React from 'react';
import { useState, useEffect } from 'react';
import ResizeService from './../../../service/resize';
import UatvIcon from './../../../trailImg/uatv.png';
import BtaIcon from './../../../trailImg/beartariaTimesApp.png';
import BtIcon from './../../../trailImg/beartariaTimes.png';
import UmIcon from './../../../trailImg/unbearablesMedia.png';
import OwenIcon from './../../../trailImg/owen.png';
import VoxDayIcon from './../../../trailImg/voxDay.png';
import ArkIcon from './../../../trailImg/arkhaven.png';
import SocGalIcon from './../../../trailImg/socialgalactic.png';
import CastaliaIcon from './../../../trailImg/castalia.png';
import InfoGalIcon from './../../../trailImg/infogalactic.png';
import MapIcon from './../../../trailImg/communityMap.svg';
import BuildIcon from './../../../trailImg/buildingBeartaria.png';
import theme from './../../theme';

const UATV = 'UATV';
const BT = 'BT';
const BTA = 'BTA';
const UM = 'UM';
const OWEN = 'OWEN';
const VOX = 'VOX';
const ARK = 'ARK';
const SOCGAL = 'SOCGAL';
const CH = 'CH';
const IG = 'IG';
const MAP = 'MAP';
const BUILD = 'BUILD';

const validCustomClasses = [
    UATV,
    BTA,
    UM,
    OWEN,
    VOX,
    ARK,
    SOCGAL,
    CH,
    IG,
    BT,
    MAP,
    BUILD,
];
const customClassExists = (className) => validCustomClasses.includes(className);

/* istanbul ignore next */
export const getImg = (className) => {
    switch(className)
    {
        case UATV:
            return UatvIcon;

        case BT:
            return BtIcon;

        case BTA:
            return BtaIcon;
        
        case UM:
            return UmIcon;
           
        case OWEN:
            return OwenIcon;
           
        case VOX:
            return VoxDayIcon;
           
        case ARK:
            return ArkIcon;

        case SOCGAL:
            return SocGalIcon;
        
        case CH:
            return CastaliaIcon;

        case IG:
            return InfoGalIcon;

        case MAP:
            return MapIcon;

        case BUILD:
            return BuildIcon;

        default:
            /* istanbul ignore next */
            return BtaIcon;
    }
};

const getCustomStyles = (className) => {
    return theme.getTrailsCustomStyle(theme, getImg(className));
};

/* istanbul ignore next */
const generalTrailUi = (aTrail, index) => 
    <li key={index}>
        <strong>
            <a href={aTrail.url} target="_blank" rel="noreferrer">
                {aTrail.title}
            </a>
        </strong>
        <p>
            {aTrail.details}
        </p>

    </li>


export const customTrailUi = ({aTrail, index}) => 
    <a href={aTrail.url} target="_blank" rel="noreferrer" key={index}>
        <div style={ getCustomStyles(aTrail.customClass) }>
        </div>
    </a>


const TrailsViewPanel = ({ data }) => {
    const getNoMarginStyle = () => theme.getAbstractNoMarginStyle(theme);
    const [ noMarginStyle, setNoMarginStyle ] = useState( getNoMarginStyle() );
    
    const getCompositeContainerStyle = () => ({
        ...theme.getInLineTextStyle(theme),
        ...theme.getGeneralFlexDirectionResizeStyle(theme)
    });
    const [ compositeContainerStyle, setCompositeContainerStyle ] = useState( getCompositeContainerStyle() );
    
    const getBodyStyle = () => theme.getProfilePwdBodyStyle(theme);
    const [ bodyStyle, setBodyStyle ] = useState( getBodyStyle() );

    /* istanbul ignore next */
    const resizeEffect = ResizeService.buildOnResize(() => {
        setNoMarginStyle( getNoMarginStyle() );
        setCompositeContainerStyle( getCompositeContainerStyle() );
        setBodyStyle( getBodyStyle() );
    });
    useEffect(resizeEffect);
    return (
        <section style={{
            ...theme.getGeneralTextStyle(theme),
        }}>
            <div style={compositeContainerStyle}>
                <h2>
                    Platforms
                </h2>
                <h3 style={noMarginStyle}>
                    -
                </h3>
                <h4>
                    Check out Beartarian and bear adjacent platforms.
                </h4>
            </div>
            <div style={bodyStyle}>
                <div style={{
                ...theme.getTrailsPlatformContainerStyle(theme),
                ...theme.getAbstractBottomPaddingSpacerStyle(theme),
                }}>
                    {
                        data.trailList.list.map(
                            /* istanbul ignore next */
                            (aTrail, index) => customClassExists(aTrail.customClass) ? customTrailUi({aTrail, index}) : generalTrailUi(aTrail, index) 
                        )
                    }
                </div>
            </div>

        </section>
    );
};

export default TrailsViewPanel;
