import React from 'react';
import theme from './../../../theme';
import OutOfNetworkQuotes from './out-of-network-quotes';
import Contributors from './contributors';
import PriceControls from './price-controls';
import PriceTotals from './price-totals';
import ExchangeDetailViewPrompt from './../../../prompt/core/exchange-detail-view';
import makeGetGrossTotal from './../core/get-gross-total';
import CurrencyInputService from './../../../../service/currency-input';

const PlanPricingEditor = ({
    delivery,
    members,
    price,
    onSetPayout,
    onPriceControlsUpdated,
    onCompletePricingStatus,
    estimatedDollarsPerGallon,
    estimatedMPG,
    pendingPayoutUpdates,
    onAddExchange,
    onRemoveExchange,
    exchangeList
}) => {

    const getGrossTotal = makeGetGrossTotal({
        price,
        pendingPayoutUpdates
    });
    const grossTotal = getGrossTotal();
    /* istanbul ignore next */
    const asking = grossTotal > 0 ? CurrencyInputService.currencyToInput(grossTotal.toString()) : grossTotal.toString();

    return (
        <div style={{
            ...theme.getGeneralTextStyle(theme)
        }}>
            <PriceControls 
                onUpdated={onPriceControlsUpdated}
                iniEstimatedMPG={estimatedMPG}
                iniEstimatedDollarsPerGallon={estimatedDollarsPerGallon}
            />
            
            <Contributors 
                members={members}
                price={price}
                onSetPayout={onSetPayout}
                estimatedMPG={estimatedMPG}
                estimatedDollarsPerGallon={estimatedDollarsPerGallon}
                pendingPayoutUpdates={pendingPayoutUpdates}
            />
            
            <PriceTotals 
                price={price}
                pendingPayoutUpdates={pendingPayoutUpdates}
            />

            <OutOfNetworkQuotes delivery={delivery} />

            <ExchangeDetailViewPrompt
                promptTitle="Add delivery payment options"
                extraOnlinePaymentCopy=""
                onAddExchange={onAddExchange}
                onRemoveExchange={onRemoveExchange}
                exchangeList={exchangeList}
                asking={asking}
                omitAskingFromRoot={true}
                paidAccount={false}
                payAccountLoaded={true}
                skipOnlinePayValidation={false}
                payAccountSetupComplete={false}
                payCurrencyList={['USD']}
                customSetupOnlinePaymentsCopy="All Members involved in this plan must setup online payments to accept online payment"
            />
        
            <div>
                <button 
                    disabled={exchangeList.length < 1}
                    onClick={
                        /* istanbul ignore next */
                        event => {
                            onCompletePricingStatus();
                        }
                    }
                >
                    Request Team Confirmation
                </button>
            </div>
    

        </div>
    );
};

export default PlanPricingEditor;
