import React from 'react';
import AuthPageTemplate from './template/auth-page';
import BidDetailPanel from './../ui/panel/bid-detail'
import PayNewCardPrompt from './../ui/prompt/pay-new-card';
import { useParams } from 'react-router-dom';
import config from './../config';

const BidDetailPage = ({ setPayClientSecret, payClientDetails, payClientSecret }) => {
    const { bidId } = useParams();
    return (
        <AuthPageTemplate>
            {
                payClientDetails && payClientDetails.type !== '' ? 
                    <PayNewCardPrompt 
                        onSuccessRedirectUrl={config.pay.paymentNewCompletedUrl}
                        payClientSecret={payClientSecret} 
                        payClientDetails={payClientDetails} 
                        setPayClientSecret={setPayClientSecret}  
                    /> :
                        <BidDetailPanel 
                            bidId={bidId} 
                            setPayClientSecret={setPayClientSecret} 
                        />
            }
        </AuthPageTemplate>
    );
};

export default BidDetailPage;
