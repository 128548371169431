export const getMenuContainerStyle = (theme) => ({
    position: 'fixed',
    top: '0',
    width: '100%',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    transition: 'all 195ms ease-out',
});

export const getHiddenMenuContainerStyle = (theme) => ({
    position: 'fixed',
    width: '100%',
    minWidth: theme.isPhone() ? '20rem' : '15rem',
    top: '-12rem',
    transition: 'all 195ms ease-in',
});

export const getMenuDockStyle = (theme) => ({
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    top: '0',
    justifyContent: theme.isTablet() ? 'center' : 'end',
    backgroundColor: theme.navColor,
    transition: 'all 195ms ease-out',
    margin: theme.isDesktop() ? '0 auto' : '0', 
    maxWidth: theme.isDesktop() ? '991px' : '100%',
    height: 'fit-content',
    overflow: 'hidden',
    position: 'relative',
    zIndex: '7',
});

export const getMenuDockHiddenStyle = (theme) => ({
    alignContent: 'space-between',
    top: '-12rem',
    flexWrap: 'nowrap',
    display: 'flex',
    justifyContent: theme.isTablet() ? 'center' : 'end',
    backgroundColor: theme.navColor,
    transition: 'all 195ms ease-in',
    margin: '0 auto', 
    maxWidth: theme.isDesktop() ? '991px' : '100%',
    height: 'fit-content',
    overflow: 'hidden',
    position: 'relative',
    zIndex: '7',
});

export const getMainMenuDockStyle = (theme) => ({
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    top: '0',
    justifyContent: theme.isTablet() ? 'center' : 'end',
    padding: '0 1rem',
    backgroundColor: theme.navColor,
    transition: 'all 195ms ease-out',
    margin: '0 auto', 
    maxWidth: theme.isDesktop() ? '991px' : '100%',
    height: '6.5rem',
    overflow: 'hidden',
    zIndex: '7',
    position: 'relative',
});

export const getMainMenuDockHiddenStyle = (theme) => ({
    alignContent: 'space-between',
    top: '-12rem',
    flexWrap: 'nowrap',
    display: 'flex',
    justifyContent: theme.isTablet() ? 'center' : 'end',
    padding: '0 1rem',
    backgroundColor: theme.navColor,
    transition: 'all 195ms ease-in',
    margin: '0 auto', 
    maxWidth: theme.isDesktop() ? '991px' : '100%',
    height: '6.5rem',
    overflow: 'hidden',
    zIndex: '7',
    position: 'relative',
});


export const getSmallMenuContainerStyle = (theme) => ({
    position: 'fixed',
    top: '0',
    alignItems: 'end',
    margin: '0 auto', 
    maxWidth: theme.isDesktop() ? '991px' : '100%',
    transition: 'top 0.9s',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: '10'
});

export const getHiddenSmallMenuContainerStyle = (theme) => ({
    position: 'fixed',
    top: '0',
    alignItems: 'end',
    margin: '0 auto', 
    maxWidth: theme.isDesktop() ? '991px' : '100%',
    transition: 'top 0.9s',
    height: 'fit-content',
    display: 'none',
    flexDirection: 'column',
    width: '100%'
});

export const getSmallListMenuItemParentStyle = (theme) => ({
    cursor: 'pointer',
    padding: '.5rem',
    fontSize: '1.25rem',
    color: theme.primaryColor
});

export const getSmallListMenuItemParentWhenDeepStyle = (theme) => ({
    cursor: 'pointer',
    fontSize: '0.75rem',
    color: theme.primaryColor
});

export const getSmallMenuListStyle = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1rem'
});

export const getSmallMenuListWhenDeepStyle = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1rem',
});

export const getSmallMenuListContainerStyle = (theme) => ({
    backgroundColor: theme.navMiniColor,
    color: theme.primaryColor,
    alignItems: 'end',
    display: 'flex',
    padding: '.5rem .5rem 1.25rem',
    flexDirection: 'column',
    position: 'relative',
    width: 'fit-content',
    maxHeight: 'calc(100vh - 6.5rem)',
    overflow: 'auto',
    borderRadius: '0 0 .25rem .25rem',
    zIndex: '7'
});

export const getSmallMenuListFlexContainerStyle = (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '14rem',
    maxWidth: '18rem',
    width: 'fit-content'
});

export const getSmallMenuBackDropStyle = (theme) => ({
    zIndex: '5',
    position: 'absolute',
    height: '100vh',
    inset: '0',
    backgroundColor: theme.backgroundOverlayLight
});

export const getSmallMenuListLinkStyle = (theme) => ({
    color: theme.primaryColor,
    padding: '.5rem',
});

export const getSmallMainMenuDockStyle = (theme) => ({
    alignContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    top: '0',
    justifyContent: theme.isTablet() ? 'center' : 'end',
    padding: '0 1rem',
    backgroundColor: theme.navColor,
    transition: 'all 500ms ease-in-out',
    margin: '0 auto', 
    maxWidth: theme.isDesktop() ? '991px' : '100%',
    height: '6.5rem',
    overflow: 'hidden',
    zIndex: '7',
    width: '100%'
});

export const getMenuListStyle = (theme) => ({
    flexWrap: 'wrap',
    display: theme.isTablet() ? 'flex' : 'none',     
});

export const getMainMenuListStyle = (theme) => ({
    flexWrap: 'wrap',
    display: theme.isTablet() ? 'flex' : 'none',     
});

export const getMenuListRightStyle = (theme) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    transition: 'all 500ms ease-in-out',
    overflow: 'visible',
});

export const getMenuStyle = (theme, icon) => ({
    width: '100%',
    minWidth: '4.5rem',
    height: '4.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '3.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
});

export const getMenuSubStyle = (theme, icon) => ({
    minWidth: '3.5rem',
    height: '3rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,

});

export const getMenuRightStyle = (theme, icon) => ({
    minWidth: '4.5rem',
    height: '6.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '3.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
});

export const getMenuCollapseStyle = (theme, icon, neverHide = false) => ({
    minWidth: '4.5rem',
    height: '6.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '3.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
    display: neverHide ? 'inherit' : ( 
        theme.isTablet() ? 'none' :
                'inherit'
    ),
});

export const getMenuTitleStyle = (theme) => ({
    textAlign: 'center',
    padding: '0 .75rem',
    fontSize: '13pt',
    color: theme.navTextColor,
    height: '2rem',
    alignItems: 'center',
    display: 'flex',
});

export const getMenuSubTitleStyle = (theme) => ({
    textAlign: 'center',
    display: 'flex',
    padding: '0 .75rem',
    fontSize: '11pt',
    fontWeight: '600',
    color: theme.navTextColor,
    height: '2.5rem',
    alignItems: 'center',
    cursor: 'pointer'
});

export const getMenuStyleGuest = (theme, icon) => ({
    marginLeft: '.25rem',
    marginRight: '.25rem',
    width: '3.5rem',
    height: '3.5rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
});

export const getUnseenNoticeStyle = (theme) => ({
    color: theme.navTextColor,
    textDecorationLine: 'none',
    position: 'relative',
    left: '0.75rem',
    top: '1.82rem',
    backgroundColor: '',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '11pt',
    textShadow: '#101010 -.08rem .08rem .04rem',
});

export const getItemViewerBidPromptButtonStyle = (theme) => ({
    fontSize: '16pt',
});

export const getItemViewerMenuStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end'
});

export const getItemAuthorMenuStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end'
});

export const getSelectedMenuItemStyle = (theme) => ({
    backgroundColor: theme.selectedMenuColor
});

export const getConfirmArchiveItemModalContainerStyle = (theme) => ({
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    backgroundColor: theme.primaryColor,
    color: theme.secondaryColor,
});

export const getConfirmArchiveItemModalTextStyle = (theme) => ({
    paddingBottom: '15px'
});
