import React from 'react';
import AuthPageTemplate from './template/auth-page';
import ProfileMenu from './../ui/menu/profile';
import ProfilePeoplePanel from './../ui/panel/profile-people';

const ProfilePeoplePage = () => {
    return (
        <AuthPageTemplate childMenu={
            <div>
                <ProfileMenu />
            </div>
        }>
            <ProfilePeoplePanel />
        </AuthPageTemplate>
    );
};

export default ProfilePeoplePage;
