import React from 'react';
import DetailPanel from './../core/detail';
import SubscriptionService from './../../../service/subscription';
import { connect } from 'react-redux';
import mapStateToProps from './../../../store/map/auth-token';
import mapDispatchToProps from './../../../store/map/on-logout';
import config from '../../../config';
import { BX_SUBSCRIPTION_ACTIVE } from './../../prompt/core/enum/bx-subscription-state';
import UrlService from './../../../service/url';

class _WaitForSubscriptionConfirmedPanel extends DetailPanel
{
    constructor(parms)
    {
        super(parms);

        this.loadData = this.loadData.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.getLoadedDataDetailUi = this.getLoadedDataDetailUi.bind(this);
        this.onAuthError = this.onAuthError.bind(this);
        this.onFatalError = this.onFatalError.bind(this);
        this.onNetworkOffline = this.onNetworkOffline.bind(this);
        this.onLoadDataResponse = this.onLoadDataResponse.bind(this);
        this.getContactingServerUi = this.getContactingServerUi.bind(this);
        this.getDefaultState = this.getDefaultState.bind(this);
        this.updateFailCount = this.updateFailCount.bind(this);
        this.tooManyFailures = this.tooManyFailures.bind(this);
    }

    getDefaultState()
    {
        const baseState = super.getDefaultState();
        return {
            ...baseState,
            failCount: 0
        };
    }

    updateFailCount()
    {
        const nextState = {
            ...this.state,
            failCount: this.state.failCount + 1
        };
        this.setState(nextState);
    }

    tooManyFailures()
    {
        return this.state.failCount > config.pay.maxFailures;
    }

    onAuthError()
    {
        this.props.onLogout();
    }

    onFatalError()
    {
        this.props.onUpdateError(true, '');
    }

    onNetworkOffline()
    {
        this.props.onUpdateDeviceOnline(false);
    }

    onLoadDataResponse(response)
    {
        if(response.hasRecord && response.status === BX_SUBSCRIPTION_ACTIVE)
        {
            const nextState = {
                ...this.state,
                contactingServer: false,
                message: '',
                redirectTo: '/subscription',
            };
            this.setState(nextState);
        }
        else
        {
            this.updateFailCount();
            /* istanbul ignore next */
            if(this.tooManyFailures())
            {
                this.onLoadDataError({
                    message: 'Your subscription verification is taking longer than expected.  Once processed your subscription status will update.'
                });
            }
            else
            {
                setTimeout(() => { this.loadData(); }, config.pay.submitPaymentMethodFeedbackDelay);
            }
        }
        
    }

    loadData()
    {
        super.loadData();
        SubscriptionService.getCurrentStatus({
            onResponse: this.onLoadDataResponse,
            onError: this.onLoadDataError,
            authToken: this.props.authToken,
            onAuthError: this.onAuthError,
            onFatalError: this.onFatalError,
            onNetworkOffline: this.onNetworkOffline
        });
    }

    componentDidMount()
    {
        const tester = /payment_intent/i;
        UrlService.correctUrl(tester, config.pay.subscriptionConfirmedUrl);
        this.loadData();
    }

    /* istanbul ignore next */
    getLoadedDataDetailUi()
    {
        return <p>
            Subscription Confirmed
        </p>
    }

    getContactingServerUi()
    {
        return (
            <div>
                <p>
                    Confirming Subscription
                </p>
                {
                    super.getContactingServerUi()
                }
            </div>
        );
    }
}

const WaitForSubscriptionConfirmedPanel = connect(mapStateToProps, mapDispatchToProps)(_WaitForSubscriptionConfirmedPanel);
export default WaitForSubscriptionConfirmedPanel;
