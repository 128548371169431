import React from 'react';

export const getIconButtonStyle = (theme, icon) => ({
    width: '3rem',
    height: '3rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
    flexShrink: '0'
});

export const getIconOverlayButtonStyle = (theme, icon) => ({
    width: '3rem',
    height: '3rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '2.5rem',
    cursor: 'pointer',
    backgroundImage: `url(${icon})`,
    position: 'absolute',
    opacity: '.86'
});

export const getIconflexContainerStyle = (theme, icon) => ({
    display: 'flex',
    maxWidth: theme.isDesktop() ? '60rem' : 'calc(100% - 1.5rem)',
    margin: '0 auto',
    alignItems: 'center',
    marginTop: '1rem'
});

export const getIconHeaderStyle = (theme, icon) => ({
    flexGrow: '1',
    marginRight: '3rem',
});

export const getIconButtonContainerStyle = (theme, { customContainerStyle }) => {
    const custom = customContainerStyle ? customContainerStyle : [];
    return {
        width: '3rem',
        ...custom,
    };
}


export const getIconButtonUi = ({ theme, icon, onClick, customContainerStyle = {} }) => 
    <div style={{
        ...getIconButtonContainerStyle(theme, { customContainerStyle }),
    }}>
        <div 
            onClick={
                /* istanbul ignore next */
                event => { onClick() }
            }
            style={{
                ...getIconButtonStyle(theme, icon),
        }}></div>
    </div>